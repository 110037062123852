import queryString from "data/queryString";
import {
    type SidebarItemDTO,
    SpecialFoldersItemURIs,
    SystemWebDavName,
} from "data/types";
import loc from "i18next";

let siteActiveCache = {
    uri: null,
    orderedIndex: -1,
};

// HACK for some reason the LinkContainer does not receive the correct match object
export const isSiteActive = (
    location: Location,
    sitesOrdered: Array<SidebarItemDTO>,
    site: SidebarItemDTO
): boolean => {
    const qs = queryString.parse(location.search);
    const qsuri =
        location.hash !== SpecialFoldersItemURIs.RecycleBinUri &&
        qs.uri !== SpecialFoldersItemURIs.RecycleBinUri
            ? "pam-item://" + qs.uri
            : SpecialFoldersItemURIs.RecycleBinUri;

    // no need to find qsuri every time
    if (siteActiveCache.uri === qsuri) {
        return (
            site.itemUri === sitesOrdered[siteActiveCache.orderedIndex].itemUri
        );
    }
    // do we have an exact match?
    const orderedIndex = sitesOrdered.findIndex(
        (orderedSite) => orderedSite.itemUri === qsuri
    );
    // ...then take it
    if (orderedIndex !== -1) {
        siteActiveCache = { uri: qsuri, orderedIndex };
        return site.itemUri === sitesOrdered[orderedIndex].itemUri;
    } else {
        // find closest neighbour
        const orderedIndex2 = sitesOrdered.findIndex(
            (orderedSite) => qsuri.indexOf(orderedSite.itemUri) === 0
        );
        if (orderedIndex2 !== -1) {
            siteActiveCache = { uri: qsuri, orderedIndex: orderedIndex2 };
            return site.itemUri === sitesOrdered[orderedIndex2].itemUri;
        }
    }
    //if uri does not exist at all in our sidebar
    siteActiveCache = { uri: null, orderedIndex: -1 };
    return false;
};

export const siteDisplayName = (site: SidebarItemDTO): string => {
    const nameIndex = Object.values(SystemWebDavName).indexOf(site.caption);

    if (nameIndex !== -1) {
        return loc.t(`common:siteItemType.${site.siteType}`);
    }

    return site.caption;
};
