// @flow
import React, { PureComponent } from "react";
import {
    type SearchBucketDTO,
    type SearchFilterDTO,
    type SearchValueTypeEnum,
    type ListViewModeEnum,
} from "data/types";
import { connect } from "react-redux";
import FilterBar from "components/FilterBar";
import * as s from "data/reducers/selectors";

type Props = {
    /** list of filters (and if any possible values) to show */
    aggregations: ?Array<SearchBucketDTO>,
    /** currently selected filter values */
    selected: Array<SearchFilterDTO>,
    /** callback when user selects a filter
     * @param {string} name field's common name
     * @param {SearchValueTypeEnum} valueType field's type
     * @param {Object} value the actual value selected
     */
    onSelect: (
        name: string,
        valueType: SearchValueTypeEnum,
        value: Object
    ) => void,
    /** callback when user wants to clear/reset all filter values */
    onClear?: () => void,
    /** callback when user wants to quickly filter by text */
    onFilterTextChange?: (value: ?string) => void,
    /** current filterText value */
    filterText: ?string,
    /** placeholder text to display on quick text filter */
    filterTextPlaceholder?: string,
    /** current viewMode */
    viewMode: ListViewModeEnum,
    /** which view modes to show/allow */
    viewModes?: Array<ListViewModeEnum>,
    /** callback when user changes viewMode
     * @param {ListViewModeEnum} viewMode the viewMode the user chose
     */
    onViewModeChange: (viewMode: ListViewModeEnum) => void,
    /** whether filter should be rendered */
    renderFilter: ?boolean,
    /** whether filter should be disabled */
    disableFilter: ?boolen,
};

type State = {
    aggregations: ?Array<SearchBucketDTO>,
};

/** will render a filter/sort/views bar */
export class FilterBarContainer extends PureComponent<Props, State> {
    state = {
        aggregations: undefined,
    };

    componentDidMount = () =>
        this._prep(this.props.aggregations, this.props.selected);

    componentDidUpdate(prevProps: Props) {
        /* istanbul ignore else */
        if (
            prevProps.aggregations !== this.props.aggregations ||
            prevProps.selected !== this.props.selected
        ) {
            this._prep(this.props.aggregations, this.props.selected);
        }
    }

    _prep(
        aggregations: ?Array<SearchBucketDTO>,
        selected: Array<SearchFilterDTO>
    ) {
        if (aggregations == null) {
            this.setState({ aggregations: undefined });
        } else {
            /* istanbul ignore next */
            let items = aggregations.slice(0);

            // update bucket.selected
            selected.forEach((filter) => {
                const item = items.find((i) => i.name === filter.name);
                /* istanbul ignore else */
                if (item != null) {
                    item.selected = [filter.value];
                }
            });
            this.setState({
                aggregations: items,
            });
        }
    }

    _onSelect = ({
        name,
        value,
        valueType,
    }: {
        name: string,
        value: any,
        valueType?: SearchValueTypeEnum,
    }): void => {
        const { aggregations } = this.state;
        /* istanbul ignore next */
        if (aggregations == null) return;
        const aggregation = aggregations.find((b) => b.name === name);
        /* istanbul ignore else */
        if (aggregation != null) {
            this.props.onSelect(
                name,
                valueType || aggregation.valueType,
                value
            );
        }
    };

    render() {
        /* istanbul ignore else */
        if (this.state.aggregations == null) return null;

        return (
            <FilterBar
                buckets={this.state.aggregations}
                onSelect={this._onSelect}
                onClear={this.props.onClear}
                filterText={this.props.filterText}
                onFilterTextChange={this.props.onFilterTextChange}
                filterTextPlaceholder={this.props.filterTextPlaceholder}
                showClear={
                    this.props.selected.filter(
                        (s) => String(s.value).length > 0
                    ).length > 0
                }
                viewMode={this.props.viewMode}
                viewModes={this.props.viewModes}
                onViewModeChange={this.props.onViewModeChange}
                renderFilter={this.props.renderFilter}
                disableFilter={this.props.disableFilter}
            />
        );
    }
}

FilterBarContainer.displayName = "FilterBarContainer";

const mapStateToProps = /* istanbul ignore next */ (state, ownProps) => ({
    ...ownProps,
    disableFilter: s.isOnlineSelector(state) ? ownProps.disableFilter : true,
});

export default connect(mapStateToProps)(FilterBarContainer);
