// @flow
import React, { useCallback } from "react";
import MenuItemUI from "react-bootstrap/lib/MenuItem";
import SearchBucket from "./";
import CountBadge from "components/CountBadge";
import { getLocalizedText } from "data/utils";
import { type SearchBucketValueDTO } from "data/types";
import styles from "./SearchBucket.module.css";

type Props = {
    name: string,
    selected: Array<string | number>,
    item: SearchBucketValueDTO,
    onSelect: (name: string, value: Object) => void,
};

const MenuItem = ({ name, selected, item, onSelect }: Props) => {
    const handleSelect = useCallback(
        () => onSelect({ name, value: item.value, valueType: item.valueType }),
        [onSelect, name, item]
    );
    return (
        <MenuItemUI
            active={selected.includes(item.value)}
            onSelect={handleSelect}
        >
            {item.count > 0 && (
                <span className={`pull-right text-sm ${styles.badge}`}>
                    <CountBadge count={item.count} />
                </span>
            )}
            <SearchBucket.Icon
                name={name}
                value={item.value}
                className={styles.icon}
            />
            <span className={`${styles.value} ${styles.margin}`}>
                {getLocalizedText(item.caption || item.value)}
            </span>
        </MenuItemUI>
    );
};

MenuItem.displayName = "FilterBar.SearchBucket.MenuItem";

export default MenuItem;
