import React from "react";
import { createModal } from "react-modal-promise";
import { OptionsModal } from "components/OptionsModal";
import { getLocalizedText } from "data/utils";
import { ShareNotifyType } from "data/types";

const options = [
    {
        icon: "fa-regular fa-user",
        eventKey: ShareNotifyType.Updated,
        label: "$share:notify.updated",
        isDefault: true,
    },
    {
        icon: "fa-regular fa-users",
        eventKey: ShareNotifyType.All,
        label: "$share:notify.all",
    },
    {
        icon: "fa-regular fa-xmark",
        eventKey: ShareNotifyType.None,
        label: "$share:notify.none",
    },
];

export const NotifyOptionsModal = ({ shareAs, ...props }) => (
    <OptionsModal
        options={options}
        requireSelection={true}
        title={getLocalizedText("$share:notify.title", {
            shareAs: shareAs?.join('", "'),
        })}
        {...props}
    />
);

NotifyOptionsModal.displayName = "Share.NotifyOptionsModal";
export const openNotifyOptionsModal = createModal(NotifyOptionsModal);
