import React, { useCallback } from "react";
import { getLocalizedText } from "data/utils";

const style = {
    height: "15px",
    width: "15px",
    padding: 0,
    verticalAlign: "top",
    marginBottom: 0,
    marginLeft: ".5em",
};

const input = {
    margin: 0,
    height: "15px",
    width: "15px",
    cursor: "pointer",
};

const stopPropagation = (e) => {
    e.stopPropagation();
};

const Checkbox = ({
    className,
    handleToggle,
    name,
    isSelected,
    label,
}: Props) => {
    const handleChange = useCallback(
        (e) => {
            handleToggle(name);
        },
        [handleToggle, name]
    );
    const checked = isSelected(name);
    return (
        <label
            className={className}
            onClick={stopPropagation}
            onDoubleClick={stopPropagation}
            style={style}
            title={getLocalizedText("$upload:rememberValues.checkbox", {
                label,
            })}
        >
            <input
                style={input}
                type="checkbox"
                onChange={handleChange}
                checked={checked}
            />
        </label>
    );
};

Checkbox.displayName = "RememberValues.Checkbox";

export default Checkbox;
