import {
    call,
    put,
    takeLatest,
    takeEvery,
    select,
    take,
    race,
} from "redux-saga/effects";
import { delay } from "redux-saga";
import actionTypes from "data/actions/actionTypes";
import {
    getFavoritesAction,
    getFavoritesResultAction,
    setCurrentBreadcrumb,
} from "data/actions";
import { fetchFavorites } from "data/api";
import Constants from "data/constants";
import * as s from "data/reducers/selectors";

const breadcrumbSelector = (state, viewName) => state.breadcrumb[viewName];

function* getFavorites(action) {
    try {
        const favorites = yield call(fetchFavorites);
        yield put(
            getFavoritesResultAction({ status: Constants.OK, favorites })
        );

        // const breadcrumb = yield select(breadcrumbSelector);
        // yield put(setCurrentBreadcrumb(breadcrumb));
    } catch (e) {
        yield put(
            getFavoritesResultAction({
                status: Constants.ERROR,
                message: e.message,
            })
        );
    }
}

function* checkIsFav(action) {
    const breadcrumb = yield select(
        breadcrumbSelector,
        action.payload.viewName
    );
    // only check if unknown
    if (breadcrumb != null && breadcrumb.isFav == null) {
        let stateFavoritesStatus = yield select(s.favoritesStatusSelector);
        if (
            [Constants.LOADING, Constants.REFRESH].includes(
                stateFavoritesStatus
            )
        ) {
            const { timeout } = yield race({
                favoritesFetched: take(actionTypes.FAVORITES_FETCH_RESULT),
                timeout: call(delay, 5000),
            });
            stateFavoritesStatus = yield select(s.favoritesStatusSelector);
            /* istanbul ignore if */
            if (timeout || stateFavoritesStatus !== Constants.OK) {
                return;
            }
        }
        const stateFavorites = yield select(s.favoritesFavoritesSelector);
        // only check if favorites known
        /* istanbul ignore else */
        if (stateFavorites != null) {
            const isFav = stateFavorites.includes(breadcrumb.itemUri);
            // update state
            yield put(setCurrentBreadcrumb({ ...action.payload, isFav }));
        }
    }
}

export function* refreshFavorites(action) {
    /* istanbul ignore else */
    if ("favorite" === action.payload.item) {
        return yield put(getFavoritesAction(Constants.REFRESH));
    }
}

function* favoritesSaga() {
    yield takeLatest(actionTypes.FAVORITES_FETCH_REQUEST, getFavorites);
    yield takeEvery(actionTypes.CURRENT_BREADCRUMB, checkIsFav);
    yield takeEvery(actionTypes.GLOBALEVENTS_SERVER, refreshFavorites);
}

export default favoritesSaga;
