import React from "react";
import FormsyEditorBase from "./FormsyEditorBase";
import GeoRasterInput from "components/GeoRasterInput";

const GeoRasterEditorControl = (props) => (
    <FormsyEditorBase
        required={props.isRequired ? "isDefaultRequiredValue" : undefined}
        render={(value, changeValue) => (
            <GeoRasterInput
                itemUri={props.itemUri}
                formatId={props.formatId}
                marker={props.marker}
                label={props.header}
                value={value || ""}
                showIcon={false}
                onChange={
                    /* istanbul ignore next */ (value: string) =>
                        changeValue({ currentTarget: { value } })
                }
                onManualLocation={
                    /* istanbul ignore next */ (value: string) =>
                        changeValue({ currentTarget: { value } })
                }
                placeholder={props.placeholder}
                inputId={props.name}
            />
        )}
        {...props}
    />
);
GeoRasterEditorControl.displayName = "GeoRasterEditorControl";
export default GeoRasterEditorControl;
