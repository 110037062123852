import { delOfflineItem } from "data/api";
import { toastActionResult } from "data/toast";
import { offlineSyncUpdateAction } from "data/actions";
import { Dispatch } from "react-redux";

/**
 * Removes an item from being available offline
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name: string }} { name }
 */

type Context = {
    itemUri: string,
    name: string,
    dispatch: Dispatch,
};

class offline_delete {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;
    execute = async ({
        itemUri,
        name,
        dispatch,
    }: Context): Promise<boolean> => {
        try {
            const success = await delOfflineItem(itemUri);
            dispatch(offlineSyncUpdateAction());
            return success;
        } catch (error) {
            return false;
        }
    };
    report = async (success: boolean, { name }: Context): Promise<boolean> => {
        this.logger(success === true, "commandAction:offline_delete", {
            name,
        });
    };
}

export default offline_delete;
