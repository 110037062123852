// @flow
import React from "react";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import {
    ViewNameType,
    type SidebarItemDTO,
    type SearchItemDTO,
    type SearchScopeEnum,
    type SearchFilterDTO,
    type SearchNodeTypeEnum,
} from "data/types";
import queryString from "data/queryString";
import * as cfg from "data/storeHelper";
import { isMiniView, isSecureParams, get } from "data/constants";

export const inPointAction = {
    browse: "browse",
    preview: "preview",
    properties: "properties",
    edit: "edit",
    archive: "archive",
    commandAction: "commandaction",
};
export type inPointActionsEnum = $Keys<typeof inPointAction>;

export /**
 * creates an inPoint:uri link (for use with inPoint.Sync)
 *
 * @param {inPointActionsEnum} action type of action
 * @param {string?} itemUri itemUri
 * @returns {string} link
 */
const inPointActionLink = (
    action: inPointActionsEnum,
    itemUri?: string,
    itemUris?: Array<string>,
    additionalProps?: any = {}
): string =>
    `inpoint:${action}?${queryString.stringify({
        ...additionalProps,
        uri: itemUri,
        uris: itemUris,
        sp: get(window.CONFIG, ["general", "secure_params"], undefined),
    })}`;

const ClientLink = ({
    action,
    name,
    itemUri,
}: {
    action: inPointActionsEnum,
    name: string,
    itemUri: string,
}) => (
    <a
        href={inPointActionLink(inPointAction[action], itemUri)}
        target="_BLANK"
        rel="noopener noreferrer"
    >
        {name}
    </a>
);
ClientLink.displayName = "ClientLink";

export /**
 * returns an HTML rendered link to open an inPoint:uri (for use with inPoint.Sync)
 *
 * @param {string} name text to display as link name
 * @param {string} itemUri itemUri
 * @param {inPointActionsEnum} action inPoint.Client action
 * @returns {string} HTML rendering of link
 */
const getClientLinkHtml = (
    name: string,
    itemUri: string,
    action: inPointActionsEnum
): string => {
    const link = <ClientLink action={action} name={name} itemUri={itemUri} />;
    return ReactDOMServer.renderToStaticMarkup(link);
};

export /**
 * converts an itemUri to a Web short itemUri (no protocol prefix)
 *
 * @param {string} itemUri
 * @returns {string}
 */
const shortItemUri = (itemUri: string): string =>
    itemUri.substring(
        itemUri.indexOf("pam-item://") === -1 ? 0 : "pam-item://".length
    );

// export /** returns an itemUri with a specific document's version */
// const docItemUriWithVersion = (itemUri: string, version?: number) =>
//     version && version > 0 ? `${itemUri}@version=${version}` : itemUri;

export /**
 * returns route to open a document
 *
 * @param {string} itemUri itemUri
 * @param {boolean} [mini] whether docRoute should be opened in mini mode
 * @param {boolean} [externalLink] whether link will be used for external new browser
 * @param {string | Array<string>} [toggles] toggles to select
 * @param {?string} [mark] terms to highlight (if PDF)
 * @param {boolean} [print] toggle print dialog (if PDF)
 * @returns {string} doc route link
 */
const docRoute = (
    itemUri: string,
    mini?: boolean,
    externalLink?: boolean,
    toggles?: string | Array<string>,
    mark?: string,
    print?: boolean,
    edit?: boolean
): string =>
    (externalLink === true
        ? get(window.CONFIG, ["host", "basename"], "")
        : "") +
    (mini === true ? "/mini" : "") +
    "/doc?" +
    queryString.stringify({
        uri: shortItemUri(itemUri),
        sp: get(window.CONFIG, ["general", "secure_params"], undefined),
        toggles:
            toggles != null && Array.isArray(toggles)
                ? toggles.join(",")
                : toggles,
        mark,
        print,
        edit,
    });

export /**
 * converts a searchItem object into a searchRoute
 *
 * @param {SearchItemDTO} searchItem searchItem object to convert
 * @returns {string} search route link
 */
const searchRouteWithItem = (searchItem: SearchItemDTO): string => {
    let qs = {};
    Object.keys(searchItem).forEach((k) => {
        if ((searchItem[k] && searchItem[k] !== null) || searchItem[k] === 0)
            switch (k) {
                case "sorts":
                    //TODO: Allow multiple sorts
                    const sort = searchItem[k][0];
                    Object.keys(sort).forEach(
                        (sortKey) => (qs[`sorts.${sortKey}`] = sort[sortKey])
                    );
                    break;
                default:
                    qs[k] = searchItem[k];
                    break;
            }
    });
    return "/searchResult?" + queryString.stringify(qs);
};

export /**
 * returns route to start a fulltext search with keyword
 *
 * @param {string} keyword keyword to use for search
 * @returns {string} search route link
 */
const searchRouteWithKeyword = (keyword: string): string =>
    "/searchResult?" +
    queryString.stringify({
        searchText: keyword,
    });

export /**
 * returns route to start a fulltext search with keyword scoped to itemUri
 *
 * @param {string} keyword keyword to use for search
 * @param {string} itemUri itemUri to limit scope (CurrentFolder)
 * @param {SearchScopeEnum} [scope] optional search scope (AllSites, AllFolders, CurrentFolder)
 * @param {Array<SearchFilterDTO>} [filters] optional search filters
 * @param {Array<SearchSortDTO>} [sorts] optional search sorts
 * @param {SearchNodeTypeEnum} [nodeType] optional search NodeType
 * @returns {string} search route link
 */
const searchRouteWithKeywordAndMore = (
    keyword: string,
    itemUri: string,
    scope?: SearchScopeEnum,
    filters?: Array<SearchFilterDTO>,
    sorts?: Array<SearchSortDTO>,
    nodeType?: SearchNodeTypeEnum
): string =>
    "/searchResult?" +
    queryString.stringify({
        searchText: keyword,
        itemUri,
        scope,
        filters:
            filters && filters.length > 0 ? JSON.stringify(filters) : undefined,
        sorts: sorts && sorts.length > 0 ? JSON.stringify(sorts) : undefined,
        nodeType,
    });

export /**
 * returns a folder route
 *
 * @param {string} itemUri folder's itemUri
 * @param {boolean} [mini] whether route should be opened in mini mode
 * @param {boolean} [externalLink] whether link will be used for external new browser
 * @param {string | Array<string>} [toggles] toggles to select
 * @returns {string} folder route link
 */
const folderRoute = (
    itemUri: string,
    mini?: boolean,
    externalLink?: boolean,
    toggles?: string | Array<string>
): string =>
    (externalLink === true
        ? get(window.CONFIG, ["host", "basename"], "")
        : "") +
    (mini === true ? "/mini" : "") +
    "/folder?" +
    queryString.stringify({
        uri: shortItemUri(itemUri),
        sp: get(window.CONFIG, ["general", "secure_params"], undefined),
        toggles:
            toggles != null && Array.isArray(toggles)
                ? toggles.join(",")
                : toggles,
    });

export /**
 * returns a route for flat view
 *
 * @param {string} itemUri folder's itemUri
 * @param {boolean} [mini] whether route should be opened in mini mode
 * @param {boolean} [externalLink] whether link will be used for external new browser
 * @param {string | Array<string>} [toggles] toggles to select
 * @returns {string} flat route link
 */
const flatRoute = (
    itemUri: string,
    mini?: boolean,
    externalLink?: boolean,
    toggles?: string | Array<string>
): string =>
    (externalLink === true
        ? get(window.CONFIG, ["host", "basename"], "")
        : "") +
    (mini === true ? "/mini" : "") +
    "/flat?" +
    queryString.stringify({
        uri: shortItemUri(itemUri),
        sp: get(window.CONFIG, ["general", "secure_params"], undefined),
        toggles:
            toggles != null && Array.isArray(toggles)
                ? toggles.join(",")
                : toggles,
    });

export /**
 * returns a route for mail view
 *
 * @param {string} itemUri folder's itemUri
 * @param {boolean} [mini] whether route should be opened in mini mode
 * @param {boolean} [externalLink] whether link will be used for external new browser
 * @param {string | Array<string>} [toggles] toggles to select
 * @returns {string} mail route link
 */
const mailRoute = (
    itemUri: string,
    mini?: boolean,
    externalLink?: boolean,
    toggles?: string | Array<string>
): string =>
    (externalLink === true
        ? get(window.CONFIG, ["host", "basename"], "")
        : "") +
    (mini === true ? "/mini" : "") +
    (mini === true ? "/flat?" : "/mail?") + // There is currently NO mini view for mail so we use flat
    queryString.stringify({
        uri: shortItemUri(itemUri),
        sp: get(window.CONFIG, ["general", "secure_params"], undefined),
        toggles:
            toggles != null && Array.isArray(toggles)
                ? toggles.join(",")
                : toggles,
    });

const viewNameTypeRouteMapping = {
    [ViewNameType.folder]: folderRoute,
    [ViewNameType.mailbox]: mailRoute,
    [ViewNameType.flat]: flatRoute,
};

export /**
 * dynamically figures out which route (and view) to use.
 * if no sites configuration available, will always return a folder route link
 * @param {{string}} itemUri item's itemURi
 * @param {{boolean}} [isDoc] whether item is a document or folder, defaults to folder (false)
 * @param {{boolean}} [isMini] whether route should be opened in mini mode
 * @param {{boolean}} [isExternalLink] whether link will be used for external new browser
 * @param {{string | Array<string>}} [toggles] whether link will be used for external new browser
 * @returns {string} route link
 */
const getRoute = ({
    itemUri,
    isDoc = false,
    isMini = null,
    isExternalLink = false,
    toggles = null,
}: {
    itemUri: string,
    isDoc?: boolean,
    isMini?: boolean,
    isExternalLink?: boolean,
    toggles?: string | Array<string>,
}): string => {
    // no mini parameter passed on? figure it out
    /* istanbul ignore else */
    if (isMini == null) {
        isMini = isMiniView(window.location);
    }

    const props = [itemUri, isMini, isExternalLink, toggles];

    if (isDoc === true) return docRoute(...props);

    const sites = cfg.sitesSelector();
    if (sites != null) {
        // sites sorted by itemUri.length descending
        const sitesOrdered = sites
            .slice(0)
            .sort(
                (a: SidebarItemDTO, b: SidebarItemDTO) =>
                    b.itemUri.length - a.itemUri.length
            );
        // do we have an exact match?
        const orderedIndex = sitesOrdered.findIndex(
            (orderedSite) => orderedSite.itemUri === itemUri
        );
        // ...then take it
        if (orderedIndex !== -1) {
            return viewNameTypeRouteMapping[
                sitesOrdered[orderedIndex].viewName
            ](...props);
        } else {
            // find closest neighbour
            const orderedIndex2 = sitesOrdered.findIndex(
                (orderedSite) => itemUri.indexOf(orderedSite.itemUri) === 0
            );
            if (orderedIndex2 !== -1) {
                return viewNameTypeRouteMapping[
                    sitesOrdered[orderedIndex2].viewName
                ](...props);
            }
        }
    }
    //if uri does not exist at all in our sidebar
    return folderRoute(...props);
};

/**
 * React component to render an HTML <A> tag with a link
 *
 * @param {{ name: string, itemUri: string }} { name, itemUri }
 * @returns HTML <A> component
 */
const BrowserLink = ({
    name,
    itemUri,
    isDoc,
}: {
    name: string,
    itemUri: string,
    isDoc?: boolean,
}) => (
    <a
        href={
            window.location.origin +
            getRoute({ itemUri, isMini: true, isExternalLink: true, isDoc })
        }
        target="_BLANK"
        rel="noopener noreferrer"
    >
        {name}
    </a>
);
BrowserLink.displayName = "BrowserLink";

export /**
 * returns an HTML rendered link to open an inPoint.Web route link
 *
 * @param {string} name text to display as link name
 * @param {string} itemUri itemUri
 * @param {?boolean} itemUri itemUri
 * @returns {string} HTML rendering of link
 */
const getBrowserLinkHtml = (
    name: string,
    itemUri: string,
    isDoc: ?boolean
): string => {
    const link = <BrowserLink name={name} itemUri={itemUri} isDoc={isDoc} />;
    return ReactDOMServer.renderToStaticMarkup(link);
};

export const BrowserDragLink = ({
    className,
    itemUri,
    children,
}: {
    className: ?string,
    itemUri: string,
    children: any,
}) =>
    isSecureParams(window.location) ? (
        <span className={className}>{children}</span>
    ) : (
        <a
            className={className}
            href={
                window.location.origin +
                getRoute({ itemUri, isMini: true, isExternalLink: true })
            }
            target="_BLANK"
            rel="noopener noreferrer"
            onClick={/* istanbul ignore next */ (e) => e.preventDefault()}
        >
            {children}
        </a>
    );
BrowserDragLink.displayName = "BrowserDragLink";
BrowserDragLink.propTypes = {
    itemUri: PropTypes.string.isRequired,
};
