import React from "react";
import { type RasterMapLayerDTO } from "data/types";
import Button from "react-bootstrap/lib/Button";
import Icons from "@hs/icons";
import CommandActionHandler from "components/CommandActions";
import Thumbnail from "components/DocListSimple/Thumbnail";
import styles from "./RasterLayer.module.css";
import loc from "i18next";
import FormGroup from "react-bootstrap/lib/FormGroup";
import InputGroup from "react-bootstrap/lib/InputGroup";
import FormControl from "react-bootstrap/lib/FormControl";

const RasterLayer = ({
    layer,
    onRemove,
    onRename,
    onMoveUp,
    onMoveDown,
}: {
    layer: RasterMapLayerDTO,
    onRemove: () => void,
    onRename: (newName: string) => void,
    onMoveUp: () => void,
    onMoveDown: () => void,
}) => (
    <div className={styles.layerContainer}>
        <div className={styles.arrows}>
            <Button
                bsSize="small"
                className="btn-chrome"
                title={loc.t("common:raster.moveUp")}
                onClick={onMoveUp}
            >
                <Icons.Library name="arrow-up" fixedWidth />
            </Button>
            <Button
                bsSize="small"
                className="btn-chrome"
                title={loc.t("common:raster.moveDown")}
                onClick={onMoveDown}
            >
                <Icons.Library name="arrow-down" fixedWidth />
            </Button>
        </div>

        <div
            className={styles.thumb}
            title={loc.t("itemActions:doc_preview.name")}
        >
            <Thumbnail
                itemUri={layer.itemUri}
                style={{ maxWidth: 200, maxHeight: 200 }}
                onClick={
                    /* istanbul ignore next */ () =>
                        CommandActionHandler("doc_preview", {
                            itemUri: layer.itemUri,
                        })
                }
            />
        </div>

        <FormGroup style={{ display: "inline", whiteSpace: "nowrap" }}>
            <InputGroup>
                <InputGroup.Addon>
                    <Icons.Library name="fa-regular fa-language" />
                </InputGroup.Addon>
                <FormControl
                    type="text"
                    value={layer.name}
                    onChange={(e) => onRename(e.currentTarget.value)}
                    className={styles.name}
                />
                <Button
                    className={styles.btnDelete}
                    onClick={onRemove}
                    title={loc.t("common:delete")}
                >
                    <Icons.Library name="trash-can" fixedWidth />
                </Button>
            </InputGroup>
        </FormGroup>

        {/* <Button bsSize="small" className="btn-chrome" title="Reconvert image">
            <Icons.Library name="repeat" fixedWidth />
        </Button> */}
    </div>
);

RasterLayer.displayName = "RasterMapManager.RasterLayer";
export default RasterLayer;
