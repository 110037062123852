// @flow
import React, { useCallback } from "react";
import { useEffectOnce } from "hooks";
import { getDisplayName } from "data/constants";

type Props = {
    /** callback when user pressed the left arrow */
    onPrev?: () => void,
    /** callback when user pressed the right arrow */
    onNext?: () => void,
};

/** used by ImageViewer/InlineViewer to intercept left/right keyboard commands */
const ViewerKeysHandler = (WrappedComponent: ReactClass<any>): Node => {
    const HOC = (props: Props) => {
        const { onNext, onPrev } = props;
        if (typeof onNext !== "function" || typeof onPrev !== "function") {
            console.warn("Missing onNext/onPrev callback");
        }
        const handleKeyDown = useCallback(
            (e) => {
                // #43374 arrow keys during DocForm editing
                if (
                    e.target?.classList?.contains("form-control") ||
                    e.target?.tagName === "INPUT"
                )
                    return;

                const keyCode = e.keyCode || e.which || e.charCode;
                switch (keyCode) {
                    case 39: // key: →
                        /* istanbul ignore else */
                        if (typeof onNext === "function") {
                            e.preventDefault();
                            onNext();
                        }
                        break;
                    case 37: // key: ←
                        /* istanbul ignore else */
                        if (typeof onPrev === "function") {
                            e.preventDefault();
                            onPrev();
                        }
                        break;
                    /* istanbul ignore next */
                    default:
                        break;
                }
            },
            [onNext, onPrev]
        );

        useEffectOnce(() => {
            window.document.addEventListener("keydown", handleKeyDown, false);
            return () => {
                window.document.removeEventListener(
                    "keydown",
                    handleKeyDown,
                    false
                );
            };
        });
        return <WrappedComponent {...props} />;
    };
    HOC.displayName = `ViewerKeysHandler(${getDisplayName(WrappedComponent)})`;
    return HOC;
};

export default ViewerKeysHandler;
