// @flow
import React from "react";
import FormGroup from "react-bootstrap/lib/FormGroup";
import InputGroup from "react-bootstrap/lib/InputGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import Icons from "@hs/icons";
import loc from "i18next";
import Button from "react-bootstrap/lib/Button";
import { ParseRaster } from "components/MapViewer/utils";
import { openMapModal } from "components/MapModal";
import styles from "./GeoRasterInput.module.css";
import { getGeoRasterProvider } from "components/MapViewer/providerSupport";
import { type MarkerDTO } from "data/types";
import { getClassNames } from "data/utils";

type Props = {
    /** current itemUri */
    itemUri: string,
    /** current formatId */
    formatId: number,
    /** displayName of field */
    label: ?string,
    /** current location in string format */
    value: ?string,
    /** whether to show an icon before the text field */
    showIcon: boolean,
    /** callback when user sets location
     * @param {string} value new location in string format
     */
    onChange: (value: string) => void,
    /** optional callback when user wants to manually set location on map
     * if defined this needs to take of calling openMapModal!
     */
    onSetLocationManually?: () => void,
    /** callback when user sets location by map
     * only needed if onSetLocationManually is not defined
     * @param {string} editLocationResult location set
     */
    onManualLocation?: (editLocationResult: string) => void,
    /** optional CSS class to apply to root element */
    className?: string,
    /** placeholder text to display when @see {value} is empty */
    placeholder?: string,
    /** optional (custom) location marker icon */
    marker?: MarkerDTO,
    /** optional id to use for the input element */
    inputId?: string,
};

type State = {
    value: ?string,
};

class GeoRasterInput extends React.PureComponent<Props, State> {
    static defaultProps = {
        showIcon: true,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    /** change location input text value */
    changeValue = (e) =>
        this.setState({ value: e.target.value }, () =>
            this.props.onChange(this.state.value)
        );

    /** show map and allow user to change marker's location */
    _setLocationManually = () => {
        const mapProvider = getGeoRasterProvider(
            this.props.itemUri,
            this.props.formatId
        );
        /* istanbul ignore next */
        if (mapProvider == null || mapProvider.isRaster !== true) {
            alert(
                "Missing Raster configuration - do not know which Raster map to display!"
            );
            return;
        }
        openMapModal({
            itemUri: this.props.itemUri,
            title: this.props.label,
            usePlaceInput: false,
            mapProvider,
            useMarker: true,
            locations: [
                Object.assign(
                    {},
                    ParseRaster(
                        this.state.value != null &&
                            String(this.state.value).length > 0
                            ? this.state.value
                            : "" // only MapViewer knows the real layer ids
                    ),
                    {
                        label: /* istanbul ignore next*/ this.props.label || "",
                        icon: this.props.marker,
                    }
                ),
            ],
            onSet: (editLocationResult: string) =>
                this.props.onManualLocation(editLocationResult),
        });
    };

    render = () => (
        <FormGroup
            className={getClassNames(styles.container, this.props.className)}
        >
            <InputGroup className={styles.inputGroup}>
                {this.props.showIcon && (
                    <InputGroup.Addon className={styles.iconWrapper}>
                        <Icons.Library name="location-dot" fixedWidth />
                    </InputGroup.Addon>
                )}
                <FormControl
                    type="text"
                    value={this.state.value || ""}
                    onChange={this.changeValue}
                    placeholder={loc.t("common:location.notSet")}
                    id={this.props.inputId}
                />
                <InputGroup.Addon className={styles.buttonsWrapper}>
                    <Button
                        title={loc.t("common:location.setManual")}
                        onClick={
                            this.props.onSetLocationManually ||
                            this._setLocationManually
                        }
                        className={styles.button}
                    >
                        <Icons.Library fixedWidth name="map-pin" />
                    </Button>
                </InputGroup.Addon>
                {this.props.children}
            </InputGroup>
        </FormGroup>
    );
}
export default GeoRasterInput;
