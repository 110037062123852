// @flow
import React from "react";
import { type ItemFlowConfigDTO } from "data/types";
import Icons from "@hs/icons";

const FlowCardImage = ({ flow }: { flow: ItemFlowConfigDTO }) => {
    if (
        flow.icon &&
        (flow.icon.startsWith("http") ||
            flow.icon.startsWith("data:image") ||
            flow.icon.startsWith("/") ||
            flow.icon.startsWith("./") ||
            flow.icon.startsWith("../"))
    )
        return <img src={flow.icon} alt="" style={{ width: 66, height: 66 }} />;

    switch (flow.commandAction.name) {
        case "itemFlow_folder":
            return <Icons.ElementType elementType={parseInt(flow.icon, 10)} />;
        case "itemFlow_docTemplate":
            if (flow.icon.startsWith(".")) {
                return <Icons.FileExt ext={flow.icon} />;
            } else
                return (
                    <Icons.Library
                        name="fa-regular fa-file"
                        className="image-bg fa-5x"
                    />
                );
        case "itemFlow_folderTemplate":
            return (
                <Icons.Library
                    name="fa-regular fa-folder"
                    className="image-bg fa-5x"
                />
            );

        default:
            return (
                <Icons.Library
                    name={flow.icon || "fa-regular fa-file"}
                    className="image-bg fa-5x"
                />
            );
    }
};
FlowCardImage.displayName = "ItemFlows.FlowCardImage";
export default FlowCardImage;
