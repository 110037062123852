// @flow
import {
    type ItemActionsConfigDTO,
    ItemLinkType,
    SearchItemType,
} from "data/types";

// this file defines all the system pre-defined actionPanel commandActions available
/* istanbul ignore next */
export const getItemActionsConfig = (): ItemActionsConfigDTO => ({
    categories: [
        {
            id: "filter",
            name: "$actionCategory:filter.name",
            icon: "filter",
            shouldBeVisible: [
                "isAnyOnlineStatus",
                "isAnySelection",
                "hasRowAndColId",
            ],
            order: 10,
        },
        {
            id: "multiSelection",
            name: "$multiselection:name",
            icon: "list-check",
            shouldBeVisible: [
                "isAnyOnlineStatus",
                "isAnySelection",
                "hasContext:multiSelection",
            ],
            order: 20,
        },
        {
            id: "general",
            name: "$actionCategory:general.name",
            icon: "fa-regular fa-hand-point-right",
            shouldBeVisible: ["isAnyOnlineStatus", "isAnySelection"],
            order: 30,
        },
        {
            id: "folder",
            name: "$actionCategory:folder.name",
            icon: "fa-regular fa-folder",
            shouldBeVisible: [
                "isFolder",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            order: 40,
        },
        {
            id: "doc",
            name: "$actionCategory:doc.name",
            icon: "fa-regular fa-file-lines",
            shouldBeVisible: ["isDoc", "isAnyOnlineStatus", "isAnySelection"],
            order: 50,
        },
        {
            id: "doclist",
            name: "$actionCategory:doclist.name",
            icon: "fa-regular fa-files",
            shouldBeVisible: ["isAnyOnlineStatus", "isAnySelection"],
            order: 60,
        },
        {
            id: "wf",
            name: "$actionCategory:wf.name",
            icon: "fa-regular fa-square-check",
            shouldBeVisible: ["!hasContext:isFlatAU", "isWorkflowEnabled"],
            order: 70,
        },
        {
            id: "reminder",
            name: "$actionCategory:reminder.name",
            icon: "fa-regular fa-clock",
            order: 80,
        },
        {
            id: "history",
            name: "Activities",
            expanded: true,
            shouldBeVisible: ["hasContext:workflow", "isAnyOnlineStatus"],
            order: 90,
        },
        {
            id: "sync",
            name: "$actionCategory:sync.name",
            icon: "arrow-up-right-from-square",
            shouldBeVisible: ["isSyncEnabled", "isAnySelection"],
            order: 100,
        },
        {
            id: "clipboard",
            name: "$actionCategory:clipboard.name",
            icon: "fa-regular fa-clipboard",
            shouldBeVisible: ["isValidItemUri", "isAnyOnlineStatus"],
            order: 110,
        },
        {
            name: "$actionCategory:others.name",
            shouldBeVisible: ["isAnyOnlineStatus"],
            order: 120,
        },
        {
            id: "recyclebin",
            name: "$actionCategory:recyclebin",
            icon: "trash-can",
            shouldBeVisible: ["isAnySelection", "isRecycleBin"],
            order: 130,
        },
        {
            id: "offline",
            name: "$actionCategory:offline",
            shouldBeVisible: [
                "hasFeature:offline",
                "isAnySelection",
                "isAnyOnlineStatus",
            ],
            order: 140,
        },
    ],
    actions: [
        {
            id: "folder_activities",
            category: "history",
            views: ["home", "wf"],
            shouldBeVisible: ["isFolder", "isValidItemUri"],
            commandAction: {
                name: "folder_preview",
            },
            view: {
                name: "itemView_activityList",
                isDoc: false,
                showAddNote: false,
                showHistory: false,
                showNotes: true,
                showRetentionInfo: false,
            },
        },
        {
            id: "doc_activities",
            category: "history",
            shouldBeVisible: ["isDoc", "isValidItemUri"],
            views: ["home", "wf", "news"],
            commandAction: {
                name: "doc_preview",
            },
            view: {
                name: "itemView_activityList",
                isDoc: true,
                showAddNote: false,
                showHistory: true,
                showNotes: true,
            },
        },
        {
            id: "doclist_exportCsv",
            category: "folder",
            name: "$itemActions:doclist_exportCsv.name",
            icon: "fa-regular fa-file-excel",
            views: ["flat", "folder", "miniflat"],
            commandAction: { name: "doclist_exportCsv" },
        },
        {
            id: "doclist_filters",
            category: "filter",
            views: ["flat", "folder", "miniflat", "mailbox"],
            view: {
                name: "itemView_filter",
                actions: [
                    "filterByValue",
                    "filterAddValue",
                    "filterRemove",
                    "reset",
                ],
            },
            shouldBeVisible: ["hasRowAndColId"],
            commandAction: {}, //HACK: persist props to itemView
        },
        {
            id: "doc_preview",
            category: "doc",
            name: "$itemActions:doc_preview.name",
            icon: "eye",
            views: [
                "flat",
                "folder",
                "searchresult",
                "news",
                "home",
                "wf",
                "offline_item",
            ],
            shouldBeVisible: ["isAnyOnlineStatus"],
            commandAction: {
                name: "doc_preview",
            },
        },
        {
            id: "doc_popout",
            category: "doc",
            name: "$itemActions:doc_popout.name",
            icon: "arrow-up-right-from-square",
            views: [
                "flat",
                "folder",
                "searchresult",
                "docmodal",
                "mailbox",
                "news",
                "home",
                "wf",
                "offline_item",
            ],
            shouldBeVisible: ["isAnyOnlineStatus"],
            commandAction: {
                name: "doc_preview",
                props: {
                    newWindow: true,
                },
            },
        },
        {
            id: "doc_print",
            category: "doc",
            name: "$itemActions:doc_print.name",
            icon: "print",
            views: ["doc", "docmodal"],
            shouldBeVisible: ["isAnyOnlineStatus", "isAnyPdf"],
            commandAction: {
                name: "doc_print",
            },
        },
        {
            id: "doc_printAny",
            category: "doc",
            name: "$itemActions:doc_print.name",
            icon: "print",
            views: ["doc", "docmodal"],
            shouldBeVisible: ["isAnyOnlineStatus", "!isAnyPdf"],
            commandAction: {
                name: "doc_printMultiple",
            },
        },
        {
            id: "doc_printMultiple",
            category: "doc",
            name: "$itemActions:doc_print.name",
            icon: "print",
            views: [
                "flat",
                "folder",
                "mailbox",
                "searchresult",
                "home",
                "news",
            ],
            shouldBeVisible: ["isAnySelection", "isAnyOnlineStatus"],
            commandAction: {
                name: "doc_printMultiple",
            },
        },
        {
            id: "doc_lock",
            category: "doc",
            name: "$itemActions:doc_lock.name",
            icon: "lock",
            views: ["flat", "folder", "searchresult", "docmodal", "document"],
            shouldBeVisible: [
                "!hasItemStatus:Locked",
                "hasContext:isDocEditAllowed",
                "isAnySelection",
            ],
            commandAction: {
                name: "lock",
            },
        },
        {
            id: "doc_unlock",
            category: "doc",
            name: "$itemActions:doc_unlock.name",
            icon: "lock-open",
            views: ["flat", "folder", "searchresult", "docmodal", "document"],
            shouldBeVisible: [
                "hasItemStatus:Locked",
                "hasContext:isDocEditAllowed",
                "isAnySelection",
            ],
            commandAction: {
                name: "unlock",
            },
        },
        {
            id: "folder_lock",
            visible: false,
            category: "folder",
            name: "$itemActions:folder_lock.name",
            icon: "lock",
            views: ["flat", "folder", "searchresult"],
            shouldBeVisible: [
                "!hasItemStatus:Locked",
                "hasContext:isEditAllowed",
                "isAnySelection",
            ],
            commandAction: {
                name: "lock",
            },
        },
        {
            id: "folder_unlock",
            visible: false,
            category: "folder",
            name: "$itemActions:folder_unlock.name",
            icon: "lock-open",
            views: ["flat", "folder", "searchresult"],
            shouldBeVisible: [
                "hasItemStatus:Locked",
                "hasContext:isEditAllowed",
                "isAnySelection",
            ],
            commandAction: {
                name: "unlock",
            },
        },
        {
            id: "folder_fav_add",
            category: "folder",
            // name: "Add as Favorite",
            name: "$itemActions:fav_add.name",
            icon: "fa-regular fa-star",
            shouldBeVisible: [
                "!isFav",
                "isValidItemUri",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: {
                name: "favorite",
                props: {
                    isFav: false,
                },
            },
        },
        {
            id: "folder_fav_remove",
            category: "folder",
            name: "$itemActions:fav_remove.name",
            // name: "Remove from Favorites",
            icon: "star",
            shouldBeVisible: [
                "isFav",
                "isValidItemUri",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: {
                name: "favorite",
                props: {
                    isFav: true,
                },
            },
        },
        {
            id: "doc_fav_add",
            category: "doc",
            // name: "Add as Favorite",
            name: "$itemActions:fav_add.name",
            icon: "fa-regular fa-star",
            shouldBeVisible: [
                "!isFav",
                "isValidItemUri",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: {
                name: "favorite",
                props: {
                    isFav: false,
                },
            },
        },
        {
            id: "doc_fav_remove",
            category: "doc",
            name: "$itemActions:fav_remove.name",
            // name: "Remove from Favorites",
            icon: "star",
            shouldBeVisible: [
                "isFav",
                "isValidItemUri",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: {
                name: "favorite",
                props: {
                    isFav: true,
                },
            },
        },
        {
            id: "doc_share_web",
            category: "doc",
            name: "$itemActions:share_web.name",
            icon: "share-nodes",
            shouldBeVisible: [
                "hasBrowser_webShareApi",
                "isValidItemUri",
                "!hasSiteType:SharedSites",
                "isAnyOnlineStatus",
            ],
            commandAction: {
                name: "share_web",
            },
        },
        {
            id: "folder_share_web",
            category: "folder",
            name: "$itemActions:share_web.name",
            icon: "share-nodes",
            shouldBeVisible: [
                "hasBrowser_webShareApi",
                "isValidItemUri",
                "!hasSiteType:SharedSites",
                "isAnyOnlineStatus",
            ],
            commandAction: {
                name: "share_web",
            },
        },
        {
            id: "doc_share",
            category: "doc",
            name: "$itemActions:share.name",
            icon: "share-nodes",
            shouldBeVisible: [
                "hasContext:canBeShared",
                "isValidItemUri",
                "isAnySelection",
            ],
            commandAction: {
                name: "share",
            },
        },
        {
            id: "folder_share",
            category: "folder",
            name: "$itemActions:share.name",
            icon: "share-nodes",
            shouldBeVisible: [
                "hasContext:canBeShared",
                "isValidItemUri",
                "isAnySelection",
            ],
            commandAction: {
                name: "share",
            },
        },
        {
            id: "folder_share_edit",
            category: "folder",
            name: "$itemActions:share_edit.name",
            icon: "square-share-nodes",
            shouldBeVisible: [
                "hasContext:canBeShared",
                "isValidItemUri",
                "hasItemStatus:Share",
            ],
            commandAction: {
                name: "share",
                props: {
                    isEdit: true,
                },
            },
        },
        {
            id: "doc_share_edit",
            category: "doc",
            name: "$itemActions:share_edit.name",
            icon: "square-share-nodes",
            shouldBeVisible: [
                "hasContext:canBeShared",
                "isValidItemUri",
                "hasItemStatus:Share",
            ],
            commandAction: {
                name: "share",
                props: {
                    isEdit: true,
                },
            },
        },
        {
            id: "search_favorite_add",
            category: "general",
            // name: "Add as Favorite",
            name: "$itemActions:fav_add.name",
            icon: "fa-regular fa-star",
            shouldBeVisible: [
                "hasContext:searchRequest",
                `!hasSearchType:${SearchItemType.Favorite}`,
            ],
            commandAction: {
                name: "search_favorite",
            },
        },
        {
            id: "search_favorite_remove",
            category: "general",
            name: "$itemActions:fav_remove.name",
            // name: "Remove from Favorites",
            icon: "star",
            shouldBeVisible: [
                "hasContext:searchRequest",
                `hasSearchType:${SearchItemType.Favorite}`,
            ],
            commandAction: {
                name: "search_favorite",
            },
        },
        {
            id: "search_preview",
            category: "general",
            name: "$itemActions:search_preview.name",
            icon: "eye",
            views: ["home"],
            shouldBeVisible: ["hasContext:searchRequest"],
            commandAction: {
                name: "search_preview",
            },
        },
        {
            id: "search_popout",
            category: "general",
            name: "$itemActions:search_popout.name",
            icon: "arrow-up-right-from-square",
            views: ["home"],
            shouldBeVisible: ["hasContext:searchRequest"],
            commandAction: {
                name: "search_preview",
                props: {
                    newWindow: true,
                },
            },
        },
        {
            id: "doc_subscribe",
            category: "doc",
            name: "$itemActions:subscribe.name",
            icon: "rss",
            views: ["flat", "folder", "searchresult", "docmodal", "document"],
            shouldBeVisible: [
                "isValidItemUri",
                "!hasContext:isFlatAU",
                "!hasItemStatus:Subscribed",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: {
                name: "subscribe",
            },
        },
        {
            id: "folder_subscribe",
            category: "folder",
            name: "$itemActions:subscribe.name",
            icon: "rss",
            views: ["flat", "folder", "searchresult"],
            shouldBeVisible: [
                "isValidItemUri",
                "!hasContext:isFlatAU",
                "!hasItemStatus:Subscribed",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: {
                name: "subscribe",
            },
        },
        {
            id: "doc_unsubscribe",
            category: "doc",
            name: "$itemActions:unsubscribe.name",
            icon: "rss",
            views: [
                "flat",
                "folder",
                "searchresult",
                "docmodal",
                "news",
                "document",
            ],
            shouldBeVisible: [
                "hasItemStatus:Subscribed",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: {
                name: "unsubscribe",
            },
        },
        {
            id: "folder_unsubscribe",
            category: "folder",
            name: "$itemActions:unsubscribe.name",
            icon: "rss",
            views: ["flat", "folder", "searchresult", "news"],
            shouldBeVisible: [
                "hasItemStatus:Subscribed",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: {
                name: "unsubscribe",
            },
        },
        {
            id: "folder_transfer",
            category: "folder",
            name: "$itemActions:transfer.name",
            icon: "arrow-right-arrow-left",
            views: ["flat", "folder", "searchresult"],
            shouldBeVisible: ["isValidItemUri"],
            commandAction: {
                name: "transfer",
            },
            visible: false,
        },
        {
            id: "folder_move",
            category: "folder",
            name: "$itemActions:transfer.move",
            icon: "arrow-right-arrow-left",
            views: ["folder", "searchresult"],
            shouldBeVisible: [
                "isValidItemUri",
                "!hasContext:isFlatAU",
                "isAnySelection",
            ],
            commandAction: {
                name: "transfer",
                props: {
                    type: "move",
                },
            },
        },
        {
            id: "folder_link",
            category: "folder",
            name: "$itemActions:transfer.link",
            icon: "link",
            views: ["folder", "searchresult"],
            shouldBeVisible: [
                "isValidItemUri",
                "!hasContext:isFlatAU",
                "isAnySelection",
            ],
            commandAction: {
                name: "transfer",
                props: {
                    type: "link",
                },
            },
        },
        {
            id: "doc_transfer",
            category: "doc",
            name: "$itemActions:transfer.name",
            icon: "arrow-right-arrow-left",
            views: ["flat", "folder", "searchresult"],
            shouldBeVisible: ["isValidItemUri"],
            commandAction: {
                name: "transfer",
            },
            visible: false,
        },
        {
            id: "doc_move",
            category: "doc",
            name: "$itemActions:transfer.move",
            icon: "arrow-right-arrow-left",
            views: ["folder", "searchresult", "docmodal", "document"],
            shouldBeVisible: [
                "isValidItemUri",
                "!hasContext:isFlatAU",
                "isAnySelection",
            ],
            commandAction: {
                name: "transfer",
                props: {
                    type: "move",
                },
            },
        },
        {
            id: "doc_copy",
            category: "doc",
            name: "$itemActions:transfer.copy",
            icon: "fa-regular fa-files",
            views: ["folder", "searchresult", "docmodal", "document"],
            shouldBeVisible: [
                "isValidItemUri",
                "!hasContext:isFlatAU",
                "isAnySelection",
            ],
            commandAction: {
                name: "transfer",
                props: {
                    type: "copy",
                },
            },
        },
        {
            id: "doc_link",
            category: "doc",
            name: "$itemActions:transfer.link",
            icon: "link",
            views: ["folder", "searchresult", "docmodal", "document"],
            shouldBeVisible: [
                "isValidItemUri",
                "!hasContext:isFlatAU",
                "isAnySelection",
            ],
            commandAction: {
                name: "transfer",
                props: {
                    type: "link",
                },
            },
        },
        {
            id: "reminder_manage",
            category: "reminder",
            shouldBeVisible: ["isValidItemUri"],
            view: {
                name: "itemView_reminder",
            },
            commandAction: {
                name: "reminder_manage",
            },
        },
        {
            id: "reminder_add",
            category: "reminder",
            name: "$itemActions:reminder_add.name",
            icon: "fa-regular fa-clock",
            shouldBeVisible: ["isValidItemUri"],
            commandAction: {
                name: "reminder_manage",
            },
        },
        {
            id: "folder_preview",
            category: "folder",
            name: "$itemActions:folder_preview.name",
            icon: "eye",
            views: [
                "searchresult",
                "news",
                "home",
                "document",
                "wf",
                "offline_item",
            ],
            shouldBeVisible: [
                "isValidItemUri",
                "!isMiniView",
                "!hasContext:isFlatAU",
            ],
            commandAction: {
                name: "folder_preview",
            },
        },
        {
            id: "folder_popout",
            category: "folder",
            name: "$itemActions:folder_popout.name",
            icon: "arrow-up-right-from-square",
            views: [
                "searchresult",
                "news",
                "home",
                "document",
                "wf",
                "offline_item",
            ],
            shouldBeVisible: [
                "isValidItemUri",
                "isAnyOnlineStatus",
                "!isMiniView",
                "!hasContext:isFlatAU",
            ],
            commandAction: {
                name: "folder_preview",
                props: {
                    newWindow: true,
                },
            },
        },
        {
            id: "doc_download",
            category: "doc",
            name: "$itemActions:doc_download.name",
            // name: "Download",
            icon: "download",
            shouldBeVisible: [
                "hasContext:isDocDownloadAllowed",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: {
                name: "doc_download",
            },
        },
        {
            id: "doc_downloadZip",
            category: "doc",
            name: "$itemActions:doc_downloadZip.name",
            // name: "Download as ZIP",
            icon: "fa-regular fa-file-zip",
            shouldBeVisible: [
                "hasContext:isDocDownloadAllowed",
                "isAnyOnlineStatus",
                "isMultiSelection",
            ],
            commandAction: {
                name: "doc_downloadZip",
            },
        },
        {
            id: "doc_downloadFilesAPI",
            category: "doc",
            name: "$itemActions:doc_downloadFilesAPI.name",
            // name: "Download as ZIP",
            icon: "fa-regular fa-folder-open",
            shouldBeVisible: [
                "hasContext:isDocDownloadAllowed",
                "isAnyOnlineStatus",
                "isMultiSelection",
            ],
            commandAction: {
                name: "doc_downloadFilesAPI",
            },
        },
        {
            id: "doc_editWebDav",
            category: "doc",
            name: "$itemActions:doc_editWebDav.name",
            icon: "pencil",
            views: ["folder", "document", "docmodal", "flat", "home"],
            shouldBeVisible: [
                "hasContext:isDocEditAllowed",
                "!hasItemStatus:CloudSync",
            ],
            commandAction: {
                name: "doc_editWebDav",
            },
        },
        {
            id: "doc_editCloud",
            category: "doc",
            name: "$itemActions:doc_editCloud.name",
            icon: "pencil",
            views: ["folder", "document", "docmodal", "flat", "home"],
            shouldBeVisible: [
                "hasContext:isDocEditAllowed",
                "hasItemStatus:CloudSync",
            ],
            commandAction: {
                name: "doc_editCloud",
                props: {
                    onlineEditor: true,
                },
            },
        },
        {
            id: "doc_editCloudLocal",
            category: "doc",
            name: "$itemActions:doc_editCloudLocal.name",
            icon: "pencil",
            views: ["folder", "document", "docmodal", "flat", "home"],
            shouldBeVisible: [
                "hasContext:isDocEditAllowed",
                "hasItemStatus:CloudSync",
            ],
            commandAction: {
                name: "doc_editCloud",
                props: {
                    onlineEditor: false,
                },
            },
        },
        {
            id: "doc_delete",
            category: "doc",
            name: "$itemActions:doc_delete.name",
            icon: "trash-can",
            views: ["folder", "document", "docmodal", "flat"],
            shouldBeVisible: [
                "isAnyOnlineStatus",
                "isAnySelection",
                "hasContext:isDocEditAllowed",
            ],
            commandAction: { name: "doc_delete" },
        },
        {
            id: "folder_delete",
            category: "folder",
            name: "$itemActions:folder_delete.name",
            icon: "trash-can",
            views: ["folder"],
            shouldBeVisible: [
                "hasContext:isEditAllowed",
                "licenseAllowEdit",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: { name: "folder_delete" },
        },
        {
            id: "folder_editClassification",
            category: "folder",
            name: "$itemActions:folder_editClassification.name",
            icon: "pen-to-square",
            views: ["folder"],
            shouldBeVisible: [
                "hasContext:isEditAllowed",
                "hasContext:isFolderClassificationEditAllowed",
                "licenseAllowEdit",
                "isAnyOnlineStatus",
            ],
            commandAction: { name: "folder_editClassification" },
        },
        {
            id: "doc_previewAndEditClassification",
            category: "doc",
            name: "$itemActions:doc_editClassification.name",
            icon: "pen-to-square",
            views: ["flat", "folder", "searchresult"],
            shouldBeVisible: [
                "hasContext:isDocEditAllowed",
                "hasContext:isDocClassificationEditAllowed",
                "licenseAllowEdit",
                "isAnyOnlineStatus",
            ],
            commandAction: {
                name: "doc_preview",
                props: {
                    triggerEditMode: true,
                },
            },
        },
        {
            id: "doc_editClassification",
            category: "doc",
            name: "$itemActions:doc_editClassification.name",
            icon: "pen-to-square",
            views: ["document", "docmodal"],
            shouldBeVisible: [
                "hasContext:isDocEditAllowed",
                "hasContext:isDocClassificationEditAllowed",
                "licenseAllowEdit",
                "isAnyOnlineStatus",
            ],
            commandAction: { name: "doc_editClassification" },
        },
        {
            id: "doc_uploadVersion",
            category: "doc",
            name: "$itemActions:doc_uploadVersion.name",
            icon: "upload",
            views: ["document", "docmodal", "folder", "flat"],
            shouldBeVisible: [
                "hasContext:isDocEditAllowed",
                "licenseAllowEdit",
                "!isSyncUpload",
                "isAnyOnlineStatus",
            ],
            commandAction: { name: "doc_uploadVersion" },
        },
        {
            id: "doc_upload",
            category: "folder",
            name: "$itemActions:doc_upload.name",
            icon: "upload",
            views: ["folder", "flat", "miniflat"],
            shouldBeVisible: [
                "hasContext:isDocAssignmentAllowed",
                "licenseAllowEdit",
                "!isSyncUpload",
                "isAnyOnlineStatus",
            ],
            commandAction: {
                name: "itemFlows",
                props: {
                    direction: "in",
                    flowId: "doc_upload",
                },
            },
        },
        {
            id: "itemFlows_input",
            category: "folder",
            name: "$itemActions:itemFlows_input.name",
            icon: "circle-plus",
            views: ["folder"],
            shouldBeVisible: ["isCreateAllowed", "isAnyOnlineStatus"],
            commandAction: {
                name: "itemFlows",
                props: {
                    direction: "in",
                },
            },
        },
        {
            id: "offline_add",
            icon: "power-off",
            category: "folder",
            name: "$itemActions:offline_add.name",
            shouldBeVisible: [
                "isValidItemUri",
                "!hasContext:isOffline",
                "hasFeature:offline",
                "isAnySelection",
            ],
            commandAction: {
                name: "offline_add",
            },
        },
        {
            id: "offline_del",
            icon: "power-off",
            category: "folder",
            name: "$itemActions:offline_delete.name",
            shouldBeVisible: [
                "hasContext:isOffline",
                "hasFeature:offline",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: {
                name: "offline_delete",
            },
        },
        {
            id: "offline_syncItem",
            icon: "cloud-arrow-down",
            category: "folder",
            name: "$itemActions:offline_syncItem.name",
            shouldBeVisible: ["hasContext:isOffline", "hasFeature:offline"],
            views: ["offline_item"],
            commandAction: {
                name: "offline_syncItem",
            },
        },
        {
            id: "clipboard_copyItemUri",
            category: "clipboard",
            name: "$itemActions:clipboard_copyItemUri.name",
            icon: "fa-regular fa-clipboard",
            views: ["flat", "folder", "docmodal", "mailbox", "offline_item"],
            shouldBeVisible: ["isAdmin", "isAnyOnlineStatus"],
            commandAction: {
                name: "clipboard_copyItemUri",
            },
        },
        {
            id: "clipboard_copyItemLink",
            category: "clipboard",
            name: "$itemActions:clipboard_copyItemLink.name",
            icon: "fa-regular fa-clipboard",
            views: ["flat", "folder", "docmodal", "mailbox", "offline_item"],
            shouldBeVisible: ["isAnyOnlineStatus"],
            commandAction: {
                name: "clipboard_copyItemLink",
            },
        },
        {
            id: "wf_report",
            category: "wf",
            name: "$itemActions:wf_report.name",
            //desc: "Displays workflow report(s)",
            icon: "book",
            // views: ["document", "docmodal", "folder", "miniflat"],
            shouldBeVisible: ["isWorkflowEnabled", "hasItemStatus:WFReport"],
            commandAction: {
                name: "doc_related",
                props: {
                    itemLinkType: ItemLinkType.WorkflowReport,
                },
            },
        },
        {
            id: "wf_start",
            category: "wf",
            name: "$itemActions:wf_start.name",
            //desc: "Starts a workflow",
            icon: "fa-regular fa-square-check",
            views: [
                "home",
                "news",
                "document",
                "docmodal",
                "folder",
                "miniflat",
            ],
            shouldBeVisible: [
                // "hasContext:isDocEditAllowed",
                "isWorkflowEnabled",
            ],
            commandAction: {
                name: "wf_start",
            },
        },
        {
            id: "wf_info",
            category: "wf",
            shouldBeVisible: [
                "isValidItemUri",
                // "hasContext:isDocEditAllowed",
                "isWorkflowEnabled",
            ],
            views: [
                "home",
                "folder",
                "document",
                "docmodal",
                "flat",
                "miniflat",
                "searchresult",
                "wf",
            ],
            commandAction: {
                name: "link",
                props: {
                    asModal: true,
                    docked: true,
                },
            },
            view: {
                name: "itemView_workflow",
            },
        },
        {
            id: "wf_info_noitemuri",
            category: "wf",
            shouldBeVisible: [
                "!isValidItemUri",
                // "hasContext:isDocEditAllowed",
                "isWorkflowEnabled",
            ],
            views: ["wf"],
            commandAction: {
                name: "link",
                props: {
                    asModal: true,
                    docked: true,
                },
            },
            view: {
                name: "itemView_workflow",
            },
        },
        {
            id: "ip_archive",
            category: "sync",
            name: "$itemActions:ip_archive.name",
            // desc: "Launch inPoint.Sync ArchiveDialog",
            icon: "upload",
            shouldBeVisible: [
                "isWindows",
                "isSyncEnabled",
                "isFolder",
                "hasContext:isDocAssignmentAllowed",
            ],
            commandAction: { name: "ip_archive" },
        },
        {
            id: "ip_browse",
            category: "sync",
            name: "$itemActions:ip_browse.name",
            icon: "eye",
            // desc: "Browse in inPoint.Client",
            shouldBeVisible: ["isWindows", "isSyncEnabled", "isValidItemUri"],
            commandAction: { name: "ip_browse" },
        },
        {
            id: "ip_properties",
            category: "sync",
            name: "$itemActions:ip_properties.name",
            // desc: "Launch inPoint.Sync Properties dialog",
            icon: "tasks",
            shouldBeVisible: [
                "isWindows",
                "isSyncEnabled",
                "hasContext:isDocAssignmentAllowed",
                "!hasContext:isFlatAU",
            ],
            commandAction: { name: "ip_properties" },
        },
        {
            id: "ip_edit",
            category: "sync",
            name: "$itemActions:ip_edit.name",
            // desc: "Edit (in original application) with inPoint.Sync",
            icon: "pen-to-square",
            shouldBeVisible: [
                "isWindows",
                "isSyncEnabled",
                "isDoc",
                "hasContext:isDocAssignmentAllowed",
                "hasContext:isDocEditAllowed",
                "!hasContext:isFlatAU",
            ],
            commandAction: { name: "ip_edit" },
        },
        {
            id: "ip_commandAction_itemSendAsEmail",
            category: "sync",
            name: "$itemActions:ip_commandAction.itemSendAsEmail",
            icon: "fa-regular fa-envelope",
            shouldBeVisible: [
                "isAnySelection",
                "isWindows",
                "isSyncEnabled",
                "isDoc",
                "hasContext:isDocSendByEmailAllowed",
            ],
            commandAction: {
                name: "ip_commandAction",
                props: {
                    commandAction: "ItemSendAsEmailCommandAction",
                },
            },
        },
        {
            id: "ip_commandAction_itemSendAsEmailLink",
            category: "sync",
            name: "$itemActions:ip_commandAction.itemSendAsEmailLink",
            icon: "fa-regular fa-envelope",
            shouldBeVisible: [
                "isAnySelection",
                "isWindows",
                "isSyncEnabled",
                "isValidItemUri",
            ],
            commandAction: {
                name: "ip_commandAction",
                props: {
                    commandAction: "ItemSendAsEmailLinkCommandAction",
                },
            },
        },
        // ---- Recycle Bin
        {
            id: "doc_previewRecycleBin",
            category: "recyclebin",
            name: "$itemActions:doc_preview.name",
            icon: "eye",
            views: ["flat", "folder", "searchresult", "news", "home"],
            shouldBeVisible: ["isDoc", "isRecycleBin"],
            commandAction: {
                name: "doc_preview",
            },
        },
        {
            id: "doc_clear",
            category: "recyclebin",
            name: "$itemActions:doc_clear.name",
            icon: "trash-can",
            shouldBeVisible: [
                "isDoc",
                "hasItemStatus:Deleted",
                "isRecycleBin",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: { name: "recyclebin_docClear" },
        },
        {
            id: "doc_restore",
            category: "recyclebin",
            name: "$itemActions:doc_restore.name",
            icon: "repeat",
            shouldBeVisible: [
                "isDoc",
                "hasItemStatus:Deleted",
                "!hasItemStatus:ParentDeleted",
                "isRecycleBin",
                "isAnySelection",
            ],
            commandAction: { name: "recyclebin_docRestore" },
        },
        {
            id: "folder_clear",
            category: "recyclebin",
            name: "$itemActions:folder_clear.name",
            icon: "trash-can",
            shouldBeVisible: [
                "isFolder",
                "hasItemStatus:Deleted",
                "isRecycleBin",
                "isAnyOnlineStatus",
                "isAnySelection",
            ],
            commandAction: { name: "recyclebin_folderClear" },
        },
        {
            id: "folder_restore",
            category: "recyclebin",
            name: "$itemActions:folder_restore.name",
            icon: "repeat",
            shouldBeVisible: [
                "isFolder",
                "hasItemStatus:Deleted",
                "!hasItemStatus:ParentDeleted",
                "isRecycleBin",
                "isAnySelection",
            ],
            commandAction: { name: "recyclebin_folderRestore" },
        },
        {
            id: "multiSelection",
            category: "multiSelection",
            view: {
                name: "itemView_multiSelection",
            },
            shouldBeVisible: ["hasContext:multiSelection"],
            commandAction: {}, //HACK: persist props to itemView
        },
    ],
});
