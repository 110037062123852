// @flow
import React from "react";
import PropTypes from "prop-types";
import FormControl from "react-bootstrap/lib/FormControl";
import { getClassNames } from "data/utils";
import styles from "components/NativeSelect/NativeSelect.module.css";
import loc from "i18next";
import { components } from "react-select";
const { DownChevron } = components;

export const RelativeDate = [
    "NotSet",
    "Today",
    "Yesterday",
    "ThisWeek",
    "LastWeek",
    "ThisMonth",
    "LastMonth",
    "ThisYear",
    "LastYear",
];

const RelativeDatePicker = ({
    autoFocus,
    index,
    value,
    onChange,
    style,
    className,
    required,
    id,
}: {
    autoFocus?: boolean,
    index: number,
    value?: string,
    onChange: Function,
    style?: Object,
    className?: string,
    required?: boolean,
    id?: string,
}) => (
    <div className={styles.wrapper}>
        <FormControl
            autoFocus={autoFocus}
            componentClass="select"
            name={index}
            value={value}
            onChange={onChange}
            style={style}
            className={getClassNames(
                className,
                styles.select,
                required && value === "NotSet" && styles.placeholder
            )}
            id={id}
        >
            {RelativeDate.map((relDate, index) => {
                const props = {};
                if (index === 0 && required) {
                    props.disabled = true;
                }
                return (
                    <option key={index} value={relDate} {...props}>
                        {loc.t(
                            `common:filter.RelativeDate.${
                                props.disabled ? "Choose" : relDate
                            }`
                        )}
                    </option>
                );
            })}
        </FormControl>
        <DownChevron className={styles.icon} />
    </div>
);
RelativeDatePicker.displayName = "RelativeDatePicker";
RelativeDatePicker.propTypes = {
    autoFocus: PropTypes.bool,
    index: PropTypes.number.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
export default RelativeDatePicker;
