import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";
import { type searchResultState } from "./types";

export const initialState: searchResultState = {
    status: Constants.NONE,
    error: undefined,
    result: undefined,
    count: {
        status: Constants.NONE,
        totalCount: -1,
        totalTime: -1,
        aggregations: [],
    },
    form: {
        id: -1,
        status: Constants.NONE,
        result: undefined,
    },
    changes: {},
    searchRequest: undefined,
    searchItem: undefined,
    isValid: true,
};

const searchReducer = (state: searchResultState = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEARCH_RESET:
            return {
                ...state,
                status: Constants.NONE,
                result: undefined,
                count: {
                    status: Constants.NONE,
                    totalCount: -1,
                    totalTime: -1,
                    aggregations: [],
                },
                searchRequest: undefined,
                searchItem: undefined,
            };

        case actionTypes.SEARCH_FAVRENAME_REQUEST:
        case actionTypes.SEARCH_FAVRENAME_RESULT:
        case actionTypes.SEARCH_FETCH_REQUEST:
        case actionTypes.SEARCH_FETCH_RESULT:
        case actionTypes.SEARCH_ITEM_SAVE:
        case actionTypes.SEARCH_FORM_CHANGES:
        case actionTypes.SEARCH_FORM_VALIDATION:
            return {
                ...state,
                ...action.payload,
            };

        case actionTypes.SEARCH_FORM_FETCH_REQUEST:
            return {
                ...state,
                form: Object.assign({}, state.form, {
                    status: Constants.LOADING,
                    id: action.payload.id,
                }),
            };

        case actionTypes.SEARCH_FORM_FETCH_RESULT:
            return {
                ...state,
                form: Object.assign({}, action.payload, {
                    id: state.form && state.form.id,
                }),
            };
        case actionTypes.SEARCH_FORM_RESET:
            return {
                ...state,
                form: { ...initialState.form },
                changes: {},
            };

        case actionTypes.SEARCH_COUNT_REQUEST:
            return {
                ...state,
                count: { status: Constants.LOADING },
            };

        case actionTypes.SEARCH_COUNT_RESULT:
            return {
                ...state,
                count: action.payload,
            };

        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default searchReducer;

export const searchStatusSelector = (state: Object) => state.search.status;
export const searchErrorSelector = (state: Object) => state.search.error;
export const searchResultSelector = (state: Object) => state.search.result;
export const searchResultTotalCountSelector = (state: Object) =>
    state.search.status === Constants.OK && state.search.result
        ? state.search.result.totalCount
        : -1;
export const searchRequestSelector = (state: Object) =>
    state.search.searchRequest;
export const searchTermsSelector = (state: Object) => state.search.terms;
export const searchItemSelector = (state: Object) => state.search.searchItem;
export const searchFormStatusSelector = (state: Object) =>
    state.search.form.status;
export const searchFormResultSelector = (state: Object) =>
    state.search.form.result;
export const searchFormIdSelector = (state: Object) => state.search.form.id;
export const searchFormChangesSelector = (state: Object) =>
    state.search.changes;
export const searchCountStatusSelector = (state: Object) =>
    state.search.count.status;
export const searchCountResultSelector = (state: Object) => state.search.count;
export const searchCountAggregationsSelector = (state: Object) =>
    state.search.count.aggregations;
export const searchFormValidationSelector = (state: Object) =>
    state.search.isValid || state.search.form.id === -1;
