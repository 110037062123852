// @flow
import React from "react";
import Row from "react-bootstrap/lib/Row";
import ItemAction from "./ItemAction";
import { type ItemActionConfig } from "data/types";
import styles from "./ItemActions.module.css";
import ItemViewFactory from "components/CommandActions/itemViews/Factory";

type Props = {
    /** list of actions to render
     * @type {Array<ItemActionConfig>}
     */
    actions: Array<ItemActionConfig>,
    /** callback when user runs an action
     * @param {ItemActionConfig} action which action to execute
     */
    onAction: (action: ItemActionConfig) => void,
};

/**
 * will render a list of itemActions
 */
const ItemActions = ({ actions, onAction }: Props) => (
    <section>
        {actions.map((action: ItemActionConfig, index: number) => (
            <Row
                key={`ga-${index}`}
                className={action.view ? styles.itemView : styles.row}
                data-test={`itemAction-${action.id}`}
            >
                {action.view ? (
                    <ItemViewFactory action={action} onAction={onAction} />
                ) : (
                    <ItemAction action={action} onAction={onAction} />
                )}
            </Row>
        ))}
    </section>
);
ItemActions.displayName = "ActionPanel.ItemActions";
export default ItemActions;
