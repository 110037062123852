import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";
import immutable from "object-path-immutable";

const initialState = {};

const thumbnailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CURRENTITEM_SET:
            /* istanbul ignore next */
            if (
                action.payload.name !== "docmodal" &&
                action.payload.name !== "itemflows" &&
                action.payload.itemUri !== state.itemUri
            )
                return { ...initialState };
            else return state;

        case actionTypes.DOCS_THUMBNAIL_REQUEST:
            return immutable.set(state, [action.payload.itemUri], {
                status: Constants.LOADING,
            });

        case actionTypes.DOCS_THUMBNAIL_RESULT:
            return immutable.set(state, [action.payload.itemUri], {
                status: action.payload.status,
                result: action.payload.result,
            });

        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default thumbnailsReducer;

export const thumbnailStatusSelector = (state: Object, itemUri: string) =>
    state.thumbnails[itemUri] != null
        ? state.thumbnails[itemUri].status
        : Constants.NONE;
export const thumbnailResultSelector = (state: Object, itemUri: string) =>
    state.thumbnails[itemUri] != null
        ? state.thumbnails[itemUri].result
        : undefined;
