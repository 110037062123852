// @flow
import React, { useCallback } from "react";
import { downloadDocUri } from "data/api";
import ViewerKeysHandler from "containers/ViewerKeysHandler";
import Icons from "@hs/icons";
import loc from "i18next";
import "./index.css";

const InlineViewer = ({
    itemUri,
    isDocDownloadAllowed,
}: {
    itemUri: string,
    isDocDownloadAllowed: boolean,
}) => {
    const handleClick = useCallback(
        (e) =>
            downloadDocUri(itemUri).then((uri) => {
                window.location.assign(uri);
            }),
        [itemUri]
    );
    return (
        <div className="inline-download">
            {loc.t("common:noviewer")}
            {isDocDownloadAllowed && (
                <>
                    <br />
                    <div className="inline-link" onClick={handleClick}>
                        <Icons.Library name="download" size="2x" />
                    </div>
                    <div className="inline-link" onClick={handleClick}>
                        {loc.t("common:download")}
                    </div>
                </>
            )}
        </div>
    );
};
InlineViewer.displayName = "InlineViewer";
export default ViewerKeysHandler(InlineViewer);
