// @flow
import React, { useMemo, useCallback } from "react";
import Icons from "@hs/icons";
import {
    GridListConstant,
    DocListColumnType,
    type DocColumnResultDTO,
    type DocListLayout,
} from "data/types";
import ValueFactory from "components/Attributes/SummaryAttribute/ValueFactory";
import styles from "./RowResultByLayout.module.css";
import { getClassNames } from "data/utils";

type Props = {
    /** CSS styles to apply */
    style?: StyleSheet,
    /** the row object */
    row: Object,
    /** row index */
    index: number,
    /** requested layout */
    layout?: DocListLayout,
    /** all the columns */
    cols: Array<DocColumnResultDTO>,
    /** whether row should be marked as selected */
    isSelected: boolean,
    /** callback when user single clicks the whole row
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onSingleClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
};

/**
 * Render one document row in "Simple" layout mode
 */
const RowResultByLayout = ({
    style,
    row,
    index,
    layout,
    cols,
    isSelected,
    onSingleClick,
}: Props) => {
    const itemUri = useMemo(() => row[row.length - 1], [row]);
    // map requested columns (layout.cols) to existing ones (cols)
    const colIndexes = useMemo(() => {
        let result = [];
        if (layout && layout.cols) {
            layout.cols.forEach((colName) => {
                const colIdx = cols.findIndex((col) => col.name === colName);
                /* istanbul ignore if */
                if (colIdx === -1) {
                    console.warn(
                        "RowResultByLayout: Cannot find column named: " +
                            colName
                    );
                } else {
                    result.push(colIdx);
                }
            });
        } else {
            // show a reasonable default - icon, name
            const extensionColIndex = cols.findIndex(
                (col) => col.name === GridListConstant.ExtensionIcon
            );
            if (extensionColIndex !== -1) result.push(extensionColIndex);
            let nameColIndex = cols.findIndex(
                (col) => col.type === DocListColumnType.WebDavName
            );
            // FlatAUs do not have any Name columns
            if (nameColIndex === -1) nameColIndex = 0;
            result.push(nameColIndex);
        }
        return result;
    }, [layout, cols]);
    const _onSingleClick = useCallback(
        (e: SyntheticMouseEvent<*>) => {
            e.stopPropagation();

            /* istanbul ignore else */
            if (typeof onSingleClick === "function")
                onSingleClick(e, itemUri, index);
        },
        [onSingleClick, itemUri, index]
    );

    return (
        <div
            className={getClassNames(
                styles.table,
                styles.row
                // isSelected ? "active" : null
            )}
            style={style}
            onClick={_onSingleClick}
        >
            {colIndexes.map((colIndex, colNameIndex) => {
                switch (cols[colIndex].name) {
                    case GridListConstant.ExtensionIcon:
                        return (
                            <div
                                key={`rrbl-${index}.${colIndex}`}
                                className={styles.cell}
                                style={{ width: cols[colIndex].width + 2 }}
                            >
                                <Icons.Custom
                                    ext={row[colIndex]?.value || row[colIndex]}
                                    icon={row[colIndex]?.icon}
                                    color={row[colIndex]?.color}
                                    className={styles.icon}
                                />
                            </div>
                        );
                    default:
                        let value = row[colIndex];
                        if (
                            Object.prototype.hasOwnProperty.call(value, "$type")
                        ) {
                            value = (
                                <ValueFactory
                                    value={value}
                                    className={`a-val ${value?.$type}--single`}
                                />
                            );
                        }
                        return (
                            <div
                                key={`rrbl-${index}.${colIndex}`}
                                className={getClassNames(
                                    styles.cell,
                                    colNameIndex === colIndexes.length - 1 &&
                                        colIndex > 2
                                        ? styles.lastcell
                                        : ""
                                )}
                                style={{
                                    width: cols[colIndex].width,
                                    textAlign: [
                                        "LongValue",
                                        "IntValue",
                                    ].includes(cols[colIndex].type)
                                        ? "right"
                                        : "left",
                                }}
                            >
                                {value}
                            </div>
                        );
                }
            })}
        </div>
    );
};

RowResultByLayout.displayName = "DocListSimple.RowResultByLayout";
RowResultByLayout.defaultProps = {
    isSelected: false,
};
export default RowResultByLayout;
