// @flow
import React, { useCallback, useContext } from "react";
import ReactDOM from "react-dom";
import { LinkContainer } from "react-router-bootstrap";
import Breadcrumb from "react-bootstrap/lib/Breadcrumb";
import { getRoute } from "components/ItemUriLink";
import Icons from "@hs/icons";
import loc from "i18next";
import { getLocalizedText } from "data/utils";
import { MultiSelectionContext } from "data/context";

/* istanbul ignore next */
function partTitle(
    text: string,
    itemUri: string,
    isLast: boolean,
    bctext: string,
    isDoc: boolean
): string {
    return isDoc && isLast
        ? loc.t("breadcrumb.titleDoc", { text })
        : isLast
        ? bctext
        : loc.t("breadcrumb.title", { text });
}

type Props = {
    /** Breadcrumb as Text (shown in Tooltip) */
    bctext?: string,
    /** font-awesome icon to display before text */
    symbolName?: string,
    /** part name */
    text: string,
    /** part itemUri */
    itemUri: string,
    /** whether this is the last part */
    isLast: boolean,
    /** CSS styles to apply */
    style?: StyleSheet,
    /** CSS classname to apply */
    className?: string,
    /** part index */
    index: number,
    /** calback when width of part has been measured
     * @param {number} index index of part
     * @param {number} width pixel width of rendered part
     */
    onMeasured?: (index: number, width: number) => void,
    /** whether part should be rendered as readonly (no navigation) */
    isReadonly: boolean,
    /** optional onClick instead of using history.push */
    onClick?: (itemUri: string) => void,
    /** optional isDoc flag, defaults to false */
    isDoc?: boolean,
};

export const BreadcrumbPart = ({
    bctext,
    symbolName,
    text,
    itemUri,
    isLast,
    style,
    className,
    index,
    onMeasured,
    isReadonly,
    onClick,
    isDoc = false,
}: Props) => {
    /* istanbul ignore next */
    const { disable } = useContext(MultiSelectionContext) || {};
    const measureWidth = useCallback(
        (ref: Breadcrumb.Item) => {
            const bi: Breadcrumb.Item = ReactDOM.findDOMNode(ref);
            /* istanbul ignore else */
            if (bi != null && onMeasured) onMeasured(index, bi.clientWidth + 4);
        },
        [index, onMeasured]
    );
    const onPartClick = useCallback(
        (e, itemUri: string) => {
            // no link on last part
            if (isLast) {
                e.preventDefault();
                return;
            }
            // if onclick defined avoid history.push
            if (typeof onClick === "function") {
                e.preventDefault();
                onClick(itemUri);
            }
            // always disable multiselection on click
            if (typeof disable === "function") disable();
            // use history.push to load itemUri
            return null;
        },
        [onClick, isLast, disable]
    );

    const localizedText = text.startsWith("$folder:")
        ? getLocalizedText(text)
        : text;

    return (
        <Breadcrumb.Item
            ref={(ref) => ref && measureWidth(ref)}
            active={isLast || isReadonly}
            style={style}
            className={className}
            data-test={`breadCrumb${isReadonly ? "Disabled" : "Enabled"}`}
        >
            {isReadonly ? (
                <span title={localizedText}>
                    {symbolName ? (
                        <Icons.Library name={symbolName} className="fa-lg" />
                    ) : (
                        localizedText
                    )}
                </span>
            ) : (
                <LinkContainer
                    to={getRoute({ itemUri, isDoc })}
                    onClick={(e) => onPartClick(e, itemUri)}
                >
                    <span
                        title={partTitle(
                            localizedText,
                            itemUri,
                            isLast,
                            bctext,
                            isDoc
                        )}
                    >
                        {symbolName ? (
                            <Icons.Library
                                name={symbolName}
                                className="fa-lg"
                            />
                        ) : (
                            localizedText
                        )}
                    </span>
                </LinkContainer>
            )}
        </Breadcrumb.Item>
    );
};

BreadcrumbPart.displayName = "BreadcrumbPart";
export default BreadcrumbPart;
