// @flow
import React from "react";
import { get } from "data/constants";
import Button from "react-bootstrap/lib/Button";
import loc from "i18next";
import styles from "./LocalContactInfo.module.css";
import globalloc from "data/i18n.global";

const LocalContactInfo = () => {
    const localContactInfo = get(
        window.CONFIG,
        ["general", "localContactInfo"],
        null
    );
    if (localContactInfo == null) {
        return null;
    }
    const { email, text = "", url } = localContactInfo;
    return (
        <div className={styles.wrapper}>
            {text}
            {(email || url) && (
                <div className={styles.actions}>
                    {email && (
                        <div className={styles.btn}>
                            {loc.t("localContactInfo:email") ||
                                /* istanbul ignore next */ globalloc
                                    .localContactInfo.email}
                            <Button
                                href={`mailto:${email}`}
                                bsStyle="link"
                                rel="noopener external"
                                target="_blank"
                            >
                                {email}
                            </Button>
                        </div>
                    )}
                    {url && (
                        <div className={styles.btn}>
                            {loc.t("localContactInfo:url") ||
                                /* istanbul ignore next */ globalloc
                                    .localContactInfo.url}
                            <Button
                                href={url}
                                bsStyle="link"
                                rel="noopener external"
                                target="_blank"
                            >
                                {url}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

LocalContactInfo.displayName = "LocalContactInfo";
export default LocalContactInfo;
