import { lock as lockApi } from "data/api";
import { toastActionResult } from "data/toast";

class lock {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;
    execute = async ({ itemUri }: { itemUri: string }): Promise<boolean> => {
        try {
            return await lockApi(itemUri);
        } catch (err) {
            return false;
        }
    };
    report = async (success: boolean): Promise<boolean> => {
        this.logger(success === true, "commandAction:lock");
    };
}

export default lock;
