import { call, put, takeLatest, select } from "redux-saga/effects";
import actionTypes from "data/actions/actionTypes";
import { getUserResultAction } from "data/actions";
import { fetchUser } from "data/api";
import Constants from "data/constants";
import { version } from "../../../package.json";
import { signout } from "data/loginFlow";
import * as s from "data/reducers/selectors";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getUser(action) {
    try {
        const user = yield call(fetchUser);
        window.CONFIG.web_version = version;
        window.CONFIG.provider_fileVersion = user.provider_fileVersion;
        window.CONFIG.provider_serviceVersion = user.provider_serviceVersion;
        window.CONFIG.general.features = user.features;

        yield put(getUserResultAction({ status: Constants.OK, info: user }));
        //}
    } catch (e) {
        yield put(
            getUserResultAction({ status: Constants.ERROR, message: e.message })
        );
    }
}

function* logoutUser() {
    const idToken = yield select(s.loginIdTokenSelector);
    yield call(signout, idToken);
}

function* userSaga() {
    yield takeLatest(actionTypes.USER_FETCH_REQUESTED, getUser);
    yield takeLatest(actionTypes.LOGIN_SIGNOUT, logoutUser);
}

export default userSaga;
