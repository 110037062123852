//@flow
import { inPointAction, inPointActionLink } from "components/ItemUriLink";

/**
 * opens inPoint.Client to execute command action for the specified itemUris
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ items?: Array<any> }} { items }
 * @param {{ commandAction?: string}} { commandAction }
 */

type Context = {
    itemUri: string,
    items?: Array<any>,
    commandAction?: string,
};

class ip_commandAction {
    execute({ itemUri, items, commandAction }: Context) {
        window.location.assign(
            inPointActionLink(
                inPointAction.commandAction,
                items?.length ? undefined : itemUri,
                items?.length ? items.map((item) => item.itemUri) : undefined,
                {
                    name: commandAction,
                }
            )
        );
        return true;
    }
}

export default ip_commandAction;
