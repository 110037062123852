// @ts-check
import React, { PureComponent, useRef, useCallback } from "react";
import {
    DocListFilterOperators,
    type DocListFilterOperatorsEnum,
} from "data/types";
import moment from "moment";
import Moment from "containers/Moment";
import DayPickerInput from "components/DayPickerInput";
import { Manager } from "react-popper";
import { userLanguage } from "data/storeHelper";

// range of dates to show in the picker
const currentYear = () => new Date().getFullYear();
const fromMonth = () => new Date(currentYear() - 10, 0);
const toMonth = () => new Date(currentYear() + 10, 11);

export function YearMonthForm({
    date,
    localeUtils,
    onChange,
}: {
    date?: Date,
    localeUtils?: Object,
    onChange: Function,
}) {
    const month = useRef(null);
    const year = useRef(null);

    const handleChange = useCallback(
        (e) => {
            onChange(new Date(year.current.value, month.current.value));
        },
        [month, year, onChange]
    );

    if (localeUtils === undefined || localeUtils === null) return null;
    if (date === undefined || date === null) date = new Date();
    const months = localeUtils.getMonths(userLanguage());

    const years = [];
    for (
        let i = fromMonth().getFullYear();
        i <= toMonth().getFullYear();
        i += 1
    ) {
        years.push(i);
    }

    return (
        <div className="DayPicker-Caption">
            <select
                ref={month}
                name="month"
                onChange={handleChange}
                value={date.getMonth()}
            >
                {months.map((month, i) => (
                    <option key={i} value={i}>
                        {month}
                    </option>
                ))}
            </select>
            <select
                ref={year}
                name="year"
                onChange={handleChange}
                value={date.getFullYear()}
            >
                {years.map((year, i) => (
                    <option key={i} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
}

type Props = {
    autoFocus?: boolean,
    index: number,
    operator: DocListFilterOperatorsEnum,
    values: Array<string>,
    valueIndex: number,
    onChange: Function,
    id?: string,
};

type State = {
    month: Date,
    values: Array<string>,
};

export class DatePicker extends PureComponent<Props, State> {
    state = {
        month: fromMonth(),
        values: [],
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            month: new Date(),
            values:
                (Array.isArray(props.values) &&
                    props.values.map((value) => this.getValueAsDate(value))) ||
                [],
        };
        moment.locale(userLanguage());
    }

    handleYearMonthChange = (month: Date) => {
        this.setState({ month });
    };

    areValuesEqual = (
        prevValues: Array<string>,
        currentValues: Array<string>
    ) => {
        return (
            prevValues[0] === currentValues[0] &&
            prevValues[1] === currentValues[1]
        );
    };

    componentDidUpdate(prevProps: Props) {
        /* istanbul ignore else */
        if (!this.areValuesEqual(prevProps.values, this.props.values)) {
            this.setState({
                month: new Date(),
                values:
                    (Array.isArray(this.state.values) &&
                        this.props.values.map((value) =>
                            this.getValueAsDate(value)
                        )) ||
                    /* istanbul ignore next */
                    [],
            });
        }
    }

    getValueAsDate = (value: ?string) => {
        return value && String(value).length > 0
            ? moment(value).format("L")
            : null;
    };

    handleDayChange = (
        day: ?Date,
        dayPickerInput: DayPickerInput,
        inputValue: ?Date
    ) => {
        // hours set to prevent date inconsistencies, when giving manual input
        day?.setHours(12);

        let value =
            inputValue &&
            /* istanbul ignore next */
            (dayPickerInput._ref.value || dayPickerInput.state.value);

        const { index, valueIndex, onChange } = this.props;
        if (day != null) {
            value = this.getValueAsDate(day);
            onChange(
                moment(day).utc().startOf("day").toJSON(),
                index,
                valueIndex
            );
        } else {
            /* istanbul ignore else */
            if (value == null) {
                onChange("", index, valueIndex);
            }
        }
        const values = [...this.state.values];
        values[valueIndex] = value;
        this.setState({ values });
    };

    render() {
        const { autoFocus, valueIndex, values, operator } = this.props;

        const from =
            [
                DocListFilterOperators.Between,
                DocListFilterOperators.NotBetween,
            ].includes(operator) &&
            values &&
            values[0] &&
            String(values[0]).length > 0
                ? moment(values[0]).toDate()
                : null;

        const to =
            [
                DocListFilterOperators.Between,
                DocListFilterOperators.NotBetween,
            ].includes(operator) &&
            values &&
            values[1] &&
            String(values[1]).length > 0
                ? moment(values[1]).toDate()
                : null;

        const getDisabled = () => {
            if (valueIndex === 1 && from) {
                return { before: from };
            }

            if (valueIndex === 0 && to) {
                return { after: to };
            }

            return null;
        };

        // calculate the month that should be displayed on opening the daypicker modal
        const getMonthToDisplay = () => {
            if (valueIndex === 0) {
                if (!to) {
                    return from || this.state.month;
                }
                return to;
            }

            /* istanbul ignore else */
            if (valueIndex === 1) {
                if (!from) {
                    return to || this.state.month;
                }
                return from;
            }
        };

        return (
            <div className="YearNavigation">
                <Manager>
                    <DayPickerInput
                        className="form-control"
                        autoFocus={autoFocus}
                        required={
                            // the first one is required if there is value in the second one
                            (valueIndex === 0 && to) ||
                            // the second one is required if there is value in the first one
                            (valueIndex === 1 && from)
                        }
                        dayPickerProps={{
                            month: getMonthToDisplay(),
                            showWeekNumber: true,
                            numberOfMonths: 1,
                            fixedWeeks: true,
                            disabled: getDisabled(),
                            fromMonth:
                                from && valueIndex === 1 ? from : fromMonth(),
                            toMonth: to && valueIndex === 0 ? to : toMonth(),
                            captionLayout: "dropdown-buttons",
                            index: valueIndex,
                        }}
                        inputProps={{
                            className: "form-control",
                            id: this.props.id,
                        }}
                        onDayChange={this.handleDayChange}
                        value={this.state.values[valueIndex]}
                        placeholder={moment.localeData().longDateFormat("L")}
                        format={moment.localeData().longDateFormat("L")}
                    />
                </Manager>
            </div>
        );
    }
}

export default Moment(DatePicker);
