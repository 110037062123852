// @flow
import React, { useRef, useEffect, type Element } from "react";
import List from "react-virtualized/dist/commonjs/List";
import "react-virtualized/styles.css"; // only needs to be imported once
import Measure from "react-measure";
import NoData from "components/NoData";
import { getClassNames } from "data/utils";
import styles from "./Tree.module.css";

type Props = {
    /** function called to render one item
     * @param {string} key Unique key within array of rows
     * @param {number} index Index of row within collection
     * @param {boolean} isScrolling The List is currently being scrolled
     * @param {boolean} isVisible This row is visible within the List (eg it is not an overscanned row)
     * @param {CSStyle} style Style object to be applied to row (to position it)
     */
    renderRow: (
        key: string,
        index: number,
        isScrolling: boolean,
        isVisible: boolean,
        style: CSSStyle
    ) => React.Node,
    /** current node index to scroll to */
    scrollToIndex: number,
    /** current node id to mark as active */
    activeTreeNodeId: number,
    /** total number items to render */
    totalItems: number,
    /** callback when user scrolls the tree */
    onScroll: () => void,
    /** localization context (e.g. for nodata) */
    locContext: string,
    /** optional NoData Content Component */
    NoDataContent: Element<any>,
    /** filter text currently applied */
    filter?: string,
    /** optional classname to apply to root ListGroup */
    className?: string,
};

const Tree = ({
    renderRow,
    scrollToIndex,
    activeTreeNodeId,
    totalItems,
    onScroll,
    locContext,
    NoDataContent,
    filter,
    className,
}: Props) => {
    const list: List = useRef(null); // internal ref to rendered virtualized List Component

    useEffect(() => {
        /* istanbul ignore next */ list.current &&
            list.current.forceUpdateGrid();
    }, [activeTreeNodeId, filter]);

    if (totalItems === 0)
        return (
            <NoData locContext={locContext}>
                {NoDataContent && <NoDataContent />}
            </NoData>
        );

    return (
        <Measure bounds>
            {({
                measureRef,
                contentRect: /* istanbul ignore next */ { bounds } = {
                    bounds: { width: 0, height: 0 },
                },
            }) => (
                <ul
                    ref={measureRef}
                    className={getClassNames(
                        "list-group",
                        styles.list,
                        className
                    )}
                >
                    <List
                        ref={list}
                        width={/* istanbul ignore next */ bounds?.width ?? 0}
                        height={/* istanbul ignore next */ bounds?.height ?? 0}
                        onScroll={onScroll}
                        rowCount={totalItems}
                        rowHeight={42}
                        scrollToIndex={scrollToIndex}
                        rowRenderer={renderRow}
                    />
                </ul>
            )}
        </Measure>
    );
};

export default Tree;
