import React from "react";
import styles from "./Attachments.module.css";
import InputGroup from "react-bootstrap/lib/InputGroup";
import AttributeLabel from "components/ExtraAttributes/AttributeLabel";
import ViewerControlBase from "components/ExtraAttributes/ViewerControl/ViewerControlBase";
import Icons from "@hs/icons";
import { getBytes } from "../Utils";
import { type ShareAttachment } from "data/types";

const AttachmentsViewerControl = ({
    header,
    value: attachments,
    ext,
    showBytes = true,
}: {
    header?: string,
    value: Array<ShareAttachment>,
    ext?: string,
    showBytes?: boolean,
}) =>
    attachments?.map((attachment, i) =>
        attachment == null ? null : (
            <ViewerControlBase key={`attachment-${i}`}>
                {header && <AttributeLabel label={header} />}
                <InputGroup className={styles.group}>
                    <InputGroup.Addon>
                        <Icons.Library name="paperclip" />
                    </InputGroup.Addon>
                    <div
                        data-test={`AttachmentViewerControl${
                            ext || attachment.ext
                        }`}
                        className={`form-control ${styles.wrapper}`}
                        title={`${attachment.name}${ext || attachment.ext}`}
                    >
                        <Icons.FileExt
                            ext={ext || attachment.ext}
                            style={{ verticalAlign: "text-top" }}
                        />
                        <span>{attachment.name}</span>
                        {showBytes && (
                            <small>
                                {" "}
                                {" (" + getBytes(attachment.size) + ")"}
                            </small>
                        )}
                    </div>
                </InputGroup>
            </ViewerControlBase>
        )
    );

AttachmentsViewerControl.displayName = "AttachmentsViewerControl";
export default AttachmentsViewerControl;
