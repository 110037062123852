import React, { useCallback, useMemo } from "react";
import Icons from "@hs/icons";
import Button from "react-bootstrap/lib/Button";
import PrincipalEditorControl from "components/ExtraAttributes/EditorControl/PrincipalEditorControl";
import EnumEditorControl from "components/ExtraAttributes/EditorControl/EnumEditorControl";
import { ShareType, type SharePrincipalDTO } from "data/types";
import loc from "i18next";
import { getLocalizedText } from "data/utils";

type Props = {
    onRemove: (value) => void,
    onChange: (value) => void,
    name: string,
    value?: SharePrincipalDTO,
    extras?: any,
};

const SharePrincipalForm = ({
    onChange,
    onRemove,
    name,
    value: sharePrincipal,
    extras = { aclEnumValues: null, acl: null, newUserIdRef: null },
}: Props) => {
    const handleChange = useCallback(
        (name: string, value: any) =>
            onChange({
                ...sharePrincipal,
                principal: sharePrincipal.principal
                    ? { ...sharePrincipal.principal }
                    : null,
                [name]: value,
            }),
        [onChange, sharePrincipal]
    );
    const aclEnumValuesWithDefault = useMemo(() => {
        const aclEnumValuesOrEmpty = extras.aclEnumValues || [];
        const withDefault = [...aclEnumValuesOrEmpty];
        const defaultAcl = aclEnumValuesOrEmpty.find(
            (enumValue) => enumValue.value === extras.acl
        );
        withDefault.unshift({
            value: null,
            label: loc.t("share:acl.default", {
                default: defaultAcl
                    ? getLocalizedText(defaultAcl.label)
                    : "n/a",
            }),
        });
        return withDefault;
    }, [extras.aclEnumValues, extras.acl]);
    return (
        <>
            <PrincipalEditorControl
                name="principal"
                includeGroups={true}
                isMulti={false}
                isClearable={false}
                isRequired={true}
                createUser={true}
                value={sharePrincipal.principal}
                onChange={handleChange}
                newUserIdRef={extras.newUserIdRef}
                validationErrors={{
                    isDefaultRequiredValue: loc.t("common:validation.required"),
                }}
            />
            {extras.type === ShareType.Share && (
                <EnumEditorControl
                    name="acl"
                    id={`${name}[acl]`}
                    enum={aclEnumValuesWithDefault}
                    value={sharePrincipal.acl}
                    onChange={handleChange}
                    component="Dropdown"
                />
            )}
            <Button
                aria-label="delete"
                bsStyle="link"
                onClick={onRemove}
                title={loc.t("share:principal.delete")}
                className="pull-right"
            >
                <Icons.Library name="user-xmark" />
            </Button>
        </>
    );
};

SharePrincipalForm.displayName = "SharePrincipalForm";
export default SharePrincipalForm;
