import React from "react";
import BreadcrumbContainer from "containers/Breadcrumb";
import SearchcrumbContainer from "containers/Searchcrumb";
import { check_isSpecialFolder } from "containers/ActionPanel/ShouldBeVisible";
import Icons from "@hs/icons";
import { type SearchItemDTO } from "data/types";
import loc from "i18next";

export const CustomBreadcrumb = ({
    name,
    icon,
}: {
    /** text to show */
    name: string,
    /** icon to show (FontAwesome) */
    icon: string,
}) => (
    <div style={{ color: "#777", fontSize: "14px" }}>
        <Icons.Library name={icon} className="fa-lg" />
        {" " + name}
    </div>
);
CustomBreadcrumb.displayName = "CustomBreadcrumb";

type Props = {
    /** itemUri */
    itemUri: ?string,
    /** current view name */
    viewName: string,
    /** optional used searchItem */
    searchItem?: SearchItemDTO,
    /** whether user is allowed to navigate through breadcrumb (and hide favorite option) */
    isReadonly: boolean,
    /** scope breadcrumb to show parts only starting the specified level number */
    scopeFromLevel?: number,
    /** optional breadcrumbPart click event instead of history.push */
    onBreadcrumbPartClick?: (itemUri: string) => void,
};

export const CrumbSelector = ({
    itemUri,
    viewName,
    searchItem,
    isDoc = false,
    isReadonly = false,
    isRecycleBin = false,
    scopeFromLevel = 0,
    onBreadcrumbPartClick,
    canChangeSite = false,
}: Props) => {
    if (searchItem != null)
        return (
            <SearchcrumbContainer
                searchItem={searchItem}
                isReadonly={isReadonly}
            />
        );
    else if (
        itemUri == null //home baby
    )
        return null;
    // <CustomBreadcrumb name={loc.t("home")} icon="home" />;
    else if (itemUri === "search")
        return (
            <CustomBreadcrumb
                name={loc.t("common:search.searchcrumb")}
                icon="magnifying-glass"
            />
        );
    else if (itemUri === "news")
        return <CustomBreadcrumb name={loc.t("sidebar.news")} icon="rss" />;
    else if (itemUri === "upload")
        return (
            <CustomBreadcrumb name={loc.t("sidebar.upload")} icon="upload" />
        );
    else if (
        String(itemUri).startsWith("pam-item://") ||
        check_isSpecialFolder({ itemUri })
    ) {
        const isFavorable = !(
            check_isSpecialFolder({ itemUri }) || isRecycleBin
        );
        return (
            <BreadcrumbContainer
                itemUri={itemUri}
                isDoc={isDoc}
                isReadonly={isReadonly}
                isFavorable={isFavorable}
                scopeFromLevel={scopeFromLevel}
                viewName={viewName}
                onBreadcrumbPartClick={onBreadcrumbPartClick}
                canChangeSite={canChangeSite}
            />
        );
    } else {
        return null;
    }
};
CrumbSelector.displayName = "CrumbSelector";
export default CrumbSelector;
