import React, { useMemo } from "react";
import FormBar from "@hs/form-bar";
import SearchInput from "@hs/search-input";
import { useFormActions } from "hooks";
import { FormBarView } from "data/types";
import { getClassNames } from "data/utils";
import styles from "./ArchiveTarget.module.css";

type Props = {
    /** CSS Classname */
    className?: string,
    /** current ArchiveTarget */
    archiveTarget: CardItemResultDTO,
    /** callback when user clicks Cancel */
    onCancel: () => void,
    /** callback when user clicks Back */
    onBack?: () => void,
    /** callback when user types anything into filter text
     * @param {string} filterText filterText to apply
     */
    onFilterTextChange?: (filterText: string) => void,
    /** current filterText value */
    filterText?: string,
};

/**
 * renders an ArchiveTarget image (instead of icon)
 */
const ArchiveTargetImageWithTitle = (title) => {
    const Component = ({ name: src }) => (
        <img src={src} alt={title} className={styles.image} />
    );
    Component.displayName = "ArchiveTargetImage";
    return Component;
};

/**
 * renders an ArchiveTarget panel header
 */
export const ArchiveTarget = ({
    className,
    archiveTarget,
    onCancel,
    onBack,
    onFilterTextChange,
    filterText,
}: Props) => {
    const [components, icon] = useMemo(() => {
        const components = archiveTarget.image
            ? {
                  Icon: ArchiveTargetImageWithTitle(archiveTarget.name),
              }
            : null;
        switch (archiveTarget.uri) {
            case "at-fav":
                return [components, "fa-regular fa-star"];
            case "at-rec":
                return [components, "clock-rotate-left"];
            case "at-search":
                return [components, "magnifying-glass"];
            default:
                return [components, archiveTarget.image];
        }
    }, [archiveTarget]);
    const contextProps = useMemo(
        () => ({ onCancel, onBack }),
        [onCancel, onBack]
    );
    const actions = useFormActions(FormBarView.ArchiveTarget, contextProps);
    return (
        <FormBar
            className={getClassNames(
                !archiveTarget.image && styles.noIcon,
                className
            )}
            icon={archiveTarget.image}
            description={archiveTarget.description}
            components={components}
            actions={actions}
        >
            <SearchInput
                borderless
                icon={icon}
                key="searchTargets"
                placeholder={archiveTarget.name}
                onChange={onFilterTextChange}
                value={filterText}
            />
        </FormBar>
    );
};
ArchiveTarget.displayName = "FormBar.ArchiveTarget";
export default ArchiveTarget;
