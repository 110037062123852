import React, { useEffect } from "react";
import { usePrevious } from "hooks";
import withItemContext from "containers/ItemContext";

type Props = {
    itemUri: string,
    // provide effects
    effects: any,
    children: any,
};

export const ItemContextProvider = ({ children, effects, ...props }: Props) => {
    const prevProps = usePrevious(props);
    useEffect(() => {
        if (!prevProps || !effects) return;
        Object.keys(effects).forEach((key) => {
            const prevProp = prevProps[key];
            const prop = props[key];
            if (prevProp === prop) return;
            effects[key](prop, props);
        });
    }, [prevProps, props, effects]);
    return typeof children === "function" ? (
        <>{children(props)}</>
    ) : children ? (
        <>{children}</>
    ) : null;
};

export default withItemContext({ asProps: true })(ItemContextProvider);
