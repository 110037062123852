import React from "react";
import { useAsyncMemo } from "hooks";
import { siteDisplayName } from "components/Sidebar/utils";
import Dropdown from "@hs/dropdown";
import MenuItem from "react-bootstrap/lib/MenuItem";
import Icons from "@hs/icons";
import styles from "./SiteSelect.module.css";
import NativeSelect from "components/NativeSelect";
import bowser from "data/bowser";
import { sitesSelector } from "data/storeHelper";
import { getClassNames } from "data/utils";

export const SiteRow = ({
    site,
    className,
}: {
    site: SidebarItemDTO,
    className?: string,
}) => (
    <div className={className}>
        <Icons.Custom
            className={getClassNames("fa-fw", !site.color && styles.color)}
            siteType={site.siteType}
            icon={site.icon}
            color={site.color}
        />
        <span className={styles.name}>{siteDisplayName(site)}</span>
    </div>
);
SiteRow.displayName = "SiteRow";

type Props = {
    /** callback with first itemUri after loading sites */
    onLoad: (itemUri: string) => void,
    /** which itemUri to pre-select */
    defaultItemUri: ?string,
    /** callback when user changes selection
     * @param {string} itemUri new selected site's itemUri
     */
    onChange: (itemUri: string) => void,
};

/**
 * will render a dropdown to select searchable sites
 */
export const SiteSelect = ({ onLoad, defaultItemUri, onChange }: Props) => {
    /**
     * Info: Why useAsyncMemo and not useMemo
     * onLoad (from parent) will always update parent while rendering child
     * Results in react error
     */
    const [selectedSite, filteredSites] = useAsyncMemo(
        async () => {
            const sites = sitesSelector();
            //Hide custom sites, recyclebin and AUSites
            const filteredSites =
                sites == null
                    ? []
                    : sites.filter(
                          (site) =>
                              site.source !== 1 &&
                              ![4, 6].includes(site.siteType)
                      );
            if (filteredSites.length === 0) return [null, []];
            if (defaultItemUri) {
                const selectedSite = filteredSites.find(
                    (site) => site.itemUri === defaultItemUri
                );
                return [selectedSite, filteredSites];
            }

            /* istanbul ignore else */
            if (filteredSites.length > 0 && typeof onLoad === "function") {
                onLoad(filteredSites[0].itemUri);
            }
            return [null, filteredSites];
        },
        [onLoad, defaultItemUri],
        [null, []]
    );

    if (bowser.is("iOS") || bowser.is("mobile"))
        return (
            <NativeSelect
                wrapperClassName={styles.siteSelect}
                className={styles.siteSelectNative}
                onChange={
                    /* istanbul ignore next */ (option) => onChange(option.K)
                }
                defaultValue={{ K: defaultItemUri }}
                value={{ K: defaultItemUri }}
                options={filteredSites.map((site, index) => ({
                    K: site.itemUri,
                    T: siteDisplayName(site),
                }))}
                valueKey="K"
                labelKey="T"
            />
        );
    else
        return (
            <Dropdown
                bsSize="small"
                key="siteselect"
                id="siteselect"
                className={styles.siteSelect}
            >
                <Dropdown.Toggle chrome>
                    {selectedSite && (
                        <SiteRow
                            className={styles.button}
                            site={selectedSite}
                        />
                    )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {filteredSites.map((site, index) => (
                        <MenuItem
                            active={defaultItemUri === site.itemUri}
                            key={`ss-s${index}`}
                            onSelect={
                                /*istanbul ignore next */ () =>
                                    onChange(site.itemUri)
                            }
                        >
                            <SiteRow className={styles.menuitem} site={site} />
                        </MenuItem>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
};
SiteSelect.displayName = "SiteSelect";

export default SiteSelect;
