// @ts-check
import React, { useRef, useState, useCallback, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import Button from "react-bootstrap/lib/Button";
import "react-day-picker/dist/style.css";
import * as Locales from "date-fns/locale";
import moment from "moment";
import styles from "./DayPickerInput.module.css";
import loc from "i18next";
import { Reference, Popper } from "react-popper";
import { createPortal } from "react-dom";
import FocusTrap from "focus-trap-react";
import ClearableInput from "@hs/clearable-input";

export default function DayPickerInput({
    inputProps,
    placeholder,
    format: dateFormat,
    value: valueFromProps,
    onDayChange,
    component: Input = "input",
    overlayComponent: Overlay = "div",
    required = false,
    autoFocus = false,
    dayPickerProps,
    id,
}) {
    /* istanbul ignore next */
    const [selected, setSelected] = useState(
        valueFromProps ? moment(valueFromProps, dateFormat, true).toDate() : ""
    );

    const [value, setValue] = useState(valueFromProps);

    // month to display
    const [month, setMonth] = useState(
        selected || dayPickerProps?.month || new Date()
    );

    useEffect(() => {
        setValue(valueFromProps);
    }, [valueFromProps]);

    useEffect(() => {
        /* istanbul ignore next */
        setSelected((s) =>
            value && moment(value, dateFormat, true).isValid()
                ? moment(value, dateFormat, true).toDate()
                : s
        );
    }, [value, dateFormat]);

    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [isFocusTrapActive, setIsFocusTrapActive] = useState(true);

    const focus = useRef({ input: autoFocus, overlay: true });

    const popperRef = useRef(null);

    const closePopper = useCallback(() => {
        /* istanbul ignore next */
        if (focus.current.input || focus.current.overlay) return;
        const isValidDateInput = moment(value, dateFormat, true).isValid();

        /* istanbul ignore else */
        if (!isValidDateInput) {
            /* istanbul ignore else */
            if (value && selected && popperRef.current?._ref.value) {
                // if the user has a half-typed date in and we already have a selected in out state
                // then overwrite the typed in text with the selected date
                setValue(moment(selected).format(dateFormat));
                onDayChange(
                    selected,
                    popperRef.current,
                    moment(selected).format(dateFormat)
                );
            } else {
                // if there is nothing selected then delete the text
                setValue("");
            }
        }
        setIsPopperOpen(false);
    }, [value, selected, dateFormat, onDayChange]);

    const handleInputChange = useCallback(
        (e) => {
            setValue(e);
            const dateObj = moment(e, dateFormat, true);

            if (dateObj.isValid()) {
                const date = dateObj.toDate();
                onDayChange(date, popperRef.current);
                setSelected(date);
                // change the month to switch the daypicker view
                setMonth(date);
            } else {
                if ((e ?? "").trim() === "") {
                    onDayChange(null, popperRef.current, null, true);
                    setSelected(undefined);
                } else {
                    onDayChange(null, popperRef.current, e);
                }
            }

            // if the input was cleared by the X button then
            // we have to blur and focus again so the FocusTrap becomes active
            // otherwise the date is not selectable and the popup just closes on click
            // bugticket for proper fix: Bug 66350: @hs/clearable-input breaks DayPickerInput

            /* istanbul ignore next */
            if (e == null) {
                setTimeout(() => {
                    popperRef.current._ref.blur();
                    popperRef.current._ref.focus();
                });
            }
        },
        [dateFormat, onDayChange]
    );

    const handleFocus = useCallback((e) => {
        focus.current.input = true;
        setIsPopperOpen(true);
    }, []);

    const handleBlur = useCallback(() => {
        focus.current.input = false;
        closePopper();
    }, [closePopper]);

    const handleDaySelect = useCallback(
        (date: Date) => {
            setSelected(date);
            if (date) {
                const formattedDate = moment(date).format(
                    moment.localeData().longDateFormat("L")
                );

                setValue(formattedDate);
                onDayChange(date, popperRef.current, formattedDate);
                focus.current.overlay = false;
                setIsFocusTrapActive(false);
                closePopper();
            } else {
                onDayChange(null, popperRef.current, null, true);
                setValue("");
            }
        },
        [closePopper, onDayChange]
    );

    /* istanbul ignore next */
    const handleActivate = useCallback(() => {
        focus.current.overlay = true;
    }, []);

    const handleDeactivate = useCallback(() => {
        focus.current.overlay = false;
        setIsFocusTrapActive(false);
        closePopper();
    }, [closePopper]);

    const setToday = useCallback(() => {
        handleDaySelect(new Date());
        setMonth(new Date());
    }, [handleDaySelect]);

    return (
        <>
            <Reference>
                {({ ref }) => (
                    <div ref={ref}>
                        <ClearableInput
                            ref={popperRef}
                            placeholder={placeholder}
                            value={value}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            {...inputProps}
                            required={required}
                            autoFocus={autoFocus}
                            className="form-control"
                            id={id}
                        />
                    </div>
                )}
            </Reference>
            {isPopperOpen &&
                createPortal(
                    <Popper
                        modifiers={[
                            {
                                name: "preventOverflow",
                                enabled: true,
                                options: {
                                    rootBoundary: "window",
                                },
                            },
                        ]}
                        positionFixed={false}
                        placement="bottom-end"
                    >
                        {({ ref, style, placement }) => (
                            <FocusTrap
                                active={isFocusTrapActive}
                                focusTrapOptions={{
                                    initialFocus: false,
                                    allowOutsideClick: true,
                                    clickOutsideDeactivates: true,
                                    returnFocusOnDeactivate: false,
                                    onActivate: handleActivate,
                                    onDeactivate: handleDeactivate,
                                }}
                            >
                                <div
                                    ref={ref}
                                    style={{
                                        ...style,
                                        backgroundColor: "#f5f5f5",
                                    }}
                                    className={styles.overlay}
                                    data-placement={placement}
                                    onMouseDown={() =>
                                        setIsFocusTrapActive(true)
                                    }
                                >
                                    <DayPicker
                                        mode="single"
                                        defaultMonth={selected}
                                        selected={selected}
                                        onSelect={handleDaySelect}
                                        locale={Locales[moment.locale()]}
                                        onMonthChange={setMonth}
                                        modifiersStyles={{
                                            selected: {
                                                backgroundColor: "#0098aa",
                                            },
                                        }}
                                        {...dayPickerProps}
                                        month={month}
                                    />
                                    <Button
                                        bsStyle="link"
                                        className={styles.todayButton}
                                        onClick={setToday}
                                        data-test="dayPickerTodayButton"
                                    >
                                        {loc.t(
                                            "folder:action_filter.calendar_today"
                                        )}
                                    </Button>
                                </div>
                            </FocusTrap>
                        )}
                    </Popper>,
                    document.getElementById("root")
                )}
        </>
    );
}
