import { useCallback } from "react";
/**
 * handles navigating between different documents using keyboard arrows
 *
 * @param {*} { _iframe, onNext, onPrev, onPdfReady }
 * @returns
 */
const usePdfArrowNavigation = ({ iframe, onNext, onPrev, onPdfReady }) => {
    // handle onPrev/onNext
    const onKeyDown = useCallback(
        (e: SyntheticKeyboardEvent<*>) => {
            const allowNav =
                !iframe.current.contentWindow.PDFViewerApplication.pdfViewer
                    .isHorizontalScrollbarEnabled;
            switch (e.keyCode) {
                case 27: //Escape - close
                    var event = document.createEvent("Event");
                    event.initEvent("keydown", true, true);
                    event.keyCode = 27;
                    document.dispatchEvent(event);
                    break;
                case 39: // right
                    /* istanbul ignore else */
                    if (
                        iframe.current != null &&
                        typeof onNext === "function" &&
                        allowNav
                    ) {
                        onNext();
                    }
                    break;
                case 37: // left
                    /* istanbul ignore else */
                    if (
                        iframe.current != null &&
                        typeof onPrev === "function" &&
                        allowNav
                    ) {
                        onPrev();
                    }
                    break;
                /* istanbul ignore next */
                default:
                    break;
            }
        },
        [iframe, onNext, onPrev]
    );

    // once iframe loads register our events
    const onLoad = useCallback(() => {
        /* istanbul ignore else */
        if (iframe.current != null) {
            iframe.current.contentWindow.addEventListener("keydown", onKeyDown);
            iframe.current.contentWindow.document.addEventListener(
                "textlayerrendered",
                onPdfReady,
                true
            );
        }
    }, [iframe, onPdfReady, onKeyDown]);

    return { onLoad };
};

export default usePdfArrowNavigation;
