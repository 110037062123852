import { useUpdateEffect } from "hooks";
import { useSelector } from "react-redux";
import { toastStyled, dismissToast, toastPosition, toastId } from "data/toast";
import { NotificationStyle } from "data/types";
import styles from "./NetworkStatus.module.css";
import * as s from "data/reducers/selectors";
import loc from "i18next";
import { offlineQueueStorage } from "data/storage";

const useNetworkStatus = () => {
    const isOnline = useSelector(s.isOnlineSelector);

    useUpdateEffect(() => {
        dismissToast(toastId.NetworkStatus);
        if (isOnline) {
            offlineQueueStorage.length().then((count) =>
                toastStyled({
                    message:
                        count === 0
                            ? loc.t("common:offline.connected")
                            : loc.t("common:offline.connectedSyncing", {
                                  count,
                              }),
                    icon: "fa-regular fa-check",
                    style: NotificationStyle.Success,
                    toastId: toastId.NetworkStatus,
                    className: styles.toast,
                    position: toastPosition.TOP_CENTER,
                })
            );
        } else {
            toastStyled({
                message: "$common:offline.lost",
                icon: "triangle-exclamation",
                style: NotificationStyle.Warning,
                toastId: toastId.NetworkStatus,
                className: styles.toast,
                autoClose: false,
                position: toastPosition.TOP_CENTER,
            });
        }
    }, [isOnline]);

    return null;
};

export { useNetworkStatus };
