import {
    call,
    put,
    take,
    actionChannel,
    race,
    flush,
    cancelled,
} from "redux-saga/effects";
import { buffers } from "redux-saga";
import actionTypes from "data/actions/actionTypes";
import { docThumbnailResultAction } from "data/actions";
import { fetchThumbnail } from "data/api";
import Constants from "data/constants";

function* thumbnailsSaga() {
    // 1- Create a channel for request actions
    const requestChan = yield actionChannel(
        actionTypes.DOCS_THUMBNAIL_REQUEST,
        buffers.expanding()
    );
    while (true) {
        // 2- take from the channel
        const { payload } = yield take(requestChan);

        // support cancellation
        // https://github.com/redux-saga/redux-saga/issues/1046
        // eslint-disable-next-line
        const { task, cancel } = yield race({
            task: call(getThumbnail, payload),
            cancel: take(actionTypes.CURRENTITEM_SET),
        });

        /* istanbul ignore next */
        if (cancel !== undefined) {
            yield flush(requestChan);
            // Don't need these actions, do nothing.
        }
    }
}

export function* getThumbnail(payload) {
    // https://decembersoft.com/posts/redux-saga-abort-controller-cancel-api-calls/
    const abortController = new AbortController();

    try {
        const result = yield call(
            fetchThumbnail,
            payload.itemUri,
            abortController.signal
        );

        yield put(
            docThumbnailResultAction({
                itemUri: payload.itemUri,
                status: Constants.OK,
                result,
            })
        );
    } catch (e) {
        yield put(
            docThumbnailResultAction({
                itemUri: payload.itemUri,
                status: Constants.ERROR,
                message: e.message,
            })
        );
    } finally {
        if (yield cancelled()) {
            // Cancel the API call if the saga was cancelled
            abortController.abort();
        }
    }
}

export default thumbnailsSaga;
