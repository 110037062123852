import copy from "copy-to-clipboard";
import { toastActionResult } from "data/toast";
import loc from "i18next";

/**
 * copies current itemUri to clipboard
 *
 * @param {{ itemUri: string }} { itemUri }
 */

class clipboard_copyItemUri {
    logger: () => any = toastActionResult;
    shouldRender: boolean = false;
    execute = async ({ itemUri }: Context): Promise<boolean> =>
        new Promise((resolve) => {
            copy(itemUri, {
                message: loc.t("itemActions:clipboard_copyItemUri.message"),
                format: "text/plain",
                onCopy: (clipboardData: Object) => resolve(true),
            });
        });
    report = async (success: boolean): Promise<boolean> => {
        this.logger(success === true, "itemActions:clipboard_copyItemUri");
    };
}
export default clipboard_copyItemUri;
