// @flow
import React, { PureComponent } from "react";
import {
    type MapLocationInfo,
    type Location,
    type RasterLocation,
    type MapEditLocationResult,
    type MapProviderConfig,
} from "data/types";
import { userLanguage } from "data/storeHelper";
import qs from "data/queryString";
import { fetchRasterMapLayers } from "data/api";
import NoData from "components/NoData";
import { correlationId } from "data/storeHelper";
import { CurrentItemContext } from "data/context";

type Props = {
    /** provider config to use
     * @default null retrieve default mapProvider from config.general.geo.name/key
     */
    mapProvider: ?MapProviderConfig,
    /** list of locations (markers) to show on the map */
    locations: ?Array<MapLocationInfo>,
    /** whether to render a "center" draggable pin marker */
    useMarker?: boolean,
    /** the current radius circle (in meters) - used by GeoRadiusInput */
    radius?: number,
    /** callback when user clicks an empty space on the map (only when useMarker=false & mapProvider.onClick exists)
     * @param {RasterLocation} rasterLocation the location the user clicked on the map
     */
    onClick?: (rasterLocation: RasterLocation) => void,
    /** callback after iframe loaded
     * @param  {bool} success whether the iframe was loaded successfully
     */
    onLoad?: (success: boolean) => void,
    /** current itemUri (Note: Use MapViewer.WithContext to get current itemUri from CurrentItem.Provider) */
    itemUri: string,
};

type State = {
    editLocationResult: ?MapEditLocationResult,
    fetchError: ?Object,
};

export class MapViewer extends PureComponent<Props, State> {
    _iframe: HTMLIFrameElement | null;

    constructor(props: Props) {
        super(props);
        this._iframe = React.createRef();
        this.state = {
            fetchError: null,
            editLocationResult: {
                location:
                    props.locations != null && props.locations.length > 0
                        ? props.locations[0]
                        : null,
                area:
                    props.locations != null && props.locations.length > 0
                        ? props.locations[0].area
                        : null,
                radius: props.radius,
            },
        };

        // validate raster configuration
        /* istanbul ignore next */
        if (
            (props.mapProvider == null ||
                props.mapProvider.isRaster !== true) &&
            props.locations &&
            props.locations.length > 0
        ) {
            // no .isRaster? make sure locations are not raster positions and warn user!
            const anyRaster = props.locations.find((l) =>
                Object.prototype.hasOwnProperty.call(l, "rasterId")
            );
            if (anyRaster) {
                this.state.fetchError = {
                    message:
                        "Raster maps require manual configuration - Please review the Documentation!",
                };
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        /* istanbul ignore next */
        if (
            this.props.locations !== prevProps.locations &&
            this.props.locations != null
        ) {
            this._addLocationsToMap(this.props.locations);
        }
    }

    SetCenterMarker = (location: Location) => {
        /* istanbul ignore next */
        if (this._iframe.current == null) return;
        this._iframe.current.contentWindow.setCenterMarker(location);
        this.setState((prevState) => ({
            editLocationResult: {
                ...prevState.editLocationResult,
                location,
            },
        }));
    };

    /** retrieve current location edit result
     * @returns {number} current radius (in meters)
     */
    GetEditLocationResult = (): MapEditLocationResult =>
        this.state.editLocationResult;

    _fetchRasterLayers = (): Promise<any> =>
        fetchRasterMapLayers({
            itemUri: this.props.itemUri,
            rasterMapIdSelector: this.props.mapProvider.rasterMapIdSelector,
        })
            .then((mapLayers) => mapLayers.layers)
            .catch((fetchError) => {
                console.error(fetchError);
                this.setState({ fetchError });
                return null;
            });

    _callOnLoad = (success: boolean) =>
        typeof this.props.onLoad === "function" && this.props.onLoad(success);

    _frameLoaded = async () => {
        let layers = null;
        if (this.props.mapProvider && this.props.mapProvider.isRaster) {
            layers = await this._fetchRasterLayers();
            if (layers == null) {
                this._callOnLoad(false);
                return;
            }
        }

        /* istanbul ignore next */
        if (this._iframe.current == null) {
            this._callOnLoad(false);
            return;
        }
        if (
            this.props.radius == null &&
            typeof this._iframe.current.contentWindow.showLocations ===
                "function"
        ) {
            this._iframe.current.contentWindow.showLocations(
                this.props.locations,
                this.props.useMarker !== false,
                /* istanbul ignore next */ (
                    editLocationResult: MapEditLocationResult | RasterLocation
                ) => {
                    if (this.props.useMarker) {
                        // editing mode
                        this.setState({ editLocationResult });
                    } else if (
                        this.props.mapProvider &&
                        this.props.mapProvider.onClick &&
                        typeof this.props.onClick === "function"
                    ) {
                        this.props.onClick(editLocationResult);
                    }
                },
                layers
            );
            this._callOnLoad(true);
        } /* istanbul ignore else */ else if (
            this.props.radius != null &&
            this.props.locations.length > 0 &&
            typeof this._iframe.current.contentWindow.searchRadius ===
                "function"
        ) {
            this._iframe.current.contentWindow.searchRadius(
                this.props.locations[0],
                this.state.editLocationResult.radius,
                /* istanbul ignore next */ (
                    editLocationResult: MapEditLocationResult
                ) =>
                    this.setState({
                        editLocationResult,
                    })
            );
            this._callOnLoad(true);
        }

        this._callOnLoad(
            typeof this._iframe.current.contentWindow.showLocations ===
                "function" ||
                typeof this._iframe.current.contentWindow.searchRadius ===
                    "function"
        );
    };

    _addLocationsToMap = (locations?: Array<MapLocationInfo>) => {
        /* istanbul ignore next */
        if (this._iframe.current == null) return;
        this._iframe.current.contentWindow.showMoreLocations(locations);
    };

    _getMapUrl = (): string => {
        const providerName =
            (this.props.mapProvider && this.props.mapProvider.name) ||
            window.CONFIG.general.geo.name ||
            "leaflet-osm";
        const providerKey =
            (this.props.mapProvider && this.props.mapProvider.key) ||
            window.CONFIG.general.geo.key;
        return (
            (process.env.PUBLIC_URL || "") +
            `/__map/${providerName}/index.html?` +
            qs.stringify({
                lang: userLanguage(),
                key: providerKey,
                correlationId: correlationId(),
            })
        );
    };

    render() {
        const { fetchError } = this.state;
        if (fetchError == null) {
            return (
                <iframe
                    ref={this._iframe}
                    onLoad={this._frameLoaded}
                    title="MapViewer"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen={true}
                    src={this._getMapUrl()}
                />
            );
        }

        // custom error message handling
        return (
            <NoData locContext="map">
                <div>
                    <br />
                    Error: {fetchError.message}
                </div>
            </NoData>
        );
    }
}

const MapViewerWithContext = (props: Props) => (
    <CurrentItemContext.Consumer>
        {(itemUri) => <MapViewer {...props} itemUri={itemUri} />}
    </CurrentItemContext.Consumer>
);

MapViewer.WithContext = MapViewerWithContext;
export default MapViewer;
