export const PickerAction = {
    Open: "picker.open",
    Select: "picker.select",
    Cancel: "picker.cancel",
};

export const DefaultAction = {
    Browse: "browse",
    Search: "search",
    GeoInfo: "geo.info",
    GeoInfoLegacy: "geo-info",
    Exec: "exec",
    Picker: PickerAction,
};
