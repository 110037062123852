import React, { useMemo, useCallback } from "react";
import { useEffectOnce, usePersistSetting } from "hooks";
import SearchInput from "@hs/search-input";
import loc from "i18next";
import ToggleButtonGroup from "react-bootstrap/lib/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/lib/ToggleButton";
import Icons from "@hs/icons";
import styles from "./TreeFilter.module.css";
import * as s from "data/reducers/selectors";
import { useSelector } from "react-redux";

type Props = {
    /** current text filter */
    filter: string,
    /** whether to allow subfolders */
    allowSubfolders?: boolean,
    /** whether to include subfolders */
    includeSubfolders: boolean,
    /** callback when user changes filter and/or includeSubfolders */
    onChange: (filter: string, includeSubfolders: boolean) => void,
};

const TreeFilter = ({
    filter,
    includeSubfolders,
    onChange,
    allowSubfolders = false,
}: Props) => {
    const isOnline = useSelector(s.isOnlineSelector);
    const [persisted_includeSubfolders, persisted_setIncludeSubfolders] =
        usePersistSetting({
            name: "treeFilter.includeSubfolders",
            default: false,
        });

    // onMount make sure we use the persisted value
    useEffectOnce(() => {
        if (!isOnline) {
            onChange(filter, false);
        } else {
            if (includeSubfolders !== persisted_includeSubfolders) {
                onChange(filter, persisted_includeSubfolders);
            }
        }
    });

    const changeFilter = useCallback(
        (value: ?string) => onChange(value || "", includeSubfolders),
        [onChange, includeSubfolders]
    );

    const toggleSubfolders = useCallback(() => {
        persisted_setIncludeSubfolders(!includeSubfolders);
        onChange(filter, !includeSubfolders);
    }, [persisted_setIncludeSubfolders, onChange, includeSubfolders, filter]);

    const placeholderText = useMemo(
        () =>
            includeSubfolders
                ? loc.t("folder:treefilter.deep.text")
                : loc.t("folder:treefilter.shallow.text"),
        [includeSubfolders]
    );
    const toggleTitle = useMemo(
        () =>
            includeSubfolders
                ? loc.t("folder:treefilter.shallow.title")
                : loc.t("folder:treefilter.deep.title"),
        [includeSubfolders]
    );

    return (
        <div className={styles.filter}>
            {isOnline && allowSubfolders && (
                <ToggleButtonGroup
                    bsSize="small"
                    id="filterMode"
                    type="checkbox"
                    name="filterMode"
                    value={includeSubfolders}
                    onChange={toggleSubfolders}
                    className={styles.toggleGroup}
                    data-test="toggleButtonGroup"
                >
                    <ToggleButton
                        // bsStyle={bowser.is("mobile") ? undefined : "link"}
                        className={styles.buttonDeep}
                        key="fm-op"
                        value={true}
                        title={toggleTitle}
                        data-test={`toggleIncludeSubfolders-${!!includeSubfolders}`}
                    >
                        <Icons.Library
                            name="sitemap"
                            style={{ margin: 0, padding: 0 }}
                        />
                    </ToggleButton>
                </ToggleButtonGroup>
            )}
            <SearchInput
                borderless
                key="searchInput"
                onChange={changeFilter}
                placeholder={placeholderText}
                value={filter}
                className={styles.si}
            />
            {/* <ViewModes
                viewModes={[ListViewMode.List, ListViewMode.Grid]}
                viewMode={ListViewMode.List}
            /> */}
        </div>
    );
};
export default TreeFilter;
