import React from "react";
import AttributeLabel from "components/ExtraAttributes/AttributeLabel";
import ViewerControlBase from "components/ExtraAttributes/ViewerControl/ViewerControlBase";
import ValueFactory from "components/Attributes/SummaryAttribute/ValueFactory";
import styles from "./SummaryAttributeViewerControl.module.css";
import sharedStyles from "shared/styles.module.css";
import { getClassNames } from "data/utils";

const SummaryAttributeViewerControl = ({ header, name, values }) => (
    <ViewerControlBase>
        <AttributeLabel label={header} />
        <div
            className={getClassNames(
                "form-control",
                "form-control-static",
                sharedStyles.select_text,
                styles.form
            )}
        >
            {values?.map((value, i) => (
                <ValueFactory
                    className={`a-val ${value.$type}--form`}
                    key={`${name}-${i}`}
                    value={value}
                />
            ))}
        </div>
    </ViewerControlBase>
);

SummaryAttributeViewerControl.displayName = "SummaryAttributeViewerControl";
export default SummaryAttributeViewerControl;
