// @flow
import { useMemo } from "react";
import { downloadDocToken } from "data/api";
import { userLanguage } from "data/storeHelper";

type Props = {
    /** document download token */
    token: string,
    /** whether document is a PDF file */
    isPdf: boolean,
    /**pdf.js: search terms */
    searchTerm: ?string,
};

/**
 * returns a full ViewerUrl including one-time-access download link
 * @param {*} { token, pdf, searchTerm }
 * @returns
 */
const useViewerUrl = ({ token, isPdf, searchTerm }: Props) => {
    // builds the one-time-download link
    const getDocUrl: string = useMemo(
        () =>
            downloadDocToken(
                token,
                !isPdf, //this.props.inline
                isPdf
            ),
        [token, isPdf]
    );

    // builds the full pdfJs viewer url
    const getViewerUrl: string = useMemo(
        () =>
            isPdf
                ? `${
                      process.env.PUBLIC_URL || ""
                  }/__pdfjs/web/viewer.html?file=${encodeURIComponent(
                      getDocUrl
                  )}#locale=${userLanguage()}${
                      searchTerm != null ? `&search=${searchTerm}` : ""
                  }`
                : getDocUrl,
        [isPdf, getDocUrl, searchTerm]
    );

    return getViewerUrl;
};

export default useViewerUrl;
