// @flow
import React from "react";
import { openActionPanel } from "containers/ActionPanel";
import Icons from "@hs/icons";
import Breadcrumb from "components/Breadcrumb";
import Attributes from "components/Attributes";
import styles from "./ItemActionPanel.module.css";
import { type BreadcrumbResultDTO } from "data/types";
import { getFileExtension, getLocalizedText } from "data/utils";
import ActionCategory from "components/ActionPanel/ActionCategory";
import loc from "i18next";

export const RowTitle = ({
    title,
    ext,
    icon,
    color,
}: {
    title?: string,
    ext?: SummaryAttributeValueExtension | string,
    icon?: string,
    color?: string,
}) => (
    <>
        {ext && (
            <Icons.Custom
                className={styles.icon}
                ext={ext?.value || ext}
                icon={icon || ext?.icon}
                color={color || ext?.color}
            />
        )}
        <span className={styles.title}>{title || "Actions"}</span>
    </>
);
RowTitle.displayName = "RowTitle";

export const RowBody = ({
    thumbnail,
    breadcrumb,
    attributes,
}: {
    thumbnail?: React.Element,
    breadcrumb?: BreadcrumbResultDTO,
    attributes?: Array<Array<string>>,
}) => (
    <ActionCategory
        category={{
            id: "$Overview",
            name: loc.t("actionCategory:overview.name"),
            icon: "fa-regular fa-pen",
        }}
    >
        <div className={styles.wrapper}>
            {thumbnail}
            {breadcrumb && (
                <Breadcrumb
                    isReadonly={true}
                    siteType={breadcrumb.siteType}
                    parts={breadcrumb.parts}
                    className={styles.bc}
                />
            )}
            {attributes && (
                <Attributes
                    style={{ marginTop: 15, marginBottom: 6 }}
                    attributes={attributes}
                />
            )}
        </div>
    </ActionCategory>
);
RowBody.displayName = "RowBody";

type Props = {
    /** the name of the item */
    title?: string,
    /** file extension (if document and known) */
    ext?: SummaryAttributeValueExtension | string,
    /** optional icon */
    icon?: string,
    /** optional icon color */
    color?: string,
    /** contextProps to pass to commandActions */
    contextProps: {
        itemUri: string,
        isDoc: boolean,
        formatId: number,
        name?: string,
        ext?: string,
        icon?: string,
        color?: string,
    } & Object,
    /** file thumbnail (if document) */
    thumbnail?: React.Element,
    /** item breadcrumb */
    breadcrumb?: BreadcrumbResultDTO,
    /** item attribute values */
    attributes?: Array<Array<string>>,
    /** Target view (override for current)*/
    targetView?: string,
};

const parseName = (name) =>
    typeof name !== "string" &&
    name &&
    Object.prototype.hasOwnProperty.call(name, "value")
        ? name.value
        : name;

const parseTitle = (title, parsedName) => {
    let parsedTitleOrName = title || parsedName;
    return `${parsedTitleOrName}`.startsWith("$folder:")
        ? getLocalizedText(parsedTitleOrName)
        : parsedTitleOrName;
};

export /**
 * opens an actionPanel for the given itemUri (can be a Document or Folder)
 */
const openItemActionPanel = ({
    title,
    ext,
    icon,
    color,
    contextProps,
    thumbnail,
    breadcrumb,
    attributes,
    targetView,
}: Props) => {
    const parsedName = parseName(contextProps && contextProps.name);
    const parsedTitle = parseTitle(title, parsedName);

    openActionPanel({
        title: (
            <RowTitle
                title={parsedTitle}
                ext={
                    ext ||
                    contextProps?.ext ||
                    (contextProps?.isDoc &&
                        parsedName &&
                        getFileExtension(parsedName))
                }
                icon={icon || contextProps?.icon}
                color={color || contextProps?.color}
            />
        ),
        body: (thumbnail || breadcrumb || attributes) && (
            <RowBody
                thumbnail={thumbnail}
                breadcrumb={breadcrumb}
                attributes={attributes}
            />
        ),
        contextProps,
        targetView,
    });
};
