// @flow
import { type ConstantsEnum } from "./constants";
import { toast } from "react-toastify";

export type Dictionary<K, T> = { [key: K]: T };

// --------- ApiCatalog

export const HttpMethod = {
    GET: "GET", // default
    PUT: "PUT",
    DELETE: "DELETE",
    POST: "POST",
    HEAD: "HEAD",
};
export type HttpMethodEnum = $Values<typeof HttpMethod>;

export const ContentType = {
    NONE: -1,
    AUTO: 0, // default (identify json / text by Response.ContentType)
    JSON: 1,
    BLOB: 2,
    TEXT: 3,
};
export type ContentTypeEnum = $Values<typeof ContentType>;

// https://developers.google.com/web/tools/workbox/modules/workbox-strategies
export const CacheStrategy = {
    None: -1,
    StaleWhileRevalidate: 0,
    CacheFirst: 1,
    NetworkFirst: 2,
    NetworkOnly: 3,
    CacheOnly: 4,
};
export type CacheStrategyEnum = $Values<typeof CacheStrategy>;

/** Default Cache Name used by service-worker */
export const DEFAULT_CACHE_NAME = "api";

export type OfflineSettings = {
    /**
     * Whether offline editing is allowed. Will then be by data/offline/offlineQueue.js
     * @default {boolean} false
     */
    allowEdit?: boolean,
    /**
     * Whether to show user a toast that item has been queued
     * @default {boolean} false
     */
    showToast?: boolean,
    /**
     * Result object to return when called offline
     */
    transformer?: (params: Object) => Object,
};

export type CacheSettings = {
    /**
     * Workbox Cache strategy to use
     * @description https://developers.google.com/web/tools/workbox/modules/workbox-strategies
     * @default CacheStrategy.NetworkOnly
     * @type {CacheStrategyEnum}
     */
    strategy?: CacheStrategyEnum,

    /**
     * Specify which params to use when creating the cacheKey
     */
    keyParams?: (params) => Object,

    // ApiManagerMakeAvailableOffline will transform result before caching
    transformer?: (data: Object) => Object,

    depsAnalyzer: (data: FormResultDTO, params: Object) => Array<ApiMethodRef>,
};

export type ApiMethod = {
    url: string,
    /**
     * @default HttpMethod.GET
     * @type {HttpMethodEnum}
     */
    method?: HttpMethodEnum,
    params?: Object,
    contentType: ContentTypeEnum,
    cache?: boolean | CacheSettings,
    offline?: OfflineSettings,
};

export type ApiMethodRef = {
    name: string,
    params?: Object,
    signal?: AbortSignal,
};

// const OperationTypeEnum = {
//     ReadOnly: 0,
//     WriteOnly: 1,
//     WriteResilient: 2,
// }
// when resilient -> connect to which Readonly method should return it (+transformer)

export const CustomHttpHeader = {
    /** name of HTTP header for CacheStrategy passed by ApiManager and parsed by service-worker.js */
    CacheStrategy: "X-WB-CACHE-STRATEGY",
    /** name of HTTP header for custom CacheKey passed by ApiManager and parsed by service-worker.js */
    CacheKey: "X-WB-CACHE-KEY",
    /** used by the server to correlate logging events (in Root.js) */
    CorrelationId: "x-correlation-id",
    /** Bearer token */
    Authorization: "authorization",
    /** instead not using standard Authorization header */
    CustomAuthorization: "X-inPoint-Auth",
    /** used to pass original secureParams token */
    SecureParams: "X-SECURE-PARAMS",
    /** "1"=running /mini/ view, "0"=full */
    MiniView: "X-MINI-VIEW",
    /** itemUri when storing in offline queue */
    OfflineItemUri: "x-wb-api-uri",
    /** apiCatalog.name ref when storing in offline queue */
    OfflineApiName: "x-wb-api-name",
    /** used by the server/Controllers to identify offline requests; "true" when online */
    OfflineSync: "x-wb-sync",
};

// --------- Offline

export type OfflineQueueItem = {
    name: string,
    params?: any,
    retries?: number,
    error?: any,
};

export const OfflineQueueItemType = {
    ApiCall: "api",
    UppyFile: "uppy",
};
export type OfflineQueueItemTypeEnum = $Values<typeof OfflineQueueItemType>;

// --------- Toggles

export type ToggleItemDTO = {
    value: string,
    text: string,
    icon?: string,
    count?: number,
    status?: string,
    isSelected: boolean,
    isHidden?: boolean,
    isDisabled?: boolean,
};

export type ToggleDTO = {
    name: string,
    isMultiple: boolean,
    toggles: Array<ToggleItemDTO>,
};

// --------- SiteItems

export const SiteType = {
    NotSet: 0,
    GlobalSites: 1,
    MySites: 2,
    SharedSites: 3,
    RecycleBin: 4,
    MailBox: 5,
    AUSites: 6,
};
export type SiteTypeEnum = $Values<typeof SiteType>;

export const ViewNameType = {
    folder: 0,
    flat: 1,
    mailbox: 2,
};
export type ViewNameTypeEnum = $Values<typeof ViewNameType>;

export const SystemWebDavName = {
    GlobalSite: "GLOBALSITE",
    MySite: "MYSITE",
    SharedSite: "SHARESITE",
    MailBox: "MAILBOX",
    RecycleBin: "RECYCLEBIN",
};
export type SystemWebDavNameEnum = $Values<typeof SystemWebDavName>;

export const SidebarItemSource = {
    System: 0,
    Custom: 1,
    User: 2,
    Offline: 3,
};
export type SidebarItemSourceEnum = $Values<typeof SidebarItemSource>;

export type SidebarItemDTO = {
    id?: string,
    source: SidebarItemSourceEnum,
    webDavName: SystemWebDavNameEnum | string,
    caption: string,
    itemUri: string,
    mini: boolean,
    siteType: SiteTypeEnum,
    viewName: ViewNameTypeEnum,
    shouldBeVisible?: Array<string>,
};

// --------- Breadcrumb

export type BreadcrumbPartDTO = {
    text: string,
    itemUri: string,
};

export type BreadcrumbResultDTO = {
    text: string,
    siteType: SiteTypeEnum,
    parts: Array<BreadcrumbPartDTO>,
    itemUri?: string,
    version?: number,
    formatId: number,
};

// --------- NavigationItems

export const NavigationItemType = {
    Home: "home",
    News: "news",
    Report: "report",
    Search: "search",
    SearchResult: "searchResult",
    Wf: "wf",
    Upload: "upload",
    CommandAction: "commandAction",
};
export type NavigationItemTypeEnum = $Values<typeof NavigationItemType>;

export type CommandActionPropsDTO = {
    name: string,
    props?: Object,
};

export type NavigationItemDTO = {
    id: string,
    type: NavigationItemTypeEnum,
    icon: ?string,
    title: ?string,
    description: ?string,
    href: ?string,
    commandAction: ?CommandActionPropsDTO,
    shouldBeVisible?: Array<string>,
};

// ------ Attributes

export const SummaryAttributeValueType = {
    FacePile: "face-pile",
    Group: "group",
    User: "user",
    Progress: "progress",
    VisualRules: "visual-rules",
    Enum: "enum",
    TextLink: "text-link",
    Filesize: "filesize",
    ReactElement: "react-element",
    Datetime: "datetime",
    Extension: "extension",
};

export type SummaryAttributeValueTypeEnum = $Values<
    typeof SummaryAttributeValueType
>;
export type SummaryAttributeValue = {
    type: SummaryAttributeValueTypeEnum,
};

export type SummaryAttributeDTO = {
    caption: string,
    name?: string,
    value: SummaryAttributeValue,
    format?: string,
};

export const PrincipalType = {
    User: "user",
    Group: "group",
};

export type PrincipalTypeEnum = $Values<typeof PrincipalType>;

export const SharePrincipalType = {
    User: "user",
    Group: "group",
    Share: "share",
};
export type SharePrincipalTypeEnum = $Values<typeof SharePrincipalType>;

type IPrincipalDTO = {
    $type: PrincipalTypeEnum,
    id: int,
    displayName: string,
};

type ISharePrincipalDTO = {
    $type: SharePrincipalTypeEnum,
};

export type GroupPrincipalDTO = IPrincipalDTO & ISharePrincipalDTO;

export type UserPrincipalDTO = IPrincipalDTO &
    ISharePrincipalDTO & {
        description?: string,
        email?: string,
    };

export type SummaryAttributeValuePrincipal =
    | UserPrincipalDTO
    | GroupPrincipalDTO;

export type SummaryAttributeValueProgress = SummaryAttributeValue & {
    progress: Array<any>,
};

export type SummaryAttributeValueFacePile = SummaryAttributeValue & {
    total?: number,
    onMoreCommand?: CommandActionPropsDTO,
    pile: Array<SummaryAttributeValuePrincipal>,
};

export type SummaryAttributeValueTextLink = SummaryAttributeValue & {
    value: string,
    rawValue?: string,
    caption?: string,
    isDoc?: boolean,
    icon?: string,
    isIconLast?: boolean,
};

export type SummaryAttributeValueFilesize = SummaryAttributeValue & {
    value: string,
    rawValue?: string,
};

export type SummaryAttributeValueDatetime = SummaryAttributeValue & {
    value: string,
    rawValue?: Date,
};

export type SummaryAttributeValueReactElement = SummaryAttributeValue & {
    element: React.ReactElement,
};

// export type SummaryAttributeValueUser = SummaryAttributeValuePrincipal & {
// }

// export type SummaryAttributeValueGroup = SummaryAttributeValuePrincipal & {
// }

export type IconDTO = {
    iconUrl: string,
    iconSize?: Array<number>,
};

export type MarkerDTO = IconDTO & {
    iconAnchor?: Array<number>,
};
export type SummaryAttributeValueVisualRules = SummaryAttributeValue & {
    value: any,
    icon?: IconDTO,
    marker?: MarkerDTO,
    color?: string,
};

export type SummaryAttributeValueExtension = SummaryAttributeValue & {
    value: string,
    icon?: string,
    color?: string,
};

// --------- UserInfo

// what we actually received from Server.IOfflineService
export type OfflineItemDTO = {
    id: string,
    itemUri: string,
    isDoc: boolean,
    lastUpdate: ?Date,
    breadcrumb: BreadcrumbResultDTO,
};

export const OfflineSyncStatus = {
    Unknown: "Unknown",
    Synced: "Synced",
    SyncedWithErrors: "SyncedWithErrors",
    Syncing: "Syncing",
    Conflict: "Conflict",
    Error: "Error",
};
export type OfflineSyncStatusEnum = $Values<typeof OfflineSyncStatus>;

// what we actually store in our Redux-Store
export type OfflineItemStatus = OfflineItemDTO & {
    status: OfflineSyncStatusEnum,
    took?: number,
    at: Date,
};

export type UserInfoResultDTO = {
    id: number,
    displayName: number,
    isAdmin: boolean,
    language: string,
    status: ConstantsEnum,
    wfEnabled: boolean,
    features: Array<string>,
    sites: Array<SidebarItemDTO>,
    favorites: Array<string>,
    decimalSeparator: string,
    thousandSeparator: string,
    avatar: string,
    hasOfflineItems: boolean,
};

// --------- UserSettings

export type UserSettingsDTO = {
    language: string,
};

export const NotificationType = {
    Toast: 0,
    API: 1,
};

export type NotificationTypeEnum = $Values<typeof NotificationType>;

export type UserSettingsResultDTO = {
    current: UserSettingsDTO,
    allLanguages: Array<{ name: string, code: string }>,
    notification?: NotificationTypeEnum,
    identityChangePasswordUri?: string,
    identity2FaUri?: string,
};

// --------- Folder's Tree

export type TreeItemDTO = {
    elementType: number,
    id: number,
    itemUri: string,
    numberOfChildren: number,
    webDavName: string,
    bc?: string,
};

export type TreeDTO = {
    isRoot: boolean,
    isBrowsable: boolean,
    totalCount: number,
    items: Array<TreeItemDTO>,
};

export const SpecialFoldersItemURIs = {
    SharedDocumentsUri: "shareddocuments",
    RecycledDocumentsUri: "recycleddocuments",
    RecycleBinUri: "recyclebin",
};

export type SpecialFoldersItemURIsEnum = $Values<typeof SpecialFoldersItemURIs>;
// --------- Item Status

/** @enum {number} */
export const ItemStatuses = {
    /** Nothing set */
    Nothing: 0,
    /** There is an reminder on this item. */
    Reminder: 1,
    /** This item is shared. */
    Share: 1 << 1,
    /** This item has a relation to another item. */
    Relation: 1 << 2,
    /** This item has a converted PDF of itself. */
    PDF: 1 << 3,
    /** This item has at least one Workflow-Report */
    WFReport: 1 << 4,
    /** This item has a note */
    Note: 1 << 5,
    /** This item has a link to another item. (Shortcut) */
    Link: 1 << 6,
    /** When this item is an msg and it has some attachments. */
    MailHasAttachment: 1 << 7,
    /** When this item is an msg and it was replied to someone. */
    MailReplied: 1 << 8,
    /** When this item is an msg and it was forwarded to someone. */
    MailForwarded: 1 << 9,
    /** When this item is checked out. */
    Locked: 1 << 10,
    /** When this item is has a DocTemplate applied. */
    Template: 1 << 11,
    /** There is a subscription on this item. */
    Subscribed: 1 << 12,
    /** This item managed via CloudSync. */
    CloudSync: 1 << 13,
    // Custom flags for inPoint.Web.Server only
    /** This item is marked for deletion. */
    Deleted: 1 << 28,
    /** Any of this items parents is marked for deletion. */
    ParentDeleted: 1 << 29,
    /** This item is marked for offline access. */
    //Offline: TBD,
    /** This item is signed. */
    Signed: 1 << 30,
};

export type ItemStatusesDTO = $Values<typeof ItemStatuses>;

// --------- DocInfo

export /**
 * @deprecated Will be removed in 2023.3
 */ type DocInfoResultDTO = {
    name: string,
    ext: string,
    size: number,
    hasPdf: boolean,
    isPdf: boolean,
    canPdf: boolean,
    isFlatAU: boolean,
    flags: number,
    itemStatus: ItemStatusesDTO,
    // workflow: any,
};

export /**
 * @deprecated Will be removed in 2023.3
 */ type DocInfoResultWithTokenDTO = DocInfoResultDTO & {
    token: string,
};

// --------- Documents

export const ListViewMode = {
    Thumbnails: "Thumbnails",
    List: "List",
    Grid: "Grid",
    Map: "Map",
    Calendar: "Calendar",
};
export type ListViewModeEnum = $Keys<typeof ListViewMode>;

export const DocListFilterOperators = {
    None: "None",
    Equals: "Equals",
    NotEquals: "NotEquals",
    LessThan: "LessThan",
    LessThanOrEqualsTo: "LessThanOrEqualsTo",
    GreaterThan: "GreaterThan",
    GreaterThanOrEqualsTo: "GreaterThanOrEqualsTo",
    Contains: "Contains",
    DoesNotContain: "DoesNotContain",
    //Like: "Like",
    //NotLike: "NotLike",
    StartsWith: "StartsWith",
    DoesNotStartWith: "DoesNotStartWith",
    EndsWith: "EndsWith",
    DoesNotEndWith: "DoesNotEndWith",
    Between: "Between",
    NotBetween: "NotBetween",
    DateRelative: "DateRelative",
};

export type DocListFilterOperatorsEnum = $Keys<typeof DocListFilterOperators>;

export const DocListColumnType = {
    StringValue: "StringValue",
    WebDavName: "WebDavName",
    FileSize: "FileSize",
    IntValue: "IntValue",
    BooleanValue: "BooleanValue",
    LongValue: "LongValue",
    ShortValue: "ShortValue",
    DoubleValue: "DoubleValue",
    DecimalValue: "DecimalValue",
    SingleValue: "SingleValue",
    DateTimeValue: "DateTimeValue",
    DateTimeOffsetValue: "DateTimeOffsetValue",
    ByteValue: "ByteValue",
    ObjectValue: "ObjectValue",
};

export type DocListColumnTypeEnum = $Keys<typeof DocListColumnType>;

export type DocColumnResultDTO = {
    name: string,
    caption: string,
    width: number,
    type: DocListColumnTypeEnum,
    canSort: boolean,
};

export type DocsResultDTO = {
    itemUri: string,
    pageSize: number,
    rowCount: number,
    rows: Object,
    cols: Array<DocColumnResultDTO>,
    flags: number,
};

export const SortDirectionOptions = {
    Ascending: "Ascending",
    Descending: "Descending",
};
export type SortDirectionEnum = $Keys<typeof SortDirectionOptions>;

export type DocListSortRequestDTO = {
    name: string,
    direction: SortDirectionEnum,
};

export type DocListFilterRequestDTO = {
    operator: DocListFilterOperatorsEnum,
    name: string,
    values: Array<string>,
};

export type DocListLayout = {
    filters?: Array<DocListFilterRequestDTO>,
    sorts?: Array<DocListSortRequestDTO>,
    cols?: Array<string>,
};

export const ActivityType = {
    create: "create",
    modifyContent: "modifyContent",
    modifyField: "modifyField",
    comment: "comment",
    locked: "locked",
    delete: "delete",
    unlocked: "unlocked",
};
export type ActivityTypeEnum = $Keys<typeof ActivityType>;

export type ActivityFieldDTO = {
    caption: string,
    oldVal: ?string,
    newVal: ?string,
};

export const RetentionType = {
    Default: "default",
    Forever: "forever",
    Custom: "custom",
};
export type RetentionTypeEnum = $Keys<typeof RetentionType>;

export type RetentionInfoDTO = {
    type: RetentionTypeEnum,
    months?: number,
    label?: string,
    date?: string,
    isExpired?: boolean,
};

export const ItemNoteType = {
    Default: "default",
    PdfHighlight: "pdfHighlight",
};

export type ItemNoteTypeEnum = $Values<typeof ItemNoteType>;

export const ItemNoteTypeIcon = {
    default: "fa-regular fa-comment",
    pdfHighlight: "fa-regular fa-comment-dots",
};

export type IItemNoteExtras = {
    $type: ItemNoteTypeEnum,
};

export type ActivityDTO = {
    id: number,
    on: DateTime,
    what: ActivityTypeEnum,
    extras?: IItemNoteExtras,
    userId: number,
    userName: string,
    text: string,
    changes: Array<ActivityFieldDTO>,
    retention?: RetentionInfoDTO,
};

export type ItemNoteExtrasDefault = IItemNoteExtras & {
    data: Array<any>,
};

export const PdfShapeType = {
    Rectangle: "rectangle",
};

export type PdfShapeTypeEnum = $Values<typeof PdfShapeType>;

export type IPdfShape = {
    $type: PdfShapeTypeEnum,
};

export type PdfShapeRectangle = {
    scale: number,
    pageNumber: number,
    x: number,
    y: number,
    width: number,
    height: number,
};

export type ItemNoteExtrasPdfHighlight = IItemNoteExtras & {
    shapes: Array<IPdfShape>,
};

// export type ItemNoteDTO = {
//     itemUri: string,
//     text: string,
//     id?: number,
//     parentId?: number,
//     extras?: IItemNoteExtras,
//     date?: DateTime,
//     creationDate?: DateTime,
//     creator?: IPrincipalDTO,
// };

export const CleanupKeepVersionType = {
    All: "all",
    Last: "last",
    PerDay: "perDay",
    PerUser: "perUser",
};
export type CleanupKeepVersionTypeEnum = $Keys<typeof CleanupKeepVersionType>;

export type CleanupKeepInfoDTO = {
    type: CleanupKeepVersionTypeEnum,
    count: number,
};

export type ActivitiesDTO = {
    items: Array<ActivityDTO>,
    keep: CleanupKeepInfoDTO,
    retention: RetentionInfoDTO,
};
// --------- Form

export type LastChangeState = {
    ns?: string,
    name: string,
    value: Object,
};

export const FormLookupType = {
    ip: 0,
    ea_lookup: 1,
    ea_ftlookup: 2,
    sf_ftlookup: 3,
};
export type FormLookupTypeEnum = $Values<typeof FormLookupType>;

export type FormLookupValue = {
    T: string,
    K?: string,
};

// export type GetLookupValuesRequestDTO = {
//     keyword: string,
//     pageNum: number,
//     itemUri: string,
//     name: string,
//     lookupType: FormLookupTypeEnum,
//     valueType: SearchValueTypeEnum,
//     values: any
// }

export type LookupValuesResultDTO = {
    values: Array<FormLookupValue>,
    hasMore: boolean,
};

export const NewFormType = {
    Document: "Document",
    Folder: "Folder",
};
export type NewFormTypeEnum = $Values<typeof NewFormType>;

export const ExtraAttributeEmbeddedGridRowStatus = {
    None: 0,
    Updated: 1,
    Added: 2,
    Deleted: 3,
};
export type ExtraAttributeEmbeddedGridRowStatusEnum = $Values<
    typeof ExtraAttributeEmbeddedGridRowStatus
>;

export type ExtraAttributeEmbeddedGridColumnDTO = {
    name: string,
    visible: boolean,
    width: number,
};

export type ExtraAttributesEmbeddedGridDTO = {
    columns: Array<ExtraAttributeEmbeddedGridColumnDTO>,
    //rows: Dictionary<number, Array<string>>,
    rows: Array<Array<string>>,
    pageSize: number,
    showFilter: boolean,
    sortColumn: string,
    sortDirection: SortDirectionEnum,
    seqIdColumn: number,
};

export type ExtraAttributeDataAuditLogDTO = {
    deletedIds: Array<number>,
    updatedRows: Object,
    newRows: Array<Array<Object>>,
};

export const DateTimeFormat = {
    Time: "Time",
    LongTime: "LongTime",
    DateTime: "DateTime",
    DateLongTime: "DateLongTime",
    Date: "Date",
    //For non-formatted DateTimes, e.g. Reminder, Share, DocListFilter
    JsonDate: "JsonDate",
    JsonDateEnd: "JsonDateEnd", //Time should point to end of day
    JsonDateTime: "JsonDateTime",
};

export type DateTimeFormatValues = $Values<typeof DateTimeFormat>;

type FieldDefinitionBaseDTO = {
    id: number,
    header?: string,
    name: string,
    value: null | any | ExtraAttributesEmbeddedGridDTO,
};

type FieldDefinitionWithExtrasBaseDTO = FieldDefinitionBaseDTO & {
    ro: boolean,
    req: boolean,
    format: string, // CustomFormats enum
    extra?: ?Object,
};

export type FieldDefinitionDTO = FieldDefinitionWithExtrasBaseDTO & {
    width: number,
    height: number,
};

export type FormFieldDTO = FieldDefinitionWithExtrasBaseDTO & {
    max?: number,
};

export const ItemLinkType = {
    Manual: 0,
    WorkflowReport: 1,
    Shortcut: 2,
};
export type ItemLinkTypeEnum = $Values<typeof ItemLinkType>;

type IFormResultDTO = {
    name: string,
};

export type FormResultDTO = IFormResultDTO & {
    formatId: number,
    isDoc: boolean,
    layoutXml?: string,
    attributes?: Array<FieldDefinitionDTO>,
    fields: Array<FormFieldDTO>,
    formTitle?: string,
    grids?: Array<ExtraAttributesEmbeddedGridDTO>,
    labelWidth: number,
    isEditAllowed: boolean,
    status: ItemStatusesDTO,
    etag: string,
};

export type LockResultDTO = {
    lockedById: number,
    lockedByName: string,
    lockedOnMachine: string,
    lockedDate: string,
};

export type DocFormResultDTO = FormResultDTO & {
    isLocked: boolean,
    lockInfo?: LockResultDTO,
};

export type FormFieldProps = {
    name?: string,
    header: string,
    value: null | any | ExtraAttributesEmbeddedGridDTO,
    width?: number,
    userid?: number,
    marker?: any,
    isGridCell?: boolean,
    formatId?: number,
};

export type FormFieldInfo = {
    Component: React.Component,
    props: FormFieldProps,
};

// --------- Workflow

export const WorkflowProvider = {
    inPointWorkflow: "inPointWf",
    AxonIvy: "AxonIvy",
};
export type WorkflowProviderEnum = $Values<typeof WorkflowProvider>;

export const WorkflowType = {
    Unknown: "unknown",
    Routing: "routing",
    Process: "process",
    ToDo: "todo",
};
export type WorkflowTypeEnum = $Values<typeof WorkflowType>;

export const WorkflowStartLinkMode = {
    Window: "window",
    Docked: "docked",
    Modal: "modal",
};
export type WorkflowStartLinkModeEnum = $Values<typeof WorkflowStartLinkMode>;

export type WorkflowDTO = {
    provider: string,
    id: string,
    icon?: string,
    name: string,
    description: string,
    startLink: string,
    startLinkMode: WorkflowStartLinkModeEnum,
    deadline?: string,
    progress?: number,
    itemUri?: string,
};

export type WorkflowAction = {
    name: string,
    icon?: string,
    caption: string,
    description?: string,
    page: string,
};

export type WorkflowInfoDTO = {
    provider: string,
    id: string,
    name: string,
    progress?: number,
    status?: string,
    attributes?: Array<SummaryAttributeDTO>,
    actions?: Array<WorkflowAction>,
};

export type WorkflowProviderDTO = {
    provider: string,
    caption: string,
    icon?: string,
};

// --------- Search

export const SearchOperator = {
    NotSet: 0,
    Contextor: 1,
    From: 2,
    To: 3,
    FromTo: 4,
    FromToWithTime: 5,
    FromWithTime: 6,
    ToWithTime: 7,
    Equals: 8,
    EqualsWithTime: 9,
    RelativeDate: 10,
    // #46483 remove not working date searchform Operators
    // FromRelativeTo: 11,
    // FromRelativeToWithTime: 12,
    // FromToRelative: 13,
    // FromWithTimeToRelative: 14,
    FromRelative: 15,
    ToRelative: 16,
    Contains: 17,
    StartsWith: 18,
    EndsWith: 19,
    GeoRadius: 20,
    GeoArea: 21,
    GeoRadiusIntersect: 22,
    GeoRadiusWithin: 23,
    GeoAreaIntersect: 24,
    GeoAreaWithin: 25,
    GeoAreaPoint: 26,
    ContextorContains: 27,
};
export type SearchOperatorEnum = $Values<typeof SearchOperator>;

export const SearchNodeType = {
    AllNodes: 0,
    LeafNode: 1,
    CustomNode: 2,
    Auto: 3,
};
export type SearchNodeTypeEnum = $Values<typeof SearchNodeType>;

export type SearchFormInfoDTO = {
    id: number,
    name: string,
    // scope: string,
    // itemUri: string,
    // breadcrumb: string,
    // image: string
};

export const SearchScope = {
    AllSites: 0,
    AllFolders: 1,
    CurrentFolder: 2,
};
export type SearchScopeEnum = $Values<typeof SearchScope>;

export const SearchValueType = {
    NotSet: 0,
    String: 1,
    RelativeDate: 2,
    DateTime: 3,
    FileSize: 4,
    ItemType: 5,
    Decimal: 6,
    GeoPoint: 7,
    GeoArea: 8,
    StringFieldOnly: 9,
    DecimalFieldOnly: 10,
    Link: 11,
    IUID: 100,
};
export type SearchValueTypeEnum = $Values<typeof SearchValueType>;

export type SearchBucketValueDTO = {
    value: string | number,
    caption?: string,
    count?: number,
};

export type SearchBucketDTO = {
    visible?: boolean,
    name: string,
    caption?: string,
    hideName?: boolean,
    hideMenu?: boolean,
    hideAllReset?: boolean,
    selected?: Array<string | number>,
    valueType?: SearchValueTypeEnum,
    buckets: Array<SearchBucketValueDTO>,
};

export const SearchRequestType = {
    Favorite: 0,
    Recent: 1,
    Freetext: 2,
    Form: 3,
    Url: 4,
};
export type SearchRequestTypeEnum = $Values<typeof SearchRequestType>;

export type SearchFilterDTO = {
    name: string,
    valueType: SearchValueTypeEnum,
    value: any,
    op: SearchOperatorEnum,
    boostLeafNode?: number,
    nodeType?: SearchNodeTypeEnum,
};

export type SearchSortDTO = {
    name: string,
    valueType: SearchValueTypeEnum,
    direction: SortDirectionEnum,
};

export const FakeResultItemType = {
    Add: 0,
    Edit: 1,
    Delete: 2,
};

export type FakeResultItemTypeEnum = $Values<typeof FakeResultItemType>;

export type SearchFakeResultItemDTO = {
    type: FakeResultItemTypeEnum,
    itemUri: string,
};

export type SearchRequestDTO = {
    id: number,
    name: ?string,
    searchType: SearchRequestTypeEnum,
    scope?: SearchScopeEnum,
    isCustom: boolean,
    searchText?: ?string,
    pageNum: number,
    itemUri?: string,
    filters: Array<SearchFilterDTO>,
    sorts: Array<SearchSortDTO>,
    fakeUris?: Array<SearchFakeResultItemDTO>,
};

export const SearchItemType = {
    Favorite: 0,
    Recent: 1,
    Freetext: 2,
    Form: 3,
    Url: 4, // passing Url parameters
};
export type SearchItemTypeEnum = $Values<typeof SearchItemType>;

export type SearchItemDTO = {
    name: string,
    searchText: ?string,
    scope?: SearchScopeEnum,
    id: number,
    searchType: SearchItemTypeEnum,
    param?: Array<string>,
    nodeType?: SearchNodeTypeEnum,

    // TODO those do not exist in server-side object!
    itemUri?: string,
    filters?: Array<SearchFilterDTO>,
    sorts?: Array<SearchSortDTO>,
    fakeUris?: Array<SearchFakeResultItemDTO>,
};

export type SAYTOptionDTO = {
    /* required by react-select */
    className?: string,
    label: string,
    /* our custom values */
    count: number,
    item: SearchItemDTO,
};

export type SearchResultRowDTO = {
    title: string,
    itemUri: string,
    isDoc: boolean,
    elementType: ?number,
    ext: string,
    summary: ?string,
    foundTerms: ?Array<string>,
    breadcrumb: BreadcrumbResultDTO,
    attributes: Array<SummaryAttributeDTO>,
};

type IGridColumn = {
    type: string,
    name: string,
    caption: string,
    width: number,
    canSort: boolean,
};
type IGridRow = any;

type IGridList = {
    cols: Array<IGridColumn>,
    rows: { [rowId: number]: IGridRow },
    pageSize: number,
};

export type SearchResultDTO = IGridList & {
    pageNum: number,
    totalCount: number,
    aggregations?: Array<SearchBucketDTO>,
    autoBrowseToItem?: boolean,
    listViewMode: ListViewModeEnum,
};

export type SearchCountResultDTO = {
    totalCount: number,
    totalTime: number,
    aggregations?: Array<SearchBucketDTO>,
};

// searchForms

export type SearchFieldDefinitionDTO = FieldDefinitionBaseDTO & {
    hidden?: boolean,
    valueType: SearchValueTypeEnum,
    op: SearchOperatorEnum,
    boostLeafNode?: number,
    nodeType?: SearchNodeTypeEnum,
    required?: boolean,
};

export type SearchFormResultDTO = IFormResultDTO & {
    layoutXml?: string,
    attributes?: Array<SearchFieldDefinitionDTO>,
    sort?: Array<SearchSortDTO>,
    itemUri: string,
};

// --------- Cards

export const CardType = {
    Rec: "rec",
    Fav: "fav",
    Task: "task",
    Action: "action",
};
export type CardTypeEnum = $Values<typeof CardType>;

export const CardItemType = {
    Folder: "folder",
    Doc: "doc",
    Search: "search",
    Task: "task",
    SearchForm: "searchForm",
    CommandAction: "commandAction",
    ArchiveTarget: "archiveTarget",
};
export type CardItemTypeEnum = $Values<typeof CardItemType>;

export type CardListRequest = {
    cardTypes: Array<CardType>,
    cardItemTypes: Array<CardItemTypes>,
};

export type CardItemResultDTO = {
    id: string,
    type: CardTypeEnum,
    itemtype: CardItemTypeEnum,
    siteType: SiteTypeEnum,
    image: ?string,
    name: string,
    desc?: string,
    uri: ?string,
    searchItem?: SearchItemDTO, // only for search
    bcText?: string, // only for recent folders,
    commandAction?: CommandActionPropsDTO,
    extras?: Object, // any additional properties
};

export type ItemActionViewConfig = {
    name: string,
    header?: string,
};

export type ItemActionConfigBase = {
    id?: string,
    visible?: boolean,
    itemUriRegex?: string,
    loc?: string, //only used in data/itemActions.js
    name: string,
    desc?: string,
    icon?: string,
    shouldBeVisible?: Array<string>,
    views?: Array<string>,
    view?: ItemActionViewConfig,
};

export type ItemActionCategoryConfig = ItemActionConfigBase & {
    renderHeader?: boolean,
    expanded?: boolean,
};

export type ItemActionConfig = ItemActionConfigBase & {
    category?: string,
    commandAction?: CommandActionPropsDTO,
};

export type ItemActionsConfigDTO = {
    categories: Array<ItemActionCategoryConfig>,
    actions: Array<ItemActionConfig>,
};

export type FormActionsConfigDTO = Array<ItemActionConfig>;

// ---------- Errors

export type ApiErrorEventId = {
    id: number,
    name: string,
};

export type ApiErrorResult = {
    detail: string | null,
    message: string,
    isError: boolean,
    eventId: ApiErrorEventId,
    extra?: any,
};

export type ApiError = {
    data: ApiErrorResult,
};

// ----------- NewsFeed

export type NewsFeedItemDTO = {
    date: Object,
    title: string,
    itemStatus: ItemStatusesDTO,
    name: string,
    ext: string,
    category: string,
    itemUri: string,
    isDoc: boolean,
    elementType: number,
    breadcrumb: BreadcrumbResultDTO,
    summary: string,
};

export type NewsFeedDTO = {
    items: Array<NewsFeedItemDTO>,
};

export type SubscribeResponseDTO = {
    id: number,
    name: string,
    itemUri: string,
};

export type SubscribeRequestDTO = {
    itemUri: string,
    name: string,
};

// ----------- Upload

/** whether TusHandler.cs should Create or Modify a document */
export const UploadMode = {
    CreateDocument: "CreateDocument",
    ModifyDocument: "ModifyDocument",
};

// ------------ Map

type MapProviderOnClickConfig = {
    // HACK will set commandAction.props.defaultValues[locationTargetFieldName] to clicked location
    locationTargetFieldName?: string,
    commandAction: CommandActionPropsDTO,
};

export type MapProviderConfig = {
    name?: string,
    enabled?: boolean,
    key?: string,
    isRaster?: boolean,
    rasterMapIdSelector?: string, // only when isRaster=true
    onClick?: MapProviderOnClickConfig,
    layers?: Array<string>, // references to public/__map/indoor-raster/*
};

export type Location = {
    latitude: number,
    longitude: number,
};

export type RasterLocation = {
    x: number,
    y: number,
    rasterId: string,
};

/** returned in callback methods from map providers */
export type MapEditLocationResult = {
    /** new Point location (or setRadius center) */
    location?: Location,
    /** setRadius setting */
    radius?: number,
    /** area path */
    area?: Array<Location>,
};

export type MapLocationInfo = Location & {
    /** field's common name */
    attribute: string,
    /** item's name */
    label: string,
    /** item's rendered InfoPopup in HTML */
    preview?: string,
    /** item's image thumbnail either in URL format or base64 blob */
    thumbnail?: string | Blob,
    /** marker custom icon */
    icon?: MarkerDTO,
    /** item's itemUri */
    itemUri?: string,
    /** optional polygon area points */
    area?: Array<Location>,
    /** whether this marker can be dragged/edited */
    allowEdit?: boolean,
};

// export const MapEditMode = {
//     Readonly: 0,
//     Point: 1,
//     Area: 2,
//     Radius: 3
// };

// export type MapEditModeEnum = $Values<typeof MapEditMode>;

// ---- Server Sent Events

export const ServerEventItemType = {
    document: "document",
    folder: "folder",
    activity: "activity",
    task: "task",
    search: "search",
    favorite: "favorite",
    subscription: "subscription",
    offline: "offline",
};

export type ServerEventItemEnum = $Values<typeof ServerEventItemType>;

export const ServerEventActionType = {
    notSet: 0,
    add: "add",
    modify: "modify",
    edit: "edit",
    delete: "delete",
    refresh: "refresh",
    view: "view",
};

export type ServerEventActionEnum = $Values<typeof ServerEventActionType>;

export type ServerEventData = {
    userId: number,
    /** ServerEventItemUriData */
    itemUri: ?string,
    parentItemUri: ?string,
    /** ServerEventSearchData */
    formId: ?number,
};

export type ServerEventMessage = {
    id: string,
    item: ServerEventItemEnum,
    action: ServerEventActionEnum,
    data: ServerEventData,
};

export const RefreshMode = {
    All: 0, // Auto refresh all
    Originator: 1, // Auto refresh Originator, ask Others
    Self: 2, // Auto refresh Originator, ignore Others
};

export type RefreshModeEnum = $Values<typeof RefreshMode>;

export type ImageResultDTO = {
    /** base64 encoded src string */
    src: string,
    /** indicates if src is a default or editable source */
    fallback: boolean,
};

// ------ ArrowNavigation

export type ArrowNavigationSetter = {
    /** source (itemUri) of master => to target source specific child windows only */
    setSource: (source: string) => void,
    /** target setter => for action panel context */
    setTarget: (target: string) => void,
    /** set DocModal rows => context */
    setRows: (rows: Array<any>) => void,
    /** callback to set */
    setCurrentIndex: (index: number) => void,
};

// ------ ItemFlows

export const ItemFlowDirection = {
    in: "in",
    out: "out",
};

export type ItemFlowDirectionEnum = $Values<typeof ItemFlowDirection>;

export const ItemFlowTemplateType = {
    Document: "Document",
    DocTemplate: "DocTemplate",
    Folder: "Folder",
    FolderTemplate: "FolderTemplate",
};

export type ItemFlowTemplateTypeEnum = $Values<typeof ItemFlowTemplateType>;

export type ItemFlowConfigDTO = ItemActionConfig & {
    isSuggested: boolean,
};

export type ItemFlowCategoryConfig = ItemActionConfigBase & {};

export type ItemFlowsConfigDTO = {
    selectFlowId?: string,
    categories: Array<ItemFlowCategoryConfig>,
    flows: Array<ItemFlowConfigDTO>,
};

export const UploadProvider = {
    Any: "Any",
    Uppy: "Uppy",
    Sync: "Sync",
};

export type UploadProviderEnum = $Values<typeof UploadProvider>;

export type NewItemResultDTO = {
    success: boolean,
    itemUri: string,
    name: string,
    formatId: number,
    elementType: number,
};

// ------ Loading

export const LoadingType = {
    Default: "Default",
    Status: "Status",
};

export type LoadingTypeEnum = $Values<typeof LoadingType>;

// ------ Raster maps

export type RasterMapLayerDTO = {
    id: string,
    name: string,
    itemUri: string,
    dim: Array<number>,
    zoom: Array<number>,
};

export type RasterMapResultDTO = {
    id: string,
    layers: Array<RasterMapLayerDTO>,
};

export const RasterMapLayerEditAction = {
    none: 0,
    delete: 1,
    add: 2,
    rename: 3,
};
export type RasterMapLayerEditActionEnum = $Values<
    typeof RasterMapLayerEditAction
>;

export type RasterMapLayerSaveRequestDTO = RasterMapLayerDTO & {
    editAction: RasterMapLayerEditAction,
};

export type RasterMapSaveRequestDTO = {
    id: string,
    layers: Array<RasterMapLayerSaveRequestDTO>,
};

export const LocalDateStringOption = {
    DateTimeLong: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    },
    DateTime: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    },
    Date: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    },
    // Browser does not natively support displaying only Time from Date Object
    // TimeLong: {
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    // },
    // Time: {
    //     hour: "2-digit",
    //     minute: "2-digit",
    // },
};

// ------ Reminder

export const ReminderPriority = {
    NotSet: 0,
    High: 1,
    Normal: 2,
    Low: 3,
};
export type ReminderPriorityEnum = $Values<typeof ReminderPriority>;

export const ReminderTypeFlags = {
    NotSet: 0,
    Calendar: 1,
    Task: 2,
    Mail: 4,
    CustomAction: 8,
};
export type ReminderTypeFlagsEnum = $Values<typeof ReminderTypeFlags>;

export const CustomActionState = {
    NotSet: 0,
    NotStarted: 1,
    Retry: 2,
    Error: 9,
    Done: 10,
    Abort: 11,
};

export type CustomActionStateEnum = $Values<typeof CustomActionState>;
export type CustomActionDTO = {
    id: string,
    displayName: string,
    state: CustomActionStateEnum,
    executedDate: DateTime,
    error: string,
};
export type ReminderDTO = {
    id?: int,
    itemUri: string,
    date: DateTime,
    creationDate?: DateTime,
    creator?: UserPrincipalDTO,
    recipient?: UserPrincipalDTO | GroupPrincipalDTO,
    reason: string,
    priority: ReminderPriorityEnum,
    type?: ReminderTypeFlagsEnum,
    isReadOnly: boolean,
    customAction?: CustomActionDTO,
};

export type EnumValue<T> = {
    value: T,
    label: string,
    icon?: string,
};

export type SummaryAttributeValueEnum<T> = SummaryAttributeValue & {
    enum: EnumValue<T>,
    value: T,
};

export const ReminderTypeFlagsEnumValues: Array<
    EnumValue<ReminderTypeFlagsEnum>
> = [
    {
        value: ReminderTypeFlags.Calendar,
        label: "$reminder:type.calendar",
        icon: "fa-regular fa-calendar",
    },
    {
        value: ReminderTypeFlags.Task,
        label: "$reminder:type.task",
        icon: "fa-regular fa-rectangle-list",
    },
    {
        value: ReminderTypeFlags.Mail,
        label: "$reminder:type.mail",
        icon: "fa-regular fa-envelope",
    },
];

export const ReminderPriorityEnumValues: Array<
    EnumValue<ReminderPriorityEnum>
> = [
    {
        value: ReminderPriority.High,
        label: "$reminder:priority.high",
        icon: "fa-regular fa-exclamation",
    },
    {
        value: ReminderPriority.Normal,
        label: "$reminder:priority.normal",
    },
    {
        value: ReminderPriority.Low,
        label: "$reminder:priority.low",
        icon: "down-long",
    },
];

// ----- Share

const AllowedShareActionFlags = {
    NotSet: 0,
    Link: 1,
    Web: 2,
    Sync: 4,
    Share: 8,
    Attachment: 16,
    Pdf: 32,
    Anonym: 64,
    WebShare: 128,
};
AllowedShareActionFlags.Default =
    AllowedShareActionFlags.Link |
    AllowedShareActionFlags.Web |
    AllowedShareActionFlags.Share;

export { AllowedShareActionFlags };

export type AllowedShareActionFlagsEnum = $Values<
    typeof AllowedShareActionFlags
>;

export const ShareType = {
    Anonym: "anonym", // SAS (not implemented yet)
    WebShare: "webshare", // Cloud Webshare
    Share: "share", // Create Share and Provide as Web/Client Link
    Link: "link", // Provide as Web/Client Link
    Attachment: "attachment", // Provide Document as Attachment via Mail
    Pdf: "pdf", // Provide Document as PDF via Mail
};

export type ShareTypeEnum = $Values<typeof ShareType>;

export const ShareAccessControlListType = {
    View: "view",
    Edit: "edit",
    Delete: "delete",
};

export type ShareAccessControlListTypeEnum = $Values<
    typeof ShareAccessControlListType
>;

export type ShareActionDTO = {
    allowedFlags: AllowedShareActionFlagsEnum,
    type: ShareTypeEnum,
    acl: ShareAccessControlListTypeEnum,
    expires?: DateTime,
    shareAs?: string,
};

export type SharePrincipalDTO = ISharePrincipalDTO & {
    principal: GroupPrincipalDTO | UserPrincipalDTO,
    acl: ShareAccessControlListTypeEnum,
};

export const ShareNotifyType = {
    Updated: "updated",
    All: "all",
    None: "none",
};

export type ShareNotifyTypeTypeEnum = $Values<typeof ShareNotifyTypeType>;

export type ShareDTO = {
    names: Array<string>,
    itemUris: Array<string>,
    action: ShareActionDTO,
    principals: Array<GroupPrincipalDTO | UserPrincipalDTO | SharePrincipalDTO>,
    message?: string,
    notify?: ShareNotifyTypeTypeEnum,
};

export type ShareFormDTO = ShareDTO & {
    message: string,
};

export type ShareAllowedDTO = {
    allowedAcl: Array<ShareAccessControlListTypeEnum>,
};

export type ShareAttachment = {
    name: string,
    ext: string,
    size: number,
};

export const SORT_BUCKET_NAME = "__SORT__";

export const GridListConstant = {
    ExtensionIcon: "ExtensionIcon",
    IconItemStatus: "IconItemStatus",
    ItemUri: "ITEMURI",
};

export type ItemContextResponseDTO = {
    isDoc: boolean,
    siteType: SiteTypeEnum,
    isFlatAU: boolean,
    isReadOnly: boolean,
    viewNameType: ViewNameTypeEnum,
    itemStatus?: ItemStatusesDTO,
    isFolderClassificationEditAllowed?: boolean,
    isEditAllowed?: boolean,
    isDocClassificationEditAllowed?: boolean,
    isDocEditAllowed?: boolean,
    isDocDownloadAllowed?: boolean,
    isDocAssignmentAllowed?: boolean,
    isDocSendByEmailAllowed?: boolean,
    inPointSyncIntegration?: boolean,
    isOffline?: boolean,
    name?: string,
    ext?: string,
    size?: number,
    customViewerUrl?: string,
    isContinuousPreview?: boolean,
    icon?: string,
    color?: string,
    toggles?: ToggleDTO,
};

const ItemContextsFlags = {
    NotSet: 0,
    ItemType: 1,
    ItemStatus: 2,
    ResultFlags: 4,
    Offline: 8,
    Pdf: 16,
    WebDavName: 32,
    Extension: 64,
    Filesize: 128,
    ViewerUrl: 256,
    ViewName: 512,
    Workflow: 1024,
    CustomIcon: 2048,
    Toggles: 4096,
};
ItemContextsFlags.Pdf =
    ItemContextsFlags.ItemStatus |
    ItemContextsFlags.Pdf |
    ItemContextsFlags.Extension;
ItemContextsFlags.All =
    ItemContextsFlags.ItemType |
    ItemContextsFlags.ItemStatus |
    ItemContextsFlags.ResultFlags |
    ItemContextsFlags.Offline |
    ItemContextsFlags.WebDavName |
    ItemContextsFlags.Pdf |
    ItemContextsFlags.Extension |
    ItemContextsFlags.Filesize |
    ItemContextsFlags.ViewerUrl |
    ItemContextsFlags.ViewName |
    ItemContextsFlags.Workflow |
    ItemContextsFlags.CustomIcon |
    ItemContextsFlags.Toggles;

export { ItemContextsFlags };

export type ItemContextsFlagsEnum = $Values<typeof ItemContextsFlags>;

export const ItemContextKey = {
    IsDoc: "isDoc",
    IsFlatAU: "isFlatAU",
    IsReadOnly: "isReadOnly",
    ViewName: "viewNameType",
    ItemStatus: "itemStatus",
    HasWorkflow: "hasWorkflow",
    WebDavName: "name",
    InPointSyncIntegration: "inPointSyncIntegration",
    IsFolderClassificationEditAllowed: "isFolderClassificationEditAllowed",
    IsEditAllowed: "isEditAllowed",
    Extension: "ext",
    Filesize: "size",
    IsDocClassificationEditAllowed: "isDocClassificationEditAllowed",
    IsDocEditAllowed: "isDocEditAllowed",
    IsDocDownloadAllowed: "isDocDownloadAllowed",
    IsDocAssignmentAllowed: "isDocAssignmentAllowed",
    IsDocSendByEmailAllowed: "isDocSendByEmailAllowed",
    IsOffline: "isOffline",
    HasPdf: "hasPdf",
    CanPdf: "canPdf",
    IsPdf: "isPdf",
    SiteType: "siteType",
    ViewerUrl: "customViewerUrl",
    IsContinuousPreview: "isContinuousPreview",
    CanBeShared: "canBeShared",
    Icon: "icon",
    IconColor: "color",
    Toggles: "toggles",
};
export type ItemContextKeyEnum = $Values<typeof ItemContextKey>;

export type ItemContextRequestDTO = {
    itemUri: string,
    formatId: number,
    itemContexts: ItemContextsFlagsEnum,
};

export const TransferType = {
    Copy: "copy",
    Move: "move",
    Link: "link",
};

export type TransferTypeEnum = $Values<typeof TransferType>;

export type TransferItemRequestDTO = {
    type: TransferTypeEnum,
    name: string,
    itemUri: string,
    targetItemUri: string,
};

export type MultiSelectionActions = {
    handleAdd: (id: number) => void,
    handleRemove: (id: number) => void,
    handleToggle: (id: number) => void,
    handleSelectAll: () => void,
    handleDeselectAll: () => void,
    handleInvert: () => void,
};

export type MultiSelectionHandlers = {
    onTouchEnd: () => void,
    onTouchMove: () => void,
    onTouchStart: () => void,
};

export type MultiSelectionContextProps = {
    handler: MultiSelectionHandlers,
    multiple: boolean,
    actions: MultiSelectionActions,
    isSelected: (index: number) => boolean,
    renderedBucket: React.Component,
    renderCheckbox: (index: number) => React.Component,
};

export type MultiSelectionProps = {
    actions: MultiSelectionActions,
    activeRows: any,
    availableRows: any,
    setAvailableRows: () => void,
    enable: () => void,
    disable: () => void,
    handler: MultiSelectionHandlers,
    multiple: boolean,
    isSelected: (index: number) => boolean,
};

export const NotificationStyle = {
    Info: "info",
    Success: "success",
    Warning: "warning",
    Error: "error",
    Default: "default",
};
export type NotificationStyleEnum = $Values<typeof NotificationStyle>;

export const NotificationStatusFlag = {
    New: 0,
    Read: 1,
    Pending: 2,
    Error: 4,
};
export type NotificationStatusFlagEnum = $Values<typeof NotificationStatusFlag>;

export type ToastProps = {
    message: string,
    icon?: string,
    details?: (props: ToastProps) => any,
    handleClick?: () => void,
};

export type ToastType = ToastProps & {
    style?: NotificationStyleEnum,
    status?: NotificationStatusFlagEnum,
    toastId?: string,
    isRouted?: boolean,
    autoClose?: number | boolean,
    position?: $Keys<typeof toast.POSITION>,
    className?: string,
};

export type GUID = string;
export type ToastId = string;
export type NotificationId = string;

export const PriorityType = {
    Low: 0,
    Intermediate: 1,
    High: 2,
};
export type PriorityTypeEnum = $Values<typeof PriorityType>;

export type NotificationDTO = {
    notificationId: NotificationId,
    icon: string,
    message: string,
    priority: PriorityEnum,
    style: NotificationStyleEnum,
    status: NotificationStatusFlagEnum,
    toast?: any,
    Component?: React.Component,
    timestamp: Date,
};

export const CommandActionStatusFlag = {
    NotSet: 0,
    Loading: 1,
    Done: 2,
    Success: 4,
    Partial: 8,
    Error: 16,
};
export type CommandActionStatusFlagEnum = $Values<
    typeof CommandActionStatusFlag
>;

export type CommandActionItem = {
    status: ConstantsEnum,
    itemUri: string,
    formatId: number,
    isDoc: boolean,
};

export type CommandActionLog = {
    timestamp: Date,
    message: string,
    status: ConstantsEnum,
    details: any,
    itemUri: string,
};

export type CommandActionRequest = {
    guid: GUID,
    status: CommandActionStatusFlagEnum,
    items: Array<CommandActionItem>,
    commandAction: string,
    log: Array<CommandActionLog>,
};

export const FormBarView = {
    Document: "document",
    DocModal: "docmodal",
    Folder: "folder",
    Search: "search",
    LockInfo: "lock_info",
    ArchiveTarget: "archive_target",
    SearchScope: "search_scope",
    Upload: "upload",
    ItemFlows: "itemflows",
};

export type FormBarViewEnum = $Values<typeof FormBarView>;
