/**
 * toggle operators of current form
 */

type Props = {
    showSearchOperators: SettingValueProps,
};

class searchForm_operator {
    execute = ({ showSearchOperators }: Props) => {
        if (showSearchOperators == null) return false;
        const { value, setValue } = showSearchOperators;
        setValue(!value);
        return true;
    };
}
export default searchForm_operator;
