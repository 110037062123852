// @flow
import { openFormModal } from "components/FormModal";

/**
 * opens FormModal to edit classification
 *
 * @param {{ itemUri: string }} { itemUri }
 */

class editClassification {
    execute = async ({ itemUri }: { itemUri: string }) => {
        await openFormModal({
            itemUri,
            viewName: "formmodal",
        });
        return true;
    };
}

export default editClassification;
