// @flow
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { type ItemFlowConfigDTO, type NewItemResultDTO } from "data/types";
import CustomItemFlow from "./itemFlow_custom";
import Folder from "./itemFlow_folder";
import Document from "./itemFlow_document";
import DocTemplate from "./itemFlow_docTemplate";
import FolderTemplate from "./itemFlow_folderTemplate";
import { formDefaultsSelector } from "data/reducers/selectors";

export type Props = {
    /** current flow selected by user */
    flow: ItemFlowConfigDTO,
    /** current viewName */
    viewName: string,
    /** target folder itemUri to create item in */
    itemUri: string,
    /** callback when user cancel */
    onCancel: () => void,
    /** callback when user wants to create the item
     * @async
     * @param {object} model the form's values
     * @returns {boolean} true if success, false otherwise
     */
    onCreate?: (model: Object) => Promise<boolean>,
    /** callback when item has been created
     * @param {boolean} success true if created successfully
     * @param {Object} error error object in case success is false
     * @param {NewItemResultDTO} data additional data related to new item
     */
    onCreated?: (
        success: boolean,
        error?: Object,
        data?: NewItemResultDTO
    ) => void,
    /** form default values */
    defaultValues?: Object,
    /** optional whether to preview the newly created item
     * @default true
     */
    previewNewItem?: boolean,
};

const flowsMappings = {
    itemFlow_custom: CustomItemFlow,
    itemFlow_document: Document,
    itemFlow_docTemplate: DocTemplate,
    itemFlow_folder: Folder,
    itemFlow_folderTemplate: FolderTemplate,
};

/** ItemFLows Type factory
 * each component takes care of:
 * 1. rendering whatever is required (e.g. Form or Upload)
 * 2. handling the creation process
 * 3. calling back onCreated with success/failure
 */
const Factory = ({
    defaultValues: defaultValuesFromProps,
    ...props
}: Props) => {
    // get default form values
    const defaultValuesFromStore = useSelector(formDefaultsSelector);
    const defaultValues = useMemo(
        () => ({ ...defaultValuesFromStore, ...defaultValuesFromProps }),
        [defaultValuesFromStore, defaultValuesFromProps]
    );
    const ComponentClass = flowsMappings[props.flow.commandAction.name];
    if (ComponentClass == null)
        return (
            <div>Unknown itemFlow named {props.flow.commandAction.name}</div>
        );
    else return <ComponentClass {...props} defaultValues={defaultValues} />;
};
Factory.displayName = "ItemFlows.Factory";
export default Factory;
