import React, { useState, useCallback, useEffect } from "react";
import { usePrevious } from "hooks";
import Radio from "react-bootstrap/lib/Radio";
import SiteSelect from "components/SiteSelect";
import RecentFolderSelect from "components/RecentFolderSelect";
import CommandActionButton from "components/CommandActions/CommandActionButton";
import loc from "i18next";
import { SearchScope, type SearchScopeEnum } from "data/types";

type Props = {
    className?: string,
    radioClassName?: string,
    selectClassName?: string,
    actionClassName?: string,
    searchScope: SearchScopeEnum,
    itemUri: string,
    onChange: (scope: SearchScopeEnum, itemUri?: string) => void,
    renderAction: boolean,
};

const SearchScopeSelect = ({
    className,
    radioClassName,
    selectClassName,
    actionClassName,
    itemUri,
    searchScope,
    onChange,
    renderAction = true,
}: Props) => {
    const [folderItemUri, setFolderItemUri] = useState();
    const [siteItemUri, setSiteItemUri] = useState();

    const prevSearchScope = usePrevious(searchScope);
    const prevItemUri = usePrevious(itemUri);
    const prevFolderItemUri = usePrevious(folderItemUri);
    const prevSiteItemUri = usePrevious(siteItemUri);
    useEffect(() => {
        if (prevSearchScope === searchScope && prevItemUri === itemUri) return;
        switch (searchScope) {
            case SearchScope.AllFolders:
                siteItemUri !== itemUri && setSiteItemUri(itemUri);
                break;
            case SearchScope.CurrentFolder:
                folderItemUri !== itemUri && setFolderItemUri(itemUri);
                break;
            default:
        }
    }, [
        itemUri,
        prevItemUri,
        searchScope,
        prevSearchScope,
        folderItemUri,
        siteItemUri,
    ]);

    useEffect(() => {
        if (prevFolderItemUri != null && prevFolderItemUri !== folderItemUri)
            onChange(SearchScope.CurrentFolder, folderItemUri);
    }, [onChange, folderItemUri, prevFolderItemUri]);

    useEffect(() => {
        if (prevSiteItemUri != null && prevSiteItemUri !== siteItemUri)
            onChange(SearchScope.AllFolders, siteItemUri);
    }, [onChange, siteItemUri, prevSiteItemUri]);

    const handleScopeChange = useCallback(
        (e) => {
            const searchScope = SearchScope[e.target.name];
            let itemUri = null;
            if (searchScope === SearchScope.AllFolders) itemUri = siteItemUri;
            if (searchScope === SearchScope.CurrentFolder)
                itemUri = folderItemUri;
            return onChange(searchScope, itemUri);
        },
        [onChange, siteItemUri, folderItemUri]
    );
    const handleFolderPickerChange = useCallback(
        (scope: SearchScopeEnum, itemUri?: string) => {
            onChange(scope, itemUri);
        },
        [onChange]
    );
    return (
        <div className={className}>
            <Radio
                key="ss-r-all"
                className={radioClassName}
                onChange={handleScopeChange}
                name="AllSites"
                checked={searchScope === SearchScope.AllSites}
            >
                {loc.t("search:scope.prefix_AllSites")}
            </Radio>
            <Radio
                key="ss-r-allfolders"
                className={radioClassName}
                onChange={handleScopeChange}
                name="AllFolders"
                checked={searchScope === SearchScope.AllFolders}
            >
                {loc.t("search:scope.prefix_AllFolders")}{" "}
                <SiteSelect
                    onLoad={setSiteItemUri}
                    defaultItemUri={siteItemUri}
                    onChange={setSiteItemUri}
                />
            </Radio>
            <Radio
                key="ss-r-currentfolder"
                className={radioClassName}
                onChange={handleScopeChange}
                name="CurrentFolder"
                checked={searchScope === SearchScope.CurrentFolder}
            >
                {loc.t("search:scope.prefix_CurrentFolder")}{" "}
                <RecentFolderSelect
                    onLoad={setFolderItemUri}
                    defaultItemUri={folderItemUri}
                    className={selectClassName}
                    onChange={setFolderItemUri}
                />
                {renderAction && (
                    <CommandActionButton
                        className={actionClassName}
                        action="search_scope"
                        icon="fa-regular fa-folder-open"
                        actionPrefix="formActions"
                        props={{
                            itemUri:
                                searchScope === SearchScope.CurrentFolder
                                    ? folderItemUri
                                    : siteItemUri,
                            onSearchScopeChange: handleFolderPickerChange,
                        }}
                    />
                )}
            </Radio>
        </div>
    );
};

export default SearchScopeSelect;
