// @flow
import React from "react";
import MenuItem from "react-bootstrap/lib/MenuItem";
import actionMenus from "data/actionMenus";
import loc from "i18next";

type ActionMenuItem = {
    id: string,
    text: string,
    type: null | "separator" | "header",
};

type Props = {
    menuName: string,
    item: ActionMenuItem,
    /** callback to decide whether specified action should be visible
     * @param {object} action action object for which to decide visibility
     * @returns {boolean} true if should be visible, false to not render
     */
    onShouldBeVisible: ?(action: { id: string }) => boolean,
    /** callback to retrieve parameters to show in the action's text */
    onGetActionTextArguments: ?(actionId: string) => Object,
    /** callback when user selects action from ActionsMenu */
    onSelect: (id: string) => void,
};

export const RenderActionMenuItem = ({
    menuName,
    item,
    onShouldBeVisible,
    onGetActionTextArguments,
    onSelect,
}: Props) => {
    if (typeof onShouldBeVisible === "function") {
        if (!onShouldBeVisible(item)) return null;
    }
    const args =
        typeof onGetActionTextArguments === "function"
            ? onGetActionTextArguments(item.id)
            : {};

    return (
        <MenuItem
            id={item.id}
            key={item.id}
            eventKey={item.id}
            divider={item.type === "separator"}
            header={item.type === "header"}
            onSelect={onSelect}
            onClick={onSelect}
            data-test={item.id}
        >
            {item.type !== "separator" &&
                loc.t(`${menuName}:actions.${item.id}`, args)}
        </MenuItem>
    );
};

function RenderActionsMenu(
    name: string,
    onShouldBeVisible: ?(action: { id: string }) => boolean,
    onGetActionTextArguments: ?(actionId: string) => Object,
    onSelect: (id: string) => void
) {
    if (!Object.prototype.hasOwnProperty.call(actionMenus, name)) return null;

    return actionMenus[name].map((item, key) => (
        <RenderActionMenuItem
            key={key}
            menuName={name}
            item={item}
            onShouldBeVisible={onShouldBeVisible}
            onGetActionTextArguments={onGetActionTextArguments}
            onSelect={
                /* istanbul ignore next */ (id) => {
                    if (typeof id === "string") onSelect(id);
                }
            }
        />
    ));
}

export default RenderActionsMenu;
