// @flow
import React, { type Node } from "react";
import "./index.css";
// local components
import CrumbSelector from "./CrumbSelector";
import ToggleBarContainer from "containers/ToggleBar";
import ActionsMenu from "components/ActionsMenu";
import ActionsButton from "components/ActionPanel/ActionsButton";
import Pane from "components/SplitPanes/Pane";
import { openItemActionPanel } from "components/ItemActionPanel";
import { type ToggleDTO, type SearchItemDTO } from "data/types";
import { usePendingOfflineChanges } from "./usePendingOfflineChanges";

type Props = {
    /** toggles definition */
    toggles: ?ToggleDTO,
    /** React children */
    children?: Node,
    /** optional control to show at the beginning of topbar */
    topRowBefore?: Node,
    /** optional control to show at the end of topbar */
    topRowEnd?: Node,
    /** optional CSS class to apply */
    className?: string,
    /** current itemUri */
    itemUri?: ?string, //TODO remove MainLayout.ItemUri since it should be included in contextProps
    /** any contextProps to pass to actions */
    contextProps?: Object,
    /** optional used searchItem */
    searchItem?: SearchItemDTO,
    /** view name */
    name: string,
    /** callback when user selects action from ActionsMenu
     * @param {string} actionId which action has been selected by the user
     */
    onAction?: (actionId: string) => void,
    /** callback to decide whether specified action should be visible
     * @param {object} action action object for which to decide visibility
     * @returns {boolean} true if should be visible, false to not render
     */
    onActionShouldBeVisible?: (action: { id: string }) => boolean,
    /** scope breadcrumbs to recyclebin; default: false */
    breadcrumbIsRecycleBin?: boolean,
    /** whether user is allowed to navigate through breadcrumb (and hide favorite option); default: false */
    breadcrumbReadonly?: boolean,
    /** scope breadcrumb to show parts only starting the specified level number */
    breadcrumbScopeFromLevel?: number,
    /** optional breadcrumbPart click event instead of history.push */
    onBreadcrumbPartClick?: (itemUri: string) => void,
    /** whether to disable the whole actionsMenu; default: false */
    actionsDisabled?: boolean,
    /** whether root of breadcrumb can be changed */
    breadcrumbCanChangeSite?: boolean,
};

const MainLayout = ({
    toggles,
    children,
    topRowClassName = "",
    topRowBefore,
    topRowEnd,
    id,
    className,
    itemUri,
    contextProps,
    searchItem,
    name,
    onAction,
    onActionShouldBeVisible,
    breadcrumbIsRecycleBin = false,
    breadcrumbReadonly = false,
    breadcrumbScopeFromLevel,
    onBreadcrumbPartClick,
    actionsDisabled = false,
    breadcrumbCanChangeSite = false,
}: Props) => {
    usePendingOfflineChanges(itemUri);
    return (
        <div className="main">
            <Pane className={`topRow ${topRowClassName}`}>
                {topRowBefore}
                <div className="bcContainer">
                    <CrumbSelector
                        isReadonly={breadcrumbReadonly}
                        isRecycleBin={breadcrumbIsRecycleBin}
                        itemUri={itemUri}
                        isDoc={contextProps?.isDoc}
                        searchItem={searchItem}
                        scopeFromLevel={breadcrumbScopeFromLevel}
                        viewName={name}
                        onBreadcrumbPartClick={onBreadcrumbPartClick}
                        canChangeSite={breadcrumbCanChangeSite}
                    />
                </div>

                {toggles && <ToggleBarContainer {...toggles} />}

                {contextProps && (
                    <ActionsButton
                        disabled={actionsDisabled}
                        onClick={
                            /* istanbul ignore next */ () =>
                                openItemActionPanel({ contextProps })
                        }
                        className="ab"
                        dataTest="openItemActionPanelButton"
                    />
                )}
                {onAction && name && (
                    <ActionsMenu
                        name={name}
                        disabled={actionsDisabled}
                        onAction={onAction}
                        onShouldBeVisible={onActionShouldBeVisible}
                    />
                )}
                {topRowEnd}
            </Pane>
            <Pane
                id={id}
                className={`contentRow${className ? ` ${className}` : ""}`}
            >
                {children}
            </Pane>
        </div>
    );
};
MainLayout.displayName = "MainLayout";
export default MainLayout;
