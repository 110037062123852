import { getDisplayName } from "data/constants";
import { useOfflineHandler } from "hooks";

const withOfflineHandler = (WrappedComponent) => {
    const HOC = (props) => {
        const offlineHandler = useOfflineHandler({
            itemUri: props.itemUri,
            name: props.name,
        });
        return <WrappedComponent {...props} offlineHandler={offlineHandler} />;
    };
    HOC.displayName = `withOfflineHandler(${getDisplayName(WrappedComponent)})`;
    return HOC;
};

export default withOfflineHandler;
