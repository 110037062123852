// @flow
import React from "react";
import FormControl from "react-bootstrap/lib/FormControl";
import {
    type DocListColumnTypeEnum,
    DocListColumnType,
    type DocListFilterOperatorsEnum,
    DocListFilterOperators,
} from "data/types";
import FilterDateValue from "./FilterDateValue";
import BoolPicker from "./BoolPicker";
import ClearableInput from "@hs/clearable-input";

type Props = {
    autoFocus?: boolean,
    index: number,
    type: DocListColumnTypeEnum,
    operator: DocListFilterOperatorsEnum,
    values: Array<string>,
    valueIndex: number,
    onChange: Function,
    id?: string,
};

const FilterValue = ({
    autoFocus,
    index,
    valueIndex,
    type,
    values,
    operator,
    onChange,
    id,
}: Props) => {
    const from =
        [
            DocListFilterOperators.Between,
            DocListFilterOperators.NotBetween,
        ].includes(operator) &&
        values &&
        values[0] &&
        String(values[0]).length > 0
            ? values[0]
            : null;

    switch (type) {
        case DocListColumnType.BooleanValue:
            return (
                <BoolPicker
                    index={index}
                    value={values && values[valueIndex]}
                    onChange={
                        /* istanbul ignore next */ (e) => onChange(e, index)
                    }
                />
            );

        case DocListColumnType.DateTimeValue:
            return (
                <FilterDateValue
                    autoFocus={autoFocus /* istanbul ignore next */ || false}
                    index={index}
                    valueIndex={valueIndex}
                    values={values}
                    operator={operator}
                    onChange={onChange}
                    id={id}
                />
            );

        case DocListColumnType.IntValue:
        case DocListColumnType.LongValue:
        case DocListColumnType.FileSize:
        case DocListColumnType.ShortValue:
        case DocListColumnType.DoubleValue:
        case DocListColumnType.DecimalValue:
        case DocListColumnType.SingleValue:
            return (
                <FormControl
                    autoFocus={autoFocus /* istanbul ignore next */ || false}
                    name={index}
                    type="number"
                    required={valueIndex === 1 && from !== null}
                    value={(values && values[valueIndex]) || ""}
                    step={
                        type === DocListColumnType.DecimalValue ||
                        type === DocListColumnType.DoubleValue
                            ? "0.01"
                            : "1"
                    }
                    onChange={
                        /* istanbul ignore next */
                        (e) => onChange(e, index, valueIndex)
                    }
                    id={id}
                />
            );

        default:
            return (
                <ClearableInput
                    autoFocus={autoFocus /* istanbul ignore next */ || false}
                    value={values && values[valueIndex]}
                    required={valueIndex === 1 && from !== null}
                    onChange={
                        /* istanbul ignore next */ (e) =>
                            onChange(e, index, valueIndex)
                    }
                    className="form-control"
                    id={id}
                />
            );
    }
};
FilterValue.displayName = "FilterValue";
export default FilterValue;
