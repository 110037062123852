import React from "react";
import { LinkViewer } from "components/ExtraAttributes/ViewerControl/LinkViewerControl";
import { type SummaryAttributeValueTextLink } from "data/types";
import styles from "./SummaryAttribute.module.css";

/* istanbul ignore next */
const handleClick = (e: SyntheticEvent<MouseEvent>) => {
    e.stopPropagation();
};

export const ValueTextLink = ({
    className,
    value,
}: {
    className: string,
    value: SummaryAttributeValueTextLink,
}) => {
    return (
        <span className={`${styles.link} ${className}`}>
            <LinkViewer
                onClick={handleClick}
                value={value.value}
                caption={value.caption}
                isDoc={value.isDoc}
                icon={value.icon}
                isIconLast={value.isIconLast}
            />
        </span>
    );
};
ValueTextLink.displayName = "SummaryAttribute.Value.TextLink";

export default ValueTextLink;
