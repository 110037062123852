// @flow
import React, { useRef } from "react";
import Modal, { ModalSize } from "components/Modal";
import Form from "containers/Form";
import { createModal } from "react-modal-promise";
import Breadcrumb from "containers/Breadcrumb";
import loc from "i18next";
import styles from "./FormModal.module.css";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "react-bootstrap/lib/Button";
import Icons from "@hs/icons";
import Pane from "components/SplitPanes/Pane";
import ItemContextProvider from "containers/ItemContext/Provider";

type Props = {
    /** whether to show modal */
    open: boolean,
    /** callback when user closes Modal */
    close: () => void,
    /** target itemUri */
    formProps: { itemUri: string, viewName: string } & Object,
};

/**
 * FormModal
 */
export const FormModal = ({ open, close, itemUri, ...formProps }: Props) => {
    // used by FormContainer to fill in Save button
    const footerRef = useRef(null);
    return (
        <ItemContextProvider itemUri={itemUri}>
            {(itemContext) => (
                <Modal
                    fullscreen
                    enforceFocus={false}
                    scroll
                    size={ModalSize.Medium}
                    dialogClassName={styles.formmodal}
                    show={open !== false}
                    onHide={close}
                    style={{ zIndex: 1050 }} /* correctly overlays map modal */
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: 13 }}>
                            <Breadcrumb
                                itemUri={formProps.itemUri}
                                isDoc={itemContext.isDoc}
                                viewName={formProps.viewName}
                                isReadonly
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Pane>
                            <Form
                                showHeader={false}
                                buttonToolbarPortal={footerRef.current}
                                onCancel={close}
                                isEditMode={itemContext.isEditAllowed}
                                itemUri={itemUri}
                                {...formProps}
                            />
                        </Pane>
                    </Modal.Body>
                    <Modal.Footer>
                        {!itemContext.isEditAllowed && (
                            <>
                                <div className={styles.notAuthorizedWrapper}>
                                    <Icons.Library
                                        name="triangle-exclamation"
                                        size="2x"
                                        className={styles.notAuthorizedIcon}
                                    />
                                    {loc.t(
                                        `${formProps.viewName}:edit_form.notAuthorized`
                                    )}
                                </div>
                                <ButtonToolbar className="pull-right">
                                    <Button
                                        aria-label={loc.t("close")}
                                        onClick={close}
                                        bsStyle="primary"
                                        className={styles.close}
                                    >
                                        {loc.t("close")}
                                    </Button>
                                </ButtonToolbar>
                            </>
                        )}

                        <div ref={footerRef} />
                    </Modal.Footer>
                </Modal>
            )}
        </ItemContextProvider>
    );
};
FormModal.displayName = "FormModal";
export default FormModal;
export const openFormModal = createModal(FormModal);
