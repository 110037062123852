import { DefaultAction, PickerAction } from "./Default";
import { CustomUploadAction } from "./CustomUpload";
import { ArrowNavigationAction } from "./ArrowNavigation";
import { PdfHighlightAction } from "./PdfHighlight";
import { PdfSignatureAction } from "./PdfSignature";
import { PdfPrintAction } from "./PdfPrint";
import { ModalAction } from "./Modal";
import { NotificationAction } from "./Notification";
import { PrintAction } from "./Print";
import { TogglesAction } from "./Toggles";
import { ItemAction } from "./Item";
import { HtmlViewerAction } from "@hs/html-viewer";

DefaultAction.Toast = NotificationAction.Toast;

export {
    DefaultAction,
    PickerAction,
    CustomUploadAction,
    ArrowNavigationAction,
    PdfHighlightAction,
    PdfSignatureAction,
    PdfPrintAction,
    ModalAction,
    NotificationAction,
    PrintAction,
    TogglesAction,
    ItemAction,
    HtmlViewerAction,
};

export type DefaultActionEnum = $Values<typeof DefaultAction>;
export type PickerActionEnum = $Values<typeof PickerAction>;
export type CustomUploadActionEnum = $Values<typeof CustomUploadAction>;
export type PdfHighlightActionEnum = $Values<typeof PdfHighlightAction>;
export type ArrowNavigationActionEnum = $Values<typeof ArrowNavigationAction>;
export type ModalActionEnum = $Values<typeof ModalAction>;
export type NotificationActionEnum = $Values<typeof ModalAction>;
export type PrintActionEnum = $Values<typeof PrintAction>;
export type TogglesActionEnum = $Values<typeof TogglesAction>;
export type ItemActionEnum = $Values<typeof ItemAction>;
export type HtmlViewerActionEnum = $Values<typeof HtmlViewerAction>;
