// @flow
import React from "react";
import Button from "react-bootstrap/lib/Button";
import loc from "i18next";
import Icons from "@hs/icons";

const OpenFiltersButton = ({ onClick }: { onClick: Function }) => (
    <Button
        aria-label="filter"
        bsStyle="link"
        onClick={onClick}
        style={{ color: "black" }}
    >
        <Icons.Library name="filter" style={{ marginRight: 3 }} />
        {loc.t("actionCategory:filter.name")}
    </Button>
);
OpenFiltersButton.displayName = "OpenFiltersButton";
export default OpenFiltersButton;
