// @flow
import React from "react";
import { type CleanupKeepInfoDTO, CleanupKeepVersionType } from "data/types";
import Alert from "react-bootstrap/lib/Alert";
import loc from "i18next";
import Icons from "@hs/icons";

type Props = {
    /** info object for Cleanup Keep Version */
    info: ?CleanupKeepInfoDTO,
    /** optional custom CSS class to apply */
    className?: string,
};

const CleanupKeepInfo = ({ info, className }: Props) => {
    // no message if keeping them all
    if (info == null || info.type === CleanupKeepVersionType.All) return null;
    return (
        <Alert bsStyle="info" className={className}>
            <Icons.Library name="circle-info" />{" "}
            {loc.t(`docactivity:keep.${info.type}`, { count: info.count })}
        </Alert>
    );
};
CleanupKeepInfo.displayName = "CleanupKeepInfo";
export default CleanupKeepInfo;
