import actionTypes from "../actions/actionTypes";
import { SiteType } from "data/types";

export const initialState = {};

const breadcrumbReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CURRENT_BREADCRUMB:
            const { parts } = action.payload.breadcrumb;
            return {
                ...state,
                [action.payload.viewName]: {
                    ...action.payload.breadcrumb,
                    isFav: action.payload.isFav,
                    //viewName: action.payload.viewName,
                    itemUri:
                        parts == null ||
                        !Array.isArray(parts) ||
                        parts.length === 0
                            ? undefined
                            : parts[parts.length - 1].itemUri,
                },
            };
        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default breadcrumbReducer;

export const breadcrumbIsFavSelector = (state: Object, name: string) =>
    (state.breadcrumb[name] && state.breadcrumb[name].isFav) || false;
export const breadcrumbItemUriSelector = (state: Object, name: string) =>
    state.breadcrumb[name] && state.breadcrumb[name].itemUri;
export const breadcrumbNameSelector = (state: Object, name: string) =>
    state.breadcrumb[name] && state.breadcrumb[name].parts
        ? state.breadcrumb[name].parts[state.breadcrumb[name].parts.length - 1]
              .text
        : "";
export const breadcrumbLevelsCountSelector = (state: Object, name: string) =>
    state.breadcrumb[name] && state.breadcrumb[name].parts
        ? state.breadcrumb[name].parts.length
        : -1;
export const breadcrumbVersionSelector = (state: Object, name: string) =>
    state.breadcrumb[name] && state.breadcrumb[name].version;
export const breadcrumbFormatIdSelector = (state: Object, name: string) =>
    state.breadcrumb[name] && state.breadcrumb[name].formatId;
export const breadcrumbSiteTypeSelector = (state: Object, name: string) =>
    (state.breadcrumb[name] && state.breadcrumb[name].siteType) ||
    SiteType.NotSet;
