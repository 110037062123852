import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";
import { produce } from "immer";

const initialState = {
    cards: [],
    status: Constants.NONE,
};

const cardsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        const { payload, type } = action;
        switch (type) {
            case actionTypes.CARDS_FETCH_ENTITY_REQUESTED:
                draft.status = payload.status;
                break;
            case actionTypes.CARDS_FETCH_REQUESTED:
            case actionTypes.CARDS_FETCH_RESULT:
                return { ...state, ...payload };
            case actionTypes.CARDS_FETCH_ENTITY_RESULT:
                const { status, cards, cardTypes, cardItemTypes } = payload;
                const cardsToAdd = cards.filter(
                    (card) =>
                        !(draft.cards || []).find(
                            (oldCard) => oldCard.id === card.id
                        )
                );
                const cardsToUpdate = (draft.cards || [])
                    .map(
                        (oldCard) =>
                            cards.find((card) => oldCard.id === card.id) ??
                            ((cardTypes?.includes(oldCard.type) ?? true) &&
                            (cardItemTypes?.includes(oldCard.itemtype) ?? true)
                                ? null
                                : oldCard)
                    )
                    .filter((card) => !!card);
                draft.status = status;
                draft.cards = [...cardsToAdd, ...cardsToUpdate];
                break;
            /* istanbul ignore next */
            case actionTypes.PERSIST_PURGE:
                return initialState;
            default:
                return state;
        }
    });

export default cardsReducer;

export const cardsStatusSelector = (state: Object) => state.cards.status;
export const cardsCardsSelector = (state: Object) => state.cards.cards;
