import history from "data/history";
import { getRoute } from "components/ItemUriLink";
import { getFolderItemUri } from "data/api";

/**
 * opens the folder browse in current or newWindow
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ newWindow?: boolean}} { newWindow }
 * @param {{ toggles?: string | Array<string>}} { toggles }
 */

type Context = {
    itemUri: string,
    newWindow?: boolean,
    toggles?: string | Array<string>,
};

class folder_preview {
    execute = async ({ itemUri, newWindow, toggles }: Context) => {
        const folderUri = await getFolderItemUri(itemUri);
        if (newWindow) {
            window.open(
                getRoute({
                    itemUri: folderUri,
                    isMini: true,
                    isExternalLink: true,
                    toggles,
                }),
                "",
                `status=no,location=no,toolbar=no,menubar=no,width=600px,height=640px,top=${
                    Math.random() * 100
                },left=${Math.random() * 100}`
            );
        } else {
            history.push(
                getRoute({
                    itemUri: folderUri,
                    toggles,
                })
            );
        }
        return true;
    };
}
export default folder_preview;
