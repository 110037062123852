import { openRasterMapManagerModal } from "components/RasterMapManagerModal";

type Context = {
    formatId: number,
    itemUri: string,
    name: string,
};

class raster_manage {
    /**
     * Opens Modal to manage Raster Layers
     *
     * @param {{ itemUri: string }} { itemUri }
     * @param {{ name: string }} { name }
     */
    execute = async ({ formatId, itemUri, name }: Context) => {
        await openRasterMapManagerModal({ formatId, itemUri, name });
        return true;
    };
}
export default raster_manage;
