import ip_commandAction from "./ip_commandAction";

/**
 * opens inPoint.Client to archive to the specified itemUri
 *
 * @param {{ itemUri?: string }} { itemUri }
 */

class ip_archive extends ip_commandAction {
    execute = ({ itemUri }: { itemUri?: string }) =>
        super.execute({ itemUri, commandAction: "ArchiveCommandAction" });
}

export default ip_archive;
