// @flow
//#region imports
import type {
    UserInfoResultDTO,
    CardItemResultDTO,
    TreeDTO,
    BreadcrumbResultDTO,
    FormResultDTO,
    SearchFormResultDTO,
    DocInfoResultDTO,
    DocInfoResultWithTokenDTO,
    DocListFilterRequestDTO,
    DocListSortRequestDTO,
    DocsResultDTO,
    WorkflowDTO,
    WorkflowInfoDTO,
    WorkflowProviderDTO,
    SAYTOptionDTO,
    SearchResultDTO,
    SearchCountResultDTO,
    SearchRequestDTO,
    SearchFilterDTO,
    UserPrincipalDTO,
    GroupPrincipalDTO,
    ExtraAttributesEmbeddedGridDTO,
    SortDirectionEnum,
    ItemLinkTypeEnum,
    ActivitiesDTO,
    NewFormTypeEnum,
    LookupValuesResultDTO,
    FormLookupTypeEnum,
    NewsFeedDTO,
    SubscribeResponseDTO,
    UserSettingsResultDTO,
    SearchFormInfoDTO,
    UserSettingsDTO,
    ItemFlowsConfigDTO,
    ItemFlowDirectionEnum,
    NewItemResultDTO,
    RasterMapResultDTO,
    ReminderDTO,
    ItemContextResponseDTO,
    TransferItemRequestDTO,
    ShareAttachment,
    // SearchValueTypeEnum
} from "data/types";
import { ItemContextsFlags } from "data/types";
import API from "./ApiManager";
import { correlationId, isOnline } from "data/storeHelper";
import qs from "data/queryString";
//#endregion

//#region AbortSignal
// https://github.com/flow-typed/flow-typed/issues/1652
// translation of https://dom.spec.whatwg.org/#abortcontroller
declare interface AbortSignal extends EventTarget {
    +aborted: boolean;
    onabort: EventHandler;
}
declare class AbortController {
    +signal: AbortSignal;
    abort: () => void;
}
//#endregion

// helper
const nameof = (o) => Object.keys(o)[0];

//#region SiteItems
export const fetchSites = (): Promise<Array<SidebarItemDTO>> =>
    API.call(nameof({ fetchSites }));
//#endregion

//#region UserInfo

// retrieves the user's info object from WebApi
export const fetchUser = (): Promise<UserInfoResultDTO> =>
    API.call(nameof({ fetchUser }));
//#endregion
//#region UserSettings

export const fetchUserSettings = (): Promise<UserSettingsResultDTO> =>
    API.call(nameof({ fetchUserSettings }));

export const saveUserSettings = (params: UserSettingsDTO): Promise<boolean> =>
    API.call(nameof({ saveUserSettings }), { params });
//#endregion
//#region Cards

// retrieves the user's home cards from WebApi
/* istanbul ignore next */
export const fetchCards = (
    signal?: AbortController
): Promise<Array<CardItemResultDTO>> =>
    API.call(nameof({ fetchCards }), { signal });

export const fetchCardsByEntity = (
    params: CardListRequest,
    signal?: AbortController
): Promise<Array<CardItemResultDTO>> =>
    API.call(nameof({ fetchCardsByEntity }), { params, signal });
//#endregion
//#region Favorites

export const fetchNews = (): Promise<NewsFeedDTO> =>
    API.call(nameof({ fetchNews }));

export const fetchFavorites = (): Promise<Array<string>> =>
    API.call(nameof({ fetchFavorites }));

export const addFavorite = (itemUri: string, name: string): Promise<boolean> =>
    API.call(nameof({ addFavorite }), { params: { itemUri, name } });

export const delFavorite = (itemUri: string): Promise<boolean> =>
    API.call(nameof({ delFavorite }), { params: { itemUri } });

export const addSearchFavorite = (params: SearchRequestDTO): Promise<boolean> =>
    API.call(nameof({ addSearchFavorite }), {
        params,
    });

export const delFavoriteLink = (linkId: number): Promise<boolean> =>
    API.call(nameof({ delFavoriteLink }), {
        params: { linkId },
    });
//#endregion
//#region Mails

export /* istanbul ignore next */ const sendMail = (
    params: SendNotificationRequestDTO
): Promise<boolean> =>
    API.call(nameof({ sendMail }), {
        params,
    });

export /* istanbul ignore next */ const sendMailAttachments = (
    params: SendDocumentsRequestDTO
): Promise<boolean> =>
    API.call(nameof({ sendMailAttachments }), {
        params,
    });
//#endregion
//#region Folder's Tree

export /* istanbul ignore next */ const fetchTree = ({
    itemUri,
    filter = "",
    deep = false,
    all = false,
    signal,
}: {
    itemUri: string,
    filter: string,
    deep: boolean,
    all: boolean,
    signal?: AbortSignal,
}): Promise<TreeDTO> =>
    API.call(nameof({ fetchTree }), {
        params: {
            itemUri,
            filter,
            deep,
            all,
        },
        signal,
    });
//#endregion
//#region Folder

export const createFolder = ({
    itemUri,
    formatId,
    elementType,
    values,
}: {
    itemUri: string,
    formatId: number,
    elementType?: number,
    values: Object,
}): Promise<NewItemResultDTO> =>
    API.call(nameof({ createFolder }), {
        params: {
            itemUri,
            formatId,
            elementType,
            values,
        },
    });

export const createFolderFromTemplate = (
    template: string,
    itemUri: string,
    targetFolderName: string
) =>
    API.call(nameof({ createFolderFromTemplate }), {
        params: { template, itemUri, targetFolderName },
    });

export const createFolderTemplateGetItemUri = (jobId: number) =>
    API.call(nameof({ createFolderTemplateGetItemUri }), {
        params: { jobId },
    });
//#endregion
//#region ItemFlows

export const fetchItemFlows = (
    itemUri: string,
    dir: ItemFlowDirectionEnum
): Promise<ItemFlowsConfigDTO> =>
    API.call(nameof({ fetchItemFlows }), {
        params: {
            itemUri,
            dir,
        },
    });

export const fetchItemFlowForm = ({
    formType,
    itemUri,
    templateId,
    templateType,
}: {
    formType: NewFormTypeEnum,
    itemUri: string,
    templateId: string,
    templateType: string,
}): Promise<FormResultDTO> =>
    API.call(nameof({ fetchItemFlowForm }), {
        params: {
            formType,
            itemUri,
            templateId,
            templateType,
        },
    });

//#endregion
//#region Breadcrumb

export const fetchBreadcrumb = (
    itemUri: string,
    signal?: AbortSignal
): Promise<BreadcrumbResultDTO> =>
    API.call(nameof({ fetchBreadcrumb }), { params: { itemUri }, signal });
//#endregion
//#region Item
export const delItemUri = (itemUri: string): Promise<boolean> =>
    API.call(nameof({ delItemUri }), { params: { itemUri } });
//#endregion
//#region Form
export const fetchSearchFormList = (): Promise<Array<SearchFormInfoDTO>> =>
    API.call(nameof({ fetchSearchFormList }));

export const fetchNewForm = (
    formType: NewFormTypeEnum,
    itemUri: string
): Promise<FormResultDTO> =>
    API.call(nameof({ fetchNewForm }), {
        params: { itemUri },
    });

export const fetchForm = (itemUri: string): Promise<FormResultDTO> =>
    API.call(nameof({ fetchForm }), { params: { itemUri } });

export const fetchSearchForm = (
    id: number,
    values: Object
): Promise<SearchFormResultDTO> =>
    API.call(nameof({ fetchSearchForm }), {
        params: { id, values },
    });

export const fetchFormLookup = ({
    keyword,
    pageNum,
    isDoc,
    formatId,
    name,
    lookupType,
    id,
    filters,
    values,
    itemUri,
}: {
    keyword: string,
    pageNum: number,
    isDoc: boolean,
    formatId: number,
    name: string,
    lookupType: FormLookupTypeEnum,
    id: ?number,
    filters: Array<SearchFilterDTO>,
    values: Object,
    itemUri: string,
}): Promise<LookupValuesResultDTO> =>
    API.call(nameof({ fetchFormLookup }), {
        params: {
            keyword,
            pageNum,
            isDoc,
            formatId,
            name,
            lookupType,
            id,
            filters,
            values,
            itemUri,
        },
    });

export const fetchFormLookupDependencies = ({
    isDoc,
    formatId,
    name,
    lookupType,
    currentLookupValues,
    itemUri,
}: {
    isDoc: boolean,
    formatId: number,
    name: string,
    lookupType: FormLookupTypeEnum,
    currentLookupValues: Array<LookupValueDTO>,
    itemUri: string,
}) =>
    API.call(nameof({ fetchFormLookupDependencies }), {
        params: {
            isDoc,
            formatId,
            name,
            lookupType,
            currentLookupValues,
            itemUri,
        },
    });

export const fetchExtraAttributesEmbeddedGrid = (
    itemUri: string,
    name: string,
    filter: string,
    pageNum: number,
    sortColumn: string,
    sortDirection: SortDirectionEnum,
    signal?: AbortController
): Promise<ExtraAttributesEmbeddedGridDTO> =>
    API.call(nameof({ fetchExtraAttributesEmbeddedGrid }), {
        params: {
            itemUri,
            name,
            filter,
            pageNum,
            sortColumn,
            sortDirection,
        },
        signal,
    });

export const saveForm = (
    itemUri: string,
    formatId: number,
    values: Object,
    etag: string
): Promise<boolean> =>
    API.call(nameof({ saveForm }), {
        params: {
            itemUri,
            formatId,
            values,
            etag,
        },
    });

export const saveNewDocFromTemplate = ({
    templateId,
    itemUri,
    values,
}: {
    templateId: string,
    itemUri: string,
    values: Object,
}) =>
    API.call(nameof({ saveNewDocFromTemplate }), {
        params: {
            templateId,
            itemUri,
            values,
        },
    });

/**
 * Will call the server to Validate a form's values
 * @export
 * @param {boolean} isNew create or update form?
 * @param {boolean} isDoc document or folder?
 * @param {string} itemUri if create then parent's itemUri, if update then item's itemUri
 * @param {boolean} validateWebdavName validate WebdavName?
 * @param {Object} values
 * @param {AbortController} [signal] AbortController's signal for cancelling
 * @returns {Promise<Object>} validationErrors - any resource names to localize will start with $
 */
export const validateForm = (
    isNew: boolean,
    isDoc: boolean,
    itemUri: string,
    validateWebdavName: boolean,
    values: Object,
    signal?: AbortSignal
): Promise<Object> =>
    API.call(nameof({ validateForm }), {
        params: {
            isNew,
            isDoc,
            itemUri,
            validateWebdavName,
            values,
        },
        signal,
    });

export const getArchiveTargets = (
    offline?: boolean
): Promise<Array<CardItemResultDTO>> =>
    API.call(nameof({ getArchiveTargets }), {
        params: { offline },
    });

export const getFolderItemUri = (
    itemUri: string,
    signal?: AbortSignal
): Promise<string> =>
    API.call(nameof({ getFolderItemUri }), { params: { itemUri }, signal });
//#endregion
//#region Documents

export const fetchActivities = (
    itemUri: string
): Promise<Array<ActivitiesDTO>> =>
    API.call(nameof({ fetchActivities }), {
        params: { itemUri },
    });

export /* istanbul ignore next */ const fetchItemNote = (
    itemUri: string,
    noteId: number
): Promise<any> =>
    API.call(nameof({ fetchItemNote }), {
        params: { itemUri, noteId },
    });

export const addItemNote = (params: ActivityDTO): Promise<any> =>
    API.call(nameof({ addItemNote }), {
        params,
    });

export const updateItemNote = (params: ActivityDTO): Promise<any> =>
    API.call(nameof({ updateItemNote }), {
        params,
    });

export const deleteItemNote = (itemUri: string, noteId: number): Promise<any> =>
    API.call(nameof({ deleteItemNote }), {
        params: { itemUri, noteId },
    });

export const delDocVersion = (
    itemUri: string,
    version: number
): Promise<boolean> =>
    API.call(nameof({ delDocVersion }), {
        params: { itemUri, version },
    });

export const restoreDocVersion = (
    itemUri: string,
    version: number
): Promise<boolean> =>
    API.call(nameof({ restoreDocVersion }), {
        params: { itemUri, version },
    });

export const fetchDocCount = (
    itemUri: string,
    filters?: Array<DocListFilterRequestDTO>,
    signal?: AbortController
): Promise<number> =>
    API.call(nameof({ fetchDocCount }), {
        params: {
            itemUri,
            pageNum: 0,
            filters,
        },
        signal,
    });

export const fetchDocsCsv = (
    itemUri: string,
    sorts?: DocListSortRequestDTO | Array<DocListSortRequestDTO>,
    filters?: Array<DocListFilterRequestDTO>
): Promise<any> /* istanbul ignore next */ =>
    API.call(nameof({ fetchDocsCsv }), {
        params: {
            itemUri,
            sorts,
            filters,
        },
    });

export const fetchDocs = (
    itemUri: string,
    pageNum?: number,
    sorts?: DocListSortRequestDTO | Array<DocListSortRequestDTO>,
    filters?: Array<DocListFilterRequestDTO>,
    cols?: Array<string>,
    signal?: AbortSignal
): Promise<DocsResultDTO> /* istanbul ignore next */ =>
    API.call(nameof({ fetchDocs }), {
        params: { itemUri, pageNum, sorts, filters, cols },
        signal,
    });

/* istanbul ignore next */
export const fetchDownloadToken = (
    itemUri: string
): Promise<string> /* istanbul ignore next */ =>
    API.call(nameof({ fetchDownloadToken }), {
        params: { itemUri },
    });

/* istanbul ignore next */
export function downloadDocToken(
    token: string,
    inline: ?boolean,
    pdf: ?boolean,
    stream: ?boolean
): string /* istanbul ignore next */ {
    let data = { token };
    if (isOnline()) data.correlationId = correlationId();
    if (inline === true) data.inline = true;
    if (pdf === true) data.pdf = true;
    if (stream === true) data.stream = true;

    // if (uri !== undefined) {
    //     return `${uri}?${qs.stringify(data)}`;
    // } else {
    const host =
        window.CONFIG.host.bridge ||
        `${window.CONFIG.host.basename}${window.CONFIG.general.api}`;
    return `${host}/doc/download?${qs.stringify(data)}`;
    // }
}

/* istanbul ignore next */
const downloadDocUri = (
    itemUri: string,
    inline: ?boolean,
    pdf: ?boolean
): Promise<string> =>
    fetchDownloadToken(itemUri).then((token: string) =>
        downloadDocToken(token, inline, pdf)
    );

export { downloadDocUri };

export const fetchThumbnail = (
    itemUri: string,
    signal?: AbortSignal
): Promise<string> =>
    API.call(nameof({ fetchThumbnail }), {
        params: { itemUri },
        signal,
    });

export const fetchRelatedDocs = (
    itemUri: string,
    itemLinkType: ItemLinkTypeEnum,
    getSource: ?boolean
): Promise<Array<string>> =>
    API.call(nameof({ fetchRelatedDocs }), {
        params: { itemUri, itemLinkType, getSource },
    });

/* istanbul ignore next */
export const getWebDavHttpLink = (
    itemUri: string,
    webDavName: string
): Promise<string> =>
    API.call(nameof({ getWebDavHttpLink }), {
        params: { itemUri, webDavName },
    });

/* istanbul ignore next */
export const getCloudInfo = (
    itemUri: string,
    webDavName: string
): Promise<Array<string>> =>
    API.call(nameof({ getCloudInfo }), { params: { itemUri, webDavName } });

//#endregion
//#region (Un)lock

export const lock = (itemUri: string): Promise<boolean> =>
    API.call(nameof({ lock }), {
        params: { itemUri },
    });

export const unlock = (itemUri: string): Promise<boolean> =>
    API.call(nameof({ unlock }), {
        params: { itemUri },
    });
//#endregion
//#region (Un)subscribe

export const subscribe = (itemUri: string, name: string): Promise<boolean> =>
    API.call(nameof({ subscribe }), {
        params: { itemUri, name },
    });

export const isSubscribed = (itemUri: string): Promise<SubscribeResponseDTO> =>
    API.call(nameof({ isSubscribed }), {
        params: { itemUri },
    });

export const unsubscribeByItemUri = (itemUri: string): Promise<boolean> =>
    API.call(nameof({ unsubscribeByItemUri }), {
        params: { itemUri },
    });

export const unsubscribeById = ({
    linkID,
    itemUri,
}: {
    linkID: number,
    itemUri: string,
}): Promise<boolean> =>
    API.call(nameof({ unsubscribeById }), {
        params: { linkID, itemUri },
    });
//#endregion
//#region Workflow
export const fetchWorkflowProviders = (
    signal?: AbortController
): Promise<Array<WorkflowProviderDTO>> =>
    API.call(nameof({ fetchWorkflowProviders }), {
        signal,
    });

export const fetchWorkflowCards = (
    signal?: AbortController
): Promise<Array<CardItemResultDTO>> =>
    API.call(nameof({ fetchWorkflowCards }), {
        signal,
    });

export const fetchNewWorkflows = (
    itemUri: string,
    signal?: AbortSignal
): Promise<Array<WorkflowDTO>> =>
    API.call(nameof({ fetchNewWorkflows }), {
        params: { itemUri },
        signal,
    });
export const fetchWorkflowInfo = ({
    provider,
    wfId,
    itemUri,
    signal,
}: {
    provider: string,
    wfId?: string,
    itemUri?: string,
    signal?: AbortSignal,
}): Promise<Array<WorkflowInfoDTO>> =>
    API.call(nameof({ fetchWorkflowInfo }), {
        params: { provider, wfId, itemUri },
        signal,
    });

//#endregion
//#region Recycle Bin
export const delDefiniteItemUri = (itemUri: string): Promise<boolean> =>
    API.call(nameof({ delDefiniteItemUri }), {
        params: { itemUri },
    });

export const restoreItemUri = (itemUri: string): Promise<boolean> =>
    API.call(nameof({ restoreItemUri }), {
        params: { itemUri },
    });

//#endregion
//#region Raster maps
export const fetchRasterMapLayers = ({
    itemUri,
    rasterMapIdSelector,
    create,
}: {
    itemUri: string,
    rasterMapIdSelector: ?string,
    create?: boolean,
}): Promise<RasterMapResultDTO> =>
    API.call(nameof({ fetchRasterMapLayers }), {
        params: { itemUri, rasterMapIdSelector, create },
    });

export const saveRasterMapLayers = ({
    map,
}: {
    map: RasterMapSaveRequestDTO,
}): Promise<RasterMapResultDTO> =>
    API.call(nameof({ saveRasterMapLayers }), {
        params: map,
    });
//#endregion
//#region Search

export const fetchSAYT = (
    keyword: ?string,
    itemUri: ?string
): Promise<Array<SAYTOptionDTO>> /* istanbul ignore next */ =>
    API.call(nameof({ fetchSAYT }), {
        params: { keyword, itemUri },
    });

export const fetchSearchCount = (
    params: SearchRequestDTO,
    signal?: AbortSignal
): Promise<SearchCountResultDTO> /* istanbul ignore next */ =>
    API.call(nameof({ fetchSearchCount }), {
        params,
        signal,
    });

export const fetchSearchResult = (
    params: SearchRequestDTO,
    signal?: AbortSignal
): Promise<SearchResultDTO> /* istanbul ignore next */ =>
    API.call(nameof({ fetchSearchResult }), {
        params,
        signal,
    });

/* istanbul ignore next */
export const fetchSearchPrincipal = (
    keyword: string,
    includeUsers: boolean = true,
    includeGroups: boolean = false,
    signal?: AbortSignal
): Promise<Array<UserPrincipalDTO | GroupPrincipalDTO>> =>
    API.call(nameof({ fetchSearchPrincipal }), {
        params: { keyword, includeUsers, includeGroups },
        signal,
    });

export const fetchSearchSuggestion = (
    provider: string,
    filters: Array<SearchFilterDTO>
): Promise<string> =>
    API.call(nameof({ fetchSearchSuggestion }), {
        params: { provider, filters },
    });
//#endregion
//#region Reminder
export const fetchReminders = (
    itemUri: string,
    signal?: AbortSignal
): Promise<Array<ReminderDTO>> =>
    API.call(nameof({ fetchReminders }), {
        params: { itemUri },
        signal,
    });

export const upsertReminder = (
    params: ReminderDTO,
    signal?: AbortSignal
): int =>
    API.call(nameof({ upsertReminder }), {
        params,
        signal,
    });

export const deleteReminder = (
    reminderId: number,
    itemUri: string,
    signal?: AbortSignal
): int =>
    API.call(nameof({ deleteReminder }), {
        params: { reminderId, itemUri },
        signal,
    });
//#endregion
//#region Share

export const fetchShare = (
    itemUri: string,
    signal?: AbortSignal
): Promise<ShareDTO> =>
    API.call(nameof({ fetchShare }), { params: { itemUri }, signal });

export const createShare = (
    shareRequest: ShareDTO,
    signal?: AbortSignal
): Promise<boolean> =>
    API.call(nameof({ createShare }), { params: shareRequest }, signal);

export const updateShare = (
    shareRequest: ShareDTO,
    signal?: AbortSignal
): Promise<boolean> =>
    API.call(nameof({ updateShare }), { params: shareRequest }, signal);

export const deleteShare = (
    itemUri: string,
    signal?: AbortSignal
): Promise<boolean> =>
    API.call(nameof({ deleteShare }), { params: { itemUri }, signal });

export const checkShareName = (
    itemUris: Array<string>,
    webDavNames: Array<string>,
    signal?: AbortSignal
): Promise<string> =>
    API.call(nameof({ checkShareName }), {
        params: { itemUris, webDavNames },
        signal,
    });

export const fetchShareContext = (
    itemUris: Array<string>,
    formatIds: Array<number>,
    signal?: AbortSignal
): Promise<boolean> =>
    API.call(nameof({ fetchShareContext }), {
        params: { itemUris, formatIds },
        signal,
    });

//#endregion
//#region Offline

export const fetchOfflineItems = (): Promise<Array<OfflineItemDTO>> =>
    API.call(nameof({ fetchOfflineItems }));

export const addOfflineItem = (itemUri: string): Promise<string> =>
    API.call(nameof({ addOfflineItem }), {
        params: { itemUri },
    });

export const delOfflineItem = (itemUri: string): Promise<boolean> =>
    API.call(nameof({ delOfflineItem }), {
        params: { itemUri },
    });

// called directly by service-worker.custom.js
// export const fetchOfflinePrecache = (): Promise<Array<string>> =>
//     API.call(nameof({ fetchOfflinePrecache }));
//#endregion

//#region transfer
export const transfer = (
    transferRequest: TransferItemRequestDTO
): Promise<boolean> =>
    API.call(nameof({ transfer }), { params: transferRequest });
//#endregion

//#region context
export const context = (
    itemUris,
    formatIds,
    /* istanbul ignore next */
    itemContexts = ItemContextsFlags.All
): Promise<ItemContextResponseDTO> =>
    API.call(nameof({ context }), {
        params: { itemUris, formatIds, itemContexts },
    });

export const fetchItemContexts = (...args) => context(...args);

/* istanbul ignore next */
export const fetchDownloadContext = (
    itemUri: string
): Promise<ItemContextResponseDTO> =>
    context(
        [itemUri],
        null,
        ItemContextsFlags.WebDavName | ItemContextsFlags.Extension
    );

export const fetchShareAttachment = (
    itemUri: string
): Promise<ShareAttachment> =>
    context(
        [itemUri],
        null,
        ItemContextsFlags.WebDavName |
            ItemContextsFlags.Extension |
            ItemContextsFlags.Filesize
    );

export const fetchDocResultFlags = (
    itemUri: string,
    formatId: number
): Promise<ItemContextResponseDTO> =>
    context([itemUri], [formatId], ItemContextsFlags.ResultFlags);

export const fetchItemContext = (
    itemUri: string
): Promise<ItemContextResponseDTO> =>
    context([itemUri], null, ItemContextsFlags.All);

export /* istanbul ignore next */ const fetchViewNameType = (
    itemUri: string
): Promise<ItemContextResponseDTO> =>
    context([itemUri], null, ItemContextsFlags.ViewName);

//#endregion
