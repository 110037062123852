import {
    call,
    put,
    take,
    takeLatest,
    select,
    cancelled,
    race,
} from "redux-saga/effects";
import actionTypes from "data/actions/actionTypes";
import {
    getSearchResultAction,
    renameSearchFavoriteResultAction,
    getSearchFormResultAction,
    globalSearchDefaultResultAction,
    getSearchFormListResultAction,
    getSearchCountResultAction,
    togglesUpdateCounterAction,
    searchResetAction,
} from "data/actions";
import { SearchRequestType } from "data/types";
import {
    fetchSearchResult,
    fetchSearchFormList,
    fetchSearchForm,
    fetchSAYT,
    fetchSearchCount,
} from "data/api";
import Constants from "data/constants";
import * as s from "data/reducers/selectors";
import immutable from "object-path-immutable";

// fetches batch of results
function* getSearchResult(action) {
    try {
        const isValid = yield select(s.searchFormValidationSelector);
        if (!isValid) {
            yield put(searchResetAction());
            return;
        }

        let count = 0;
        let status = Constants.LOADING;
        yield updateResultToggles(count, status);

        const abortController = new AbortController();

        const { result, abort, reset } = yield race({
            result: call(
                fetchSearchResult,
                action.payload.searchRequest,
                abortController.signal
            ),
            abort: take(actionTypes.SEARCH_ABORT),
            reset: take(actionTypes.SEARCH_RESET),
        });

        status = Constants.OK;

        if (reset || abort) {
            abortController.abort();
            yield put(searchResetAction());
            return;
        }

        count = result.totalCount;
        yield updateResultToggles(count, status);

        yield put(
            getSearchResultAction({
                status,
                result,
                terms: [
                    (
                        action.payload.searchRequest
                            .searchText /* istanbul ignore next */ || ""
                    )
                        .replace(/\[[^\s]+/g, "")
                        .trim(),
                ],
            })
        );
    } catch (e) {
        yield put(
            getSearchResultAction({
                status: Constants.ERROR,
                error: /* istanbul ignore next */ e.data || e.message,
            })
        );
    }
}

export function* getSearchCountResult(action) {
    // https://decembersoft.com/posts/redux-saga-abort-controller-cancel-api-calls/
    const abortController = new AbortController();
    try {
        const result = yield call(
            fetchSearchCount,
            action.payload.searchRequest,
            abortController.signal
        );

        yield put(
            getSearchCountResultAction({
                status: Constants.OK,
                result,
            })
        );
    } catch (e) {
        yield put(
            getSearchCountResultAction({
                status: Constants.ERROR,
                message: e.message,
            })
        );
    } finally {
        if (yield cancelled()) {
            // console.log("getDocs cancelled");
            // Cancel the API call if the saga was cancelled
            abortController.abort();
        }
    }
}

function* renameSearchFavorite(action) {
    const searchRequest = yield select(s.searchRequestSelector);
    // TODO searchFavorite rename existing
    if (
        searchRequest.searchType !== SearchRequestType.Favorite ||
        searchRequest.isCustom
    ) {
        yield put(
            renameSearchFavoriteResultAction({
                status: Constants.OK,
                message: "",
                searchRequest: immutable.set(
                    searchRequest,
                    "name",
                    action.payload.name
                ),
            })
        );
    }
}

function* updateResultToggle(name, result, status) {
    yield put(
        togglesUpdateCounterAction({
            name,
            counts: { result },
            status: { result: status },
        })
    );
}

function* updateResultToggles(count, status) {
    yield updateResultToggle("searchresult", count, status);
    yield updateResultToggle("search", count, status);
}

function* resetSearch() {
    yield updateResultToggles(-1, Constants.OK);
}

function* updateSearchFormToggles(counts, status) {
    yield put(
        togglesUpdateCounterAction({
            name: "forms",
            counts,
            status,
        })
    );
    yield put(
        togglesUpdateCounterAction({
            name: "search",
            counts: {
                forms: counts,
            },
            status: {
                forms: status,
            },
        })
    );
}

function* getSearchFormList(action) {
    try {
        yield updateSearchFormToggles(-1, Constants.LOADING);
        const result = yield call(fetchSearchFormList);
        yield updateSearchFormToggles(result.length, Constants.OK);
        yield put(
            getSearchFormListResultAction({
                status: Constants.OK,
                result,
            })
        );
    } catch (e) {
        yield put(
            getSearchFormListResultAction({
                status: Constants.ERROR,
                message: e.message,
            })
        );
    }
}

function* getSearchForm(action) {
    try {
        const result = yield call(
            fetchSearchForm,
            action.payload.id,
            action.payload.model
        );

        yield put(
            getSearchFormResultAction({
                status: Constants.OK,
                result,
            })
        );
    } catch (e) {
        yield put(
            getSearchFormResultAction({
                status: Constants.ERROR,
                message: e.message,
            })
        );
    }
}

function* getGlobalDefault(action) {
    try {
        const result = yield call(fetchSAYT);
        yield put(
            globalSearchDefaultResultAction({
                status: Constants.OK,
                options: result,
            })
        );
    } catch (e) {
        yield put(
            globalSearchDefaultResultAction({
                status: Constants.ERROR,
                message: e.message,
            })
        );
    }
}

function* searchSaga() {
    yield takeLatest(actionTypes.SEARCH_FETCH_REQUEST, getSearchResult);
    yield takeLatest(actionTypes.SEARCH_COUNT_REQUEST, getSearchCountResult);
    yield takeLatest(
        actionTypes.SEARCH_FAVRENAME_REQUEST,
        renameSearchFavorite
    );
    yield takeLatest(actionTypes.SEARCH_FORM_FETCH_REQUEST, getSearchForm);
    yield takeLatest(
        actionTypes.GLOBALDEFAULTSEARCH_FETCH_REQUEST,
        getGlobalDefault
    );
    yield takeLatest(
        actionTypes.SEARCH_FORM_LIST_FETCH_REQUEST,
        getSearchFormList
    );
    yield takeLatest(actionTypes.SEARCH_RESET, resetSearch);
}

export default searchSaga;
