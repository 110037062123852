// @flow
import React, { useState } from "react";
import Measure from "react-measure";
import { PaneSizeContext } from "data/context";

type Props = {
    /** html id attribute */
    id: string,
    /** html class attribute */
    className: string,
    /** Components Children */
    children: Children,
};

const Pane = ({ id, className, style, children, dataTest }: Props) => {
    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
    });
    return (
        <Measure
            bounds
            onResize={({ bounds }) => {
                /* istanbul ignore next */
                if (
                    bounds.width === dimensions.width &&
                    bounds.height === dimensions.height
                )
                    return;
                setDimensions({
                    width: bounds.width,
                    height: bounds.height,
                });
            }}
        >
            {({ measureRef }) => (
                <div
                    id={id}
                    ref={measureRef}
                    className={className}
                    style={style}
                    data-test={dataTest}
                >
                    <PaneSizeContext.Provider value={dimensions}>
                        {children}
                    </PaneSizeContext.Provider>
                </div>
            )}
        </Measure>
    );
};

export default Pane;
