// @flow
import React, { PureComponent } from "react";
import Col from "react-bootstrap/lib/Col";
import Modal from "components/Modal";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "react-bootstrap/lib/Button";
import SplitButton from "react-bootstrap/lib/SplitButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import Icons from "@hs/icons";
import Operators from "./Operators";
import FilterValue from "./FilterValue";
import {
    type DocListFilterRequestDTO,
    DocListFilterOperators,
    DocListColumnType,
} from "data/types";
import { type docsResultState } from "data/reducers/types";
import immutable from "object-path-immutable";
import loc from "i18next";
import styles from "./DocListFilterModal.module.css";
import { GetDefaults } from "./Utils";

type Props = {
    /** whether to show modal */
    show: boolean,
    /** which filter to focus on */
    focusOnFilterName: ?string,
    /** modal dialog title */
    title: string,
    /** current result */
    result: docsResultState,
    /** callback when user closes modal */
    onClose: () => void,
    /** callback when user wants to set filters
     * @param {Array<DocListFilterRequestDTO>} filters all filters values
     */
    onFilter: (filters: Array<DocListFilterRequestDTO>) => void,
    /** current filters definitions */
    filters: ?Array<DocListFilterRequestDTO>,
};

type State = {
    filters: Array<DocListFilterRequestDTO>,
};

export default class DocListFilterModal extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.filters == null || props.filters.length === 0) {
            if (props.result.itemUri != null)
                this.state = {
                    filters:
                        GetDefaults(
                            props.result.itemUri,
                            props.formatId,
                            props.result.cols
                        ).filters /* istanbul ignore next */ || [],
                };
            else this.state = { filters: [] };
        } else {
            this.state = {
                filters: props.filters,
            };
        }
    }

    _onFilter = () => {
        this.props.onFilter(this.state.filters);
    };

    _onSubmit = (e: SyntheticInputEvent<*>) => {
        e.preventDefault();
        this._onFilter();
    };

    _onOperatorChange = (e: SyntheticInputEvent<*>): void => {
        const index = parseInt(e.currentTarget.name, 10);
        const filter = this.state.filters[index];
        let newfilters = immutable.set(
            this.state.filters,
            [String(index), "operator"],
            e.currentTarget.value
        );
        // reset value only if not compatible
        if (
            filter.operator === DocListFilterOperators.DateRelative ||
            newfilters[index].operator === DocListFilterOperators.DateRelative
        ) {
            newfilters = immutable.set(
                newfilters,
                [String(index), "values"],
                [""]
            );
        }
        this.setState({
            filters: newfilters,
        });
    };

    _onValueChange = (
        e: SyntheticInputEvent<*> | string,
        index: number,
        valueIndex: ?number
    ) => {
        let value;
        /* istanbul ignore if */
        if (typeof e === "string") {
            value = e;
        } else {
            value = e.currentTarget.value;
        }
        this.setState(
            {
                filters: immutable.set(
                    this.state.filters,
                    [
                        String(index),
                        "values",
                        valueIndex /* istanbul ignore next  */ || 0,
                    ],
                    value
                ),
            },
            () => {
                // if Between "from" is changed clear the "to"...
                if (
                    valueIndex === 0 &&
                    this.state.filters[index].operator ===
                        DocListFilterOperators.Between &&
                    // ... unless it's a date (dates are passed as strings instead of event objects)
                    typeof e !== "string"
                )
                    this.setState({
                        filters: immutable.set(
                            this.state.filters,
                            [String(index), "values", 1],
                            ""
                        ),
                    });
                // if not Between clear the "to"...
                if (
                    this.state.filters[index].operator !==
                    DocListFilterOperators.Between
                ) {
                    const updatedFilters = immutable.del(this.state.filters, [
                        index,
                        "values",
                        1,
                    ]);

                    this.setState({
                        filters: updatedFilters,
                    });
                }
            }
        );
    };

    _resetValues = () => {
        this._resetSelect("values");
    };

    _resetSelect = (eventKey: "all" | "values") => {
        if (eventKey === "all") {
            const { result, formatId } = this.props;
            // clear the WHOLE form (operators & values)
            /* istanbul ignore else */
            if (result.itemUri != null)
                this.setState({
                    filters: GetDefaults(result.itemUri, formatId, result.cols)
                        .filters,
                });
            else this.setState({ filters: [] });
        } else {
            /* istanbul ignore else */
            if (eventKey === "values") {
                this.setState({
                    filters: this.state.filters.map((filter) =>
                        immutable.set(filter, "values", [""])
                    ),
                });
            }
        }
    };

    // submit form on keyboard Enter
    _onKeyPress = (e: SyntheticKeyboardEvent<*>) => {
        /* istanbul ignore else */
        if (e.which === 13) {
            e.preventDefault();
            this._onFilter();
        }
    };

    // will return column index to focus on
    // if passed in props then return that
    // otherwise find first visible field
    _getFocusColumnIndex = () => {
        const { filters } = this.state;
        const { focusOnFilterName, result } = this.props;
        if (focusOnFilterName == null) {
            const cols = result.cols || [];
            // find first field available
            const i = filters.findIndex((filter) => {
                const col = cols.find((c) => c.name === filter.name);
                if (col != null && col.canSort) return true;
                return false;
            });
            return i;
        }

        const i = filters.findIndex(
            (filter) => filter.name === focusOnFilterName
        );
        /* istanbul ignore next */
        if (i == null) return -1;
        return i;
    };

    render() {
        const { show, onClose, result, title } = this.props;
        const { filters } = this.state;
        const cols = result.cols || [];
        const focusColumnIndex = this._getFocusColumnIndex();

        return (
            <Modal
                enforceFocus={false}
                dialogClassName={styles.modal}
                show={show}
                onHide={onClose}
                onKeyPress={this._onKeyPress}
                scroll
                footerSize={55}
            >
                <Form horizontal onSubmit={this._onSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Icons.Library name="filter" />
                            {" " +
                                loc.t("folder:action_filter.title") +
                                " " +
                                title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {filters.map((filter, index) => {
                            const col = cols.find(
                                (c) => c.name === filter.name
                            );
                            if (col == null) {
                                return null;
                            }
                            if (!col.canSort) return null;
                            return (
                                <FormGroup controlId={col.name} key={index}>
                                    <Col
                                        componentClass={ControlLabel}
                                        sm={3}
                                        htmlFor={col.name}
                                    >
                                        {col.caption}
                                    </Col>
                                    {col.type !==
                                        DocListColumnType.BooleanValue && (
                                        <Col sm={3}>
                                            <Operators
                                                id={`${col.name}_operator`}
                                                index={index}
                                                value={filter.operator}
                                                type={col.type}
                                                onChange={
                                                    this._onOperatorChange
                                                }
                                            />
                                        </Col>
                                    )}
                                    {[
                                        DocListFilterOperators.Between,
                                        DocListFilterOperators.NotBetween,
                                    ].includes(filter.operator) ? (
                                        <>
                                            <Col sm={2}>
                                                <FilterValue
                                                    index={index}
                                                    autoFocus={
                                                        focusColumnIndex ===
                                                        index
                                                    }
                                                    values={filter.values}
                                                    valueIndex={0}
                                                    type={col.type}
                                                    operator={filter.operator}
                                                    onChange={
                                                        this._onValueChange
                                                    }
                                                    id={col.name}
                                                />
                                            </Col>
                                            <Col sm={2}>
                                                <FilterValue
                                                    index={index}
                                                    values={filter.values}
                                                    valueIndex={1}
                                                    type={col.type}
                                                    operator={filter.operator}
                                                    onChange={
                                                        this._onValueChange
                                                    }
                                                    id={`${col.name}_2`}
                                                />
                                            </Col>
                                        </>
                                    ) : (
                                        <Col sm={6}>
                                            <FilterValue
                                                index={index}
                                                autoFocus={
                                                    focusColumnIndex === index
                                                }
                                                values={filter.values}
                                                valueIndex={0}
                                                type={col.type}
                                                operator={filter.operator}
                                                onChange={this._onValueChange}
                                                id={col.name}
                                            />
                                        </Col>
                                    )}
                                </FormGroup>
                            );
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonToolbar
                            className="pull-left"
                            onClick={
                                /* istanbul ignore next */ (e) =>
                                    e.stopPropagation()
                            }
                        >
                            <SplitButton
                                id="reset-button"
                                dropup
                                bsStyle="default"
                                title={loc.t("folder:action_filter.reset")}
                                aria-label="reset"
                                onClick={this._resetValues}
                                onSelect={this._resetSelect}
                            >
                                <MenuItem eventKey="all" id="resetall-button">
                                    {loc.t("folder:action_filter.reset_all")}
                                </MenuItem>
                            </SplitButton>
                        </ButtonToolbar>
                        <ButtonToolbar className="pull-right">
                            <Button
                                id="filter-button"
                                aria-label="filter"
                                type="submit"
                                bsStyle="primary"
                                style={{ padding: "6px 30px" }}
                            >
                                {loc.t("folder:action_filter.filter")}
                            </Button>
                        </ButtonToolbar>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
