import { isHandheld } from "data/bowser";

const GridList = ({ node, isActive, isHovered }) => {
    const config = {
        outer: ".ReactVirtualized__Grid",
        inner: ".ReactVirtualized__Grid__innerScrollContainer",
    };
    const outer = node?.closest(config.outer);
    const inner = node?.closest(config.inner);
    const rect = {
        current: node?.getBoundingClientRect(),
        outer: outer?.getBoundingClientRect(),
        inner: inner?.getBoundingClientRect(),
    };
    const rgb = isActive
        ? "0,152,170"
        : isHovered
        ? "245,245,245"
        : "255,255,255";
    const borderColor = isActive
        ? `rgb(${rgb})`
        : isHovered
        ? "#ededed"
        : "#ededed";
    const scrollbarWidth = !isHandheld() ? 17 : 0;
    const scrollbarHeight =
        !isHandheld() && rect.outer?.width < rect.inner?.width ? 16 : 0;
    const style = {
        position: "absolute",
        transform: "translateX(-100%)",
        zIndex: 99,
        // Scroll container
        top: rect.current?.y + 1 - (rect.outer?.y - outer?.scrollTop),
        left:
            rect.outer?.width < rect.inner?.width + scrollbarWidth
                ? rect.outer?.width +
                  outer?.scrollLeft -
                  (rect.outer?.height - scrollbarHeight < rect.inner?.height
                      ? scrollbarWidth
                      : 0)
                : rect.inner?.width,
        // Root
        // top: rect.current?.y + 1,
        // left:
        //     rect.outer?.x +
        //     (rect.outer?.width < rect.inner?.width
        //         ? rect.outer?.width -
        //           (rect.outer?.height < rect.inner?.height && !isHandheld()
        //               ? 17
        //               : 0)
        //         : rect.inner?.width),
        minHeight: 40,
        color: isActive ? "white" : "black",
        // Box Layout
        /*minWidth: 37,
            background: `rgb(${rgb})`,
            borderLeft: `1px solid ${borderColor}`,
            borderBottom: `1px solid ${borderColor}`,*/
        // Gradient Layout
        minWidth: 42,
        justifyContent: "flex-end",
        background: `linear-gradient(90deg, rgba(${rgb},0) 0%, rgba(${rgb},1) 50%)`,
    };
    if (isHandheld()) {
        style.transform = "translate(-100%, -1px) translateX(-1px)";
        style.minWidth = undefined;
        style.background = `rgb(${rgb})`;
        style.borderLeft = `1px solid ${borderColor}`;
        style.borderBottom = `1px solid ${borderColor}`;
        style.minHeight = 42;
    }
    return style;
};

const List = ({ node, isActive, isHovered }) => {
    return {
        position: "absolute",
        transform: "translateX(-100%)",
        zIndex: 99,
        top: 1,
        left: "100%",
        color: isActive ? "white" : "black",
        minHeight: 32,
    };
};

const StickyToggleStyle = {
    List,
    GridList,
};
export default StickyToggleStyle;
