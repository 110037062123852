import React, { useCallback, useState, useMemo } from "react";
import { useAbortController } from "hooks";
import Modal, { ModalSize } from "components/Modal";
import Button from "react-bootstrap/lib/Button";
import Icons from "@hs/icons";
import loc from "i18next";
import styles from "./ReminderManagerModal.module.css";
import { createModal } from "react-modal-promise";
import Constants from "data/constants";
import Status from "components/Status";
import {
    LocalDateStringOption,
    ReminderPriority,
    ReminderTypeFlags,
    type ReminderDTO,
} from "data/types";
import { toastActionResult } from "data/toast";
import ReminderForm from "components/Reminder/ReminderForm";
import { upsertReminder } from "data/api";
import { userLanguage } from "data/storeHelper";

type Props = {
    open: boolean,
    close: () => void,
    itemUri: string,
    name: string,
    onSave: () => void,
    reminder?: ReminderDTO,
};

const getReminder = (itemUri): ReminderDTO => ({
    id: null,
    itemUri,
    date: new Date(new Date().setDate(new Date().getDate() + 1)).toLocalJSON(),
    creator: null,
    recipient: null,
    reason: "",
    priority: ReminderPriority.Normal,
    type: ReminderTypeFlags.Mail,
});

export const ReminderManagerModal = ({
    itemUri,
    name,
    close,
    open,
    onSave,
    reminder: reminderFromProps = getReminder(itemUri),
}: Props) => {
    const [reminder, setReminder] = useState(reminderFromProps);
    const [isDirty, setIsDirty] = useState(false);

    const allowReminderTypeEdit = useMemo(
        () =>
            Object.keys(ReminderTypeFlags).filter(
                (k) =>
                    ReminderTypeFlags[k] === ReminderTypeFlags.Mail ||
                    (reminderFromProps.type & ReminderTypeFlags[k]) > 1
            ).length > 1,
        [reminderFromProps]
    );

    const [canSubmit, setCanSubmit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const abortController = useAbortController();

    const handleChange = useCallback((name, value) => {
        setIsDirty(true);
        setReminder((r) => ({ ...r, [name]: value }));
    }, []);

    const handleCanSubmit = useCallback(
        (canSubmit) => setCanSubmit(canSubmit && isDirty),
        [isDirty]
    );

    const handleSave = useCallback(() => {
        const locContext = {
            date: new Date(reminder.date).toLocaleDateString(
                userLanguage(),
                LocalDateStringOption.DateTime
            ),
        };
        setIsSubmitting(true);
        upsertReminder(reminder, abortController(true).signal)
            .then((reminderId) => {
                const id = parseInt(reminderId, 10);
                /* istanbul ignore else */
                if (typeof onSave === "function") {
                    onSave(reminder, id);
                }
                setIsSubmitting(false);
                /* istanbul ignore else */
                if (id > 0) {
                    close(false);
                }
                toastActionResult(id > 0, "reminder:action_save", locContext);
                return reminderId;
            })
            .catch((error) => {
                console.error(error);
                setIsSubmitting(false);
                toastActionResult(false, "reminder:action_save", locContext);
            });
    }, [reminder, abortController, onSave, close]);

    return (
        <Modal
            size={ModalSize.Small}
            enforceFocus={false}
            show={open !== false}
            onHide={/* istanbul ignore next */ () => close(false)}
            dialogClassName={styles.modal}
            docked
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Icons.Library name="fa-regular fa-clock" />
                    {` ${loc.t(
                        reminder.id == null ? "reminder:new" : "reminder:edit"
                    )} › ${name}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReminderForm
                    setCanSubmit={handleCanSubmit}
                    reminder={reminder}
                    handleSave={handleSave}
                    handleChange={handleChange}
                    autoFocus={reminder.id == null}
                    allowReminderTypeEdit={allowReminderTypeEdit}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    aria-label="cancel"
                    onClick={() => close(false)}
                    bsStyle="link"
                    className="pull-left"
                >
                    {loc.t("cancel")}
                </Button>
                <Button
                    disabled={
                        !canSubmit || /*istanbul ignore next*/ isSubmitting
                    }
                    aria-label="save"
                    onClick={handleSave}
                    bsStyle="primary"
                >
                    {isSubmitting && (
                        <Status status={Constants.LOADING} inline={true} />
                    )}{" "}
                    {loc.t(
                        `reminder:action_save.${
                            reminder.id == null ? "insert" : "update"
                        }`
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ReminderManagerModal.displayName = "ReminderManagerModal";
export const openReminderManagerModal = createModal(ReminderManagerModal);
