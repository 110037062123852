import React, { useMemo } from "react";
import { Img } from "react-image";
import Icons from "@hs/icons";

export const ValueVisualRules = ({
    className,
    caption,
    value,
}: {
    className: string,
    caption: string,
    value: SummaryAttributeValueVisualRules,
}) => {
    const [style, icon] = useMemo(() => {
        const style = value.color ? { color: value.color } : undefined;
        const icon = value.icon ? (
            <Img
                style={
                    value.icon.iconSize
                        ? {
                              width: value.icon.iconSize[0],
                              height: value.icon.iconSize[1],
                          }
                        : null
                }
                className="a-icon"
                loader={<Icons.Library name="spinner" pulse />}
                src={value.icon.iconUrl}
                alt={caption}
            />
        ) : null;
        return [style, icon];
    }, [value, caption]);
    return (
        <span className={className} style={style}>
            {icon != null && icon} {value.value}
        </span>
    );
};
ValueVisualRules.displayName = "SummaryAttribute.Value.VisualRules";

export default ValueVisualRules;
