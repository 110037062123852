// @flow
import React, { useState, useCallback, useEffect, useMemo } from "react";
import Modal, { ModalSize } from "components/Modal";
import { createModal } from "react-modal-promise";
import styles from "./PickerModal.module.css";
import MiniPicker from "views/minipicker";
import Button from "react-bootstrap/lib/Button";
import SplitButton from "react-bootstrap/lib/SplitButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import loc from "i18next";
import HelpBlock from "react-bootstrap/lib/HelpBlock";
import { fetchBreadcrumb } from "data/api";
import { useModalPostMessageHandler, useAbortController } from "hooks";
import { sitesSelector } from "data/storeHelper";

export const PickerType = {
    Folder: 0,
    File: 1,
    Any: 2,
};
type PickerTypeEnum = $Values<typeof PickerType>;

type PickerActionDTO = {
    label: string,
    eventKey: string,
};

type Props = {
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog */
    close: () => void,
    /** current folder's itemUri  */
    itemUri: string,
    /** type of picker
     * @default {PickerTypeEnum} PickerType.File
     */
    pickerType: PickerTypeEnum,
    /** optional help text to display the user */
    helpText?: string,
    /** optional breadcrumb scoping */
    scopeBreadcrumb?: boolean,
    /** optional selectButton string */
    selectButton?: string,
    /** callback for additional actions */
    onSelect: (eventKey) => void,
    /** additional actions */
    additionalActions?: Array<PickerActionDTO>,
};

/** allows user to pick a Folder or Document */
export const PickerModal = ({
    open,
    close,
    itemUri,
    pickerType,
    helpText,
    onSelect,
    additionalActions,
    scopeBreadcrumb = false,
    canChangeSite = false,
    canSelectSite = false,
    selectButton = loc.t("minipicker:selectButton"),
}: Props) => {
    // remember the currently shown folder itemUri
    const [currentRootItemUri, setCurrentRootItemUri] = useState(itemUri);
    // remember the currently selected itemUri (folder/doc)
    const [currentItemUri, setCurrentItemUri] = useState(null);
    const [currentRootItemUriLevels, setCurrentItemUriLevels] = useState(-1);
    const sites = useMemo(sitesSelector, []);

    /* istanbul ignore next*/
    useEffect(() => {
        canSelectSite &&
            scopeBreadcrumb &&
            console.warn(
                "Site Selection disabled: Prop canSelectSite will be false, when scopeBreadcrumb is true!"
            );
    }, [canSelectSite, scopeBreadcrumb]);

    const handleFolderSelect = useCallback(
        (itemUri, treeNodeId) =>
            pickerType !== PickerType.File && setCurrentItemUri(itemUri),
        [pickerType]
    );
    const handleDocumentSelect = useCallback(
        (e, itemUri, index) =>
            pickerType !== PickerType.Folder && setCurrentItemUri(itemUri),
        [pickerType]
    );
    const setRootItemUri = useCallback(
        (itemUri: string) => {
            setCurrentRootItemUri(itemUri);
            if (pickerType !== PickerType.File) {
                handleFolderSelect(
                    canSelectSite || !sites.some((s) => s.itemUri === itemUri)
                        ? itemUri
                        : null
                );
            } else {
                setCurrentItemUri(null);
            }
        },
        [pickerType, handleFolderSelect, canSelectSite, sites]
    );
    /* istanbul ignore next */
    const handleClose = useCallback(() => {
        close(false);
    }, [close]);

    useModalPostMessageHandler(handleClose);

    const handleSelect = useCallback(() => {
        close(currentItemUri);
    }, [close, currentItemUri]);

    const abortController = useAbortController();
    useEffect(() => {
        async function fetchData() {
            if (scopeBreadcrumb) {
                const bc = await fetchBreadcrumb(
                    itemUri,
                    abortController(true).signal
                );
                setCurrentItemUriLevels(bc.parts.length - 1);
            }
        }
        fetchData();
    }, [scopeBreadcrumb, itemUri, abortController]);

    const disabled = currentItemUri == null || currentItemUri === itemUri;

    return (
        <Modal
            size={ModalSize.Small}
            fullscreen
            enforceFocus={false}
            show={open !== false}
            onHide={handleClose}
            dialogClassName={styles.picker}
            headerSize={0}
            footerSize={0}
        >
            <Modal.Body>
                <MiniPicker
                    itemUri={currentRootItemUri}
                    onClose={handleClose}
                    onDocSingleClick={handleDocumentSelect}
                    onDocDoubleClick={(e, itemUri) =>
                        pickerType !== PickerType.Folder && close(itemUri)
                    }
                    onFolderSingleClick={handleFolderSelect}
                    onBreadcrumbPartClick={setRootItemUri}
                    breadcrumbScopeFromLevel={currentRootItemUriLevels}
                    canChangeSite={!scopeBreadcrumb && canChangeSite}
                />
            </Modal.Body>
            <Modal.Footer>
                {helpText && (
                    <HelpBlock className="pull-left">{helpText}</HelpBlock>
                )}
                {!additionalActions?.length ? (
                    <Button
                        id="select-button"
                        aria-label="select"
                        disabled={disabled}
                        onClick={handleSelect}
                        bsStyle="primary"
                        className={styles.button}
                    >
                        {selectButton}
                    </Button>
                ) : (
                    <SplitButton
                        id="select-button"
                        aria-label="select"
                        disabled={disabled}
                        onToggle={(isOpen, e) => {
                            e.stopPropagation(); // HACK: prevent closing of Menu within Modal.Footer
                        }}
                        onClick={handleSelect}
                        bsStyle="primary"
                        title={selectButton}
                        className={styles.button}
                        pullRight
                        dropup
                    >
                        {additionalActions.map((action, i) => (
                            <MenuItem
                                eventKey={action.eventKey}
                                key={`pickermodal-menu-item-${i}`}
                                onSelect={() => {
                                    onSelect(action.eventKey);
                                    handleSelect();
                                }}
                            >
                                {action.label}
                            </MenuItem>
                        ))}
                    </SplitButton>
                )}
            </Modal.Footer>
        </Modal>
    );
};
PickerModal.displayName = "PickerModal";
PickerModal.defaultProps = {
    pickerType: PickerType.File,
};
export const openPickerModal = createModal(PickerModal);
