import { setOfflineItemSyncStatusAction } from "data/actions";
import { OfflineSyncStatus } from "data/types";

/**
 * re-sync current offlineItem
 */
class offline_syncItem {
    execute = ({ dispatch, offlineItem }) =>
        dispatch(
            setOfflineItemSyncStatusAction({
                item: offlineItem,
                status: OfflineSyncStatus.Unknown,
            })
        );
}

export default offline_syncItem;
