import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "hooks";
import { persistTogglesAction } from "data/actions";
import { getDisplayName } from "data/constants";
import getToggles from "data/toggles";
import queryString from "data/queryString";
import isEqual from "lodash/isEqual";
import { selectedTogglesSelector } from "data/reducers/selectors";
import { produce } from "immer";
import { usePostMessageHandler } from "hooks";
import { TogglesAction } from "hooks/PostMessage/Actions";
import { postMessageTo } from "hooks/PostMessage/Utils";

type Props = {
    /** Current URL location */
    location?: Location,
    /** Selected Toggles from props */
    toggles?: Array<string>,
    /** Viewname from props */
    viewName?: string,
};

type Config = {
    viewName?: string,
    onInit?: () => void,
};

// will manage initially loading default localized toggles
// and changing local state based on selectedToggles
const TogglesHandler =
    ({ viewName, onInit }: Config): Node =>
    (WrappedComponent: ReactClass<any>) => {
        const HOC = ({
            toggles: selectedTogglesFromProps,
            viewName: viewNameFromProps,
            location,
            injectedToggles = null,
            ...props
        }: Props) => {
            /* istanbul ignore next */
            const usedViewName = viewName || viewNameFromProps;
            const prevSelectedTogglesFromProps = usePrevious(
                selectedTogglesFromProps
            );
            const togglesFromViewName = useMemo(
                () =>
                    produce(getToggles(usedViewName), (draft) => {
                        /* istanbul ignore else */
                        if (typeof onInit === "function") {
                            onInit(draft);
                        }
                    }),
                [usedViewName]
            );
            const [toggles, setToggles] = useState(togglesFromViewName);
            const [persist, setPersist] = useState(true);
            const allowMultiple = useSelector(
                (state) => state.browser.greaterThanOrEqual.medium
            );
            const prevAllowMultiple =
                usePrevious(allowMultiple) ?? allowMultiple;
            const dispatch = useDispatch();
            const selectedTogglesFromStore = useSelector((state) =>
                selectedTogglesSelector(state, usedViewName)
            );
            const prevSelectedTogglesFromStore = usePrevious(
                selectedTogglesFromStore
            );
            const selectedTogglesFromQueryString = useMemo(
                () =>
                    queryString
                        .parse(location?.search ?? "")
                        ?.toggles?.split(","),
                [location?.search]
            );
            const prevSelectedTogglesFromQueryString = usePrevious(
                selectedTogglesFromQueryString
            );
            const [selectedToggles, setSelectedToggles] = useState(
                togglesFromViewName.toggles
                    .filter((t) => t.isSelected)
                    .map((t) => t.value)
            );
            const prevSelectedToggles =
                usePrevious(selectedToggles) || selectedToggles;
            const handleSetToggles = useCallback(
                (togglesFromCallback = null, persist = true) => {
                    // console.log("aaa", "handleSetToggles", persist);
                    const toggles = produce(
                        togglesFromCallback ?? togglesFromViewName,
                        (draft) => {
                            // Ensure to set usedViewname (for toggle selection)
                            draft.name = usedViewName;
                        }
                    );
                    setPersist(persist);
                    const selectedToggles = toggles.toggles
                        .filter((t) => t.isSelected)
                        .map((t) => t.value);
                    setSelectedToggles(
                        selectedToggles.length
                            ? selectedToggles
                            : [
                                  toggles.toggles.find((t) => !t.isDisabled)
                                      ?.value,
                              ]
                    );
                    setToggles(toggles);
                    persist &&
                        dispatch(
                            persistTogglesAction(usedViewName, toggles.toggles)
                        );
                },
                [dispatch, usedViewName, togglesFromViewName]
            );

            const availableToggles = toggles.toggles
                .filter((t) => !t.isHidden)
                .map((t) => t.value);

            // handle selected toggles from store
            useEffect(() => {
                if (selectedTogglesFromStore == null) return;
                if (
                    isEqual(selectedToggles, selectedTogglesFromStore) ||
                    isEqual(
                        prevSelectedTogglesFromStore,
                        selectedTogglesFromStore
                    )
                )
                    return;
                // console.log(
                //     "aaa",
                //     "selectedTogglesFromStore",
                //     selectedTogglesFromStore
                // );
                if (
                    selectedTogglesFromStore.some((t) =>
                        availableToggles.includes(t)
                    )
                )
                    setSelectedToggles(selectedTogglesFromStore);
                else setSelectedToggles([availableToggles[0]]);
            }, [
                selectedToggles,
                prevSelectedTogglesFromStore,
                selectedTogglesFromStore,
                availableToggles,
            ]);

            // handle selected toggles from props
            useEffect(() => {
                if (selectedTogglesFromProps == null) return;
                if (
                    isEqual(selectedToggles, selectedTogglesFromProps) ||
                    isEqual(
                        prevSelectedTogglesFromProps,
                        selectedTogglesFromProps
                    )
                )
                    return;
                // console.log(
                //     "aaa",
                //     "selectedTogglesFromProps",
                //     selectedTogglesFromProps
                // );
                setSelectedToggles(selectedTogglesFromProps);
            }, [
                selectedToggles,
                prevSelectedTogglesFromProps,
                selectedTogglesFromProps,
            ]);

            // handle selected toggles from query string
            useEffect(() => {
                if (selectedTogglesFromQueryString == null) return;
                if (
                    isEqual(selectedToggles, selectedTogglesFromQueryString) ||
                    isEqual(
                        prevSelectedTogglesFromQueryString,
                        selectedTogglesFromQueryString
                    )
                )
                    return;
                // console.log(
                //     "aaa",
                //     "selectedTogglesFromQueryString",
                //     selectedTogglesFromQueryString
                // );
                const validToggles = selectedTogglesFromQueryString.filter(
                    (t) => availableToggles.includes(t)
                );
                if (validToggles.length < 1) {
                    const msg =
                        "[TogglesHandler] Invalid toggle names provided. Please read the docs to setup properly.";
                    alert(msg);
                    console.error(
                        msg,
                        selectedTogglesFromQueryString,
                        "https://serviceportal.hs.ag/docs/inPoint.Web/docs/feature-integration-miniviews.html#view-toggles"
                    );
                } else {
                    if (
                        validToggles.length !==
                        selectedTogglesFromQueryString.length
                    ) {
                        console.warn(
                            "[TogglesHandler] Not all provided toggles can be set",
                            "https://serviceportal.hs.ag/docs/inPoint.Web/docs/feature-integration-miniviews.html#view-toggles"
                        );
                    }
                    /* istanbul ignore else */ if (
                        !isEqual(selectedToggles, validToggles)
                    ) {
                        setSelectedToggles(validToggles);
                    }
                }
            }, [
                selectedToggles,
                prevSelectedTogglesFromQueryString,
                selectedTogglesFromQueryString,
                toggles.toggles,
                availableToggles,
            ]);

            const handleToggleChange = useCallback(
                (map) => {
                    const togglesBySelected = produce(toggles, (draft) => {
                        draft.isMultiple =
                            allowMultiple && togglesFromViewName.isMultiple;
                        /* istanbul ignore else */ if (
                            typeof map === "function"
                        )
                            draft.toggles = draft.toggles.map(map);
                    });
                    if (!isEqual(toggles, togglesBySelected)) {
                        // console.log("aaa", "handleToggleChange");
                        setToggles(togglesBySelected);
                        dispatch(
                            persistTogglesAction(
                                usedViewName,
                                togglesBySelected.toggles
                            )
                        );
                    }
                },
                [
                    toggles,
                    usedViewName,
                    dispatch,
                    allowMultiple,
                    togglesFromViewName.isMultiple,
                ]
            );

            useEffect(() => {
                if (allowMultiple === prevAllowMultiple) return;
                const isMultiple =
                    togglesFromViewName.isMultiple && allowMultiple;
                // console.log("aaa", "allowMultiple changed", allowMultiple);
                let hasSelection = false;
                handleToggleChange((toggle) => {
                    toggle.isSelected =
                        toggle.isSelected &&
                        (isMultiple || (!isMultiple && !hasSelection));
                    if (toggle.isSelected) {
                        hasSelection = true;
                    }
                    return toggle;
                });
            }, [
                handleToggleChange,
                allowMultiple,
                prevAllowMultiple,
                togglesFromViewName.isMultiple,
            ]);

            // Effect to fix selected toggles
            useEffect(() => {
                if (isEqual(prevSelectedToggles, selectedToggles)) return;
                if (!persist) {
                    setPersist(true);
                    return;
                }
                // console.log(
                //     "aaa",
                //     "selectedToggles effect",
                //     selectedToggles,
                //     persist
                // );
                handleToggleChange((toggle) => {
                    toggle.isSelected = selectedToggles.includes(toggle.value);
                    return toggle;
                });
            }, [
                persist,
                prevSelectedToggles,
                selectedToggles,
                handleToggleChange,
            ]);

            // PostMessage
            usePostMessageHandler(
                useMemo(
                    () => ({
                        [TogglesAction.Get]: (data, source) => {
                            postMessageTo(
                                TogglesAction.Set,
                                toggles.toggles,
                                source
                            );
                        },
                        [TogglesAction.Set]: ({
                            toggles: maybeAllToggles,
                            persist,
                        }) => {
                            handleSetToggles(
                                produce(toggles, (draft) => {
                                    draft.toggles = draft.toggles.map(
                                        (toggle) => ({
                                            ...toggle,
                                            ...maybeAllToggles.find(
                                                (t) => t.value === toggle.value
                                            ),
                                        })
                                    );
                                }),
                                persist
                            );
                        },
                    }),
                    [toggles, handleSetToggles]
                )
            );

            return (
                <WrappedComponent
                    toggles={toggles}
                    viewName={usedViewName}
                    selectedToggles={selectedToggles}
                    location={location}
                    setToggles={handleSetToggles}
                    {...props}
                />
            );
        };
        HOC.displayName = `TogglesHandler(${getDisplayName(WrappedComponent)})`;
        return HOC;
    };

export default TogglesHandler;
