import useAsync from "react-use/lib/useAsync";
import { getFolderItemUri } from "data/api";

const useFolderItemUri = (itemUri) =>
    useAsync(async () => {
        if (itemUri == null) return null;
        try {
            return await getFolderItemUri(itemUri);
        } catch (e) {
            console.error("Cannot fetch folder itemUri", itemUri, e);
            return itemUri;
        }
    }, [itemUri]);

export { useFolderItemUri };
