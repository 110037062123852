import { openUploadVersionModal } from "components/UploadVersionModal";
import { type DroppedFile } from "containers/FileDropHandler";

/**
 * opens the upload dialog to upload a new document version to the given itemUri
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ files: Array<DroppedFile> }} { files }
 */

type Context = {
    itemUri: string,
    files?: Array<DroppedFile>,
};
class doc_uploadVersion {
    execute = async ({ itemUri, files }: Context) => {
        await openUploadVersionModal({
            files,
            itemUri,
        });
        return true;
    };
}

export default doc_uploadVersion;
