// @flow
import React from "react";
import { connect } from "react-redux";
import ViewModesMenu from "./ViewModesMenu";
import ViewModesButtons from "./ViewModesButtons";
import { type ListViewModeEnum, ListViewMode } from "data/types";
import styles from "./ViewModes.module.css";

export const ViewModeIcon = {
    [ListViewMode.Thumbnails]: "fa-regular fa-image",
    [ListViewMode.List]: "list",
    [ListViewMode.Grid]: "table",
    [ListViewMode.Map]: "earth-europe",
    [ListViewMode.Calendar]: "fa-regular fa-calendar",
};

type Props = {
    /** current selected ViewMode */
    viewMode: ListViewModeEnum,
    /** all available viewModes to render */
    viewModes: Array<ListViewModeEnum>,
    /** callback when user selects a different viewMode
     * @param {ListViewModeEnum} viewMode new selected viewMode
     */
    onChange: (viewMode: ListViewModeEnum) => void,
};

/** render the ViewMenu choice with requested viewModes marking the currently active viewMode */
export const ViewModes = (props: Props) => {
    if (
        props.viewModes == null ||
        props.viewMode == null ||
        props.viewModes.length <= 1
    )
        return null;
    else
        return props.browser.greaterThanOrEqual.large ? (
            <ViewModesButtons {...props} className={styles.right} />
        ) : (
            <ViewModesMenu {...props} className={styles.right} />
        );
};
ViewModes.displayName = "ViewModes";

// apply Redux Store state to Component Properties
const mapStateToProps = /* istanbul ignore next */ (state, ownProps) => ({
    browser: state.browser,
    ...ownProps,
});

export default connect(mapStateToProps)(ViewModes);
