import React from "react";
import ImageViewerControl from "components/ExtraAttributes/ViewerControl/ImageViewerControl";
import FileDropHandler, { type DroppedFile } from "containers/FileDropHandler";
import styles from "./UploadImage.module.css";
import Button from "react-bootstrap/lib/Button";
import Icons from "@hs/icons";
import { getFileExtension } from "data/utils";
import { openImageEditorModal } from "./ImageEditorModal";
import loc from "i18next";
import { toastStyled, toastTypes } from "data/toast";
import { defaultFallbackImage } from "components/ExtraAttributes/ViewerControl/ImageViewerControl";
import { type ImageResultDTO } from "data/types";

type Props = {
    /** relativePath for upload queue (e.g. while offline) */
    relativePath: string,
    /** unique attribute name */
    name: string,
    /** current image blob */
    value: ?string | ImageResultDTO,
    /** optional pixel width */
    width?: number,
    /** optional pixel height */
    height?: number,
    /** whether to allow editing */
    isReadonly?: boolean,
    /** CSV list of image file extensions (with .) to accept
     * @default ".png,.jpg,.jpeg,.jfif,.gif"
     */
    accept?: string,
    /** callback when user chose file to upload
     * @param {string} base64 image encoded as base64
     */
    onDone: (base64: string) => void,
    /** optional id for input element */
    id?: string,
};

// https://github.com/GrillWork/react-file-reader/blob/master/ReactFileReader.js
export default class UploadImage extends React.PureComponent<Props> {
    _input: HTMLInputElement | null;

    static defaultProps = {
        isReadonly: false,
        accept: ".png,.jpg,.jpeg,.jfif,.gif",
    };

    _onDrop = (files: Array<DroppedFile>) => {
        if (files.length !== 1) {
            toastStyled({
                message: loc.t("upload:image.onlyOne"),
                ...toastTypes.failure,
            });
            return;
        }
        const file = files[0].data;
        if (this._validateFile(file)) this._processFile(file);
    };

    _onBrowse = (e) => this._input && this._input.click();

    _onBrowseChange = (e) => {
        // get the files
        let files = e.target.files;
        /* istanbul ignore next */
        if (files.length !== 1) return;
        e.persist();

        // Process single first file
        let file = files[0];
        if (this._validateFile(file))
            this._processFile(file, () => {
                // We clear the input after a file is selected, because otherwise
                // change event is not fired in Chrome and Safari when a file
                // with the same name is selected.
                // ___Why not use value="" on <input/> instead?
                //    Because if we use that method of clearing the input,
                //    Chrome will not trigger change if we drop the same file twice (Issue #768).
                e.target.value = null;
            });
    };

    _validateFile = (file: Blob): boolean => {
        const ext = getFileExtension(file.name);
        if (this.props.accept.indexOf(ext) === -1) {
            toastStyled({
                message: loc.t("upload:image.invalidFile", {
                    valid: this.props.accept,
                }),
                ...toastTypes.failure,
            });
            return false;
        }
        return true;
    };

    _processFile = (file: Blob, cb?: Function) => {
        // Make new FileReader
        const reader = new FileReader();
        reader.addEventListener("loadend", () => {
            /* istanbul ignore else */
            if (reader.readyState === 2) {
                /* istanbul ignore else */
                if (typeof this.props.onDone === "function")
                    this.props.onDone(reader.result);
            } else {
                console.warn(
                    `UploadImage Cannot load file ${file.name}: ${reader.error.message}`
                );
            }
            /* istanbul ignore else */
            if (typeof cb === "function") cb();
        });

        // Convert the file to base64 text
        reader.readAsDataURL(file);
    };

    _onEdit = () => {
        const { src } = this._parseValueAsImageResultDTO();
        openImageEditorModal({
            title: loc.t("upload:image.title", { name: this.props.name }),
            src,
            width: this.props.width,
            height: this.props.height,
        }).then((result) => result && this.props.onDone(result.croppedImage));
    };

    _parseValueAsImageResultDTO = () => {
        const { value } = this.props;
        if (!value) {
            return {
                src: defaultFallbackImage,
                fallback: true,
            };
        } else if (typeof value === "string") {
            return {
                src: value,
                fallback: false,
            };
        } else {
            return this.props.value;
        }
    };

    render() {
        const { src, fallback: hideEdit } = this._parseValueAsImageResultDTO();

        const viewer = (
            <ImageViewerControl
                className={styles.image}
                name={this.props.name}
                value={this.props.value}
                width={this.props.width}
                height={this.props.height}
            />
        );

        if (this.props.isReadonly)
            return (
                <div className={`${styles.container} ${styles.ro}`}>
                    {viewer}
                </div>
            );
        else
            return (
                <FileDropHandler
                    relativePath={this.props.relativePath}
                    allowDrop
                    addFilesToUppy={false}
                    dragOverText="drop"
                    onDrop={this._onDrop}
                    className={styles.container}
                >
                    {src && viewer}
                    <div className={styles.middle}>
                        <input
                            type="file"
                            className={styles.input}
                            accept={this.props.accept}
                            onChange={this._onBrowseChange}
                            multiple={false}
                            ref={(ref) => (this._input = ref)}
                            id={this.props.id}
                        />
                        <Button
                            bsStyle="link"
                            className={styles.button}
                            onClick={this._onBrowse}
                            title={loc.t("upload:image.upload.title")}
                        >
                            <Icons.Library name="upload" size="lg" />
                            <p>{loc.t("upload:image.upload.button")}</p>
                        </Button>
                        {!hideEdit && (
                            <Button
                                bsStyle="link"
                                className={styles.button}
                                onClick={this._onEdit}
                                title={loc.t("upload:image.edit.title")}
                            >
                                <Icons.Library name="pen-to-square" size="lg" />
                                <p>{loc.t("upload:image.edit.button")}</p>
                            </Button>
                        )}
                    </div>
                </FileDropHandler>
            );
    }
}
