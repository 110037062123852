// @flow
import React, { useState, useCallback } from "react";
import Constants from "data/constants";
import Status from "components/Status";

/**
 * renders a local loading icon until iframe onLoad event is fired
 */
const LoadingIframe = ({
    onLoad,
    ...props
}: {
    onLoad: () => void,
    props: Object,
}) => {
    const [loading, setLoading] = useState(true);

    const _onLoad = useCallback(() => {
        setLoading(false);
        /* istanbul ignore else */
        if (typeof onLoad === "function") onLoad();
    }, [setLoading, onLoad]);

    return (
        <>
            {loading && <Status status={Constants.LOADING} />}
            <iframe
                title="external link"
                width="100%"
                height="100%"
                marginHeight="0"
                marginWidth="0"
                frameBorder="0"
                allowFullScreen={true}
                {...props}
                onLoad={_onLoad}
            />
        </>
    );
};

export default LoadingIframe;
