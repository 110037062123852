import { call, take, actionChannel, select, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
    userLanguageSelector,
    settingValueSelector,
} from "data/reducers/selectors";
import actionTypes from "data/actions/actionTypes";
import { notificationUpsertAction } from "data/actions";
import { Toast, RoutedToast } from "data/toast";
import { NotificationType } from "data/types";
import { buffers } from "redux-saga";
import Icons from "@hs/icons";

const mapFontawesomeIconToNotificationsIcon = (icon) =>
    icon?.includes("fa-")
        ? icon
              .split(" ")
              .filter((i) => i.startsWith("fa-"))
              .map((i) => i.replace("fa-", ""))
              .join("-")
        : `solid-${icon}`;

function* triggerNotificationAPI({ message, handleClick, autoClose, icon }) {
    const lang = yield select(userLanguageSelector);
    const notification = new Notification(message, {
        dir: "auto",
        lang,
        icon: icon
            ? `${
                  window.CONFIG.host.basename
              }/__notification/${mapFontawesomeIconToNotificationsIcon(
                  icon
              )}.png`
            : undefined,
    });
    /* istanbul ignore else */
    if (handleClick) {
        notification.addEventListener("click", handleClick);
    }
    /* istanbul ignore else */
    if (autoClose) {
        setTimeout(notification.close.bind(notification), autoClose);
    }
    return notification;
}

function triggerReactToastify({
    icon,
    message,
    details,
    handleClick,
    style,
    isRouted,
    className,
    toastId,
    autoClose,
    position,
}) {
    const Component = isRouted ? RoutedToast : Toast;
    const toastOptions = {
        type: style,
        className,
        toastId,
        autoClose,
        position,
        theme: "colored",
        icon: icon && <Icons.Library name={icon} className="fa-lg" />,
    };

    Object.keys(toastOptions).forEach((key) =>
        toastOptions[key] === undefined ? delete toastOptions[key] : {}
    );

    toast(
        <Component
            message={message}
            details={details}
            handleClick={handleClick}
            style={style}
        />,
        toastOptions
    );
}

function* toastSaga() {
    const channel = yield actionChannel(
        actionTypes.NOTIFICATION_TOAST,
        buffers.expanding()
    );

    while (true) {
        const { payload } = yield take(channel);
        const notificationType = yield select((state) =>
            settingValueSelector("notificationType", state)
        );
        let toast = payload.toast;
        if (notificationType === NotificationType.API) {
            // Update toast property with notification reference to close notification
            toast = yield call(triggerNotificationAPI, {
                ...payload.toast,
                message: payload.message,
                icon: payload.icon,
            });
        } else {
            triggerReactToastify({
                ...payload.toast,
                toastId: payload.notificationId,
                message: payload.message,
                style: payload.style,
                icon: payload.icon,
            });
        }
        yield put(notificationUpsertAction({ ...payload, toast }));
    }
}

export default toastSaga;
