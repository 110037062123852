import React, { useCallback, useMemo } from "react";
import FormBar from "@hs/form-bar";
import { useFormActions, usePersistSetting } from "hooks";
import { SearchScope as Scope, FormBarView } from "data/types";
import SearchScopeSelect from "components/MainSearchScope/SearchScopeSelect";
import styles from "./SearchScope.module.css";

type Props = {
    /** optional string of css classes */
    className?: string,
    /** initial search scope */
    searchScope: SearchScopeEnum,
    /** initial search scope itemUri */
    itemUri: string,
    /** callback when user changes scope
     * @param {SearchScopeEnum} searchScope new search scope
     * @param {string} itemUri new scoped itemUri
     */
    onSearchScopeChange: (
        searchScope: SearchScopeEnum,
        itemUri: ?string
    ) => void,
};

const components = {
    Container: "aside",
};

/**
 * renders dismissable search scope
 *
 * @param {Props} { scope, className }
 */
export const SearchScope = ({
    searchScope,
    onSearchScopeChange,
    className,
    scopeItemUri: itemUri,
}: Props) => {
    const [value, setValue] = usePersistSetting({
        name: "showSearchScope",
        defaultValue: false,
    });
    const handleClose = useCallback(() => setValue(false), [setValue]);
    const contextProps = useMemo(
        () => ({
            itemUri,
            onSearchScopeChange,
            showSearchScope: { value, setValue },
        }),
        [itemUri, onSearchScopeChange, value, setValue]
    );
    const actions = useFormActions(FormBarView.SearchScope, contextProps);
    return (
        <FormBar
            className={`${styles.aside} ${value ? styles.show : styles.hide}`}
            onClose={handleClose}
            actions={actions}
            components={components}
        >
            <SearchScopeSelect
                className={styles.wrapper}
                radioClassName={styles.radio}
                searchScope={searchScope || Scope.AllSites}
                itemUri={itemUri}
                onChange={onSearchScopeChange}
                renderAction={false}
            />
        </FormBar>
    );
};
SearchScope.displayName = "FormBar.SearchScope";
export default SearchScope;
