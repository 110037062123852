import React from "react";
import Activities from "containers/Activities";
import { type ItemActionViewConfig, type ItemActionConfig } from "data/types";

type ItemActionViewActivityListConfig = ItemActionViewConfig & {
    /** whether this item is a Document or Folder */
    isDoc?: boolean,
    /** whether to allow adding notes
     * @default {boolean} false
     */
    showAddNote?: boolean,
    /** whether to show notes (comment) entries
     * @default {boolean} true
     */
    showNotes?: boolean,
    /** whether to show history entries
     * @default {boolean} false
     */
    showHistory?: boolean,
    /** whether to show retention info
     * @default {boolean} false
     */
    showRetentionInfo?: boolean,
};

/**
 * shows the ActivityList
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ formatId: number }} { formatId }
 * @param {{ action: ItemActionConfig }} { action }
 * @param {{ view: ItemActionViewActivityListConfig }} { view }
 * @param {{ onItemUriAction: (itemUri: string) => void }} { onItemUriAction }
 */
const ItemView_ActivityList = ({
    itemUri,
    formatId,
    action,
    view,
    onItemUriAction,
}: {
    itemUri: string,
    formatId: number,
    action: ItemActionConfig,
    view: ItemActionViewActivityListConfig,
    onItemUriAction: (itemUri: string) => void,
}) => (
    <Activities
        isDoc={view.isDoc || false}
        itemUri={itemUri}
        formatId={formatId}
        showAddNote={view.showAddNote}
        showNotes={view.showNotes}
        showHistory={view.showHistory || false}
        showRetentionInfo={view.showRetentionInfo || false}
        showActions={false}
    />
);

ItemView_ActivityList.displayName = "ItemViews.ActivityList";
export default ItemView_ActivityList;
