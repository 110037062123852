// @flow
import React from "react";
import MenuItem from "react-bootstrap/lib/MenuItem";
import loc from "i18next";
import { type SearchBucketDTO } from "data/types";

type Props = {
    item: SearchBucketDTO,
    onSelect: (name: string, value: Object) => void,
};

const AllItem = ({ item, onSelect }: Props) => (
    <MenuItem
        key="bvall"
        onSelect={() =>
            onSelect({
                name: item.name,
                value: "__ALL__",
            })
        }
    >
        {loc.t("common:filter.AllCaption")}
    </MenuItem>
);

AllItem.displayName = "FilterBar.SearchBucket.AllItem";

export default AllItem;
