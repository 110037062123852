import React from "react";
import CommandActionHandler from "components/CommandActions";
import { type ItemActionConfig } from "data/types";

import ItemView_DocList from "./itemView_docList";
import ItemView_ActivityList from "./itemView_activityList";
import ItemView_Workflow from "./itemView_workflow";
import ItemView_Reminder from "./itemView_reminder";
import ItemView_Filter from "./itemView_filter";
import ItemView_MultiSelection from "./itemView_multiSelection";

const viewMappings = {
    itemView_docList: ItemView_DocList,
    itemView_activityList: ItemView_ActivityList,
    itemView_workflow: ItemView_Workflow,
    itemView_reminder: ItemView_Reminder,
    itemView_filter: ItemView_Filter,
    itemView_multiSelection: ItemView_MultiSelection,
};

const Factory = ({
    action,
    onAction,
}: {
    action: ItemActionConfig,
    onAction: () => void,
}) => {
    const componentClass = viewMappings[action.view && action.view.name];
    if (componentClass == null) {
        alert(
            `CommandAction ${action.id} defines an unknown view named ${action.view.name}`
        );
        return null;
    } else {
        let props = { onAction };
        if (
            action.commandAction != null &&
            action.commandAction.props != null
        ) {
            props = Object.assign(props, action.commandAction.props, {
                action: action,
                view: action.view,
                onItemUriAction: (itemUri: string) =>
                    CommandActionHandler(
                        action.commandAction.name,
                        Object.assign({}, action.commandAction.props, {
                            itemUri,
                        })
                    ),
                onClick: (props: Object) =>
                    CommandActionHandler(
                        action.commandAction.name,
                        Object.assign({}, action.commandAction.props, props)
                    ),
            });
        } else {
            console.warn(
                `Since action ${action.id} has a view defined ${action.view.name} but no CommandAction defined, no contextProps will be passed on!`
            );
        }
        return React.createElement(componentClass, props);
    }
};
Factory.displayName = "itemViews.Factory";
export default Factory;
