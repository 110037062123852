import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";
import { type searchFormListResultState } from "./types";

export const initialState: searchFormListResultState = {
    status: Constants.NONE,
    result: [],
};

const searchFormsReducer = (
    state: searchResultState = initialState,
    action
) => {
    switch (action.type) {
        case actionTypes.SEARCH_FORM_LIST_FETCH_REQUEST:
        case actionTypes.SEARCH_FORM_LIST_FETCH_RESULT:
            return {
                ...state,
                ...action.payload,
            };
        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default searchFormsReducer;

export const searchFormListStatusSelector = (state: Object) =>
    state.searchForms.status;
export const searchFormListResultSelector = (state: Object) =>
    state.searchForms.result;
export const searchFormListCountSelector = (state: Object) =>
    state.searchForms.status === Constants.OK
        ? state.searchForms.result.length
        : -1;
