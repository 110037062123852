import React from "react";
import { useAsyncMemo } from "hooks";
import { mapValues, tokenize } from "data/utils";
import { stringForFormTokens, withFormTokens } from "../utils/FormTokens";
import Status from "components/Status";
import Constants from "data/constants";
import { type Props } from "./Factory";

const CustomItemFlow = (props: Props) => {
    const encodedContext = useAsyncMemo(async () => {
        const encodedContext = mapValues(props, (value) =>
            encodeURIComponent(value)
        );
        if (!props.itemUri) {
            return encodedContext;
        }
        return await withFormTokens(props, encodedContext, true);
    }, [props]);
    if (encodedContext == null) {
        return <Status status={Constants.LOADING} />;
    }
    const tokenizedUrl = tokenize(
        stringForFormTokens(props.flow.commandAction.props.url),
        encodedContext
    );
    return (
        <iframe
            title="itemFlow custom"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen={true}
            src={tokenizedUrl}
        />
    );
};

CustomItemFlow.displayName = "ItemFlowsInput.Custom";
export default CustomItemFlow;
