import { useRef, useMemo } from "react";
import { useUnmount, usePostMessageHandler } from "hooks";
import { toastStyled, dismissToast, toastTypes, toastId } from "data/toast";
import { PdfSignatureAction } from "hooks/PostMessage/Actions";
import loc from "i18next";

/**
 * handles messaging with pdfJs to alert user of invalidated PDF Signature
 */
const useHandleSignedPdf = () => {
    const toastRef = useRef(null);
    // register callback from pdf.js
    usePostMessageHandler(
        useMemo(
            () => ({
                [PdfSignatureAction]: () => {
                    if (toastRef.current == null) {
                        toastRef.current = toastStyled({
                            ...toastTypes.pdfSignature,
                            message: loc.t("docmodal:signedPdf"),
                        });
                    }
                },
            }),
            []
        )
    );

    // dismiss Toast in case user just closed the DocViewer
    useUnmount(() => dismissToast(toastId.PdfJs));
};

export default useHandleSignedPdf;
