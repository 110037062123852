/* istanbul ignore next */
function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}

/* istanbul ignore next */
export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues
        image.src = url;
    });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
/* istanbul ignore next */
export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    // First, calculate the bounding box size:
    const image = await createImage(imageSrc);

    const rotRad = getRadianAngle(rotation);
    const bBoxWidth =
        Math.abs(Math.cos(rotRad) * image.width) +
        Math.abs(Math.sin(rotRad) * image.height);
    const bBoxHeight =
        Math.abs(Math.sin(rotRad) * image.width) +
        Math.abs(Math.cos(rotRad) * image.height);

    //  Second, create a canvas element with the size of the bounding box:
    const canvas = document.createElement("canvas");

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // Then, rotate the canvas context around the center of the canvas:
    const ctx = canvas.getContext("2d");

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);

    // Set context to point to the top-left corner of the rotated image:
    ctx.translate(-image.width / 2, -image.height / 2);

    // Draw the image onto the rotated context:
    ctx.drawImage(image, 0, 0);

    // What we have in the canvas at this point is a rotated image inside its bounding box.
    // Now, we just need to extract the result using croppedAreaPixels and repaint it on a canvas with the size of the final image:

    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    );

    // set the canvas size to the final image size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste the extracted image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As Base64 string
    return canvas.toDataURL("image/jpeg");
}
