// @flow
import React from "react";
import PropTypes from "prop-types";
import FormControl from "react-bootstrap/lib/FormControl";
import { DocListFilterOperators, type DocListColumnTypeEnum } from "data/types";
import loc from "i18next";

const operatorsPerType = {
    StringValue: [
        DocListFilterOperators.Equals,
        DocListFilterOperators.NotEquals,
        DocListFilterOperators.Contains,
        DocListFilterOperators.DoesNotContain,
        DocListFilterOperators.StartsWith,
        DocListFilterOperators.DoesNotStartWith,
        DocListFilterOperators.EndsWith,
        DocListFilterOperators.DoesNotEndWith,
    ],
    IntValue: [
        DocListFilterOperators.Equals,
        DocListFilterOperators.NotEquals,
        DocListFilterOperators.LessThan,
        DocListFilterOperators.LessThanOrEqualsTo,
        DocListFilterOperators.GreaterThan,
        DocListFilterOperators.GreaterThanOrEqualsTo,
        DocListFilterOperators.Between,
    ],
    BooleanValue: [DocListFilterOperators.None, DocListFilterOperators.Equals],
    LongValue: [
        DocListFilterOperators.Equals,
        DocListFilterOperators.NotEquals,
        DocListFilterOperators.LessThan,
        DocListFilterOperators.LessThanOrEqualsTo,
        DocListFilterOperators.GreaterThan,
        DocListFilterOperators.GreaterThanOrEqualsTo,
        DocListFilterOperators.Between,
    ],
    ShortValue: [
        DocListFilterOperators.Equals,
        DocListFilterOperators.NotEquals,
        DocListFilterOperators.LessThan,
        DocListFilterOperators.LessThanOrEqualsTo,
        DocListFilterOperators.GreaterThan,
        DocListFilterOperators.GreaterThanOrEqualsTo,
        DocListFilterOperators.Between,
    ],
    DoubleValue: [
        DocListFilterOperators.Equals,
        DocListFilterOperators.NotEquals,
        DocListFilterOperators.LessThan,
        DocListFilterOperators.LessThanOrEqualsTo,
        DocListFilterOperators.GreaterThan,
        DocListFilterOperators.GreaterThanOrEqualsTo,
        DocListFilterOperators.Between,
    ],
    DecimalValue: [
        DocListFilterOperators.Equals,
        DocListFilterOperators.NotEquals,
        DocListFilterOperators.LessThan,
        DocListFilterOperators.LessThanOrEqualsTo,
        DocListFilterOperators.GreaterThan,
        DocListFilterOperators.GreaterThanOrEqualsTo,
        DocListFilterOperators.Between,
    ],
    SingleValue: [
        DocListFilterOperators.Equals,
        DocListFilterOperators.NotEquals,
        DocListFilterOperators.LessThan,
        DocListFilterOperators.LessThanOrEqualsTo,
        DocListFilterOperators.GreaterThan,
        DocListFilterOperators.GreaterThanOrEqualsTo,
    ],
    DateTimeValue: [
        DocListFilterOperators.DateRelative,
        DocListFilterOperators.Equals,
        DocListFilterOperators.LessThan,
        DocListFilterOperators.LessThanOrEqualsTo,
        DocListFilterOperators.GreaterThan,
        DocListFilterOperators.GreaterThanOrEqualsTo,
        DocListFilterOperators.Between,
    ],
    DateTimeOffsetValue: [DocListFilterOperators.None],
    ByteValue: [
        DocListFilterOperators.Equals,
        DocListFilterOperators.NotEquals,
        DocListFilterOperators.LessThan,
        DocListFilterOperators.LessThanOrEqualsTo,
        DocListFilterOperators.GreaterThan,
        DocListFilterOperators.GreaterThanOrEqualsTo,
        DocListFilterOperators.Between,
    ],
    ObjectValue: [DocListFilterOperators.None],
};

operatorsPerType.WebDavName = operatorsPerType.StringValue;
operatorsPerType.FileSize = operatorsPerType.LongValue;

const Operators = ({
    index,
    type,
    value,
    onChange,
    id,
}: {
    index: number,
    type: DocListColumnTypeEnum,
    value: string,
    onChange: Function,
}) => (
    <FormControl
        componentClass="select"
        name={index}
        value={value}
        onChange={onChange}
        id={id}
    >
        {Array.isArray(operatorsPerType[type]) ? (
            operatorsPerType[type].map((opType, index) => (
                <option key={index} value={opType}>
                    {loc.t("folder:action_filter.operators." + opType)}
                </option>
            ))
        ) : (
            <option value={DocListFilterOperators.None}>
                {loc.t(
                    `folder:action_filter.operators.${DocListFilterOperators.None}`
                )}
            </option>
        )}
    </FormControl>
);

Operators.propTypes = {
    index: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default Operators;
