import _queryString, { StringifyOptions } from "query-string";

const queryString = {
    ..._queryString,
    stringify: (object: Record<string, any>, options: StringifyOptions) =>
        _queryString.stringify(object, {
            skipNull: true,
            skipEmptyString: true,
        }),
};

export default queryString;
