// @flow
import React from "react";
import ModalBootstrap from "react-bootstrap/lib/Modal";
import Form from "react-bootstrap/lib/Form";
import { isHandheld, isIOS } from "data/bowser";
import debounce from "lodash/debounce";
import styles from "./Modal.module.css";
import { History } from "history";
import { withRouter } from "react-router";
import queryString from "data/queryString";
import sharedStyles from "shared/styles.module.css";
import { getClassNames } from "data/utils";

const ModalHeader = (props) => <ModalBootstrap.Header {...props} />;
const ModalTitle = (props) => <ModalBootstrap.Title {...props} />;
const ModalBody = (props) => <ModalBootstrap.Body {...props} />;
const ModalFooter = (props) => <ModalBootstrap.Footer {...props} />;

export const ModalSize = {
    Large: "large",
    Medium: undefined,
    Small: "small",
};

type ModalSizeEnum = $Values<typeof ModalSize>;

type Props = {
    history: History,
    show: boolean,
    onHide: () => void,
    onKeyPress: () => void,
    style?: CSSStyleDeclaration,
    /** width of Dialog box to render */
    size?: ModalSizeEnum,
    /** height in pixels of header
     * @default 42 */
    headerSize?: number,
    /** height in pixels of footer
     * @default 0
     */
    footerSize?: number,
    /** whether to scroll modal contents
     * @default false
     */
    scroll?: boolean,
    /** whether modal should take fullscreen height (true) or max-height (false)
     * @default false
     */
    fullscreen?: boolean,
    /** whether modal should open docked to the right (true) or centered (false)
     * @default false
     */
    docked?: boolean,
    /** whether modal should enforce focus state (set to false if portal is used within modal)
     * @default true
     */
    enforceFocus?: boolean,
    /** optional CSS class to apply to Modal dialog */
    dialogClassName?: string,
    /** optional string to help test engines target this component */
    dataTest?: string,
};

type State = {
    vh: number,
    fs: boolean,
    closeRequested: boolean,
};

/**
 * wrapper around Modal which will dynamically calculate viewport height
 */
export class Modal extends React.PureComponent<Props, State> {
    Header: ModalHeader;
    Title: ModalTitle;
    Body: ModalBody;
    Footer: ModalFooter;

    static defaultProps = {
        size: ModalSize.Large,
        headerSize: 42,
        footerSize: 0,
        scroll: false,
        fullscreen: false,
        enforceFocus: true,
    };

    constructor(props: Props) {
        super(props);
        const fs = this._shouldFullscreen();
        this.state = {
            vh: this._getHeight(fs),
            fs,
            closeRequested: false,
            location: props.history.location,
        };
    }

    componentDidMount = () => {
        this.historyUnlisten = this.props.history.listen((location, action) => {
            // Compare search parameter to distinguish if modal should be closed: Do not close Modal on e.g. pagination
            const _search = queryString.parse(this.state.location.search);
            const search = queryString.parse(location.search);
            const closeRequested = search.uri !== _search.uri;
            this.setState({
                location,
                closeRequested,
            });
        });
        window.addEventListener("resize", this._onResizeDebounced, {
            capture: false,
            passive: true,
        });
    };

    componentWillUnmount = () => {
        if (this.historyUnlisten) this.historyUnlisten();
        window.removeEventListener("resize", this._onResizeDebounced);
    };

    componentDidUpdate(prevProps: Props) {
        /* istanbul ignore else */
        if (this.props.show !== prevProps.show && this.props.show === true)
            this._onResize();
    }

    _onResize = () => {
        const fs = this._shouldFullscreen();
        this.setState({
            vh: this._getHeight(
                /*istanbul ignore next*/ fs || this.props.docked
            ),
            fs,
        });
    };

    _onResizeDebounced = debounce(this._onResize, 400);

    _shouldFullscreen = () => isHandheld() || this._getWidth() <= 768;

    _getWidth = () =>
        isIOS() ? document.documentElement.clientWidth : window.innerWidth;

    _getHeight = (fs: boolean) => {
        const height = isIOS()
            ? document.documentElement.clientHeight
            : window.innerHeight;
        const margin = fs ? 0 : 31;
        return (
            height - this.props.headerSize - this.props.footerSize - margin * 2
        );
    };

    _renderChildren = (children) =>
        React.Children.map(children, (child, index) =>
            this._renderChild(child)
        );

    _renderChild = (child) => {
        /* istanbul ignore else */
        if (child != null) {
            if (child.type === ModalBody) {
                return React.cloneElement(child, {
                    style: {
                        height: this.props.fullscreen
                            ? this.state.vh
                            : undefined,
                        maxHeight: this.props.fullscreen
                            ? undefined
                            : this.state.vh,
                        overflowY:
                            this.props.scroll === true ? "auto" : "hidden",
                    },
                });
            } else if (child.type === ModalHeader) {
                return React.cloneElement(child, {
                    style: {
                        height: this.props.headerSize,
                    },
                });
            } else if (
                child.type === Form &&
                React.Children.count(child.props.children) > 0
            ) {
                return React.cloneElement(
                    child,
                    {},
                    this._renderChildren(child.props.children)
                );
            } else {
                return React.cloneElement(child, {});
            }
        }
    };
    render() {
        const { show, onHide, onKeyPress, dialogClassName, style } = this.props;
        return (
            <ModalBootstrap
                enforceFocus={this.props.enforceFocus}
                show={show && !this.state.closeRequested}
                animation={false}
                onHide={onHide}
                onKeyPress={onKeyPress}
                style={style}
                bsSize={this.props.size}
                data-test={this.props.dataTest}
                dialogClassName={getClassNames(
                    styles.modal,
                    dialogClassName,
                    this.state.fs ? styles.fs : "",
                    this.props.docked ? styles.docked : "",
                    sharedStyles.select_none
                )}
            >
                {this._renderChildren(this.props.children)}
            </ModalBootstrap>
        );
    }
}

Modal.Header = ModalHeader;
Modal.Title = ModalTitle;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
export default withRouter(Modal);
