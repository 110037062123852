import React from "react";
import { type SummaryAttributeValueDatetime } from "data/types";

export const ValueDatetime = ({
    className,
    value,
}: {
    className: string,
    value: SummaryAttributeValueDatetime,
}) => {
    return (
        <span className={className} title={value.value}>
            {value.value}
        </span>
    );
};
ValueDatetime.displayName = "SummaryAttribute.Value.Datetime";

export default ValueDatetime;
