import ApiManagerBase, { type Context } from "./ApiManagerBase";
import {
    ContentType,
    type OfflineItemDTO,
    CustomHttpHeader,
    type ApiMethodRef,
    DEFAULT_CACHE_NAME,
} from "data/types";
import ApiCacheManager from "data/ApiCacheManager";
import { JL } from "jsnlog";

/**
 * Specifically used by offlineSaga to sync "make available offline"marked for offline items
 *
 * @export
 * @class ApiManagerMakeAvailableOffline
 * @extends {ApiManagerBase}
 */
export default class ApiManagerMakeAvailableOffline extends ApiManagerBase {
    constructor(offlineItem: OfflineItemDTO) {
        super();
        this._log = JL("ApiManagerMakeAvailableOffline");
        this._offlineItem = offlineItem;
        this._apiCache = new ApiCacheManager();
        this.CacheExists = this.CacheExists.bind(this);
        this.DeleteCache = this.DeleteCache.bind(this);
        this.LogEstimates = this.LogEstimates.bind(this);
        this.CheckLicense = this.CheckLicense.bind(this);
        this.EOL = this.EOL.bind(this);
        this._callWithDeps = this._callWithDeps.bind(this);
        this._runTransformer = this._runTransformer.bind(this);
        this._addToCache = this._addToCache.bind(this);
    }

    _callWithDeps = async (
        name: string,
        args?: Object = {}
    ): Promise<Array<ApiMethodRef>> => {
        // calling the API method will effectively cache it
        const context = await this._callReturnContext(name, args);
        const { cache } = context.request;
        if (cache == null || cache.depsAnalyzer == null) return [];
        else return cache.depsAnalyzer(context.body, context.request.params);
    };

    /**
     * Deletes the whole current OfflineItem's cache
     * @returns {Promise<bool>} true if the cache was actually deleted and false if something went wrong or the cache doesn’t exist
     * @memberof ApiManagerMakeAvailableOffline
     * @public
     */
    DeleteCache = (): Promise<boolean> => {
        /* istanbul ignore else */
        if (this._offlineItem) {
            return this._apiCache.DeleteCache(this._offlineItem.id);
        } else {
            return Promise.reject(false);
        }
    };

    /**
     * Checks if an existing Cache key already exists
     * @returns {Promise<boolean>} true or false
     * @memberof ApiManagerMakeAvailableOffline
     * @public
     */
    CacheExists = (): Promise<boolean> => {
        /* istanbul ignore else */
        if (this._offlineItem) {
            return this._apiCache.CacheExists(this._offlineItem.id);
        } else {
            return Promise.reject(false);
        }
    };

    /**
     * Logs to the console some storage usage estimates
     * @memberof ApiManagerMakeAvailableOffline
     * @public
     */
    LogEstimates = (): void => {
        /* istanbul ignore else */
        if (this._offlineItem) {
            this._apiCache.LogEstimates(this._offlineItem.id);
        }
    };

    /**
     * Prepares request's HTTP headers
     * @memberof ApiManagerMakeAvailableOffline
     * @param {Context} context query context
     * @returns {Promise<Context>} current context
     * @override
     */
    async BeforeFetch(context: Context): Promise<Context> {
        context = await super.BeforeFetch(context);
        // tell serviceWorker to skip these requests - we'll cache them
        context.options.headers.delete(CustomHttpHeader.CacheStrategy);
        // #55379 tell the server we are requesting for offline cache
        context.options.headers.append(CustomHttpHeader.OfflineSync, "true");
        return context;
    }

    /**
     * We skip licensing check when caching offline items
     * @param {Context} context query context
     * @returns {Promise<Context>} current context
     * @memberof ApiManagerMakeAvailableOffline
     * @override
     */
    async CheckLicense(context: Context): Promise<Context> {
        return context;
    }

    // TODO handle API custom errors instead of throwing
    // async CheckCustomError(context: Context): Promise<Context> {
    // }

    /**
     * Convert response body and cache it
     * @param {Context} context query context
     * @returns {Promise<Context>} current context
     * @memberof ApiManagerMakeAvailableOffline
     * @override
     */
    async EOL(context: Context): Promise<Context> {
        this._log.debug(() => ({
            msg: `${context.request.name}: EOL`,
            apiContext: context,
        }));

        // custom transformer function defined?
        context = this._runTransformer(context);

        await this._addToCache(context);

        return context;
    }

    _runTransformer = (context: Context): Promise<Context> => {
        if (
            context.request &&
            typeof context.request.transformer === "function"
        ) {
            if (
                context.request.contentType !== ContentType.AUTO &&
                context.request.contentType !== ContentType.JSON
            ) {
                throw new Error(
                    `ApiCatalog method ${context.request.name} error: cache.transformer can only be used on ContentType.AUTO or JSON!`
                );
            }
            try {
                // transform it and convert back to body
                const cacheBody = JSON.stringify(
                    context.request.transformer(context.body)
                );

                // return/cache this response instead
                context.response = new Response(cacheBody, context.response);
            } catch (e) {
                this._log.fatalException(
                    () => ({
                        msg: "error while transforming body",
                        body: context.body,
                        apiMethod: context.request.name,
                        apiContext: context,
                    }),
                    e
                );
                throw new Error(
                    "Could not transform body for " + context.request.name
                );
            }
        }
        return context;
    };

    _addToCache = async (context: Context): void => {
        // note: better control here than having the serviceWorker do this; cons - #54575 we need to handle our own cache store
        const cache = await this._apiCache.OpenCache(this._offlineItem.id);
        if (cache != null) {
            const cacheKey = this._getFullCacheKey(context);
            // note: no need to clone response here since .DecodeBody() does it for us!
            try {
                await cache.put(cacheKey, context.response);

                // Delete existing cache entry from Default store
                // Fixes #58699 Remove offline availability is not available
                const defaultCache = await this._apiCache.OpenCache(
                    DEFAULT_CACHE_NAME
                );
                const deleted = await defaultCache.delete(cacheKey);
                /* istanbul ignore next */
                if (deleted) {
                    this._log.info(() => ({
                        msg: `Deleted duplicate cacheKey from defaultStore.`,
                        cacheKey,
                        defaultCache: DEFAULT_CACHE_NAME,
                        cache: this._offlineItem.id,
                    }));
                }
            } catch (e) {
                this._log.fatalException(
                    () => ({
                        msg: "could not store cacheKey",
                        cacheKey: cacheKey,
                        apiMethod: context.request.name,
                        apiContext: context,
                    }),
                    e
                );
                throw new Error("Could not store cacheKey " + cacheKey);
            }
        } else {
            this._log.fatalException(() => ({
                msg: "could not open cache for offlineItem",
                apiMethod: context.request.name,
                offlineItem: this._offlineItem,
            }));
            throw new Error("Could not open cache for storing offlineItem");
        }
    };
}
