// @flow
import React from "react";
import { openItemActionPanel } from "components/ItemActionPanel";
import Thumbnail from "components/DocListSimple/Thumbnail";
import store from "data/store";

export const openSearchResultActionPanel = ({
    row,
    ...contextProps
}: {
    row: Object,
}) =>
    openItemActionPanel({
        title: row.title,
        ext: row.ext,
        icon: row.icon,
        color: row.color,
        contextProps: {
            ...contextProps,
            itemUri: row.itemUri,
            name: row.title,
            ext: row.ext,
            isDoc: row.isDoc,
            formatId: row.breadcrumb.formatId,
        },
        breadcrumb: row.breadcrumb,
        thumbnail: row.isDoc && (
            <Thumbnail
                store={store}
                itemUri={row.itemUri}
                style={{
                    maxWidth: "100%",
                    margin: "0 auto",
                    display: "block",
                    borderRadius: 0,
                }}
            />
        ),
        attributes: row.attributes,
        targetView: row.targetView,
    });
