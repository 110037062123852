import { unlock as unlockApi } from "data/api";
import { toastActionResult } from "data/toast";

class unlock {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;
    execute = async ({ itemUri }: { itemUri: string }): Promise<boolean> => {
        try {
            return await unlockApi(itemUri);
        } catch (err) {
            return false;
        }
    };
    report = async (success: boolean): Promise<boolean> => {
        this.logger(success === true, "commandAction:unlock");
    };
}

export default unlock;
