import { useMemo } from "react";
import { usePostMessageHandler } from "hooks/PostMessage/Handler";
import { postMessageTo } from "hooks/PostMessage/Utils";
import { ArrowNavigationAction } from "hooks/PostMessage/Actions";
import { docRoute } from "components/ItemUriLink";
import queryString from "data/queryString";
import { get } from "data/constants";

const useArrowNavigationTransmitter = (
    source,
    target,
    targetData,
    setCurrentIndex,
    history
) =>
    usePostMessageHandler(
        useMemo(
            () => ({
                [ArrowNavigationAction.Ready]: (data) =>
                    postMessageTo(
                        ArrowNavigationAction.Load,
                        targetData,
                        target
                    ),
                [ArrowNavigationAction.Paginate]: (data) => {
                    if (source !== data.source) {
                        return;
                    }
                    const search = queryString.parse(window.location.search);
                    search[data.qsParam] = data.currentIndex;
                    setCurrentIndex(data.currentIndex);
                    const a = document.createElement("a"); // Use a tag for Location conversion
                    a.href = window.location;
                    a.pathname = a.pathname.replace(
                        get(window.CONFIG, ["host", "basename"], ""),
                        ""
                    );
                    a.search = queryString.stringify(search);
                    history.push(a.pathname + a.search);
                },
            }),
            [source, target, targetData, setCurrentIndex, history]
        )
    );

const useArrowNavigationReceiver = (
    postMessageProps,
    setPostMessageProps,
    history,
    setMaxCount
) =>
    usePostMessageHandler(
        useMemo(
            () => ({
                [ArrowNavigationAction.Load]: (data) => {
                    /* istanbul ignore if */
                    if (postMessageProps.source) {
                        return;
                    }
                    setPostMessageProps({
                        ...data,
                        rows: data.rows ?? [],
                        currentIndex: data.currentIndex ?? 0,
                    });
                    setMaxCount(data.rows?.length ?? 0);
                },
                [ArrowNavigationAction.Reload]: (data) => {
                    /* istanbul ignore if */
                    if (data.source !== postMessageProps.source) {
                        return;
                    }
                    setPostMessageProps({
                        ...postMessageProps,
                        rows: data.rows ?? [],
                        currentIndex: data.currentIndex ?? 0,
                    });
                    setMaxCount(data.rows?.length ?? 0);
                },
                [ArrowNavigationAction.SetIndex]: (data) => {
                    if (data.source !== postMessageProps.source) {
                        return;
                    }
                    const url = docRoute(
                        postMessageProps.rows[data.currentIndex],
                        true,
                        false
                    );
                    history.push(url);
                    setPostMessageProps({
                        ...postMessageProps,
                        currentIndex: data.currentIndex,
                    });
                },
            }),
            [postMessageProps, setPostMessageProps, history, setMaxCount]
        )
    );

export { useArrowNavigationTransmitter, useArrowNavigationReceiver };
