// @flow
import React, {
    useRef,
    useEffect,
    useCallback,
    type ComponentType,
} from "react";
import { useEffectOnce, usePrevious } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import Split from "split.js";
import panesDefaultState from "data/panes";
import { getDisplayName } from "data/constants";
import { setSplitPanesAction, persistSplitPanesAction } from "data/actions";
import { panesViewSelector } from "data/reducers/selectors";

type Props = {
    viewName?: string,
};

type Config = {
    viewName?: string,
    paneIds: Array<string>,
    toggleIds: Array<string>,
    direction?: string,
};

const SplitPanes =
    ({ viewName, paneIds, toggleIds, direction = "vertical" }: Config) =>
    (WrappedComponent: ComponentType<any>) => {
        const HOC = ({
            viewName: viewNameFromProps,
            selectedToggles,
            ...props
        }: Props) => {
            const split1 = useRef();
            const split2 = useRef();
            /* istanbul ignore next */
            const usedViewName = viewName || viewNameFromProps;
            const panes = useSelector((state) =>
                panesViewSelector(state, usedViewName)
            );
            const prevPanes = usePrevious(panes);
            const prevSelectedToggles = usePrevious(selectedToggles);
            const dispatch = useDispatch();
            const handleDispatch = useCallback(
                ({
                    persist,
                    ...action
                }: {
                    persist: boolean,
                    view: string,
                    name: number,
                    dimensions: Array<number>,
                }) =>
                    dispatch(
                        persist
                            ? persistSplitPanesAction(action)
                            : setSplitPanesAction(action)
                    ),
                [dispatch]
            );

            const setSplitSizes = useCallback(
                (paneIdx: number, dimensions: Array<number>) => {
                    /* istanbul ignore else */
                    if (paneIdx === 0) {
                        split1.current?.setSizes(dimensions);
                    } else if (paneIdx === 1) {
                        split2.current?.setSizes(dimensions);
                    } else {
                        alert("Unknown paneIdx=" + paneIdx);
                    }
                },
                []
            );
            useEffectOnce(() => {
                let paneIdx = 0;
                split1.current = Split(
                    [paneIds[paneIdx++], paneIds[paneIdx++]],
                    {
                        sizes: panes[0],
                        direction:
                            paneIds.length === 2
                                ? direction /*"vertical"*/
                                : "horizontal",
                        minSize: 0,
                        gutterSize: 10,
                        onDragEnd: () => {
                            // adjust toggles based on panes' dimensions
                            const dimensions = split1.current.getSizes();

                            handleDispatch({
                                persist: true,
                                view: usedViewName,
                                name: 0,
                                dimensions,
                            });
                        },

                        elementStyle: /* istanbul ignore next */ (
                            dimension,
                            size,
                            gutterSize
                        ) => {
                            return {
                                "flex-basis":
                                    "calc(" +
                                    size +
                                    "% - " +
                                    gutterSize +
                                    "px)",
                            };
                        },
                        gutterStyle: /* istanbul ignore next */ (
                            dimension,
                            gutterSize
                        ) => {
                            return { "flex-basis": gutterSize + "px" };
                        },
                    }
                );

                if (paneIdx < paneIds.length) {
                    split2.current = Split(
                        [paneIds[paneIdx++], paneIds[paneIdx++]],
                        {
                            sizes: panes[1],
                            minSize: 0,
                            gutterSize: 10,
                            direction: "vertical",
                            onDragEnd: () => {
                                handleDispatch({
                                    persist: true,
                                    view: usedViewName,
                                    name: 1,
                                    dimensions: split2.current.getSizes(),
                                });
                            },
                            elementStyle: /* istanbul ignore next */ (
                                dimension,
                                size,
                                gutterSize
                            ) => {
                                return {
                                    "flex-basis":
                                        "calc(" +
                                        size +
                                        "% - " +
                                        gutterSize +
                                        "px)",
                                };
                            },
                            gutterStyle: /* istanbul ignore next */ (
                                dimension,
                                gutterSize
                            ) => {
                                return { "flex-basis": gutterSize + "px" };
                            },
                        }
                    );
                }
            });
            useEffect(() => {
                if (isEqual(prevPanes, panes)) return;
                //console.log("[SplitPanes]", "panes", ...panes);
                if (paneIds.length === 2) {
                    split1.current.setSizes(panes[0]);
                } else {
                    split1.current.setSizes(panes[0]);
                    split2.current.setSizes(panes[1]);
                }
            }, [panes, prevPanes]);
            useEffect(() => {
                if (selectedToggles == null) return;
                /* istanbul ignore if */
                if (isEqual(prevSelectedToggles, selectedToggles)) return;
                if (paneIds.length === 2) {
                    let master = panes[0].some((p) => !p)
                        ? panesDefaultState[usedViewName][0].slice(0)
                        : panes[0].slice(0);
                    if (!selectedToggles.includes(toggleIds[0])) {
                        master = [0, 100];
                    } else if (!selectedToggles.includes(toggleIds[1])) {
                        master = [100, 0];
                    }
                    if (!isEqual(master, panes[0])) {
                        // console.log("[SplitPanes]", "master", master);
                        split1.current.setSizes(master);
                        handleDispatch({
                            persist: prevSelectedToggles != null,
                            view: usedViewName,
                            name: 0,
                            dimensions: master,
                        });
                    }
                } else {
                    let master = panes[0].some((p) => !p)
                        ? panesDefaultState[usedViewName][0].slice(0)
                        : panes[0].slice(0);

                    if (!selectedToggles.includes(toggleIds[0])) {
                        master = [0, 100];
                    }

                    let slave = panes[1].some((p) => !p)
                        ? panesDefaultState[usedViewName][1].slice(0)
                        : panes[1].slice(0);
                    if (!selectedToggles.includes(toggleIds[1])) slave[0] = 0;
                    if (!selectedToggles.includes(toggleIds[2])) slave[1] = 0;
                    if (
                        !selectedToggles.includes(toggleIds[1]) &&
                        !selectedToggles.includes(toggleIds[2]) &&
                        selectedToggles.includes(toggleIds[0])
                    ) {
                        master = [100, 0];
                    }
                    if (
                        selectedToggles.includes(toggleIds[1]) &&
                        !selectedToggles.includes(toggleIds[2])
                    ) {
                        slave[0] = 100;
                    } else if (
                        !selectedToggles.includes(toggleIds[1]) &&
                        selectedToggles.includes(toggleIds[2])
                    ) {
                        slave[1] = 100;
                    }
                    if (!isEqual(master, panes[0])) {
                        // console.log("[SplitPanes]", "master", master);
                        split1.current.setSizes(master);
                        handleDispatch({
                            persist: prevSelectedToggles != null,
                            view: usedViewName,
                            name: 0,
                            dimensions: master,
                        });
                    }
                    if (!isEqual(slave, panes[1])) {
                        // console.log("[SplitPanes]", "slave", slave);
                        split2.current.setSizes(slave);
                        handleDispatch({
                            persist: prevSelectedToggles != null, //Do not persist initial set of toggles
                            view: usedViewName,
                            name: 1,
                            dimensions: slave,
                        });
                    }
                }
            }, [
                handleDispatch,
                selectedToggles,
                prevSelectedToggles,
                usedViewName,
                panes,
            ]);
            return (
                <WrappedComponent
                    {...props}
                    viewName={usedViewName}
                    setSplitSizes={setSplitSizes}
                />
            );
        };
        HOC.displayName = `SplitPanes(${getDisplayName(WrappedComponent)})`;
        return HOC;
    };

export default SplitPanes;
