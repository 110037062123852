import history from "data/history";
import { searchRouteWithItem } from "components/ItemUriLink";
import { type SearchRequestDTO } from "data/types";

/**
 * opens preview for the given search request
 *
 * @param {{ searchRequest: SearchRequestDTO }} { searchRequest }
 * @param {{ newWindow: bool}} { newWindow }
 */

class search_preview {
    execute = ({
        searchRequest,
        newWindow,
    }: {
        searchRequest: SearchRequestDTO,
        newWindow: boolean,
    }) => {
        const searchResultUrl = searchRouteWithItem(searchRequest);
        newWindow
            ? window.open(
                  searchResultUrl,
                  "",
                  `status=no,location=no,toolbar=no,menubar=no,width=600px,height=640px,top=${
                      Math.random() * 100
                  },left=${Math.random() * 100}`
              )
            : history.push(searchResultUrl);
        return true;
    };
}
export default search_preview;
