// @flow
import React, { PureComponent } from "react";
import Modal from "components/Modal";
import Button from "react-bootstrap/lib/Button";
import {
    type MapLocationInfo,
    type Location,
    type MapEditLocationResult,
} from "data/types";
import loc from "i18next";
import { createModal } from "react-modal-promise";
import { SupportGeoPlaceInput } from "components/MapViewer/providerSupport";
import MapViewer from "components/MapViewer";
import PlaceInput from "./PlaceInput";
import Icons from "@hs/icons";
import styles from "./MapModal.module.css";

type Props = {
    /** current itemUri */
    itemUri: string,
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog */
    close: () => void,
    /** name of provider to use
     * @default null retrieve default mapProvider from config.general.geo.name
     */
    mapProvider: ?string,
    /** callback when the user clicks the Set button
     * @param {MapEditLocationResult} editLocationResult latitude and longitude (or Radius, or Area) of user editing
     */
    onSet: ?(editLocationResult: MapEditLocationResult) => void,
    /** label to show on Button
     * @default common:map.setPosition
     */
    setButtonLabel?: string,
    /** list of locations (markers / areas) to show on the map */
    locations: ?Array<MapLocationInfo>,
    /** whether to render a "center" draggable pin marker
     * @default false
     */
    useMarker?: boolean,
    /** the current radius circle (in meters) - used by GeoRadiusInput */
    radius?: number,
    /** whether to show place input box (geocoding) */
    usePlaceInput?: boolean,
    /** optional modal title
     * @default {string} common:map.title
     */
    title?: string,
};

type State = {
    viewerLoaded: boolean,
};

class MapModal extends PureComponent<Props, State> {
    mapRef: { current: null | MapViewer };

    static defaultProps = {
        usePlaceInput: true,
    };

    constructor(props: Props) {
        super(props);
        this.mapRef = React.createRef();
        this.state = {
            viewerLoaded: false,
        };
    }

    _onSet = () => {
        /* istanbul ignore else */
        if (this.mapRef != null && this.mapRef.current != null) {
            const editLocation = this.mapRef.current.GetEditLocationResult();
            /* istanbul ignore else */
            if (typeof this.props.onSet === "function")
                this.props.onSet(editLocation);
            this.props.close();
        }
    };

    _onChangeFromPlaceInput = (location: Location) =>
        this.mapRef.current.SetCenterMarker(location);

    _showGeoInput = (): boolean =>
        this.props.usePlaceInput === true &&
        SupportGeoPlaceInput() &&
        this.props.useMarker === true &&
        this.props.locations != null &&
        this.props.locations.length > 0 &&
        this.props.locations[0].allowEdit !== false;

    _onViewerLoad = (success: boolean) =>
        this.setState({ viewerLoaded: success });

    render = () => (
        <Modal
            fullscreen
            show={this.props.open !== false}
            onHide={this.props.close}
            footerSize={
                typeof this.props.onSet === "function"
                    ? 55
                    : /* istanbul ignore next */ 0
            }
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Icons.Library name="earth-europe" />{" "}
                    {this.props.title || loc.t("common:map.title")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MapViewer
                    ref={this.mapRef}
                    itemUri={this.props.itemUri}
                    mapProvider={this.props.mapProvider}
                    locations={this.props.locations}
                    useMarker={this.props.useMarker}
                    radius={this.props.radius}
                    onLoad={this._onViewerLoad}
                />
            </Modal.Body>
            {this.state.viewerLoaded &&
                typeof this.props.onSet === "function" && (
                    <Modal.Footer className={styles.footer}>
                        {this._showGeoInput() && (
                            <PlaceInput
                                onChange={this._onChangeFromPlaceInput}
                            />
                        )}
                        <Button
                            aria-label={
                                this.props.setButtonLabel ||
                                loc.t("common:map.setPosition")
                            }
                            type="submit"
                            disabled={false}
                            onClick={this._onSet}
                            bsStyle="primary"
                            className={styles.btnSet}
                        >
                            {this.props.setButtonLabel ||
                                loc.t("common:map.setPosition")}
                        </Button>
                    </Modal.Footer>
                )}
        </Modal>
    );
}
MapModal.displayName = "MapModal";
export default MapModal;
export const openMapModal = createModal(MapModal);
