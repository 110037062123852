// @flow
import { openProgressModal } from "components/ProgressModal";
import { downloadDocUri } from "data/api";
import { getLocalizedText } from "data/utils";
import { toastActionResult, toastStyled, toastTypes } from "data/toast";
import { NotificationStyle } from "data/types";

type Context = {
    items?: Array<any>,
};

class doc_downloadFilesAPI {
    logger: () => any = toastActionResult;
    getFileName = (disposition: string): string => {
        const utf8FilenameRegex = /filename\*=UTF-8''([\w%-.~]+)(?:; ?|$)/i;
        const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

        let fileName: string = null;
        if (utf8FilenameRegex.test(disposition)) {
            fileName = decodeURIComponent(
                utf8FilenameRegex.exec(disposition)[1]
            );
        } else {
            const filenameStart = disposition
                .toLowerCase()
                .indexOf("filename=");
            if (filenameStart >= 0) {
                const partialDisposition = disposition.slice(filenameStart);
                const matches = asciiFilenameRegex.exec(partialDisposition);
                if (matches != null && matches[2]) {
                    fileName = matches[2];
                }
            }
        }
        // replace ~ on first char
        if (["~", "-"].includes(fileName?.[0] ?? "")) {
            fileName = fileName.replace("~", "_");
        }
        return fileName;
    };

    errors = 0;

    execute = async ({ items }: Context): Promise<boolean> => {
        const documents = items?.filter((item) => item.isDoc) || [];
        if (!documents.length) {
            this.logger(false, "itemActions:doc_downloadFilesAPI");
            return false;
        }

        const directoryHandle = await window.showDirectoryPicker();
        if (
            !directoryHandle ||
            (await directoryHandle.requestPermission({ writable: true })) !==
                "granted"
        ) {
            this.logger(false, "itemActions:doc_downloadFilesAPI");
            return false;
        }

        const name = getLocalizedText(`$multiselection:actionpanel.document`, {
            count: documents.length,
        });

        await openProgressModal({
            icon: "fa-regular fa-folder-open",
            title: getLocalizedText(
                "$itemActions:doc_downloadFilesAPI.downloading",
                {
                    documents: name,
                }
            ),
            initialProgress: 0,
            doWork: async (close, setError, setProgress) => {
                const progressPart = 100 / documents.length;
                for (let doc = 0; doc < documents.length; doc++) {
                    const document = documents[doc];
                    try {
                        const uri = await downloadDocUri(document.itemUri);
                        if (uri == null)
                            throw new Error(
                                "Cannot create download URI for document!"
                            );
                        const response = await fetch(uri);
                        const buffer = await response.arrayBuffer();
                        const fileHandle = await directoryHandle.getFileHandle(
                            this.getFileName(
                                response.headers.get("content-disposition")
                            ),
                            { create: true }
                        );
                        const writable = await fileHandle.createWritable();
                        await writable.write(buffer);
                        await writable.close();
                        setProgress(progressPart * (doc + 1));
                    } catch (e) {
                        this.errors++;
                        console.warn(
                            `Cannot save ${document.name} to directory`,
                            e
                        );
                    }
                }
                //Close delayed to always show 100% progress
                setTimeout(close, 800);
            },
        });
        return true;
    };
    report = (success, { items, name }: Context) => {
        const documents = items?.filter((item) => item.isDoc) || [];
        if (this.errors === 0 || this.errors === documents.length) {
            this.logger(
                this.errors === 0 && documents.length > 0,
                "itemActions:doc_downloadFilesAPI",
                { count: documents.length }
            );
            return;
        }

        toastStyled({
            ...toastTypes.failure,
            icon: "fa-regular fa-exclamation-triangle",
            style: NotificationStyle.Warning,
            message: getLocalizedText(
                `$itemActions:doc_downloadFilesAPI.partial`,
                {
                    count: documents.length,
                    ok: documents.length - this.errors,
                    error: this.errors,
                    name,
                }
            ),
        });
    };
}

export default doc_downloadFilesAPI;
