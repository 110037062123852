// @flow
import React, { PureComponent } from "react";
import { connect, Dispatch } from "react-redux";
import { compose } from "redux";
import { fetchForm, fetchNewForm, saveForm, validateForm } from "data/api";
import { togglesUpdateCounterAction } from "data/actions";
import {
    ServerEventActionType,
    ServerEventItemType,
    type ServerEventMessage,
    type NewFormTypeEnum,
    NewFormType,
    type FormResultDTO,
    type DocFormResultDTO,
    FormBarView,
    type ApiError,
} from "data/types";
import { getLocalizedText } from "data/utils";
import Form from "components/Form";
import FormEdit from "components/FormEdit";
import * as s from "data/reducers/selectors";
import Constants, {
    type ConstantsEnum,
    isFeatureOn,
    Feature,
} from "data/constants";
import Status from "components/Status";
import loc from "i18next";
import { toastActionResult } from "data/toast";
import isEmpty from "lodash/isEmpty";
import GlobalEventsHandler from "containers/GlobalEventsHandler";
import { openConflictModal } from "components/ConflictModal";
import { produce } from "immer";
import withOfflineHandler from "containers/OfflineHandler";
import withRememberValues from "containers/RememberValues";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import SettingValue, { type SettingValueProps } from "containers/SettingValue";
import FormBarFactory from "components/FormBar/Factory";
import styles from "./Form.module.css";

type Props = {
    form?: FormResultDTO | DocFormResultDTO,
    /** the current itemUri */
    itemUri: string,
    /** the current viewName */
    viewName: string,
    /** whether we are now editing */
    isEditMode: boolean,
    /** callback when cancelling edit mode */
    onCancel: () => void,
    /** callback when saving a new item
     * @async
     * @param {object} model the form's values
     * @param {boolean?} keepOriginalFilename whether to use the actual filename or the user input
     * @returns {boolean} true if success, false otherwise
     */
    onSaveNew: (
        model: Object,
        keepOriginalFilename?: boolean,
        formatId: number
    ) => Promise<boolean>,
    /** callback when the form definition has been loaded from server
     * @param {FormResultDTO | DocFormResultDTO} form the form definition
     */
    onFormLoad: (form: FormResultDTO | DocFormResultDTO) => void,
    /** which new item to create (Folder or Document) */
    newFormType: NewFormTypeEnum,
    /** pass-through save button label */
    saveLabel?: string,
    /** pass-through saving label */
    savingLabel?: string,
    /** pass-through to FormBarFactory */
    defaultFormTitle?: string,
    /** pass-through to FormBarFactory */
    defaultFormIcon?: string,
    /** whether to enable save form button */
    isFormButtonEnabled?: boolean,
    /** whether an internet connection is detected */
    isOnline?: boolen,
    /** whether offline feature is enabled */
    isOfflineAllowed?: boolean,
    /** whether to return ALL form fields or only changeLog */
    onSaveReturnAllFields?: boolean,
    /** defaultValues to preset on the form, accepts any {field:value} or {attribute:value} (no prefixes) */
    defaultValues?: Object,
    /** Redux dispatch */
    dispatch: Dispatch,
    /** GlobalEventsHandler.refreshId */
    refreshId: number,
    /** GlobalEventsHandler.refreshEvent */
    refreshEvent: ?ServerEventMessage,
    /** whether to show FormBarFactory */
    showHeader?: boolean,
    /** optional DOM ref to render ButtonToolbar onto (otherwise will render as child of form) */
    buttonToolbarPortal?: React.DOMElement,
    /** whether no data is intentional or an error */
    isNoDataError?: boolean,
    /** persisted bool if original filename should be kept */
    keepFilename: SettingValueProps,
};

type State = {
    form?: FormResultDTO | DocFormResultDTO,
    status: ConstantsEnum,
    defaultValues: ?Object,
};

export class FormContainer extends PureComponent<Props, State> {
    abortController: AbortController | null;
    _isMounted: boolean;
    _formsyRef: Formsy | null;

    static defaultProps = {
        isEditMode: false,
        isFormButtonEnabled: true,
        isOnline: true,
        isOfflineAllowed: false,
        showHeader: true,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            form: undefined,
            status: Constants.LOADING,
            defaultValues: props.defaultValues,
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
        this._fetch(this.props.itemUri);
    };

    componentWillUnmount() {
        this._isMounted = false;
        /* istanbul ignore else */
        if (this.abortController != null) {
            this.abortController.abort();
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        /* istanbul ignore else */
        if (
            prevProps.itemUri !== this.props.itemUri ||
            prevProps.refreshId !== this.props.refreshId ||
            prevProps.form !== this.props.form
        ) {
            this.setState({
                status: Constants.LOADING,
                defaultValues: this.props.defaultValues,
            });
            this._fetch(this.props.itemUri);
            return;
        }

        if (prevProps.keepFilename.value !== this.props.keepFilename.value) {
            this.setState((state) => ({
                form: this._handleKeepFilename(state.form),
            }));
        }
    }

    /**
     * @async
     * @summary will fetch the appropriate form (new/edit) from the server
     * @param {string} paramUri - the itemUri of the item
     * @memberof FormContainer
     */
    _fetch = async (paramUri: string) => {
        this._updateToggleLoading(true);
        try {
            let form = this.props.form;
            if (form == null) {
                if (this.props.newFormType != null) {
                    form = await this.props.offlineHandler.factory(
                        fetchNewForm
                    )(this.props.newFormType, paramUri);
                } else {
                    form = await this.props.offlineHandler.factory(fetchForm)(
                        paramUri
                    );
                }
                if (typeof this.props.onFormLoad === "function")
                    this.props.onFormLoad(form);
            }
            /* istanbul ignore else */
            if (
                form != null &&
                this.props.itemUri === paramUri &&
                this._isMounted
            ) {
                form = this._handleKeepFilename(form);
                this.setState({
                    status: Constants.OK,
                    form,
                });
            }
        } catch (e) {
            console.error(e);
            try {
                this.setState({ status: Constants.ERROR });
            } catch (e2) {}
        } finally {
            this._updateToggleLoading(false);
        }
    };

    _updateToggleLoading = (loading?: boolean) => {
        const { viewName, dispatch } = this.props;
        dispatch(
            togglesUpdateCounterAction({
                name: viewName,
                counts: { form: 0 },
                status: {
                    form: loading === true ? Constants.LOADING : null,
                },
            })
        );
    };

    _handleKeepFilename = (form: FormResultDTO) => {
        if (this.props.newFormType !== NewFormType.Document) {
            return form;
        }
        return produce(form, (draft) => {
            const idx = draft.fields.findIndex(
                (f) => f.format === "WebDavName" && f.name === "$NAME$"
            );
            /* istanbul ignore else */
            if (idx > -1) {
                draft.fields[idx].ro = this.props.keepFilename.value;
            }
        });
    };

    _isWebDavNameHidden = (): boolean =>
        this.state.form.fields.some(
            (f) =>
                f.format === "WebDavName" &&
                f.name === "$NAME$" &&
                f.extra &&
                f.extra.hidden
        );

    _ignoreWebDavName = (): boolean => {
        const {
            newFormType,
            keepFilename: { value: keepFilename },
        } = this.props;

        const hiddenWebDavName = this._isWebDavNameHidden();
        return (
            (newFormType === NewFormType.Document && keepFilename) ||
            (newFormType != null && hiddenWebDavName)
        );
    };

    _maybeAddDefaultWebDavName = (defaultValues: ?Object): Object => {
        const { newFormType } = this.props;
        if (!(this._isWebDavNameHidden() && newFormType != null)) {
            return defaultValues;
        }
        return produce(defaultValues, (draft: ?Object) => {
            /* istanbul ignore else */
            if (draft == null) {
                draft = {};
            }
            /* istanbul ignore else */
            if (!draft["$NAME$"]) {
                draft["$NAME$"] = loc.t("common:new_folder");
            }
            return draft;
        });
    };

    /**
     * Will validate the model against the server
     * @async
     * @param {Object} model contains current changed fields & attributes
     * @returns {Object} containing all localized validation errors per field e.g. {fieldName:error, fieldName:error...}
     * @memberof FormContainer
     */
    _onValidate = async (model: Object): Object => {
        this.abortController = new AbortController();
        const validationErrors = await validateForm(
            this.props.newFormType != null, //isNew
            this.props.form?.isDoc ?? this.state.form?.isDoc,
            this.props.itemUri,
            !this._ignoreWebDavName(),
            model,
            this.abortController.signal
        );
        // localize error messages
        const locErrors = {};
        if (!isEmpty(validationErrors)) {
            Object.keys(validationErrors).forEach(
                (k) => (locErrors[k] = getLocalizedText(validationErrors[k]))
            );
        }
        return locErrors;
    };

    /**
     * Will submit the form to the server
     * @returns {boolean} true if success, false otherwise
     * @async
     * @param {Object} model - contains current changed fields & attributes
     * @memberof FormContainer
     */
    _onSave = async (
        model: Object,
        cancelEditMode: boolean = true
    ): boolean => {
        try {
            let response: boolean | null | ApiError = null;
            if (this.props.newFormType != null) {
                this.props.rememberValues?.updateAvailableValues(model);
                return await this.props.onSaveNew(
                    model,
                    this._ignoreWebDavName(),
                    this.state.form.formatId
                );
            } else {
                response = await saveForm(
                    this.props.itemUri,
                    this.state.form.formatId,
                    model,
                    this.state.form.etag
                );
            }
            /* istanbul ignore else */
            if (response != null) {
                if (response === false || response.isError) {
                    toastActionResult(
                        false,
                        `${this.props.viewName}:edit_form`
                    );
                } else {
                    toastActionResult(true, `${this.props.viewName}:edit_form`);
                    cancelEditMode && this.props.onCancel();
                    return true;
                }
            }
        } catch (e) {
            if (e.data && e.constructor.name === "ApiConflictError") {
                const doOverwrite = await openConflictModal(e.data.extra.name);
                if (doOverwrite) {
                    this.setState({
                        form: produce(
                            this.state.form,
                            (draft: FormResultDTO) => {
                                draft.etag = e.data.extra.currentEtag;
                            }
                        ),
                    });
                    return await this._onSave(model);
                } else {
                    cancelEditMode && this.props.onCancel();
                }
            } else {
                //this.setState({ status: Constants.ERROR });
                toastActionResult(false, `${this.props.viewName}:edit_form`);
            }
        }
        return false;
    };

    /* istanbul ignore next */
    handleKeepFilename = (e: SyntheticEvent<HTMLInputElement>) =>
        this.props.keepFilename.setValue(e.currentTarget.checked);

    renderNewDocument = () => {
        if (this.props.newFormType !== NewFormType.Document) {
            return null;
        }
        return (
            <>
                <FormGroup
                    controlId="keepFilename"
                    className={styles.newDoc_group}
                >
                    <FormControl
                        type="checkbox"
                        name="keepFilename"
                        className={styles.newDoc_checkbox}
                        checked={this.props.keepFilename.value}
                        onChange={this.handleKeepFilename}
                    />{" "}
                    <ControlLabel className={styles.newDoc_label}>
                        {loc.t("upload:keepFilename")}
                    </ControlLabel>
                </FormGroup>
                {this.props.rememberValues?.renderControl([
                    styles.newDoc_group,
                    styles.newDoc_checkbox,
                    styles.newDoc_label,
                ])}
            </>
        );
    };

    getFormBarViewNames = () => {
        const viewNames = [this.props.viewName];
        if (this.state.form.isLocked) {
            viewNames.push(FormBarView.LockInfo);
        }
        return viewNames;
    };

    render() {
        const {
            itemUri,
            isEditMode,
            onCancel,
            onBack,
            newFormType,
            isFormButtonEnabled,
            saveLabel,
            savingLabel,
            onSaveReturnAllFields,
            isOnline,
            isOfflineAllowed,
            isNoDataError,
        } = this.props;
        const { status, form, defaultValues } = this.state;
        let adjustedItemUri = itemUri;
        //TODO: Move to Server
        if (newFormType === NewFormType.Document) {
            adjustedItemUri +=
                "@eid=00000000|000|000|0000000000000000000000000";
        }
        if (status === Constants.OK) {
            return (
                <>
                    {this.props.showHeader && (
                        <FormBarFactory
                            className={styles.header}
                            views={this.getFormBarViewNames()}
                            isEdit={isEditMode}
                            onCancel={onCancel}
                            onBack={onBack}
                            title={
                                form.formTitle ||
                                form.name ||
                                this.props.defaultFormTitle
                            }
                            icon={this.props.defaultFormIcon}
                            children={this.renderNewDocument()}
                            lockInfo={form.lockInfo}
                        />
                    )}
                    {window.CONFIG.licenseAllowEdit && isEditMode ? (
                        <FormEdit
                            key={`${itemUri}-formedit`}
                            form={form}
                            itemUri={adjustedItemUri}
                            onValidate={this._onValidate}
                            onCancel={onBack || onCancel}
                            onSave={this._onSave}
                            saveLabel={saveLabel}
                            savingLabel={savingLabel}
                            confirmLabel={
                                newFormType ===
                                NewFormType.Document /* istanbul ignore next */
                                    ? null
                                    : loc.t("common:confirm.leaveEdit")
                            }
                            isFormButtonEnabled={
                                isFormButtonEnabled &&
                                (isOnline || isOfflineAllowed)
                            }
                            onSaveReturnAllFields={onSaveReturnAllFields}
                            defaultValues={this._maybeAddDefaultWebDavName(
                                this.props.rememberValues?.maybeAddRememberedValues(
                                    defaultValues
                                ) ?? defaultValues
                            )}
                            buttonToolbarPortal={this.props.buttonToolbarPortal}
                        />
                    ) : (
                        <Form
                            key={`form-${itemUri}`}
                            form={form}
                            itemUri={itemUri}
                            isNoDataError={isNoDataError}
                        />
                    )}
                </>
            );
        } else
            return (
                <Status
                    status={status}
                    error={this.props.offlineHandler.error}
                    center
                />
            );
    }
}

const mapStateToProps = /* istanbul ignore next */ (state, ownProps) => ({
    isOnline: s.isOnlineSelector(state),
    isOfflineAllowed: isFeatureOn(Feature.offline),
    ...ownProps,
});

export default compose(
    connect(mapStateToProps),
    withOfflineHandler,
    SettingValue({
        name: "keepFilename",
        namespace: "keepFilename",
        defaultValue: true,
    }),
    withRememberValues,
    GlobalEventsHandler({
        actions: [
            ServerEventActionType.modify,
            ServerEventActionType.edit,
            ServerEventActionType.refresh,
        ],
        items: [ServerEventItemType.document, ServerEventItemType.folder],
    })
)(FormContainer);
