// @flow

import { openListWorkflowsModal } from "components/ListWorkflowsModal";

/**
 * Start Workflow with selected itemUri
 *
 * @param {{ itemUri: string }} { itemUri to start Workflow with }
 */

class wf_start {
    execute = async ({ itemUri }: { itemUri: string }) => {
        await openListWorkflowsModal({ itemUri });
        return true;
    };
}

export default wf_start;
