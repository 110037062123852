import store from "data/store";
import { isMiniView, isFeatureOn, type FeatureEnum } from "data/constants";
import { isValidItemUri } from "data/utils";
import bowser from "data/bowser";
import * as s from "data/reducers/selectors";
import {
    ItemStatuses,
    UploadProvider,
    SpecialFoldersItemURIs,
    SiteType,
    type ItemActionConfigBase,
} from "data/types";
import { get } from "data/constants";

/**
 * Apply any selection context (itemUri, formatId and isDoc) to existing callback (check_*) function
 *
 * @param {Function} cb check_* function to apply any selection context to
 */
export const applyAnySelectionContext =
    (cb: Function): Function<boolean> =>
    (args: any): boolean => {
        const { contextProps } = args;
        return check_isMultiSelection(args)
            ? contextProps?.items.every((item) =>
                  cb({
                      ...args,
                      ...item,
                      contextProps: { ...args.contextProps, ...item },
                  })
              )
            : cb(args);
    };

export const check_isFavorite = ({ itemUri }: { itemUri: string }) =>
    s.favoritesFavoritesSelector(store.getState()).includes(itemUri);

export const check_isDoc = ({ contextProps: { isDoc = false } }) =>
    isDoc === true;

export const check_isSpecialFolder = ({ itemUri }) =>
    Object.values(SpecialFoldersItemURIs).includes(itemUri);

export const check_isAdmin = () => s.userIsAdminSelector(store.getState());

export const check_isValidItemUri = ({ itemUri }) => isValidItemUri(itemUri);

// export const check_isSyncEnabled = ({
//     itemUri,
//     formatId,
// }: {
//     itemUri?: string,
//     formatId?: number,
// }) => {
//     const def = getDefaultsConfig(itemUri, formatId);
//     if (def && def.inPointSyncIntegration === false) return false;
//     return true;
// };

export const check_isOnline = () => s.isOnlineSelector(store.getState());

export const check_isWindows = () => bowser.is("windows");

export const check_isMiniView = () => isMiniView(window.location);

export const check_hasSiteType = ({ contextProps, params }) => {
    const siteType = SiteType[params];
    if (siteType == null) {
        console.warn(`Unknown SiteType: ${params}`);
    }
    return (
        siteType ===
        ((contextProps && contextProps.siteType) || SiteType.NotSet)
    );
};

/** returns a property from the contextProps (boolean) */
export const check_hasContext = ({ contextProps, params }) =>
    get(contextProps, params.split("."), false);

export const check_licenseAllowEdit = () => window.CONFIG.licenseAllowEdit;

export const check_isSyncForced = () =>
    window.CONFIG.general.uploadProvider === UploadProvider.Sync;

export const check_isSyncUpload = (props) =>
    check_isWindows() &&
    check_isSyncForced() &&
    check_hasContext({
        ...props,
        params: "inPointSyncIntegration",
    });

export const check_isWorkflowEnabled = () =>
    s.userIsWfEnabledSelector(store.getState());

export const check_hasItemStatus = ({
    contextProps: { itemStatus = ItemStatuses.Nothing },
    params,
}) => itemStatus & ItemStatuses[params];

export const check_hasSearchType = ({
    contextProps: { searchRequest },
    params,
}) => {
    if (!searchRequest) return false;
    return (
        searchRequest.searchType === parseInt(params, 10) &&
        !searchRequest.isCustom
    );
};

// whether browser supports WebShareAPI (used by CommandActions/share_web)
export const check_browser_webShareApi = () =>
    navigator.share !== undefined && navigator.canShare !== undefined;

export const check_hasFeature = ({ name }: { name: FeatureEnum }) =>
    isFeatureOn(name);

export const check_isMultiSelection = ({ contextProps: { multiple } }) =>
    !!multiple;

export const check_hasExtension = ({ contextProps: { ext }, params }) =>
    String(
        ext && ext.startsWith(".") ? ext.substring(1) : ext || ""
    ).toUpperCase() === params.toUpperCase();

export const check_isAnyPdf = ({
    contextProps: { hasPdf = false, isPdf = false, canPdf = false, ext = "" },
}) =>
    hasPdf ||
    isPdf ||
    canPdf ||
    check_hasExtension({ contextProps: { ext }, params: "pdf" });

const checkMapping = {
    isOnline: check_isOnline,
    isAdmin: check_isAdmin,
    isFav: applyAnySelectionContext(check_isFavorite),
    isDoc: applyAnySelectionContext(check_isDoc),
    isFolder: applyAnySelectionContext((props) => !check_isDoc(props)),
    isSpecialFolder: applyAnySelectionContext(check_isSpecialFolder),
    isValidItemUri: applyAnySelectionContext(check_isValidItemUri),
    isSyncEnabled: (props) =>
        check_hasContext({
            ...props,
            params: "inPointSyncIntegration",
        }),
    isSyncForced: check_isSyncForced,
    isSyncUpload: check_isSyncUpload,
    isWindows: check_isWindows,
    isMiniView: check_isMiniView,
    hasSiteType: check_hasSiteType,
    hasContext: check_hasContext,
    hasFeature: (props) => check_hasFeature({ name: props.params }),
    hasBrowser_webShareApi: check_browser_webShareApi,
    isCreateAllowed: (props) =>
        check_hasContext({ ...props, params: "isEditAllowed" }) ||
        check_hasContext({ ...props, params: "isDocAssignmentAllowed" }),
    hasItemStatus: check_hasItemStatus,
    isRecycleBin: (props) =>
        check_hasItemStatus({ ...props, params: "Deleted" }) ||
        check_hasItemStatus({ ...props, params: "ParentDeleted" }),
    hasSearchType: check_hasSearchType,
    licenseAllowEdit: check_licenseAllowEdit,
    isWorkflowEnabled: check_isWorkflowEnabled,
    isAnyOnlineStatus: () => true,
    isAnySelection: () => true,
    isMultiSelection: check_isMultiSelection,
    hasExtension: check_hasExtension,
    hasRowAndColId: ({ contextProps }) =>
        contextProps.columnIndex != null && contextProps.rowIndex != null,
    isAnyPdf: check_isAnyPdf,
};

export const shouldBeVisible = ({
    item,
    itemUri,
    contextProps,
}: {
    item: ItemActionConfigBase,
    itemUri: string,
    contextProps: ?Object,
}): Promise<boolean> =>
    item.shouldBeVisible?.every((expr) => {
        let negate = false;
        const split = expr.split(":");
        let conditionName = split[0];
        if (conditionName.startsWith("!")) {
            negate = true;
            conditionName = conditionName.substr(1);
        }
        const func = checkMapping[conditionName];
        if (func == null) {
            console.warn("Unknown shouldBeVisible expression: " + expr);
            return false;
        }
        const result = func({ itemUri, params: split[1], contextProps });
        return negate ? !result : result;
    }) ?? true;
