// @flow
import React from "react";
import FormsyEditorBase from "./FormsyEditorBase";
import UploadImage from "components/UploadImage";

type Props = {
    itemUri: string,
    name: string,
    header: string,
    value: Object,
    width?: number,
    height?: number,
    isReadonly?: boolean,
    isRequired?: boolean,
    name: string,
};

const ImageEditorControl = (props: Props) => (
    <FormsyEditorBase
        required={props.isRequired ? "isDefaultRequiredValue" : undefined}
        render={(value, changeValue) => (
            <UploadImage
                relativePath={props.itemUri}
                name={props.name}
                value={value}
                width={props.width}
                height={props.height}
                isReadonly={props.isReadonly}
                onDone={
                    /* istanbul ignore next */ (value) =>
                        changeValue({ currentTarget: { value } })
                }
                id={props.name}
            />
        )}
        {...props}
    />
);
ImageEditorControl.displayName = "ImageEditorControl";
export default ImageEditorControl;
