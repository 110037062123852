import { useCallback, useMemo } from "react";
import { usePostMessageHandler } from "hooks";
import doc_print from "components/CommandActions/doc_print";
import { PdfPrintAction } from "hooks/PostMessage/Actions";

/**
 * handles messaging with pdfJs to toggle print dialog
 */
const useHandlePdfPrint = (target, triggerPrint) => {
    const handleTriggerPrint = useCallback(() => {
        if (target == null) {
            return;
        }
        new doc_print().execute({ target });
    }, [target]);
    usePostMessageHandler(
        useMemo(
            () => ({
                [PdfPrintAction]: handleTriggerPrint,
            }),
            [handleTriggerPrint]
        )
    );
    if (triggerPrint) {
        handleTriggerPrint();
    }
};

export default useHandlePdfPrint;
