import React from "react";
import { type SummaryAttributeDTO } from "data/types";
import SimpleAttribute from "./SimpleAttribute";
import SummaryAttribute from "./SummaryAttribute";

export const AttributeFactory = ({
    attribute,
    useDefaultStyle,
}: {
    attribute: SummaryAttributeDTO | Array<any>,
    useDefaultStyle?: boolean,
}) => {
    return Array.isArray(attribute) ? (
        <SimpleAttribute attribute={attribute} />
    ) : (
        <SummaryAttribute
            attribute={attribute}
            useDefaultStyle={useDefaultStyle}
        />
    );
};
AttributeFactory.displayName = "AttributeFactory";

const Attributes = ({
    attributes,
    className,
    style,
    useDefaultStyle = true,
}: {
    attributes: Array<SummaryAttributeDTO | Array<any>>,
    className?: string,
    style?: CSStyleDefinition,
    useDefaultStyle?: boolean,
}) => (
    <div className={className} style={style || { whiteSpace: "pre-line" }}>
        {attributes.map((attribute, index) => (
            <AttributeFactory
                key={index}
                attribute={attribute}
                useDefaultStyle={useDefaultStyle}
            />
        ))}
    </div>
);
Attributes.displayName = "Attributes";
export default Attributes;
