// @flow
import { BasePlugin } from "@uppy/core";
import { getFileExtension } from "data/utils";
import match from "mime-match";
import * as s from "data/reducers/selectors";
import store from "data/store";
import { getDefaultsConfig } from "data/constants";
import { toastStyled, toastTypes } from "data/toast";
import loc from "i18next";

// export type uppyFile = {
//     id: string, // 'uppyteamkongjpg1501851828779'
//     name: string, // 'nature.jpg'
//     extension: string, // '.jpg'
//     type: string, // 'image/jpeg'
//     data: Object, // the Blob object
//     size: number | string, // 3947642 (returns 'N/A' if size cannot be determined)
//     preview: string, // value that can be used to populate "src" attribute of an "img" tag,
//     meta: uppyFileMetadata
// };

type uppyRestrictions = {
    maxNumberOfFiles?: ?number,
    maxFileSize?: ?number,
    deniedFileTypes?: ?Array<string>,
    allowedFileTypes?: ?Array<string>,
};

export /**
 * set current uppy restrictions
 *
 * @param {Object} uppyRef
 * @param {uppyRestrictions} restrictions
 * @returns {uppyRestrictions} restrictions set
 */
const setUppyRestrictions = (
    uppyRef,
    restrictions: uppyRestrictions
): uppyRestrictions => {
    const updatedRestrictions = Object.assign(
        {},
        {
            maxNumberOfFiles: null,
            maxFileSize: null,
            deniedFileTypes: null,
            allowedFileTypes: null,
        },
        restrictions || {}
    );
    uppyRef.setOptions({
        restrictions: updatedRestrictions,
    });
    return updatedRestrictions;
};

// /* istanbul ignore next */
export default class UppyRestrictions extends BasePlugin {
    constructor(uppy: Object, opts: Object) {
        super(uppy, opts);
        this.uppy = uppy;
        this.type = "Restrictions";
        this.id = "Restrictions";
        this.title = "uppy-restrictions";

        // set default options
        const defaultOptions = {};

        // merge default options with the ones set by user
        this.opts = Object.assign({}, defaultOptions, opts);
    }

    install() {
        this.uppy.log("[restrictions] Installing UppyRestrictions Plugin");

        // before actually adding files to uppy
        this.old_onBeforeFileAdded = this.uppy.opts.onBeforeFileAdded;
        this.uppy.opts.onBeforeFileAdded = this.onBeforeFileAdded;

        // before actually starting the upload
        this.old_onBeforeUpload = this.uppy.opts.onBeforeUpload;
        this.uppy.opts.onBeforeUpload = this.onBeforeUpload;

        this.uppy.on("restriction-failed", this.onRestrictionFailed);
    }

    uninstall() {
        this.uppy.log("[restrictions] Removing UppyRestrictions Plugin");

        this.uppy.opts.onBeforeFileAdded = this.old_onBeforeFileAdded;
        this.uppy.opts.onBeforeUpload = this.old_onBeforeUpload;

        this.uppy.off("restriction-failed", this.onRestrictionFailed);
        setUppyRestrictions(this.uppy);
    }

    onRestrictionFailed = (file: Object, error: Object): void => {
        console.warn("[Restrictions] file restriction failed", file, error);
        //this.uppy.info("This file type is not allowed", "error", 500);-> does not always work!
        toastStyled({
            ...toastTypes.uppyError,
            message: loc.t("upload:restricted.message"),
        });
    };

    /** double-check before actually uploading */
    onBeforeUpload = (files: Object) => {
        const restrictions = this._updateRestrictions();
        let restrictedFileIDs = [];
        Object.keys(files).forEach((fileID) => {
            if (
                false ===
                this._checkRestrictions(
                    files[fileID],
                    files,
                    restrictions,
                    true
                )
            ) {
                restrictedFileIDs.push(fileID);
            }
        });
        if (restrictedFileIDs.length > 0) {
            const restrictedFiles = restrictedFileIDs.map(
                (fileID) => files[fileID].name
            );
            toastStyled({
                ...toastTypes.uppyError,
                message:
                    restrictedFileIDs.length === 1
                        ? loc.t("upload:restricted.one", {
                              file: restrictedFiles[0],
                          })
                        : loc.t("upload:restricted.multiple", {
                              files: restrictedFiles.join(", "),
                          }),
            });
            // abort all
            return false;
        }
        // continue
        return this.old_onBeforeUpload(files);
    };

    // https://uppy.io/docs/uppy/#onBeforeFileAdded-currentFile-files-gt-currentFile
    /** check before adding file to uppy */
    onBeforeFileAdded = (file: Object, files: Object) => {
        const restrictions = this._updateRestrictions();

        if (this._checkRestrictions(file, files, restrictions, false) === false)
            return false;
        else return this.old_onBeforeFileAdded(file, files);
    };

    /** update uppy restrictions based on current Defaults itemUri/formatId
     * @returns {Object} restrictions
     */
    _updateRestrictions = (): uppyRestrictions => {
        const state = store.getState();
        const viewName = s.currentNameSelector(state);
        const formatId = s.breadcrumbFormatIdSelector(state, viewName);
        const itemUri = s.breadcrumbItemUriSelector(state, viewName);
        const defaults = getDefaultsConfig(
            itemUri || "pam-item://",
            formatId || -1
        );
        // any Defaults configuration? set it
        if (defaults) {
            return setUppyRestrictions(this.uppy, defaults.upload);
        } else {
            // set empty retrictions
            return setUppyRestrictions(this.uppy);
        }
    };

    /**
     * Check if file passes a set of restrictions set in options: maxFileSize,
     * maxNumberOfFiles and allowedFileTypes.
     * lns -> additional checks for deniedFileTypes
     * @borrows https://github.com/transloadit/uppy/blob/856243a149739fbeeac9f75f1343c0bf71d6cf8f/packages/%40uppy/core/src/index.js#L438
     *
     * @param {object} file object to check
     * @param {object} files Object of IDs → files already added
     * @param {uppyRestrictions} restrictions which upload restrictions to apply
     * @param {boolean} beforeUpload onBeforeUpload calls with true
     * @returns {boolean} true if allowed, false if restricted
     * @private
     */
    _checkRestrictions = (
        file: Object,
        files: Object,
        restrictions: uppyRestrictions,
        beforeUpload: boolean
    ): boolean => {
        const {
            maxFileSize,
            maxNumberOfFiles,
            allowedFileTypes,
            deniedFileTypes,
        } = restrictions;

        if (maxNumberOfFiles) {
            if (
                Object.keys(files).length + (beforeUpload ? 0 : 1) >
                maxNumberOfFiles
            ) {
                this.uppy.log(
                    `[Restrictions] only ${maxNumberOfFiles} maxNumberOfFiles are allowed`
                );
                return false;
                // throw new RestrictionError(
                //     `${this.i18n("youCanOnlyUploadX", {
                //         smart_count: maxNumberOfFiles
                //     })}`
                // );
            }
        }

        const ext = getFileExtension(file.name).toLowerCase();
        if (allowedFileTypes) {
            const isCorrectFileType = allowedFileTypes.some((type) => {
                // is this is a mime-type
                if (type.indexOf("/") > -1) {
                    /* istanbul ignore next */
                    if (!file.type) return false;
                    return match(file.type.replace(/;.*?$/, ""), type);
                }

                // otherwise this is likely an extension
                /* istanbul ignore else */
                if (type[0] === ".") {
                    return ext === type.toLowerCase();
                }
                return false;
            });

            if (!isCorrectFileType) {
                this.uppy.log(
                    `[Restrictions] only ${allowedFileTypes.join(
                        ", "
                    )} are allowed`
                );
                return false;
                // const allowedFileTypesString = allowedFileTypes.join(", ");
                // throw new RestrictionError(
                //     this.i18n("youCanOnlyUploadFileTypes", {
                //         types: allowedFileTypesString
                //     })
                // );
            }
        }

        if (deniedFileTypes) {
            // https://github.com/transloadit/uppy/blob/856243a149739fbeeac9f75f1343c0bf71d6cf8f/packages/%40uppy/core/src/index.js#L438
            const isDeniedFileType = deniedFileTypes.some((type) => {
                // is this is a mime-type
                if (type.indexOf("/") > -1) {
                    /* istanbul ignore next */
                    if (!file.type) return false;
                    return match(file.type.replace(/;.*?$/, ""), type);
                }

                // otherwise this is likely an extension
                /* istanbul ignore else */
                if (type[0] === ".") {
                    return ext === type.toLowerCase();
                }
                return false;
            });

            if (isDeniedFileType) {
                this.uppy.log(
                    `[Restrictions] ${deniedFileTypes.join(
                        ", "
                    )} are not allowed`
                );
                // this.uppy.log(
                //     `[restrictions] User tried to upload '${currentFile.name}' which is listed in the deniedFileTypes (${deniedFileTypes})`
                // );
                return false;
            }
        }

        // We can't check maxFileSize if the size is unknown.
        if (maxFileSize && file.data.size != null) {
            if (file.data.size > maxFileSize) {
                this.uppy.log(
                    `[Restrictions] with ${file.data.size} exceeds maxFileSize set at ${maxFileSize}`
                );
                return false;
                // throw new RestrictionError(
                //     `${this.i18n("exceedsSize")} ${prettyBytes(maxFileSize)}`
                // );
            }
        }
        return true;
    };
}
