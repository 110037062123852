// @flow
import React from "react";
import Button from "react-bootstrap/lib/Button";
import HelpBlock from "react-bootstrap/lib/HelpBlock";
import Icons from "@hs/icons";
import { getLocalizedText } from "data/utils";
import { type ItemActionConfig } from "data/types";
import styles from "./ItemAction.module.css";

const ItemAction = ({
    action,
    onAction,
}: {
    action: ItemActionConfig,
    onAction: (action: ItemActionConfig) => void,
}) => (
    <div>
        <Button
            bsStyle="link"
            onClick={() => onAction(action)}
            className={styles.button}
        >
            <Icons.Library
                name={action.icon || "fa-regular fa-arrow-up-right-from-square"}
                className={styles.icon}
                fixedWidth
            />{" "}
            {getLocalizedText(action.name) || "N/A"}
        </Button>
        {action.desc && (
            <HelpBlock className={styles.desc}>
                {getLocalizedText(action.desc)}
            </HelpBlock>
        )}
    </div>
);
ItemAction.displayName = "ActionPanel.ItemAction";
export default ItemAction;
