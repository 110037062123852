import { exportDocsToCsvAction } from "data/actions";

/**
 * exports current doclist to CSV
 */

class doc_exportCsv {
    execute = ({ dispatch }) => dispatch(exportDocsToCsvAction());
}
export default doc_exportCsv;
