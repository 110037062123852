// @flow
import React from "react";
import { type RetentionInfoDTO, RetentionType } from "data/types";
import Alert from "react-bootstrap/lib/Alert";
import loc from "i18next";
import Icons from "@hs/icons";

type Props = {
    /** info object for Retention */
    info: ?RetentionInfoDTO,
    /** optional custom CSS class to apply */
    className?: string,
};

const RetentionInfo = ({ info, className }: Props) => {
    // no message if default
    if (info == null || info.type === RetentionType.Default) return null;
    return (
        <Alert bsStyle="info" className={className}>
            <Icons.Library name="circle-info" />{" "}
            {info.label == null ? (
                loc.t(`docactivity:retention.${info.type}`, {
                    months: info.months,
                })
            ) : (
                <span
                    dangerouslySetInnerHTML={{
                        __html: loc.t(`common:act.retention.${info.type}`, {
                            date: info.date,
                            label: info.label,
                        }),
                    }}
                />
            )}
        </Alert>
    );
};
RetentionInfo.displayName = "RetentionInfo";
export default RetentionInfo;
