import React, { useRef, useCallback, useEffect, useMemo } from "react";
import { postMessageTo } from "hooks/PostMessage/Utils";
import { usePostMessageHandler } from "hooks";
import { userLanguage } from "data/storeHelper";
import { CustomUploadAction } from "hooks/PostMessage/Actions";

type Props = {
    /** uppy instance reference */
    uppy: Object,
    /** url to custom page providing file selection */
    url: string,
    /** callback to close custom panel */
    onClose: () => void,
};

const CustomUploadSelection = ({ url, uppy, onClose }: Props) => {
    const iframeRef = useRef(null);
    let iframeTimeout = useRef(null);

    const handleFile = useCallback(
        async ({ name, data, url, source = "Local" } = {}) => {
            clearTimeout(iframeTimeout.current);
            let error = false;
            if (name == null || (data == null && url == null)) {
                console.error(`CustomUploadAction.Start prop missing: name `);
                error = true;
            }
            if (data == null && url == null) {
                console.error(
                    `CustomUploadAction.Start prop missing: data or url`
                );
                error = true;
            }
            if (error) {
                return;
            }

            // Optional fetch url as blob, if data is empty...
            if (url && data == null) {
                const response = await fetch(url);
                data = await response.blob();
            }
            uppy.addFile({
                name,
                type: data.type,
                data,
                source,
                isRemote: false,
            });
            /* istanbul ignore else */
            if (typeof onClose === "function") onClose();
        },
        [uppy, onClose]
    );

    const setLanguage = useCallback(() => {
        clearTimeout(iframeTimeout.current);
        postMessageTo(
            CustomUploadAction.Language,
            userLanguage(),
            iframeRef.current.contentWindow
        );
    }, []);

    usePostMessageHandler(
        useMemo(
            () => ({
                [CustomUploadAction.Ready]: setLanguage,
                [CustomUploadAction.File]: handleFile,
            }),
            [setLanguage, handleFile]
        )
    );

    // Notify console if custom upload does not trigger CustomUploadAction.Ready
    useEffect(() => {
        /* istanbul ignore else */
        if (iframeTimeout.current == null) {
            iframeTimeout.current = setTimeout(
                /* istanbul ignore next */ () => {
                    console.warn(
                        `Breaking Change: Loaded CustomUploadSelection at ${url} does not communicate with postMessage. See https://serviceportal.hs.ag/docs/inPoint.Web/docs/next/feature-integration-postmessage.html`
                    );
                },
                1000
            );
        }
        return () => {
            clearTimeout(iframeTimeout.current);
            iframeTimeout.current = null;
        };
    }, [url]);

    return (
        <iframe
            ref={iframeRef}
            title="itemFlow custom"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen={true}
            src={url}
        />
    );
};
CustomUploadSelection.displayName = "ItemFlows.CustomUploadSelection";
export default CustomUploadSelection;
