import { openNameModal } from "components/NameModal";
import { openProgressModal } from "components/ProgressModal";
import {
    validateForm,
    createFolderFromTemplate,
    createFolderTemplateGetItemUri,
} from "data/api";
import loc from "i18next";
import { toastActionResult } from "data/toast";
import isEmpty from "lodash/isEmpty";
import history from "data/history";
import { getRoute } from "components/ItemUriLink";
import { getLocalizedText } from "data/utils";

/**
 * Will validate the model against the server
 * @async
 * @returns {Object} containing all localized validation errors per field e.g. {fieldName:error, fieldName:error...}
 * @memberof FormContainer
 */
export const ValidateTemplate = async (
    template: string,
    itemUri: string,
    newTargetFolderName: string
): Object => {
    // this.abortController = new AbortController();
    const validationErrors = await validateForm(
        true, //isNew
        false, //isDoc
        itemUri,
        true, //validateWebDavName
        { ip: { $NAME$: newTargetFolderName } } // we want to send the {ip:{...}} model
        // this.abortController.signal
    );
    // localize error messages
    const locErrors = {};
    if (!isEmpty(validationErrors)) {
        Object.keys(validationErrors).forEach(
            (k) => (locErrors[k] = getLocalizedText(validationErrors[k]))
        );
        return locErrors["ip.Name"];
    }
    return null;
};

/**
 * interval pulling for job status; will redirect once new itemUri received
 */
export const CheckJobStatus = ({
    jobId,
    close,
    logger = toastActionResult,
}: {
    jobId: number,
    close: () => void,
    logger: () => void,
}) => {
    let times = 1;
    const interval = window.setInterval(async () => {
        /* istanbul ignore if */
        if (times > 12) {
            //one minute? too long, bail user
            window.clearInterval(interval);
            logger(false, "folder:action_createFolder_template");
            close();
        } else {
            try {
                const newTargetItemUri = await createFolderTemplateGetItemUri(
                    jobId
                );
                /* istanbul ignore else */
                if (String(newTargetItemUri).startsWith("pam-item://")) {
                    window.clearInterval(interval);
                    close();
                    history.push(getRoute({ itemUri: newTargetItemUri }));
                    logger(true, "folder:action_createFolder_template");
                } else {
                    times++;
                }
            } catch (e) {
                console.error(e);
                window.clearInterval(interval);
                logger(false, "folder:action_createFolder_template");
                close();
            }
        }
    }, 5000);
};

type Context = {
    itemUri: string,
    template: string,
    targetFolderName?: string,
};

class createFolder_template {
    logger: () => any = toastActionResult;

    /**
     * will actually create the job for creating the folder template and show a progress modal
     */
    createFolder = ({
        itemUri,
        template,
        newTargetFolderName,
    }: {
        itemUri: string,
        template: string,
        newTargetFolderName?: string,
    }) =>
        openProgressModal({
            icon: "fa-regular fa-sitemap",
            title: loc.t("folder:action_createFolder_template.creating", {
                name: newTargetFolderName,
            }),
            doWork: async (close, setError) => {
                try {
                    const jobId = await createFolderFromTemplate(
                        template,
                        itemUri,
                        newTargetFolderName
                    );
                    if (jobId == null)
                        throw new Error("Error while creating a JobID");
                    CheckJobStatus({ jobId, close, logger: this.logger });
                } catch (err) {
                    /* istanbul ignore next */ if (err && err.message)
                        setError(err.message);
                    console.warn(err);
                    // notify user
                    this.logger(false, "folder:action_createFolder_template");
                    return null;
                }
            },
        });

    targetFolderName: string = "";
    shouldRender: boolean = true;
    render = async ({
        itemUri,
        template,
        targetFolderName,
    }: Context): Promise<boolean> => {
        this.targetFolderName = await openNameModal({
            itemUri,
            name: targetFolderName,
            title: loc.t("folder:action_createFolder_template.title", {
                name: template,
            }),
            nameLabel: loc.t("folder:action_createFolder_template.nameLabel"),
            okButton: loc.t(
                "folder:action_createFolder_template.confirmButton"
            ),
            validation: /* istanbul ignore next */ (
                newTargetFolderName: String
            ) => ValidateTemplate(template, itemUri, newTargetFolderName),
        });
        if (this.targetFolderName == null) return false;
        return true;
    };

    execute = ({
        itemUri,
        template,
        targetFolderName,
    }: Context): Promise<boolean> => {
        this.createFolder({
            itemUri,
            template,
            newTargetFolderName: this.targetFolderName,
        });
        this.logger(true, "folder:action_createFolder_template");
        return true;
    };
}

export default createFolder_template;
