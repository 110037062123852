import share from "./share";
import { ShareType } from "data/types";

/**
 * opens the share dialog with share type link
 */

class doc_emailLink extends share {
    execute = async (context) =>
        super.execute({
            ...context,
            shareType: ShareType.Link,
        });
}
export default doc_emailLink;
