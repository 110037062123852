import React from "react";
import { useToggle } from "hooks";
import FormBar from "@hs/form-bar";
import Avatar from "components/User/Avatar";
import { LockInfoOn } from "components/LockInfo";
import { type LockResultDTO } from "data/types";
import { useFormActions } from "hooks";
import { FormBarView } from "data/types";
import { getClassNames } from "data/utils";
import styles from "./LockInfo.module.css";

type Props = {
    lockInfo: LockResultDTO,
    className?: string,
};

const components = {
    Container: "aside",
};

/**
 * renders dismissable lock info
 *
 * @param {Props} { lockInfo, className }
 */
export const LockInfo = ({ lockInfo, className }: Props) => {
    const [show, toggleShow] = useToggle(true);
    const actions = useFormActions(FormBarView.LockInfo);
    return (
        <FormBar
            className={getClassNames(
                className,
                styles.aside,
                show ? styles.show : styles.hide
            )}
            onClose={toggleShow}
            actions={actions}
            components={components}
        >
            <div className={styles.wrapper}>
                <Avatar
                    id={lockInfo.lockedById}
                    title={lockInfo.lockedByName}
                    className={styles.avatar}
                />
                <span>{lockInfo.lockedByName}</span>
                <LockInfoOn date={lockInfo.lockedDate} />
            </div>
        </FormBar>
    );
};
LockInfo.displayName = "FormBar.LockInfo";
export default LockInfo;
