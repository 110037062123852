// @flow
import { openProgressModal } from "components/ProgressModal";
import { downloadDocUri } from "data/api";
import { getLocalizedText } from "data/utils";
import { toastActionResult } from "data/toast";
import { PrintAction } from "hooks/PostMessage/Actions";

type Context = {
    items?: Array<any>,
};

class doc_printMultiple {
    logger: () => any = toastActionResult;
    createPrintableFile = (data, contentType) => {
        switch (contentType) {
            case "image/apng":
            case "image/avif":
            case "image/gif":
            case "image/jpeg":
            case "image/png":
            case "image/svg+xml":
            case "image/webp":
                return {
                    $type: "image",
                    data: `data:${contentType};base64,${new Buffer.from(
                        data
                    ).toString("base64")}`,
                };
            case "text/html":
                return {
                    $type: "html",
                    data: new Buffer.from(data).toString(),
                };
            case "application/pdf":
                return { $type: "pdf", data };
            default:
                throw new Error(`Cannot print Content-Type: ${contentType}`);
        }
    };
    iframe = null;
    execute = async ({
        multiple,
        itemUri,
        ext,
        name: docName,
        isDoc,
        items,
    }: Context): Promise<boolean> => {
        const documents = items?.filter((item) => item.isDoc) || [];
        if (!multiple && isDoc) {
            documents.push({ itemUri, ext, isDoc, name: docName });
        }
        if (!documents.length) {
            this.logger(false, "itemActions:doc_print");
            return false;
        }

        const name = getLocalizedText(`$multiselection:actionpanel.document`, {
            count: documents.length,
        });

        await openProgressModal({
            icon: "fa-regular fa-print",
            title: getLocalizedText("$itemActions:doc_print.preparing", {
                documents: name,
            }),
            initialProgress: 0,
            doWork: async (close, setError, setProgress) => {
                const progressPart = 100 / documents.length;
                try {
                    // Load ipw-print-js in iframe
                    this.iframe = await new Promise((resolve) => {
                        let node;
                        const handleInit = (e) => {
                            /* istanbul ignore if */
                            if (!e.isTrusted || !e.data) return;
                            /* istanbul ignore if */
                            if (e.data?.source !== "inPoint") return;
                            /* istanbul ignore if */
                            if (e.data?.type !== PrintAction.Ready) return;
                            window.removeEventListener("message", handleInit);
                            resolve(node);
                        };
                        window.addEventListener("message", handleInit);
                        node = document.createElement("iframe");
                        node.src = `${process.env.PUBLIC_URL || ""}/__printjs/`;
                        document.body.appendChild(node);
                    });

                    let errors = 0;

                    // Iterate over documents and prepare for print
                    for (let doc = 0; doc < documents.length; doc++) {
                        const d = documents[doc];
                        try {
                            const uri = await downloadDocUri(
                                d.itemUri,
                                false,
                                !window.CONFIG.general.htmlViewerExtensions.includes(
                                    String(d.ext).toLowerCase()
                                ) &&
                                    !window.CONFIG.general.imageViewerExtensions.includes(
                                        String(d.ext).toLowerCase()
                                    )
                            );
                            /* istanbul ignore if */
                            if (uri == null)
                                throw new Error(
                                    "Cannot create download URI for document!"
                                );
                            const response = await fetch(uri);
                            const buffer = await response.arrayBuffer();
                            const data = this.createPrintableFile(
                                buffer,
                                response.headers.get("Content-Type")
                            );
                            // Send file to printjs and wait for response
                            await new Promise((resolve, reject) => {
                                /* istanbul ignore if */
                                if (data == null) reject();
                                const handlePrepareFile = (e) => {
                                    /* istanbul ignore if */
                                    if (!e.isTrusted || !e.data) return;
                                    /* istanbul ignore if */
                                    if (e.data?.source !== "inPoint") return;
                                    /* istanbul ignore if */
                                    if (e.data?.type === PrintAction.Failed)
                                        reject();
                                    /* istanbul ignore if */
                                    if (e.data?.type !== PrintAction.Ready)
                                        return;
                                    window.removeEventListener(
                                        "message",
                                        handlePrepareFile
                                    );
                                    resolve();
                                };
                                window.addEventListener(
                                    "message",
                                    handlePrepareFile
                                );
                                this.iframe?.contentWindow?.postMessage(
                                    {
                                        source: "inPoint",
                                        type: PrintAction.Prepare,
                                        data,
                                    },
                                    "*"
                                );
                            });
                        } catch (e) {
                            console.warn(
                                `Cannot add ${d.name} to print queue`,
                                e
                            );
                            errors++;
                        }

                        // Update progress bar
                        setProgress(progressPart * (doc + 1));
                    }

                    if (errors === documents.length) {
                        this.logger(false, "itemActions:doc_print");
                        close();
                        return;
                    }

                    this.iframe?.contentWindow?.postMessage(
                        {
                            source: "inPoint",
                            type: PrintAction.Print,
                        },
                        "*"
                    );
                    //Close delayed to always show 100% progress
                    setTimeout(close, 800);
                } catch (err) {
                    /* istanbul ignore else */
                    if (err && err.message) setError(err.message);
                    console.warn(err);
                    // notify user
                    this.logger(false, "itemActions:doc_print");
                }
            },
        });
        /* istanbul ignore if */
        if (this.iframe != null) {
            document.body.removeChild(this.iframe);
        }
        return true;
    };
}

export default doc_printMultiple;
