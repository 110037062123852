import React, { type Node, useEffect } from "react";
import { usePersistSetting } from "hooks";
import Icons from "@hs/icons";
import Collapse from "react-bootstrap/lib/Collapse";
import styles from "./Divider.module.css";
import { getClassNames } from "data/utils";

type Props = {
    /** optional CSS classname to apply */
    className?: string,
    /** caption to show on top left */
    header: string | Node,
    /** setting name (for persistance) */
    name?: string,
    /** whether to hide HorizontalRow */
    hideHR?: boolean,
    /** whether per default it should be expanded */
    defaultExpanded?: boolean,
    /** prop to control expanded state */
    expanded?: boolean,
    /** for Collapse: whether per default child component unmounts on exit  */
    unmountOnExit?: boolean,
    /** for Collapse: whether component is mounted on enter  */
    mountOnEnter?: boolean,
};

const Divider = ({
    className,
    header,
    name,
    hideHR,
    defaultExpanded,
    children,
    expanded: expandedFromProps,
    unmountOnExit = true,
    mountOnEnter = false,
}: Props) => {
    const [expanded, setExpanded] = usePersistSetting({
        name: name || "NA",
        defaultValue: defaultExpanded != null ? defaultExpanded : true,
    });
    useEffect(() => {
        setExpanded(expandedFromProps);
    }, [setExpanded, expandedFromProps]);
    return (
        <>
            <div
                onClick={() => {
                    name && setExpanded(!expanded);
                }}
                className={getClassNames(styles.main, className)}
                aria-expanded={expanded}
            >
                {children ? (
                    <span
                        className={
                            hideHR !== true ? styles.text : styles.textNoHR
                        }
                    >
                        <Icons.Library
                            name={`${expanded ? "caret-down" : "caret-right"}`}
                            className={styles.fa}
                            fixedWidth
                        />
                        <>{header}</>
                    </span>
                ) : (
                    <>{header}</>
                )}
                {hideHR !== true && <hr className={styles.hr} />}
            </div>
            {children && (
                <Collapse
                    in={expanded}
                    unmountOnExit={unmountOnExit}
                    mountOnEnter={mountOnEnter}
                >
                    {children}
                </Collapse>
            )}
        </>
    );
};
Divider.displayName = "Divider";
export default Divider;
