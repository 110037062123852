// @flow
import React from "react";
import { connect, Dispatch } from "react-redux";
// import LayoutLoader from "containers/LayoutLoader";
import FilterBarContainer from "containers/FilterBar";
import {
    DocListFilterOperators,
    type DocListLayout,
    type SearchBucketDTO,
    type SearchFilterDTO,
    type SearchValueTypeEnum,
    type DocColumnResultDTO,
    DocListColumnType,
    LocalDateStringOption,
    ListViewMode,
    type ListViewModeEnum,
    SearchValueType,
    type DocListFilterRequestDTO,
    SORT_BUCKET_NAME,
} from "data/types";
import { type docsResultState } from "data/reducers/types";
import {
    resetDocFilters,
    setDocListViewModeAction,
    setDocFilterByName,
    setDocFilterTextAction,
} from "data/actions";
import Constants, { type ConstantsEnum } from "data/constants";
import * as s from "data/reducers/selectors";
import { GetDefaults } from "components/DocListFilterModal/Utils";
import { getDefaultsConfig, get } from "data/constants";
import { userLanguage } from "data/storeHelper";
import loc from "i18next";

type Props = {
    /** Redux dispatch */
    dispatch: Dispatch,
    /** doclist fetch status */
    status: ConstantsEnum,
    /** doclist fetch result */
    result: docsResultState,
    /** current docListViewMode */
    docListViewMode: ListViewModeEnum,
    /** docList layout settings */
    layout: DocListLayout,
    /** callback to set filtering on a specific column
     * @param {string} colName name of column to filter
     */
    requestShowFilter: (colName: string) => void,
    /** callback to set sorting on a specific column
     * @param {string} colName name of column to sort
     */
    setSorting: (colName: string) => void,
    /** redux: current itemUri */
    itemUri: string,
    /** redux: current formatId */
    formatId: number,
    /** whether doclist filter should be rendered */
    renderFilter: ?boolean,
    /** whether doclist filter should be disabled */
    disableFilter: ?boolen,
};

type QuickFilterConfig = {
    name: string,
    caption?: string,
    visible: boolean,
    operator: DocListFilterOperatorsEnum,
    valueType: SearchValueTypeEnum,
};

type State = {
    aggregations: Array<SearchBucketDTO>,
    selected: Array<SearchFilterDTO>,
    quickFilter: ?QuickFilterConfig,
};

export class DocListFilterBar extends React.PureComponent<Props, State> {
    state = {
        aggregations: [],
        selected: [],
        quickFilter: null,
    };

    componentDidMount() {
        this.setState({
            ...this.prepFilters(),
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.status !== prevProps.status &&
            this.props.result != null
        ) {
            this.setState({
                ...this.prepFilters(),
            });
        }
    }

    prepFilters = () => {
        // prep available sorting/filters
        const { status, layout, result, formatId } = this.props;
        if (status !== Constants.OK || result == null || result.cols == null)
            return null;

        // filterBar buckets
        let selected = [];
        let filterBuckets: Array<SearchBucketDTO> = [];
        const sortBucket = {
            visible: true,
            icon: "fa-regular fa-sort",
            name: SORT_BUCKET_NAME,
            caption: loc.t("common:filter.SortCaption"),
            valueType: SearchValueType.NotSet,
            selected: [""],
            buckets: [],
        };

        // add sorting
        result.cols.forEach((col) => {
            if (col.canSort) {
                // add sorting
                sortBucket.buckets.push({
                    value: col.name,
                    caption: col.caption,
                });
            }
        });
        // custom sorting set?
        sortBucket.selected =
            layout && layout.sorts && layout.sorts.length > 0
                ? [layout.sorts[0].name]
                : [""];
        selected.push({
            name: SORT_BUCKET_NAME,
            value: sortBucket.selected[0],
            valueType: SearchValueType.NotSet,
        });

        // find current quickFilter defaults settings
        const quickFilter = this._getQuickFilterConfig();
        const quickFilterCol = this._findCol(quickFilter.name);
        if (quickFilterCol) quickFilter.caption = quickFilterCol.caption;
        else quickFilter.visible = false;

        // add filters
        const defaultFilters = GetDefaults(
            result.itemUri,
            formatId,
            result.cols
        ).filters;
        const filters =
            layout.filters != null && layout.filters.length > 0
                ? layout.filters
                : defaultFilters;

        filters.forEach((filter) => {
            const col = this._findCol(filter.name);
            if (col == null || col.canSort === false) return;
            // add filter
            const dupIdx = filterBuckets.findIndex((b) => b.name === col.name);
            // avoid duplicates
            /* istanbul ignore else */
            if (dupIdx === -1) {
                const hasFilter =
                    filter != null &&
                    filter.values.filter((f) => f != null && String(f).length)
                        .length > 0;
                const filterCaption = hasFilter
                    ? this._getFilterDisplay(filter, col)
                    : null;
                if (hasFilter) {
                    selected.push({
                        name: col.name,
                        value: this._getValueOrValues(filter.values),
                        valueType: SearchValueType.NotSet,
                    });
                }
                // add to filterBar
                filterBuckets.push({
                    name: col.name,
                    caption: col.caption,
                    valueType: SearchValueType.NotSet,
                    selected: hasFilter ? filter.values : [],
                    buckets: hasFilter
                        ? [
                              {
                                  value: this._getValueOrValues(filter.values),
                                  caption: filterCaption,
                              },
                          ]
                        : [],
                    hideMenu: true,
                    hideName: false,
                    visible: true,
                });
                // is it also our quickFilter?
                if (
                    quickFilter &&
                    quickFilter.visible &&
                    // this.props.result.filterText == null &&
                    quickFilter.name.toUpperCase() === col.name.toUpperCase() &&
                    quickFilter.operator === filter.operator
                ) {
                    this._onFilterTextChange(filter.values, quickFilter);
                }
            }
        });

        // sort always at the end
        filterBuckets.push(sortBucket);

        return { aggregations: filterBuckets, selected, quickFilter };
    };

    _getValueOrValues = (values: Array<any>): Array<any> | any =>
        values.length > 1 ? values : values[0];

    _getFilterDisplay = (
        filter: DocListFilterRequestDTO,
        col?: DocColumnResultDTO
    ): string => {
        let values = [...filter.values];
        // Convert JSON UTC Date value to localized Date
        /* istanbul ignore else */
        if (
            col &&
            col.type === DocListColumnType.DateTimeValue &&
            filter.operator !== DocListFilterOperators.DateRelative
        ) {
            values = values.map((value) =>
                value == null
                    ? ""
                    : new Date(
                          String(value).replace("Z", "")
                      ).toLocaleDateString(
                          userLanguage(),
                          LocalDateStringOption.Date
                      )
            );
        }
        switch (filter.operator) {
            case DocListFilterOperators.DateRelative:
                return loc.t("common:filter.RelativeDate." + values[0]);
            case DocListFilterOperators.Equals:
                return `${values[0]}`;
            case DocListFilterOperators.Between:
                return `${loc.t(
                    "folder:action_filter.operators." + filter.operator
                )} ${values[0]}-${values[1]}`;
            default:
                return `${loc.t(
                    "folder:action_filter.operators." + filter.operator
                )} ${values[0]}`;
        }
    };

    _findCol = (
        name: string,
        identifier: string = "name"
    ): DocColumnResultDTO | null =>
        this.props.result &&
        this.props.result.cols &&
        this.props.result.cols.find(
            (c) => c[identifier].toUpperCase() === name.toUpperCase()
        );

    _onFilterBarSelect = (
        name: string,
        valueType: SearchValueTypeEnum,
        value: any
    ) => {
        if (value === null) {
            // button item
            this.props.requestShowFilter(name);
        } else if (name === SORT_BUCKET_NAME) {
            // sort
            this.props.setSorting(value);
        } else {
            this.props.dispatch(setDocFilterByName({ name, valueType, value }));
        }
    };

    _onFilterBarClear = () => this.props.dispatch(resetDocFilters());

    _onViewModeChange = (viewMode: ListViewModeEnum) =>
        this.props.dispatch(setDocListViewModeAction(viewMode));

    _getQuickFilterConfig = () => {
        // merge default with appsettings defaults
        const quickFilter = Object.assign(
            {},
            {
                visible: true,
                name: null, //will then search for Name or Dateiname
                operator: DocListFilterOperators.Contains,
                valueType: SearchValueType.String, // we only really support strings anyways
            },
            get(
                getDefaultsConfig(this.props.itemUri, this.props.formatId),
                ["docList", "quickFilter"],
                {}
            )
        );
        // find real name column (unless provided)
        const nameCol = quickFilter.name
            ? this._findCol(quickFilter.name)
            : this._findCol(DocListColumnType.WebDavName, "type");
        quickFilter.name = (nameCol && nameCol.name) || "Name"; // nothing found, fallback to Name
        return quickFilter;
    };

    _onFilterTextChange = (
        filterText?: string,
        quickFilter?: QuickFilterConfig
    ) =>
        this.props.dispatch(
            setDocFilterTextAction({
                ...(quickFilter || this.state.quickFilter),
                value: filterText,
            })
        );

    render = () => (
        <FilterBarContainer
            aggregations={this.state.aggregations}
            selected={this.state.selected}
            onSelect={this._onFilterBarSelect}
            onClear={this._onFilterBarClear}
            filterText={
                this.props.result &&
                this.props.result.filterText &&
                this.props.result.filterText.value
            }
            onFilterTextChange={
                this.state.quickFilter &&
                this.state.quickFilter.visible &&
                this._onFilterTextChange
            }
            filterTextPlaceholder={
                this.state.quickFilter &&
                this.state.quickFilter.caption &&
                loc.t("document:docsfilter", {
                    name: this.state.quickFilter.caption,
                })
            }
            viewModes={[
                ListViewMode.Thumbnails,
                ListViewMode.List,
                ListViewMode.Grid,
            ]}
            viewMode={this.props.docListViewMode}
            onViewModeChange={this._onViewModeChange}
            renderFilter={this.props.renderFilter}
            disableFilter={this.props.disableFilter}
        />
    );
}

const mapStateToProps = /* istanbul ignore next */ (state, ownProps) => ({
    docListViewMode: s.docListViewModeSelector(state),
    status: s.docsStatusSelector(state),
    result: state.docs,
    itemUri: s.breadcrumbItemUriSelector(state, s.currentNameSelector(state)),
    formatId: s.breadcrumbFormatIdSelector(state, s.currentNameSelector(state)),
    ...ownProps,
});

export default connect(mapStateToProps)(DocListFilterBar);
