import actionTypes from "../actions/actionTypes";
import {
    NotificationStatusFlag,
    PriorityType,
    NotificationStyle,
    type NotificationDTO,
} from "data/types";
import { produce } from "immer";

export const initialState: Array<NotificationDTO> = [];

const notificationReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        const { payload, type } = action;
        const i = state.findIndex(
            (i) => i.notificationId === payload?.notificationId
        );

        switch (type) {
            case actionTypes.NOTIFICATION_UPSERT:
                if (i === -1)
                    draft.unshift({
                        notificationId:
                            payload?.notificationId ??
                            window.crypto.randomUUID(),
                        message: payload?.message,
                        status: payload?.status ?? NotificationStatusFlag.New,
                        priority:
                            payload?.priority ?? PriorityType.Intermediate,
                        style: payload?.style ?? NotificationStyle.Default,
                        icon: payload?.icon,
                        Component: payload?.Component,
                        toast: payload?.toast ?? false,
                        timestamp: payload?.timestamp ?? Date.now(),
                    });
                else draft[i] = { ...draft[i], ...payload };
                break;
            case actionTypes.NOTIFICATION_DEL:
                if (i > -1) draft.splice(i, 1);
                else
                    console.warn(
                        `Not found. Cannot delete Notification ${payload.notificationId}`
                    );
                break;
            case actionTypes.NOTIFICATION_STATUS:
                if (i === -1)
                    draft.forEach((item, i) => {
                        draft[i].status = payload.replace
                            ? payload.status
                            : draft[i].status | payload.status;
                    });
                else
                    draft[i].status = payload.replace
                        ? payload.status
                        : draft[i].status | payload.status;
                break;
            case actionTypes.NOTIFICATION_CLEAR:
                return payload == null
                    ? initialState
                    : draft.filter(
                          (item) =>
                              (payload.priority == null ||
                                  payload.priority !== item.priority) &&
                              (payload.style == null ||
                                  payload.style !== item.style) &&
                              (payload.status == null ||
                                  payload.status !== item.status)
                      );
            /* istanbul ignore next */
            case actionTypes.PERSIST_PURGE:
                return initialState;
            default:
                break;
        }
    });

export default notificationReducer;

export const notificationSelector = (
    state: Any,
    notificationId: NotificationId = null
): Array<NotificationDTO> =>
    state.notification
        ?.filter(
            (i) => notificationId == null || i.notificationId === notificationId
        )
        ?.sort((a, b) =>
            a.timestamp > b.timestamp ? -1 : a.timestamp < b.timestamp ? 1 : 0
        ) ?? [];
