// @flow
import React, { useContext } from "react";
import { useRecycleBin } from "hooks";
import NoData from "components/NoData";
import { IsFiltered } from "components/DocListFilterModal/Utils";
import { type DocListLayout } from "data/types";
import OpenFiltersButton from "./OpenFiltersButton";
import CommandActionButton from "components/CommandActions/CommandActionButton";
import { ItemContext } from "data/context";
import styles from "./NoDocs.module.css";

const NoDocs = ({
    layout,
    requestShowFilter,
    showAddDocument,
}: {
    layout?: DocListLayout,
    requestShowFilter?: Function,
    showAddDocument?: boolean,
}) => {
    const { isDocAssignmentAllowed, itemUri, itemStatus } =
        useContext(ItemContext) || {};
    const [, , , checkIfInRecycleBin] = useRecycleBin(itemUri, itemStatus);
    const hasActiveFilter =
        layout != null &&
        layout.filters &&
        requestShowFilter != null &&
        IsFiltered(layout.filters);
    const canUploadDocument =
        showAddDocument &&
        !hasActiveFilter &&
        itemUri &&
        isDocAssignmentAllowed &&
        !checkIfInRecycleBin();
    return (
        <NoData locContext="doc">
            {(hasActiveFilter || canUploadDocument) && (
                <div className={styles.wrapper}>
                    {hasActiveFilter && (
                        <OpenFiltersButton onClick={requestShowFilter} />
                    )}
                    {canUploadDocument && (
                        <CommandActionButton
                            action="doc_upload"
                            props={{ itemUri }}
                        />
                    )}
                </div>
            )}
        </NoData>
    );
};
NoDocs.displayName = "NoDocs";
NoDocs.defaultProps = {
    showAddDocument: true,
};
export default NoDocs;
