import "whatwg-fetch";
import { ApiCatalog } from "data/ApiCatalog";
import {
    HttpMethod,
    type HttpMethodEnum,
    ContentType,
    type ContentTypeEnum,
    CacheStrategy,
    type CacheSettings,
    CustomHttpHeader,
} from "data/types";
import { isMiniView } from "data/constants";
import {
    HTTP_ERROR_CONFLICT,
    notifyOnError,
    ApiError,
    ApiErrorContainer,
    ApiNetworkError,
    ApiNetworkOfflineError,
    ApiConflictError,
} from "data/apiError";
import { accessToken, correlationId, isOnline } from "data/storeHelper";
import { signinRedirect } from "data/loginFlow";
import qs from "data/queryString";
import { performanceTester } from "data/utils";
import { JL } from "jsnlog";
import { isLegacyResponseClone } from "data/bowser";
import cloneDeep from "lodash/cloneDeep";

//#region AbortSignal
// https://github.com/flow-typed/flow-typed/issues/1652
// translation of https://dom.spec.whatwg.org/#abortcontroller
declare interface AbortSignal extends EventTarget {
    +aborted: boolean;
    onabort: EventHandler;
}
declare class AbortController {
    +signal: AbortSignal;
    abort: () => void;
}
//#endregion

export type ContextRequest = {
    name: string,
    method: HttpMethodEnum,
    contentType: ContentTypeEnum,
    urlEnd: string,
    params: Object,
    cache: CacheSettings,
};

export type Context = {
    request: ContextRequest,
    response?: Response,
    url: string,
    options: RequestInit,
    body?: any,
};

/**
 * Base implementation for API calls
 * TODO this is not meant to be used directly - a cache read-through (including offline) descendent WIP
 * @export
 * @class ApiManager
 * @abstract
 */
export default class ApiManagerBase {
    constructor() {
        this._log = JL("ApiManager");
        this.call = this.call.bind(this);
        this.BeforeFetch = this.BeforeFetch.bind(this);
        this.OnFetch = this.OnFetch.bind(this);
        this.AfterFetch = this.AfterFetch.bind(this);
        this.CheckLicense = this.CheckLicense.bind(this);
        this.DecodeBody = this.DecodeBody.bind(this);
        this.CheckCustomError = this.CheckCustomError.bind(this);
        this.EOL = this.EOL.bind(this);
    }

    /**
     * Call an API method
     * @param {string} name API method name (from ApiCatalog)
     * @param {Object} params optional parameters
     * @param {AbortSignal} signal optional AbortSignal
     * @returns {Context} decoded response body
     * @memberof ApiManager
     * @protected
     */
    _callReturnContext = async (
        name: string,
        {
            params,
            signal,
            transformer,
        }?: {
            params?: Object,
            signal?: AbortSignal,
            transformer?: (data: Object) => Object,
        } = {}
    ): Promise<Context> => {
        if (name == null)
            throw new ApiError("You must provide the API method name!");
        const meta = ApiCatalog[name];
        if (meta == null)
            throw new ApiError(`Could not find ${name} in ApiCatalog!`);
        let context: Context = {
            request: {
                name,
                method: meta.method ?? HttpMethod.GET,
                urlEnd: meta.url,
                params:
                    typeof meta.params === "function"
                        ? meta.params(params)
                        : params,
                contentType: meta.contentType ?? ContentType.AUTO,
                cache: meta.cache,
                offline: meta.offline,
                transformer,
                signal,
            },
        };
        context = await this._pipeline(context);
        return context;
    };

    /**
     * Call an API method
     * @param {string} name API method name (from ApiCatalog)
     * @param {Object} params optional parameters
     * @param {AbortSignal} signal optional AbortSignal
     * @returns {any} decoded response body
     * @memberof ApiManager
     * @public
     */
    call = async (name: string, args?: Object = {}): Promise<any> => {
        return (await this._callReturnContext(name, args)).body;
    };

    /**
     * encode request query parameters
     * @param {Object} data
     * @returns {string} encoded ready to add to URL (without ?)
     * @memberof ApiManager
     * @private
     */
    _encodeData = (data: Object): string => {
        // prefix ItemUris
        if (Object.prototype.hasOwnProperty.call(data, "itemUri")) {
            let itemUri = data.itemUri;
            if (itemUri && !itemUri.startsWith("pam-item://")) {
                data.itemUri = "pam-item://" + itemUri;
            }
        }
        // stringify skipping empty values
        return qs.stringify(data, {
            filter: /* istanbul ignore next */ (prefix, value) =>
                value == null ? undefined : value,
        });
    };

    _getFullCacheKey = (context: Context): string => {
        if (context.request.cacheKey) {
            return `${window.CONFIG.host.basename}${window.CONFIG.general.api}${context.request.urlEnd}${context.request.cacheKey}`;
        }
        if (context.request.method === HttpMethod.GET) {
            return context.url;
        } else {
            // store api method name in cache URL to cover apis using same URL but different HTTP methods
            // e.g. fetchFavorites, addFavorite and delFavorite
            return `${context.url}?${this._encodeData({
                api: context.request.name,
            })}`;
        }
    };

    /**
     * Creates appropriate request HTTP headers
     * @param {Context} context query context
     * @returns {Headers} Headers collection
     * @memberof ApiManager
     * @private
     */
    _getHeaders = (context: Context): Headers => {
        const token: string = accessToken();
        /* istanbul ignore else */
        if (token == null) {
            this._log.error("No user token available!");
            throw new ApiError("No user token available!");
        }

        const headers = new Headers();
        headers.append("Accept", "application/json"); //application/json -> to avoid OPTIONS pre-flight requests

        // Bearer Authorization header
        if (window.CONFIG.auth.useCustomAuthHeader) {
            headers.append(CustomHttpHeader.CustomAuthorization, token);
        } else {
            headers.append(CustomHttpHeader.Authorization, `Bearer ${token}`);
        }
        headers.append(CustomHttpHeader.CorrelationId, correlationId());

        // add cacheStrategy for our ServiceWorker to process (avoid a back-lookup)
        const { cache } = context.request;
        if (cache !== false) {
            headers.append(
                CustomHttpHeader.CacheStrategy,
                cache == null || cache === true
                    ? CacheStrategy.NetworkFirst
                    : cache.strategy || CacheStrategy.NetworkFirst
            );
        }

        // encode custom cacheKey for serviceWorker to use when storing in cache
        if (
            cache != null &&
            Object.prototype.hasOwnProperty.call(cache, "keyParams")
        ) {
            context.request.cacheKey = `?${this._encodeData(
                cache.keyParams(context.request.params)
            )}`;
            headers.append(CustomHttpHeader.CacheKey, context.request.cacheKey);
        }

        // passthrough original secureParams token
        if (window.CONFIG.general.secure_params) {
            headers.append(
                CustomHttpHeader.SecureParams,
                window.CONFIG.general.secure_params
            );
        }

        // passthrough original /mini view mode
        if (isMiniView(window.location)) {
            headers.append(CustomHttpHeader.MiniView, "1");
        }
        return headers;
    };

    /**
     * Handles failed Network requests
     * @param {Context} context query context
     * @memberof ApiManager
     * @private
     */
    _networkError = (context: Context): void => {
        this._log.error(() => ({
            msg: `${context.request.name}: OnFetch - Network request failed`,
            apiContext: context,
        }));
        throw new ApiNetworkOfflineError("Network request failed", context);
    };

    /**
     * First chain method to prepare Request
     * @memberof ApiManager
     * @param {Context} context query context
     * @returns {Promise<Context>} current context
     * @virtual
     */
    async BeforeFetch(context: Context): Promise<Context> {
        this._log.debug(() => ({
            msg: `${context.request.name}: BeforeFetch`,
            apiContext: context,
        }));
        const { method, urlEnd, params, signal } = context.request;

        context.url = `${window.CONFIG.host.basename}${window.CONFIG.general.api}${urlEnd}`;
        if (
            params &&
            (method === HttpMethod.GET ||
                method === HttpMethod.DELETE ||
                method === HttpMethod.HEAD)
        ) {
            const urlParams = this._encodeData(params);
            if (urlParams && urlParams.length > 0) {
                context.url += `?${urlParams}`;
            }
        }

        context.options = {
            method,
            headers: this._getHeaders(context),
            credentials: "include",
            mode: "cors",
            body: undefined,
            signal,
        };

        /* istanbul ignore else */
        if (method === HttpMethod.PUT || method === HttpMethod.POST) {
            context.options.headers.append("Content-Type", "application/json");
            context.options.body = JSON.stringify(params);
        }
        return context;
    }

    /**
     * Runs actual data fetch
     * @param {Context} context query context
     * @returns {Promise<Context>} current context with fetch .response
     * @memberof ApiManager
     * @virtual
     */
    async OnFetch(context: Context): Promise<Context> {
        this._log.debug(() => ({
            msg: `${context.request.name}: OnFetch`,
            apiContext: context,
        }));
        if (false === isOnline()) this._networkError(context);
        context.response = await fetch(context.url, context.options).catch(
            (e) => {
                // Handle Signal AbortErrors
                if (e.name === "AbortError") {
                    throw e;
                }
                this._networkError(context);
            }
        );

        return context;
    }

    /**
     * First response in fetch response chain
     * @param {Context} context query context
     * @returns {Promise<Context>} current context
     * @memberof ApiManager
     * @virtual
     */
    async AfterFetch(context: Context): Promise<Context> {
        this._log.debug(() => ({
            msg: `${context.request.name}: AfterFetch`,
            apiContext: context,
        }));
        const { response } = context;
        /* istanbul ignore else */
        if (!response.ok) {
            // NotAuthorized? redirect
            if (response.status === 401) {
                signinRedirect();
                return context;
            }
            if (response.status === HTTP_ERROR_CONFLICT) {
                throw new ApiConflictError(
                    response,
                    await this._getErrorData(response)
                );
            }
            if (response.status !== 500 && response.status !== 408) {
                throw new ApiNetworkError(
                    response,
                    await this._getErrorData(response)
                );
            } else {
                // API errors also have HTTP 500 but include json for isError description
                const contentHeader = response.headers.get("content-type");
                if (
                    contentHeader == null ||
                    contentHeader.indexOf("application/json") === -1
                ) {
                    // this is a different HTTP 500 not coming from API!
                    throw new ApiNetworkError(
                        response,
                        await this._getErrorData(response)
                    );
                }
            }
        }
        return context;
    }

    async _getErrorData(response: Response): Promise<ApiErrorResult> {
        let result = {};
        try {
            result = await response.clone().json();
        } catch (error) {
            /* istanbul ignore next */
            this._log.warn(() => ({
                msg: "Could parse response body for additional error data",
                error,
                response,
            }));
        }
        return result;
    }

    /**
     * Validates HTTP header for a valid inPoint license
     * @param {Context} context query context
     * @returns {Promise<Context>} current context
     * @memberof ApiManager
     * @virtual
     */
    async CheckLicense(context: Context): Promise<Context> {
        this._log.debug(() => ({
            msg: `${context.request.name}: CheckLicense`,
            apiContext: context,
        }));
        // check license
        const lic = context.response.headers.get("x-inpoint-license");
        /* istanbul ignore else */
        if (lic === null || lic === "Invalid") {
            // TODO invalid license user notification instead of console error
            this._log.warn("Invalid inPoint.Web Server License!");
        }
        return context;
    }

    /**
     * Decodes response body based on content-type
     * @param {Context} context query context
     * @returns {Promise<Context>} current context with decoded .body
     * @memberof ApiManager
     * @virtual
     */
    async DecodeBody(context: Context): Promise<Context> {
        this._log.debug(() => ({
            msg: `${context.request.name}: DecodeBody`,
            apiContext: context,
        }));
        const { request } = context;
        // avoid bodyUsed in case of cache.transformer!
        /* istanbul ignore next */
        const response = isLegacyResponseClone()
            ? cloneDeep(context.response) // Legacy browsers
            : context.response.clone(); // Modern browsers
        switch (request.contentType) {
            case ContentType.NONE:
                context.body = null;
                break;
            case ContentType.TEXT:
                context.body = await response.text();
                break;
            case ContentType.AUTO:
            // fall through
            case ContentType.JSON:
                const contentHeader = response.headers.get("content-type");
                if (
                    contentHeader &&
                    contentHeader.indexOf("application/json") !== -1
                ) {
                    // checking response header
                    context.body = await response.json();
                } else {
                    context.body = await response.text();
                }
                break;
            case ContentType.BLOB:
                // TODO #40646 only newest fetch-mock supports blobs
                context.body =
                    typeof response.blob === "function"
                        ? await response.blob()
                        : /* istanbul ignore next */ null;
                break;
            default:
                this._log.error(() => ({
                    msg: `${context.request.name} ApiCatalog has invalid ContentType: ${context.request.contentType}`,
                    apiContext: context,
                }));
                break;
        }
        return context;
    }

    /**
     * Check for any custom server errors
     * @param {Context} context query context
     * @returns {Promise<Context>} current context
     * @memberof ApiManager
     * @virtual
     */
    async CheckCustomError(context: Context): Promise<Context> {
        this._log.debug(() => ({
            msg: `${context.request.name}: CheckCustomError`,
            apiContext: context,
        }));
        const { request, body } = context;
        if (request.contentType !== ContentType.BLOB && body != null) {
            /* istanbul ignore else */
            if (body.isError === true) {
                notifyOnError(body);
                throw new ApiErrorContainer(body);
            }
        }
        return context;
    }

    /**
     * Placeholder event at the End Of line
     * @param {Context} context
     * @returns {Promise<Context>}
     * @memberof ApiManager
     * @virtual
     */
    async EOL(context: Context): Promise<Context> {
        return context;
    }

    /**
     * Main Worker kicking off pipeline chain
     * @memberof ApiManager
     * @private
     */
    _pipeline = async (
        context: Context
    ): Promise<Context> /* istanbul ignore next */ =>
        performanceTester(
            async () =>
                await this.BeforeFetch(context)
                    .then(this.OnFetch)
                    .then(this.AfterFetch)
                    .then(this.CheckLicense)
                    .then(this.DecodeBody)
                    .then(this.CheckCustomError)
                    .then(this.EOL),
            (ms) =>
                this._log.info(() => ({
                    msg: `${context.request.name} Done in ${ms}ms`,
                    apiContext: context,
                    apiTook: ms,
                }))
        );
}
