import React, { useCallback, useMemo } from "react";
import Formsy from "formsy-react";
import loc from "i18next";
import DateEditorControl from "components/ExtraAttributes/EditorControl/DateEditorControl";
import PrincipalEditorControl from "components/ExtraAttributes/EditorControl/PrincipalEditorControl";
import EnumEditorControl from "components/ExtraAttributes/EditorControl/EnumEditorControl";
import EnumViewerControl from "components/ExtraAttributes/ViewerControl/EnumViewerControl";
import MemoEditorControl from "components/ExtraAttributes/EditorControl/MemoEditorControl";
import HelpBlock from "react-bootstrap/lib/HelpBlock";
import Icons from "@hs/icons";
import {
    DateTimeFormat,
    ReminderTypeFlags,
    ReminderTypeFlagsEnumValues,
    ReminderPriorityEnumValues,
    type ReminderDTO,
} from "data/types";

type Props = {
    reminder: ReminderDTO,
    autoFocus?: boolean,
    allowReminderTypeEdit: boolean,
    setCanSubmit: () => void,
    handleSave: () => void,
    handleChange: () => void,
};

const ReminderForm = ({
    reminder: { customAction, ...reminder },
    autoFocus,
    setCanSubmit,
    handleSave,
    handleChange,
    allowReminderTypeEdit,
}: Props) => {
    const disableSubmit = useCallback(
        () => setCanSubmit(false),
        [setCanSubmit]
    );
    const enableSubmit = useCallback(() => setCanSubmit(true), [setCanSubmit]);
    const reminderTypeEnumValues = useMemo(
        () =>
            customAction == null
                ? ReminderTypeFlagsEnumValues.filter(
                      (r) =>
                          ![
                              ReminderTypeFlags.Task,
                              ReminderTypeFlags.Calendar,
                          ].includes(r.value)
                  )
                : ReminderTypeFlagsEnumValues.filter(
                      (r) =>
                          ![
                              ReminderTypeFlags.Task,
                              ReminderTypeFlags.Calendar,
                          ].includes(r.value)
                  ).concat({
                      value: ReminderTypeFlags.CustomAction,
                      label:
                          customAction.displayName ||
                          "$reminder:type.customAction",
                      icon: (
                          <Icons.CustomActionReminder
                              style={{
                                  width: 13,
                                  height: 13,
                                  marginBottom: -2,
                                  display: "inline-block",
                              }}
                          />
                      ),
                  }),
        [customAction]
    );
    return (
        <Formsy
            onValidSubmit={handleSave}
            onValid={enableSubmit}
            onInvalid={disableSubmit}
        >
            <DateEditorControl
                header={`${loc.t("reminder:date")}*`}
                name="date"
                isRequired={true}
                type={DateTimeFormat.JsonDateTime}
                value={reminder.date}
                onChange={handleChange}
                validations={{
                    isGreaterThanNow: (values, value) =>
                        new Date(value) - Date.now() > 0,
                }}
                validationErrors={{
                    isDefaultRequiredValue: loc.t("common:validation.required"),
                    isGreaterThanNow: loc.t("common:validation.greaterThanNow"),
                }}
            />
            <PrincipalEditorControl
                header={`${loc.t("reminder:recipient")}*`}
                name="recipient"
                includeGroups={true}
                isMulti={false}
                isClearable={true}
                isRequired={true}
                createUser={true}
                value={reminder.recipient}
                onChange={handleChange}
                validationErrors={{
                    isDefaultRequiredValue: loc.t("common:validation.required"),
                }}
                autoFocus={autoFocus}
            />
            <MemoEditorControl
                header={`${loc.t("reminder:reason.label")}*`}
                placeholder={loc.t("reminder:reason.placeholder")}
                name="reason"
                isRequired={true}
                value={reminder.reason}
                validations={{
                    maxLength: 255,
                }}
                validationErrors={{
                    isDefaultRequiredValue: loc.t("common:validation.required"),
                    maxLength: loc.t("common:validation.maxLength", {
                        maxLength: 255,
                    }),
                }}
                onChange={handleChange}
            />
            <EnumEditorControl
                header={loc.t("reminder:priority.label")}
                name="priority"
                enum={ReminderPriorityEnumValues}
                isMulti={false}
                value={reminder.priority}
                onChange={handleChange}
            />
            {allowReminderTypeEdit ? (
                <EnumEditorControl
                    header={loc.t("reminder:type.label")}
                    name="type"
                    enum={reminderTypeEnumValues}
                    isMulti={true}
                    validations={{
                        hasFlag: /* istanbul ignore next */ (values, value) =>
                            value !== ReminderTypeFlags.NotSet,
                    }}
                    validationErrors={{
                        hasFlag: loc.t("common:validation.required"),
                    }}
                    value={reminder.type}
                    onChange={handleChange}
                />
            ) : (
                <EnumViewerControl
                    header={loc.t("reminder:type.label")}
                    name="type"
                    enum={reminderTypeEnumValues}
                    isMulti={true}
                    value={reminder.type}
                />
            )}
            {customAction != null && customAction.error && (
                <HelpBlock>{customAction.error}</HelpBlock>
            )}
        </Formsy>
    );
};

ReminderForm.displayName = "ReminderForm";
export default ReminderForm;
