import { get, getDefaultsConfig } from "data/constants";

export const SupportGeoMapViewer = () =>
    get(window.CONFIG, ["general", "geo", "map", "enabled"], false) !== false;

export const getGeoMapViewerProvider = () =>
    get(window.CONFIG, ["general", "geo", "map"]);

export /** whether support for GeoCoding is enabled */ const SupportGeoPlaceInput = () =>
    get(window.CONFIG, ["general", "geo", "places", "enabled"], false) !==
    false;

export const getGeoPlacesProvider = () =>
    get(window.CONFIG, ["general", "geo", "places"]);

export /** whether support for editing a marker on a map is enabled */ const SupportGeoPointEdit = () =>
    get(window.CONFIG, ["general", "geo", "pointEdit", "enabled"], false) !==
    false;

export const getGeoPointProvider = () =>
    get(window.CONFIG, ["general", "geo", "pointEdit"]);

export /** whether support for editing radius on a map is enabled */ const SupportGeoRadiusEdit = () =>
    get(window.CONFIG, ["general", "geo", "radius", "enabled"], false) !==
    false;

export const getGeoRadiusProvider = () =>
    get(window.CONFIG, ["general", "geo", "radius"]);

export /** whether support for editing areas on a map is enabled */ const SupportGeoAreaEdit = () =>
    get(window.CONFIG, ["general", "geo", "areaEdit", "enabled"], false) !==
    false;

export const getGeoAreaProvider = () =>
    get(window.CONFIG, ["general", "geo", "areaEdit"]);

export const getGeoRasterProvider = (itemUri: string, formatId: number) => {
    const config = getDefaultsConfig(itemUri, formatId);
    return config ? config.map : null;
};
