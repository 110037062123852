// @flow
import React, { useCallback } from "react";
import styles from "./Button.module.css";
import CountBadge from "components/CountBadge";
import Button from "react-bootstrap/lib/Button";
import Icons from "@hs/icons";
import { type ToggleItemDTO } from "data/types";
import Constants from "data/constants";
import { getClassNames, getLocalizedText } from "data/utils";

type Props = {
    name: string,
    isMultiple: boolean,
    item: ToggleItemDTO,
    onClick: Function,
};

const ToggleBarButton = ({ item, index, isMultiple, onClick }: Props) => {
    const handleClick = useCallback(
        () => !item.isDisabled && onClick(item, isMultiple),
        [item, isMultiple, onClick]
    );
    const title = getLocalizedText(item.text);
    return (
        !item.isHidden && (
            <Button
                aria-label={title}
                active={item.isSelected}
                disabled={item.isDisabled}
                title={title}
                onClick={handleClick}
                data-test={`toggleButton-${item.value}`}
            >
                {item.icon && (
                    <Icons.Library name={item.icon} className={styles.icon} />
                )}
                <span
                    className={getClassNames(
                        item.icon && "hidden-xs hidden-sm",
                        "text-sm"
                    )}
                >
                    {title}
                </span>
                {item.count > 0 && (
                    <CountBadge
                        className={`text-sm hidden-xs ${styles.badge}`}
                        count={item.count}
                    />
                )}
                {item.status != null && (
                    <Icons.Library
                        name={
                            item.status === Constants.LOADING
                                ? "spinner"
                                : item.status === Constants.ERROR
                                ? "circle-minus"
                                : item.status
                        } /* ERROR LOCATION */
                        className={`hidden-xs ${styles.status}`}
                        pulse={item.status === Constants.LOADING}
                    />
                )}
            </Button>
        )
    );
};
ToggleBarButton.displayName = "ToggleBar.Button";

export default ToggleBarButton;
