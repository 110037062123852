import { all, call, take, put, cancelled, select } from "redux-saga/effects";
import { channel, eventChannel, buffers, delay } from "redux-saga";
import { serverEventAction } from "data/actions";
import { actions as networkActions } from "redux-saga-network-status";
import * as s from "data/reducers/selectors";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { get } from "data/constants";
import { CustomHttpHeader } from "data/types";
import { JL } from "jsnlog";

const _log = JL("Notifications");
const actionChannel = channel();

function createSocket(
    accessToken: string,
    correlationId: string
): HubConnection {
    return new HubConnectionBuilder()
        .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: /*istanbul ignore next*/ (
                retryContext
            ) => {
                if (retryContext.elapsedMilliseconds < 60000) {
                    // If we've been reconnecting for less than 60 seconds so far,
                    // wait between 0 and 10 seconds before the next reconnect attempt.
                    return Math.random() * 10000;
                } else {
                    // If we've been reconnecting for more than 60 seconds so far, try only every 5mins
                    return 1000 * 60 * 5;
                }
            },
        })
        .configureLogging("warn")
        .withUrl(
            get(
                window.CONFIG,
                ["notificationsUrl", "global"],
                "/notifications/global"
            ) +
                "?correlationId=" +
                encodeURIComponent(correlationId),
            {
                // https://docs.microsoft.com/en-us/aspnet/core/signalr/configuration?view=aspnetcore-5.0&tabs=dotnet#configure-bearer-authentication
                accessTokenFactory: /*istanbul ignore next*/ () => accessToken,
                headers: {
                    [CustomHttpHeader.CorrelationId]: correlationId,
                },
            }
        )
        .build();
}

function signalChannel(connection) {
    return eventChannel((emit) => {
        const pingUrl = `${window.CONFIG.host.basename}/ping`;
        // https://docs.microsoft.com/en-us/aspnet/signalr/overview/guide-to-the-api/hubs-api-guide-javascript-client#how-to-handle-connection-lifetime-events
        connection.on("ReceiveMessage", emit);
        connection.onreconnecting(
            /*istanbul ignore next*/ (error) => {
                actionChannel.put(networkActions.ping(pingUrl));
                _log.warn(() => ({
                    msg: "SignalR reconnecting",
                    error,
                }));
            }
        );
        connection.onreconnected(
            /*istanbul ignore next*/ (connectionId) => {
                actionChannel.put(networkActions.ping(pingUrl));
                _log.warn(() => ({
                    msg: "SignalR reconnected with connectionId",
                    connectionId,
                }));
            }
        );
        connection.onclose(
            /*istanbul ignore next*/ (error) => {
                actionChannel.put(networkActions.ping(pingUrl));
                _log.warn(() => ({
                    msg: "SignalR connection closed",
                    error,
                }));
            }
        );
        // cleanup when channel closes
        return /*istanbul ignore next*/ () =>
            connection &&
            typeof connection.close === "function" &&
            connection.close();
    }, buffers.expanding());
}

/* istanbul ignore next*/
function* getToken() {
    // try for max. 1sec * 30
    for (let i = 0; i < 30; i++) {
        const accessToken = yield select(s.loginAccessTokenSelector);
        if (accessToken != null) return accessToken;
        else yield call(delay, 1000);
    }
    _log.warn(
        () => "Cannot retrieve user token; no server pushed notifications!"
    );
    // throw new Error("Cannot retrieve token")
    return null;
}

function* notificationLoop(chan) {
    while (true) {
        try {
            // wait for incoming message
            const message = yield take(chan);
            // console.log("signalR message: ", message);
            // publish to Redux store for further handling
            yield put(serverEventAction(message));
        } finally {
            /* istanbul ignore if */
            if (yield cancelled()) {
                chan.close();
            }
        }
    }
}

/* istanbul ignore next */
function* actionLoop() {
    while (true) {
        const action = yield take(actionChannel);
        yield put(action);
    }
}

export default function* notificationsSaga() {
    // create a SignalR connection socket
    try {
        // get user's token
        const accessToken = yield call(getToken);
        // no token? no game
        /* istanbul ignore next */
        if (accessToken == null) {
            throw new Error("No Access Token");
        }
        const correlationId = yield select(s.userCorrelationIdSelector);
        const connection = yield call(createSocket, accessToken, correlationId);
        // create our Redux-Saga channel and register for client-side methods
        const chan = yield call(signalChannel, connection);

        // start listening
        connection
            .start()
            .then(
                /*istanbul ignore next*/ (result) =>
                    _log.info(() => "SignalR Connected!")
            )
            .catch(
                /*istanbul ignore next*/ (e) => {
                    throw e;
                }
            );

        yield all([notificationLoop(chan), actionLoop()]);
    } catch (e) {
        /* istanbul ignore next */
        _log.fatalException(() => "SignalR Connection failed: ", e);
    }
}
