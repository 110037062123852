import React from "react";
import Alert from "react-bootstrap/lib/Alert";
import loc from "i18next";
import styles from "./ExternalUser.module.css";

type Props = {
    value: Array<GroupPrincipalDTO | UserPrincipalDTO | ShareRecipientDTO>,
    onDelete?: () => void,
};

const ExternalUserViewerControl = ({ value, onDelete }: Props) => (
    <div className="form-group" data-test="ExternalUserViewerControl">
        <Alert bsClass="alert-share" className="alert" bsStyle="warning">
            <p
                dangerouslySetInnerHTML={{
                    __html: loc.t("external.inviteInfo"),
                }}
            />
            <div className={styles.list}>
                {value.map((user) => (
                    <div
                        key={`external.${user.email}`}
                        className={styles.label}
                    >
                        <span className={styles.name}>{user.displayName}</span>
                        {typeof onDelete === "function" && (
                            <span
                                className={styles.action}
                                onClick={() => onDelete(user.id)}
                            >
                                &times;
                            </span>
                        )}
                    </div>
                ))}
            </div>
        </Alert>
    </div>
);

ExternalUserViewerControl.displayName = "ExternalUserViewerControl";
export default ExternalUserViewerControl;
