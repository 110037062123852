// @flow
import React, { useCallback } from "react";
import Modal, { ModalSize } from "components/Modal";
import Button from "react-bootstrap/lib/Button";
import { createModal } from "react-modal-promise";
import styles from "./OptionsModal.module.css";
import Icons from "@hs/icons";
import { getLocalizedText } from "data/utils";

type Option = {
    /** unique identifier */
    eventKey: string,
    /** label to display, can be an optional i18n string @requires getLocalizedText */
    label: string,
    /** optional icon to render before the label */
    icon?: string,
    /** optional icon color */
    color?: string,
    /** optional flag for default values */
    isDefault?: boolean,
};

type Props = {
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog */
    close: () => void,
    /** options */
    options: Array<Option>,
    /** Modal Dialog title */
    title?: string,
    /** whether escape closes the window calling close(false) */
    requireSelection?: boolean,
};

/** presents a Confirm dialog to the user
 * @returns {string} eventKey of selected @param {{options: Array<Option>}} {options}, defaultOption/null otherwise, except if @param {{requireSelection?: bool}} {requireSelection} set to true
 */
export const OptionsModal = ({
    open,
    close,
    options,
    title = getLocalizedText("$common:options.title"),
    requireSelection = false,
}: Props) => {
    const defaultOption = options.find((o) => o.isDefault);
    const handleSelect = useCallback(
        (option = null) => {
            if (requireSelection && option == null) return;
            close(option ?? defaultOption?.eventKey);
        },
        [close, defaultOption, requireSelection]
    );
    return (
        <Modal
            size={ModalSize.Small}
            enforceFocus
            show={open !== false}
            onHide={handleSelect}
            dialogClassName={styles.modal}
            dataTest="confirmModal"
        >
            {title && (
                <Modal.Header closeButton={true}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body>
                <div className={styles.options}>
                    {options?.map((option) => (
                        <Button
                            className={styles.button}
                            key={option?.eventKey}
                            bsStyle="link"
                            onClick={() => {
                                handleSelect(option?.eventKey);
                            }}
                        >
                            {option?.icon && (
                                <Icons.Library
                                    name={option?.icon}
                                    style={{ color: option?.color }}
                                />
                            )}{" "}
                            {getLocalizedText(option?.label)}
                        </Button>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
};
OptionsModal.displayName = "OptionsModal";
export const openOptionsModal = createModal(OptionsModal);
