import React, { useContext, useMemo } from "react";
import { MultiSelectionContext } from "data/context";
import Mark from "@hs/mark";
import Icons from "@hs/icons";
import ItemStatusIcons from "components/ItemStatusIcons";
import { BrowserDragLink } from "components/ItemUriLink";
import ValueFactory from "components/Attributes/SummaryAttribute/ValueFactory";
import { DocListColumnType, GridListConstant } from "data/types";
import withStickyToggle from "containers/StickyToggle";
import StickyToggleStyle from "containers/StickyToggle/style";
import { isHandheld } from "data/bowser";
import { getClassNames } from "data/utils";
import styles from "./CellContent.module.css";

type Props = {
    index: number,
    col: DocColumnResultDTO,
    cell: any,
    filterText: any,
    onMoreClick: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    actionsDisabled: boolean,
    itemUri: string,
};

export const CellContent = ({
    index,
    col,
    cell,
    filterText,
    onMoreClick,
    itemUri,
    ext,
    isHovered,
}: Props): any => {
    /* istanbul ignore next */
    const { multiple, renderCheckbox } = useContext(MultiSelectionContext) || {
        multiple: false,
        renderCheckbox: () => null,
    };

    const { caption: header } = col;
    let content = cell;
    if (content == null) return null;
    if (header === GridListConstant.ExtensionIcon) {
        if (multiple && isHandheld()) {
            content = renderCheckbox(index, styles.checkbox);
        } else {
            content = (
                <>
                    {(isHovered || multiple) &&
                        !isHandheld() &&
                        renderCheckbox(
                            index,
                            getClassNames(styles.checkbox, styles.desktop)
                        )}
                    <BrowserDragLink
                        itemUri={itemUri}
                        className={getClassNames(
                            styles.link,
                            !isHandheld() && styles.desktop
                        )}
                    >
                        <Icons.Custom
                            ext={cell?.value || cell}
                            icon={cell?.icon}
                            color={cell?.color}
                            className={getClassNames(
                                styles.icon,
                                isHandheld() && styles.mobile,
                                "fa-fw"
                            )}
                            data-test={`fileExt${cell?.value || cell}`}
                        />
                    </BrowserDragLink>
                </>
            );
        }
    } else if (header === GridListConstant.IconItemStatus) {
        content = (
            <ItemStatusIcons
                itemUri={itemUri}
                ext={ext}
                itemStatus={cell}
                onMoreClick={
                    typeof onMoreClick === "function" ? onMoreClick : null
                }
                className={styles.icons}
            />
        );
    } else if (Object.prototype.hasOwnProperty.call(content, "$type")) {
        content = (
            <ValueFactory
                value={content}
                className={`a-val ${content.$type}--grid`}
            />
        );
    }

    if (col.type === DocListColumnType.BooleanValue) {
        return (
            <input
                type="checkbox"
                checked={content === "True"}
                className={styles.boolean}
                readOnly
            />
        );
    }
    if (header === GridListConstant.IconItemStatus) {
        return <>{content}</>;
    }
    if (
        [
            DocListColumnType.IntValue,
            DocListColumnType.LongValue,
            DocListColumnType.FileSize,
            DocListColumnType.ShortValue,
            DocListColumnType.DoubleValue,
            DocListColumnType.DecimalValue,
            DocListColumnType.SingleValue,
        ].includes(col.type)
    ) {
        return <span className={styles.number}>{content}</span>;
    }
    // #51803 filterText highlighting
    if (
        filterText &&
        filterText.name &&
        filterText.name.toUpperCase() === col.name.toUpperCase() &&
        filterText.value
    ) {
        return <Mark word={filterText.value}>{content}</Mark>;
    }
    return <>{content}</>;
};

const CellContentWithStickyToggle = withStickyToggle({
    getStyle: StickyToggleStyle.GridList,
    renderDiv: true,
    portalTargetSelector:
        "#docListPanel .ReactVirtualized__Grid__innerScrollContainer",
    // shouldRender: ({ col }) => {
    //     return !isHandheld() || col.caption === GridListConstant.ExtensionIcon;
    // },
})(CellContent);

export default CellContentWithStickyToggle;
