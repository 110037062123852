// @flow
import loc from "i18next";
import {
    DocListFilterOperators,
    type DocListFilterOperatorsEnum,
    SystemWebDavName,
} from "data/types";
// import template from "lodash/template";

/**
 * checks if value is included in list (case-insensitive)
 *
 * @param {string} value to search for in list
 * @param {Array<string>} list of string to search
 */
export const isListed = (value: string, list: Array<string>): boolean =>
    list.some((l) => l.toLowerCase() === value.toLowerCase());

/** returns file extension (with .)
 * @param {string} fileName the filename (without path)
 */
export const getFileExtension = (fileName: string): string =>
    fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 1);

/**
 * will replace the file extension in filename with newExt
 *
 * @param {string} filename the filename (without path) to fix
 * @param {string} newExt new file extension to assign (without .)
 * @returns {string} filename with the new extension
 * @memberof Upload
 */
export const replaceFileExtension = (
    filename: ?string,
    newExt: string
): string =>
    (filename || "").substr(
        0,
        (((filename || "").lastIndexOf(".") - 1) >>> 0) + 1
    ) +
    "." +
    newExt;

/** returns true if any of element's DOM parent has the className */
export const hasSomeParentTheClass = (
    element: any,
    className: string
): boolean => {
    //
    // If we are here we didn't find the searched class in any parents node
    //
    if (!element.parentNode) return false;
    //
    // If the current node has the class return true, otherwise we will search
    // it in the parent node
    /* istanbul ignore next */
    if (
        String(element.getAttribute("class")).split(" ").indexOf(className) >= 0
    )
        return true;
    return hasSomeParentTheClass(element.parentNode, className);
};

/**
 * will check if text starts with $ signaling our own internal i18next localization id
 * @param {string} text freetext or $loc.id
 * @returns {string} localized text
 */
export const getLocalizedText = (text: ?string, context?: any): ?string => {
    if (text && text.startsWith("$")) {
        return loc.t(text.substr(1), context);
    }
    return text;
};

export /**
 * @returns {boolean} true if itemUri starts with pam-item://
 * @param {string} itemUri to validate
 */
const isValidItemUri = (itemUri: string = "") =>
    itemUri.startsWith("pam-item://");

export /**
 * simple js string templates
 * https://gist.github.com/pbroschwitz/3891293
 * @param {string} input source string including reference variables enclosed in single {}
 * @param {Object} data object defining the variables
 * @returns result after replacing tokens
 */
const tokenize = (input: string, data: Object) =>
    input.replaceAll(/(?:([^?&=#]+)=)?{([^{}]*)}/g, (original, prop, key) => {
        const prefix = prop != null ? `${prop}=` : "";
        const joinWith = prop != null ? `&${prop}=` : ", ";
        switch (typeof data[key]) {
            case "object":
                return Array.isArray(data[key])
                    ? `${prefix}${data[key].join(joinWith)}`
                    : original;
            case "string":
            case "number":
                return `${prefix}${data[key]}`;
            default:
                return original;
        }
    });

// export /**
//  * js string templating
//  * @note although lodash.template is very powerful, there is no way to disable javascript execution - to avoid XSS cross script attacks!
//  * @param {string} input source string including reference variables
//  * @param {Object} data object defining the variables
//  * @returns compiled template result
//  */
// const interpolate = (input: string, data: Object) => {
//     // https://lodash.com/docs/4.17.15#template
//     const compiled = template(input, {
//         evaluate: null,
//         imports: null,
//         escape: /{-([\s\S]+?)}/g,
//         interpolate: /{([\s\S]+?)}/g
//     });
//     return compiled(data);
// };

export /**
 * map an object by changing it's values
 * @note although lodash.mapValues exists, it's 26KB big!
 * @param {Object} o input object
 * @param {(value: any) => any} f value transformation callback function
 * @returns {Object} transformed object with the same keys
 */
const mapValues = (o: Object, f: (value: any) => any) =>
    Object.entries(o).reduce((a, [key, value]) => {
        a[key] = Array.isArray(value)
            ? value.map((v) => f(v?.K ?? v?.V ?? v))
            : f(value?.K ?? value?.V ?? value);
        return a;
    }, {});

export /**
 * Moves an item in an array to a new position
 * @returns {Array<any>} cloned array with updated item's position
 */
const moveArrayItem = (arr: Array<any>, from: number, to: number) => {
    const clone = [...arr];
    Array.prototype.splice.call(
        clone,
        to,
        0,
        Array.prototype.splice.call(clone, from, 1)[0]
    );
    return clone;
};

export /** checks if browser support Caching */
const hasCacheSupport = () => "caches" in window;

export /** executes fn() and logs performance statistics */
const performanceTester = async (
    fn: () => Promise<any | null>,
    log: (ms: number) => void
): any | null => {
    /* istanbul ignore if */
    if (typeof fn !== "function")
        throw new Error("performanceTester function not defined!");
    const t0 = performance.now();
    const result = await fn();
    log(performance.now() - t0);
    return result;
};

// export const calcSha256 = async (message) => {
//     // encode as UTF-8
//     const msgBuffer = new TextEncoder().encode(message);

//     // hash the message
//     const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

//     // convert ArrayBuffer to Array
//     const hashArray = Array.from(new Uint8Array(hashBuffer));

//     // convert bytes to hex string
//     const hashHex = hashArray
//         .map((b) => ("00" + b.toString(16)).slice(-2))
//         .join("");
//     return hashHex;
// };

// https://advancedweb.hu/how-to-use-async-functions-with-array-filter-in-javascript/
export const asyncFilter = async (arr, predicate) =>
    arr.reduce(
        async (memo, e) => ((await predicate(e)) ? [...(await memo), e] : memo),
        []
    );

// https://advancedweb.hu/how-to-use-async-functions-with-array-some-and-every-in-javascript/
export const asyncEvery = async (arr, predicate) => {
    for (let e of arr) {
        if (!(await predicate(e))) return false;
    }
    return true;
};

export /* istanbul ignore next */ const sleep = (ms) =>
    new Promise((resolve) => setTimeout(resolve, ms));

export const getClassNames = (...classNames) =>
    classNames.filter((className) => className).join(" ");

export /* istanbul ignore next */ const closeMenuOnScroll = (e: Event) =>
    e.target &&
    !(
        e.target.getAttribute("class") &&
        e.target.getAttribute("class").match(/__menu-list/)
    );

export /*istanbul ignore next */ const getValue = (value, param = "value") =>
    value != null && Object.prototype.hasOwnProperty.call(value, "$type")
        ? value[param] || value.value
        : value;

export const parseSaveDocListFilterOperators = (
    value: ?string
): DocListFilterOperatorsEnum => {
    if (value == null) return DocListFilterOperators.None;
    return (
        Object.keys(DocListFilterOperators)
            .map((k) => ({
                key: k.toUpperCase(),
                value: DocListFilterOperators[k],
            }))
            .find((op) => op.key === value.toUpperCase())?.value ??
        DocListFilterOperators.None
    );
};

export const maybeLocalizeBreadcrumb = ({
    bcText,
    siteType,
    delimiter = "/",
}: {
    bcText: ?string,
    siteType: SiteTypeEnum,
    delimiter: string,
}) => {
    if (bcText == null) return "";
    const idx = bcText.indexOf(delimiter);
    if (idx === -1) return maybeHandleSiteName(bcText);
    return `${maybeHandleSiteName(
        bcText.substr(0, idx),
        siteType
    )}${bcText.substr(idx)}`;
};

export const maybeHandleSiteName = (text, siteType) =>
    text == null || Object.values(SystemWebDavName).some((s) => s === text)
        ? loc.t(`siteItemType.${String(siteType)}`)
        : text;
