import React from "react";
import { type Props } from "./Factory";
import ItemFlows from "components/ItemFlows";
import { NewFormType, ItemFlowTemplateType } from "data/types";
import { createFolderFromTemplate } from "data/api";
import { CheckJobStatus } from "components/CommandActions/createFolder_template";

const FolderTemplate = (props: Props) => {
    const _onCreate = (model) =>
        createFolderFromTemplate(
            props.flow.commandAction.props.templateId,
            props.itemUri,
            model.ip["$NAME$"]
        )
            .catch((err) => {
                props.onCreated(false, err);
                return null;
            })
            .then(
                /* istanbul ignore next */ (jobId) =>
                    jobId != null &&
                    CheckJobStatus({
                        jobId,
                        close: () => props.onCreated(true),
                    })
            );

    return (
        <ItemFlows.Form
            {...props}
            formType={NewFormType.Folder}
            templateType={ItemFlowTemplateType.FolderTemplate}
            templateId={props.flow.commandAction.props.templateId}
            onCreate={_onCreate}
        />
    );
};
FolderTemplate.displayName = "ItemFlowsInput.FolderTemplate";
export default FolderTemplate;
