import React, {
    useCallback,
    useState,
    useMemo,
    useEffect,
    useRef,
} from "react";
import { usePrevious } from "hooks";
import Formsy from "formsy-react";
import loc from "i18next";
import ShareActionEditorControl from "./EditorControl/ShareAction";
import SharePrincipalEditorControl from "./EditorControl/SharePrincipal";
import PrincipalEditorControl from "components/ExtraAttributes/EditorControl/PrincipalEditorControl";
import MemoEditorControl from "components/ExtraAttributes/EditorControl/MemoEditorControl";
import ExternalUserViewerControl from "./ViewerControl/ExternalUser";
import ShareActionViewerControl from "./ViewerControl/ShareAction";
import AttachmentsViewerControl from "./ViewerControl/Attachments";
import { getEnumIcon } from "./Utils";
import {
    AllowedShareActionFlags,
    ShareType,
    SharePrincipalType,
    type ShareTypeEnum,
    type ShareFormDTO,
    type EnumValue,
} from "data/types";

type Props = {
    items: Array<any>,
    share: ShareFormDTO,
    setCanSubmit: () => void,
    handleSave: () => void,
    handleChange: () => void,
    handleConfirm: (name: string, value: ShareTypeEnum) => Promise<boolean>,
    isAdvanced: boolean,
    isDoc: boolean,
    isEdit: boolean,
    attachments?: Array<ShareAttachment>,
    aclEnumValues?: Array<EnumValue<ShareAccessControlListTypeEnum>>,
    folderCount: number,
    docCount: number,
};

const ShareForm = ({
    items,
    share,
    setCanSubmit,
    handleSave,
    handleChange,
    handleConfirm,
    isAdvanced,
    setIsAdvanced,
    allowedShareActionFlags,
    aclEnumValues,
    isDoc,
    isEdit,
    attachments,
    itemKey,
    folderCount,
    docCount,
}: Props) => {
    const disableSubmit = useCallback(
        () => setCanSubmit(false),
        [setCanSubmit]
    );
    const enableSubmit = useCallback(() => setCanSubmit(true), [setCanSubmit]);
    const [isActionEdit, setIsActionEdit] = useState(false);
    const prevIsAdvanced = usePrevious(isAdvanced);
    const toggleFlag = useCallback((flag, setFlag) => setFlag(!flag), []);

    const newUserId = useRef(0);

    useEffect(() => {
        const principals = share.principals ?? [];
        // Only re-calculate principals if isAdvanced has changed
        if (prevIsAdvanced === isAdvanced) {
            return;
        }

        // Only re-calculate principals if there are any and NOT while editing
        if (!principals.length || isEdit) {
            return;
        }
        let changedPrincipals = [];
        if (isAdvanced) {
            changedPrincipals = principals.map((r) => ({
                principal: r,
                acl: null,
                $type: SharePrincipalType.Share,
            }));
        } else {
            changedPrincipals = principals
                .filter((r) => !!r.principal)
                .map((r) => r.principal);
        }
        handleChange(
            "principals",
            changedPrincipals.length > 0 ? changedPrincipals : null
        );
    }, [isAdvanced, prevIsAdvanced, handleChange, share.principals, isEdit]);

    // ExternalUser
    const externalUsers = useMemo(
        () =>
            (share.principals ?? [])
                .filter((r) =>
                    r && Object.prototype.hasOwnProperty.call(r, "principal")
                        ? r.principal && r.principal.id < 0
                        : r && r.id < 0
                )
                .map((r) =>
                    Object.prototype.hasOwnProperty.call(r, "principal")
                        ? r.principal
                        : r
                ),
        [share.principals]
    );
    const handleUserRemove = useCallback(
        (id: int) => {
            /* istanbul ignore next */
            const changedPrincipals = (share.principals ?? []).filter((r) =>
                Object.prototype.hasOwnProperty.call(r, "principal")
                    ? r.principal.id !== id
                    : r.id !== id
            );
            handleChange(
                "principals",
                changedPrincipals.length ? changedPrincipals : null
            );
        },
        [share.principals, handleChange]
    );

    const typeEnumValues = useMemo(
        (): Array<EnumValue<ShareTypeEnum>> =>
            Object.keys(ShareType)
                .filter(
                    (key) =>
                        ((allowedShareActionFlags &
                            AllowedShareActionFlags.Anonym) >
                            0 &&
                            ShareType[key] === ShareType.Anonym) ||
                        ((allowedShareActionFlags &
                            AllowedShareActionFlags.WebShare) >
                            0 &&
                            ShareType[key] === ShareType.WebShare) ||
                        ((allowedShareActionFlags &
                            AllowedShareActionFlags.Share) >
                            0 &&
                            ShareType[key] === ShareType.Share) ||
                        ((allowedShareActionFlags &
                            AllowedShareActionFlags.Link) >
                            0 &&
                            ShareType[key] === ShareType.Link) ||
                        (isDoc &&
                            (allowedShareActionFlags &
                                AllowedShareActionFlags.Attachment) >
                                0 &&
                            ShareType[key] === ShareType.Attachment) ||
                        (isDoc &&
                            (allowedShareActionFlags &
                                AllowedShareActionFlags.Pdf) >
                                0 &&
                            ShareType[key] === ShareType.Pdf)
                )
                .map((key) => ({
                    value: ShareType[key],
                    label: `$share:type.${ShareType[key]}`,
                    icon: getEnumIcon(ShareType[key]),
                })),
        [allowedShareActionFlags, isDoc]
    );

    const extras = useMemo(
        () => ({
            items,
            newUserIdRef: newUserId,
            type: share.action.type,
            acl: share.action.acl,
            aclEnumValues,
            typeEnumValues,
            count: folderCount + docCount,
            docCount,
            folderCount,
        }),
        [
            share.action.type,
            share.action.acl,
            aclEnumValues,
            typeEnumValues,
            items,
            folderCount,
            docCount,
        ]
    );

    return (
        <Formsy
            onValidSubmit={handleSave}
            onValid={enableSubmit}
            onInvalid={disableSubmit}
        >
            {isAdvanced ? (
                <>
                    <ShareActionEditorControl
                        key="advanced"
                        value={share.action}
                        name="action"
                        onChange={handleChange}
                        onConfirm={handleConfirm}
                        extras={extras}
                    />
                    <SharePrincipalEditorControl
                        baseClassName="has-rows"
                        key={`shareprincipal.${extras.type}`}
                        value={share.principals}
                        isRequired={true}
                        name="principals"
                        header={`${loc.t("share:principal.label")}*`}
                        onChange={handleChange}
                        extras={extras}
                        validationErrors={{
                            isDefaultRequiredValue: loc.t(
                                "common:validation.required"
                            ),
                        }}
                    />
                </>
            ) : (
                <>
                    {isActionEdit ? (
                        <ShareActionEditorControl
                            key="simple"
                            value={share.action}
                            name="action"
                            header={loc.t("share:action.header")}
                            onChange={handleChange}
                            onConfirm={handleConfirm}
                            extras={extras}
                            onCancel={() =>
                                toggleFlag(isActionEdit, setIsActionEdit)
                            }
                        />
                    ) : (
                        <ShareActionViewerControl
                            itemKey={itemKey}
                            value={share.action}
                            extras={extras}
                            onEdit={() =>
                                toggleFlag(isActionEdit, setIsActionEdit)
                            }
                        />
                    )}
                    <PrincipalEditorControl
                        header={`${loc.t("share:principal.label")}*`}
                        name="principals"
                        includeGroups={true}
                        isMulti={true}
                        isClearable={true}
                        isRequired={true}
                        createUser={true}
                        value={
                            /* istanbul ignore next */ (
                                share.principals ?? []
                            ).map((r) =>
                                /* istanbul ignore next */
                                r.$type === SharePrincipalType.Share
                                    ? r.principal
                                    : r
                            )
                        }
                        onChange={handleChange}
                        newUserIdRef={newUserId}
                        validationErrors={{
                            isDefaultRequiredValue: loc.t(
                                "common:validation.required"
                            ),
                        }}
                        autoFocus={true}
                    />
                </>
            )}
            {share.action.type === ShareType.Share &&
                externalUsers.length > 0 && (
                    <ExternalUserViewerControl
                        value={externalUsers}
                        onDelete={handleUserRemove}
                    />
                )}
            {share.action.type !== ShareType.Share && (
                <MemoEditorControl
                    header={loc.t("share:message.label")}
                    name="message"
                    placeholder={loc.t("share:message.placeholder")}
                    isRequired={false}
                    value={share.message}
                    onChange={handleChange}
                />
            )}
            {share.action.type === ShareType.Pdf && (
                <AttachmentsViewerControl
                    name="attachments"
                    value={attachments}
                    ext=".pdf"
                    showBytes={false}
                />
            )}
            {share.action.type === ShareType.Attachment && (
                <AttachmentsViewerControl
                    name="attachments"
                    value={attachments}
                />
            )}
        </Formsy>
    );
};

ShareForm.displayName = "ShareForm";
export default ShareForm;
