import React, { useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import * as s from "data/reducers/selectors";
import { UploadMode, NewFormType, ItemFlowTemplateType } from "data/types";
import DocumentSelection from "./DocumentSelection";
import { type Props } from "./Factory";
import ItemFlows from "components/ItemFlows";
import { produce } from "immer";
import { replaceFileExtension } from "data/utils";
import Divider from "components/Divider";
import CountBadge from "components/CountBadge";
import loc from "i18next";
import styles from "./itemFlow_document.module.css";

const Document = (props: Props) => {
    const {
        defaultValues,
        itemUri,
        onCreated,
        files: filesFromProps,
        viewName,
        flow,
    } = props;

    const isOnline = useSelector(s.isOnlineSelector);
    const [files, setFiles] = useState([]);
    // we'll keep a reference to the uppy instance created by <DocumentUpload>
    const uppyRef = useRef(null);

    const _updateFiles = useCallback(
        () => setFiles(uppyRef.current.getFiles()),
        []
    );

    /**
     * will try to get the first filename available
     * @returns {string} filename or null if nothing found
     * @memberof Upload
     */
    const _getWebDavNameHint = useCallback(() => {
        const firstFile = files.find((f) => f.meta && f.meta.itemUri == null);
        return firstFile
            ? firstFile.meta.name /* istanbul ignore next */
            : null;
    }, [files]);

    /**
     * will return defaultValues to pass on to form
     * @returns defaultValues or null if no filename found
     * @memberof Upload
     */
    const _getDefaultValues = useCallback(
        () =>
            produce(defaultValues || {}, (draft) => {
                const WebDavName = _getWebDavNameHint();
                /* istanbul ignore else */
                if (WebDavName) draft["$NAME$"] = WebDavName;
            }),
        [defaultValues, _getWebDavNameHint]
    );

    const _onCreate = useCallback(
        async (
            model,
            keepOriginalFilename: boolean = false,
            formatId: number
        ) => {
            // COPY from views/upload/_onSaveNew()
            files.forEach((file) => {
                // has itemUri set (from previous failure?)
                /* istanbul ignore else */
                if (file.meta.itemUri == null) {
                    // #46997 let's fix the file extension
                    // HACK
                    const newModel = produce(model, (draft) => {
                        draft.ip["$NAME$"] = replaceFileExtension(
                            model.ip["$NAME$"], // first filename
                            file.extension // current real extension
                        );
                    });
                    // attach classification form (ip/ea) to each file's metadata (managed server-side by TusMiddleware.cs)
                    uppyRef.current.setFileMeta(file.id, {
                        itemUri,
                        formatId,
                        mode: UploadMode.CreateDocument,
                        model: JSON.stringify(newModel),
                        keepOriginalFilename,
                    });
                }
            });
            try {
                const result = await uppyRef.current.upload();
                return onCreated(
                    !isOnline || result.failed.length === 0,
                    null,
                    null,
                    !isOnline || result.failed.length === 0
                );
            } catch (err) {
                return onCreated(false, err);
            }
        },
        [files, isOnline, itemUri, onCreated]
    );

    return (
        <>
            <div
                className={styles.wrapper}
                style={{
                    flexGrow: files.length === 0 ? 1 : 0,
                }}
            >
                <Divider
                    className={styles.divider}
                    header={
                        <>
                            {loc.t("itemflows:toggles.file")}{" "}
                            {!!files.length && (
                                <CountBadge count={files.length} />
                            )}
                        </>
                    }
                    name="upload"
                    expanded={files.length === 0}
                    unmountOnExit={false}
                    mountOnEnter={false}
                >
                    <div>
                        <DocumentSelection
                            ref={uppyRef}
                            uppyId={viewName}
                            onFileAdded={_updateFiles}
                            onFileRemoved={_updateFiles}
                            openCustomUrl={flow.commandAction.props.url}
                            openBrowseDialog={
                                flow.commandAction.props.openBrowseDialog
                            }
                            openCamera={flow.commandAction.props.openCamera}
                            numOfFiles={files.length}
                            files={filesFromProps}
                        />
                    </div>
                </Divider>
            </div>
            {files.length > 0 && (
                <ItemFlows.Form
                    {...props}
                    formType={NewFormType.Document}
                    templateType={ItemFlowTemplateType.Document}
                    templateId={flow.commandAction.props.templateId}
                    defaultValues={_getDefaultValues()}
                    onCreate={_onCreate}
                />
            )}
        </>
    );
};
Document.displayName = "ItemFlowsInput.Document";
export default Document;
