import { openPickerModal, PickerType } from "components/PickerModal";
import { SearchScope, type SearchScopeEnum } from "data/types";
import loc from "i18next";

/**
 * Change scope of current searchForm
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name: string }} { name }
 * @param {{ onSearchScopeChange: function }} { onSearchScopeChange }
 */

type Context = {
    itemUri: string,
    name: string,
    onSearchScopeChange: (
        searchScope: SearchScopeEnum,
        itemUri: ?string
    ) => void,
};

class search_scope {
    execute = async ({ itemUri, name, onSearchScopeChange }: Context) => {
        // let targetName = name;
        const targetItemUri = await openPickerModal({
            pickerType: PickerType.Folder,
            scopeBreadcrumb: false,
            itemUri,
            canChangeSite: true,
            helpText: loc.t("commandAction:search_scope.helpText", { name }),
            selectButton: loc.t("commandAction:search_scope.name"),
        });
        if (targetItemUri === false) return false;
        try {
            onSearchScopeChange(SearchScope.CurrentFolder, targetItemUri);
            return true;
        } catch (error) {
            return false;
        }
    };
}

export default search_scope;
