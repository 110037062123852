import actionTypes from "data/actions/actionTypes";
import Constants, { type ConstantsEnum } from "data/constants";
import immutable from "object-path-immutable";
import { type docsResultState, type docFilterText } from "./types";

const initialState: docsResultState = {
    status: Constants.NONE,
    error: undefined,
    filters: [],
    sorts: [],
    totalCount: 0,
    rowCount: 0,
    pageNum: 0,
    pageSize: 0,
    itemUri: null,
    rows: {},
    cols: [],
    filterText: null,
};

const docsReducer = (state: docsResultState = initialState, action) => {
    switch (action.type) {
        case actionTypes.CURRENTITEM_SET:
            if (
                action.payload.name !== "docmodal" &&
                action.payload.name !== "itemflows" &&
                action.payload.itemUri !== state.itemUri
            )
                return { ...initialState };
            else return state;

        case actionTypes.DOCS_FILTERTEXT_SET:
            return { ...state, filterText: action.payload };

        case actionTypes.DOCS_FETCH_REQUEST:
            if (action.payload.itemUri !== state.itemUri) {
                return { ...initialState, ...action.payload };
            }
            return {
                ...state,
                ...action.payload,
            };

        case actionTypes.DOCS_FETCH_RESULT:
            switch (action.payload.status) {
                case Constants.ERROR:
                    return {
                        ...initialState,
                        itemUri: state.itemUri,
                        status: action.payload.status,
                        error: action.payload.error,
                        receivedAt: action.payload.receivedAt,
                    };
                case Constants.OK:
                    if (state.pageNum <= 0) {
                        return {
                            ...state,
                            // req specific
                            totalCount: action.payload.totalCount,
                            status: action.payload.status,
                            error: action.payload.error,
                            receivedAt: action.payload.receivedAt,

                            rowCount: action.payload.result.rowCount,
                            ...action.payload.result,
                        };
                    } else {
                        return {
                            // kept
                            cols: state.cols,
                            // TODO remove filters/sorts - should be retrieved from DocLayout!
                            filters: state.filters,
                            sorts: state.sorts,
                            itemUri: state.itemUri,
                            pageSize: state.pageSize,
                            totalCount: state.totalCount,
                            pageNum: state.pageNum,
                            // req specific
                            status: action.payload.status,
                            error: action.payload.error,
                            receivedAt: action.payload.receivedAt,

                            rowCount: action.payload.result.rowCount,
                            rows: immutable.assign(
                                state,
                                "rows",
                                action.payload.result.rows
                            ).rows,
                        };
                    }

                /* istanbul ignore next */
                default:
                    return {
                        ...state,
                        ...action.payload,
                    };
            }

        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default docsReducer;

export const docsItemUriSelector = (state: Object): string =>
    state.docs.itemUri;
export const docsStatusSelector = (state: Object): ConstantsEnum =>
    state.docs.status;
export const docsPageNumSelector = (state: Object): ConstantsEnum =>
    state.docs.pageNum;
export const docsErrorSelector = (state: Object) => state.docs.error;
export const docsColsSelector = (state: Object) => state.docs.cols;
export const docsRowsSelector = (state: Object) => state.docs.rows;
export const docsFilterTextSelector = (state: Object): docFilterText =>
    state.docs.filterText;
export const docsTotalCountSelector = (state: Object): number =>
    state.docs?.totalCount ?? 0;
export const docsFiltersSelector = (state: Object) => state.docs.filters;
export const docsSortsSelector = (state: Object) => state.docs.sorts;
