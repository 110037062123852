import { openConfirmModal } from "components/ConfirmModal";
import { delDefiniteItemUri } from "data/api";
import loc from "i18next";
import { toastActionResult } from "data/toast";

/**
 * Permanently deletes an folder from the Recycle Bin by itemUri
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name: string }} { name }
 */

type Context = {
    itemUri: string,
    name: string,
};

class recyclebin_folderClear {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;
    shouldRender: boolean = true;

    render = async ({ name }: Context): Promise<boolean> => {
        this.shouldRender = false;
        return await openConfirmModal({
            body: loc.t("commandAction:recyclebin_folderClear.confirmBody", {
                name,
            }),
            confirmButton: loc.t(
                "commandAction:recyclebin_folderClear.confirmYes"
            ),
        });
    };

    execute = async ({ itemUri }: Context): Promise<boolean> => {
        try {
            return await delDefiniteItemUri(itemUri);
        } catch (err) {
            console.warn(err);
            return false;
        }
    };

    report = async (success: boolean, { name }: Context): Promise<boolean> => {
        this.logger(success === true, "commandAction:recyclebin_folderClear", {
            name,
        });
    };
}

export default recyclebin_folderClear;
