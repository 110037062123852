import { fetchForm } from "data/api";

const stringForFormTokens = (uri: string): string =>
    uri.replaceAll("{ea.", "{ea_").replaceAll("{ip.", "{ip_"); // hack since tokenize does not support sub-objects

const parseFieldsOrAttributes = (
    fields,
    shouldURIEncode = false,
    prefix = "ea_"
) => {
    const rawValues = {};
    if (fields && fields.length > 0) {
        fields.forEach(
            (field) => (rawValues[prefix + field.name] = field.value)
        );
    }
    /* istanbul ignore else */
    if (shouldURIEncode) {
        Object.keys(rawValues).forEach((key) => {
            const value = rawValues[key];
            rawValues[key] = Array.isArray(value)
                ? value.map((value) =>
                      encodeURIComponent(value?.K ?? value?.V ?? value)
                  )
                : encodeURIComponent(value?.K ?? value?.V ?? value);
        });
    }
    return rawValues;
};

const withFormTokens = async (
    context: any,
    otherContext: any = {},
    shouldURIEncode = false
) => {
    try {
        if (context.itemUri) {
            const form = await fetchForm(context.itemUri);
            const rawValues = {
                ...parseFieldsOrAttributes(form.fields, shouldURIEncode, "ip_"),
                ...parseFieldsOrAttributes(
                    form.attributes,
                    shouldURIEncode,
                    "ea_"
                ),
            };
            return Object.assign(otherContext, rawValues);
        }
    } catch (e) {
        console.error("Error while withFormTokens", e);
    }
    return otherContext;
};

export { stringForFormTokens, withFormTokens, parseFieldsOrAttributes };
