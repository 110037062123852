import React, { useMemo } from "react";
import Alert from "react-bootstrap/lib/Alert";
import loc from "i18next";
import styles from "./ShareAction.module.css";
import Icons from "@hs/icons";
import { getShareDate } from "components/ShareModal/Utils";

type Props = {
    isDoc: boolean,
    value: ShareActionDTO,
    onEdit: () => void,
    extras?: any,
};

const ShareActionViewerControl = ({
    itemKey,
    value,
    onEdit,
    extras = {},
}: Props) => {
    const { type, expires, acl } = value;
    const { count, docCount, folderCount } = extras;
    const [identifier, context] = useMemo(
        () => [
            `${type}.${itemKey}`,
            {
                acl: loc.t(`share:acl.${acl}`),
                date: getShareDate(expires),
                count,
                document: loc.t(`share:name.document`, { count: docCount }),
                folder: loc.t(`share:name.folder`, { count: folderCount }),
            },
        ],
        [type, expires, acl, itemKey, count, docCount, folderCount]
    );
    return (
        <div className="form-group" data-test="ShareActionViewerControl">
            <Alert
                bsClass="alert-share"
                className={`alert ${styles.info}`}
                onClick={onEdit}
            >
                <Icons.Library name="caret-right" fixedWidth />{" "}
                {loc.t(`share:action.${identifier}`, context)}
            </Alert>
        </div>
    );
};

ShareActionViewerControl.displayName = "ShareActionViewerControl";
export default ShareActionViewerControl;
