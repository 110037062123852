// @flow
import React from "react";
import Dropdown from "@hs/dropdown";
import bowser from "data/bowser";
import Name from "./Name";
import Header from "./Header";
import AllItem from "./AllItem";
import MenuItem from "./MenuItem";
import Button from "./Button";
import Icon from "./Icon";
import { SORT_BUCKET_NAME, type SearchBucketDTO } from "data/types";

export const getSelected = (
    selected: Array<string | number>
): string | number | null =>
    selected != null &&
    Array.isArray(selected) &&
    selected.length > 0 &&
    selected[0] != null &&
    String(selected[0]).length > 0
        ? selected[0]
        : null;

type Props = {
    item: SearchBucketDTO,
    i: number,
    onSelect: (name: string, value: Object) => void,
    isDisabled: boolean,
};

const SearchBucket = ({ item, i, onSelect, isDisabled }: Props) => {
    const selected: Array<string | number> = item.selected || [];

    // custom button without menu
    /* istanbul ignore else */
    if (item.buckets.length === 0) {
        if (item.hideMenu === true) {
            return (
                <SearchBucket.Button
                    key={`b-${i}`}
                    item={item}
                    selected={selected}
                    onSelect={onSelect}
                    disabled={isDisabled}
                />
            );
        } else return null;
    }

    return (
        <Dropdown
            bsSize="small"
            key={`sb-${i}`}
            id={`sb-${i}`}
            disabled={isDisabled}
        >
            <Dropdown.Toggle
                chrome
                style={{
                    fontWeight: selected.length > 0 ? "bold" : "normal",
                    fontStyle: selected.length > 0 ? "normal" : "italic",
                }}
            >
                <SearchBucket.Name item={item} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {bowser.is("mobile") && <SearchBucket.Header item={item} />}
                {getSelected(selected) != null &&
                    item.name !== SORT_BUCKET_NAME &&
                    item.hideAllReset !== true && (
                        <SearchBucket.AllItem item={item} onSelect={onSelect} />
                    )}
                {item.buckets.map((bucket, i) => (
                    <SearchBucket.MenuItem
                        key={`bv-${i}`}
                        name={item.name}
                        item={bucket}
                        selected={selected}
                        onSelect={onSelect}
                    />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
SearchBucket.displayName = "FilterBar.SearchBucket";
SearchBucket.Name = Name;
SearchBucket.Header = Header;
SearchBucket.AllItem = AllItem;
SearchBucket.MenuItem = MenuItem;
SearchBucket.Button = Button;
SearchBucket.Icon = Icon;

export default SearchBucket;
