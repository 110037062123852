// @flow
import React, { useState, useEffect } from "react";
import { useEffectOnce } from "hooks";
import { Location } from "history";
import queryString from "data/queryString";
import { fetchSearchSuggestion } from "data/api";
import {
    type ApiError,
    type SearchFilterDTO,
    SearchValueType,
} from "data/types";
import Constants, { getDisplayName } from "data/constants";
import Status from "components/Status";
import PageStatus from "components/PageStatus";
import loc from "i18next";

type Props = {
    /** React-router history object */
    location: Location,
    /** Redux-state current itemUri */
    itemUri: string,
};

type Config = {
    /** whether we expect a doc ItemUri */
    docSearchProvider: boolean,
};

export const isSearchParam = (p: string): boolean => {
    if (p == null) return false;
    const pSplit = p.split(".");
    if (pSplit.length === 1) {
        if (["searchtext", "advancedsearchtext"].includes(p)) return true;
        else return false;
    }
    return [
        "leafnode",
        "doc",
        "item",
        "allnodes",
        "folder",
        "itemany",
    ].includes(pSplit[0]);
};

/**
 * HOC to handle searching for an itemUri by fulltext search filters passed as URL query parameters,
 * will then pass the result itemUri
 * @param {ComponentType} WrappedComponent children to render
 */
const SuggestionHandler =
    ({ docSearchProvider = false }: Config) =>
    (WrappedComponent: ComponentType<any>) => {
        const HOC = ({ location, ...props }: Props) => {
            const itemUriFromLocation =
                "pam-item://" + queryString.parse(location.search).uri;
            const [status, setStatus] = useState(Constants.LOADING);
            const [errorDetail, setErrorDetail] = useState(null);
            const [itemUri, setItemUri] = useState(null);
            useEffectOnce(() => {
                if (itemUriFromLocation !== "pam-item://undefined") {
                    setStatus(Constants.OK);
                    setItemUri(itemUriFromLocation);
                    return;
                }
                const qs = queryString.parse(location.search);
                // any specific provider or use default
                let provider = "";
                /* istanbul ignore else */
                if (qs.p) {
                    provider = qs.p;
                    delete qs.p;
                }
                // remove secureParams
                /* istanbul ignore else */
                if (qs.sp) {
                    delete qs.sp;
                }
                // anything else should be a suggestion filter (/parameter)
                const filters: Array<SearchFilterDTO> = Object.keys(qs)
                    .filter((p) => isSearchParam(p.toLowerCase()))
                    .map((p) => ({
                        name: p,
                        value: qs[p],
                        valueType: SearchValueType.NotSet,
                    }));
                // should we return a Doc ItemUri or a Folder one?
                if (provider.length === 0 && docSearchProvider === true)
                    provider = "DocSearchProvider";

                fetchSearchSuggestion(provider, filters)
                    .catch((err: ApiError) => {
                        if (
                            err.data &&
                            err.data.eventId &&
                            err.data.eventId.id ===
                                1540 /*SEARCH_SUGGESTION_NOTFOUND*/
                        ) {
                            setStatus(Constants.ERROR);
                            setErrorDetail(
                                "Not Found! Please contact your administrator."
                            );
                        }
                        return null;
                    })
                    .then((itemUri) => {
                        if (
                            itemUri !== null &&
                            String(itemUri).length > 0 &&
                            String(itemUri).startsWith("pam-item://")
                        ) {
                            setStatus(Constants.OK);
                            setItemUri(itemUri);
                        } else {
                            setStatus(Constants.ERROR);
                        }
                    });
            });
            useEffect(() => {
                if (
                    status === Constants.OK &&
                    itemUri != null &&
                    itemUriFromLocation !== "pam-item://undefined"
                ) {
                    setItemUri(itemUriFromLocation);
                }
            }, [itemUriFromLocation, itemUri, status]);
            switch (status) {
                case Constants.OK:
                    // passthrough overriding with our suggested itemUri
                    return (
                        <WrappedComponent
                            {...props}
                            location={location}
                            itemUri={itemUri}
                        />
                    );
                case Constants.ERROR:
                    return errorDetail == null ? (
                        <Status status={status} />
                    ) : (
                        <PageStatus status={status} message={errorDetail} />
                    );
                default:
                    return (
                        <PageStatus
                            status={status}
                            message={loc.t("common:search.loading")}
                        />
                    );
            }
        };
        HOC.displayName = `SuggestionHandler(${getDisplayName(
            WrappedComponent
        )})`;
        return HOC;
    };

export default SuggestionHandler;
