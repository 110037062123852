import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import * as s from "data/reducers/selectors";
import { createGlobalState } from "react-use";
import Button from "react-bootstrap/lib/Button";
import { isFeatureOn, Feature } from "data/constants";
import Icons from "@hs/icons";
import loc from "i18next";
import CommandActionHandler from "components/CommandActions";
import { toastStyled, dismissToast, toastId } from "data/toast";
import { NotificationStyle } from "data/types";

const useDismissNetworkToast = createGlobalState(false);

const useOfflineHandler = ({ itemUri, name, request = null }) => {
    const [error, setError] = useState(null);
    const [dismiss, setDismiss] = useDismissNetworkToast();
    const isOnline = useSelector(s.isOnlineSelector);

    useEffect(() => {
        if (isOnline) {
            dismissToast(toastId.NoNetwork);
            setDismiss(false);
        }
    }, [dismiss, setDismiss, isOnline]);

    const handleDismissClick = useCallback(
        () => setDismiss(true),
        [setDismiss]
    );
    const handleEnableOffline = useMemo(
        () =>
            /* istanbul ignore else */
            itemUri
                ? () =>
                      CommandActionHandler("offline_add", {
                          itemUri,
                          name: name || loc.t("offline.pretty.noname"),
                      })
                : null,
        [itemUri, name]
    );

    const details = useMemo(
        () =>
            function OfflineErrorDetails() {
                return (
                    <>
                        {isFeatureOn(Feature.offline) &&
                            typeof handleEnableOffline === "function" && (
                                <Button
                                    bsSize="small"
                                    onClick={handleEnableOffline}
                                >
                                    <Icons.Library name="power-off" />{" "}
                                    {loc.t("offline.pretty.enable")}
                                </Button>
                            )}
                        <Button
                            bsSize="small"
                            bsStyle="link"
                            className="pull-right"
                            onClick={handleDismissClick}
                        >
                            {loc.t("offline.pretty.dismiss")}
                        </Button>
                    </>
                );
            },
        [handleEnableOffline, handleDismissClick]
    );

    const factory = useCallback(
        (fetchRequest) =>
            (...args) =>
                fetchRequest(...args)
                    .then((response) => {
                        dismissToast(toastId.NoNetwork);
                        setDismiss(false);
                        setError(null);
                        return response;
                    })
                    .catch((e) => {
                        setError(e);
                        if (
                            e.constructor.name === "ApiNetworkOfflineError" &&
                            !dismiss
                        ) {
                            toastStyled({
                                icon: "link-slash",
                                style: NotificationStyle.Warning,
                                autoClose: false,
                                details,
                                toastId: toastId.NoNetwork,
                                message: "$offline.pretty.msg",
                            });
                        }
                        throw e;
                    }),
        [details, dismiss, setDismiss]
    );

    return useMemo(
        () => ({
            error,
            factory: request ? undefined : factory,
            request: request ? factory(request) : undefined,
        }),
        [error, factory, request]
    );
};

export { useOfflineHandler };
