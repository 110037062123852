// @flow
import { type ComponentType } from "react";
import { type Location } from "history";

// global const
const Constants = {
    NONE: "",
    OK: "OK",
    ERROR: "ERROR",
    LOADING: "LOADING",
    REFRESH: "REFRESH",
};

export default Constants;

export type ConstantsEnum = $Keys<typeof Constants>;

// used to give a nice displayName to HOCs
export const getDisplayName = (WrappedComponent: ComponentType<any>) =>
    WrappedComponent.displayName || WrappedComponent.name || "Component";

// finds an appsettings defaults config given an itemUri
export const getDefaultsConfig = (
    itemUri: string,
    formatId: number
): Object | null => {
    if (
        itemUri &&
        window.CONFIG.general.defaults &&
        window.CONFIG.general.defaults.length > 0
    ) {
        // search by itemUri
        const defaults = window.CONFIG.general.defaults.find((item) => {
            let found = false;
            // check itemUri
            if (item.exactItemUri !== false) found = item.itemUri === itemUri;
            else found = itemUri.startsWith(item.itemUri);
            if (found) return found;
            // check formatIds
            if (formatId && formatId !== -1)
                found = item.formatIds && item.formatIds.includes(formatId);
            return found;
        });
        if (defaults != null) {
            return defaults;
        }

        // is there a global default (no itemUri nor formatIds)?
        const globalDefaults = window.CONFIG.general.defaults.find(
            (item) => item["itemUri"] == null && item["formatIds"] == null
            //item => !Object.prototype.hasOwnProperty.call(item, "itemUri")
        );
        /* istanbul ignore else */
        if (globalDefaults != null) {
            return globalDefaults;
        }
    }
    return null;
};

// Feature Toggles

export const Feature = {
    archiveTargetsWithItemFlows: "archiveTargetsWithItemFlows",
    homeActions: "homeActions",
    mailService: "mailService",
    noteService: "noteService",
    offline: "offline",
    rasterMapService: "rasterMapService",
    reminderService: "reminderService",
    searchAsYouType: "searchAsYouType",
    searchCalendar: "searchCalendar",
    searchFacets: "searchFacets",
    searchStatistics: "searchStatistics",
    shareService: "shareService",
    templateService: "templateService",
    uppyLexmark: "uppyLexmark",
    workflowService: "workflowService",
};
export type FeatureEnum = $Values<typeof Feature>;

export const isFeatureOn = (name: FeatureEnum) =>
    get(window.CONFIG, ["general", "features"], []).includes(name);

/**
 * easy null-checked way to access a deep object
 * @link https://medium.com/javascript-inside/safely-accessing-deeply-nested-values-in-javascript-99bf72a0855a
 * @note could have used lodash._get but this is enough for our needs
 * @param {Object} o the object itself
 * @param {Array<string>} p array of properties to drill down
 * @param {any} d default value in case on the way a null is encountered
 */
export const get = (o, p, d) =>
    p.reduce((xs, x) => (xs != null && xs[x] != null ? xs[x] : d), o);

export /**
 * checks if running in "mini" view, of an optional viewName
 *
 * @param {?Location} location
 * @param {?string} viewName
 */
const isMiniView = (location: ?Location, viewName = null) =>
    location?.pathname?.includes(`/mini/${viewName ?? ""}`) ?? false;

export /**
 * checks if running in "secureParams" mode
 *
 * @param {?Location} location
 */
const isSecureParams = (location: ?Location) =>
    location != null ? location.search.indexOf("sp=") >= 0 : false;
