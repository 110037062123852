// @flow
import React from "react";
import Button from "react-bootstrap/lib/Button";
import Icons from "@hs/icons";
import loc from "i18next";

type Props = {
    /** callback when user clicks on More... Button */
    onClick?: () => void,
    /** optional CSS Class to apply */
    className?: string,
    /** whether to render the button in a disabled state */
    disabled?: boolean,
    /** additional css styling */
    style?: CSStyleDefinition,
    /** Integration Test identifier */
    dataTest?: string,
};

const ActionsButton = ({
    onClick,
    className,
    disabled,
    style = { color: "black" },
    dataTest = "actionsButton",
    ...props
}: Props) => (
    <Button
        bsStyle="link"
        className={className}
        onClick={onClick}
        disabled={disabled}
        title={loc.t("common:actionsButton")}
        style={style}
        data-test={dataTest}
        {...props}
    >
        <Icons.Library name="fa-regular fa-ellipsis-vertical" />
    </Button>
);
ActionsButton.displayName = "ActionsButton";
export default ActionsButton;
