// @flow
import React, { useMemo, memo } from "react";
import Form from "react-bootstrap/lib/Form";
import Row from "react-bootstrap/lib/Row";
import "./index.css";
import EAForm from "components/ExtraAttributes/EAForm";
import NoData from "components/NoData";
import LocalContactInfo from "components/LocalContactInfo";
import { type FormResultDTO, type DocFormResultDTO } from "data/types";
import { LabelSizeContext } from "data/context";
import { getClassNames } from "data/utils";
import { getFields } from "./utils";

type Props = {
    form: FormResultDTO | DocFormResultDTO,
    itemUri: string,
    isNoDataError: boolean,
};

const MyForm = memo(({ form, itemUri, isNoDataError }: Props) => {
    const fields = useMemo(() => getFields(form, itemUri), [form, itemUri]);
    const fieldsLength = useMemo(
        () => fields?.filter((field) => field).length,
        [fields]
    );

    const hasEA = useMemo(() => form?.attributes != null, [form]);
    return (
        <Form
            className={getClassNames(
                "form-inline",
                "ip-form",
                hasEA ? "has-ea" : "no-ea"
            )}
            onSubmit={/* istanbul ignore next */ (e) => e.preventDefault()}
            data-test="form"
        >
            <LabelSizeContext.Provider value={form.labelWidth}>
                {form?.fields != null &&
                    fieldsLength > 0 &&
                    fields.map(
                        (field, index) =>
                            field && (
                                <Row
                                    key={form.fields[index].id}
                                    data-test={`formRow${form.fields[index].name}`}
                                >
                                    <field.Component {...field.props} />
                                </Row>
                            )
                    )}
                {hasEA && (
                    <EAForm
                        itemUri={itemUri}
                        formatId={form.formatId}
                        formTitle={form.formTitle}
                        layoutXml={form.layoutXml}
                        attributes={form.attributes}
                    />
                )}
                {(form?.fields === null || fieldsLength === 0) &&
                    form?.attributes === null && (
                        <NoData locContext="form">
                            {isNoDataError && <LocalContactInfo />}
                        </NoData>
                    )}
            </LabelSizeContext.Provider>
        </Form>
    );
});

export default MyForm;
