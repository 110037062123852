import ip_commandAction from "./ip_commandAction";

/**
 * opens inPoint.Client to edit to the specified itemUri
 *
 * @param {{ itemUri: string }} { itemUri }
 */

class ip_edit extends ip_commandAction {
    execute = ({ itemUri }: { itemUri: string }) =>
        super.execute({ itemUri, commandAction: "DocumentOpenCommandAction" });
}

export default ip_edit;
