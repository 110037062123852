import { downloadDocUri, fetchDownloadContext } from "data/api";
import loc from "i18next";
import { notificationDelAction } from "data/actions";
import { dispatch } from "data/storeHelper";
import { toastActionResult, toastStyled } from "data/toast";

/**
 * starts a download for the given itemUri
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name?: string }} { name }
 * @param {{ ext?: string }} { ext }
 */

type Context = {
    itemUri: string,
    name?: string,
    ext?: string,
};

export const downloadFile = async (uri, fileName): Promise<boolean> => {
    try {
        const resource = await fetch(uri);
        const blob = await resource.blob();
        const href = URL.createObjectURL(blob);
        const temporaryDownloadLink = document.createElement("a");
        temporaryDownloadLink.setAttribute("href", href);
        temporaryDownloadLink.setAttribute("target", "_blank");
        temporaryDownloadLink.setAttribute("download", fileName);
        temporaryDownloadLink.click();
        URL.revokeObjectURL(href);
        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
};

class doc_download {
    static toastId = "doc_download";
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;
    fileNameWithExtension = ({ name, ext }: Context) =>
        name.endsWith(ext) ? name : `${name}${ext}`;
    maybeAddContext = async (context: Context): Promise<Context> =>
        context.ext == null || context.name == null
            ? {
                  ...(await fetchDownloadContext(context.itemUri)),
                  ...context,
              }
            : context;

    execute = async (context: Context): Promise<boolean> => {
        // Since we do not have a progress bar for the download, we show a toast notification instead.
        toastStyled({
            message: loc.t("commandAction:doc_download.info"),
            toastId: doc_download.toastId,
        });
        // We might need to fetch the name and extension from the server. (when called with itemUri only)
        const { itemUri, name, ext } = await this.maybeAddContext(context);
        try {
            const uri = await downloadDocUri(itemUri);
            if (uri == null)
                throw new Error("Error while creating Download URI (empty)");
            return await downloadFile(
                uri,
                this.fileNameWithExtension({ name, ext })
            );
        } catch (err) {
            return false;
        } finally {
            dispatch(notificationDelAction(doc_download.toastId)); // no notification needed, remove from notification center.
        }
    };
    report = async (success: boolean, { name }: Context): Promise<boolean> => {
        this.logger(success, "commandAction:doc_download", { name, count: 1 });
        return success;
    };
}
export default doc_download;
