import { useMemo } from "react";
import { usePostMessageHandler } from "./Handler";
import { ModalAction } from "./Actions";

const useModalPostMessageHandler = (close) =>
    usePostMessageHandler(
        useMemo(
            () => ({
                [ModalAction.Close]: close,
                [ModalAction.CloseLegacy]: close,
            }),
            [close]
        )
    );

export { useModalPostMessageHandler };
