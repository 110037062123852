/**
 * toggle scope of current form
 */

type Props = {
    showSearchScope: SettingValueProps,
};

class searchForm_scope {
    execute = ({ showSearchScope }: Props) => {
        if (showSearchScope == null) return false;
        const { value, setValue } = showSearchScope;
        setValue(!value);
        return true;
    };
}
export default searchForm_scope;
