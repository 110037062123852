// @flow
import React from "react";
import { useToggle } from "hooks";
import Button from "react-bootstrap/lib/Button";
import Icons from "@hs/icons";
import { getClassNames } from "data/utils";

type Props = {
    /** title to show on hover */
    title: string,
    /** whether the narrow state is disabled */
    toggled: boolean,
    /** icon to show instead of components */
    icon: string,
    /** label to show next to icon */
    label: string,
    /** whether to render narrow layout (icon only, input on focus) */
    narrow: boolean,
    /** react component to render icon in, defaults to Bootstrap Button */
    Component: ?any,
};

const NarrowToggle = ({
    className,
    title,
    toggled,
    icon,
    label,
    narrow,
    children,
    Component = Button,
}: Props) => {
    const [isToggled, setIsToggled] = useToggle(toggled);
    return narrow && !isToggled ? (
        <Component
            className={getClassNames(className, "narrowtoggle")}
            title={title}
            onClick={() => setIsToggled(true)}
        >
            <Icons.Library name={icon} />{" "}
            <span className="narrowtoggle__label">{label}</span>
        </Component>
    ) : (
        <>
            {children}
            {narrow && (
                <Component
                    className={className}
                    title={title}
                    onClick={() => setIsToggled(false)}
                >
                    <Icons.Library name="xmark" />
                </Component>
            )}
        </>
    );
};

export default NarrowToggle;
