import { openShareModal } from "components/ShareModal";
import { fetchShareAttachment, fetchShareContext } from "data/api";
import {
    AllowedShareActionFlags,
    ItemStatuses,
    type ItemStatusesDTO,
    type ShareTypeEnum,
    type ShareAttachment,
} from "data/types";
import { toastActionResult } from "data/toast";
import { getEnumIcon } from "components/ShareModal/Utils";
import loc from "i18next";

type Context = {
    itemUri: string,
    formatId: number,
    name: string,
    itemStatus: ItemStatusesDTO,
    isDoc: boolean,
    isEdit: boolean,
    shareType?: ShareTypeEnum,
    multiple?: boolean,
    items?: Array<any>,
};

class share {
    constructor() {
        this.execute = this.execute.bind(this);
        this.getAttachment = this.getAttachment.bind(this);
    }

    logger: () => any = toastActionResult;

    async getAttachment(itemUri: string): ShareAttachment {
        try {
            return await fetchShareAttachment(itemUri);
        } catch {
            console.error(`Error while fetching document context (${itemUri})`);
            return null;
        }
    }

    getModalProps({ multiple, isDoc, name, items }) {
        if (multiple) {
            const docCount = items.filter((i) => i.isDoc).length;
            const folderCount = items.length - docCount;
            const itemKey =
                folderCount && docCount
                    ? "mixed"
                    : docCount
                    ? "document"
                    : "folder";
            return {
                docCount,
                folderCount,
                itemKey,
                name: loc.t(`share:name.${itemKey}`, {
                    document: loc.t(`share:name.document`, { count: docCount }),
                    folder: loc.t(`share:name.folder`, { count: folderCount }),
                    count: docCount + folderCount,
                }),
            };
        }
        return {
            docCount: isDoc ? 1 : 0,
            folderCount: isDoc ? 0 : 1,
            name,
            itemKey: isDoc ? "document" : "folder",
        };
    }

    /**
     * Opens Modal to (update/create) Share for folder/document
     *
     * @param {{ itemUri: string }} { itemUri }
     * @param {{ formatId: number }} { formatId }
     * @param {{ name: string }} { name }
     * @param {{ itemStatus?: ItemStatusesDTO }} { itemStatus }
     * @param {{ isDoc?: bool }} { isDoc }
     * @param {{ isEdit?: bool }} { isEdit }
     * @param {{ shareType?: ShareTypeEnum }} { shareType }
     * @param {{ items?: Array<any> }} { items }
     * @param {{ multiple?: bool }} { multiple }
     */
    async execute({
        itemUri,
        formatId,
        name,
        itemStatus = ItemStatuses.Nothing,
        isDoc = false,
        isEdit = false,
        shareType,
        items,
        multiple,
    }: Context) {
        const itemUris = [];
        const formatIds = [];
        let attachments = [];
        if (multiple) {
            for (let item of items) {
                itemUris.push(item.itemUri);
                formatIds.push(item.formatId ?? -1);
                item.isDoc &&
                    attachments.push(await this.getAttachment(item.itemUri));
            }
        } else {
            itemUris.push(itemUri);
            formatIds.push(formatId ?? -1);
            isDoc && attachments.push(await this.getAttachment(itemUri));
        }
        attachments = attachments.filter((a) => !!a);
        const {
            allowedActions:
                allowedShareActionFlags = AllowedShareActionFlags.Default,
            allowedAcl,
            shareType: shareTypeFromContext,
        } = await fetchShareContext(itemUris, formatIds);
        const aclEnumValues = allowedAcl?.map((value) => ({
            value,
            label: `$share:acl.${value}`,
            icon: getEnumIcon(value),
        }));

        // bail if no share action is available
        /* istanbul ignore else */
        if (
            !(
                (allowedShareActionFlags & AllowedShareActionFlags.Link) > 0 ||
                (allowedShareActionFlags & AllowedShareActionFlags.Share) > 0 ||
                (allowedShareActionFlags & AllowedShareActionFlags.Attachment) >
                    0 ||
                (allowedShareActionFlags & AllowedShareActionFlags.Pdf) > 0 ||
                (allowedShareActionFlags & AllowedShareActionFlags.Anonym) >
                    0 ||
                (allowedShareActionFlags & AllowedShareActionFlags.WebShare) > 0
            )
        ) {
            this.logger(false, "share:action_allowed", {
                name,
            });
            return;
        }

        await openShareModal({
            itemUris,
            items: items || [{ itemUri, isDoc, name }],
            isEdit: isEdit && (itemStatus & ItemStatuses.Share) > 0,
            canEdit: (itemStatus & ItemStatuses.Share) > 0,
            isDoc,
            allowedShareActionFlags,
            aclEnumValues,
            attachments: attachments.length && attachments,
            shareType: shareType || shareTypeFromContext?.toLowerCase(),
            ...this.getModalProps({ isDoc, name, items, multiple }),
        });
        return true;
    }
}
export default share;
