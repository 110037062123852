import ArchiveTarget from "./ArchiveTarget";
import Edit from "./Edit";
import LockInfo from "./LockInfo";
import Search from "./Search";
import SearchScope from "./SearchScope";
import View from "./View";

const FormBarItem = {
    ArchiveTarget,
    Edit,
    LockInfo,
    Search,
    SearchScope,
    View,
};

export default FormBarItem;
