// @flow
import React from "react";
import Icons from "@hs/icons";
import Button from "react-bootstrap/lib/Button";
import loc from "i18next";
import { openMapModal } from "components/MapModal";
import {
    ParseLocation,
    ParseRaster,
    ParseArea,
} from "components/MapViewer/utils";
import {
    SupportGeoMapViewer,
    getGeoMapViewerProvider,
    getGeoRasterProvider,
} from "components/MapViewer/providerSupport";
import { type MarkerDTO } from "data/types";

type Props = {
    /** current itemUri */
    itemUri: string,
    /** current formatId */
    formatId: number,
    /** location point or area in string format */
    value: string,
    /** marker name to show on map */
    label: string,
    /** whether to render button as disabled */
    disabled?: boolean,
    /** CSS style to apply to button */
    style?: CSSStyleDeclaration,
    /** optional (custom) location marker icon */
    marker?: MarkerDTO,
};

const MapButton = ({
    itemUri,
    formatId,
    value,
    label,
    disabled,
    style,
    marker,
}: Props) => {
    const showOnMap = () => {
        // area?
        if (value.includes(";")) {
            const area = ParseArea(value);
            openMapModal({
                title: label,
                mapProvider: getGeoMapViewerProvider(),
                useMarker: false,
                locations: [
                    {
                        attribute: label,
                        label,
                        area,
                    },
                ],
            });
        } else if (value.includes(":")) {
            // raster
            const loc = ParseRaster(value);
            openMapModal({
                title: label,
                mapProvider: getGeoRasterProvider(itemUri, formatId),
                useMarker: true,
                locations: [
                    {
                        attribute: label,
                        label,
                        allowEdit: false,
                        icon: marker,
                        ...loc,
                    },
                ],
            });
        } else {
            // exactly one point
            const loc = ParseLocation(value);
            openMapModal({
                title: label,
                mapProvider: getGeoMapViewerProvider(),
                useMarker: true,
                locations: [
                    {
                        attribute: label,
                        label,
                        allowEdit: false,
                        icon: marker,
                        ...loc,
                    },
                ],
            });
        }
    };
    if (!SupportGeoMapViewer()) return null;
    return (
        <Button
            style={style}
            disabled={value == null || String(value).length === 0 || disabled}
            title={loc.t("common:location.open")}
            onClick={showOnMap}
        >
            <Icons.Library name="earth-europe" />
        </Button>
    );
};
MapButton.displayName = "MapButton";
export default MapButton;
