// @flow
//#region imports
import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    type Node,
} from "react";
import { useEffectOnce, useUpdateEffect, useRecycleBin } from "hooks";
import { History } from "history";
import { withRouter } from "react-router";
import TogglesHandler from "containers/TogglesHandler";
import styles from "./document.module.css";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import queryString from "data/queryString";
import MainLayout from "components/MainLayout";
import FormContainer from "containers/Form";
import DocViewerContainer from "containers/DocViewer";
import ActivitiesContainer from "containers/Activities";
import {
    setCurrentItemAction,
    setCurrentBreadcrumb,
    backupCurrentItemAction,
    restoreCurrentItemAction,
    formSetEditModeAction,
} from "data/actions";
import {
    RefreshMode,
    ServerEventItemType,
    ServerEventActionType,
    ItemContextKey,
    type ToggleDTO,
    type ToggleItemDTO,
} from "data/types";
import GlobalEventsHandler from "containers/GlobalEventsHandler";
import CommandActionHandler from "components/CommandActions";
import Constants, { isMiniView } from "data/constants";
import SplitPanes from "containers/SplitPanes";
import loc from "i18next";
// import FloatingBarList from "components/FloatingBar/FloatingBarList";
import FileDropHandler, { type DroppedFile } from "containers/FileDropHandler";
import { toastStyled, toastTypes } from "data/toast";
import {
    check_isSyncUpload,
    check_licenseAllowEdit,
} from "containers/ActionPanel/ShouldBeVisible";

import SuggestionHandler from "containers/SuggestionHandler";
import Pane from "components/SplitPanes/Pane";
import Status from "components/Status";
import withItemContext from "containers/ItemContext";
import withItemUri from "containers/ItemUri";
import withViewName from "containers/ViewName";
import ItemContextProvider from "containers/ItemContext/Provider";
import {
    breadcrumbVersionSelector,
    formIsEditModeSelector,
    breadcrumbFormatIdSelector,
} from "data/reducers/selectors";
//#endregion

//#region flow types
type Props = {
    /** React-router History */
    history: History,
    /** withItemUri: either prop or by location */
    itemUri: string,
    /** whether shown as Modal */
    asModal?: boolean,
    /** React Node to show before toolbar */
    topRowBefore?: Node,
    /** React Node to show after toolbar */
    topRowEnd?: Node,
    /** callback when user wants to see previous doc */
    onPrev?: Function,
    /** callback when user wants to see next doc */
    onNext?: Function,
    // from TogglesHandler
    /** TogglesHandler: set current toggles */
    setToggles: Function,
    /** TogglesHandler: current toggles */
    toggles: ?ToggleDTO,
    /** Redux: current itemUri */
    itemUri: string,
    /** Redux: breadcrumb itemUri version */
    version?: number,
    /** whether current document is a favorite */
    isFav: boolean,
    /** current document name */
    docName: string,
    /** current viewName */
    viewName: string,
    /** whether running under Windows OS */
    onWindows: boolean,
    /** whether trigger edit mode on mount  */
    triggerEditMode: boolean,
    /** TogglesHandler: currently selected toggles' names */
    selectedToggles: Array<string>,
    /** SplitPanes callback to set split sizes */
    setSplitSizes: Function,
    /**pdf.js: search terms */
    searchTerm?: string,
    /** pdf.js: whether trigger browser print */
    triggerPrint: boolean,
};
//#endregion

export const Document = ({
    viewName,
    itemUri,
    refreshId,
    isDocClassificationEditAllowed,
    isEditMode,
    triggerEditMode: triggerEditModeFromProps,
    isFlatAU,
    itemStatus: itemStatusFromProps,
    toggles,
    setToggles,
    setSplitSizes,
    searchTerm: searchTermFromProps,
    triggerPrint: triggerPrintFromProps,
    asModal,
    topRowBefore,
    topRowEnd,
    onPrev,
    onNext,
    history: { location },
}: Props) => {
    const formatId = useSelector((state) =>
        breadcrumbFormatIdSelector(state, viewName)
    );
    const versionFromStore = useSelector((state) =>
        breadcrumbVersionSelector(state, viewName)
    );
    const isMini = isMiniView(location);
    const formInEditMode = useSelector((state) =>
        formIsEditModeSelector(state, viewName)
    );
    const [version, setVersion] = useState(versionFromStore || 0);
    const [versionedItemUri, setVersionedItemUri] = useState(null);

    const qs = queryString.parse(location.search);

    const searchTerm = qs.mark ?? searchTermFromProps;
    const triggerPrint =
        Object.prototype.hasOwnProperty.call(qs, "print") ||
        !!triggerPrintFromProps;
    const triggerEditModeFromPropsOrQs =
        Object.prototype.hasOwnProperty.call(qs, "edit") ||
        !!triggerEditModeFromProps;
    const [triggerEditMode, setTriggerEditMode] = useState(
        triggerEditModeFromPropsOrQs
    );

    const dispatch = useDispatch();

    useEffectOnce(() => {
        dispatch(backupCurrentItemAction());
        return () => {
            dispatch(setCurrentBreadcrumb({ breadcrumb: {}, viewName }));
            dispatch(formSetEditModeAction("doc", false));
            dispatch(restoreCurrentItemAction());
        };
    });

    // Trigger Edit Mode
    useUpdateEffect(() => {
        if (!triggerEditMode) return;
        if (isDocClassificationEditAllowed) {
            /* istanbul ignore else */
            if (!isEditMode) {
                dispatch(formSetEditModeAction("doc", true));
                setTriggerEditMode(false);
            }
        } else {
            toastStyled({
                message: loc.t("document:edit_form.notAuthorized"),
                ...toastTypes.failure,
            });
        }
    }, [isEditMode, isDocClassificationEditAllowed, triggerEditMode, dispatch]);

    useEffect(() => {
        setVersion(versionFromStore);
    }, [versionFromStore]);

    useEffect(() => {
        setVersion(0);
        setVersionedItemUri(null);
        setTriggerEditMode(!!triggerEditModeFromPropsOrQs);
        dispatch(formSetEditModeAction("doc", false));
        dispatch(
            setCurrentItemAction({
                name: viewName,
                itemUri,
            })
        );
    }, [viewName, itemUri, refreshId, triggerEditModeFromPropsOrQs, dispatch]);
    useEffect(() => {
        dispatch(
            setCurrentItemAction({
                name: viewName,
                itemUri,
            })
        );
    }, [viewName, itemUri, dispatch]);

    const handleDrop = useCallback(
        (files?: Array<DroppedFile>) => {
            CommandActionHandler("doc_uploadVersion", {
                files,
                itemUri,
            });
        },
        [itemUri]
    );

    const handleCancelForm = useCallback(
        () => dispatch(formSetEditModeAction("doc", false)),
        [dispatch]
    );

    const handleShowVersion = useCallback(
        (version: number, itemUri: string) => {
            setVersion(version);
            setVersionedItemUri(itemUri);
        },
        []
    );

    const [, checkIfDeleted, checkIfAnyParentDeleted, checkIfInRecycleBin] =
        useRecycleBin(itemUri, itemStatusFromProps);

    //#region ItemContextProvider effects
    const effects = useMemo(
        () => ({
            [ItemContextKey.Toggles]: (toggles) => setToggles(toggles),
        }),
        [setToggles]
    );
    //#endregion

    const itemUriWithVersion = versionedItemUri || itemUri;
    if (isMini && checkIfInRecycleBin()) {
        return (
            <Status status={Constants.ERROR} message={loc.t("doc_deleted")} />
        );
    }
    return (
        <ItemContextProvider
            itemUri={itemUriWithVersion}
            refreshId={refreshId}
            name="wrapper"
            effects={effects}
        >
            {(itemContext) => (
                <MainLayout
                    name={viewName}
                    toggles={toggles}
                    contextProps={itemContext}
                    itemUri={itemUriWithVersion}
                    actionsDisabled={
                        formInEditMode ||
                        (checkIfAnyParentDeleted(itemContext.itemStatus) &&
                            !checkIfDeleted(itemContext.itemStatus))
                    }
                    breadcrumbIsRecycleBin={checkIfInRecycleBin(
                        itemContext.itemStatus
                    )}
                    breadcrumbReadonly={asModal === true || isMini === true}
                    topRowBefore={topRowBefore}
                    topRowEnd={topRowEnd}
                    className={itemContext.isFlatAU ? "flatAU" : ""}
                >
                    <Pane
                        id="viewerPanel"
                        dataTest="viewerPanel"
                        className={`${styles.split} ${styles.viewer}`}
                    >
                        <DocViewerContainer
                            itemUri={itemUriWithVersion}
                            searchTerm={searchTerm}
                            triggerPrint={triggerPrint}
                            onPrev={onPrev}
                            onNext={onNext}
                            name="viewer"
                        />
                    </Pane>
                    <div
                        data-test="documentRightPanel"
                        id="drightPanel"
                        className={`${styles.split} ${styles.right}`}
                    >
                        <Pane id="docformPanel" className={styles.form}>
                            {itemContext.isFlatAU === false && (
                                <FileDropHandler
                                    addFilesToUppy={false}
                                    relativePath={itemUriWithVersion}
                                    allowDrop={
                                        check_licenseAllowEdit() &&
                                        !check_isSyncUpload({
                                            itemUri: itemUriWithVersion,
                                            formatId,
                                        }) &&
                                        !formInEditMode &&
                                        itemContext.isDocEditAllowed &&
                                        !checkIfInRecycleBin()
                                    }
                                    onDrop={handleDrop}
                                    dragOverText={loc.t(
                                        "itemActions:doc_uploadVersion.name"
                                    )}
                                    className={styles.filedrop}
                                >
                                    <FormContainer
                                        itemUri={itemUriWithVersion}
                                        viewName={viewName}
                                        isEditMode={formInEditMode}
                                        onCancel={handleCancelForm}
                                    />
                                </FileDropHandler>
                            )}
                        </Pane>
                        <Pane id="docActivityPanel" className={styles.activity}>
                            {itemContext.isFlatAU === false && (
                                <ActivitiesContainer
                                    key="activities"
                                    itemUri={itemUri}
                                    formatId={formatId}
                                    currentVersion={version}
                                    onShowVersion={handleShowVersion}
                                    isReadOnly={
                                        !!checkIfInRecycleBin(
                                            itemContext.itemStatus
                                        )
                                    }
                                />
                            )}
                        </Pane>
                    </div>
                </MainLayout>
            )}
        </ItemContextProvider>
    );
};

export const mapViewerName = ({ asModal }: Props) =>
    asModal ? "docmodal" : "document";

export const DocumentWithoutHash = compose(
    withRouter,
    withViewName({
        mapper: mapViewerName,
    }),
    withItemUri,
    TogglesHandler({}),
    GlobalEventsHandler({
        items: [ServerEventItemType.document],
        actions: [ServerEventActionType.delete],
        refreshMode: RefreshMode.Originator,
        refreshMessage: loc.t("globalEvents:document.delete"),
        refreshRoute: false,
    }),
    SplitPanes({
        paneIds: [
            "#viewerPanel",
            "#drightPanel",
            "#docformPanel",
            "#docActivityPanel",
        ],
        toggleIds: ["viewer", "form", "act"],
    }),
    withItemContext({
        asProps: [
            ItemContextKey.IsFlatAU,
            ItemContextKey.ItemStatus,
            ItemContextKey.IsDocClassificationEditAllowed,
        ],
    })
)(Document);

export default compose(
    withRouter,
    withViewName({
        mapper: mapViewerName,
    }),
    withItemUri,
    SuggestionHandler({ docSearchProvider: true }),
    TogglesHandler({}),
    GlobalEventsHandler({
        items: [ServerEventItemType.document],
        actions: [ServerEventActionType.delete],
        refreshMode: RefreshMode.Originator,
        refreshMessage: loc.t("globalEvents:document.delete"),
        refreshRoute: false,
    }),
    SplitPanes({
        paneIds: [
            "#viewerPanel",
            "#drightPanel",
            "#docformPanel",
            "#docActivityPanel",
        ],
        toggleIds: ["viewer", "form", "act"],
    }),
    withItemContext({
        asProps: [
            ItemContextKey.IsFlatAU,
            ItemContextKey.ItemStatus,
            ItemContextKey.IsDocClassificationEditAllowed,
        ],
    })
)(Document);
