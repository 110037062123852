import store from "data/store";
import * as s from "data/reducers/selectors";
import { persistor } from "data/store";
import { SiteType } from "data/types";

export const isOnline = () => s.isOnlineSelector(store.getState());

export const cardsCards = () => s.cardsCardsSelector(store.getState());

export const docsItemUri = () => s.docsItemUriSelector(store.getState());

export const currentItemUri = () => s.currentItemUriSelector(store.getState());

export const accessToken = () => s.loginAccessTokenSelector(store.getState());

export const correlationId = () =>
    s.userCorrelationIdSelector(store.getState());

export const sitesSelector = () => s.userSitesSelector(store.getState());

export const globalSitesAreaSelector = () =>
    sitesSelector().find(
        (s) =>
            s.siteType === SiteType.GlobalSites && s.webDavName === "GLOBALSITE"
    );

export const userLanguage = () => s.userLanguageSelector(store.getState());

export const settingValue = (name) =>
    s.settingValueSelector(name, store.getState());

export const swRegistrationSelector = () =>
    s.swRegistrationSelector(store.getState());

export const dispatch = (action) => store.dispatch(action);

export const getNotification = (notificationId) =>
    s.notificationSelector(store.getState(), notificationId)?.[0] || {};

export { persistor };
