// @flow
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Constants, {
    getDefaultsConfig,
    type ConstantsEnum,
} from "data/constants";
import {
    fetchActivities,
    addItemNote,
    updateItemNote,
    deleteItemNote,
    delDocVersion,
    restoreDocVersion,
} from "data/api";
import Activities from "components/Activities";
import Status from "components/Status";
import {
    ServerEventActionType,
    ServerEventItemType,
    type UserInfoResultDTO,
    type ActivitiesDTO,
} from "data/types";
import GlobalEventsHandler from "containers/GlobalEventsHandler";
import { openConfirmModal } from "components/ConfirmModal";
import loc from "i18next";
import { toastActionResult } from "data/toast";
import CommandActionHandler from "components/CommandActions";
import moment from "moment";
import Moment from "containers/Moment";
import withOfflineHandler from "containers/OfflineHandler";
import { userLanguage } from "data/storeHelper";

type Props = {
    isDoc: boolean,
    /** the current itemUri */
    itemUri: ?string,
    /** the current formatId */
    formatId: ?number,
    /** Redux: the current User object */
    user: UserInfoResultDTO,
    /** GlobalEventsHandler.refreshId */
    refreshId: number,
    /** which version should be selected
     * 0 to show latest
     */
    currentVersion?: number,
    /** callback when user wants to see a specific version
     * @param {number} version which version to display
     * @param {number} itemUri itemUri including version
     */
    onShowVersion?: (version: number, itemUri: string) => void,
    /** whether to allow adding notes
     * @default {boolean} true
     */
    showAddNote?: boolean,
    /** whether to show notes (comment) entries
     * @default {boolean} true
     */
    showNotes?: boolean,
    /** whether to show history entries
     * @default {boolean} true
     */
    showHistory?: boolean,
    /** whether to show history menu
     * @default {boolean} true
     */
    showActions?: boolean,
    /** whether to show retention info
     * @default {boolean} true
     */
    showRetentionInfo?: boolean,
    /** whether activities are read-only
     * @default {boolean} false
     */
    isReadOnly?: boolean,
};

type State = {
    status: ConstantsEnum,
    result: ?ActivitiesDTO,
    showDetails: boolean,
    /** whether to allow adding notes
     * @default {boolean} true
     */
    showAddNote?: boolean,
    /** whether to show notes (comment) entries
     * @default {boolean} true
     */
    showNotes?: boolean,
    /** whether to show history entries
     * @default {boolean} true
     */
    showHistory?: boolean,
    /** whether to show history menu
     * @default {boolean} true
     */
    showActions?: boolean,
    /** whether to show retention info
     * @default {boolean} true
     */
    showRetentionInfo?: boolean,
};

// TODO this will need refactoring once inPoint implemented Folder versioning
export class ActivitiesContainer extends PureComponent<Props, State> {
    state = {
        status: Constants.LOADING,
        result: null,
        showDetails: false,
        showAddNote: !!this.props.showAddNote,
        showNotes: !!this.props.showNotes,
        showHistory: !!this.props.showHistory,
        showActions: !!this.props.showActions,
        showRetentionInfo: !!this.props.showRetentionInfo,
    };

    static defaultProps = {
        currentVersion: 0,
        isReadOnly: false,
        showAddNote: true,
        showNotes: true,
        showHistory: true,
        showActions: true,
        showRetentionInfo: true,
    };

    constructor(props: Props) {
        super(props);
        moment.locale(userLanguage());
        this.format = `${moment.localeData().longDateFormat("L")} ${moment
            .localeData()
            .longDateFormat("LTS")}`;
    }

    componentDidMount = () => {
        this._fetch();
        this._setActivityListDefaults();
    };

    componentDidUpdate(prevProps: Props) {
        /* istanbul ignore else */
        if (prevProps.itemUri !== this.props.itemUri) {
            this.setState(
                {
                    status: Constants.LOADING,
                    result: null,
                },
                () => {
                    this._fetch();
                    this._setActivityListDefaults();
                }
            );
        } else if (prevProps.refreshId !== this.props.refreshId) {
            this.setState(
                {
                    status: Constants.LOADING,
                },
                () => this._fetch()
            );
        }
    }

    _fetch() {
        const { itemUri, offlineHandler } = this.props;
        if (itemUri != null) {
            offlineHandler
                .factory(fetchActivities)(itemUri)
                .catch((err) => {
                    this.setState({
                        status: Constants.ERROR,
                    });
                    return null;
                })
                .then((result: ActivitiesDTO) => {
                    if (result != null) {
                        this.setState({
                            status: Constants.OK,
                            result,
                        });
                    }
                });
        }
    }

    _setActivityListDefaults = () => {
        const defaults = getDefaultsConfig(
            this.props.itemUri,
            this.props.formatId
        );
        if (
            defaults == null ||
            !Object.prototype.hasOwnProperty.call(defaults, "activityList")
        ) {
            return;
        }
        this.setState({ ...defaults.activityList });
    };

    _onAddNote = (activity: ActivityDTO) => {
        if (this.props.itemUri == null) {
            toastActionResult(false, "activity:note.notallowed");
            return new Promise((resolve) => resolve(false));
        }
        activity.itemUri = this.props.itemUri;
        return addItemNote(activity)
            .then(() => true)
            .catch((err) => {
                console.warn(err);
                toastActionResult(false, "activity:note.add");
                return false;
            });
    };

    /* add additional checks here (e.g. date based) */
    _canUserUpdateOrDelete = (activity: ActivityDTO): boolean =>
        activity != null &&
        this.props.itemUri === activity.itemUri &&
        this.props.user.id === activity.userId;

    _onUpdateNote = (activity: ActivityDTO): Promise<boolean> => {
        activity.itemUri = this.props.itemUri;
        if (!this._canUserUpdateOrDelete(activity)) {
            toastActionResult(false, "activity:note.notallowed");
            return new Promise((resolve) => resolve(false));
        }
        return updateItemNote(activity)
            .then(() => true)
            .catch((err) => {
                console.warn(err);
                toastActionResult(false, "activity:note.update");
                return false;
            });
    };

    _onDeleteNote = (activity: ActivityDTO): Promise<boolean> => {
        activity.id = activity.id && Math.abs(activity.id);
        activity.itemUri = this.props.itemUri;
        if (!this._canUserUpdateOrDelete(activity)) {
            toastActionResult(false, "activity:note.notallowed");
            return new Promise((resolve) => resolve(false));
        }
        return openConfirmModal({
            body: loc.t("activity:note.delete.confirmBody"),
            confirmButton: loc.t("activity:note.delete.confirmYes"),
        }).then(async (confirmed) => {
            /* istanbul ignore else */
            if (confirmed === true) {
                try {
                    const deleted = await deleteItemNote(
                        this.props.itemUri,
                        activity.id
                    );
                    if (!deleted)
                        toastActionResult(false, "activity:note.delete");
                    return deleted;
                } catch (err) {
                    console.warn(err);
                    toastActionResult(false, "activity:note.delete");
                    return false;
                }
            }
            return false;
        });
    };

    _toggleDetails = () =>
        this.setState((prevState: State) => ({
            showDetails: !prevState.showDetails,
        }));

    _onAction = (actionId: string, payload: any) => {
        const activity =
            (typeof payload === "number" && this.state.result.items[payload]) ||
            {};
        const version = activity.id;
        const itemUriWithVersion = activity.itemUri;
        switch (actionId) {
            case "view":
                /* istanbul ignore else */
                if (typeof this.props.onShowVersion === "function")
                    this.props.onShowVersion(version, itemUriWithVersion);
                break;
            case "download":
                CommandActionHandler("doc_download", {
                    itemUri: itemUriWithVersion,
                });
                break;
            case "popout":
                CommandActionHandler("doc_preview", {
                    itemUri: itemUriWithVersion,
                    newWindow: true,
                });
                break;
            case "details":
                this._toggleDetails();
                break;
            case "restore":
                openConfirmModal({
                    body: loc.t(
                        "docactivity_context:action_restore.confirmBody"
                    ),
                }).then((confirmed) => {
                    if (confirmed === true) {
                        this.restoreVersion(version);
                    }
                });
                break;
            case "delete":
                openConfirmModal({
                    body: loc.t(
                        "docactivity_context:action_delete.confirmBody"
                    ),
                    confirmButton: loc.t("common:delete"),
                }).then((confirmed) => {
                    if (confirmed === true) {
                        this.deleteVersion(version);
                    }
                });
                break;
            case "add_note":
                return this._onAddNote(payload);
            case "update_note":
                return this._onUpdateNote(payload);
            case "delete_note":
                return this._onDeleteNote(activity);
            /* istanbul ignore next */
            default:
                console.warn(actionId);
                break;
        }
    };

    restoreVersion = (version: number) => {
        this.setState({ status: Constants.LOADING }, () => {
            restoreDocVersion(this.props.itemUri, version)
                .catch((err) => {
                    console.warn(err);
                    return null;
                })
                .then((success) => {
                    // notify user
                    toastActionResult(
                        success === true,
                        "docactivity_context:action_restore"
                    );
                    // refresh
                    if (success === true) {
                        // show new @latest version
                        /* istanbul ignore else */
                        if (typeof this.props.onShowVersion === "function")
                            this.props.onShowVersion(0, this.props.itemUri);
                        // refetch activity list
                        this._fetch();
                    } else {
                        this.setState({
                            status: Constants.ERROR,
                        });
                    }
                });
        });
    };

    deleteVersion = (version: number) => {
        this.setState({ status: Constants.LOADING }, () => {
            delDocVersion(this.props.itemUri, version)
                .catch((err) => {
                    console.warn(err);
                    return null;
                })
                .then((success) => {
                    // notify user
                    toastActionResult(
                        success === true,
                        "docactivity_context:action_delete"
                    );
                    // refresh
                    if (success === true) {
                        // in case it was selected reset to latest
                        /* istanbul ignore else */

                        if (
                            this.props.currentVersion === version &&
                            typeof this.props.onShowVersion === "function"
                        ) {
                            this.props.onShowVersion(0, this.props.itemUri);
                        }
                        // re-fetch activity list
                        this._fetch();
                    } else {
                        this.setState({
                            status: Constants.ERROR,
                        });
                    }
                });
        });
    };

    render() {
        const { status, result } = this.state;
        if (result != null && status !== Constants.ERROR) {
            return (
                <Activities
                    status={status}
                    showAddNote={this.state.showAddNote}
                    showNotes={this.state.showNotes}
                    showHistory={this.state.showHistory}
                    showRetentionInfo={this.state.showRetentionInfo}
                    showActions={this.state.showActions}
                    isDoc={this.props.isDoc}
                    items={result.items}
                    cleanupKeepInfo={result.keep}
                    retentionInfo={result.retention}
                    currentVersion={this.props.currentVersion}
                    userId={this.props.user.id}
                    onAction={this._onAction}
                    showDetails={this.state.showDetails}
                    isReadOnly={this.props.isReadOnly}
                />
            );
        }
        return (
            <Status status={status} error={this.props.offlineHandler.error} />
        );
    }
}

// apply Redux Store state to Component Properties
const mapStateToProps = /* istanbul ignore next */ (state, ownProps) => ({
    user: state.user,
});

export default compose(
    connect(mapStateToProps),
    GlobalEventsHandler({
        items: [
            ServerEventItemType.document,
            ServerEventItemType.task,
            ServerEventItemType.activity,
        ],
        actions: [
            ServerEventActionType.add,
            ServerEventActionType.modify,
            ServerEventActionType.edit,
            ServerEventActionType.delete,
            ServerEventActionType.refresh,
        ],
    }),
    Moment,
    withOfflineHandler
)(ActivitiesContainer);
