// @flow
import React from "react";
import ButtonUI from "react-bootstrap/lib/Button";
import SearchBucket from "./";
import { type SearchBucketDTO } from "data/types";

type Props = {
    item: SearchBucketDTO,
    selected: Array<string | number>,
    onSelect: (name: string, value: Object) => void,
};

const Button = ({ item, selected, onSelect }: Props) => (
    <ButtonUI
        onClick={() =>
            onSelect({
                name: item.name,
                value: null,
            })
        }
        bsSize="small"
        style={{
            fontWeight: selected.length > 0 ? "bold" : "normal",
            fontStyle: selected.length > 0 ? "normal" : "italic",
        }}
    >
        <SearchBucket.Name item={item} />
    </ButtonUI>
);

Button.displayName = "FilterBar.SearchBucket.Button";

export default Button;
