import { formSetEditModeAction } from "data/actions";

/**
 * starts editing the current document
 */

class doc_editClassification {
    execute = ({ dispatch }) => {
        dispatch(formSetEditModeAction("doc", true));
        return true;
    };
}
export default doc_editClassification;
