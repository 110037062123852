// @flow
import React, { type ComponentType } from "react";
import { getDisplayName } from "data/constants";
import { usePersistSetting } from "hooks";

type Config = {
    /** setting name in store */
    name: string,
    /** namespace for props */
    namespace: string,
    /** defaultValue to assign when not found in storage */
    defaultValue?: ?Object,
};

export type SettingValueProps = {
    value: ?Object,
    setValue: (value: Object) => void,
};

/** will return the current setting's value and dispatch an action on change
 * @deprecated please use hooks/usePersistSetting
 */
const SettingValue =
    ({ name, namespace, defaultValue }: Config) =>
    (WrappedComponent: ComponentType<any>) => {
        const HOC = (props) => {
            const extraProps = {
                [namespace]: usePersistSetting({
                    name,
                    defaultValue,
                    asObject: true,
                }),
            };
            return <WrappedComponent {...props} {...extraProps} />;
        };
        HOC.displayName = `SettingValue(${getDisplayName(WrappedComponent)})`;
        return HOC;
    };

export default SettingValue;
