import { useState, useRef, useEffect } from "react";
import { BroadcastChannel } from "broadcast-channel";

export function useBroadcast(
    channelName: string = "BROADCAST",
    initialState: Object | null = { type: "UNKNOWN" }
) {
    let [state, setState] = useState(initialState);
    let channel = useRef(new BroadcastChannel(channelName));

    useEffect(() => {
        /* istanbul ignore next */
        if (channel.current) {
            channel.current.onmessage = (event: MessageEvent) =>
                setState(event);
        }
        return () => channel.current && channel.current.close();
    }, [channelName]);

    const postMessage = (value: any) =>
        channel.current && channel.current.postMessage(value);

    return [state, postMessage];
}
