import React, { useMemo } from "react";
import FormBar from "@hs/form-bar";
import { getLocalizedText } from "data/utils";
import { type FormBarViewEnum } from "data/types";
import { useFormActions } from "hooks";
import { getClassNames } from "data/utils";
import styles from "./Edit.module.css";

type Props = {
    /** CSS Classname */
    className?: string,
    /** current Form being edited */
    form?: FormResultDTO | DocFormResultDTO,
    /** callback when user clicks Cancel */
    onCancel: () => void,
    /** callback when user clicks Back */
    onBack?: () => void,
    /** default form title to use when formTitle/name not supplied
     * @default {string} null so use i18next localization upload:edit_form.title
     */
    title?: string,
    /** optional icon to display
     * @default {string} edit
     */
    /** optional center component */
    children?: React.ElementType,
    /** targetView */
    targetView: FormBarViewEnum,
};

/**
 * renders the FormEdit's panel header
 *
 * @param {Props} { form, title, onCancel, onBack, children, icon }
 */
export const Edit = ({
    className,
    title,
    onCancel,
    onBack,
    targetView,
    children,
    icon = "edit",
}: Props) => {
    const contextProps = useMemo(
        () => ({ onCancel, isEdit: true, onBack }),
        [onCancel, onBack]
    );
    const actions = useFormActions(targetView, contextProps);
    return (
        <FormBar
            key={targetView}
            className={getClassNames(className, styles.content)}
            icon={icon}
            title={title || getLocalizedText("$upload:edit_form.title")}
            children={children}
            actions={actions}
        />
    );
};
Edit.displayName = "FormBar.Edit";
export default Edit;
