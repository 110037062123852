import { useMemo, useContext } from "react";
import unionBy from "lodash/unionBy";
import cloneDeep from "lodash/cloneDeep";
import { useSelector } from "react-redux";
import { useAsyncMemo } from "hooks";
import CommandActionHandler from "components/CommandActions";
import {
    shouldRenderItem,
    mergePartialConfig,
} from "containers/ActionPanel/Utils";
import { getDefaultsConfig } from "data/constants";
import { ItemContext } from "data/context";
import { getFormActionsConfig } from "data/formActions";
import { userFormActionsSelector } from "data/reducers/selectors";
import { type ItemActionConfig, type FormActionsConfigDTO } from "data/types";
import { getLocalizedText, asyncFilter } from "data/utils";

export const useFormActionsConfig = (
    itemUri: string,
    formatId: number
): FormActionsConfigDTO => {
    const userFormActions = useSelector(userFormActionsSelector);
    return useMemo(() => {
        const userActions = cloneDeep(userFormActions.actions);
        // get formActions as defined in appsettings.json (after being checked for security for this user)
        const formActions = cloneDeep(getFormActionsConfig());

        // get itemUri specific formActions
        const defaults = getDefaultsConfig(itemUri, formatId);
        if (defaults?.formActions) {
            if (defaults.formActions.replace === true) {
                return (
                    mergePartialConfig(
                        defaults.formActions.actions,
                        formActions
                    ) || []
                );
            } else {
                return unionBy(
                    defaults.formActions.actions,
                    userActions,
                    formActions,
                    "id"
                );
            }
        } else {
            return unionBy(userActions, formActions, "id");
        }
    }, [itemUri, formatId, userFormActions]);
};

export const useFormActions = (targetView, contextProps) => {
    const itemContext = useContext(ItemContext);
    const { itemUri = "", formatId = -1 } = itemContext || {};
    const formActions = useFormActionsConfig(itemUri, formatId);
    const allContextProps = useMemo(
        () => ({ ...contextProps, ...itemContext }),
        [contextProps, itemContext]
    );
    const actions: Array<ItemActionConfig> = useAsyncMemo(
        async () => {
            const result = await asyncFilter(
                formActions,
                (action: ItemActionConfig) =>
                    shouldRenderItem(
                        action,
                        itemUri,
                        formatId,
                        allContextProps,
                        targetView
                    )
            );
            return result.map((action) => ({
                id: action.id,
                title: getLocalizedText(action.name),
                icon: action.icon,
                description: getLocalizedText(
                    action.desc ?? action.description
                ),
                onClick: () => {
                    if (action.commandAction == null) {
                        console.error(
                            `Sorry, no CommandAction defined for action.name='${action.name}'`
                        );
                    } else {
                        CommandActionHandler(
                            action.commandAction.name,
                            Object.assign(
                                {},
                                allContextProps,
                                action.commandAction.props
                            )
                        );
                    }
                },
            }));
        },
        [formActions, itemUri, formatId, allContextProps, targetView],
        null
    );
    return actions;
};
