// @flow
import React from "react";
import Modal from "components/Modal";
// import Button from "react-bootstrap/lib/Button";
import { createModal } from "react-modal-promise";
import Icons from "@hs/icons";
import { useModalPostMessageHandler } from "hooks";
import LoadingIframe from "components/LoadingIframe";

type Props = {
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog */
    close: () => void,
    /** url to open in modal */
    url: string,
    /** iframe name */
    name?: string,
    /** whether to open Modal in Docked mode */
    docked?: boolean,
};

export const ExternalLinkModal = ({
    open,
    close,
    url,
    name,
    docked,
}: Props) => {
    useModalPostMessageHandler(close);

    return (
        <Modal fullscreen show={open !== false} onHide={close} docked={docked}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Icons.Library name="arrow-up-right-from-square" />{" "}
                    {name || url}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingIframe
                    title={name || "external link"}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen={true}
                    src={url}
                />
            </Modal.Body>
        </Modal>
    );
};

export const openExternalLinkModal = createModal(ExternalLinkModal);
