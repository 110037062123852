import React from "react";
import styles from "./SummaryAttribute.module.css";
import { type SummaryAttributeDTO } from "data/types";
import ValueFactory from "./ValueFactory";

const SummaryAttribute = ({
    attribute,
    useDefaultStyle = false,
}: {
    attribute: SummaryAttributeDTO,
    useDefaultStyle: boolean,
}) => {
    /* istanbul ignore if */
    if (attribute == null || attribute.value == null) return null;
    return (
        <span className={useDefaultStyle ? "a-wrapper" : styles.wrapper}>
            <span
                className={useDefaultStyle ? "a-name" : styles.caption}
                title={attribute.caption}
            >
                {attribute.caption}
            </span>{" "}
            <ValueFactory
                className={useDefaultStyle ? "a-val" : styles.value}
                value={attribute.value}
            />
        </span>
    );
};
SummaryAttribute.displayName = "SummaryAttribute";
export default SummaryAttribute;
