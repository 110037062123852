import { buffers, delay } from "redux-saga";
import {
    actionChannel,
    all,
    call,
    flush,
    fork,
    put,
    race,
    select,
    takeLatest,
    takeEvery,
    take,
} from "redux-saga/effects";
import actionTypes from "data/actions/actionTypes";
import { dispatch } from "data/storeHelper";
import Constants, { type ConstantsEnum } from "data/constants";
import { CommandActionStatusFlag } from "data/types";
import {
    commandActionQueueAddAction,
    commandActionQueueRemoveAction,
    commandActionRemoveAction,
    commandActionItemStatusAction,
    commandActionStatusAction,
    commandActionLogAction,
    notificationUpsertAction,
    notificationDelAction,
    notificationToastAction,
} from "data/actions";
import { commandActionRequestSelector } from "data/reducers/selectors";
import { actionMappings } from "components/CommandActions";
import loc from "i18next";
import { toastTypes } from "data/toast";
import { JL } from "data/logging";

const log = JL("commandActionSaga");

function* handleCommandActionRequest({ payload }) {
    const { guid, commandAction, props } = payload;
    const actionHandler = new actionMappings[commandAction]();

    log.info(() => ({
        msg: "Handle CommandAction request",
        payload,
        actionHandler,
    }));

    if (props.multiple && actionHandler.queueMultiple) {
        log.info(() => ({
            msg: "Add commandAction to queue",
            commandAction,
            guid,
        }));
        yield put(
            notificationUpsertAction({
                notificationId: guid,
                message: loc.t(`commandAction:${commandAction}.queued`, {
                    count: payload.items.length,
                    name: payload.items?.map((i) => i.name).join(", "),
                }),
            })
        );
        for (let item of payload?.items) {
            yield put(
                commandActionQueueAddAction({
                    guid,
                    commandAction,
                    actionHandler,
                    props: { ...props, ...item },
                })
            );
            yield put(
                commandActionItemStatusAction(
                    guid,
                    item.itemUri,
                    Constants.LOADING,
                    true
                )
            );
        }
    } else {
        yield executeCommandAction({
            payload: {
                commandAction,
                actionHandler,
                context: {
                    dispatch,
                    ...props,
                },
                guid,
            },
        });
        yield put(commandActionRemoveAction(guid));
    }
}

function getToastType(status) {
    switch (true) {
        case (status & CommandActionStatusFlag.Success) > 0:
            return toastTypes.success;
        case (status & CommandActionStatusFlag.Partial) > 0:
            return toastTypes.partial;
        default:
            return toastTypes.failure;
    }
}

function getToastMessage(commandAction, status) {
    let state = "failure";
    switch (true) {
        case (status & CommandActionStatusFlag.Success) > 0:
            state = "success";
            break;
        case (status & CommandActionStatusFlag.Partial) > 0:
            state = "partial";
            break;
        default:
            break;
    }
    return loc.t(`commandAction:${commandAction.commandAction}.${state}`, {
        count: commandAction.items.length,
        ok: commandAction.items.filter((i) => i.status === Constants.OK).length,
        error: commandAction.items.filter((i) => i.status === Constants.ERROR)
            .length,
        name: commandAction.items.map((i) => i.name).join(", "),
    });
}

function* updateCommandActionStatus({ payload }) {
    const commandAction = (yield select((state) =>
        commandActionRequestSelector(state, payload.guid)
    ))?.[0];
    if (commandAction == null) {
        log.warn(() => ({
            msg: "Cannot update CommandAction Status",
            payload,
        }));
        return;
    }
    log.info(() => ({
        msg: "updateCommandActionStatus",
        commandAction,
        payload,
    }));
    let status = CommandActionStatusFlag.NotSet;
    if (commandAction?.items?.some((i) => i.status === Constants.ERROR))
        status |= CommandActionStatusFlag.Error;
    if (commandAction?.items?.every((i) => i.status !== Constants.LOADING)) {
        status |= CommandActionStatusFlag.Done;
        if (commandAction?.items?.every((i) => i.status === Constants.OK)) {
            status |= CommandActionStatusFlag.Success;
            yield put(commandActionRemoveAction(payload.guid));
        } else if (
            !commandAction?.items?.every((i) => i.status === Constants.ERROR)
        )
            status |= CommandActionStatusFlag.Partial;
        log.info(() => ({
            msg: "Command Action queue completed",
            guid: payload.guid,
            commandAction,
        }));
        yield put(
            notificationToastAction({
                ...getToastType(status),
                notificationId: payload.guid,
                log: commandAction.log,
                message: getToastMessage(commandAction, status),
            })
        );
    } else {
        if (commandAction?.items?.some((i) => i.status !== Constants.LOADING))
            status |= CommandActionStatusFlag.Partial;
        status |= CommandActionStatusFlag.Loading;
    }
    yield put(commandActionStatusAction(payload.guid, status, true));
}

function* handleQueueRequest(payload, channel) {
    log.info(() => ({
        msg: "handleQueueRequest",
        payload,
    }));
    const { guid, actionHandler, commandAction, props } = payload;
    // HACK: Delay 50ms on commancAction doc_download to allow for multiple downloads
    if (commandAction === "doc_download") {
        yield call(delay, 50);
        log.info(() => ({
            msg: "delayed 50ms",
            commandAction,
        }));
    }
    const context = {
        dispatch,
        ...props,
    };
    actionHandler.logger = (success, identifier, context) =>
        dispatch(
            commandActionLogAction(guid, {
                timestamp: Date.now(),
                status: success ? Constants.OK : Constants.ERROR,
                message: loc.t(
                    `${identifier}.${success ? "success" : "failure"}`,
                    context
                ),
                details: {
                    ...props,
                    commandAction: commandAction.commandAction,
                },
            })
        );
    log.info(() => ({
        msg: "Update actionHandler.logger to log current details",
        actionHandler,
        context,
    }));
    const itemStatus = yield executeCommandAction({
        payload: { actionHandler, commandAction, context, guid },
    });
    yield put(
        commandActionItemStatusAction(guid, props.itemUri, itemStatus, true)
    );
}

function* executeCommandAction({ payload }): ConstantsEnum {
    const { actionHandler, commandAction, context, guid } = payload;
    log.info(() => ({
        msg: `Execute commandAction "${commandAction}"`,
        actionHandler,
        context,
    }));
    const shouldExecute =
        typeof actionHandler?.render !== "function" ||
        !actionHandler?.shouldRender ||
        (yield call(actionHandler.render, context));
    if (!shouldExecute || typeof actionHandler?.execute !== "function") {
        yield put(commandActionQueueRemoveAction(guid));
        return Constants.OK;
    }
    let itemStatus = Constants.LOADING;
    try {
        const result = yield call(actionHandler.execute, context);
        if (typeof actionHandler?.report === "function")
            yield call(actionHandler.report, result, context);
        itemStatus = result ? Constants.OK : Constants.ERROR;
        log.info(() => ({
            msg: `Executed commandAction "${commandAction}"`,
            actionHandler,
            context,
            result,
            itemStatus,
        }));
    } catch (error) {
        itemStatus = Constants.ERROR;
        log.error(() => ({
            msg: `Error while executing commandAction "${commandAction}"`,
            actionHandler,
            context,
            error,
            itemStatus,
        }));
    }
    return itemStatus;
}

function* abortCommandAction({ payload }, channel) {
    yield flush(channel);
    yield put(
        commandActionStatusAction(
            payload.guid,
            CommandActionStatusFlag.Done | CommandActionStatusFlag.Partial,
            true
        )
    );
    yield rebuildQueue();
}

function* removeCommandAction({ payload }, channel) {
    channel && (yield flush(channel));
    yield put(commandActionRemoveAction(payload.guid));
    yield put(notificationDelAction(payload.guid));
    yield rebuildQueue();
}

function* handleCommandActionQueueAction(action, payload, channel) {
    switch (action?.type) {
        case actionTypes.COMMANDACTION_ABORT:
            yield call(abortCommandAction, action, channel);
            break;
        case actionTypes.COMMANDACTION_QUEUE_REMOVE:
            yield call(removeCommandAction, action, channel);
            break;
        case actionTypes.COMMANDACTION_QUEUE_PAUSE:
            yield call(pauseQueue, payload, channel);
            break;
        default:
            // Continue with requests
            break;
    }
}

function* pauseQueue(payload, channel) {
    // Re-add to queue (since we paused)
    yield put(commandActionQueueAddAction(payload));
    // Wait for command to continue queue
    const action = (yield race([
        take(actionTypes.COMMANDACTION_ABORT),
        take(actionTypes.COMMANDACTION_QUEUE_REMOVE),
        take(actionTypes.COMMANDACTION_QUEUE_START),
    ])).find((a) => a != null);
    yield call(handleCommandActionQueueAction, action, channel);
}

function* rebuildQueue() {
    const requests = (yield select(commandActionRequestSelector)).filter(
        (req) => req.status === CommandActionStatusFlag.Loading
    );
    if (requests.length === 0) return;
    log.info(() => ({
        msg: "rebuildQueue",
        requests,
    }));
    for (let payload of requests) {
        yield call(handleCommandActionRequest, { payload });
    }
}

function* watchCommandActionQueue() {
    const channel = yield actionChannel(
        actionTypes.COMMANDACTION_QUEUE_ADD,
        buffers.expanding()
    );
    while (true) {
        const { payload } = yield take(channel);
        const action = (yield race([
            call(handleQueueRequest, payload, channel),
            take(actionTypes.COMMANDACTION_ABORT),
            take(actionTypes.COMMANDACTION_QUEUE_REMOVE),
            take(actionTypes.COMMANDACTION_QUEUE_PAUSE),
        ])).find((a) => a != null);
        log.info(() => ({
            msg: "watchCommandActionQueue",
            action,
            payload,
        }));
        yield call(handleCommandActionQueueAction, action, payload, channel);
    }
}

function* commandActionSaga() {
    yield all([
        // listen for command action requests
        takeLatest(
            actionTypes.COMMANDACTION_REQUEST,
            handleCommandActionRequest
        ),
        // listen for command action item status changes
        takeEvery(
            actionTypes.COMMANDACTION_ITEM_STATUS,
            updateCommandActionStatus
        ),
        // watch Command Action Queue
        fork(watchCommandActionQueue),
    ]);
}

export default commandActionSaga;
