import React, { useEffect } from "react";
import { useAsyncMemo } from "hooks";
import { useSelector, useDispatch, type Dispatch } from "react-redux";
import { type CardItemResultDTO, CardType, CardItemType } from "data/types";
import { getCardsByEntityAction } from "data/actions";
import Constants, { type ConstantsEnum } from "data/constants";
import Status from "components/Status";
import * as s from "data/reducers/selectors";
import Dropdown from "@hs/dropdown";
import MenuItem from "react-bootstrap/lib/MenuItem";
import Icons from "@hs/icons";
import styles from "./RecentFolderSelect.module.css";
import NativeSelect from "components/NativeSelect";
import bowser from "data/bowser";
import { getClassNames } from "data/utils";
import { fetchBreadcrumb } from "data/api";
import { maybeLocalizeBreadcrumb } from "data/utils";

type Props = {
    /** callback with first itemUri after loading recents */
    onLoad: (itemUri: string) => void,
    /** which itemUri to pre-select */
    defaultItemUri: ?string,
    /** callback when user changes selection
     * @param {string} itemUri new selected itemUri
     */
    onChange: (itemUri: string) => void,
    /** CSS Classname to apply */
    className?: string,
};

/** localize the siteName in the breadcrumb text */
export const FolderRow = ({
    label,
    siteType,
    className,
    icon,
    color,
}: {
    label?: string,
    siteType: SiteTypeEnum,
    className?: string,
    icon?: string,
    color?: string,
}) => {
    return (
        <div className={className}>
            <Icons.Custom
                className={getClassNames("fa-fw", !color && styles.color)}
                icon={icon}
                color={color}
                siteType={siteType}
            />
            <span className={styles.text} title={label}>
                {label}
            </span>
        </div>
    );
};
FolderRow.displayName = "FolderRow";

/** renders a dropdown with recent folders */
export const RecentFolderSelect = ({
    onLoad,
    defaultItemUri,
    onChange,
    className,
}: Props) => {
    const status: ConstantsEnum = useSelector(s.cardsStatusSelector);
    const cards: Array<CardItemResultDTO> = useSelector(s.cardsCardsSelector);
    const dispatch: Dispatch = useDispatch();
    useEffect(() => {
        if (status === Constants.NONE) {
            dispatch(
                getCardsByEntityAction(
                    [CardType.Rec],
                    [CardItemType.Folder],
                    Constants.LOADING
                )
            );
        }
    }, [status, dispatch]);

    const [selectedCard, filteredCards] = useAsyncMemo(
        async () => {
            const filteredCards =
                cards == null
                    ? []
                    : cards.filter(
                          (card) =>
                              card.type === CardType.Rec &&
                              card.itemtype === CardItemType.Folder
                      );
            if (filteredCards.length === 0) return [null, []];
            if (defaultItemUri) {
                let card = filteredCards.find(
                    (card) => card.uri === defaultItemUri
                );
                if (card) return [card, filteredCards];
                card = await fetchBreadcrumb(defaultItemUri).then((bc) => ({
                    uri: defaultItemUri,
                    bcText: bc.text,
                    siteType: bc.siteType,
                    icon: bc.icon,
                    color: bc.color,
                }));
                return [card, [card, ...filteredCards]];
            }

            /* istanbul ignore else */
            if (filteredCards.length > 0 && typeof onLoad === "function") {
                onLoad(filteredCards[0].uri);
            }
            return [null, filteredCards];
        },
        [cards, defaultItemUri, onLoad],
        [null, []]
    );

    if ([Constants.NONE, Constants.LOADING].includes(status)) {
        return <Status status={status} inline />;
    }

    if (bowser.is("iOS") || bowser.is("mobile"))
        return (
            <NativeSelect
                wrapperClassName={styles.recentFolder}
                className={styles.recentFolderNative}
                onChange={
                    /* istanbul ignore next */ (option) => onChange(option.K)
                }
                defaultValue={{ K: defaultItemUri }}
                value={{ K: defaultItemUri }}
                options={filteredCards.map((card, index) => ({
                    K: card.uri,
                    T: maybeLocalizeBreadcrumb(card),
                }))}
                valueKey="K"
                labelKey="T"
            />
        );
    else
        return (
            <Dropdown
                bsSize="small"
                key="folderselect"
                id="folderselect"
                className={styles.recentFolder}
            >
                <Dropdown.Toggle chrome>
                    {selectedCard && (
                        <FolderRow
                            className={styles.button}
                            label={maybeLocalizeBreadcrumb(selectedCard)}
                            siteType={selectedCard.siteType}
                            icon={selectedCard.icon}
                            color={selectedCard.color}
                        />
                    )}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                    {filteredCards.map((card, index) => (
                        <MenuItem
                            active={defaultItemUri === card?.uri}
                            key={`rf-s${index}`}
                            onSelect={
                                /*istanbul ignore next*/ () =>
                                    onChange(card.uri)
                            }
                        >
                            <FolderRow
                                className={styles.menuitem}
                                label={maybeLocalizeBreadcrumb(card)}
                                siteType={card.siteType}
                                icon={card.icon}
                                color={card.color}
                            />
                        </MenuItem>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
};
RecentFolderSelect.displayName = "RecentFolderSelect";
export default RecentFolderSelect;
