//@flow
import React from "react";
import { useMomentLocale } from "hooks";
import { userLanguage } from "data/storeHelper";
import { getDisplayName } from "data/constants";
import Status from "components/Status";
import Constants from "data/constants";

const Moment = (WrappedComponent: ComponentType<any>) => {
    function MomentLocale(props: any) {
        const status = useMomentLocale(userLanguage());
        return status === Constants.OK ? (
            <WrappedComponent {...props} />
        ) : (
            <Status status={status} />
        );
    }
    MomentLocale.displayName = `MomentLocale(${getDisplayName(
        WrappedComponent
    )})`;
    return MomentLocale;
};

export default Moment;
