import actionTypes from "../actions/actionTypes";

export const initialState = {
    initialized: false,
    updated: false,
    failed: false,
    registration: null,
};

const serviceWorkerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SW_INIT:
            return {
                ...state,
                initialized:
                    action.payload.registration != null &&
                    action.payload.error == null,
                failed:
                    action.payload.registration == null &&
                    action.payload.error != null,
                ...action.payload,
            };
        case actionTypes.SW_UPDATE:
            return {
                ...state,
                updated: !state.updated,
                ...action.payload,
            };
        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default serviceWorkerReducer;

export const isSwInitializedSelector = (state: Object): boolean =>
    state.sw.initialized === true;
export const isSwFailedSelector = (state: Object): boolean =>
    state.sw.failed === true;
export const isSwUpdatedSelector = (state: Object): boolean =>
    state.sw.updated === true;
export const swRegistrationSelector = (
    state: Object
): ServiceWorkerRegistration | null => state.sw.registration;
