// @flow
import React from "react";
import Status from "components/Status";
import loc from "i18next";

type Props = {
    /** whether marked as favorite */
    isFav?: boolean,
    /** fetch processing status */
    favStatus?: string,
    /** callback when favorite icon clicked */
    onClick: () => void,
};

const BreadcrumbFavorite = ({ isFav, favStatus, onClick }: Props) => {
    if (isFav) {
        return (
            <a
                href="about:blank"
                className="bc-fav"
                title={loc.t("breadcrumb.delfav.title")}
                onClick={onClick}
            >
                <Status
                    status={favStatus /* istanbul ignore next */ || ""}
                    ok="star"
                    inline
                />
            </a>
        );
    } else {
        return (
            <a
                href="about:blank"
                className="bc-fav"
                title={loc.t("breadcrumb.addfav.title")}
                onClick={onClick}
            >
                <Status
                    status={favStatus /* istanbul ignore next */ || ""}
                    ok="fa-regular fa-star"
                    inline
                />
            </a>
        );
    }
};
BreadcrumbFavorite.displayName = "BreadcrumbFavorite";

export default BreadcrumbFavorite;
