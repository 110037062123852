/**
 * execute clear of current form
 */

type Props = {
    onBack?: () => void,
};

class form_back {
    execute = ({ onBack }: Props) => {
        if (typeof onBack !== "function") {
            return false;
        }
        onBack();
        return true;
    };
}
export default form_back;
