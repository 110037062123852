import copy from "copy-to-clipboard";
import { getRoute } from "components/ItemUriLink";
import { toastActionResult } from "data/toast";
import loc from "i18next";

/**
 * copies a link to a view with the current itemUri to clipboard
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ isDoc: boolean }} { isDoc }
 */

type Context = {
    itemUri: string,
    isDoc: boolean,
};

class clipboard_copyItemLink {
    logger: () => any = toastActionResult;
    execute = async ({ itemUri, isDoc }: Context): Promise<boolean> =>
        new Promise((resolve) => {
            copy(
                window.location.origin +
                    getRoute({
                        itemUri,
                        isDoc,
                        isMini: true,
                        isExternalLink: true,
                    }),
                {
                    message: loc.t(
                        "itemActions:clipboard_copyItemLink.message"
                    ),
                    format: "text/plain",
                    onCopy: (clipboardData: Object) => resolve(true),
                }
            );
        });
    report = async (success: boolean): Promise<boolean> => {
        this.logger(success === true, "itemActions:clipboard_copyItemLink");
    };
}
export default clipboard_copyItemLink;
