import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";
import { type docsLayoutState } from "./types";

export const initialState: docsLayoutState = {
    status: Constants.NONE,
};

const docsReducer = (state: docsLayoutState = initialState, action) => {
    switch (action.type) {
        case actionTypes.CURRENTITEM_SET:
            if (
                action.payload.name !== "docmodal" &&
                action.payload.name !== "itemflows" &&
                action.payload.itemUri !== state.itemUri
            )
                return { ...initialState };
            else return state;

        case actionTypes.DOCS_LAYOUT_REQUEST:
        case actionTypes.DOCS_LAYOUT_SET:
            return {
                ...state,
                ...action.payload,
            };

        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default docsReducer;

export const docsLayoutStatusSelector = (state: Object) =>
    state.docsLayout.status;
export const docsLayoutFiltersSelector = (state: Object) =>
    state.docsLayout.filters;
export const docsLayoutSortsSelector = (state: Object) =>
    state.docsLayout.sorts;
