// @flow
import React from "react";
import ItemActions from "./ItemActions";
import {
    type ItemActionConfig,
    type ItemActionCategoryConfig,
} from "data/types";
import Icons from "@hs/icons";
import { getLocalizedText } from "data/utils";
import Divider from "components/Divider";
import styles from "./ActionCategory.module.css";

type Props = {
    /** current category to render */
    category: ItemActionCategoryConfig,
    /** list of all actions (will be filtered by category.category) */
    actions?: Array<ItemActionConfig>,
    /** callback when user clicks an action */
    onAction?: (action: ItemActionConfig) => void,
    /** optional children (if actions not provided) */
    children?: React.Node,
};

/**
 * renders a category and all it's defined actions
 */
const ActionCategory = ({ category, actions, onAction, children }: Props) => {
    const catActions =
        actions && actions.filter((action) => action.category === category.id);
    if (
        (catActions == null || catActions.length === 0) &&
        typeof onAction === "function"
    )
        return null;

    const content = catActions ? (
        <ItemActions actions={catActions} onAction={onAction} />
    ) : (
        children
    );
    if (category.renderHeader === false) {
        return content;
    } else {
        return (
            <Divider
                name={`actionPanelCategory.${category.id}`}
                hideHR
                className={styles.header}
                defaultExpanded={category.expanded}
                header={
                    <>
                        {getLocalizedText(category.name)}
                        {category.icon && (
                            <Icons.Library
                                name={category.icon}
                                fixedWidth
                                className={styles.icon}
                            />
                        )}
                    </>
                }
            >
                {content}
            </Divider>
        );
    }
};

ActionCategory.displayName = "ActionCategory";
export default ActionCategory;
