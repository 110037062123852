// @flow
import React, { useMemo, useContext } from "react";
import { PaneSizeContext } from "data/context";
type Props = {
    /** html style attribute */
    style: CSSStyleDeclaration,
    /** html class attribute */
    className: string,
    /** Components Children */
    children: Children,
};

const ViewerControlBase = ({
    className = "form-group",
    style = {},
    children,
}: Props) => {
    const margins = 32;
    let width;
    const ctx = useContext(PaneSizeContext);
    if (ctx != null) {
        width = ctx.width;
    }
    const computedStyle: CSSStyleDeclaration = useMemo(() => {
        const styles = { ...style };
        if (width != null) {
            styles.maxWidth = width - margins;
        }
        return styles;
    }, [style, width]);
    return (
        <div className={className} style={computedStyle}>
            {children}
        </div>
    );
};

export default ViewerControlBase;
