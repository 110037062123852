// @flow
import React from "react";
import PropTypes from "prop-types";
import ToggleButtonGroup from "react-bootstrap/lib/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/lib/ToggleButton";
import loc from "i18next";

const BoolPicker = ({
    index,
    value,
    onChange,
}: {
    index: number,
    value?: string,
    onChange: Function,
}) => (
    <ToggleButtonGroup type="radio" name={index + ""} defaultValue={value}>
        <ToggleButton value="" onChange={onChange}>
            {loc.t("folder:action_filter.Boolean.None")}
        </ToggleButton>
        <ToggleButton value="True" onChange={onChange}>
            {loc.t("folder:action_filter.Boolean.True")}
        </ToggleButton>
        <ToggleButton value="False" onChange={onChange}>
            {loc.t("folder:action_filter.Boolean.False")}
        </ToggleButton>
    </ToggleButtonGroup>
);

BoolPicker.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default BoolPicker;
