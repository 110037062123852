import actionTypes from "../actions/actionTypes";
import { ListViewMode } from "data/types";
import bowser from "data/bowser";

export const initialState = {
    mini: false,
    // set default based on target device
    docListViewMode: null,
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SETTINGS_SIDEBAR_MINI:
            return { ...state, ...action.payload };
        case actionTypes.SETTINGS_DOCLIST_VIEWMODE:
            return { ...state, docListViewMode: action.payload.viewMode };
        case actionTypes.SETTINGS_SET:
            return { ...state, [action.payload.name]: action.payload.value };

        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default settingsReducer;

export const sidebarMiniSelector = (state: Object) => state.settings.mini;
export const docListViewModeSelector = (state: Object) =>
    state.settings.docListViewMode == null
        ? bowser.is("mobile") || bowser.is("tablet")
            ? ListViewMode.List
            : ListViewMode.Grid
        : state.settings.docListViewMode;
export const settingValueSelector = (name: string, state: Object) =>
    state.settings[name];
