import React, { useMemo } from "react";
import Icons from "@hs/icons";
import Button from "react-bootstrap/lib/Button";
import PrincipalAvatar from "components/User/PrincipalAvatar";
import HelpBlock from "react-bootstrap/lib/HelpBlock";
import loc from "i18next";
import styles from "./ReminderItem.module.css";
import EnumIcon from "./EnumIcon";
import {
    LocalDateStringOption,
    CustomActionState,
    ReminderTypeFlags,
    ReminderTypeFlagsEnumValues,
    ReminderPriority,
    ReminderPriorityEnumValues,
} from "data/types";
import { toastStyled, toastTypes } from "data/toast";
import { userLanguage } from "data/storeHelper";

type Props = {
    reminder: ReminderDTO,
    isEditAllowed: boolean,
    onEdit: () => void,
    onDelete: () => void,
    className: string,
};

const ReminderItem = ({
    reminder: { customAction, ...reminder },
    isEditAllowed,
    onEdit,
    onDelete,
    className = "",
}: Props) => {
    const reminderTypeEnumValues = useMemo(
        () =>
            customAction == null
                ? ReminderTypeFlagsEnumValues
                : ReminderTypeFlagsEnumValues.concat({
                      value: ReminderTypeFlags.CustomAction,
                      label:
                          customAction.displayName ||
                          "$reminder:type.customAction",
                      icon: (
                          <Icons.CustomActionReminder
                              onClick={() =>
                                  customAction.error &&
                                  toastStyled({
                                      message: customAction.error,
                                      ...toastTypes.failure,
                                  })
                              }
                              className={`${styles.customAction}${
                                  customAction.state === CustomActionState.Error
                                      ? ` ${styles.error}`
                                      : ""
                              }`}
                          />
                      ),
                  }),
        [customAction]
    );
    return (
        <div
            title={reminder.reason}
            className={`${styles.reminder} ${
                isEditAllowed ? "" : styles.readonly
            } ${className}`.trim()}
        >
            {reminder.priority !== ReminderPriority.Normal && (
                <EnumIcon
                    className={styles.icon}
                    enum={ReminderPriorityEnumValues}
                    value={reminder.priority}
                    name="priority"
                    label={loc.t("reminder:priority.label")}
                />
            )}
            <span className={styles.date}>
                {new Date(reminder.date).toLocaleDateString(
                    userLanguage(),
                    LocalDateStringOption.DateTime
                )}
            </span>
            <EnumIcon
                className={styles.icon}
                enum={reminderTypeEnumValues}
                value={reminder.type}
                isMulti={true}
                name="type"
                label={loc.t("reminder:type.label")}
            />
            {reminder.recipient && (
                <span
                    title={`${reminder.recipient.displayName} ${
                        reminder.recipient.description || ""
                    }`}
                    className={styles.avatar}
                >
                    <PrincipalAvatar
                        id={reminder.recipient.id}
                        type={reminder.recipient.$type}
                    />
                </span>
            )}
            <HelpBlock className={styles.reason}>{reminder.reason}</HelpBlock>
            <span className={styles.actions}>
                <Button
                    bsStyle="link"
                    aria-label="edit"
                    title={loc.t("reminder:edit")}
                    bsSize="xsmall"
                    onClick={onEdit}
                    className={isEditAllowed ? "" : "disabled"}
                >
                    <Icons.Library name="pencil" />
                </Button>
                <Button
                    bsStyle="link"
                    aria-label="delete"
                    title={loc.t("reminder:delete")}
                    bsSize="xsmall"
                    onClick={onDelete}
                    className={isEditAllowed ? "" : "disabled"}
                >
                    <Icons.Library name="trash-can" />
                </Button>
            </span>
        </div>
    );
};

ReminderItem.displayName = "ReminderItem";
export default ReminderItem;
