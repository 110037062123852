import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";
import { produce } from "immer";

const initialState = {
    cards: [],
    providers: null,
    status: Constants.NONE,
};

const workflowReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.WF_PROVIDERS_FETCH_REQUESTED:
            case actionTypes.WF_PROVIDERS_FETCH_RESULT:
            case actionTypes.WF_FETCH_REQUESTED:
            case actionTypes.WF_FETCH_RESULT:
                return { ...draft, ...action.payload };
            /* istanbul ignore next */
            case actionTypes.PERSIST_PURGE:
                return initialState;
            default:
                return draft;
        }
    });

export default workflowReducer;

export const workflowStatusSelector = (state: Object) => state.workflow.status;
export const workflowCardsSelector = (state: Object) => state.workflow.cards;
export const workflowProvidersSelector = (state: Object) =>
    state.workflow.providers;
export const workflowPendingCountSelector = (state: Object) =>
    (state.workflow.status === Constants.OK ||
        state.workflow.status === Constants.REFRESH) &&
    state.workflow.cards
        ? state.workflow.cards.filter(
              (card) => card.extras.toggle === "pending"
          ).length
        : -1;
