import { useCallback } from "react";
import { type History } from "history";
import { ViewNameType, type ViewNameTypeEnum } from "data/types";
import { folderRoute, flatRoute, mailRoute } from "components/ItemUriLink";

type Props = {
    targetViewNameType: ViewNameTypeEnum,
    history: History,
    isMini?: boolean,
};

const useViewNameTypeRouting = ({
    targetViewNameType,
    history,
    isMini = false,
}: Props) => {
    const handleViewNameTypeChange = useCallback(
        (viewNameType, props) => {
            if (props.itemUri == null) return;
            if (targetViewNameType === viewNameType || isMini) return;
            let url = "";
            switch (viewNameType) {
                case ViewNameType.flat:
                    url = flatRoute(props.itemUri, isMini, true);
                    break;
                case ViewNameType.mailbox:
                    url = mailRoute(props.itemUri, isMini, true);
                    break;
                case ViewNameType.folder:
                    url = folderRoute(props.itemUri, isMini, true);
                    break;
                default:
                    return;
            }
            history.push(url);
        },
        [targetViewNameType, isMini, history]
    );
    return handleViewNameTypeChange;
};

export { useViewNameTypeRouting };
