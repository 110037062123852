// @flow
import React from "react";
import Modal, { ModalSize } from "components/Modal";
import Button from "react-bootstrap/lib/Button";
import ProgressBar from "react-bootstrap/lib/ProgressBar";
import { createModal } from "react-modal-promise";
import styles from "./ProgressModal.module.css";
import Icons from "@hs/icons";
import loc from "i18next";

type Props = {
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog */
    close: () => void,
    /** text to show in Cancel button */
    cancelButton?: string,
    /** Modal Dialog title */
    title?: string,
    /** FontAwesome icon to show above title
     * @default {string} "spinner"
     */
    icon?: string,
    /** called once dialog mounted to actually perform work
     * @param {Function} close call to close dialog passing whichever return value you need
     * @param {Function} setError call to set error message to display on dialog
     * @param {Function} setProgress call to set progress bar on dialog
     */
    doWork: (
        close: () => void,
        setError: (error: string) => void,
        setProgress: (progress: number) => void
    ) => void,
    /** initial progress (defaults to 100) */
    initialProgress: number,
};

type State = {
    error: ?string,
    progress: number,
};

/** presents a Progress dialog to the user
 * @returns {boolean} true if user confirmed, null/undefined otherwise
 */
export class ProgressModal extends React.PureComponent<Props, State> {
    state = { error: null, progress: 100 };

    componentDidMount() {
        /* istanbul ignore else */
        if (typeof this.props.doWork === "function") {
            this.props.doWork(
                this.props.close,
                (error: string) => this.setState({ error }),
                (progress: number) => this.setState({ progress })
            );
        }
        if (this.state.progress !== this.props.initialProgress) {
            this.setState({
                progress: this.props.initialProgress,
            });
        }
    }

    render() {
        const {
            open,
            close,
            cancelButton = loc.t("common:cancel"),
            title,
            icon,
        } = this.props;

        return (
            <Modal
                size={ModalSize.Small}
                enforceFocus
                show={open !== false}
                onHide={/* istanbul ignore next */ () => close()}
                dialogClassName={styles.modal}
            >
                {title && (
                    <Modal.Header closeButton>
                        <Icons.Library
                            name={this.state.error ? "circle-minus" : icon}
                            className="fa-3x faa-pulse animated faa-slow"
                        />
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                )}
                <Modal.Body>
                    {this.state.error == null && (
                        <ProgressBar active now={this.state.progress} />
                    )}
                    {this.state.error != null && <div>{this.state.error}</div>}
                </Modal.Body>
                {this.state.error != null && (
                    <Modal.Footer>
                        <Button
                            aria-label={cancelButton}
                            onClick={/* istanbul ignore next */ () => close()}
                        >
                            {cancelButton}
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        );
    }
}
ProgressModal.displayName = "ProgressModal";
ProgressModal.defaultProps = {
    icon: "spinner",
    initialProgress: 100,
};

export const openProgressModal = createModal(ProgressModal);
