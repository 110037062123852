// @flow
import React, { useContext, useMemo } from "react";
import AttributeLabel from "components/ExtraAttributes/AttributeLabel";
import MapButton from "components/MapButton";
import ViewerControlBase from "components/ExtraAttributes/ViewerControl/ViewerControlBase";
import { PaneSizeContext, LabelSizeContext } from "data/context";
import { type MarkerDTO } from "data/types";
import sharedStyles from "shared/styles.module.css";

type Props = {
    itemUri: string,
    formatId: number,
    name: string,
    header?: string,
    value: any | string,
    width?: number,
    /** optional (custom) location marker icon */
    marker?: MarkerDTO,
};

const GeoRasterViewerControl = ({
    itemUri,
    formatId,
    name,
    header,
    value,
    width,
    marker,
}: Props) => {
    const { width: maxWidth } = useContext(PaneSizeContext);
    const labelWidth = useContext(LabelSizeContext);
    // $FlowFixMe
    const style: CSSStyleDeclaration = useMemo(() => {
        const style: CSSStyleDeclaration = {
            flexShrink: 1,
        };
        if (width && width > 0) style.width = width + "px";
        else style.minWidth = "100px";
        return style;
    }, [width]);

    const margins = 45;
    const labelMargins = 15;
    const wrapperStyle: CSSStyleDeclaration = useMemo(
        () => ({
            display: "flex",
            justifyContent: "space-between",
            maxWidth: maxWidth - labelWidth - margins - labelMargins,
        }),
        [maxWidth, labelWidth, margins, labelMargins]
    );

    return (
        <ViewerControlBase>
            <AttributeLabel label={header} />
            <div style={wrapperStyle}>
                <span
                    className={`form-control form-control-static ${sharedStyles.select_text}`}
                    style={style}
                >
                    {value}
                </span>
                {value && String(value).length > 0 && (
                    <MapButton
                        itemUri={itemUri}
                        formatId={formatId}
                        value={value}
                        label={header}
                        marker={marker}
                    />
                )}
            </div>
        </ViewerControlBase>
    );
};

GeoRasterViewerControl.displayName = "GeoRasterViewerControl";
export default GeoRasterViewerControl;
