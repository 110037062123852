import React from "react";
import { toast } from "react-toastify";
import Icons from "@hs/icons";
import styles from "./toast.module.css";
import Button from "react-bootstrap/lib/Button";
import { withRouter } from "react-router";
import loc from "i18next";
import {
    settingValue,
    isOnline,
    dispatch,
    getNotification,
} from "data/storeHelper";
import {
    NotificationType,
    ToastProps,
    ToastType,
    NotificationStyle,
    NotificationStatusFlag,
} from "data/types";
import { notificationToastAction } from "data/actions";
import { getLocalizedText } from "data/utils";
import globalloc from "data/i18n.global";

export const toastPosition = toast.POSITION;

export const toastId = {
    PdfJs: "pdfjs",
    NetworkStatus: "network-status",
    NoNetwork: "offline",
    OfflineChanges: "offline-changes",
    ServiceWorker: "sw",
    ServiceWorkerOfflineQueue: "swoq",
    UppyInfo: "uppy-info",
    UppyRecovered: "uppy-recovered",
    UppySuccess: "uppy-success",
};

export const toastTypes: Object<ToastType> = {
    refresh: {
        icon: "arrows-rotate",
        style: NotificationStyle.Info,
        autoClose: false,
        details: /* istanbul ignore next */ ({ handleClick }) => (
            <Button
                bsStyle="link"
                className={styles.btnToast}
                onClick={handleClick}
            >
                {loc.t("globalEvents:refresh.button")}
            </Button>
        ),
        toastId: "refresh",
    },
    success: {
        icon: "check",
        style: NotificationStyle.Success,
    },
    partial: {
        icon: "circle-exclamation",
        style: NotificationStyle.Warning,
    },
    failure: {
        icon: "triangle-exclamation",
        style: NotificationStyle.Error,
    },
    uppyComplete: {
        message: "$upload:complete",
        style: NotificationStyle.Success,
        toastId: toastId.UppySuccess,
    },
    uppyError: {
        style: NotificationStyle.Error,
        toastId: toastId.UppyInfo,
    },
    uppyRecovered: {
        icon: "upload",
        style: NotificationStyle.Info,
        toastId: toastId.UppyRecovered,
        isRouted: true,
    },
    uppyInfo: {
        icon: "upload",
        style: NotificationStyle.Info,
        toastId: toastId.UppyInfo,
    },
    uppyResuming: {
        message: "$upload:resuming",
        icon: "upload",
        style: NotificationStyle.Info,
    },
    pdfSignature: {
        toastId: toastId.PdfJs,
        style: NotificationStyle.Warning,
        autoClose: false,
        position: toast.POSITION.TOP_CENTER,
    },
    swReady: {
        message: globalloc.cache.done,
        toastId: toastId.ServiceWorker,
        icon: "cloud-arrow-down",
        style: NotificationStyle.Info,
    },
    swFailed: {
        message: globalloc.cache.failed,
        toastId: toastId.ServiceWorker,
        style: NotificationStyle.Error,
    },
    swReload: {
        message: globalloc.cache.reload,
        toastId: toastId.ServiceWorker,
        icon: "cloud-arrow-down",
        style: NotificationStyle.Info,
    },
    swOfflineQueueAdd: {
        toastId: toastId.ServiceWorkerOfflineQueue,
        icon: "cloud-arrow-down",
        style: NotificationStyle.Info,
        autoClose: true,
        message: "$common:offline.add",
    },
    swOfflineQueueAddFile: {
        toastId: toastId.ServiceWorkerOfflineQueue,
        icon: "cloud-arrow-down",
        style: NotificationStyle.Info,
        autoClose: true,
        message: "$common:offline.addFile",
    },
    offlinePendingChanges: {
        message: "$common:offline.pending",
        icon: "hourglass-half",
        style: NotificationStyle.Info,
        toastId: toastId.OfflineChanges,
    },
};

export const Toast = ({
    message,
    icon,
    details,
    handleClick,
    style,
    ...rest
}: ToastProps) => (
    <div data-test={`toast${style}`} className={styles.toast}>
        {icon && <Icons.Library name={icon} className="fa-lg" />}
        {message}
        {typeof details === "function" && (
            <div className={styles.detail}>{details({ handleClick })}</div>
        )}
    </div>
);

export const RoutedToast = withRouter(Toast);

/**
 * will toast a message depending on @see {ToastType}
 *
 * @param {ToastType} toastType
 */
export const toastStyled = ({
    message,
    style,
    icon,
    status = NotificationStatusFlag.New,
    ...toast
}: ToastType): Notification | string => {
    dispatch(
        notificationToastAction({
            notificationId: toast?.toastId,
            icon,
            message: getLocalizedText(message),
            timestamp: Date.now(),
            style,
            status,
            toast,
        })
    );
};

export const dismissToast = (toastId: ToastId) => {
    const { toast: notification } = getNotification(toastId);
    settingValue("notificationType") === NotificationType.API
        ? notification?.close?.()
        : toast.dismiss(toastId);
};

/**
 * will toast a success/failure message depending on @see {success}
 *
 * @param {boolean} success
 * @param {string} locPrefix localization name prefix (will append .success or .failure accordingly)
 * @param {any} locParams localization parameters
 */
export const toastActionResult = (
    success: boolean,
    locPrefix: string,
    locParams?: any,
    toastId?: string
): void => {
    //#56010 in case of success+!isOnline there should be no toast
    if (success && !isOnline()) return;
    const message = success
        ? loc.t(locPrefix + ".success", locParams)
        : loc.t(locPrefix + ".failure", locParams);
    const type = toastTypes[success ? "success" : "failure"];
    toastStyled({ ...type, message, toastId });
};
