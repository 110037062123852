// @flow
import React from "react";
import Async from "react-select/async";
import loc from "i18next";
import { LocationToString } from "components/MapViewer/utils";
import { getGeoPlacesProvider } from "components/MapViewer/providerSupport";
import { closeMenuOnScroll } from "data/utils";
import styles from "./GeoPlaceInput.module.css";

type Props = {
    /** initial string value to show */
    value?: string,
    /** callback when user selects a location */
    onChange: (location: string) => void,
    /** input placeholder */
    placeholder: string,
    /** CSS style to apply to container */
    style?: CSSStyleSheet,
    /** id of the input element */
    id?: string,
};

type State = {
    value: string,
    refreshIndex: number,
};

/**
 * renders a Select dropdown for Geocoding address search
 */
export default class GeoPlaceInput extends React.PureComponent<Props, State> {
    _geoCodingProvider: Object | null;

    static defaultProps = {
        placeholder: "",
        inline: false,
    };

    constructor(props: Props) {
        super(props);
        this._initProvider();
        this.state = {
            value: /* istanbul ignore next */ props.value || "",
            refreshIndex: 0,
        };
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value,
                refreshIndex: prevState.refreshIndex + 1,
            });
        }
    }

    _initProvider = () => {
        const providerMapping = {
            osm: "openStreetMapProvider",
            google: "googleProvider",
            bing: "bingProvider",
            esri: "esriProvider",
        };
        const providerName = providerMapping[getGeoPlacesProvider().name];
        /* istanbul ignore next */
        if (providerName == null) {
            alert(
                `Unknown setting under general/geoProvider.places.name - '${getGeoPlacesProvider()}' unknown!`
            );
            return;
        }
        // dynamically load only the requested geoProvider
        import(`leaflet-geosearch/lib/providers/${providerName}.js`)
            .then((provider) => {
                this._geoCodingProvider = new provider.default({
                    // https://wiki.openstreetmap.org/wiki/Nominatim
                    params: window.CONFIG.general.geo.places.params,
                });
            })
            .catch(
                /* istanbul ignore next */ (err) =>
                    alert("Cannot load geoProvider: " + err.message)
            );
    };

    getSAYT = (query?: string): Promise<*> => {
        if (this._geoCodingProvider == null) {
            return new Promise((resolve) => resolve([]));
        } else {
            return this._geoCodingProvider.search({ query });
        }
    };

    _onChange = (option: ?Object) => {
        /* istanbul ignore next */
        if (option == null) return;
        this.props.onChange(
            LocationToString({
                latitude: option.y,
                longitude: option.x,
            })
        );
    };

    _onInputChange = (value, { action }) => {
        if (action === "input-change" || action === "set-value") {
            this.setState({ value });
        }
        if (action === "input-blur") {
            this.props.onChange(this.state.value);
        }
    };

    render = () => (
        <Async
            inputId={this.props.id}
            key={`geocoding-${this.state.refreshIndex}`}
            className={styles.gpi}
            classNamePrefix="gpi"
            // styling
            styles={{
                container: /* istanbul ignore next */ (provided, state) => ({
                    ...provided,
                    ...this.props.style,
                }),
                control: /* istanbul ignore next */ (provided, state) => ({
                    ...provided,
                    height: 34,
                    minHeight: "fit-content",
                    borderRadius: 0,
                    marginRight: -1,
                    boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
                    width: "100%",
                }),
                valueContainer: /* istanbul ignore next */ (
                    provided,
                    state
                ) => ({
                    ...provided,
                    padding: "0px 6px 0 12px",
                    width: "100%",
                    maxHeight: 34,
                }),
                input: /* istanbul ignore next */ (provided, state) => ({
                    ...provided,
                    margin: 0,
                    padding: 0,
                    width: "100%",
                    marginLeft: -3,
                }),
                clearIndicator: /* istanbul ignore next */ (
                    provided,
                    state
                ) => ({
                    ...provided,
                    padding: 4,
                }),
                placeholder: /* istanbul ignore next */ (provided, state) => ({
                    ...provided,
                    whiteSpace: "nowrap",
                    color: "hsl(0, 0%, 80%)",
                    marginLeft: -3,
                }),
                menu: /* istanbul ignore next */ (provided, state) => ({
                    ...provided,
                    marginTop: 0,
                    marginBottom: 0,
                    borderRadius: 0,
                }),
                menuPortal: /* istanbul ignore next */ (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                }),
                option: /* istanbul ignore next */ (provided, state) => {
                    let style = Object.assign({}, provided, {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    });
                    if (state.isFocused) {
                        style = Object.assign({}, style, {
                            boxShadow: "none",
                            background: "#f5f5f5",
                            textShadow: "none",
                            cursor: "pointer",
                        });
                    }
                    return style;
                },
            }}
            components={{
                IndicatorSeparator: /* istanbul ignore next */ () => null,
                //     Option: OptionRenderer,
                DropdownIndicator: /* istanbul ignore next */ () => null,
            }}
            // async loading
            cacheOptions={true}
            loadOptions={this.getSAYT}
            // behaviour
            isClearable
            menuPortalTarget={document.body}
            menuPlacement="auto"
            closeMenuOnScroll={closeMenuOnScroll}
            // localizations
            loadingMessage={
                /* istanbul ignore next */ () => loc.t("search.loading")
            }
            placeholder={this.props.placeholder}
            noOptionsMessage={
                /* istanbul ignore next */ () =>
                    loc.t("common:location.searchPlace")
            }
            // selection
            onChange={this._onChange}
            onInputChange={this._onInputChange}
            inputValue={this.state.value}
        />
    );
}
