// @flow
import React, { useEffect, useCallback } from "react";
import Modal from "components/Modal";
import Breadcrumb from "containers/Breadcrumb";
import UploadSelection from "components/UploadSelection";
import Icons from "@hs/icons";
import { useUppy } from "hooks";
import { addUppyEvents, removeUppyEvents, type uppyFile } from "data/uppy";
import { UploadMode } from "data/types";
import { toastStyled, toastTypes } from "data/toast";
import styles from "./UploadVersionModal.module.css";
import { createModal } from "react-modal-promise";
import { type DroppedFile } from "containers/FileDropHandler";
import { useModalPostMessageHandler } from "hooks";

type Props = {
    /** target existing document's itemUri */
    itemUri: string,
    /** which file(s) to automatically add (used by FileDropHandler.onDrop) */
    files?: Array<DroppedFile>,
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog or upload was a success
     * @param {boolean} success whether file was successfully upload
     */
    close: (success: boolean) => void,
};

/** Uppy properties for UploadVersionModal */
const uppyProps = {
    maxNumberOfFiles: 1,
};

/**
 * Will display a File browser dialog and add a new version to an existing document
 */
const UploadVersionModal = ({ itemUri, files, open, close }: Props) => {
    const uppy = useUppy({
        uppyId: "upload-version",
        uppyProps,
    });

    const _onClose = useCallback(
        (success?: boolean) => {
            /* istanbul ignore else */
            if (success === true) {
                toastStyled(toastTypes.uppyComplete);
            }
            // all success -> reset uppy, close and refresh
            uppy.cancelAll();
            close(success === true);
        },
        [uppy, close]
    );
    useModalPostMessageHandler(_onClose);

    const _onFileAdded = useCallback(
        (file: uppyFile): void => {
            /* istanbul ignore else */
            if (file.meta.itemUri == null) {
                // attach itemUri (managed server-side by TusMiddleware.cs)
                uppy.setFileMeta(file.id, {
                    itemUri,
                    mode: UploadMode.ModifyDocument,
                });
                // do it!
                uppy.upload();
            }
        },
        [uppy, itemUri]
    );

    const _onUploadSuccess = useCallback(
        (file: uppyFile, resp: Any, uploadURL: string): void => _onClose(true),
        [_onClose]
    );

    useEffect(() => {
        /* istanbul ignore else */
        if (uppy) {
            addUppyEvents(
                [
                    { type: "file-added", callback: _onFileAdded },
                    { type: "upload-success", callback: _onUploadSuccess },
                ],
                uppy
            );

            // user dropped file with FileDropHandler
            if (Array.isArray(files) && files.length > 0) {
                try {
                    /**
                     * if a immutable object is supplied, we need to open it up,
                     * due to the nature of uppy modifying the original object
                     */
                    const file = { ...files[0], meta: { ...files[0].meta } };
                    uppy.addFile(file);
                } catch (err) /* istanbul ignore next */ {
                    // ... but failed restrictions - close dialog
                    console.warn("UploadVersionModal Cannot addFile: ", err);
                    _onClose(false);
                }
            }

            return () => {
                removeUppyEvents(
                    [
                        { type: "file-added", callback: _onFileAdded },
                        { type: "upload-success", callback: _onUploadSuccess },
                    ],
                    uppy
                );
            };
        }
    }, [uppy, files, _onFileAdded, _onUploadSuccess, _onClose]);

    return (
        <Modal
            dialogClassName={styles.modal}
            show={open !== false}
            onHide={_onClose}
            style={{ zIndex: 1050 }} /* correctly overlays map modal */
            dataTest="uploadVersionModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Icons.Library name="upload" />{" "}
                    <Breadcrumb
                        itemUri={itemUri}
                        isDoc={true}
                        isFav={false}
                        isReadonly={true}
                        isRefreshing={false}
                        viewName="upload-version"
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{uppy && <UploadSelection uppy={uppy} />}</Modal.Body>
        </Modal>
    );
};
export default UploadVersionModal;
export const openUploadVersionModal = createModal(UploadVersionModal);
