import React, { useCallback } from "react";
import FormGroup from "react-bootstrap/lib/FormGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import { getLocalizedText } from "data/utils";

const Control = ({ classNames = [], remember, setRemember }) => {
    const [group, checkbox, label] = classNames;
    const handleRememberValues = useCallback(
        (e: SyntheticEvent<HTMLInputElement>) => {
            setRemember(e.currentTarget.checked);
        },
        [setRemember]
    );
    return (
        <FormGroup controlId="rememberValues" className={group}>
            <FormControl
                type="checkbox"
                name="rememberValues"
                className={checkbox}
                checked={remember}
                onChange={handleRememberValues}
            />
            <ControlLabel className={label}>
                {getLocalizedText("$upload:rememberValues.control")}
            </ControlLabel>
        </FormGroup>
    );
};

Control.displayName = "RememberValues.Control";

export default Control;
