import React from "react";
import FormsyEditorBase from "./FormsyEditorBase";

const HiddenEditorControl = (props) => (
    <FormsyEditorBase
        isAdditionalRender={true}
        render={(value, changeValue) => (
            <input type="hidden" onChange={changeValue} value={value || ""} />
        )}
        {...props}
    />
);
HiddenEditorControl.displayName = "HiddenEditorControl";
export default HiddenEditorControl;
