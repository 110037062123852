import React from "react";
import FormsyEditorBase from "./FormsyEditorBase";
import MaskedInput from "@hs/masked-input";
import { getClassNames } from "data/utils";
import IMask from "imask";
import loc from "i18next";

const TextEditorControl = (props) => (
    <FormsyEditorBase
        required={props.isRequired ? "isDefaultRequiredValue" : undefined}
        render={(value, changeValue) => (
            <MaskedInput
                mask={props.mask}
                inputType={props.inputType}
                placeholder={props.placeholder || props.mask}
                className={getClassNames("form-control", props.className)}
                disabled={props.isReadonly}
                value={value || ""}
                onChange={changeValue}
                onChangeWithRawEvent={true}
                style={
                    props.style || {
                        width: "100%",
                        borderRadius: 0,
                    }
                }
                id={props.name}
            />
        )}
        {...props}
        validations={{
            ...props.validations,
            notEmptyMask: /*istanbul ignore next*/ (values, value) => {
                if (!props.isRequired || !props.mask) return true;
                const rawValue = IMask.pipe(
                    value,
                    {
                        mask: props.mask,
                        definitions: {
                            "#": /[0-9]/,
                            "&": /./,
                            A: /[0-9a-zA-Z]/,
                            a: /[0-9a-zA-Z]/,
                            9: /[0-9]/,
                            C: /./,
                            "?": /./,
                            n: /[0-9]/,
                        },
                    },
                    IMask.PIPE_TYPE.MASKED,
                    IMask.PIPE_TYPE.UNMASKED
                );
                return String(rawValue) !== "";
            },
        }}
        validationErrors={{
            ...props.validationErrors,
            notEmptyMask: loc.t("common:validation.required"),
        }}
    />
);
TextEditorControl.displayName = "TextEditorControl";
export default TextEditorControl;
