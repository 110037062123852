// @flow
import { openDocModal, type DocModalProps } from "components/DocModal";
import { docRoute } from "components/ItemUriLink";

/**
 * opens document Preview
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ newWindow: boolean}} { newWindow }
 * @param {{ setTarget?: () => void}} { setTarget }
 * @param {{ toggles?: string | Array<ToggleItemDTO> | Array<string>}} { toggles }
 */

type Context = DocModalProps & {
    itemUri: string,
    newWindow?: boolean,
    setTarget?: () => any,
    toggles?: string | Array<ToggleItemDTO> | Array<string>,
};

class doc_preview {
    execute = async ({
        itemUri,
        newWindow,
        setTarget,
        toggles,
        ...docModalProps
    }: Context): Promise<boolean> => {
        if (newWindow) {
            const target = window.open(
                docRoute(
                    itemUri,
                    true,
                    true,
                    this.togglesFrom(toggles),
                    docModalProps.searchTerm,
                    docModalProps.triggerPrint,
                    docModalProps.triggerEditMode
                ),
                "",
                `status=no,location=no,toolbar=no,menubar=no,width=600px,height=640px,top=${
                    Math.random() * 100
                },left=${Math.random() * 100}`
            );
            // Set target for PostMessage communication
            /* istanbul ignore else */
            if (typeof setTarget === "function") {
                setTarget(target);
            }
            return true;
        } else {
            await openDocModal({
                itemUri,
                toggles: this.togglesFrom(toggles),
                ...docModalProps,
            });
            return true;
        }
    };
    togglesFrom = (toggles: string | Array<ToggleItemDTO> | Array<string>) => {
        if (typeof toggles === "string") return toggles.split(",");
        if (Array.isArray(toggles))
            return toggles
                .filter(
                    (toggle) => typeof toggle === "string" || toggle?.isSelected
                )
                .map((toggle) => toggle?.value ?? toggle);
        return undefined;
    };
}
export default doc_preview;
