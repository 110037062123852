// @flow
import React from "react";
import loc from "i18next";

/** Expand to Fullscreen svg image */
const ExpandButton = () => (
    <svg
        height={25}
        width={25}
        viewBox="0 0 40 40"
        style={{ verticalAlign: "middle" }}
    >
        <g>
            <path d="m23.4 8.4h8.2v8.2h-3.2v-5h-5v-3.2z m5 20v-5h3.2v8.2h-8.2v-3.2h5z m-20-11.8v-8.2h8.2v3.2h-5v5h-3.2z m3.2 6.8v5h5v3.2h-8.2v-8.2h3.2z" />
        </g>
    </svg>
);

/** Reduce from Fullscreen svg image */
const CompressButton = () => (
    <svg
        height={25}
        width={25}
        viewBox="0 0 40 40"
        style={{ verticalAlign: "middle" }}
    >
        <g>
            <path d="m26.6 13.4h5v3.2h-8.2v-8.2h3.2v5z m-3.2 18.2v-8.2h8.2v3.2h-5v5h-3.2z m-10-18.2v-5h3.2v8.2h-8.2v-3.2h5z m-5 13.2v-3.2h8.2v8.2h-3.2v-5h-5z" />
        </g>
    </svg>
);

type Props = {
    /** whether we are now in Fullscreen mode */
    isFullscreen: boolean,
    /** callback when user clicked on icon */
    onClick: () => void,
};

/** Renders a Fullscreen button */
const ModalFullscreenButton = ({ isFullscreen, onClick }: Props) => (
    <button
        type="button"
        className="close"
        style={{ padding: "5px", marginTop: 0, fontSize: 15 }}
        onClick={onClick}
        title={
            isFullscreen
                ? loc.t("common:exit_fullscreen")
                : loc.t("common:enter_fullscreen")
        }
    >
        {isFullscreen ? <CompressButton /> : <ExpandButton />}
        <span className="sr-only">
            {isFullscreen
                ? loc.t("common:exit_fullscreen")
                : loc.t("common:enter_fullscreen")}
        </span>
    </button>
);
ModalFullscreenButton.displayName = "ModalFullscreenButton";
export default ModalFullscreenButton;
