import Bowser from "bowser";

const bowser = Bowser.getParser(window.navigator.userAgent);
export default bowser;

export /**
 * @returns {boolean} true if browser cannot clone fetch responses
 * @url https://developer.mozilla.org/en-US/docs/Web/API/Response/clone#browser_compatibility
 */
/* istanbul ignore next */
const isLegacyResponseClone = () =>
    bowser.satisfies({
        chrome: "<42",
    });

export /**
 * @returns {boolean} true if mobile or tablet device
 *
 */
const isHandheld = () => bowser.is("mobile") || bowser.is("tablet");

export /**
 * @returns {boolean} true if iOS
 *
 */
const isIOS = () => bowser.is("iOS");

export /**
 * @returns {boolean} true if Windows
 *
 */
const isWindows = () => bowser.is("Windows");

export /**
 * @returns {boolean} true if Safari
 *
 */
const isSafari = () => bowser.is("Safari");

export /* istanbul ignore next */ const hasConsole = () =>
    bowser.is(Bowser.BROWSER_MAP.edge) ||
    bowser.is(Bowser.BROWSER_MAP.chrome) ||
    bowser.is(Bowser.BROWSER_MAP.firefox) ||
    bowser.is(Bowser.BROWSER_MAP.opera) ||
    bowser.is(Bowser.BROWSER_MAP.electron) ||
    bowser.is(Bowser.BROWSER_MAP.safari);
