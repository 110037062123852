// import { openBarcodeModal } from "components/BarcodeModal";
import { getBarcodeMatch } from "data/barcode";

type Props = {
    /** Barcode modal title */
    title: string,
    /** callback once barcode was read (or user clicked cancel)
     * @note will NOT be called if user clicked Cancel
     * @param {?string} barcode null if no match or barcode itself
     */
    onBarcode: (barcode: ?string) => void,
    /** optional RegExp to retrieve code from QR Barcode */
    matchRegexp?: string,
};

/**
 * shows a Barcode Model to scan/upload a barcode,
 * matches it and returns the matched code
// TODO currently only QR codes supported, normal Barcode do not need any regexp!
 */
const getBarcode = async ({ title, onBarcode, matchRegexp }: Props) => {
    const { openBarcodeModal } = await import(
        "components/BarcodeModal" /* webpackChunkName: "barcode" */
    );
    openBarcodeModal({
        title,
    }).then((code) => {
        // user clicked cancel/closed modal?
        if (code === false) {
            return;
        }
        const result = matchRegexp
            ? getBarcodeMatch({ code, matchRegexp })
            : code;
        onBarcode(result);
    });
};

export default getBarcode;
