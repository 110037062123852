// @flow
import React, { useEffect, useState, useCallback } from "react";
import { withRouter, Prompt } from "react-router";

type Props = { isDirty: boolean, message: ?string | ?boolean };

const NavigationBlock = ({ isDirty, message, history }: Props) => {
    const [shouldDisplayPrompt, setShouldDisplayPrompt] = useState(true);

    const handleNavigation = useCallback(() => {
        if (isDirty) {
            // don't show the prompt more than one times
            setShouldDisplayPrompt(false);
        }
    }, [isDirty, setShouldDisplayPrompt]);

    useEffect(() => {
        const unlisten = history.listen(() => {
            handleNavigation();
        });

        return () => {
            unlisten();
        };
    }, [handleNavigation, history]);

    useEffect(() => {
        if (isDirty === true) {
            window.onbeforeunload = /* istanbul ignore next */ () => message;
        } else {
            window.onbeforeunload = undefined;
        }

        return () => {
            window.onbeforeunload = undefined;
        };
    }, [isDirty, message]);

    if (message == null) return null;
    return <Prompt when={isDirty && shouldDisplayPrompt} message={message} />;
};

export default withRouter(NavigationBlock);
