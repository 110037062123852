// @flow
import React from "react";
import Icons from "@hs/icons";

type Props = {
    /** commonName */
    name: string,
    /** field's value */
    value: number | string | null,
    /** CSS class to apply to icon */
    className?: string,
};

// gets a bucket's value's icon
const Icon = ({ name, value, className }: Props) => {
    /* istanbul ignore next */
    if (value === null) return null;
    // note: custom handling of known searchBuckets
    if (name === "Dateityp") {
        return <Icons.FileExt ext={String(value)} className={className} />;
    } /* istanbul ignore else */ else if (name === "ItemType") {
        if (value === "Document")
            return (
                <Icons.Library
                    name="fa-regular fa-file"
                    className={className}
                />
            );
        /* istanbul ignore else */ else if (value === "Folder")
            return (
                <Icons.Library
                    name="fa-regular fa-folder"
                    className={className}
                />
            );
    }
    return null;
};
Icon.displayName = "FilterBar.SearchBucket.Icon";
export default Icon;
