import share from "./share";
import { ShareType } from "data/types";

/**
 * opens the share dialog with share type link
 */
class folder_emailAttachment extends share {
    execute = (context) =>
        super.execute({ ...context, shareType: ShareType.Link });
}
export default folder_emailAttachment;
