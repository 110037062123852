import { select, call, put, takeLatest, cancelled } from "redux-saga/effects";
import { delay } from "redux-saga";
import actionTypes from "data/actions/actionTypes";
import { cardsCardsSelector } from "data/reducers/selectors";
import {
    getCardsResultAction,
    getCardsByEntityAction,
    getCardsByEntityResultAction,
} from "data/actions";
import { fetchCards, fetchCardsByEntity } from "data/api";
import {
    ServerEventItemType,
    ServerEventActionType,
    CardType,
    CardItemType,
    type ServerEventItemTypeEnum,
    type CardItemTypeEnum,
} from "data/types";
import Constants from "data/constants";

// worker Saga: will be fired on CARDS_FETCH_REQUESTED actions
export function* getCards(action) {
    const abortController = new AbortController();

    try {
        const cards = yield call(fetchCards, abortController.signal);
        yield put(getCardsResultAction({ status: Constants.OK, cards }));
    } catch (e) {
        yield put(
            getCardsResultAction({
                status: Constants.ERROR,
                message: e.message,
            })
        );
    } finally {
        if (yield cancelled()) {
            // Cancel the API call if the saga was cancelled
            abortController.abort();
        }
    }
}

const getCardItemTypeFrom = (
    itemType: ServerEventItemTypeEnum
): CardItemTypeEnum => {
    switch (itemType) {
        case ServerEventItemType.document:
            return CardItemType.Doc;
        case ServerEventItemType.folder:
            return CardItemType.Folder;
        case ServerEventItemType.search:
            return CardItemType.Search;
        /* istanbul ignore next */
        default:
            return null;
    }
};

export function* refreshCards(action) {
    const params = {};

    // Handle recent changes
    if (
        action.payload.action === ServerEventActionType.view &&
        [
            ServerEventItemType.document,
            ServerEventItemType.folder,
            ServerEventItemType.search,
        ].includes(action.payload.item)
    ) {
        params.cardTypes = [CardType.Rec];
        params.cardItemTypes = [getCardItemTypeFrom(action.payload.item)];
    }

    // Handle favorite changes
    if (action.payload.item === ServerEventItemType.favorite) {
        params.cardTypes = [CardType.Fav];
        params.cardItemTypes = [
            CardItemType.Doc,
            CardItemType.Folder,
            CardItemType.Search,
        ];
    }

    /* istanbul ignore else */
    if (params.cardTypes == null || params.cardItemTypes == null) {
        return;
    }

    // De-bouncer
    yield call(delay, 10000);

    yield put(
        getCardsByEntityAction(
            params.cardTypes,
            params.cardItemTypes,
            Constants.REFRESH
        )
    );
}

export function* getCardsByEntity(action) {
    const params = {
        cardTypes: action.payload.cardTypes,
        cardItemTypes: action.payload.cardItemTypes,
    };

    const abortController = new AbortController();
    try {
        const cards = yield call(
            fetchCardsByEntity,
            params,
            abortController.signal
        );
        yield put(
            getCardsByEntityResultAction({
                status: Constants.OK,
                cards,
                cardTypes: action.payload.cardTypes,
                cardItemTypes: action.payload.cardItemTypes,
            })
        );
    } catch (e) {
        const cards = yield select(cardsCardsSelector);
        yield put(
            getCardsResultAction({
                status: Constants.ERROR,
                message: e.message,
                cards,
            })
        );
    } finally {
        if (yield cancelled()) {
            // Cancel the API call if the saga was cancelled
            abortController.abort();
        }
    }
}

function* cardsSaga() {
    yield takeLatest(actionTypes.CARDS_FETCH_REQUESTED, getCards);
    yield takeLatest(
        actionTypes.CARDS_FETCH_ENTITY_REQUESTED,
        getCardsByEntity
    );
    yield takeLatest(actionTypes.GLOBALEVENTS_SERVER, refreshCards);
}

export default cardsSaga;
