import * as API from "data/api";
import { toastActionResult } from "data/toast";
import { openConfirmModal } from "components/ConfirmModal";
import loc from "i18next";

type Context = {
    itemUri: string,
    name: string,
    confirm?: boolean,
};

class unsubscribe {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;
    /**
     * Api call to either unsubscribe by link ID or itemUri
     *
     * @param {string} api unsubscribe API Endpoint, ByID or ByItemUri
     * @param {string | { linkID: number, itemUri: string }} payload API payload, that will be sent.
     * @param {string} name webDavName of ressource, for notification purposes.
     * @returns {Promise} Promise of the API call
     */
    handleUnsubscribe = async (
        api: string,
        payload: string | { linkID: number, itemUri: string },
        name: string
    ) => {
        try {
            const success = await API[`unsubscribe${api}`](payload);
            this.logger(success, "commandAction:unsubscribe", { name });
            return true;
        } catch (error) {
            this.logger(false, "commandAction:unsubscribe", { name });
            return false;
        }
    };

    /**
     * Unsubscribes a given resource by itemUri, with an optional confirm dialog
     *
     * @param {{ itemUri: string }} { itemUri }
     * @param {{ name: string }} { name }
     * @param {{ confirm: bool }} { confirm }
     */
    execute = async ({ itemUri, name, confirm = false }: Context) => {
        if (!confirm) {
            return this.handleUnsubscribe("ByItemUri", itemUri, name);
        }
        try {
            const item = await API.isSubscribed(itemUri);
            if (!item) {
                this.logger(false, "commandAction:unsubscribe", { name });
                return false;
            }
            const payload = {
                linkID: item.id,
                itemUri: item.itemUri,
            };
            if (item.itemUri === itemUri) {
                return this.handleUnsubscribe("ById", payload, name);
            }
            const confirmed = await openConfirmModal({
                body: loc.t("commandAction:unsubscribe.confirm", {
                    name,
                    subscription: item.name,
                    context: /* istanbul ignore next*/ name
                        ? "name"
                        : undefined,
                }),
                confirmButton: loc.t("commandAction:unsubscribe.name"),
            });
            return (
                confirmed && this.handleUnsubscribe("ById", payload, item.name)
            );
        } catch (error) {
            /* istanbul ignore next */
            return this.logger(false, "commandAction:unsubscribe", { name });
        }
    };
}

export default unsubscribe;
