import React from "react";
import FormBarItem from "./Item";
import { FormBarView } from "data/types";

const Factory = ({ views, ...props }) =>
    views.map((targetView, i) => {
        switch (targetView) {
            case FormBarView.Document:
            case FormBarView.Upload:
            case FormBarView.ItemFlows:
            case FormBarView.DocModal:
            case FormBarView.Folder:
                return props.isEdit ? (
                    <FormBarItem.Edit
                        key={`${targetView}.edit.${i}`}
                        targetView={targetView}
                        {...props}
                    />
                ) : (
                    <FormBarItem.View
                        key={`${targetView}.view.${i}`}
                        targetView={targetView}
                        {...props}
                    />
                );
            case FormBarView.Search:
                return (
                    <FormBarItem.Search key={`${targetView}.${i}`} {...props} />
                );
            case FormBarView.SearchScope:
                return (
                    <FormBarItem.SearchScope
                        key={`${targetView}.${i}`}
                        {...props}
                    />
                );
            case FormBarView.LockInfo:
                return (
                    <FormBarItem.LockInfo
                        key={`${targetView}.${i}`}
                        {...props}
                    />
                );
            case FormBarView.ArchiveTarget:
                return (
                    <FormBarItem.ArchiveTarget
                        key={`${targetView}.${i}`}
                        {...props}
                    />
                );
            default:
                console.error(`Unknown FormBarView ${targetView}`);
                return null;
        }
    });
Factory.displayName = "FormBarFactory";
export default Factory;
