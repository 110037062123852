import ip_commandAction from "./ip_commandAction";

/**
 * opens inPoint.Client to show properties window for the specified itemUri
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ isDoc: boolean }} { isDoc }
 */

class ip_properties extends ip_commandAction {
    execute = ({ itemUri, isDoc }: { itemUri: string, isDoc: boolean }) =>
        super.execute({
            itemUri,
            commandAction: isDoc
                ? "DocumentEditAttributesCommandAction"
                : "FolderEditAttributesCommandAction",
        });
}

export default ip_properties;
