// @flow
import React, { PureComponent } from "react";
import "./index.css";

type Props = {
    /** controlled input text value */
    name: string,
    /** controller input onChange event */
    onChange: Function,
    /** optional title (tooltip) */
    title?: string,
};

type State = {
    name: string,
    editing: boolean,
};

class Rename extends PureComponent<Props, State> {
    _txt: HTMLInputElement | null;

    constructor(props: Props) {
        super(props);
        this.state = { editing: false, name: props.name };
    }

    _startEditing = () => {
        this.setState({ editing: true }, () => {
            /* istanbul ignore else */
            if (this._txt != null) {
                const txt = this._txt;
                txt.focus();
                txt.setSelectionRange(0, this.state.name.length);
            }
        });
    };

    _onChange = (e: SyntheticInputEvent<*>) => {
        this.setState({ name: e.currentTarget.value });
    };

    _onBlur = (e: SyntheticInputEvent<*>) => {
        this.setState({ editing: false }, () => {
            /* istanbul ignore else */
            if (
                typeof this.props.onChange === "function" &&
                this.state.name !== this.props.name
            )
                this.props.onChange(this.state.name);
        });
    };

    _onKeyDown = (e: SyntheticInputEvent<*>) => {
        /* istanbul ignore else */
        if (e.which === 13) {
            //ENTER
            e.preventDefault();
            this._onBlur(e);
        }
        /* istanbul ignore else */
        if (e.which === 27) {
            //ESCAPE
            e.preventDefault();
            this.setState({ name: this.props.name }, () => this._onBlur(e));
        }
    };

    render() {
        if (this.state.editing) {
            return (
                <input
                    ref={(ref) => (this._txt = ref)}
                    style={{ display: "inline" }}
                    type="text"
                    className="form-control"
                    value={this.state.name}
                    onBlur={this._onBlur}
                    onChange={this._onChange}
                    onKeyDown={this._onKeyDown}
                />
            );
        } else {
            return (
                <div
                    onClick={this._startEditing}
                    className="rename"
                    title={this.props.title}
                >
                    {this.props.name}
                </div>
            );
        }
    }
}
export default Rename;
