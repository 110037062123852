// @flow
import React, { PureComponent } from "react";
import loc from "i18next";
import { ParseLocation } from "components/MapViewer/utils";
import GeoPlaceInput from "components/GeoPlaceInput";
import FormGroup from "react-bootstrap/lib/FormGroup";
import InputGroup from "react-bootstrap/lib/InputGroup";
import Icons from "@hs/icons";
import styles from "./PlaceInput.module.css";

type Props = {
    /** callback when user sets location */
    onChange: (location: Location) => void,
};

/**
 * MapModal's Places search input
 */
class PlaceInput extends PureComponent<Props> {
    _onChangePlace = (location: string) => {
        if (typeof this.props.onChange === "function") {
            const latlng = ParseLocation(location);
            if (latlng != null) {
                this.props.onChange(latlng);
            }
        }
    };

    render = () => (
        <FormGroup className={styles.container}>
            <InputGroup className={styles.inputGroup}>
                <InputGroup.Addon className={styles.iconWrapper}>
                    <Icons.Library name="magnifying-glass" fixedWidth />
                </InputGroup.Addon>

                <GeoPlaceInput
                    onChange={this._onChangePlace}
                    placeholder={loc.t("common:location.searchPlace")}
                />
            </InputGroup>
        </FormGroup>
    );
}
export default PlaceInput;
