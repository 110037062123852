// this file defines default split panes proportions

const panesDefaultState = {
    minipicker: [/*leftSplit:*/ [25, 75]],
    folder: [
        /*leftSplit:*/ [25, 75],
        /*rightSplit:*/ [50, 50],
    ],
    mailbox: [
        /*leftSplit:*/ [25, 75],
        /*rightSplit:*/ [60, 40],
    ],
    document: [
        /*leftSplit:*/ [70, 30],
        [60, 40],
    ],
    docmodal: [
        /*leftSplit:*/ [70, 30],
        [60, 40],
    ],
    miniflat: [/*topSplit:*/ [60, 40]],
    upload: [/*leftSplit*/ [30, 70]],
    search: [
        /*leftSplit*/ [20, 70],
        /*rightSplit:*/ [40, 60],
    ],
    itemflows: [/*leftSplit*/ [35, 65]],
};

export default panesDefaultState;
