import actionTypes from "../actions/actionTypes";
import {
    OfflineSyncStatus,
    type OfflineSyncStatusEnum,
    type OfflineItemStatus,
} from "data/types";
import { produce } from "immer";

export const initialState = {
    status: OfflineSyncStatus.Unknown,
    message: null,
    items: {},
};

const offlineReducer = (state = initialState, action) => {
    const { payload } = action;
    const { item } = payload || {};

    switch (action.type) {
        case actionTypes.OFFLINE_SYNC_STARTED:
        //lint -fallthrough
        case actionTypes.OFFLINE_SYNC_DONE:
            return {
                ...state,
                ...payload,
            };

        case actionTypes.OFFLINEITEM_SYNC_STATUS:
            return {
                ...state,
                message: payload.message,
                items: produce(state.items, (draft) => {
                    draft[item.id] = Object.assign({}, item, {
                        message: payload.message,
                        status: payload.status,
                        at: action.receivedAt,
                        took: payload.took,
                    });
                }),
            };

        case actionTypes.OFFLINEITEM_REMOVE:
            return {
                ...state,
                items: produce(state.items, (draft) => {
                    delete draft[item.id];
                }),
            };

        case actionTypes.OFFLINEITEM_DEPSMAP_RESULT:
            return {
                ...state,
                items: produce(state.items, (draft) => {
                    draft[item.id] = Object.assign({}, item, {
                        depsMap: payload.depsMap,
                    });
                }),
            };

        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default offlineReducer;

export const offlineSyncStatusSelector = (
    state: Object
): OfflineSyncStatusEnum => state.offline.status;
export const offlineItemsSelector = (state: Object) =>
    state.offline.items /* istanbul ignore next */ || {};
export const offlineItemDepsMapSelector = (
    state: Object,
    item: OfflineItemStatus
) => state.offline.items[item.id].depsMap;
