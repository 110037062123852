import { call, put, takeLatest, cancelled } from "redux-saga/effects";
import { delay } from "redux-saga";
import actionTypes from "data/actions/actionTypes";
import {
    getWorkflowCards,
    getWorkflowCardsResult,
    getWorkflowProvidersResult,
    togglesUpdateCounterAction,
} from "data/actions";
import { fetchWorkflowCards, fetchWorkflowProviders } from "data/api";
import Constants from "data/constants";
import countBy from "lodash/countBy";

export const lib = {
    delay,
};

const defaultCount = {
    start: 0,
    pending: 0,
    // done: 0,
};
const countCards = (cards?: Array<CardItemResultDTO>) => ({
    ...defaultCount,
    ...countBy(cards || /* istanbul ignore next */ [], "extras.toggle"),
});

// worker Saga: will be fired on WF_FETCH_REQUESTED actions
export function* getCards(action) {
    let status = {
        start: Constants.LOADING,
        pending: Constants.LOADING,
    };
    let counts = { ...defaultCount };
    yield put(
        togglesUpdateCounterAction({
            name: "wf",
            counts,
            status,
        })
    );
    if (action?.payload?.status === Constants.REFRESH) {
        // this delay in combination with takeLatest
        // is our debouncer to avoid multiple calls
        yield call(lib.delay, 10000);
    }

    // https://decembersoft.com/posts/redux-saga-abort-controller-cancel-api-calls/
    const abortController = new AbortController();
    try {
        const cards = yield call(fetchWorkflowCards, abortController.signal);
        yield put(getWorkflowCardsResult({ status: Constants.OK, cards }));
        status = {
            start: Constants.OK,
            pending: Constants.OK,
        };
        counts = countCards(cards);
    } catch (e) {
        yield put(
            getWorkflowCardsResult({
                status: Constants.ERROR,
                message: e.message,
            })
        );
        status = {
            start: Constants.ERROR,
            pending: Constants.ERROR,
        };
    } finally {
        if (yield cancelled()) {
            // Cancel the API call if the saga was cancelled
            abortController.abort();
        }
        yield put(
            togglesUpdateCounterAction({
                name: "wf",
                counts,
                status,
            })
        );
    }
}

// worker Saga: will be fired on WF_PROVIDERS_FETCH_REQUESTED actions
export function* getProviders(action) {
    const abortController = new AbortController();

    try {
        const providers = yield call(
            fetchWorkflowProviders,
            abortController.signal
        );
        yield put(
            getWorkflowProvidersResult({ status: Constants.OK, providers })
        );
    } catch (e) {
        yield put(
            getWorkflowProvidersResult({
                status: Constants.ERROR,
                message: e.message,
            })
        );
    } finally {
        if (yield cancelled()) {
            // Cancel the API call if the saga was cancelled
            abortController.abort();
        }
    }
}

function* refreshCards(action) {
    /* istanbul ignore else */
    if (
        action.payload.action === "view" &&
        ["document", "folder", "task", "search"].includes(action.payload.item)
    ) {
        return yield put(getWorkflowCards(Constants.REFRESH));
    }
}

function* workflowSaga() {
    yield takeLatest(actionTypes.WF_PROVIDERS_FETCH_REQUESTED, getProviders);
    yield takeLatest(actionTypes.WF_FETCH_REQUESTED, getCards);
    yield takeLatest(actionTypes.GLOBALEVENTS_SERVER, refreshCards);
}

export default workflowSaga;
