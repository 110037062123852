import React from "react";
import { useLocalStore } from "hooks";
import { Provider } from "react-redux";
import { getDisplayName } from "data/constants";

type Props = {
    name?: string,
    childres?: any,
};

const LocalStore = ({ name = "LocalStore", children }: Props) => {
    const localStore = useLocalStore(name);
    return <Provider store={localStore}>{children}</Provider>;
};

type Config = {
    name?: string,
};

export const LocalStoreHandler = ({
    name = "LocalStore",
}: Config = {}): Node => (WrappedComponent: ReactClass<any>) => {
    const HOC = (props) => (
        <LocalStore name={name}>
            <WrappedComponent {...props} />
        </LocalStore>
    );
    HOC.displayName = `LocalStoreHandler(${getDisplayName(WrappedComponent)})`;
    return HOC;
};

export default LocalStore;
