import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";

const initialState = {
    options: undefined,
    status: Constants.LOADING,
};

const globalSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GLOBALDEFAULTSEARCH_FETCH_RESULT:
            return { ...state, ...action.payload };
        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default globalSearchReducer;

export const globalSearchOptionsSelector = (state: Object) =>
    state.globalSearch.status === Constants.OK
        ? state.globalSearch.options
        : undefined;
