// @flow
import React, { useCallback } from "react";
import {
    type ActivityDTO,
    type CleanupKeepInfoDTO,
    type RetentionInfoDTO,
} from "data/types";
import { isFeatureOn, Feature } from "data/constants";
import ActivityList from "./ActivityList";
import Status from "components/Status";
import Constants, { type ConstantsEnum } from "data/constants";
import CleanupKeepInfo from "./CleanupKeepInfo";
import RetentionInfo from "./RetentionInfo";
import NoteInput from "components/NoteInput";
import styles from "./Activities.module.css";

type Props = {
    /** optional status defaults to OK*/
    status?: ConstantsEnum,
    /** list of activities */
    items: Array<ActivityDTO>,
    /** cleanup keep versions info */
    cleanupKeepInfo: ?CleanupKeepInfoDTO,
    /** retention info */
    retentionInfo: ?RetentionInfoDTO,
    /** callback when user chooses an action
     * @param {string} actionId action identifier
     * @param {string} index activity row index
     */
    onAction: (actionId: string, index: number) => void,
    /** which version should be selected
     * 0 to show latest
     */
    currentVersion: number,
    /** whether to show all details */
    showDetails: boolean,
    /** whether to allow adding notes */
    showAddNote: boolean,
    /** whether to show notes (comment) entries */
    showNotes: boolean,
    /** whether to show history entries */
    showHistory: boolean,
    /** whether to show history menu */
    showActions: boolean,
    /** whether to show retention info */
    showRetentionInfo: boolean,
    /** current userId */
    userId: number,
    /** whether activities are read-only */
    isReadOnly: boolean,
};

/**
 * renders a list of document's activities
 */
const Activities = ({
    status = Constants.OK,
    items,
    cleanupKeepInfo,
    retentionInfo,
    onAction,
    currentVersion,
    showDetails,
    showAddNote,
    showNotes,
    showHistory,
    showActions,
    showRetentionInfo,
    userId,
    isReadOnly,
}: Props) => {
    const handleEnter: (activity: ActivityDTO) => Promise<boolean> =
        useCallback((activity) => onAction("add_note", activity), [onAction]);
    return (
        <div className={styles.wrapper}>
            <Status.Overlay status={status} />
            {showRetentionInfo && (
                <>
                    <RetentionInfo
                        info={retentionInfo}
                        className={styles.info}
                    />
                    <CleanupKeepInfo
                        info={cleanupKeepInfo}
                        className={styles.info}
                    />
                </>
            )}
            <ActivityList
                items={items}
                userId={userId}
                showDetails={showDetails}
                showNotes={showNotes}
                showHistory={showHistory}
                showActions={showActions}
                onAction={onAction}
                currentVersion={currentVersion}
                isReadOnly={isReadOnly}
            />
            {showAddNote && !isReadOnly && isFeatureOn(Feature.noteService) && (
                <NoteInput userId={userId} onEnter={handleEnter} />
            )}
        </div>
    );
};

Activities.displayName = "Activities";
export default Activities;

// const useScrollToBottom = ({ className }) => {
//     React.useEffect(() => {
//         const elems = document.getElementsByClassName(className);
//         if (elems.length === 1) {
//             elems[0].scrollTo(0, elems[0].scrollHeight);
//         }
//     }, [className]);
// };

// actEnd: HTMLDivElement | null;

// componentDidMount = () => this._scrollToBottom();

// // componentDidUpdate = () => this._scrollToBottom();

// _scrollToBottom = (smooth?: boolean) => {
//     if (
//         this.actEnd != null &&
//         typeof this.actEnd.scrollIntoView === "function"
//     ) {
//         if (smooth === true)
//             this.actEnd.scrollIntoView({ behavior: "smooth" });
//         else this.actEnd.scrollIntoView();
//     }
// };
