// @flow
import React, { useEffect, useMemo } from "react";
import { isFeatureOn, Feature } from "data/constants";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/drag-drop/dist/style.min.css";
import "@uppy/status-bar/dist/style.min.css";
import "@uppy/webcam/dist/style.min.css";
import "./index.css";

type Props = {
    /** uppy instance reference */
    uppy: Object,
    /** whether to immediately open File Browse dialog */
    openBrowseDialog?: boolean,
    /** whether to immediately open Camera dialog */
    openCamera?: boolean,
    /** callback when user wants to add a file
     * set to replace uppy's dashboard addFilePanel
     */
    onAddFile?: () => void,
};

const UploadSelection = ({
    uppy,
    openBrowseDialog,
    openCamera,
    onAddFile,
}: Props) => {
    useEffect(() => {
        const plugin = uppy.getPlugin("react:Dashboard");
        /* istanbul ignore else */
        if (plugin) plugin.hideAllPanels();
        if (openBrowseDialog === true) {
            // browse
            var e = document.getElementsByClassName("uppy-Dashboard-input");
            /* istanbul ignore else */
            if (e.length === 1) e[0].click();
        } else if (openCamera === true) {
            /* istanbul ignore else */
            if (plugin) plugin.showPanel("Webcam");
        }
        if (plugin && onAddFile)
            plugin.toggleAddFilesPanel = onAddFile.bind(this);
    }, [uppy, openBrowseDialog, openCamera, onAddFile]);

    const plugins = useMemo(() => {
        const plugins = ["Webcam"];
        /* istanbul ignore next */
        if (isFeatureOn(Feature.uppyLexmark)) {
            plugins.push("Lexmark");
        }
        return plugins;
    }, []);

    return (
        <Dashboard
            key="uppy-dashboard"
            inline={true}
            uppy={uppy}
            hideUploadButton={true}
            hideRetryButton={false}
            hidePauseResumeButton={false}
            hideCancelButton={false}
            showLinkToFileUploadResult={false}
            showProgressDetails={true}
            disableInformer={true}
            disableThumbnailGenerator={true} // ThumbnailGenerator is manually added by uppy.js to add support for GoldenRetriever plugin
            metaFields={[]}
            width="100%"
            height="100%"
            proudlyDisplayPoweredByUppy={false}
            plugins={plugins}
            hideProgressAfterFinish={true}
            doneButtonHandler={null}
            // disableStatusBar={this.props.disableStatusBar}
            // showSelectedFiles={this.props.showSelectedFiles}
        />
    );
};
export default UploadSelection;
