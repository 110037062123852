// @flow
import React, { useCallback, useEffect, useRef } from "react";
import Icons from "@hs/icons";
import Button from "react-bootstrap/lib/Button";
import NoteInput, { getNoteType } from "components/NoteInput";
import {
    type ActivityDTO,
    type ActivityFieldDTO,
    ActivityType,
    ItemNoteType,
    ItemNoteTypeIcon,
    RetentionType,
} from "data/types";
import Avatar from "components/User/Avatar";
import Table from "react-bootstrap/lib/Table";
import loc from "i18next";
import Badge from "react-bootstrap/lib/Badge";
import styles from "./Activity.module.css";
import RetentionInfo from "./RetentionInfo";
import ActionsMenu from "components/ActionsMenu";
import { LockInfoOn, UnlockedOn } from "components/LockInfo";
import { getClassNames } from "data/utils";

type Props = {
    /** activity to render */
    activity: ActivityDTO,
    /** current user's id */
    userId: number,
    /** whether to show activities details
     * @default {boolean} false
     */
    showDetails?: boolean,
    /** whether to show the Actions menu
     * @default {boolean} false
     */
    showActions?: boolean,
    /** whether to render in edit state
     * @default {boolean} false
     */
    isEdit: boolean,
    /** set items edit state
     * @param {boolean} isEdit new isEdit state
     */
    isHighlighted: boolean,
    /** callback when user chooses an action
     * @param {boolean} false
     */
    isSelected?: boolean,
    /** whether this activity is the last version available
     * @default {boolean} false
     */
    isLastVersion?: boolean,
    /** callback when user chooses an action
     * @param {string} actionId action identifier
     * @param {?any} payload optional payload
     */
    onAction: (actionId: string, payload: ?any) => void,
    /** what action menu to display
     * @default {string} docactivity_context
     */
    actionMenu: string,
    /** whether Activity is read-only */
    isReadOnly?: boolean,
};

const getCommentIconString = (activity) => {
    if (
        activity?.extras?.$type == null ||
        !Object.prototype.hasOwnProperty.call(
            ItemNoteTypeIcon,
            activity.extras.$type
        )
    )
        return ItemNoteTypeIcon[ItemNoteType.Default];
    return ItemNoteTypeIcon[activity.extras.$type];
};

/** Renders a list of activities */
const Activity = ({
    activity,
    userId,
    showDetails,
    showActions,
    isEdit,
    isHighlighted,
    isSelected,
    isLastVersion,
    onAction,
    actionMenu,
    isReadOnly,
}: Props) => {
    const renderWhatIcon = useCallback(() => {
        switch (activity.what) {
            case ActivityType.create:
                return <Icons.Library name="plus" />;
            case ActivityType.modifyContent:
            case ActivityType.modifyField:
                return <Icons.Library name="pencil" />;
            case ActivityType.delete:
                return <Icons.Library name="trash-can" />;
            case ActivityType.comment:
                return (
                    <Icons.Library
                        className="comment"
                        onClick={
                            /*istanbul ignore next */ () =>
                                onAction("filter_note")
                        }
                        name={getCommentIconString(activity)}
                        title={loc.t("activity:note.filter")}
                    />
                );
            default:
                return null;
        }
    }, [activity, onAction]);

    const renderWhat = useCallback((): string => {
        switch (activity.what) {
            case ActivityType.create:
            case ActivityType.modifyContent:
            case ActivityType.modifyField:
            case ActivityType.comment:
            case ActivityType.delete:
                return loc.t(`common:act.changes_${activity.what}`, {
                    date: activity.on,
                });
            case ActivityType.locked:
                return <LockInfoOn date={activity.on} />;
            case ActivityType.unlocked:
                return <UnlockedOn date={activity.on} />;
            default:
                return "unknown what=" + activity.what;
        }
    }, [activity]);

    const renderChange = useCallback(
        (change: ActivityFieldDTO, index: number) => (
            <tr key={`ac-${index}`}>
                <td>{change.caption}</td>
                <td>{change.newVal}</td>
                <td>{change.oldVal}</td>
            </tr>
        ),
        []
    );

    const renderChanges = useCallback(
        () => (
            <div className={styles.container}>
                <Table condensed className={styles.table}>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>{loc.t("common:act.changes_newVal")}</th>
                            <th>{loc.t("common:act.changes_oldVal")}</th>
                        </tr>
                    </thead>
                    <tbody className={styles.tbody}>
                        {activity.changes.map((change, index) =>
                            renderChange(change, index)
                        )}
                    </tbody>
                </Table>
            </div>
        ),
        [activity, renderChange]
    );

    const activityRef = useRef(null);
    useEffect(() => {
        /* istanbul ignore else */
        if (isHighlighted && activityRef.current) {
            activityRef.current.scrollIntoView({
                block: "center",
                inline: "center",
            });
        }
    }, [isHighlighted]);

    const handleEnter: (activity: ActivityDTO) => Promise<boolean> =
        useCallback(
            (activity: ActivityDTO) =>
                onAction("update_note", activity).then(
                    (result) => result && onAction("edit_note", false)
                ),
            [onAction]
        );

    const handleAbort: (e: SyntheticMouseEvent<*>) => void = useCallback(
        (e) => e.stopPropagation() || onAction("edit_note", false),
        [onAction]
    );

    const renderText = useCallback(() => {
        switch (activity.what) {
            case ActivityType.create:
            case ActivityType.modifyContent:
            case ActivityType.modifyField:
                if (activity.changes == null || activity.changes.length === 0)
                    return null;
                return renderChanges();
            case ActivityType.comment:
                if (isEdit) {
                    return (
                        <NoteInput
                            activity={activity}
                            onEnter={handleEnter}
                            onAbort={handleAbort}
                        />
                    );
                } else {
                    return <p className={styles.text}>{activity.text}</p>;
                }
            case ActivityType.locked:
            case ActivityType.unlocked:
                return null;
            default:
                return "unknown what=" + activity.what;
        }
    }, [activity, isEdit, handleEnter, renderChanges, handleAbort]);

    const onShouldBeVisible = (action: { id: string }): boolean =>
        !(
            isLastVersion === true &&
            ["sep", "restore", "delete"].includes(action.id)
        );

    return (
        <div
            ref={activityRef}
            className={getClassNames(
                styles.row,
                isSelected ? "selected" : "",
                isHighlighted ? "highlighted" : ""
            )}
            onClick={
                /* istanbul ignore next */ () =>
                    (!isEdit || (isEdit && !isHighlighted)) &&
                    onAction("highlight_note")
            }
        >
            <header className={styles.header}>
                <Avatar
                    id={activity.userId}
                    className={getClassNames("round", styles.avatar)}
                />
                <span>{activity.userName}</span>
                {/* <Icons.Library
                        className={styles.expander}
                        name={showDetails ? "caret-down" : "caret-left"}
                    /> */}
                <span className={styles.icon}>{renderWhatIcon()}</span>
                {showActions &&
                    [
                        ActivityType.create,
                        ActivityType.modifyContent,
                        ActivityType.modifyField,
                    ].includes(activity.what) && (
                        <ActionsMenu
                            toggleComponent={
                                // <Icons.Library name="ellipsis-h" />
                                <Badge className={styles.version}>
                                    {loc.t("common:version.text", {
                                        version: activity.id,
                                    })}
                                    <span className="caret" />
                                </Badge>
                            }
                            name={actionMenu}
                            className={styles.actions}
                            onAction={onAction}
                            onShouldBeVisible={onShouldBeVisible}
                        />
                    )}
                {activity.userId === userId &&
                    activity.what === ActivityType.comment && (
                        <span className={styles.actions}>
                            {!isEdit && !isReadOnly && (
                                <Button
                                    bsStyle="link"
                                    aria-label="edit"
                                    title={loc.t(
                                        `activity:${getNoteType(
                                            activity.extras &&
                                                activity.extras.$type
                                        )}.edit.label`
                                    )}
                                    bsSize="xsmall"
                                    onClick={
                                        /* istanbul ignore next */ (e) =>
                                            ((isEdit ||
                                                (!isEdit && isHighlighted)) &&
                                                e.stopPropagation()) ||
                                            onAction("edit_note", true)
                                    }
                                >
                                    <Icons.Library name="pencil" />
                                </Button>
                            )}
                            {isEdit && (
                                <Button
                                    bsStyle="link"
                                    aria-label="delete"
                                    title={loc.t(
                                        `activity:${getNoteType(
                                            activity.extras &&
                                                activity.extras.$type
                                        )}.delete.label`
                                    )}
                                    bsSize="xsmall"
                                    onClick={
                                        /* istanbul ignore next */ (e) =>
                                            (isEdit && e.stopPropagation()) ||
                                            onAction("delete_note")
                                    }
                                >
                                    <Icons.Library name="trash-can" />
                                </Button>
                            )}
                        </span>
                    )}

                <div className={styles.what}>{renderWhat()}</div>
                {activity.retention != null &&
                    activity.retention.type !== RetentionType.Default && (
                        <RetentionInfo
                            info={activity.retention}
                            className={styles.retention}
                        />
                    )}
            </header>
            <article
                className={getClassNames(
                    styles.article,
                    showDetails || activity.what === ActivityType.comment
                        ? ""
                        : "hidden"
                )}
            >
                {renderText(activity)}
            </article>
        </div>
    );
};
Activity.displayName = "Activity";
Activity.defaultProps = {
    showDetails: false,
    showActions: false,
    isSelected: false,
    actionMenu: "docactivity_context",
};
export default Activity;
