// @flow
import React, { useCallback, useContext, useState, useEffect } from "react";
import { MultiSelectionContext } from "data/context";
import noop from "lodash/noop";
import { isHandheld } from "data/bowser";

type Props = {
    /** which HTML element to use to wrap the children */
    componentClass: string,
    /** callback when user wants to see the ContextMenu */
    onContextMenu: () => void,
    /** React children to render */
    children?: Node,
};

/**
 * wrapper to add context menu event handling
 */
const EventHandler = ({
    componentClass = "div",
    onContextMenu = noop,
    children,
    onTouchEnd,
    onTouchStart,
    onTouchMove,
    onMouseDown,
    onMouseLeave,
    onMouseUp,
    rowIndex,
    columnIndex,
    ...rest
}: Props) => {
    /* istanbul ignore next */
    const {
        handler,
        multiple,
        actions: { handleAdd },
    } = useContext(MultiSelectionContext) || {
        handler: {},
        multiple: false,
        actions: { handleAdd: (index: number) => null },
    };

    // Auto toggle row on initial MultiSelection
    const [autoToggleRow, setAutoToggleRow] = useState(false);

    useEffect(() => {
        setAutoToggleRow(multiple);
    }, [multiple]);

    const handleTouchStart = useCallback(
        (e) => {
            /* istanbul ignore else */
            if (handler.onTouchStart) handler.onTouchStart(e);
            /* istanbul ignore else */
            if (onTouchStart) onTouchStart(e);
            if (multiple) setAutoToggleRow(false);
        },
        [handler, onTouchStart, multiple]
    );

    const handleTouchMove = useCallback(
        (e) => {
            /* istanbul ignore else */
            if (handler.onTouchMove) handler.onTouchMove(e);
            /* istanbul ignore else */
            if (onTouchMove) onTouchMove(e);
        },
        [handler, onTouchMove]
    );

    const handleTouchEnd = useCallback(
        (e) => {
            /* istanbul ignore else */
            if (handler.onTouchEnd) handler.onTouchEnd(e);
            /* istanbul ignore else */
            if (onTouchEnd) onTouchEnd(e);
            /* istanbul ignore else */
            if (autoToggleRow) {
                setAutoToggleRow(false);
                handleAdd(rowIndex);
            }
        },
        [handler, onTouchEnd, autoToggleRow, handleAdd, rowIndex]
    );

    const handleMouseDown = useCallback(
        (e) => {
            /* istanbul ignore else */
            if (handler.onMouseDown) handler.onMouseDown(e);
            /* istanbul ignore else */
            if (onMouseDown) onMouseDown(e);
        },
        [handler, onMouseDown]
    );

    const handleMouseLeave = useCallback(
        (e) => {
            /* istanbul ignore else */
            if (handler.onMouseLeave) handler.onMouseLeave(e);
            /* istanbul ignore else */
            if (onMouseLeave) onMouseLeave(e);
        },
        [handler, onMouseLeave]
    );

    const handleMouseUp = useCallback(
        (e) => {
            /* istanbul ignore else */
            if (handler.onMouseUp) handler.onMouseUp(e);
            /* istanbul ignore else */
            if (onMouseUp) onMouseUp(e);
        },
        [handler, onMouseUp]
    );

    return React.createElement(
        componentClass,
        {
            ...rest,
            onTouchStart: handleTouchStart,
            onTouchMove: handleTouchMove,
            onTouchEnd: handleTouchEnd,
            onMouseDown: handleMouseDown,
            onMouseLeave: handleMouseLeave,
            onMouseUp: handleMouseUp,
            onContextMenu: isHandheld() ? null : onContextMenu,
        },
        children
    );
};

export default EventHandler;
