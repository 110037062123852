import actionTypes from "data/actions/actionTypes";

const globalEventsReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.GLOBALEVENTS_SERVER:
            return { ...state, ...action.payload, id: action.receivedAt };

        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return {};
        default:
            return state;
    }
};

export default globalEventsReducer;

export const globalEventsSelector = (state: Object) => state.globalEvents;
// export const serverEventsTypeSelector = (state: Object) =>
//     state.serverEvents.type;
// export const serverEventsItemUriSelector = (state: Object) =>
//     state.serverEvents.itemUri;
