import { restoreItemUri } from "data/api";
import { toastActionResult } from "data/toast";

/**
 * Restores a folder from the Recycle Bin by itemUri
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name: string }} { name }
 */

type Context = {
    itemUri: string,
    name: string,
};

class recyclebin_folderRestore {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;

    execute = async ({ itemUri }: Context): Promise<boolean> => {
        try {
            return await restoreItemUri(itemUri);
        } catch (err) {
            console.warn(err);
            return false;
        }
    };

    report = async (success: boolean, { name }: Context): Promise<boolean> => {
        this.logger(
            success === true,
            "commandAction:recyclebin_folderRestore",
            {
                name,
            }
        );
    };
}

export default recyclebin_folderRestore;
