import { type Location, type RasterLocation } from "data/types";

export const TrimLocation = (location: Array<String>): Array<String> =>
    location
        .map((point) => point.replace(/\s+/g, ""))
        .filter((point) => !!point);

export /**
 * will parse a string like "63.4234,-18.1232" into a Location object
 *
 * @param {string} location in string i.e. "lat,lang"
 * @returns {Location} in object format i.e. { latitude: number, longitude: number } or null if could not parse
 */
const ParseLocation = (location: string): Location => {
    if (
        location == null ||
        String(location).trim().length === 0 ||
        location.indexOf(",") === -1
    )
        return null;
    const pos = TrimLocation(location.split(","));
    // only Number() will correctly return NaN if any letters are in there
    if (isNaN(Number(pos[0])) || isNaN(Number(pos[1]))) return null;
    // but only parseFloat() will correctly return the full number (not rounded)
    const latitude = parseFloat(pos[0]);
    const longitude = parseFloat(pos[1]);
    return { latitude, longitude };
};

export const ParseRaster = (location: string): RasterLocation => {
    if (
        location == null ||
        String(location).trim().length === 0 ||
        location.indexOf(",") === -1 ||
        location.indexOf(":") === -1
    )
        return null;
    const pos = TrimLocation(location.split(":"));
    const loc = ParseLocation(pos[1]);
    if (loc == null) return null;
    return {
        x: loc.latitude,
        y: loc.longitude,
        rasterId: pos[0],
    };
};

export /**
 * will parse a string like "63.4234,-18.1232;63.4234,-18.1232;63.4234,-18.1232" into a list of Location objects
 *
 * @param {string} area in string i.e. "lat,lang;lat,lang;..."
 * @returns {Array<Location>} in object format i.e. [{latitude:number, longitude: number}, ...] or null if could not parse
 */
const ParseArea = (area: string): Array<Location> => {
    if (
        area == null ||
        String(area).trim().length === 0 ||
        area.indexOf(",") === -1
    )
        return null;
    return TrimLocation(area.split(";")).map((point) => ParseLocation(point));
};

export /**
 * will parse a string like "13.45,22.32,5000" into a Location and Radius
 */
const ParseRadius = (
    value: string
): { location: Location, radius: ?number } => {
    let location = null,
        radius = null;
    if (value && String(value).length > 0 && value.indexOf(",") > 0) {
        const parts = TrimLocation(value.split(","));
        /* istanbul ignore else */
        if (parts.length >= 2) {
            location = ParseLocation(`${parts[0]},${parts[1]}`);
            if (parts.length === 3 && !isNaN(Number(parts[2]))) {
                radius = parseFloat(parts[2]);
            }
        }
    }
    return {
        location,
        radius,
    };
};

export /**
 * will convert a location with radius into a string
 *
 * @param {Location} location input location object
 * @param {number} radius radius in meters
 * @returns {string} string result i.e. "latitude,longitude,radius"
 */
const RadiusToString = (location: Location, radius: number) =>
    location == null || radius == null
        ? ""
        : LocationToString(location) + "," + radius.toFixed(0);

export /**
 * will convert a Location object into a string
 *
 * @param {Location} location input location object
 * @returns {string} string result comma-separated i.e. "lat,longitude"
 */
const LocationToString = (location: Location): string =>
    location == null ? "" : location.latitude + "," + location.longitude;

export /**
 * will convert an area path into a string
 *
 * @param {Array<Location>} area input area coordinates
 * @returns {string} string result comma-separated and semi-colon separated i.e. "lat,longitude;lat,longitude;..."
 */
const AreaToString = (area: Array<Location>): string =>
    area == null
        ? ""
        : area.reduce(
              (result, location, index) =>
                  (result +=
                      (index > 0 ? ";" : "") + LocationToString(location)),
              ""
          );

export /**
 * will convert a raster coordinate into a string
 *
 * @param {RasterLocation} raster input raster coordinates
 * @returns {string} string result of raster location, i.e. "rasterId:x,y"
 */
const RasterToString = (raster: RasterLocation) =>
    raster == null || raster.rasterId == null
        ? ""
        : `${raster.rasterId}:${raster.x},${raster.y}`;

export const GeoPointValidationRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
export const GeoAreaValidationRegex = /^([-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)(;)*)*$/;
