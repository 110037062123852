// @flow
import React, { useContext, useMemo } from "react";
import AttributeLabel from "components/ExtraAttributes/AttributeLabel";
import MapButton from "components/MapButton";
import ViewerControlBase from "components/ExtraAttributes/ViewerControl/ViewerControlBase";
import { type MarkerDTO } from "data/types";
import { PaneSizeContext, LabelSizeContext } from "data/context";
import sharedStyles from "shared/styles.module.css";

type Props = {
    /** Common Name */
    name: string,
    /** Display Name*/
    header?: string,
    /** Coordinates for Geo Area/Point */
    value: any | string,
    /** optional width of button */
    width?: number,
    /** optional (custom) location marker icon */
    marker?: MarkerDTO,
};

const LocationViewerControl = ({
    name,
    header,
    value,
    width,
    marker,
}: Props) => {
    const { width: maxWidth } = useContext(PaneSizeContext);
    const labelWidth = useContext(LabelSizeContext);
    // $FlowFixMe
    const style: CSSStyleDeclaration = useMemo(() => {
        const style: CSSStyleDeclaration = {
            flexShrink: 1,
        };
        if (width && width > 0) style.width = width + "px";
        else style.minWidth = "100px";
        return style;
    }, [width]);

    const margins = 45;
    const labelMargins = 15;
    const wrapperStyle: CSSStyleDeclaration = useMemo(
        () => ({
            display: "flex",
            justifyContent: "space-between",
            maxWidth: maxWidth - labelWidth - margins - labelMargins,
        }),
        [maxWidth, labelWidth, margins, labelMargins]
    );

    return (
        <ViewerControlBase>
            <AttributeLabel label={header} />
            {String(value || "").length > 0 ? (
                <div style={wrapperStyle}>
                    <span
                        className={`form-control form-control-static ${sharedStyles.select_text}`}
                        style={style}
                    >
                        {value}
                    </span>

                    <MapButton value={value} label={header} marker={marker} />
                </div>
            ) : (
                <p className="form-control form-control-static" />
            )}
        </ViewerControlBase>
    );
};

LocationViewerControl.displayName = "LocationViewerControl";
export default LocationViewerControl;
