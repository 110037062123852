// @flow
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import Tus from "@uppy/tus";
import Webcam from "@uppy/webcam";
import "@uppy/webcam/dist/style.css";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import GoldenRetriever from "@uppy/golden-retriever";
import { accessToken, userLanguage, correlationId } from "data/storeHelper";
import { ReduxStore } from "@uppy/store-redux";
import store from "data/store";
import isEmpty from "lodash/isEmpty";
import UppyExif from "components/uppy-exif";
import UppyMsg from "@hs/uppy-msg";
import UppyLexmark from "@hs/uppy-lexmark";
import UppyRestrictions from "components/uppy-restrictions";
import { isFeatureOn, Feature, get, isMiniView } from "data/constants";
import { CustomHttpHeader, OfflineQueueItemType } from "data/types";
import { JL } from "data/logging";
import { isOnline } from "data/storeHelper";
import {
    addToOfflineQueue,
    anyPendingChanges,
} from "data/offline/offlineQueue";
import { postMessageTo } from "hooks/PostMessage/Utils";
import { ModalAction } from "hooks/PostMessage/Actions";
import { toastStyled, toastTypes } from "data/toast";
import loc from "i18next";

export const DEFAULT_UPPY_ID = "upload";

export type uppyFile = {
    id: string, // 'uppyteamkongjpg1501851828779'
    name: string, // 'nature.jpg'
    extension: string, // '.jpg'
    type: string, // 'image/jpeg'
    data: Object, // the Blob object
    size: number | string, // 3947642 (returns 'N/A' if size cannot be determined)
    preview: string, // value that can be used to populate "src" attribute of an "img" tag
};

export const uppyPendingUploadsCountSelector = (
    state: Object,
    id: string /* istanbul ignore next */ = DEFAULT_UPPY_ID
) => {
    let pendingCount = 0;
    if (
        state &&
        state.uppy &&
        state.uppy[id] &&
        state.uppy[id].files &&
        !isEmpty(state.uppy[id].files)
    ) {
        Object.keys(state.uppy[id].files).forEach((fileId) => {
            const file = state.uppy[id].files[fileId];
            if (file.progress.uploadComplete !== true) {
                pendingCount++;
            }
        });
    }
    return pendingCount;
};

export const uppyHasPendingUploadsSelector = (
    state: Object,
    id: string = DEFAULT_UPPY_ID
) => uppyPendingUploadsCountSelector(state, id) > 0;

export const uppyIsResumingUploadsSelector = (
    state: Object,
    id: string = DEFAULT_UPPY_ID
) =>
    state &&
    state.uppy &&
    state.uppy[id] &&
    state.uppy[id].currentUploads &&
    !isEmpty(state.uppy[id].currentUploads);

// returns metadata of queued pending files (not uploaded nor started)
export const uppyPendingFilesMetaSelector = (
    state: Object,
    id: string = DEFAULT_UPPY_ID
) => {
    if (
        state &&
        state.uppy &&
        state.uppy[id] &&
        state.uppy[id].files &&
        !isEmpty(state.uppy[id].files)
    ) {
        let result = [];
        Object.keys(state.uppy[id].files).forEach((fileId) => {
            const file = state.uppy[id].files[fileId];
            if (
                file.progress.uploadStarted === null &&
                file.progress.uploadComplete === false
            ) {
                result.push(
                    Object.assign(
                        {},
                        { id: file.id, preview: file.preview },
                        file.meta
                    )
                );
            }
        });
        return result;
    } else {
        return null;
    }
};

/* istanbul ignore next */
export const getUppyLocale = async () => {
    const locale = mapToUppyLocale[userLanguage()] || "en_US";
    const i18n = await import(
        `__i18n/uppy/${locale}` /* webpackChunkName: "uppy-[request]" */
    );
    return {
        locale,
        i18n,
    };
};

export const mapToUppyLocale: Object = {
    bg: "bg_BG",
    cs: "cs_CZ",
    de: "de_DE",
    "de-AT": "de_DE",
    "de-CH": "de_DE",
    "de-DE": "de_DE",
    en: "en_US",
    "en-GB": "en_US",
    "en-US": "en_US",
    es: "es_ES",
    fr: "fr_FR",
    hr: "hr_HR",
    hu: "hu_HU",
    it: "it_IT",
    ru: "ru_RU",
    sk: "sk_SK",
    sl: "sl_SL", // manually created
    uk: "uk_UA",
    lt: "en_US", //"lt_LT", //NOT SUPPORTED
};

export const createUppy = ({
    id = DEFAULT_UPPY_ID,
    maxNumberOfFiles,
    maxFileSize,
    allowedFileTypes,
    deniedFileTypes,
    metadata,
}: {
    id?: string,
    maxNumberOfFiles?: number,
    maxFileSize?: number,
    allowedFileTypes?: Array<string>,
    deniedFileTypes?: Array<string>,
    metadata?: Object,
}) =>
    new Uppy({
        store: new ReduxStore({
            store,
            id,
        }),
        autoProceed: false,
        restrictions: {
            maxNumberOfFiles,
            maxFileSize,
            allowedFileTypes,
            deniedFileTypes, // custom option handled by our own components/uppy-restrictions
        },
        meta: metadata,
        allowMultipleUploadBatches: false,
        debug:
            process.env.NODE_ENV !== "production" /* istanbul ignore next */ ||
            get(window.CONFIG, ["general", "browserLoggingLevel"], 4000) < 3000,
        logger: JL("Uppy"),
    });

// #48012: Load uppy plugins when token is available
export const addUppyPlugins = (_uppy = uppy) => {
    const headers = {
        [CustomHttpHeader.CorrelationId]: correlationId(),
        [CustomHttpHeader.SecureParams]: get(
            window.CONFIG,
            ["general", "secure_params"],
            undefined
        ),
        [CustomHttpHeader.MiniView]: /* istanbul ignore next */ isMiniView(
            window.location
        )
            ? "1"
            : "0",
    };
    /* istanbul ignore next */
    if (get(window.CONFIG, ["auth", "useCustomAuthHeader"], false)) {
        headers["X-inPoint-Auth"] = accessToken();
    } else {
        headers.authorization = `Bearer ${accessToken()}`;
    }
    /* istanbul ignore next */
    if (!_uppy.getPlugin("Tus")) {
        _uppy = _uppy.use(Tus, {
            endpoint:
                (window.CONFIG.host.bridge || window.CONFIG.host.basename) +
                "/__up",
            withCredentials: true,
            headers,
            chunkSize: 10000000, // 10mb limit imposed by Azure otherwise HTTP 413: https://uppy.io/docs/tus/#chunkSize-Infinity
            limit: 1, //only one concurrent upload at a time - to avoid filename collisions
            retryDelays: [0, 1000, 3000, 5000],
        });
    }
    /* istanbul ignore next */
    try {
        if (!_uppy.getPlugin("Webcam")) {
            _uppy = _uppy.use(Webcam, {
                facingMode: "environment",
                countdown: false,
                modes: ["video-audio", "video-only", "audio-only", "picture"],
                mirror: false,
                locale: {
                    strings: {
                        title: "Camera",
                    },
                },
            });
        }
    } catch (e) {
        _uppy.log("[Webcam] Cannot load Plugin...");
    }
    /* istanbul ignore next */
    if (!_uppy.getPlugin("Lexmark") && isFeatureOn(Feature.uppyLexmark)) {
        const { UppyLexmark: Lexmark } = UppyLexmark;
        _uppy = _uppy.use(Lexmark, {
            id: "Lexmark",
        });
    }
    /* istanbul ignore next */
    if (!_uppy.getPlugin("ThumbnailGenerator")) {
        _uppy = _uppy.use(ThumbnailGenerator, { thumbnailWidth: 280 });
    }
    /* istanbul ignore next */
    if (!_uppy.getPlugin("GoldenRetriever"))
        try {
            if (window.localStorage) {
                // NOTE: https://github.com/transloadit/uppy/pull/268
                // GoldenRetriever restores ALL files from ANY queue (uppy.id)
                // only now (after Thumbnail-generator has been registered) include Golden-Retriever!
                _uppy = _uppy.use(GoldenRetriever, {
                    serviceWorker: isFeatureOn(Feature.offline),
                });
            }
        } catch (e) {
            if (e instanceof DOMException && e.name === "SecurityError")
                console.error(
                    "Cannot load Uppy Plugin GoldenRetriever (disabled Local Storage)"
                );
        }
    /* istanbul ignore next */
    if (!_uppy.getPlugin("Exif")) {
        _uppy = _uppy.use(UppyExif, {
            tags: ["GPSLatitude", "GPSLongitude", "GPSDOP", "Orientation"],
        });
    }
    /* istanbul ignore next */
    if (!_uppy.getPlugin("Msg")) {
        _uppy = _uppy.use(UppyMsg, {
            tags: ["senderName", "senderEmail", "senderDomain", "subject"],
        });
    }
    /* istanbul ignore next */
    if (!_uppy.getPlugin("Restrictions")) {
        _uppy = _uppy.use(UppyRestrictions, {});
    }

    // uppy does not trigger error on offline anymore => move to start upload (and handle ALL files in queue)
    _uppy.on("upload", (data) => {
        /* istanbul ignore else */
        if (!isOnline()) {
            _uppy.log("[Offline] handling upload request", data);
            const queue = [];
            _uppy
                .getFiles()
                .filter(
                    (file) =>
                        file.progress?.uploadStarted == null &&
                        file.progress?.uploadComplete === false
                )
                .forEach((file) => {
                    const key = file.meta.itemUri + "_" + file.id; // to identify pending changes on target folder
                    anyPendingChanges(key).then((exists) => {
                        if (!exists) {
                            queue.push(
                                addToOfflineQueue({
                                    type: OfflineQueueItemType.UppyFile,
                                    key,
                                    name: file.name,
                                    params: file,
                                }).then(() => {
                                    _uppy.removeFile(file.id);
                                    // Notify user that file has been added to offline queue
                                    toastStyled(
                                        toastTypes.swOfflineQueueAddFile
                                    );
                                })
                            );
                        }
                    });
                });

            // Trigger ModalAction.Close after all files have been added to offline queue
            return Promise.all(queue).then((values) => {
                postMessageTo(ModalAction.Close);
            });
        }
    });

    // standard upload-error handling
    _uppy.on("upload-error", (file: uppyFile, error: any) => {
        console.warn("[Uppy] upload-error", file, error);
        toastStyled({
            ...toastTypes.uppyError,
            message: loc.t("upload:error", { file: file.name }),
        });
    });

    return _uppy;
};

export type UppyEvent = {
    type: string,
    callback: Function,
};

export const addUppyEvents = (events: Array<UppyEvent>, _uppy = uppy) => {
    events.forEach((event) => _uppy.on(event.type, event.callback));
    return _uppy;
};

export const removeUppyEvents = (events: Array<UppyEvent>, _uppy = uppy) => {
    events.forEach((event) => _uppy.off(event.type, event.callback));
    return _uppy;
};

/** our global uppy handler instance */
const uppy = createUppy({});
export default uppy;
