// @flow
import React from "react";
import { type Props } from "./Factory";
import {
    NewFormType,
    ItemFlowTemplateType,
    type NewItemResultDTO,
} from "data/types";
import ItemFlows from "components/ItemFlows";
import { createFolder } from "data/api";
import history from "data/history";
import { getRoute } from "components/ItemUriLink";

const Folder = (props: Props) => {
    const onCreate = async (model): Promise<boolean> => {
        try {
            const result: NewItemResultDTO = await createFolder({
                elementType: props.flow.commandAction.props.elementType,
                formatId: props.flow.commandAction.props.templateId,
                itemUri: props.itemUri,
                values: model,
            });
            if (result) {
                if (result.success) {
                    if (props.previewNewItem === true && result.itemUri) {
                        history.push(getRoute({ itemUri: result.itemUri }));
                    }
                    props.onCreated(true, null, result);
                    return true;
                } else {
                    console.warn("Received success=false");
                    props.onCreated(false, null);
                    return false;
                }
            } else {
                throw new Error("No Result received");
            }
        } catch (err) {
            console.warn(err);
            props.onCreated(false, err);
            return false;
        }
    };

    return (
        <ItemFlows.Form
            {...props}
            formType={NewFormType.Folder}
            templateType={ItemFlowTemplateType.Folder}
            templateId={props.flow.commandAction.props.templateId}
            onCreate={onCreate}
        />
    );
};
Folder.displayName = "ItemFlowsInput.Folder";
Folder.defaultProps = {
    previewNewItem: true,
};
export default Folder;
