import React, { useCallback } from "react";
import RasterLayer from "./RasterLayer";
import styles from "./LayersEditor.module.css";
import immutable from "object-path-immutable";
import { RasterMapLayerEditAction } from "data/types";
import { moveArrayItem } from "data/utils";

type Props = {
    onMapEdit: (map) => void,
    map: RasterMapResultDTO,
};

const LayersEditor = ({ map, onMapEdit }: Props) => {
    const removeLayer = useCallback(
        (layer) => {
            const idx = map.layers.findIndex((i) => i.id === layer.id);
            onMapEdit(
                immutable.set(
                    map,
                    `layers.${idx}.editAction`,
                    RasterMapLayerEditAction.delete
                )
            );
        },
        [map, onMapEdit]
    );

    const renameLayer = useCallback(
        (layer, newName) => {
            const idx = map.layers.findIndex((i) => i.id === layer.id);
            let newMap = immutable.set(map, `layers.${idx}.name`, newName);
            // do not change editAction for new .added layers
            if (newMap.layers[idx].editAction !== RasterMapLayerEditAction.add)
                newMap.layers[idx].editAction = RasterMapLayerEditAction.rename;
            onMapEdit(newMap);
        },
        [map, onMapEdit]
    );

    const moveUp = useCallback(
        (layer) => {
            const idx = map.layers.findIndex((i) => i.id === layer.id);
            let target = idx - 1;
            if (idx === 0) target = map.layers.length - 1;
            onMapEdit(
                immutable.set(
                    map,
                    "layers",
                    moveArrayItem(map.layers, idx, target)
                )
            );
        },
        [map, onMapEdit]
    );

    const moveDown = useCallback(
        (layer) => {
            const idx = map.layers.findIndex((i) => i.id === layer.id);
            let target = idx + 1;
            if (target === map.layers.length) target = 0;
            onMapEdit(
                immutable.set(
                    map,
                    "layers",
                    moveArrayItem(map.layers, idx, target)
                )
            );
        },
        [map, onMapEdit]
    );

    return (
        <div className={styles.wrapper}>
            {map.layers
                .filter((m) => m.editAction !== RasterMapLayerEditAction.delete)
                .map((layer, i) => (
                    <RasterLayer
                        key={layer.id || `RasterLayer${i}`}
                        layer={layer}
                        onRemove={() => removeLayer(layer)}
                        onRename={(newName) => renameLayer(layer, newName)}
                        onMoveUp={() => moveUp(layer)}
                        onMoveDown={() => moveDown(layer)}
                    />
                ))}
        </div>
    );
};
LayersEditor.displayName = "RasterMapManager.LayersEditor";
export default LayersEditor;
