// @flow
import React from "react";
import {
    DocListFilterOperators,
    type DocListFilterOperatorsEnum,
} from "data/types";
import DatePicker from "./DatePicker";
import RelativeDatePicker from "./RelativeDatePicker";

const FilterDateValue = ({
    autoFocus,
    index,
    valueIndex,
    values,
    operator,
    onChange,
    id,
}: {
    autoFocus: boolean,
    index: number,
    valueIndex: number,
    values?: Array<string>,
    operator: DocListFilterOperatorsEnum,
    onChange: Function,
    id?: string,
}) => {
    switch (operator) {
        case DocListFilterOperators.DateRelative:
            return (
                <RelativeDatePicker
                    autoFocus={autoFocus}
                    index={index}
                    value={values && values[valueIndex]}
                    onChange={
                        /*istanbul ignore next */ (e) =>
                            onChange(e, index, valueIndex)
                    }
                    id={id}
                />
            );

        case DocListFilterOperators.None:
            return null;

        default:
            return (
                <DatePicker
                    autoFocus={autoFocus}
                    index={index}
                    valueIndex={valueIndex}
                    values={values || []}
                    operator={operator}
                    onChange={onChange}
                    id={id}
                />
            );
    }
};

export default FilterDateValue;
