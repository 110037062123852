// @flow
import React, { useMemo } from "react";
import { connect } from "react-redux";
import FormsyEditorBase from "./FormsyEditorBase";
import MaskedInput from "@hs/masked-input";
import * as s from "data/reducers/selectors";
import { getClassNames } from "data/utils";
import IMask from "imask";
import loc from "i18next";

type Props = {
    /** precision - how many digits */
    decimalScale: number,
    /** currency identifier */
    currency?: string,
    /** localization setting for Thousands separator */
    thousandSeparator: string,
    /** localization setting for Decimal separator */
    decimalSeparator: string,
    /** whether field is readonly (disabled) */
    isReadonly: boolean,
    /** whether field is required (validation) */
    isRequired: boolean,
    /** placeholder text to display when @see {value} is empty */
    placeholder?: string,
    /** CSS Style to apply */
    style?: Object,
    /** CSS Classname to apply */
    className?: string,
    /** whether to truncate the trailing zeros at the end of decimal numbers */
    truncateZeros?: boolean,
};

const IMaskNumberDefault = ({
    decimalScale,
    thousandSeparator,
    decimalSeparator,
    truncateZeros = false,
}) => {
    const props = {
        mask: Number,
        scale: decimalScale,
        signed: false,
        normalizeZeros: true,
        thousandsSeparator: thousandSeparator,
        padFractionalZeros: false,
        radix: "\u200B", //HACK: Use zero-width space as commaseparator to enforce integer
        mapToRadix: [],
    };
    if (decimalScale > 0) {
        // only set this to false in case of EA fields and searchfields
        props.padFractionalZeros = !truncateZeros;
        props.radix = decimalSeparator;
        props.mapToRadix = [decimalSeparator];
    }
    return props;
};

const IMaskCurrencyDefault = ({ currency, ...numberProps }) => ({
    mask: `${currency} num`,
    blocks: {
        num: { ...IMaskNumberDefault(numberProps) },
    },
});

export const NumberEditorControl = ({
    mask,
    currency,
    decimalScale,
    thousandSeparator,
    decimalSeparator,
    truncateZeros,
    ...props
}: Props) => {
    const maskProps = useMemo(() => {
        if (mask) {
            if (String(mask).startsWith("{")) {
                return JSON.parse(mask, function (key, value) {
                    if (value === "Number") return Number;
                    return value;
                });
            }
            return { mask };
        }
        if (currency) {
            return IMaskCurrencyDefault({
                currency,
                decimalScale,
                thousandSeparator,
                decimalSeparator,
                truncateZeros,
            });
        }
        return IMaskNumberDefault({
            decimalScale,
            thousandSeparator,
            decimalSeparator,
            truncateZeros,
        });
    }, [
        mask,
        currency,
        decimalScale,
        thousandSeparator,
        decimalSeparator,
        truncateZeros,
    ]);
    return (
        <FormsyEditorBase
            required={props.isRequired ? "isDefaultRequiredValue" : undefined}
            render={(value, changeValue) => (
                <MaskedInput
                    {...maskProps}
                    debug={true}
                    placeholder={props.placeholder || mask}
                    className={getClassNames("form-control", props.className)}
                    disabled={props.isReadonly}
                    value={value}
                    onChange={changeValue}
                    onChangeWithRawEvent={true}
                    id={props.name}
                    style={
                        props.style || {
                            width: "100%",
                            borderRadius: 0,
                        }
                    }
                />
            )}
            {...props}
            validations={{
                ...props.validations,
                notEmptyMask: /*istanbul ignore next*/ (values, value) => {
                    if (!props.isRequired) return true;
                    const rawValue = IMask.pipe(
                        value ?? "",
                        {
                            ...maskProps,
                            definitions: {
                                "#": /[0-9]/,
                                "&": /./,
                                A: /[0-9a-zA-Z]/,
                                a: /[0-9a-zA-Z]/,
                                9: /[0-9]/,
                                C: /./,
                                "?": /./,
                                n: /[0-9]/,
                            },
                        },
                        IMask.PIPE_TYPE.MASKED,
                        IMask.PIPE_TYPE.UNMASKED
                    );
                    return String(rawValue) !== "";
                },
            }}
            validationErrors={{
                ...props.validationErrors,
                notEmptyMask: loc.t("common:validation.required"),
            }}
        />
    );
};

const mapStateToProps = /* istanbul ignore next */ (state, ownProps) => ({
    thousandSeparator: s.userThousandSeparator(state),
    decimalSeparator: s.userDecimalSeparator(state),
    ...ownProps,
});

export default connect(mapStateToProps)(NumberEditorControl);
