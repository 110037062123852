// @flow
import React from "react";
import ToggleButtonGroup from "react-bootstrap/lib/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/lib/ToggleButton";
import Icons from "@hs/icons";
import { type ItemFlowCategory } from "data/types";
import { getLocalizedText } from "data/utils";

type Props = {
    /** currently selected type */
    value?: string,
    /** flow categories */
    categories: Array<ItemFlowCategory>,
    /** callback when user selects a new type to filter
     * @param {string} newFilterType new selected filter type
     */
    onChange: (newFilterType: string) => void,
    /** optional CSS style to apply */
    style?: Object,
    /** optional CSS classname to apply */
    className?: string,
};

const CategoryFilter = ({
    value,
    categories,
    onChange,
    style,
    className,
}: Props) => (
    <ToggleButtonGroup
        name="itemflows-filtertype"
        type="radio"
        defaultValue={value}
        bsSize="small"
        style={style}
        className={className}
        onChange={onChange}
    >
        {categories.map((category) => (
            <ToggleButton
                key={`cat-${category.id}`}
                value={category.id}
                title={getLocalizedText(category.name)}
            >
                <Icons.Library
                    name={category.icon}
                    style={{ margin: 0, padding: 0 }}
                />
            </ToggleButton>
        ))}
    </ToggleButtonGroup>
);
CategoryFilter.displayName = "ItemFlows.CategoryFilter";
export default CategoryFilter;
