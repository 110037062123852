import React from "react";
import { type Props } from "./Factory";
import { saveNewDocFromTemplate } from "data/api";
import { NewFormType, ItemFlowTemplateType } from "data/types";
import ItemFlows from "components/ItemFlows";

const DocTemplate = (props: Props) => {
    const _onCreate = async (model) => {
        try {
            const result = await saveNewDocFromTemplate({
                templateId: props.flow.commandAction.props.templateId,
                itemUri: props.itemUri,
                values: model,
            });
            props.onCreated(result === true);
        } catch (err) {
            // console.warn(err);
            props.onCreated(false, err);
        }
    };

    return (
        <ItemFlows.Form
            {...props}
            formType={NewFormType.Document}
            templateType={ItemFlowTemplateType.DocTemplate}
            templateId={props.flow.commandAction.props.templateId}
            onCreate={_onCreate}
        />
    );
};
DocTemplate.displayName = "ItemFlowsInput.DocTemplate";
export default DocTemplate;
