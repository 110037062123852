import React, { useMemo } from "react";
import FormBar from "@hs/form-bar";
import { getLocalizedText } from "data/utils";
import { useFormActions, usePersistSetting } from "hooks";
import Constants, { type ConstantsEnum } from "data/constants";
import { useDispatch } from "react-redux";
import { FormBarView } from "data/types";
import { getClassNames } from "data/utils";
import styles from "./Search.module.css";

type Props = {
    /** CSS Classname */
    className?: string,
    /** current SearchForm */
    searchForm?: SearchFormResultDTO,
    /** callback when user clicks Cancel */
    onCancel: () => void,
    /** callback when user clicks Back */
    onBack?: () => void,
    /** callback when user clicks Clear */
    onClear: () => void,
    /** used for evaluating if the clear button should be visible */
    clearEnabled: () => boolean,
    /** status of the search */
    status: ConstantsEnum,
};

/**
 * renders the SearchForm's panel header
 *
 * @param {Props} { searchForm, onCancel, onClear, onBack }
 */
export const Search = ({
    className,
    searchForm,
    onCancel,
    onClear,
    onBack,
    status,
}: Props) => {
    const dispatch = useDispatch();
    const [operator, setOperator] = usePersistSetting({
        name: "showSearchOperators",
        defaultValue: false,
    });
    const [scope, setScope] = usePersistSetting({
        name: "showSearchScope",
        defaultValue: false,
    });
    const isSearching = status === Constants.LOADING;
    const contextProps = useMemo(
        () => ({
            searchForm,
            onClear,
            onCancel,
            onBack,
            showSearchOperators: { value: operator, setValue: setOperator },
            showSearchScope: { value: scope, setValue: setScope },
            isSearching,
            dispatch,
        }),
        [
            searchForm,
            onClear,
            onCancel,
            onBack,
            operator,
            setOperator,
            scope,
            setScope,
            isSearching,
            dispatch,
        ]
    );
    const actions = useFormActions(FormBarView.Search, contextProps);
    return (
        <FormBar
            className={getClassNames(styles.header, className)}
            icon="magnifying-glass"
            title={
                searchForm?.name ?? getLocalizedText("$common:search.loading")
            }
            actions={actions}
        />
    );
};
Search.displayName = "FormBar.Search";
export default Search;
