import { call, put, takeEvery, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import getToggles from "data/toggles";
import actionTypes from "data/actions/actionTypes";
import {
    togglesChangeAction,
    currentItemLoadedAction,
    //setCurrentItemAction,
    saveCurrentItemAction,
    setSplitPanesAction,
} from "data/actions";
import { viewStorage, cacheStorage } from "data/storage";
import {
    isMiniView,
    isFeatureOn,
    Feature,
    getDefaultsConfig,
} from "data/constants";
import { produce } from "immer";
import * as s from "data/reducers/selectors";

const togglesSelector = (state) => state.toggles;
const panesSelector = (state) => state.panes;

const ignoredViews = ["itemflows", "upload", "search"];
const isIgnoredView = (view) => ignoredViews.indexOf(view) > -1;

function* loadSettings(action) {
    try {
        const currentItemName = yield select(s.currentNameSelector);
        const currentItemUri = yield select(s.currentItemUriSelector);
        const name = yield call(getName, action, currentItemName);

        // Bail early if on ignored view
        /* istanbul ignore if */
        if (isIgnoredView(name)) {
            yield put(currentItemLoadedAction());
            return;
        }

        const key = yield call(getKey, name, currentItemUri);
        const value = yield call(
            [
                shouldPersist(currentItemUri) ? viewStorage : cacheStorage,
                "getItem",
            ],
            name
        );

        /**
         * Note: Currently the toggles in the store to not match the toggles we need to persist.
         * Some redundant code is already here for moving everything to a saga (maybe not the current one)
         * see TogglesHandler HOC
         */
        const isPersisted = yield select((state) =>
            s.togglesPersistedSelector(state, currentItemName)
        );

        const usePersistedToggles = !!(isPersisted || value?.toggles);

        const selectedToggles = isPersisted
            ? yield select((state) =>
                  s.selectedTogglesSelector(state, currentItemName)
              )
            : value?.toggles;

        if (
            value != null &&
            selectedToggles != null &&
            !selectedToggles.every(
                (t) =>
                    value.toggles?.includes(t) /* istanbul ignore next */ ?? []
            )
        ) {
            value.toggles = selectedToggles;
        }

        /* istanbul ignore next */
        const toggles = produce(getToggles(name), (draft) => {
            draft.toggles = draft.toggles.map((toggle) => {
                toggle.isSelected = usePersistedToggles
                    ? selectedToggles?.includes(toggle.value) ??
                      toggle.isSelected
                    : toggle.isSelected;
                switch (name) {
                    case "home":
                        // Fix persisted hidden toggles
                        if (
                            toggle.value === "action" &&
                            !isFeatureOn(Feature.homeActions)
                        ) {
                            toggle.isHidden = true;
                            toggle.isSelected = false;
                        }
                        // Trigger action view on home in Mini-View
                        if (
                            isMiniView(window.location) &&
                            toggle.value !== "action" &&
                            isFeatureOn(Feature.homeActions)
                        ) {
                            console.warn(
                                `Mini View "${name}" currently does not support "${value}", falling back to "action"`
                            );
                            toggle.value = "action";
                        }
                        break;
                    default:
                        break;
                }
                return toggle;
            });

            // Always select at least one
            if (!draft.toggles.some((t) => t.isSelected)) {
                draft.toggles.find((t) => !t.isHidden).isSelected = true;
            }
        });
        // console.log("aaa settoggles", { ...toggles.toggles });
        yield put(togglesChangeAction(currentItemName, toggles.toggles));

        if (value !== null) {
            value.layout = yield call(
                [
                    shouldPersist(currentItemUri) ? viewStorage : cacheStorage,
                    "getItem",
                ],
                key
            );

            if (
                Object.prototype.hasOwnProperty.call(value, "panes") &&
                !isPersisted
            ) {
                for (let key in value.panes) {
                    yield put(
                        setSplitPanesAction({
                            view: name,
                            name: key,
                            dimensions: value.panes[key],
                        })
                    );
                }
            }

            // console.log("loadsettings", value, name);
        }

        yield put(currentItemLoadedAction(value));
    } catch (e) {
        /* istanbul ignore next */
        console.warn(e);
    }
}

function shouldPersist(itemUri: string): boolean {
    const def = getDefaultsConfig(itemUri);
    if (def != null && def.persist != null) return def.persist;
    else return true;
}

function* calcCurrentItem(action) {
    yield call(delay, 500);
    try {
        const currentItemName = yield select(s.currentNameSelector);
        const currentItemUri = yield select(s.currentItemUriSelector);
        const name = yield call(getName, action, currentItemName);

        // Bail early if on ignored view
        /* istanbul ignore if */
        if (isIgnoredView(name)) {
            return;
        }

        const key = yield call(getKey, name, currentItemUri);
        // console.log("calcCurrentItem", action.type, name, key);

        /* istanbul ignore next */
        let value = yield call(
            [
                shouldPersist(currentItemUri) ? viewStorage : cacheStorage,
                "getItem",
            ],
            name
        );
        // console.log("val", action.type, value);
        /* istanbul ignore next */
        if (value == null) {
            value = {};
        }

        /* istanbul ignore next */
        value.layout = yield call(
            [
                shouldPersist(currentItemUri) ? viewStorage : cacheStorage,
                "getItem",
            ],
            key
        );

        // Setup defaults
        const toggles = yield select(togglesSelector);
        value.toggles = toggles[name]["_selected"];
        const panes = yield select(panesSelector);
        value.panes = panes[name] /* istanbul ignore next */ || [];
        value.layout = {};

        //console.log("action", action);
        switch (action.type) {
            /* istanbul ignore next */
            case actionTypes.CURRENTITEM_SET:
                //yield call(loadSettings, action);
                break;

            case actionTypes.TOGGLES_PERSIST:
                value.toggles = action.payload.toggles
                    .filter((t) => t.isSelected)
                    .map((t) => t.value);
                break;

            case actionTypes.SPLITPANES_PERSIST:
                value.panes[action.payload.name] = action.payload.dimensions;
                break;

            case actionTypes.DOCS_LAYOUT_SET:
                const filters =
                    action.payload.filters ||
                    (yield select(s.docsLayoutFiltersSelector));
                const sorts =
                    action.payload.sorts ||
                    (yield select(s.docsLayoutSortsSelector));
                value.layout = {
                    filters,
                    sorts,
                };
                break;

            /* istanbul ignore next */
            default:
                console.warn(
                    "currentItemSaga.calcCurrentItem cannot handle action.type=" +
                        action.type
                );
                break;
        }

        yield put(
            saveCurrentItemAction({
                name,
                key,
                value,
                persist: shouldPersist(currentItemUri),
            })
        );
    } catch (e) {
        /* istanbul ignore next */
        console.warn(e);
    }
}

function* saveCurrentItem(action) {
    try {
        const {
            name,
            key,
            value: { layout, ...rest },
            persist,
        } = action.payload;
        // console.log("saveCurrentItem", action.type, key, value);
        yield call(
            [persist ? viewStorage : cacheStorage, "setItem"],
            name,
            rest
        );
        yield call(
            [persist ? viewStorage : cacheStorage, "setItem"],
            key,
            layout
        );
    } catch (e) {
        /* istanbul ignore next */
        console.warn(e);
    }
}

export function getKey(name, currentItemUri) {
    switch (name) {
        // doc* settings are global and not itemUri specific
        case "document":
        case "docmodal":
            // const folderItemUri = currentItemUri.substring(
            //     0,
            //     currentItemUri.indexOf("@eid")
            // );
            // return `${name}_${folderItemUri}`;
            return name;
        default:
            return `${name}_${currentItemUri}`;
    }
}

export function getName(action, currentItemName) {
    let name;
    switch (action.type) {
        case actionTypes.CURRENTITEM_SET:
        case actionTypes.TOGGLES_PERSIST:
            name = action.payload.name;
            break;
        case actionTypes.DOCS_LAYOUT_SET:
            name = currentItemName;
            break;
        case actionTypes.SPLITPANES_PERSIST:
            name = action.payload.view;
            break;
        default:
            console.warn(
                "currentItemSaga.getName cannot handle action.type=" +
                    action.type
            );
            return null;
    }
    return name;
}

function* currentItemSaga() {
    // this will reload settings from storage (and apply them) whenever CURRENTITEM changes
    yield takeEvery(actionTypes.CURRENTITEM_SET, loadSettings);
    // this will calculate the settings for the CURRENTITEM
    yield takeEvery(
        [
            //actionTypes.CURRENTITEM_SET,
            actionTypes.TOGGLES_PERSIST,
            actionTypes.SPLITPANES_PERSIST,
            actionTypes.DOCS_LAYOUT_SET,
        ],
        calcCurrentItem
    );
    // this will save settings in storage
    yield takeEvery(actionTypes.CURRENTITEM_SAVE, saveCurrentItem);
}

export default currentItemSaga;
