import { type FormFieldDTO } from "data/types";
import {
    type FormResultDTO,
    type DocFormResultDTO,
    type FormFieldInfo,
} from "data/types";

import StaticViewerControl from "components/ExtraAttributes/ViewerControl/StaticViewerControl";
import UserIdViewerControl from "components/ExtraAttributes/ViewerControl/UserIdViewerControl";
import ImageViewerControl from "components/ExtraAttributes/ViewerControl/ImageViewerControl";
import LocationViewerControl from "components/ExtraAttributes/ViewerControl/LocationViewerControl";
import GeoRasterViewerControl from "components/ExtraAttributes/ViewerControl/GeoRasterViewerControl";
import EmailViewerControl from "components/ExtraAttributes/ViewerControl/EmailViewerControl";
import TelViewerControl from "components/ExtraAttributes/ViewerControl/TelViewerControl";
import LinkViewerControl from "components/ExtraAttributes/ViewerControl/LinkViewerControl";
import SummaryAttributeViewerControl from "components/ExtraAttributes/ViewerControl/SummaryAttributeViewerControl";

const findCustomField = (
    fields: Array<FormFieldDTO>,
    name: string
): ?FormFieldDTO => {
    return fields.find((f) => f.format === name && f.name === name);
};

export const getFields = (
    form: FormResultDTO | DocFormResultDTO,
    itemUri: string
): ?Array<?FormFieldInfo> => {
    return form?.fields?.map((field) =>
        getFieldType(itemUri, form.formatId, field, form.fields)
    );
};

const getFieldType = (
    itemUri: string,
    formatId: number,
    field: FormFieldDTO,
    fields: Array<FormFieldDTO>
): ?FormFieldInfo => {
    const header = field.header || field.name;

    if (field.extra?.summary)
        return {
            Component: SummaryAttributeViewerControl,
            props: {
                name: field.name,
                header,
                values: field.extra?.summary,
            },
        };

    switch (field.format) {
        case undefined:
            return null;

        case "Image":
            return {
                Component: ImageViewerControl,
                props: {
                    name: header,
                    header,
                    value: field.value,
                },
            };

        case "CreatorName":
            const creatorIdField = findCustomField(fields, "CreatorId");
            /* istanbul ignore next */
            if (creatorIdField === undefined) return null;
            return {
                Component: UserIdViewerControl,
                props: {
                    header,
                    value: field.value,
                    userid: parseInt(creatorIdField.value, 10),
                    width: field.width,
                },
            };

        case "ModifiedName":
            const modifiedIdField = findCustomField(fields, "ModifiedId");
            /* istanbul ignore next */
            if (modifiedIdField === undefined) return null;
            return {
                Component: UserIdViewerControl,
                props: {
                    header,
                    value: field.value,
                    userid: parseInt(modifiedIdField.value, 10),
                    width: field.width,
                },
            };

        case "CreatorId":
        case "ModifiedId":
            // those will not be rendered since they are internally created
            /* istanbul ignore next */
            if (field.format === field.name) return null;
            else
                return {
                    Component: StaticViewerControl,
                    props: {
                        name: field.name,
                        header,
                        value: field.value,
                        width: field.width,
                    },
                };

        case "Text_GeoPoint":
        case "Text_GeoArea":
            return {
                Component: LocationViewerControl,
                props: {
                    name: field.name,
                    header,
                    value: field.value,
                    width: field.width,
                    marker: field.extra && field.extra.marker,
                },
            };

        case "Text_GeoRaster":
            return {
                Component: GeoRasterViewerControl,
                props: {
                    itemUri,
                    formatId,
                    name: field.name,
                    header,
                    value: field.value,
                    width: field.width,
                    marker: field.extra && field.extra.marker,
                },
            };

        case "Text_Lookup":
        case "Text_LookupRep":
            const value =
                field.value == null || field.value.T == null
                    ? null
                    : field.value.T;
            return {
                Component: StaticViewerControl,
                props: {
                    name: field.name,
                    header,
                    value,
                    width: field.width,
                },
            };

        case "Text_TelNumber":
            return {
                Component: TelViewerControl,
                props: {
                    name: field.name,
                    header,
                    value: field.value,
                    isGridCell: false,
                },
            };

        case "Text_EMail":
            return {
                Component: EmailViewerControl,
                props: {
                    name: field.name,
                    header,
                    value: field.value,
                    isGridCell: false,
                },
            };

        case "Text_Link":
            return {
                Component: LinkViewerControl,
                props: {
                    name: field.name,
                    header,
                    value: field.value,
                    isGridCell: false,
                },
            };
        case "WebDavName":
            if (field.extra && field.extra.hidden) {
                return null;
            }
        //fall through
        default:
            return {
                Component: StaticViewerControl,
                props: {
                    name: field.name,
                    header,
                    value: field.value,
                    width: field.width,
                },
            };
    }
};
