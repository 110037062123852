import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";

export const initialState = {
    displayName: "N/A",
    id: -1,
    status: Constants.NONE,
    sites: [],
    mainNavigation: [],
    isWfEnabled: false,
    isAdmin: false,
    avatar: undefined,
    hasOfflineItems: false,
    itemActions: [
        {
            categories: [],
            actions: [],
        },
    ],
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_FETCH_REQUESTED:
            return { ...state, ...action.payload };
        case actionTypes.USER_FETCH_RESULT:
            const { favorites, ...rest } = action.payload;
            return { ...state, ...rest };
        case actionTypes.USER_SITES_RESULT:
            return { ...state, ...action.payload };
        case actionTypes.LOGIN_SIGNOUT:
            return initialState;
        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default userReducer;

export const userIdSelector = (state: Object) => state.user.id;
export const userStatusSelector = (state: Object) => state.user.status;
export const userCorrelationIdSelector = (state: Object) =>
    window.CONFIG.correlationId;
export const userSitesSelector = (state: Object) => state.user.sites;
export const userMainNavigationSelector = (state: Object) =>
    state.user.mainNavigation;
export const userIsAdminSelector = (state: Object) =>
    state.user.status === Constants.OK ? state.user.isAdmin : false;
export const userIsWfEnabledSelector = (state: Object) =>
    state.user.status === Constants.OK ? state.user.isWfEnabled : false;
export const userLanguageSelector = (state: Object) =>
    state.user.status === Constants.OK ? state.user.language : undefined;
export const userDecimalSeparator = (state: Object) =>
    state.user.status === Constants.OK ? state.user.decimalSeparator : ".";
export const userThousandSeparator = (state: Object) =>
    state.user.status === Constants.OK ? state.user.thousandSeparator : ",";
export const userItemActionsSelector = (state: Object) =>
    state.user.itemActions;
export const userFormActionsSelector = (state: Object) =>
    state.user.formActions;
export const userTopbarItemsSelector = (state: Object) =>
    state.user.topbar?.items;
export const userHasOfflineItemsSelector = (state: Object) =>
    state.user.status === Constants.OK ? state.user.hasOfflineItems : false;
