import { openConfirmModal } from "components/ConfirmModal";
import { delItemUri } from "data/api";
import loc from "i18next";
import { toastActionResult } from "data/toast";
import { postMessageTo } from "hooks/PostMessage/Utils";
import { ItemAction } from "hooks/PostMessage/Actions";

/**
 * deletes an existing folder by itemUri
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name: string }} { name }
 */

type Context = {
    itemUri: string,
    name: string,
};

class folder_delete {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;
    shouldRender: boolean = true;

    render = async ({ name }: Context): Promise<boolean> => {
        this.shouldRender = false;
        return await openConfirmModal({
            body: loc.t("commandAction:folder_delete.confirmBody", { name }),
            confirmButton: loc.t("commandAction:folder_delete.confirmYes"),
        });
    };

    execute = async ({ itemUri }: Context): Promise<boolean> => {
        try {
            const success = await delItemUri(itemUri);
            if (success) postMessageTo(ItemAction.Deleted, itemUri, window);
            return success;
        } catch (err) {
            console.warn(err);
            return false;
        }
    };

    report = async (success: boolean, { name }: Context): Promise<boolean> => {
        this.logger(success === true, "commandAction:folder_delete", { name });
    };
}

export default folder_delete;
