import { addOfflineItem } from "data/api";
import { toastActionResult } from "data/toast";
import {
    setOfflineItemSyncStatusAction,
    offlineSyncUpdateAction,
} from "data/actions";
import { OfflineSyncStatus } from "data/types";
import { Dispatch } from "react-redux";

/**
 * Marks an item to be available offline
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name: string }} { name }
 */

type Context = {
    itemUri: string,
    name: string,
    dispatch: Dispatch,
};

class offline_add {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;
    execute = async ({
        itemUri,
        name,
        dispatch,
    }: Context): Promise<boolean> => {
        try {
            const id = await addOfflineItem(itemUri);
            if (id == null) {
                throw new Error("Cannot create offline Item (id is null)");
            }
            dispatch(
                setOfflineItemSyncStatusAction({
                    item: { id, itemUri },
                    status: OfflineSyncStatus.Unknown,
                })
            );
            // Request sync update
            dispatch(offlineSyncUpdateAction());
            return true;
        } catch (error) {
            return false;
        }
    };
    report = async (success: boolean, { name }: Context): Promise<boolean> => {
        this.logger(success === true, "commandAction:offline_add", {
            name,
        });
    };
}

export default offline_add;
