/* istanbul ignore file */

import Loader from "components/Loader";
import { LoadingType } from "data/types";

export default Loader(
    {
        loader: () =>
            import("./index" /* webpackChunkName: "component-EAForm" */),
    },
    LoadingType.Status
);
