import React, { useState, useMemo, useCallback } from "react";
import UploadSelection from "components/UploadSelection";
import CustomUploadSelection from "./CustomUploadSelection";
import { useUppy } from "hooks";

type Props = {
    /** reference to Uppy */
    uppyRef: { current: null | Node },
    /** which uppy ID to use (to separate from other upload queues) */
    uppyId: string,
    /** callback when user adds a file */
    onFileAdded: (file: uppyFile) => void,
    /** callback when user removes a file */
    onFileRemoved: (file: uppyFile) => void,
    /** whether to immediately open File Browse dialog */
    openBrowseDialog?: boolean,
    /** whether to immediately open Camera dialog */
    openCamera?: boolean,
    /** whether to use a custom URL for the file selection (instead of Uppy) */
    openCustomUrl?: string,
    /** current total number of files queued for upload */
    numOfFiles: number,
    /** files to upload */
    files?: Array<DroppedFile>,
};

export const DocumentSelection = ({
    uppyRef,
    uppyId,
    onFileAdded,
    onFileRemoved,
    openBrowseDialog,
    openCamera,
    openCustomUrl,
    numOfFiles,
    files,
}: Props) => {
    const [isOpen, setOpen] = useState(false);
    const events = useMemo(
        () => [
            { type: "file-added", callback: onFileAdded },
            { type: "file-removed", callback: onFileRemoved },
        ],
        [onFileAdded, onFileRemoved]
    );
    const onAddFile = useCallback(() => setOpen(true), []);
    const onClose = useCallback(() => setOpen(false), []);

    uppyRef.current = useUppy({
        uppyId,
        events,
        files,
    });
    /* istanbul ignore if */
    if (uppyRef.current == null) return null;

    if (openCustomUrl)
        return (
            <>
                {(numOfFiles === 0 || isOpen) && (
                    <CustomUploadSelection
                        uppy={uppyRef.current}
                        url={openCustomUrl}
                        onClose={onClose}
                    />
                )}
                {numOfFiles > 0 && !isOpen && (
                    <UploadSelection
                        uppy={uppyRef.current}
                        onAddFile={onAddFile}
                    />
                )}
            </>
        );
    else
        return (
            <UploadSelection
                uppy={uppyRef.current}
                openBrowseDialog={openBrowseDialog}
                openCamera={openCamera}
            />
        );
};

// we are forwarding the new instanced uppy reference up to our parent since we do not want to handle actual uploads
/* istanbul ignore next */
const DocumentSelectionWithRef = React.forwardRef((props: Props, uppyRef) => (
    <DocumentSelection uppyRef={uppyRef} {...props} />
));

export default DocumentSelectionWithRef;
