//@flow
import React, {
    useState,
    useCallback,
    useMemo,
    useRef,
    useEffect,
    useContext,
} from "react";
import { MultiSelectionContext } from "data/context";
import { createPortal } from "react-dom";
import { getDisplayName } from "data/constants";
import ActionsButton from "components/ActionPanel/ActionsButton";
import styles from "./StickyToggle.module.css";
import { isHandheld } from "data/bowser";

type Config = {
    // Whether to render a DIV tag (e.g. if the wrapped component does not support a ref or mouse events)
    renderDiv: boolean,
    usePortal: boolean,
    portalTargetSelector: string,
    portalTargetSelectionMethod: "pop" | "shift",
    //
    getStyle: ({
        node: React.DOMElement,
        isActive: boolean,
        isHovered: boolean,
    }) => CSStyleDefinition,
    rowIndexProp: number,
    isActiveProp: string,
    isHoveredProp: string,
    // props name for onClick callback of ActionsButton
    actionsHandlerProp: string,
    // props name for disabled boolean of ActionsButton
    actionsDisabledProp: string,
    // Callback to check if item should be rendered at all; defaults to true
    shouldRender: () => boolean,
};

const DEFAULT_CONFIG = {
    renderDiv: true,
    usePortal: true,
    portalTargetSelector: "#root",
    portalTargetSelectionMethod: "pop",
    getStyle: /* istanbul ignore next*/ ({ node }) => ({
        position: "absolute",
        transform: "translateX(-100%)",
        zIndex: 99,
        top: node?.getBoundingClientRect().y,
        left: window.innerWidth,
    }),
    rowIndexProp: "index",
    isActiveProp: "isActive",
    isHoveredProp: "isHovered",
    actionsHandlerProp: "onMoreClick",
    actionsDisabledProp: "actionsDisabled",
    shouldRender: () => true,
};

type Props = {
    style?: CSStyleDefinition,
    onMoreClick: () => void,
};

const withStickyToggle =
    ({
        renderDiv = DEFAULT_CONFIG.renderDiv,
        usePortal = DEFAULT_CONFIG.usePortal,
        portalTargetSelector = DEFAULT_CONFIG.portalTargetSelector,
        portalTargetSelectionMethod = DEFAULT_CONFIG.portalTargetSelectionMethod,
        getStyle = DEFAULT_CONFIG.getStyle,
        rowIndexProp = DEFAULT_CONFIG.rowIndexProp,
        isActiveProp = DEFAULT_CONFIG.isActiveProp,
        isHoveredProp = DEFAULT_CONFIG.isHoveredProp,
        actionsHandlerProp = DEFAULT_CONFIG.actionsHandlerProp,
        actionsDisabledProp = DEFAULT_CONFIG.actionsDisabledProp,
        shouldRender = DEFAULT_CONFIG.shouldRender,
    }: Config = DEFAULT_CONFIG) =>
    (WrappedComponent: ComponentType<any>) => {
        const HOC = ({
            style,
            onMouseEnter,
            onMouseLeave,
            ...props
        }: Props) => {
            /* istanbul ignore next */
            const { multiple, isSelected } = useContext(
                MultiSelectionContext
            ) || {
                multiple: false,
                isSelected: (index: number) => false,
            };
            const divRef = useRef(null);
            const actionsHandler = props[actionsHandlerProp];
            const actionsDisabled = props[actionsDisabledProp];
            const isActive = multiple
                ? isSelected(props[rowIndexProp])
                : props[isActiveProp];
            const isHovered = props[isHoveredProp];
            const [show, setShow] = useState(isHandheld());
            const handleMouseEnter = useCallback(
                (...p) => {
                    setShow(true);
                    /* istanbul ignore else */
                    if (typeof onMouseEnter === "function") {
                        onMouseEnter(...p);
                    }
                },
                [onMouseEnter]
            );

            const handleMouseLeave = useCallback(
                (...p) => {
                    setShow(isHandheld());
                    /* istanbul ignore else */
                    if (typeof onMouseLeave === "function") {
                        onMouseLeave(...p);
                    }
                },
                [onMouseLeave]
            );

            const handleClick = useCallback(
                (...p) => {
                    const [event] = p;
                    event.stopPropagation();
                    /* istanbul ignore else */
                    if (typeof actionsHandler === "function") {
                        actionsHandler(...p);
                    }
                },
                [actionsHandler]
            );
            const wrapperProps = useMemo(
                () => ({
                    divRef: renderDiv ? null : divRef,
                    onMouseEnter: handleMouseEnter,
                    onMouseLeave: handleMouseLeave,
                }),
                [handleMouseEnter, handleMouseLeave]
            );

            const calculatedStyle = Object.assign(
                getStyle({
                    node: divRef.current,
                    isActive,
                    isHovered,
                }),
                {
                    opacity: show ? 1 : 0,
                },
                style
            );

            // Get Target from DOM
            const getPortalTarget = useCallback(
                () =>
                    [...document.querySelectorAll(portalTargetSelector)]?.[
                        portalTargetSelectionMethod
                    ](),
                []
            );

            // Handle Portal Target (if DOM is not rendered yet)
            const [portalTarget, setPortalTarget] = useState(getPortalTarget());
            useEffect(() => {
                /* istanbul ignore next */
                if (portalTarget == null) {
                    setPortalTarget(getPortalTarget);
                }
            }, [portalTarget, getPortalTarget]);

            return (
                <>
                    <WrappedComponent {...props} {...wrapperProps} />
                    {renderDiv && (
                        <div
                            ref={divRef}
                            className={styles.wrapper}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        />
                    )}
                    {shouldRender(props) &&
                        show &&
                        typeof actionsHandler === "function" &&
                        (usePortal ? (
                            /*istanbul ignore next */ portalTarget &&
                            createPortal(
                                <ActionsButton
                                    className={styles.button}
                                    style={calculatedStyle}
                                    onClick={handleClick}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    disabled={actionsDisabled}
                                    dataTest={`actionsButtonStickyToggle`}
                                />,
                                portalTarget
                            )
                        ) : (
                            <ActionsButton
                                className={styles.button}
                                style={calculatedStyle}
                                onClick={handleClick}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                disabled={actionsDisabled}
                                dataTest={`actionsButtonStickyToggle`}
                            />
                        ))}
                </>
            );
        };
        HOC.displayName = `withStickyToggle(${getDisplayName(
            WrappedComponent
        )})`;
        return HOC;
    };

export default withStickyToggle;
