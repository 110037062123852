import { resetDocFilters } from "data/actions";

/**
 * clears current doclist filters
 */

class doc_resetFilters {
    execute = ({ dispatch }) => dispatch(resetDocFilters());
}
export default doc_resetFilters;
