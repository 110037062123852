import React from "react";
import { getDisplayName } from "data/constants";
import { itemUriSelector } from "data/reducers/selectors";

const withItemUri = (WrappedComponent: ComponentType<any>) => {
    const HOC = (props: Props) => {
        const itemUri =
            props.itemUri ||
            itemUriSelector({
                location: props.history?.location ?? window.location,
            });
        return <WrappedComponent {...props} itemUri={itemUri} />;
    };
    HOC.displayName = `withItemUri(${getDisplayName(WrappedComponent)})`;
    return HOC;
};

export default withItemUri;
