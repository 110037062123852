// @flow
import React from "react";
import { getLocalizedText } from "data/utils";
import SearchBucket, { getSelected } from "./";
import {
    SORT_BUCKET_NAME,
    type SearchBucketDTO,
    type SearchBucketValueDTO,
} from "data/types";
import styles from "./SearchBucket.module.css";
import moment from "moment";

type Props = {
    item: SearchBucketDTO,
};

const findBucketValue = (
    item: SearchBucketDTO,
    value: number | string | null
): ?SearchBucketValueDTO => {
    const idx = item.buckets.findIndex((b) => b.value === value);
    if (idx === -1) {
        return null;
    } else {
        return item.buckets[idx];
    }
};

const parseSelectedValue = (selected: Array<string | number>): string => {
    const selectedValue = getSelected(selected);
    return Array.isArray(selectedValue)
        ? selectedValue
              .filter((v) => !!v)
              .map((value) =>
                  // if it's a date...
                  moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid()
                      ? // ... format it properly
                        moment(value).format(
                            moment.localeData().longDateFormat("L")
                        )
                      : value
              )
              .join("-")
        : selectedValue;
};

const Name = ({ item }: Props) => {
    const selectedValue = parseSelectedValue(item.selected);
    const caption = getLocalizedText(item.caption || item.name);

    // nothing yet selected, show the searchBucketName (localized)
    if (selectedValue == null) {
        return <span className={styles.value}>{caption}</span>;
    } else {
        // we have a value

        // find localized caption of selected value
        const bucket = findBucketValue(item, selectedValue);
        if (bucket == null) {
            // we could not find it (should not really happen)
            return (
                <span className={styles.value}>
                    {selectedValue
                        ? `${getLocalizedText(item.caption)}: ${selectedValue}`
                        : getLocalizedText(item.caption)}
                </span>
            );
        } else {
            // should we show the searchBucketName (or save screenspace)?
            // note: custom handling of known searchBuckets
            if (
                item.name === "Dateityp" ||
                item.name === "ItemType" ||
                item.hideName === true
            ) {
                // no, just the selected value
                return (
                    <span className={styles.value}>
                        <SearchBucket.Icon
                            name={item.name}
                            value={bucket.value}
                            className={styles.icon}
                        />
                        {getLocalizedText(
                            String(bucket.caption || bucket.value)
                        )}
                    </span>
                );
            } else {
                // yes show it all (otherwise wont be clear)
                return (
                    <span className={styles.value}>
                        {item.name === SORT_BUCKET_NAME ? (
                            <>
                                {/*<Icons.Library name="sort" />*/}
                                {getLocalizedText(item.caption)}:{" "}
                            </>
                        ) : (
                            getLocalizedText(caption) + ": "
                        )}
                        <SearchBucket.Icon
                            name={item.name}
                            value={bucket.value}
                            className={styles.icon}
                        />
                        {getLocalizedText(
                            String(bucket.caption || bucket.value)
                        )}
                    </span>
                );
            }
        }
    }
};
Name.displayName = "FilterBar.SearchBucket.Name";

export default Name;
