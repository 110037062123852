import { getWebDavHttpLink } from "data/api";
import { toastActionResult } from "data/toast";

/**
 * opens webDav edit http link for a document
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name: string }} { name }
 */

type Context = {
    itemUri: string,
    name: string,
};

class doc_editWebDav {
    logger: () => any = toastActionResult;

    execute = async ({ itemUri, name }: Context): Promise<boolean> => {
        try {
            const webDavUrl = await getWebDavHttpLink(itemUri, name);
            if (webDavUrl == null || String(webDavUrl).length === 0) {
                throw new Error(`Invalid WebDavURL`);
            }
            window.location.assign(webDavUrl);
            return true;
        } catch (err) {
            return false;
        }
    };

    report = async (success: boolean): Promise<boolean> => {
        this.logger(success === true, "itemActions:doc_editWebDav");
    };
}

export default doc_editWebDav;
