import React, { useCallback } from "react";
import Icons from "@hs/icons";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "react-bootstrap/lib/Button";
import FormsyEditorBase from "components/ExtraAttributes/EditorControl/FormsyEditorBase";
import SharePrincipalForm from "components/ShareModal/SharePrincipalForm";
import loc from "i18next";
import { SharePrincipalType } from "data/types";
import styles from "./SharePrincipal.module.css";

type Props = {
    name: string,
    extras: any,
    value: Array<SharePrincipalDTO>,
};

const SharePrincipalEditorControl = (props: Props) => {
    const handleChange = useCallback(
        (value, changeValue, currentPrincipal, currentIdx) =>
            changeValue({
                currentTarget: {
                    value: value.map((principal, idx) =>
                        idx === currentIdx ? currentPrincipal : principal
                    ),
                },
            }),
        []
    );
    const handleRemove = useCallback((value, changeValue, currentIdx) => {
        const sharePrincipal = value.filter(
            (principal, idx) => idx !== currentIdx
        );
        changeValue({
            currentTarget: {
                value: sharePrincipal.length ? sharePrincipal : null,
            },
        });
    }, []);
    const { name, value, onChange } = props;
    const handleAdd = useCallback(() => {
        const principals = value != null ? [...value] : [];
        principals.push({
            $type: SharePrincipalType.Share,
            principal: null,
            acl: null,
        });
        onChange(name, principals);
    }, [onChange, name, value]);

    return (
        <FormsyEditorBase
            required={props.isRequired ? "isDefaultRequiredValue" : undefined}
            render={(
                value?: Array<SharePrincipalDTO>,
                changeValue: (name: string, value?: ShareActionDTO) => void,
                isValid: boolean
            ) => (
                <div
                    className={styles.wrapper}
                    data-test="SharePrincipalEditorControl"
                >
                    {!Array.isArray(value) ? (
                        <span className={styles.empty}>
                            {loc.t("share:principal.empty")}
                        </span>
                    ) : (
                        value.map((sharePrincipal, idx) => (
                            <div
                                className={styles.row}
                                key={`share.principal.${idx}`}
                            >
                                <SharePrincipalForm
                                    name={`${props.name}[${idx}]`}
                                    value={sharePrincipal}
                                    extras={props.extras}
                                    onChange={(currentPrincipal) =>
                                        handleChange(
                                            value,
                                            changeValue,
                                            currentPrincipal,
                                            idx
                                        )
                                    }
                                    onRemove={() =>
                                        handleRemove(value, changeValue, idx)
                                    }
                                />
                            </div>
                        ))
                    )}
                    <ButtonToolbar className={styles.toolbar}>
                        <Button
                            aria-label="add"
                            bsStyle="link"
                            className={styles.noBorder}
                            onClick={handleAdd}
                            id={props.name}
                        >
                            <Icons.Library name="user-plus" />{" "}
                            {loc.t("share:principal.new")}
                        </Button>
                    </ButtonToolbar>
                </div>
            )}
            {...props}
        />
    );
};

SharePrincipalEditorControl.displayName = "SharePrincipalEditorControl";
export default SharePrincipalEditorControl;
