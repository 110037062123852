// @flow
import React from "react";
import { useAsync } from "hooks";
import loc from "i18next";
import { createModal } from "react-modal-promise";
import Modal from "components/Modal";
import Status from "components/Status";
import NoData from "components/NoData";
import Icons from "@hs/icons";
import Constants from "data/constants";
import { fetchNewWorkflows } from "data/api";
import { type WorkflowDTO, WorkflowStartLinkMode } from "data/types";
import { openExternalLinkModal } from "components/ExternalLinkModal";
import ItemActions from "components/ActionPanel/ItemActions";
import { tokenize } from "data/utils";

/**
 * shows the Workflow Actions
 */
export const WorkflowActions = ({
    onClick,
    workflows,
}: {
    onClick: (wf: WorkflowDTO) => void,
    workflows: Array<WorkflowDTO>,
}) => {
    const workflowActions = workflows.map((wf) =>
        Object.assign({}, wf, {
            desc: wf.description,
        })
    );
    return <ItemActions actions={workflowActions} onAction={onClick} />;
};
WorkflowActions.displayName = "ListWorkflowsModal.Actions";

type Props = {
    /** whether to show modal */
    open: boolean,
    /** callback when user closes modal */
    close: () => void,
    /** itemUri to start the Workflow with */
    itemUri: string,
};

export const ListWorkflowsModal = ({ open, close, itemUri }: Props) => {
    const state = useAsync(async () => {
        try {
            return await fetchNewWorkflows(itemUri);
        } catch (err) {
            console.warn("Error while fetchNewWorkflows", err);
            throw err;
        }
    }, [itemUri]);

    return (
        <Modal
            docked
            scroll
            show={open !== false}
            aria-labelledby="wf_start-title-lg"
            onHide={close}
        >
            <Modal.Header closeButton>
                <Modal.Title id="wf_start-title-lg">
                    <Icons.Library name="fa-regular fa-square-check" />{" "}
                    {loc.t("itemActions:wf_start.name")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {state.loading && <Status inline status={Constants.LOADING} />}
                {state.error && <Status inline status={Constants.ERROR} />}

                {state.value != null &&
                    Array.isArray(state.value) &&
                    state.value.length > 0 ? (
                    <WorkflowActions
                        workflows={state.value}
                        onClick={(workflow) => {
                            const startLink = itemUri ?
                                tokenize(workflow.startLink, { ITEMURI: encodeURIComponent(itemUri) }) :
                                workflow.startLink;

                            if (
                                workflow.startLinkMode ===
                                WorkflowStartLinkMode.Window
                            ) {
                                window.open(
                                    startLink,
                                    "",
                                    "noopener=yes,noreferrer=yes"
                                );
                            } else {
                                openExternalLinkModal({
                                    url: startLink,
                                    name: workflow.name,
                                    docked:
                                        workflow.startLinkMode ===
                                        WorkflowStartLinkMode.Docked,
                                });
                            }
                            close();
                        }}
                    />
                ) : (
                    <NoData locContext="workflow" />
                )}
            </Modal.Body>
        </Modal>
    );
};
ListWorkflowsModal.displayName = "ListWorkflowsModal";

export const openListWorkflowsModal = createModal(ListWorkflowsModal);
