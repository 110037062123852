// @flow
import React from "react";
import Icons from "@hs/icons";
import loc from "i18next";
import styles from "./LockInfo.module.css";

export const LockInfoOn = ({ date }) => (
    <div className={styles.info}>
        <Icons.Locked height={14} className={styles.icon} />
        {loc.t("common:act.changes_locked", { date })}
    </div>
);

export const UnlockedOn = ({ date }) => (
    <div className={styles.info}>
        <Icons.Library
            name="lock-open"
            className={`${styles.icon} ${styles.unlock}`}
        />
        {loc.t("common:act.changes_unlocked", { date })}
    </div>
);
