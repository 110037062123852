import { isListed } from "data/utils";
import {
    type ItemActionConfigBase,
    type ItemActionsConfigDTO,
} from "data/types";
import { shouldBeVisible } from "./ShouldBeVisible";
import store from "data/store";
import * as s from "data/reducers/selectors";
import { getItemActionsConfig } from "data/itemActions";
import { getDefaultsConfig } from "data/constants";
import unionBy from "lodash/unionBy";
import cloneDeep from "lodash/cloneDeep";

export /**
 * returns true if item (itemAction or category) should be shown to the user
 *
 * @param {ItemActionConfigBase} item action or category to render
 * @param {string} itemUri current item
 * @param {number} formatId current formatId
 * @param {Object} contextProps current contextProps
 * @param {string} [targetView] override view context (from store)
 * @returns {boolean} true if should render, false to hide
 */
const shouldRenderItem = async (
    item: ItemActionConfigBase,
    itemUri: string,
    formatId: number,
    contextProps: Object,
    targetView: ?string
): Promise<boolean> => {
    if (contextProps == null) return false;
    if (item.visible === false) return false;
    if (
        item.views &&
        !check_isView({
            views: item.views,
            targetView,
        })
    )
        return false;
    if (
        item.itemUriRegex &&
        !check_itemUriRegex({
            itemUri,
            itemUriRegex: item.itemUriRegex,
        })
    )
        return false;
    if (
        item.formatIds &&
        !check_formatId({
            formatId,
            formatIds: item.formatIds,
        })
    )
        return false;

    // Add backwards compatibility
    const backwardsCompatibleShouldBeVisible = (
        item.shouldBeVisible ?? [
            "!isRecycleBin",
            "!isSpecialFolder",
            "!isMultiSelection",
            "isOnline",
        ]
    ).slice();

    // hide everything from recyclebin
    if (
        !backwardsCompatibleShouldBeVisible.some((rule) =>
            rule.endsWith("isRecycleBin")
        )
    ) {
        backwardsCompatibleShouldBeVisible.push("!isRecycleBin");
    }
    // hide everything from special folder
    if (
        !contextProps.multiple &&
        !backwardsCompatibleShouldBeVisible.some((rule) =>
            rule.endsWith("isSpecialFolder")
        )
    ) {
        backwardsCompatibleShouldBeVisible.push("!isSpecialFolder");
    }
    // hide everything on multi selection
    if (
        !backwardsCompatibleShouldBeVisible.some(
            (rule) =>
                rule.endsWith("isMultiSelection") ||
                rule.endsWith("isAnySelection")
        )
    ) {
        backwardsCompatibleShouldBeVisible.push("!isMultiSelection");
    }
    // hide everything offline...
    if (
        !backwardsCompatibleShouldBeVisible.some(
            (rule) =>
                rule.endsWith("isOnline") || rule.endsWith("isAnyOnlineStatus")
        )
    ) {
        backwardsCompatibleShouldBeVisible.push("isOnline");
    }

    // apply shouldBeVisible
    return (
        shouldBeVisible({
            item: {
                ...item,
                shouldBeVisible: backwardsCompatibleShouldBeVisible,
            },
            itemUri,
            contextProps,
        }) === true
    );
};

export /**
 * checks if view is (not) included in list (case-insensitive)
 * negate with "!" as prefix
 *
 * @param {{Array<string>}} views to search
 * @param {{?string}} targetView to (not) search for in views
 * @returns {boolean} true if match found
 */
const check_isView = ({
    views,
    targetView,
}: {
    views: Array<string>,
    targetView: ?string,
}) => {
    if (views.length === 0) return false;
    const targetViewOrCurrent = targetView
        ? targetView
        : s.currentNameSelector(store.getState());
    const negatedViews = views
        .filter((l) => l.startsWith("!"))
        .map((l) => l.substr(1));
    const defaultViews = views.filter((l) => !l.startsWith("!"));
    return (
        (negatedViews.length === 0 ||
            !isListed(targetViewOrCurrent, negatedViews)) &&
        (defaultViews.length === 0 ||
            isListed(targetViewOrCurrent, defaultViews))
    );
};

export /**
 * checks if itemUri matches the itemUriRegex regular expression
 * @returns {boolean} true if match found
 */
const check_itemUriRegex = ({
    itemUri,
    itemUriRegex,
}: {
    itemUri: string,
    itemUriRegex: string,
}) => new RegExp(itemUriRegex, "i").exec(itemUri) !== null;

export /**
 * checks if formatId is included in the formatIds list
 * @returns {boolean} true if included
 */
const check_formatId = ({
    formatId,
    formatIds,
}: {
    formatId: number,
    formatIds: Array<number>,
}) => formatIds.includes(formatId);

export const mergePartialConfig = (userActions, sysActions) => {
    let result = cloneDeep(userActions);
    if (result)
        result.forEach((userAction) => {
            // is the userAction trying to override an existing system action (by id)?
            const sysIndex = sysActions.findIndex(
                (sa) => sa.id === userAction.id
            );
            if (sysIndex !== -1) {
                const sysAction = sysActions[sysIndex];
                // merge properties (in case user missed/does not want to specify any)
                Object.keys(sysAction).forEach((key) => {
                    userAction[key] = userAction[key] || sysAction[key];
                });
            }
        });
    return result;
};

/** merges our data/itemActions.js with customer defined actions from appsettings.json */
export const mergeItemActionsConfig = (
    itemUri: string,
    formatId: number
): ItemActionsConfigDTO => {
    // get itemActions as defined in appsettings.json (after being checked for security for this user)
    const userActions = cloneDeep(s.userItemActionsSelector(store.getState()));
    const itemActions = cloneDeep(getItemActionsConfig());

    // get itemUri specific itemActions
    const defaults = getDefaultsConfig(itemUri, formatId);
    if (defaults && defaults.itemActions) {
        if (defaults.itemActions.replace === true) {
            return {
                actions:
                    mergePartialConfig(
                        defaults.itemActions.actions,
                        itemActions.actions
                    ) || [],
                categories:
                    defaults.itemActions.categories || itemActions.categories, // use our system categories in case they are not specified
            };
        } else {
            return {
                actions: unionBy(
                    defaults.itemActions.actions,
                    userActions.actions,
                    itemActions.actions,
                    "id"
                ),
                categories: unionBy(
                    defaults.itemActions.categories,
                    userActions.categories,
                    itemActions.categories,
                    "id"
                ),
            };
        }
    } else {
        return {
            actions: unionBy(userActions.actions, itemActions.actions, "id"),
            categories: unionBy(
                userActions.categories,
                itemActions.categories,
                "id"
            ),
        };
    }
};
