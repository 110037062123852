import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";

const initialState = {
    favorites: [],
    status: Constants.NONE,
};

const favoritesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FAVORITES_FETCH_REQUEST:
            return { ...state, ...action.payload };
        case actionTypes.FAVORITES_FETCH_RESULT:
            return { ...state, ...action.payload };

        // first time loaded as part of the User object
        case actionTypes.USER_FETCH_RESULT:
            return {
                ...state,
                status: Constants.OK,
                favorites: action.payload.favorites,
            };

        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default favoritesReducer;

export const favoritesStatusSelector = (state: Object) =>
    state.favorites.status;
export const favoritesFavoritesSelector = (state: Object) =>
    state.favorites.favorites;
