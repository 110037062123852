import { formSetEditModeAction } from "data/actions";

/**
 * starts editing the current folder form
 */

class folder_editClassification {
    execute = ({ dispatch }) => dispatch(formSetEditModeAction("folder", true));
}
export default folder_editClassification;
