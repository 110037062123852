import loc from "i18next";
import {
    LocalDateStringOption,
    ShareType,
    type ShareTypeEnum,
} from "data/types";
import { userLanguage } from "data/storeHelper";

export const isEditable = (shareType: ShareTypeEnum): boolean =>
    [ShareType.Anonym, ShareType.WebShare, ShareType.Share].includes(shareType);

export const getShareDate = (expires: string) =>
    expires
        ? loc.t(`share:action.date`, {
              expires: new Date(expires).toLocaleDateString(
                  userLanguage(),
                  LocalDateStringOption.Date
              ),
          })
        : "";

export const getBytes = (bytes: number): string => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
        (!bytes && "0 Bytes") ||
        (bytes / Math.pow(1024, i)).toFixed(2) +
            " " +
            ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][i]
    );
};

export const getItemKey = (isDoc: boolean, isMixed: boolean = false) =>
    isMixed ? "mixed" : isDoc ? "document" : "folder";
export const getActionKey = (isEdit: boolean) => (isEdit ? "update" : "create");

export /**
 * Maps EnumValues to their designated icons
 *
 * @param { id: string } { enum value identifier }
 * @returns {string} fontawesome 4.7 icon identifier
 */
const getEnumIcon = (id: string): ?string => {
    switch (id) {
        // ShareAccessControlListType
        case "view":
            return "eye";
        case "edit":
            return "pencil";
        case "delete":
            return "fa-regular fa-square-pen";
        // ShareType
        case "anonym":
            return "earth-europe";
        case "webshare":
            return "cloud-arrow-up";
        case "link":
            return "link";
        case "share":
            return "share-nodes";
        case "attachment":
            return "fa-regular fa-envelope";
        case "pdf":
            return "fa-regular fa-file-pdf";
        /* istanbul ignore next */
        default:
            return undefined;
    }
};
