import { openReminderManagerModal } from "components/ReminderManagerModal";
import { type ReminderDTO } from "data/types";

type Context = {
    itemUri: string,
    name: string,
    onSave?: () => void,
    reminder?: ReminderDTO,
};

class reminder_manage {
    /**
     * Opens Modal to manage (insert/update) Reminder
     *
     * @param {{ itemUri: string }} { itemUri }
     * @param {{ name: string }} { name }
     * @param {{ onSave?: () => void ) }} { onSave }
     * @param {{ reminder?: ReminderDTO }} { reminder }
     */
    execute = async ({
        itemUri,
        name,
        onSave,
        reminder,
    }: Context): Promise<boolean> => {
        await openReminderManagerModal({ itemUri, name, onSave, reminder });
        return true;
    };
}
export default reminder_manage;
