// @flow
import React, { useCallback, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { withFormsy } from "formsy-react";
import FormGroup from "react-bootstrap/lib/FormGroup";
import AttributeLabel from "components/ExtraAttributes/AttributeLabel.js";
import Error from "components/ExtraAttributes/Error.js";
import styles from "components/ExtraAttributes/AttributeLabel.module.css";
import { RememberValuesContext } from "data/context";

export type FormsyEditorBaseDTO = {
    name: string,
    value: any,
};

type Props = {
    /** class attribute for wrapper */
    baseClassName: string,
    /** label to display */
    header: string,
    /** unique field name */
    name: string,
    /** current field's value */
    value: string,
    /** React component to render after header before value */
    afterHeaderComponentClass?: Node,
    /** React component to render value */
    render: (value: any, changeValue: Function) => Node,
    /** Additional react component to render */
    additionalRender?: Node,
    /** Should Formsy Base render just the child? (No wrapping container) */
    isAdditionalRender: boolean,
    /** callback when value has been changed
     * @param {string} name field's name
     * @param {any} value new field's value
     */
    /** whether to render validation below */
    showValidationBelow: boolean,
    /** whether form field is required */
    isRequired: Boolean,
    onChange?: (name: string, value: any) => void,
    /** override for additional custom validation */
    overrideIsValid?: boolean,
    /** override isValid */
    overrideIsValid?: boolean,
    /** whether to show field's label */
    showLabel: boolean,
    /** withFormsy(): setValue */
    setValue: Function,
    /** withFormsy(): isValid */
    isValid: boolean,
    /** withFormsy(): errorMessage */
    errorMessage: ?string,
    /** callback when an attribute is edited
     * @param {string} name unique name of attribute being changed
     * @param {any} value new value
     */
    onChange: (name: string, value: any) => void,
    /** optional key to force refresh */
    loadIndex?: any,
    /** optional extras */
    extras?: any,
    /** callback when validation changes
     * @param {string} name unique name of attribute being changed
     * @param {boolean} isValid whether the field is valid
     * @returns {void}
     * */
    onValidationChange?: (name: string, isValid: boolean) => void,
};

export const FormsyEditorBase = ({
    baseClassName,
    header,
    name,
    value,
    afterHeaderComponentClass,
    render,
    isRequired,
    onChange,
    overrideIsValid,
    isValid,
    errorMessage,
    setValue,
    loadIndex,
    extras,
    onValidationChange,
    showLabel = true,
    isAdditionalRender = false,
    additionalRender = null,
    isReadonly = true,
    useHtmlFor = true,
}: Props) => {
    /* istanbul ignore next */
    const { remember, renderCheckbox } = useContext(RememberValuesContext) || {
        remember: false,
        renderCheckbox: null,
    };

    const showValidationBelow = !useSelector(
        (state) => state.browser.greaterThanOrEqual.medium
    );

    useEffect(() => {
        if (typeof onValidationChange == "function")
            onValidationChange(name, isValid);
    }, [isValid, onValidationChange, name]);

    const handleChangeValue = useCallback(
        (value) => {
            setValue(value);
            /* istanbul ignore else */
            if (typeof onChange === "function") {
                onChange(name, value);
            }
        },
        [setValue, onChange, name]
    );

    const changeValue = useCallback(
        (changed) => {
            if (
                changed &&
                Object.prototype.hasOwnProperty.call(changed, "currentTarget")
            ) {
                handleChangeValue(changed.currentTarget.value);
            } else if (
                changed &&
                Array.isArray(changed) &&
                changed.length > 0
            ) {
                changed.forEach(({ name: fieldName, value }) => {
                    if (fieldName === name) {
                        handleChangeValue(value);
                    } else if (typeof onChange === "function") {
                        onChange(fieldName, value);
                    } else {
                        console.warn(`Cannot update ${fieldName}`);
                    }
                });
            } else {
                console.warn(`Unknown changed type, cannot update ${name}`);
            }
        },
        [handleChangeValue, onChange, name]
    );

    const renderField = useCallback(
        () => (
            <>
                {render(value, changeValue, isValid)}
                {!isValid && (
                    <Error
                        errorMessage={errorMessage}
                        showValidationBelow={showValidationBelow}
                    />
                )}
            </>
        ),
        [value, changeValue, isValid, errorMessage, showValidationBelow, render]
    );

    const hasCheckbox = remember && !isReadonly;

    if (isAdditionalRender) {
        return (
            <>
                {hasCheckbox && renderCheckbox(name, header, styles.checkbox)}
                {renderField()}
            </>
        );
    }

    const valid = overrideIsValid != null ? overrideIsValid : isValid;
    const hasLabel = hasCheckbox || showLabel;

    return (
        <FormGroup
            className={baseClassName}
            controlId={useHtmlFor ? name : null}
            validationState={valid ? null : "error"}
            data-test={`formGroup${name?.replace(" ", "")}${
                isRequired /* istanbul ignore next */ && "Required"
            }`}
        >
            {hasLabel && (
                <AttributeLabel
                    label={showLabel && header}
                    className={styles.label}
                >
                    {hasCheckbox && renderCheckbox(name, header)}
                </AttributeLabel>
            )}
            {typeof afterHeaderComponentClass === "function" &&
                afterHeaderComponentClass()}
            <div className="form-field">
                {renderField()}
                {additionalRender}
            </div>
        </FormGroup>
    );
};

const FormsyEditorBaseContainer = withFormsy(FormsyEditorBase);

export default FormsyEditorBaseContainer;
