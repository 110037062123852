import React, { useCallback, useState } from "react";
import { useAsync, useAbortController } from "hooks";
import { fetchReminders, deleteReminder } from "data/api";
import Constants from "data/constants";
import Status from "components/Status";
import ReminderItem from "components/Reminder/ReminderItem";
import {
    ReminderTypeFlags,
    LocalDateStringOption,
    type ItemActionViewConfig,
    type ItemActionConfig,
} from "data/types";
import { toastActionResult } from "data/toast";
import { openConfirmModal } from "components/ConfirmModal";
import loc from "i18next";
import { userLanguage } from "data/storeHelper";
import CommandActionHandler from "components/CommandActions";

type Props = {
    onClick: (props: Object) => void,
    itemUri: string,
    action: ItemActionConfig,
    view: ItemActionViewConfig,
};

function getLocContextFor(reminder) {
    return {
        date: new Date(reminder.date).toLocaleDateString(
            userLanguage(),
            LocalDateStringOption.DateTime
        ),
        user: reminder.recipient.displayName,
    };
}

const ItemView_Reminder = ({ onClick, itemUri, action }: Props) => {
    const [reminders, setReminders] = useState(null);
    const abortController = useAbortController();

    const handleDelete = useCallback(
        (reminder) => {
            const locContext = getLocContextFor(reminder);
            openConfirmModal({
                body: loc.t("reminder:action_delete.confirmBody", locContext),
                confirmButton: loc.t("reminder:action_delete.confirmYes"),
            }).then((confirmed) => {
                /* istanbul ignore else */
                if (confirmed === true) {
                    deleteReminder(
                        reminder.id,
                        itemUri,
                        abortController(true).signal
                    )
                        .then((success) => {
                            if (success) {
                                const _reminders = reminders.filter(
                                    (r) => r.id !== reminder.id
                                );
                                setReminders(_reminders);
                            }
                            toastActionResult(
                                success === true,
                                "reminder:action_delete",
                                locContext
                            );
                            return success;
                        })
                        .catch((error) => {
                            console.error(error);
                            toastActionResult(
                                false,
                                "reminder:action_delete",
                                locContext
                            );
                        });
                }
            });
        },
        [reminders, abortController, itemUri]
    );

    const handleSave = useCallback(
        (reminder, id) => {
            /* istanbul ignore else */
            if (id > 0) {
                const _reminder = {
                    ...reminder,
                    creator: { ...reminder.creator },
                    recipient: { ...reminder.recipient },
                    id,
                };
                const _reminders = [...reminders];
                const idx = _reminders.findIndex((r) => r.id === id);
                if (idx > -1) {
                    _reminders.splice(idx, 1, reminder);
                } else {
                    _reminders.push(_reminder);
                }
                setReminders(_reminders);
            }
        },
        [reminders]
    );

    const handleEdit = useCallback(
        (reminder) =>
            CommandActionHandler(action.commandAction.name, {
                ...action.commandAction.props,
                reminder,
                onSave: handleSave,
            }),
        [handleSave, action.commandAction.name, action.commandAction.props]
    );

    const handleReadOnly = useCallback(
        (reminder, type) =>
            toastActionResult(
                false,
                `reminder:action_readonly_${type}`,
                getLocContextFor(reminder)
            ),
        []
    );

    const state = useAsync(async () => {
        try {
            const reminders = await fetchReminders(
                itemUri,
                abortController(true).signal
            );
            setReminders(reminders);
        } catch (e) {
            console.error(e);
            throw e;
        }
    });
    if (state.loading || state.error) {
        return (
            <Status
                status={state.loading ? Constants.LOADING : Constants.ERROR}
                inline={true}
            />
        );
    }
    const isLoaded =
        !(state.loading || state.error) && Array.isArray(reminders);
    const hasReminders = isLoaded && reminders.length > 0;
    if (!hasReminders) {
        return <div style={{ marginTop: 5 }} />;
    }
    return reminders.map((r) => (
        <ReminderItem
            reminder={r}
            key={`reminder.${r.id}`}
            onEdit={() =>
                r.isReadOnly ? handleReadOnly(r, "edit") : handleEdit(r)
            }
            isEditAllowed={!r.isReadOnly}
            onDelete={() =>
                r.isReadOnly ? handleReadOnly(r, "delete") : handleDelete(r)
            }
        />
    ));
};

ItemView_Reminder.displayName = "ItemViews.Reminder";
export default ItemView_Reminder;
