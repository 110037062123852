import React, { useState } from "react";
import { useAsync } from "hooks";
import Constants from "data/constants";
import { fetchDocs } from "data/api";
import Status from "components/Status";
import CountBadge from "components/CountBadge";
import ViewModes from "components/FilterBar/ViewModes";
import {
    ListViewMode,
    type ItemActionViewConfig,
    type ItemActionConfig,
} from "data/types";
import styles from "./itemView_docList.module.css";
import DocListSimple from "components/DocListSimple";
import RowResultByLayout from "components/DocListSimple/RowResultByLayout";
import Divider from "components/Divider";
import ItemViewDocListGallery from "./itemView_docListGallery";

type ItemActionViewDocListConfig = ItemActionViewConfig & {
    viewMode?: ListViewModeEnum,
    layout?: DocListLayout,
};

/**
 * shows a doclist view for a folder
 *
 * @param {{ itemUri: string }} { itemUri }
 */
const ItemView_DocList = ({
    itemUri,
    action,
    view,
    onItemUriAction,
}: {
    itemUri: string,
    action: ItemActionConfig,
    view: ItemActionViewDocListConfig,
    onItemUriAction: (itemUri: string) => void,
}) => {
    const [viewMode, setViewMode] = useState(
        view.viewMode || ListViewMode.List
    );

    // get documents
    const state = useAsync(async () => {
        try {
            const docs = await fetchDocs(
                itemUri,
                0,
                view.layout && view.layout.sorts,
                view.layout && view.layout.filters,
                view.layout && view.layout.cols
            );
            return docs;
        } catch (err) {
            console.warn("Error while fetchDocs", err);
            throw err;
        }
    }, [itemUri]);

    if (state.loading || state.error) {
        return (
            <Divider
                key={`itemView_doclist_${action.id}`}
                name={`itemView_doclist_${action.id}`}
                hideHR
                className={styles.top}
                header={
                    <>
                        <div>
                            {view.header}{" "}
                            {state.loading && (
                                <Status
                                    status={Constants.LOADING}
                                    inline={true}
                                />
                            )}
                            {state.error && (
                                <Status
                                    status={Constants.ERROR}
                                    inline={true}
                                />
                            )}
                        </div>
                    </>
                }
            />
        );
    } else {
        return (
            <Divider
                key={`itemView_doclist_${action.id}`}
                name={`itemView_doclist_${action.id}`}
                hideHR
                className={styles.top}
                header={
                    <>
                        <div>
                            {view.header}
                            <CountBadge count={state.value.rowCount} />
                        </div>
                        {state.value.rowCount > 0 && (
                            <ViewModes
                                viewMode={viewMode}
                                viewModes={[
                                    ListViewMode.List,
                                    ListViewMode.Thumbnails,
                                ]}
                                onChange={
                                    /* istanbul ignore next */ (viewMode) =>
                                        setViewMode(viewMode)
                                }
                            />
                        )}
                    </>
                }
            >
                <>
                    {viewMode === ListViewMode.List && (
                        <div className={styles.wrapper}>
                            <DocListSimple
                                minRowHeight={25}
                                RenderRowComponentClass={RowResultByLayout}
                                result={state.value}
                                totalCount={state.value.rowCount}
                                loadMoreRows={
                                    /* istanbul ignore next */ () =>
                                        console.warn(
                                            "Paging is not supported for the ItemView_DocList!"
                                        )
                                }
                                setSorting={
                                    /* istanbul ignore next */ () =>
                                        console.warn(
                                            "Setting Sorting is not supported for the ItemView_DocList!"
                                        )
                                }
                                layout={view.layout}
                                requestShowFilter={
                                    /* istanbul ignore next */ () =>
                                        console.warn(
                                            "Setting Filters is not supported for the ItemView_DocList!"
                                        )
                                }
                                showThumbnails={false}
                                showFilterbar={false} // cannot use Filters/Sorting due to centralized Redux store
                                onSingleClick={
                                    /* istanbul ignore next */ (
                                        e,
                                        itemUri,
                                        index
                                    ) => onItemUriAction(itemUri)
                                }
                            />
                        </div>
                    )}
                    {viewMode === ListViewMode.Thumbnails && (
                        <ItemViewDocListGallery
                            result={state.value}
                            onClick={
                                /* istanbul ignore next */ (itemUri) =>
                                    onItemUriAction(itemUri)
                            }
                        />
                    )}
                </>
            </Divider>
        );
    }
};

export default ItemView_DocList;
