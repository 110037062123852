import {
    HttpMethod,
    ContentType,
    CacheStrategy,
    SearchRequestType,
    SearchScope,
    NewFormType,
    ItemFlowTemplateType,
    ItemContextsFlags,
} from "data/types";
import type {
    Dictionary,
    ApiMethod,
    UserInfoResultDTO,
    CardItemResultDTO,
    TreeDTO,
    BreadcrumbResultDTO,
    FormResultDTO,
    SearchFormResultDTO,
    DocListFilterRequestDTO,
    DocListSortRequestDTO,
    DocsResultDTO,
    WorkflowDTO,
    WorkflowInfoDTO,
    WorkflowProviderDTO,
    SAYTOptionDTO,
    SearchResultDTO,
    SearchCountResultDTO,
    SearchRequestDTO,
    SearchFilterDTO,
    UserPrincipalDTO,
    GroupPrincipalDTO,
    ExtraAttributesEmbeddedGridDTO,
    SortDirectionEnum,
    ItemLinkTypeEnum,
    ActivitiesDTO,
    NewFormTypeEnum,
    LookupValuesResultDTO,
    FormLookupTypeEnum,
    NewsFeedDTO,
    SubscribeResponseDTO,
    UserSettingsResultDTO,
    SearchFormInfoDTO,
    UserSettingsDTO,
    ItemFlowsConfigDTO,
    ItemFlowDirectionEnum,
    SendNotificationRequestDTO,
    SendDocumentsRequestDTO,
    NewItemResultDTO,
    RasterMapResultDTO,
    ReminderDTO,
    ShareDTO,
    TransferItemRequestDTO,
    // SearchValueTypeEnum
} from "data/types";

import { getGeoRasterProvider } from "components/MapViewer/providerSupport";

/**
 * ApiCatalog defines ALL WebApi methods provided by inPoint.Web.Server.
 *
 * Each method is identified by name (the "key"), and the value is the metadata:
 * - url (without the __api/ prefix); e.g. "/user/cards"
 * - HTTP method (per default GET)
 *
 * Cache: caching settings for:
 * 1. resilience - unstable network connection
 *    Cached by service-worker.custom.js; do not define to use default caching strategy,
 *    set to false to always avoid caching (equals to CacheStrategy.NetworkOnly) or define
 *    extra properties as such:
 *    - strategy: defaults to CacheStrategy.StaleWhileRevalidate
 *      Available: None, StaleWhileRevalidate, CacheFirst, NetworkFirst, NetworkOnly, CacheOnly
 *
 * 2. offline items - fully synchronized for full offline work.
 *    Handled by ApiManagerMakeAvailableOffline.js, uses following cache settings:
 *    - keyParams: per default the URL is used as the CacheKey - here you can
 *      optionally choose to omit/add other params
 *    - transformer: transform the original body (only JSON) before caching
 *    - depsAnalyzer: define which other dependencies exist for the current method
 *      (note: you can view the latest dependency tree with CommandAction offline_depsMap)
 *
 * Notes:
 * - resilience is currently only for GET methods
 * - while online the caching is handled by service-worker.js; when offline it's
 *   done directly by ApiManager (since Workbox can only check ONE specific Cache
 *   and we might have items across multiple ones)
 * - caching of offline items ("synchronization") is handled only by ApiManagerMakeAvailableOffline;
 *   this includes GET/PUT/POST methods
 * -
 */

export /* istanbul ignore next*/ const ApiCatalog: Dictionary<
    string,
    ApiMethod
> = {
    //#region User

    /**
     * @returns {UserInfoResultDTO}
     */
    fetchUser: {
        url: "/user",
        cache: {
            strategy: CacheStrategy.NetworkFirst,
        },
    },

    /**
     * @returns {Array<SidebarItemDTO>}
     */
    fetchSites: {
        url: "/user/sites",
        cache: {
            strategy: CacheStrategy.NetworkFirst,
        },
    },

    /**
     * @returns {Array<CardItemResultDTO>}
     */
    fetchCards: {
        url: "/user/cards",
    },
    /**
     * @returns {Array<CardItemResultDTO>}
     */
    fetchCardsByEntity: {
        url: "/user/cards",
        method: HttpMethod.POST,
        offline: {
            storeHelper: "cardsCards",
        },
    },

    /**
     * @returns {UserSettingsResultDTO}
     */
    fetchUserSettings: {
        url: "/user/settings",
    },

    /**
     * @param {UserSettingsDTO} settings
     * @returns {boolean}
     */
    saveUserSettings: {
        method: HttpMethod.POST,
        url: "/user/settings",
    },

    /**
     * @returns {NewsFeedDTO}
     */
    fetchNews: {
        url: "/user/feed",
        cache: {
            strategy: CacheStrategy.NetworkFirst,
        },
    },

    // ----- favorites

    /**
     * @returns {Array<string>}
     */
    fetchFavorites: {
        url: "/user/fav",
        cache: {
            strategy: CacheStrategy.NetworkFirst,
        },
    },

    /**
     * @param {string} itemUri
     * @param {string} name
     * @returns {boolean}
     */
    addFavorite: {
        method: HttpMethod.PUT,
        url: "/user/fav",
        offline: {
            allowEdit: true,
            transformer: () => true,
        },
    },

    /**
     * @param {string} itemUri
     * @returns {boolean}
     */
    delFavorite: {
        method: HttpMethod.DELETE,
        url: "/user/fav",
        offline: {
            allowEdit: true,
            transformer: () => true,
        },
    },

    /**
     * @param {SearchRequestDTO} searchRequest
     * @returns {boolean}
     */
    addSearchFavorite: {
        method: HttpMethod.PUT,
        url: "/search/fav",
    },

    /**
     * @param {number} linkId
     * @returns {boolean}
     */
    delFavoriteLink: {
        method: HttpMethod.DELETE,
        url: "/user/fav",
        offline: {
            allowEdit: true,
            transformer: () => true,
        },
    },

    // ----- mails

    // TODO move to /item/mail
    /**
     * @param {SendNotificationRequestDTO} request
     * @returns {boolean}
     */
    sendMail: {
        method: HttpMethod.POST,
        url: "/user/mail",
    },

    // TODO move to /item/mail/doc
    /**
     * @param {SendDocumentsRequestDTO} request
     * @returns {boolean}
     */
    sendMailAttachments: {
        method: HttpMethod.POST,
        url: "/user/maildoc",
    },
    //#endregion

    //#region Items
    /**
     * @param {string} itemUri
     * @returns {BreadcrumbResultDTO}
     */
    fetchBreadcrumb: {
        url: "/folder/breadcrumb",
    },

    // ----- Reminders
    /**
     * @param {string} itemUri
     * @returns {Array<ReminderDTO>}
     */
    fetchReminders: {
        url: "/item/reminders",
    },

    /**
     * @param {ReminderDTO} reminderRequest
     * @returns {number}
     */
    upsertReminder: {
        method: HttpMethod.POST,
        url: "/item/reminder",
    },

    /**
     * @param {number} reminderId
     * @param {string} itemUri
     * @returns {number}
     */
    deleteReminder: {
        method: HttpMethod.DELETE,
        url: "/item/reminder",
    },

    // ----- shares
    /**
     * @param {string} itemUri
     * @returns {ShareDTO}
     */
    fetchShare: {
        url: "/item/share",
    },

    /**
     * @param {ShareDTO} shareRequest
     * @returns {boolean}
     */
    createShare: {
        method: HttpMethod.POST,
        url: "/item/share",
    },

    /**
     * @param {ShareDTO} shareRequest
     * @returns {boolean}
     */
    updateShare: {
        method: HttpMethod.PUT,
        url: "/item/share",
    },

    /**
     * @param {string} itemUri
     * @returns {boolean}
     */
    deleteShare: {
        method: HttpMethod.DELETE,
        url: "/item/share",
    },

    /**
     * @param {Array<string>} itemUris
     * @param {Array<string>} webDavName
     * @returns {Array<string>}
     */
    checkShareName: {
        method: HttpMethod.POST,
        url: "/item/share/unique",
    },

    /**
     * @param {Array<string>} itemUris
     * @param {Array<number>} formatIds
     * @returns {ShareContextDTO}
     */
    fetchShareContext: {
        method: HttpMethod.POST,
        url: "/item/share/context",
    },
    // ----- items

    /**
     * @param {string} itemUri
     * @returns {Array<ActivitiesDTO>}
     */
    fetchActivities: {
        url: "/item/activity",
    },

    /**
     * @returns {Array<OfflineItemDTO>}
     */
    fetchOfflineItems: {
        url: "/offline/all",
        cache: {
            strategy: CacheStrategy.NetworkFirst,
        },
    },

    /**
     * @param {string} itemUri
     * @returns {string} guid or null
     */
    addOfflineItem: {
        method: HttpMethod.POST,
        url: "/offline",
        offline: {
            allowEdit: true,
            transformer: () => crypto.randomUUID(), //TODO: Fix for WebKit < 92,
        },
    },

    /**
     * @param {string} itemUri
     * @returns {boolean} success
     */
    delOfflineItem: {
        method: HttpMethod.DELETE,
        url: "/offline",
        offline: {
            allowEdit: true,
            transformer: () => true,
        },
    },

    // called directly by service-worker.custom.js
    // /**
    //  * @returns {Array<string>}
    //  */
    // fetchOfflinePrecache: {
    //     // TODO move fetchOfflinePrecache to /user (or /offline?)
    //     url: "offline/precache",
    //     cache: {
    //         strategy: CacheStrategy.NetworkOnly,
    //     },
    // },

    context: {
        method: HttpMethod.POST,
        url: "/item/context",
        cache: {
            strategy: CacheStrategy.NetworkFirst,
            keyParams: (params) => ({
                itemUri: params.itemUris.length === 1 && params.itemUris[0],
            }),
            depsAnalyzer: (data: ItemContextDTO, params: Object) => {
                const itemUri = params.itemUris?.[0];
                const formatId = params.formatIds?.[0];
                const {
                    isDoc,
                    isPdf,
                    canPdf,
                    hasPdf,
                    isReadonly,
                    ext,
                    isEditAllowed,
                } = data || {};
                const depsApis = [
                    {
                        name: "getFolderItemUri",
                        params: { itemUri },
                    },
                ];
                if (isDoc) {
                    depsApis.push({
                        name: "fetchDownloadToken",
                        params: {
                            itemUri,
                            pdf:
                                window.CONFIG.general.htmlViewerExtensions.includes(
                                    String(ext).toLowerCase()
                                ) ||
                                window.CONFIG.general.imageViewerExtensions.includes(
                                    String(ext).toLowerCase()
                                )
                                    ? undefined
                                    : hasPdf || isPdf || canPdf || undefined,
                            stream:
                                window.CONFIG.general.mediaViewerExtensions.includes(
                                    String(ext).toLowerCase()
                                ) || undefined,
                        },
                        transformer: (token) => itemUri,
                    });
                    depsApis.push({
                        name: "fetchThumbnail",
                        params: { itemUri },
                    });
                } else if (!isReadonly) {
                    depsApis.push({
                        name: "fetchItemFlows",
                        params: {
                            dir: "in",
                            itemUri,
                        },
                    });
                }
                // TODO #54607 appSettings offline specs - allow editing
                if (isEditAllowed) {
                    depsApis.push(
                        {
                            name: "fetchNewForm",
                            params: { itemUri },
                        },
                        {
                            name: "validateForm",
                            params: {
                                itemUri,
                                isDoc,
                                values: {},
                            },
                        }
                    );
                    // parse inPoint.Fields
                    if (params.fields?.length > 0) {
                        for (const field of params.fields) {
                            switch (field.format) {
                                case "Text_LookupRep":
                                //lint -fallthrough
                                case "Text_Lookup":
                                    depsApis.push({
                                        name: "fetchFormLookup",
                                        params: {
                                            filters: [],
                                            formatId,
                                            id: field.id,
                                            name: field.name,
                                            pageNum: 0,
                                            isDoc,
                                            lookupType: 0,
                                            keyword: "",
                                            itemUri,
                                        },
                                    });
                                    break;
                                default:
                                    break;
                            }
                        }
                    }

                    // parse ExtraAttributes
                    if (params.attributes?.length > 0) {
                        for (const ea of params.attributes) {
                            switch (ea.format) {
                                case "Lookup_Combo":
                                    depsApis.push({
                                        name: "fetchFormLookup",
                                        params: {
                                            filters: [],
                                            formatId,
                                            id: ea.id,
                                            name: ea.name,
                                            pageNum: 0,
                                            isDoc,
                                            lookupType: 1,
                                            keyword: "",
                                            itemUri,
                                        },
                                    });
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
                return depsApis;
            },
        },
    },

    /**
     * @param {string} itemUri
     * @param {string} name
     * @returns {boolean}
     */
    subscribe: {
        method: HttpMethod.PUT,
        url: "/item/subscribe",
        offline: {
            allowEdit: true,
            transformer: () => true,
        },
    },

    // TODO should be /item/subscribe
    /**
     * @param {string} itemUri
     * @returns {SubscribeResponseDTO}
     */
    isSubscribed: {
        url: "/item/subscribed",
    },

    /**
     * @param {string} itemUri
     * @returns {boolean}
     */
    unsubscribeByItemUri: {
        method: HttpMethod.DELETE,
        url: "/item/subscribe",
        offline: {
            allowEdit: true,
            transformer: () => true,
        },
    },

    // TODO should be /item/subscribe/{id}
    /**
     * @param {string} itemUri
     * @param {number} linkID
     * @returns {boolean}
     */
    unsubscribeById: {
        method: HttpMethod.DELETE,
        url: "/item/subscribeById",
        offline: {
            allowEdit: true,
            transformer: () => true,
        },
    },

    /**
     * @param {string} itemUri
     * @returns {boolean}
     */
    delDefiniteItemUri: {
        method: HttpMethod.DELETE,
        url: "/item/deleteDefinite",
        offline: {
            allowEdit: true,
            transformer: () => true,
        },
    },

    /**
     * @param {string} itemUri
     * @returns {boolean}
     */
    restoreItemUri: {
        method: HttpMethod.PUT,
        url: "/item/restore",
    },

    /**
     * @param {string} itemUri
     * @returns {FormResultDTO}
     */
    fetchForm: {
        url: "/item/form",
        cache: {
            strategy: CacheStrategy.NetworkFirst,
            depsAnalyzer: (data: FormResultDTO, params: Object) => {
                const depsApis = [
                    {
                        name: "fetchBreadcrumb",
                        params: { itemUri: params.itemUri },
                    },
                    {
                        name: "context",
                        params: {
                            itemUris: [params.itemUri],
                            formatIds: [data.formatId],
                            itemContexts: ItemContextsFlags.All,
                            fields: data.fields,
                            attributes: data.attributes,
                        },
                    },
                    {
                        name: "fetchActivities",
                        params: { itemUri: params.itemUri },
                    },
                ];

                // parse inPoint.Fields
                if (data.fields && data.fields.length > 0) {
                    for (const field of data.fields) {
                        switch (field.format) {
                            case "Text_GeoRaster":
                                const mapProvider = getGeoRasterProvider(
                                    params.itemUri,
                                    data.formatId
                                );
                                if (mapProvider == null) {
                                    console.warn(
                                        `Text_GeoRaster field ${field.name} - could not find mapProvider for ${params.itemUri}`
                                    );
                                } else {
                                    depsApis.push({
                                        name: "fetchRasterMapLayers",
                                        params: {
                                            itemUri: params.itemUri,
                                            rasterMapIdSelector:
                                                mapProvider.rasterMapIdSelector,
                                        },
                                    });
                                }
                                break;

                            default:
                                break;
                        }
                    }
                }

                // parse ExtraAttributes
                if (data.attributes && data.attributes.length > 0) {
                    for (const ea of data.attributes) {
                        switch (ea.format) {
                            case "SearchResult":
                                // HACK not sure how to discover those dynamically?
                                const mapProvider = getGeoRasterProvider(
                                    params.itemUri,
                                    data.formatId
                                );
                                if (mapProvider == null) {
                                    console.warn(
                                        `EA.SearchResult attribute ${ea.name} - could not find mapProvider for ${params.itemUri}`
                                    );
                                } else {
                                    depsApis.push({
                                        name: "fetchRasterMapLayers",
                                        params: {
                                            itemUri: params.itemUri,
                                            rasterMapIdSelector:
                                                mapProvider.rasterMapIdSelector,
                                        },
                                    });
                                }
                                depsApis.push({
                                    name: "fetchSearchResult",
                                    params: {
                                        itemUri: params.itemUri,
                                        name: ea.name,
                                        pageNum: 0,
                                        filters: ea.extra.filters,
                                        searchType: SearchRequestType.Url,
                                        scope: SearchScope.CurrentFolder,
                                    },
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
                return depsApis;
            },
        },
    },

    /**
     * @param {boolean} isNew
     * @param {boolean} isDoc
     * @param {string} itemUri
     * @param {Object} values
     * @returns {Object}
     */
    validateForm: {
        method: HttpMethod.POST,
        url: "/item/form/validate",
        cache: {
            strategy: CacheStrategy.NetworkOnly,
            keyParams: (params) => ({
                itemUri: params.itemUri,
                isDoc: params.isDoc,
            }),
        },
        offline: {
            allowEdit: true,
            showToast: false,
            transformer: () => ({}), // return no errors on offline so we can continue
        },
    },

    /**
     * @param {string} itemUri folder or document itemUri
     * @returns {string} folder itemUri
     */
    getFolderItemUri: {
        url: "/item/folderItemUri",
    },

    /**
     * @param {NewFormTypeEnum} formType
     * @param {string} itemUri
     * @returns {FormResultDTO}
     */
    fetchNewForm: {
        url: "/doc/newform",
        params: ({ itemUri }) => ({ itemUri }),
    },

    /**
     * @param {string} itemUri
     * @returns {boolean}
     */
    delItemUri: {
        method: HttpMethod.DELETE,
        url: "/item",
        offline: {
            allowEdit: true,
            transformer: () => true,
        },
    },

    /**
     * @param {string} keyword
     * @param {number} pageNum
     * @param {boolean} isDoc
     * @param {number} formatId
     * @param {string} name
     * @param {FormLookupTypeEnum} lookupType
     * @param {number} id
     * @param {Array<SearchFilterDTO>} filters
     * @param {string} itemUri
     * @returns {LookupValuesResultDTO}
     */
    fetchFormLookup: {
        method: HttpMethod.POST,
        url: "/item/form/lookup",
        cache: {
            strategy: CacheStrategy.CacheFirst,
            keyParams: (params) => ({
                formatId: params.formatId,
                name: params.name,
                pageNum: params.pageNum,
            }),
            depsAnalyzer: (data: LookupValuesResultDTO, params: Object) => {
                // TODO #54607 appSettings offline specs
                const allowEdit = true;
                const depsApis = [];
                // recursively load all pages
                // TODO #54607 appSettings offline specs - limit number of lookup pages to cache
                if (allowEdit && data.hasMore) {
                    depsApis.push({
                        name: "fetchFormLookup",
                        params: Object.assign({}, params, {
                            pageNum: params.pageNum + 1,
                        }),
                    });
                }
                return depsApis;
            },
        },
    },

    /**
     * @param {boolean} isDoc
     * @param {number} formatId
     * @param {string} name
     * @param {FormLookupTypeEnum} lookupType
     * @param {Array<LookupValueDTO>} currentLookupValues
     * @param {string} itemUri
     * @returns {Array<LookupValueDTO>}
     */
    fetchFormLookupDependencies: {
        method: HttpMethod.POST,
        url: "/item/form/lookup/dependencies",
    },

    /**
     * @param {string} itemUri
     * @param {string} name
     * @param {string} filter
     * @param {number} pageNum
     * @param {string} sortColumn
     * @param {SortDirectionEnum} sortDirection
     * @returns {ExtraAttributesEmbeddedGridDTO}
     */
    fetchExtraAttributesEmbeddedGrid: {
        url: "/folder/form/grid",
        cache: {
            strategy: CacheStrategy.CacheFirst,
        },
    },

    /**
     * @param {string} itemUri
     * @param {number} formatId
     * @param {Object} values
     * @returns {boolean}
     */
    saveForm: {
        method: HttpMethod.POST,
        url: "/item/form",
        offline: {
            allowEdit: true,
        },
    },

    /**
     * @param {string} templateId
     * @param {string} itemUri
     * @param {Object} values
     * @returns {bool}
     */
    saveNewDocFromTemplate: {
        method: HttpMethod.PUT,
        url: "/doc/doctemplate",
        offline: {
            allowEdit: true,
            transformer: () => true,
        },
    },

    /**
     * @param {string} itemUri
     * @param {number} pageNum
     * @param {DocListSortRequestDTO | Array<DocListSortRequestDTO>} sorts
     * @param {Array<DocListFilterRequestDTO>} filters
     * @param {Array<string>} cols
     * @returns {DocsResultDTO}
     */
    fetchDocs: {
        method: HttpMethod.POST,
        url: "/folder/docs",
        params: ({
            itemUri,
            pageNum = null,
            sorts = [],
            filters = [],
            cols,
        }: {
            itemUri: string,
            pageNum?: number,
            sorts?: DocListSortRequestDTO | Array<DocListSortRequestDTO>,
            filters?: Array<DocListFilterRequestDTO>,
            cols?: Array<string>,
        }) => ({
            itemUri,
            pageNum: pageNum ?? 0,
            sorts: sorts != null && !Array.isArray(sorts) ? [sorts] : sorts,
            filters,
            cols,
        }),
        cache: {
            keyParams: (params) => ({
                itemUri: params.itemUri,
                pageNum: params.pageNum,
            }),
            depsAnalyzer: (data: DocsResultDTO, params: Object) => {
                let depsApis = [];
                if (data.rowCount > 0 && data.rows) {
                    for (let rowKey of Object.keys(data.rows)) {
                        const row = data.rows[rowKey];
                        const itemUri = row[row.length - 1];
                        depsApis.push({
                            name: "fetchForm",
                            params: { itemUri },
                        });
                    }
                }
                return depsApis;
            },
        },
    },

    /**
     * @param {string} itemUri
     * @param {DocListSortRequestDTO | Array<DocListSortRequestDTO>} sorts
     * @param {Array<DocListFilterRequestDTO>} filters
     * @returns {any}
     */
    fetchDocsCsv: {
        method: HttpMethod.POST,
        contentType: ContentType.BLOB,
        url: "/folder/docs_csv",
        params: ({
            itemUri,
            sorts = [],
            filters = [],
        }: {
            itemUri: string,
            sorts?: DocListSortRequestDTO | Array<DocListSortRequestDTO>,
            filters?: Array<DocListFilterRequestDTO>,
        }) => ({
            itemUri,
            sorts: sorts != null && !Array.isArray(sorts) ? [sorts] : sorts,
            filters,
        }),
    },

    /**
     * @param {string} itemUri
     * @param {Array<DocListFilterRequestDTO>} filters
     * @returns {number}
     */
    fetchDocCount: {
        method: HttpMethod.POST,
        url: "/folder/doccount",
    },

    /**
     * @param {string} itemUri
     * @param {number} version
     * @returns {boolean}
     */
    delDocVersion: {
        method: HttpMethod.DELETE,
        url: "/doc/version",
        offline: {
            allowEdit: true,
            transformer: () => true,
        },
    },

    /**
     * @param {string} itemUri
     * @param {number} version
     * @returns {boolean}
     */
    restoreDocVersion: {
        method: HttpMethod.PUT,
        url: "/doc/version",
    },

    /**
     * @param {string} itemUri
     * @returns {string}
     */
    fetchDownloadToken: {
        url: "/doc/token",
        cache: {
            strategy: CacheStrategy.None,
            keyParams: ({ itemUri }) => ({
                itemUri,
            }),
            depsAnalyzer: (token: GUID, params: Object) => {
                const { pdf = undefined, stream = undefined, itemUri } = params;
                const depsApis = [];
                depsApis.push({
                    name: "fetchDocument",
                    params: {
                        token,
                        pdf,
                        stream,
                        // fake for offline cache to be used only by fetchDocument/cache/keyParams
                        itemUri,
                    },
                });
                return depsApis;
            },
        },
    },

    /**
     * @param {string} token
     * @param {string} uri
     * @param {boolean} inline
     * @param {boolean} pdf
     * @param {boolean} stream
     * NOTE: api.js only returns URL, we return Content for offline caching
     */
    fetchDocument: {
        url: "/doc/download",
        cache: {
            strategy: CacheStrategy.None,
            // fake "itemUri" as token for offline cache
            keyParams: ({ pdf, stream, itemUri: token }) => {
                const params = { token };
                if (pdf) params.pdf = pdf;
                if (stream) params.stream = stream;
                return params;
            },
        },
    },

    /**
     * @param {string} itemUri
     * @returns {string}
     */
    fetchThumbnail: {
        url: "/doc/thumbnail",
        cache: {
            strategy: CacheStrategy.CacheFirst,
        },
    },

    /**
     * @param {string} itemUri
     * @param {ItemLinkTypeEnum} itemLinkType
     * @param {boolean} getSource
     * @returns {Array<string>}
     */
    fetchRelatedDocs: {
        url: "/doc/related",
    },

    /**
     * @param {string} itemUri
     * @param {string} webDavName
     * @returns {string}
     */
    getWebDavHttpLink: {
        url: "/doc/webdav",
    },

    /**
     * @param {string} itemUri
     * @param {string} webDavName
     * @returns {Array<string>}
     */
    getCloudInfo: {
        url: "/doc/cloudinfo",
    },

    /**
     * @param {string} itemUri
     * @returns {boolean}
     */
    lock: {
        method: HttpMethod.PUT,
        url: "/item/lock",
    },

    /**
     * @param {string} itemUri
     * @returns {boolean}
     */
    unlock: {
        method: HttpMethod.DELETE,
        url: "/item/lock",
    },

    // ----- folders

    //TODO shouldn't this be in /item or /user?
    /**
     * @param {boolean} offline
     * @returns {Array<CardItemResultDTO>}
     */
    getArchiveTargets: {
        url: "/folder/archivetargets",
    },

    /**
     * @param {string} itemUri
     * @param {string} filter
     * @param {boolean} deep
     * @param {boolean} all
     * @returns {TreeDTO}
     */
    fetchTree: {
        url: "/folder/tree",
        params: ({ itemUri, filter = "", deep = false, all = false }) => ({
            itemUri,
            filter,
            deep,
            all,
        }),
        cache: {
            keyParams: (params) => ({
                itemUri: params.itemUri,
            }),
            depsAnalyzer: (data: TreeDTO, params) => {
                const { itemUri } = params;
                const depsApis = [
                    // current details
                    {
                        name: "fetchForm",
                        params: { itemUri },
                    },
                    {
                        name: "fetchDocs",
                        params: { itemUri },
                    },
                ];

                if (data.totalCount > 0 && data.items) {
                    for (const treeNode of data.items) {
                        depsApis.push(
                            // recursive children
                            {
                                name: "fetchTree",
                                params: {
                                    itemUri: treeNode.itemUri,
                                    deep: false,
                                    all: true, // no paging
                                },
                            }
                        );
                    }
                }
                return depsApis;
            },
        },
    },

    /**
     * @param {string} itemUri
     * @param {number} formatId
     * @param {number} elementType
     * @param {Object} values
     * @returns {NewItemResultDTO}
     */
    createFolder: {
        method: HttpMethod.PUT,
        url: "/folder/form",
        offline: {
            allowEdit: true,
            transformer: () => ({ success: true }), // return no errors on offline so we can continue
            // transformer: (params) => ({
            //     success: true,
            //     itemUri: "pam-item://FAKEID1",
            //     name: params.values.ip["$NAME$"],
            //     formatId: params.formatId,
            //     elementType: params.elementType,
            // }),
        },
    },

    /**
     * @param {string} template
     * @param {string} itemUri
     * @param {string} targetFolderName
     * @returns {number}
     */
    createFolderFromTemplate: {
        method: HttpMethod.PUT,
        url: "/folder/template",
        offline: {
            allowEdit: true,
            transformer: () => ({ success: true }), // return no errors on offline so we can continue
        },
    },

    /**
     * @param {number} jobId
     * @returns {string}
     */
    createFolderTemplateGetItemUri: {
        url: "/folder/templatejob",
    },

    // ----- itemNotes

    /**
     * @param {string} itemUri
     * @param {number} noteId
     * @returns {any}
     */
    fetchItemNote: {
        method: HttpMethod.GET,
        url: "/item/note",
    },

    /**
     * @param {ActivityDTO} activity
     * @returns {any}
     */
    addItemNote: {
        method: HttpMethod.POST,
        url: "/item/note",
        offline: {
            allowEdit: true,
        },
    },

    /**
     * @param {ActivityDTO} activity
     * @returns {any}
     */
    updateItemNote: {
        method: HttpMethod.PUT,
        url: "/item/note",
        offline: {
            allowEdit: true,
        },
    },

    /**
     * @param {string} itemUri
     * @param {number} noteId
     * @returns {any}
     */
    deleteItemNote: {
        method: HttpMethod.DELETE,
        url: "/item/note",
        offline: {
            allowEdit: true,
        },
    },

    // ----- itemFlows

    /**
     * @param {string} itemUri
     * @param {ItemFlowDirectionEnum} dir
     * @returns {ItemFlowsConfigDTO}
     */
    fetchItemFlows: {
        url: "/item/flows",
        cache: {
            // transformer to remove custom flows using iframes!
            transformer: (data: ItemFlowsConfigDTO) =>
                data.flows &&
                data.flows.length > 0 &&
                data.flows.filter(
                    (flow) => flow.commandAction.name !== "itemFlow_custom"
                ),
            depsAnalyzer: (data: ItemFlowsConfigDTO, params: Object) => {
                const depsApi = [];
                if (data.flows && data.flows.length > 0) {
                    for (const flow of data.flows) {
                        // TODO #54607 appSettings flow specs - which itemFlows to allow/pre-cache offline
                        // components/CommandActions/itemFlows/Factory.js
                        switch (flow.commandAction.name) {
                            case "itemFlow_custom":
                                break;
                            case "itemFlow_folder":
                                depsApi.push({
                                    name: "fetchItemFlowForm",
                                    params: {
                                        formType: NewFormType.Folder,
                                        itemUri: params.itemUri,
                                        templateType:
                                            ItemFlowTemplateType.Folder,
                                        templateId:
                                            flow.commandAction.props.templateId,
                                    },
                                });
                                break;
                            case "itemFlow_folderTemplate":
                                depsApi.push({
                                    name: "fetchItemFlowForm",
                                    params: {
                                        formType: NewFormType.Folder,
                                        itemUri: params.itemUri,
                                        templateType:
                                            ItemFlowTemplateType.FolderTemplate,
                                        templateId:
                                            flow.commandAction.props.templateId,
                                    },
                                });
                                break;
                            case "itemFlow_document":
                                depsApi.push({
                                    name: "fetchItemFlowForm",
                                    params: {
                                        formType: NewFormType.Document,
                                        itemUri: params.itemUri,
                                        templateType:
                                            ItemFlowTemplateType.Document,
                                        templateId:
                                            flow.commandAction.props.templateId,
                                    },
                                });
                                break;
                            case "itemFlow_docTemplate":
                                depsApi.push({
                                    name: "fetchItemFlowForm",
                                    params: {
                                        formType: NewFormType.Document,
                                        itemUri: params.itemUri,
                                        templateType:
                                            ItemFlowTemplateType.DocTemplate,
                                        templateId:
                                            flow.commandAction.props.templateId,
                                    },
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
                return depsApi;
            },
        },
    },

    /**
     * @param {NewFormTypeEnum} formType
     * @param {string} itemUri
     * @param {string} templateId
     * @param {string} templateType
     * @returns {FormResultDTO}
     */
    fetchItemFlowForm: {
        url: "/item/flows/form",
    },

    // ----- search
    /**
     * @returns {Array<SearchFormInfoDTO>}
     */
    fetchSearchFormList: {
        url: "/search/list",
    },

    /**
     * @param {number} id
     * @param {Object} values
     * @returns {SearchFormResultDTO}
     */
    fetchSearchForm: {
        method: HttpMethod.POST,
        url: "/search/form",
    },

    //#endregion

    //#region Workflows
    /**
     * @returns {Array<WorkflowProviderDTO>}
     */
    fetchWorkflowProviders: {
        url: "/workflow/providers",
    },

    /**
     * @returns {Array<CardItemResultDTO>}
     */
    fetchWorkflowCards: {
        url: "/workflow/cards",
    },

    /**
     * @param {string} itemUri
     * @returns {Array<WorkflowDTO>}
     */
    fetchNewWorkflows: {
        url: "/workflow/new",
    },

    /**
     * @param {string} provider
     * @param {string} wfID
     * @param {string} itemUri
     * @returns {Array<WorkflowInfoDTO>}
     */
    fetchWorkflowInfo: {
        url: "/workflow/info",
    },
    //#endregion

    //#region Raster maps
    /**
     * @param {string} itemUri
     * @param {string} rasterMapIdSelector
     * @param {boolean} create
     * @returns {RasterMapResultDTO}
     */
    fetchRasterMapLayers: {
        url: "/map/raster/layers",
    },

    /**
     * @param {RasterMapSaveRequestDTO} map
     * @returns {RasterMapResultDTO}
     */
    saveRasterMapLayers: {
        method: HttpMethod.POST,
        url: "/map/raster/layers",
    },
    //#endregion

    //#region Search
    /**
     * @param {string} keyword
     * @param {string} itemUri
     * @returns {Array<SAYTOptionDTO>}
     */
    fetchSAYT: {
        url: "/search/sayt",
        cache: {
            strategy: CacheStrategy.CacheFirst,
        },
    },

    /**
     * @param {SearchRequestDTO} searchRequest
     * @returns {SearchCountResultDTO}
     */
    fetchSearchCount: {
        method: HttpMethod.PUT,
        url: "/search/count",
        cache: {
            strategy: CacheStrategy.CacheFirst,
        },
    },

    /**
     * @param {SearchRequestDTO} searchRequest
     * @returns {SearchResultDTO}
     */
    fetchSearchResult: {
        method: HttpMethod.PUT,
        url: "/search",
        cache: {
            keyParams: (params) => ({
                name: params.name,
                pageNum: params.pageNum,
                itemUri: params.itemUri,
                scope: params.scope,
                searchType: params.searchType,
                filters: JSON.stringify(params.filters),
                sorts: JSON.stringify(params.sorts),
            }),
        },
    },

    /**
     * @param {string} provider
     * @param {Array<SearchFilterDTO>} filters
     * @returns {string}
     */
    fetchSearchSuggestion: {
        method: HttpMethod.PUT,
        url: "/search/suggestion",
    },

    /**
     * @param {string} keyword
     * @param {boolean} includeUsers
     * @param {boolean} includeGroups
     * @returns {Array<UserPrincipalDTO | GroupPrincipalDTO>}
     */
    fetchSearchPrincipal: {
        url: "/search/principal",
        cache: {
            strategy: CacheStrategy.CacheFirst,
        },
    },
    //#endregion

    //#region transfer
    /**
     * @param {string} provider
     * @param {TransferItemRequestDTO} transferRequest
     * @returns {bool}
     */
    transfer: {
        method: HttpMethod.POST,
        url: "/item/transfer",
    },
    //#endregion
};
