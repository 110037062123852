import React, { useCallback, useEffect } from "react";
import loc from "i18next";
import TextEditorControl from "components/ExtraAttributes/EditorControl/TextEditorControl";
import DateEditorControl from "components/ExtraAttributes/EditorControl/DateEditorControl";
import EnumEditorControl from "components/ExtraAttributes/EditorControl/EnumEditorControl";
import { isEditable } from "components/ShareModal/Utils";
import {
    ShareType,
    DateTimeFormat,
    type ShareActionDTO,
    type ShareTypeEnum,
} from "data/types";

type Props = {
    onChange: (value) => void,
    onConfirm: (name: sting, value: ShareTypeEnum) => Promise<boolean>,
    name: string,
    value?: ShareActionDTO,
    extras?: any,
};

const ShareActionForm = ({
    onChange,
    onConfirm,
    name,
    value: shareAction,
    extras = {},
}: Props) => {
    const handleChange = useCallback(
        (name: string, value: any) =>
            onChange({ ...shareAction, [name]: value }),
        [onChange, shareAction]
    );
    const handleShareAsChange = useCallback(
        (nameAndIndex, indexValue) => {
            const [name, index] = nameAndIndex.split(".");
            const value = [...shareAction.shareAs];
            value[index] = indexValue;
            return onChange({ ...shareAction, [name]: value });
        },
        [onChange, shareAction]
    );
    const handleConfirmedChange = useCallback(
        (name: string, value: ShareTypeEnum) =>
            onConfirm(name, value).then(
                (success) => success && handleChange(name, value)
            ),
        [handleChange, onConfirm]
    );
    const { typeEnumValues, aclEnumValues } = extras;
    useEffect(() => {
        if (
            typeof shareAction.expires === "undefined" ||
            (!isEditable(shareAction.type) && shareAction.expires != null)
        ) {
            handleChange("expires", null);
        }
    }, [shareAction, handleChange]);
    return (
        <>
            <div className="row">
                <EnumEditorControl
                    header={`${loc.t("share:action.type")}*`}
                    name="type"
                    enum={typeEnumValues}
                    isRequired={true}
                    value={shareAction.type}
                    onChange={handleConfirmedChange}
                    component="Dropdown"
                    validationErrors={{
                        isDefaultRequiredValue: loc.t(
                            "common:validation.required"
                        ),
                    }}
                />
                {isEditable(shareAction.type) && (
                    <EnumEditorControl
                        header={`${loc.t("share:action.acl")}*`}
                        name="acl"
                        enum={aclEnumValues}
                        value={shareAction.acl}
                        onChange={handleChange}
                        component="Dropdown"
                        validationErrors={{
                            isDefaultRequiredValue: loc.t(
                                "common:validation.required"
                            ),
                        }}
                    />
                )}
            </div>
            {isEditable(shareAction.type) && (
                <>
                    {shareAction.type === ShareType.Share &&
                        shareAction.shareAs.map((shareAs, i) => (
                            <TextEditorControl
                                key={`shareAs-${i}`}
                                header={`${loc.t("share:action.shareAs")}*`}
                                showLabel={i === 0}
                                name={`shareAs.${i}`}
                                isRequired={true}
                                value={shareAs}
                                onChange={handleShareAsChange}
                                validationErrors={{
                                    isDefaultRequiredValue: loc.t(
                                        "common:validation.required"
                                    ),
                                }}
                            />
                        ))}
                    <DateEditorControl
                        header={loc.t("share:action.expires")}
                        name="expires"
                        isRequired={false}
                        type={DateTimeFormat.JsonDateEnd}
                        value={shareAction.expires}
                        onChange={handleChange}
                        validations={{
                            isGreaterThanNow: /* istanbul ignore next */ (
                                values,
                                value
                            ) =>
                                value == null ||
                                new Date(value) - Date.now() > 0,
                        }}
                        validationErrors={{
                            isGreaterThanNow: loc.t(
                                "common:validation.greaterThanNow"
                            ),
                        }}
                    />
                </>
            )}
        </>
    );
};

ShareActionForm.displayName = "ShareActionForm";
export default ShareActionForm;
