// @flow
import React from "react";
import Button from "react-bootstrap/lib/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import Icons from "@hs/icons";
import loc from "i18next";

export const PrevDocButton = ({
    onClick,
    disabled,
}: {
    onClick: Function,
    disabled: boolean,
}) => (
    <Button
        title={loc.t("docmodal:prevDoc")}
        onClick={onClick}
        disabled={disabled}
        data-test={"topRowExtraPrevButton"}
    >
        <Icons.Library name="backward" />
    </Button>
);

export const NextDocButton = ({
    onClick,
    disabled,
}: {
    onClick: Function,
    disabled: boolean,
}) => (
    <Button
        title={loc.t("docmodal:nextDoc")}
        onClick={onClick}
        disabled={disabled}
        data-test={"topRowExtraNextButton"}
    >
        <Icons.Library name="forward" />
    </Button>
);

const TopRowExtras = ({
    onNext,
    nextDisabled,
    onPrev,
    prevDisabled,
    onFullscreen,
}: {
    onNext: Function,
    nextDisabled: boolean,
    onPrev: Function,
    prevDisabled: boolean,
    onFullscreen: Function,
}) => (
    <ButtonGroup style={{ marginLeft: 5, marginRight: 5 }}>
        <PrevDocButton onClick={onPrev} disabled={prevDisabled} />
        <NextDocButton onClick={onNext} disabled={nextDisabled} />
    </ButtonGroup>
);
export default TopRowExtras;
