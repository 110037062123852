// @flow
import React from "react";
import { useItemFlows, useFolderItemUri } from "hooks";
import Status from "components/Status";
import ItemFlows from "views/itemFlows";
import Constants from "data/constants";
import { type ItemFlowDirectionEnum, type NewItemResultDTO } from "data/types";

type Props = {
    /** current folder's itemUri  */
    itemUri: string,
    /** optional target folder's itemUri (default: itemUri) */
    targetItemUri?: string,
    /** callback when user closes */
    onClose: () => void,
    /** flow direction "in" vs "out" */
    direction?: ItemFlowDirectionEnum,
    /** optional flow.id to pre-select */
    flowId?: string,
    /** flow.ids to allow visible */
    visibleFlowIds?: Array<string>,
    /** optional category.id to pre-select */
    categoryId?: string,
    /** optional default attribute values to pass to form */
    defaultValues?: Object,
    /** optional whether to preview the newly created item
     * @default true
     */
    previewNewItem?: boolean,
    /** files to upload */
    files?: Array<DroppedFile>,
};

const ItemFlowsContainer = (props: Props) => {
    const itemUri = props.targetItemUri || props.itemUri;
    const folderItemUri = useFolderItemUri(itemUri);
    const [selectedFlow, selectedCategory, state] = useItemFlows(props);

    if (state.loading || folderItemUri.loading)
        return <Status status={Constants.LOADING} />;
    if (state.error || folderItemUri.loading)
        return <Status status={Constants.ERROR} />;

    return (
        <ItemFlows
            asModal
            flows={state.value.flows}
            categories={state.value.categories}
            selectedFlow={selectedFlow}
            selectedCategory={selectedCategory}
            itemUri={folderItemUri.value}
            onClose={props.onClose}
            defaultValues={props.defaultValues}
            previewNewItem={props.previewNewItem}
            files={props.files}
        />
    );
};
ItemFlowsContainer.displayName = "ItemFlowsContainer";
ItemFlowsContainer.defaultProps = {
    direction: "in",
};
export default ItemFlowsContainer;
