// export all selectors at once to make imports easier
import queryString from "data/queryString";
import { Location } from "history";
import {
    favoritesStatusSelector,
    favoritesFavoritesSelector,
} from "./favoritesReducer";
import {
    userIdSelector,
    userStatusSelector,
    userCorrelationIdSelector,
    userSitesSelector,
    userMainNavigationSelector,
    userLanguageSelector,
    userDecimalSeparator,
    userThousandSeparator,
    userItemActionsSelector,
    userFormActionsSelector,
    userHasOfflineItemsSelector,
    userIsWfEnabledSelector,
    userTopbarItemsSelector,
    userIsAdminSelector,
} from "./userReducer";
import {
    loginStatusSelector,
    loginIdTokenSelector,
    loginAccessTokenSelector,
    loginRefreshTokenSelector,
    loginExpiresOnSelector,
    loginExpiresInSelector,
} from "./loginReducer";
import {
    treeStatusSelector,
    treeErrorSelector,
    treeNodesSelector,
    treeIsRootSelector,
    treeIsBrowsableSelector,
    treeTotalCountSelector,
} from "./treeReducer";
import {
    docsItemUriSelector,
    docsStatusSelector,
    docsPageNumSelector,
    docsErrorSelector,
    docsColsSelector,
    docsRowsSelector,
    docsTotalCountSelector,
    docsFiltersSelector,
    docsSortsSelector,
    docsFilterTextSelector,
} from "./docsReducer";
import {
    thumbnailStatusSelector,
    thumbnailResultSelector,
} from "./thumbnailsReducer";
import {
    docsLayoutStatusSelector,
    docsLayoutFiltersSelector,
    docsLayoutSortsSelector,
} from "./docsLayoutReducer";
import { cardsStatusSelector, cardsCardsSelector } from "./cardsReducer";
import {
    workflowStatusSelector,
    workflowProvidersSelector,
    workflowCardsSelector,
    workflowPendingCountSelector,
} from "./workflowReducer";
import {
    breadcrumbIsFavSelector,
    breadcrumbItemUriSelector,
    breadcrumbNameSelector,
    breadcrumbLevelsCountSelector,
    breadcrumbVersionSelector,
    breadcrumbFormatIdSelector,
    breadcrumbSiteTypeSelector,
} from "./breadcrumbReducer";
import {
    currentStatusSelector,
    currentItemUriSelector,
    currentNameSelector,
} from "./currentItemReducer";
import {
    sidebarMiniSelector,
    docListViewModeSelector,
    settingValueSelector,
} from "./settingsReducer";
import {
    formDetailViewSelector,
    formLastChangeSelector,
    formAuditLogSelector,
    formGridRowIndexSelector,
    formGridRowSeqIdSelector,
    formDefaultsSelector,
    formIsEditModeSelector,
    formTabIndexSelector,
} from "./formReducer";
import {
    selectedTogglesSelector,
    togglesPersistedSelector,
} from "./togglesReducer";
import { panesViewSelector } from "./panesReducer";
import { globalSearchOptionsSelector } from "./globalSearchReducer";
import {
    searchStatusSelector,
    searchErrorSelector,
    searchResultSelector,
    searchResultTotalCountSelector,
    searchRequestSelector,
    searchTermsSelector,
    searchItemSelector,
    searchFormStatusSelector,
    searchFormChangesSelector,
    searchFormResultSelector,
    searchFormIdSelector,
    searchFormValidationSelector,
    searchCountStatusSelector,
    searchCountResultSelector,
    searchCountAggregationsSelector,
} from "./searchReducer";
import {
    searchFormListStatusSelector,
    searchFormListResultSelector,
    searchFormListCountSelector,
} from "./searchFormsReducer";
import {
    offlineSyncStatusSelector,
    offlineItemsSelector,
    offlineItemDepsMapSelector,
} from "./offlineReducer";
import { globalEventsSelector } from "./globalEventsReducer";
import {
    isOnlineSelector,
    isApiOnlineSelector,
    isNavigatorOnlineSelector,
    hasDetectedNetworkStatusSelector,
    hasBeenOnlineSelector,
} from "./networkSelectors";
import {
    isSwInitializedSelector,
    isSwFailedSelector,
    isSwUpdatedSelector,
    swRegistrationSelector,
} from "./serviceWorkerReducer";

import { notificationSelector } from "./notificationReducer";

import {
    commandActionRequestSelector,
    commandActionRequestItemSelector,
} from "./commandActionReducer";

export const itemUriSelector = (props: Object & { location: Location }) => {
    const uri = queryString.parse(props.location.search).uri;
    return uri == null ? null : `pam-item://${uri}`;
};

// export const urlParamSelector = (
//     paramName: string,
//     props: Object & { location: Location }
// ) => queryString.parse(props.location.search)[paramName];

export {
    isOnlineSelector,
    isApiOnlineSelector,
    isNavigatorOnlineSelector,
    hasDetectedNetworkStatusSelector,
    hasBeenOnlineSelector,
    favoritesStatusSelector,
    favoritesFavoritesSelector,
    userIdSelector,
    userStatusSelector,
    userSitesSelector,
    userMainNavigationSelector,
    userLanguageSelector,
    userCorrelationIdSelector,
    userDecimalSeparator,
    userThousandSeparator,
    userItemActionsSelector,
    userFormActionsSelector,
    userHasOfflineItemsSelector,
    userIsWfEnabledSelector,
    userIsAdminSelector,
    userTopbarItemsSelector,
    loginStatusSelector,
    loginIdTokenSelector,
    loginAccessTokenSelector,
    loginRefreshTokenSelector,
    loginExpiresOnSelector,
    loginExpiresInSelector,
    treeStatusSelector,
    treeErrorSelector,
    treeNodesSelector,
    treeIsRootSelector,
    treeIsBrowsableSelector,
    treeTotalCountSelector,
    docsItemUriSelector,
    docsStatusSelector,
    docsPageNumSelector,
    docsErrorSelector,
    docsColsSelector,
    docsRowsSelector,
    docsTotalCountSelector,
    docsFiltersSelector,
    docsSortsSelector,
    thumbnailStatusSelector,
    thumbnailResultSelector,
    docsFilterTextSelector,
    docsLayoutStatusSelector,
    docsLayoutFiltersSelector,
    docsLayoutSortsSelector,
    cardsStatusSelector,
    cardsCardsSelector,
    workflowStatusSelector,
    workflowProvidersSelector,
    workflowCardsSelector,
    workflowPendingCountSelector,
    breadcrumbIsFavSelector,
    breadcrumbItemUriSelector,
    breadcrumbNameSelector,
    breadcrumbLevelsCountSelector,
    breadcrumbVersionSelector,
    breadcrumbFormatIdSelector,
    breadcrumbSiteTypeSelector,
    currentStatusSelector,
    currentItemUriSelector,
    currentNameSelector,
    sidebarMiniSelector,
    docListViewModeSelector,
    settingValueSelector,
    formDetailViewSelector,
    formLastChangeSelector,
    formAuditLogSelector,
    formGridRowIndexSelector,
    formGridRowSeqIdSelector,
    formDefaultsSelector,
    formIsEditModeSelector,
    formTabIndexSelector,
    selectedTogglesSelector,
    togglesPersistedSelector,
    panesViewSelector,
    globalSearchOptionsSelector,
    searchStatusSelector,
    searchErrorSelector,
    searchResultSelector,
    searchResultTotalCountSelector,
    searchRequestSelector,
    searchTermsSelector,
    searchItemSelector,
    searchCountStatusSelector,
    searchCountResultSelector,
    searchCountAggregationsSelector,
    searchFormStatusSelector,
    searchFormResultSelector,
    searchFormIdSelector,
    searchFormChangesSelector,
    searchFormListStatusSelector,
    searchFormListResultSelector,
    searchFormListCountSelector,
    searchFormValidationSelector,
    globalEventsSelector,
    offlineSyncStatusSelector,
    offlineItemsSelector,
    offlineItemDepsMapSelector,
    isSwInitializedSelector,
    isSwFailedSelector,
    isSwUpdatedSelector,
    swRegistrationSelector,
    notificationSelector,
    commandActionRequestSelector,
    commandActionRequestItemSelector,
};
