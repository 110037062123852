// @flow
import { dispatch } from "data/storeHelper";
import { commandActionRequestAction } from "data/actions";

import ip_archive from "./ip_archive";
import ip_browse from "./ip_browse";
import ip_edit from "./ip_edit";
import ip_properties from "./ip_properties";
import ip_commandAction from "./ip_commandAction";

import folder_delete from "./folder_delete";
import folder_editClassification from "./folder_editClassification";
import folder_emailLink from "./folder_emailLink";
import folder_preview from "./folder_preview";

import form_back from "./form_back";
import form_cancel from "./form_cancel";
import form_clear from "./form_clear";

import search_favorite from "./search_favorite";
import search_preview from "./search_preview";
import search_scope from "./search_scope";

import searchForm_abort from "./searchForm_abort";
import searchForm_operator from "./searchForm_operator";
import searchForm_scope from "./searchForm_scope";

import doclist_resetFilters from "./doclist_resetFilters";
import doclist_exportCsv from "./doclist_exportCsv";

import doc_download from "./doc_download";
import doc_downloadZip from "./doc_downloadZip";
import doc_downloadFilesAPI from "./doc_downloadFilesAPI";
import doc_delete from "./doc_delete";
import doc_preview from "./doc_preview";
import doc_print from "./doc_print";
import doc_printMultiple from "./doc_printMultiple";
import doc_related from "./doc_related";
import doc_upload from "./doc_upload";
import doc_uploadVersion from "./doc_uploadVersion";
import doc_emailLink from "./doc_emailLink";
import doc_emailAttachment from "./doc_emailAttachment";
import doc_editClassification from "./doc_editClassification";
import doc_editWebDav from "./doc_editWebDav";
import doc_editCloud from "./doc_editCloud";

import favorite from "./favorite";

import searchResult_form from "./searchResult_form";
import link from "./link";
import lock from "./lock";
import unlock from "./unlock";
import subscribe from "./subscribe";
import unsubscribe from "./unsubscribe";
import share from "./share";
import share_web from "./share_web";
import createFolder_template from "./createFolder_template";
import wf_start from "./wf_start";
import itemFlows from "./itemFlows";
import transfer from "./transfer";

import recyclebin_docClear from "./recyclebin_docClear";
import recyclebin_docRestore from "./recyclebin_docRestore";
import recyclebin_folderClear from "./recyclebin_folderClear";
import recyclebin_folderRestore from "./recyclebin_folderRestore";

import editClassification from "./editClassification";
import clipboard_copyItemUri from "./clipboard_copyItemUri";
import clipboard_copyItemLink from "./clipboard_copyItemLink";

import raster_manage from "./raster_manage";
import reminder_manage from "./reminder_manage";

import offline_add from "./offline_add";
import offline_delete from "./offline_delete";
import offline_syncItem from "./offline_syncItem";

// steps to add a new custom action -> see README.md
export const actionMappings = {
    ip_archive,
    ip_browse,
    ip_edit,
    ip_properties,
    ip_commandAction,

    folder_delete,
    folder_editClassification,
    folder_emailLink,
    folder_preview,

    form_back,
    form_cancel,
    form_clear,

    search_favorite,
    search_preview,
    search_scope,

    searchForm_abort,
    searchForm_operator,
    searchForm_scope,

    doclist_resetFilters,
    doclist_exportCsv,

    doc_download,
    doc_downloadZip,
    doc_downloadFilesAPI,
    doc_delete,
    doc_preview,
    doc_print,
    doc_printMultiple,
    doc_related,
    doc_upload,
    doc_uploadVersion,
    doc_emailLink,
    doc_emailAttachment,
    doc_editClassification,
    doc_editWebDav,
    doc_editCloud,

    favorite,
    link,
    lock,
    unlock,
    subscribe,
    unsubscribe,
    share,
    share_web,
    searchResult_form,
    createFolder_template,
    wf_start,
    itemFlows,
    transfer,

    recyclebin_docClear,
    recyclebin_docRestore,
    recyclebin_folderClear,
    recyclebin_folderRestore,

    editClassification,
    clipboard_copyItemUri,
    clipboard_copyItemLink,

    raster_manage,
    reminder_manage,

    offline_add,
    offline_delete,
    offline_syncItem,
};

const CommandActionHandler = (
    actionId: string,
    props?: any
): boolean | null => {
    const actionHandler = actionMappings[actionId];
    if (typeof actionHandler !== "function") {
        alert("Unknown action: " + actionId);
        return false;
    }
    dispatch(
        commandActionRequestAction({
            commandAction: actionId,
            props,
            items: props?.items ?? [
                {
                    name: props?.name,
                    itemUri: props?.itemUri,
                    formatId: props?.formatId ?? -1,
                    isDoc: props?.isDoc ?? false,
                },
            ],
        })
    );
    // HACK inPoint.Cloud.Azure AppInsights...
    /* istanbul ignore next */
    if ("appInsights" in window) {
        window.appInsights.trackEvent({
            name: "CommandAction",
            properties: {
                actionId,
                ...props,
            },
        });
    }
    //return actionHandler({ dispatch: dispatch, ...props });
};
export default CommandActionHandler;
