/* istanbul ignore next */
if (!Date.prototype.toLocalJSON) {
    //Use local Timezone (not UTC) for JSON
    (function () {
        function pad(number) {
            return number < 10 ? `0${number}` : number;
        }
        // eslint-disable-next-line
        Date.prototype.toLocalJSON = function () {
            return `${this.getFullYear()}-${pad(this.getMonth() + 1)}-${pad(
                this.getDate()
            )}T${pad(this.getHours())}:${pad(this.getMinutes())}:${pad(
                this.getSeconds()
            )}.${(this.getMilliseconds() / 1000).toFixed(3).slice(2, 5)}`;
        };
    })();
}

/* istanbul ignore next */
if (typeof window.crypto === "undefined") {
    window.crypto = require("crypto");
}

/* istanbul ignore next */
if (typeof window.crypto.randomUUID !== "function") {
    window.crypto.randomUUID = function () {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
                c ^
                (window.crypto.getRandomValues(new Uint8Array(1))[0] &
                    (15 >> (c / 4)))
            ).toString(16)
        );
    };
}
