import React from "react";
import Avatar from "./Avatar";
import UserGroup from "./UserGroup";
import { PrincipalType, type PrincipalTypeEnum } from "data/types";

const PrincipalAvatar = ({
    id,
    className,
    title,
    type = PrincipalType.User,
}: {
    id?: int,
    className?: string,
    title?: string,
    type: PrincipalTypeEnum,
}) =>
    type === PrincipalType.User ? (
        <Avatar id={id} className={className} title={title} />
    ) : (
        <UserGroup className={className} title={title} />
    );

PrincipalAvatar.displayName = "Principal";
export default PrincipalAvatar;
