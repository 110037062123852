// @flow
import React, { useState, useCallback, useMemo } from "react";
import Modal from "components/Modal";
import TopRowExtras from "./TopRowExtras";
import CloseButton from "@hs/close-button";
import FullscreenButton from "components/ModalFullscreenButton";
import styles from "./DocModal.module.css";
import { DocumentWithoutHash } from "views/document";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import bowser from "data/bowser";
import { createModal } from "react-modal-promise";
import { useModalPostMessageHandler } from "hooks";

export type DocModalProps = $Shape<{
    /** which rowIndex is currently displayed (for back&fwd buttons) */
    activeRowIndex: number,
    /** how many document exist (for back&fwd buttons) - set to -1 to disable */
    totalCount: number,
    /** whether to trigger Print */
    triggerPrint: boolean,
    /** whether to trigger EditMode */
    triggerEditMode: boolean,
    /** callback when user clicks back or fwd buttons
     * @param {number} activeRowIndex new row index
     * @param {boolean} isNext if new row index is incremented/decremented
     * @return {number} new (modified) row index
     */
    onSetActiveRowIndex: (activeRowIndex: number, isNext: boolean) => number,
    /** callback to get itemUri by activeRowIndex
     * @param {number} activeRowIndex new row index
     * @return {string} itemUri
     */
    getActiveRowItemUri: (activeRowIndex: number) => string,
    /** (optional) callback to reopen DocModal with same context but different itemUri
     * @param {string} itemUri itemUri to use to reopen DocModal
     */
    onReopenWith?: (itemUri: string) => void,
}>;

type Props = DocModalProps & {
    /** document's itemUri to show */
    itemUri: string,
    /** whether to show modal */
    open: boolean,
    /** callback when user closes modal */
    close: () => void,
    /** selected toggles */
    toggles?: Array<string>,
    /**pdf.js: search terms */
    searchTerm?: string,
    /** pdf.js: whether trigger browser print */
    triggerPrint: boolean,
};

/**
 * DocViewer in Modal window
 */
export const DocModal = ({
    totalCount = -1,
    onSetActiveRowIndex = (index: number) => index,
    getActiveRowItemUri = () => null,
    itemUri,
    activeRowIndex: activeRowIndexFromProps,
    close,
    open,
    toggles,
    onReopenWith,
    searchTerm,
    triggerPrint,
    triggerEditMode,
}: Props) => {
    const [activeRowIndex, setActiveRowIndex] = useState(
        activeRowIndexFromProps
    );
    const [minCount, setMinCount] = useState(0);
    const [maxCount, setMaxCount] = useState(totalCount);

    const fullScreenHandle = useFullScreenHandle();

    useModalPostMessageHandler(close);

    const isNextDisabled = activeRowIndex + 1 === maxCount;
    const isPrevDisabled = activeRowIndex === minCount;

    const handleDeletedWhileOpen = useCallback(
        (itemUri, rowIndex, isNext) => {
            //Go back until you find a valid itemUri
            while (itemUri == null && rowIndex > minCount) {
                rowIndex = onSetActiveRowIndex(--rowIndex, isNext);
                itemUri = getActiveRowItemUri(rowIndex);
            }
            if (itemUri != null && typeof onReopenWith === "function") {
                onReopenWith(itemUri);
            }
            return close();
        },
        [
            onSetActiveRowIndex,
            getActiveRowItemUri,
            onReopenWith,
            close,
            minCount,
        ]
    );

    const onNext = useCallback(() => {
        if (isNextDisabled) return;
        const nextRowIndex = onSetActiveRowIndex(activeRowIndex + 1, true);
        const itemUri = getActiveRowItemUri(nextRowIndex);
        if (itemUri == null) {
            return handleDeletedWhileOpen(itemUri, activeRowIndex, true);
        }

        setActiveRowIndex(nextRowIndex);
        setMaxCount((maxCount) =>
            nextRowIndex === activeRowIndex && maxCount === totalCount
                ? nextRowIndex + 1
                : maxCount
        );
    }, [
        isNextDisabled,
        activeRowIndex,
        onSetActiveRowIndex,
        getActiveRowItemUri,
        handleDeletedWhileOpen,
        totalCount,
    ]);

    const onPrev = useCallback(() => {
        if (isPrevDisabled) return;
        const prevRowIndex = onSetActiveRowIndex(activeRowIndex - 1, false);
        const itemUri = getActiveRowItemUri(prevRowIndex);
        if (itemUri == null) {
            return handleDeletedWhileOpen(itemUri, prevRowIndex, false);
        }

        setActiveRowIndex(prevRowIndex);
        setMinCount((minCount) =>
            prevRowIndex === activeRowIndex && minCount === 0
                ? prevRowIndex
                : minCount
        );
    }, [
        isPrevDisabled,
        onSetActiveRowIndex,
        getActiveRowItemUri,
        handleDeletedWhileOpen,
        activeRowIndex,
    ]);

    const toggleFullscreen = useCallback(() => {
        /* istanbul ignore next */ !fullScreenHandle?.active
            ? fullScreenHandle.enter()
            : fullScreenHandle.exit();
    }, [fullScreenHandle]);

    const activeItemUri = useMemo(
        () => getActiveRowItemUri(activeRowIndex) || itemUri,
        [activeRowIndex, getActiveRowItemUri, itemUri]
    );

    return (
        <Modal
            enforceFocus={false}
            fullscreen
            headerSize={0}
            show={open !== false}
            onHide={close}
            style={{
                zIndex: 1050,
            }} /* correctly overlays email modal */
            dataTest="docModal"
        >
            <Modal.Body>
                <FullScreen
                    className={styles.fullscreen}
                    handle={fullScreenHandle}
                >
                    <DocumentWithoutHash
                        asModal={true}
                        itemUri={activeItemUri}
                        searchTerm={searchTerm}
                        allowTogglesFromQueryString={false}
                        toggles={toggles}
                        triggerEditMode={triggerEditMode}
                        triggerPrint={triggerPrint}
                        topRowBefore={
                            totalCount !== -1 && (
                                <TopRowExtras
                                    onPrev={onPrev}
                                    prevDisabled={isPrevDisabled}
                                    onNext={onNext}
                                    nextDisabled={isNextDisabled}
                                />
                            )
                        }
                        onPrev={onPrev}
                        onNext={onNext}
                        topRowEnd={
                            <>
                                {!bowser.is("iOS") && (
                                    <FullscreenButton
                                        isFullscreen={fullScreenHandle?.active}
                                        onClick={toggleFullscreen}
                                    />
                                )}
                                <CloseButton onClick={close} />
                            </>
                        }
                    />
                </FullScreen>
            </Modal.Body>
        </Modal>
    );
};

export const openDocModal = createModal(DocModal);
