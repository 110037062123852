// @flow
import React, { PureComponent } from "react";
import { type docsResultState } from "data/reducers/types";
import { type DocListLayout } from "data/types";
import CellMeasurer from "react-virtualized/dist/commonjs/CellMeasurer";
import CellMeasurerCache from "react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache";
import Measure from "react-measure";
import Constants, { type ConstantsEnum } from "data/constants";
import ContextMenuHandler from "components/ContextMenu/Handler";
import Icons from "@hs/icons";
import NoDocs from "components/NoDocs";
import ListGroup from "react-bootstrap/lib/ListGroup";
import List from "react-virtualized/dist/commonjs/List";
import InfiniteLoader from "react-virtualized/dist/commonjs/InfiniteLoader";
import "react-virtualized/styles.css"; // only needs to be imported once
import styles from "./DocListSimple.module.css";
import RowResult from "./RowResult";
import isEqual from "lodash/isEqual";

type Props = {
    /** doclist fetch result */
    result: docsResultState,
    /** total number of documents */
    totalCount: number,
    /** callback to request loading next page */
    loadMoreRows: ({ rowIndex: number }) => Promise,
    /** docList layout settings */
    layout?: DocListLayout,
    /** callback to set filtering on a specific column
     * @param {string} colName name of column to filter
     */
    requestShowFilter: (colName: string) => void,
    /** which row index is currently selected */
    activeRowIndex?: ?number,
    /** which row index to bring into view */
    scrollToRow?: ?number,
    /** which row is the UI currently updating
     * @param {number} rowStartIndex row index in result.rows
     */
    rowStartIndexUpdated: (rowStartIndex: number) => void,
    /** callback when user clicks document icon
     * @param {SyntheticMouseEvent} e HTML click event
     */
    onIconClick?: (e: SyntheticMouseEvent) => void,
    /** callback when user single clicks the whole row
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} rowIndex the row's index
     */
    onSingleClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        rowIndex: number
    ) => void,
    /** callback when user clicks on More... button
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onMoreClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    /** whether to disable the More... button */
    actionsDisabled?: boolean,
    /** callback when user right clicks the whole row
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {number} rowIndex the row's index
     * @param {number} columnsIndex the column's index
     * @param {string} itemUri the row's itemUri
     */
    onContextClick?: (
        e: SyntheticMouseEvent,
        rowIndex: number,
        columnIndex: number,
        itemUri: string
    ) => void,
    /** React.Node children */
    children?: Node,
    /** whether to show thumbnails or just file types' icons */
    showThumbnails?: boolean,
    /** minimum row height (in px) */
    minRowHeight?: number,
    /** alternative Component used to Render each Row */
    RenderRowComponentClass?: React.Node,
};

type State = {
    rows: Object,
    pageSize: number,
    pagesStatus: Array<ConstantsEnum>,
    activeRowIndex: number,
    hoveredRowIndex: number,
    refreshId: number,
};

/**
 * Renders the Tiles/Thumbnails documents list
 */
class DocListSimple extends PureComponent<Props, State> {
    _cache: Object;
    _list: List | null; // internal ref to rendered virtualized List Component
    // _autoHide: AutoHide | null;

    static defaultProps = {
        showThumbnails: false,
        RenderRowComponentClass: RowResult,
    };

    constructor(props: Props) {
        super(props);
        this._initCellMeasurerCache(props);

        this.state = {
            //first page results loaded by DocListContainer
            rows: props.result.rows,
            pageSize: props.result.pageSize,
            //first page already pre-loaded otherwise we wouldn't be here
            pagesStatus: [Constants.OK],
            activeRowIndex:
                props.activeRowIndex != null ? props.activeRowIndex : -1,
            hoveredRowIndex: -1,
            refreshId: 0,
        };
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        /* istanbul ignore else */
        if (prevProps.showThumbnails !== this.props.showThumbnails) {
            this._initCellMeasurerCache(this.props);
            this._onResize();
        }

        if (!isEqual(this.props.result.rows, this.state.rows)) {
            this.setState(
                ({ pagesStatus, refreshId }) => ({
                    rows: this.props.result.rows,
                    pagesStatus: pagesStatus.map((p, i) =>
                        p === Constants.LOADING
                            ? i === this.props.pageNum
                                ? Constants.OK
                                : null
                            : p
                    ),
                    refreshId: ++refreshId,
                }),
                () => {
                    this._cache.clearAll();
                }
            );
        }
    }

    _initCellMeasurerCache = (props: Props) => {
        this._cache = new CellMeasurerCache({
            fixedWidth: true,
            minHeight:
                props.minRowHeight ||
                /* istanbul ignore next */ (props.showThumbnails ? 85 : 55),
        });
    };

    /* istanbul ignore next */ _onResize = (width: number, height: number) => {
        /* istanbul ignore next */
        this.setState(
            ({ refreshId }: State) => ({
                refreshId: ++refreshId,
            }),
            () => this._cache.clearAll()
        );
    };

    _isRowLoaded = ({ index }: { index: number }) => {
        return Object.prototype.hasOwnProperty.call(this.state.rows, index);
    };

    _loadMoreRows = ({
        startIndex,
        stopIndex,
    }: {
        startIndex: number,
        stopIndex: number,
    }) => {
        const { rows, pageSize, pagesStatus } = this.state;
        //console.log("loadMoreRows", startIndex, stopIndex);

        // calc pagenumber
        let pageNum = 0;
        /* istanbul ignore else */
        if (startIndex > 0) {
            // specific row index requested? calc page number
            pageNum = Math.floor(startIndex / pageSize) - 1;
        }
        // check that stopIndex is indeed included
        /* istanbul ignore next */
        if ((pageNum + 1) * pageSize < stopIndex) {
            pageNum++;
        }

        // page already loaded/requested?
        /* istanbul ignore next */
        if (
            (pagesStatus[pageNum] === Constants.OK ||
                pagesStatus[pageNum] === Constants.LOADING) &&
            !(
                Object.prototype.hasOwnProperty.call(rows, startIndex) &&
                Object.prototype.hasOwnProperty.call(rows, stopIndex)
            )
        )
            return;

        // fetch
        pagesStatus[pageNum] = Constants.LOADING;
        this.props.loadMoreRows(pageNum);
    };

    _rowRenderer = ({
        index,
        isScrolling,
        key,
        parent,
        style,
    }: {
        index: number,
        isScrolling: boolean,
        key: number,
        parent: Object,
        style: StyleSheet,
    }) => {
        if (this._isRowLoaded({ index })) {
            const { rows } = this.state;
            const row = rows[index];
            const itemUri = row[row.length - 1];
            const handleRowMoreClick = (e) => {
                /* istanbul ignore else */
                if (typeof this.props.onMoreClick === "function")
                    this.props.onMoreClick(e, itemUri, index);
            };
            const handleRowContextMenu = (e) => {
                this._cellOnContextMenu(e, index, itemUri);
            };
            const handleRowMouseEnter = (e) => {
                this.setState({ hoveredRowIndex: index });
            };
            return (
                <CellMeasurer
                    cache={this._cache}
                    columnIndex={0}
                    key={key}
                    rowIndex={index}
                    parent={parent}
                    refreshId={this.state.refreshId}
                >
                    {({ measure }) => (
                        <ContextMenuHandler
                            componentClass="div"
                            onContextMenu={handleRowContextMenu}
                            onMouseEnter={handleRowMouseEnter}
                            rowIndex={index}
                            columnIndex={0}
                            style={style}
                        >
                            {React.createElement(
                                this.props.RenderRowComponentClass,
                                {
                                    index,
                                    row,
                                    onLoad: this._onResize,
                                    showThumbnail: this.props.showThumbnails,
                                    filterText: this.props.result.filterText,
                                    layout: this.props.layout,
                                    cols: this.props.result.cols,
                                    isSelected:
                                        this.props.activeRowIndex === index,
                                    isHovered:
                                        this.state.hoveredRowIndex === index,
                                    onIconClick: this.props.onIconClick,
                                    onSingleClick: this.props.onSingleClick,
                                    onMoreClick: handleRowMoreClick,
                                    onDoubleClick: this.props.onDoubleClick,
                                    actionsDisabled: this.props.actionsDisabled,
                                }
                            )}
                        </ContextMenuHandler>
                    )}
                </CellMeasurer>
            );
        } else {
            return (
                <CellMeasurer
                    cache={this._cache}
                    columnIndex={0}
                    key={key}
                    rowIndex={index}
                    parent={parent}
                    refreshId={this.state.refreshId}
                >
                    {({ measure }) => (
                        <div
                            key={key}
                            className={styles.placeholder}
                            style={style}
                        >
                            <Icons.Library
                                name="spinner"
                                className="fa-pulse"
                            />
                        </div>
                    )}
                </CellMeasurer>
            );
        }
    };

    _cellOnContextMenu = (
        e: SyntheticMouseEvent<*>,
        rowIndex: number,
        itemUri: string
    ) => {
        this.setState({ activeRowIndex: rowIndex });
        e.preventDefault();
        e.persist();
        /* istanbul ignore else */
        if (typeof this.props.onContextClick === "function") {
            //console.log(e.target.innerText, columnIndex);
            this.props.onContextClick(e, rowIndex, 0, itemUri);
        }
    };

    render() {
        const { rows } = this.state;
        const count =
            this.props.totalCount === 0
                ? this.props.result.rowCount
                : this.props.totalCount;

        return (
            <Measure bounds onResize={this._onResize}>
                {({
                    measureRef,
                    contentRect: /* istanbul ignore next */ { bounds } = {
                        bounds: { width: 0, height: 0 },
                    },
                }) => (
                    <div
                        ref={measureRef}
                        className="docListSimple"
                        style={{
                            flex: "1 1 100%",
                            display: "flex",
                            minHeight: /* istanbul ignore next */ this.props
                                .minRowHeight
                                ? (this.props.minRowHeight + 3) * count
                                : 0,
                            minWidth: 0,
                        }}
                    >
                        <ListGroup
                            style={{
                                marginBottom: 0,
                                flex: "1 1 100%",
                                minHeight: 0,
                                minWidth: 0,
                            }}
                        >
                            <InfiniteLoader
                                isRowLoaded={this._isRowLoaded}
                                loadMoreRows={this._loadMoreRows}
                                rowCount={count}
                            >
                                {({ onRowsRendered, registerChild }) => (
                                    <List
                                        ref={(ref) => {
                                            this._list = ref;
                                            registerChild(ref);
                                        }}
                                        deferredMeasurementCache={this._cache}
                                        width={
                                            /* istanbul ignore next */ bounds?.width ??
                                            0
                                        }
                                        height={
                                            /* istanbul ignore next */ bounds?.height ??
                                            0
                                        }
                                        rowCount={count}
                                        rowHeight={this._cache.rowHeight}
                                        rowRenderer={this._rowRenderer}
                                        onRowsRendered={onRowsRendered}
                                        overscanRowCount={10}
                                        noRowsRenderer={() => (
                                            <NoDocs
                                                layout={this.props.layout}
                                                requestShowFilter={
                                                    this.props.requestShowFilter
                                                }
                                            />
                                        )}
                                        rows={rows}
                                        scrollToIndex={this.props.scrollToRow}
                                        scrollToAlignment="start"
                                    />
                                )}
                            </InfiniteLoader>
                        </ListGroup>
                        {this.props.children}
                    </div>
                )}
            </Measure>
        );
    }
}

export default DocListSimple;
