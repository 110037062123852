import { JL } from "data/logging";

/* istanbul ignore next */ const _log = JL("serviceWorkerRegistration");

/* istanbul ignore next */
const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
);

export /* istanbul ignore next */ function register(config, now) {
    if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
        // Basename should be here instead of "." otherwise the serviceWorker scope is wrong so /mini/ wont work!
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(
            window.CONFIG.host.basename || "/" /*process.env.PUBLIC_URL*/,
            window.location.href
        );
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return;
        }

        const install = () => {
            // Basename should be here instead of "." otherwise the serviceWorker scope is wrong so /mini/ wont work!
            const swUrl = `${
                window.CONFIG.host.basename || "" /*process.env.PUBLIC_URL*/
            }/service-worker.js`;

            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl, config);

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                navigator.serviceWorker.ready.then(() => {
                    _log.info(() => "Service-Worker is ready!");
                });
            } else {
                // Is not localhost. Just register service worker
                registerValidSW(swUrl, config);
            }
        };

        if (now === true) install();
        else window.addEventListener("load", install);
    }
}

/* istanbul ignore next */
function registerValidSW(swUrl, config) {
    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            _log.trace("after register", registration);
            const activeWorker = registration.active;
            if (activeWorker != null) {
                if (activeWorker.state === "activated") {
                    // Execute callback
                    if (config && config.onSuccess) {
                        config.onSuccess(registration);
                    }
                } else {
                    activeWorker.onstatechange = () => {
                        _log.trace(() => ({
                            msg: "activeWorker.state",
                            state: activeWorker.state,
                        }));
                        if (activeWorker.state === "activated") {
                            // Execute callback
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    };
                }
            }
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    _log.trace(() => ({
                        msg: "installingWorker.state",
                        state: installingWorker.state,
                    }));
                    if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                            // At this point, the updated precached content has been fetched,
                            // but the previous service worker will still serve the older
                            // content until all client tabs are closed.
                            _log.info(
                                () =>
                                    "New content is available and will be used when all " +
                                    "tabs for this page are closed."
                            );

                            // Execute callback
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            _log.info(
                                () => "Content is cached for offline use."
                            );

                            // Execute callback
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    }
                };
            };
        })
        .catch((error) => {
            _log.fatalException(
                "Error during service worker registration",
                error
            );
            if (config && config.onFailure) {
                config.onFailure(error);
            }
        });
}

/* istanbul ignore next */
function checkValidServiceWorker(swUrl, config) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: { "Service-Worker": "script" },
    })
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get("content-type");
            if (
                response.status === 404 ||
                (contentType != null &&
                    contentType.indexOf("javascript") === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            _log.info(
                () =>
                    "No internet connection found. App is running in offline mode."
            );
        });
}

export /* istanbul ignore next */ function unregister() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                _log.fatalException(
                    "While un-registering serviceWorker",
                    error
                );
            });
    }
}
