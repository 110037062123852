import React, { useMemo } from "react";
import FormBar from "@hs/form-bar";
import { useFormActions } from "hooks";
import { type FormBarViewEnum } from "data/types";

type Props = {
    className?: string,
    title?: string,
    onCancel?: () => void,
    onBack?: () => void,
    children?: React.ElementType,
    icon?: string,
    targetView: FormBarViewEnum,
};

/**
 * renders the Form's panel header
 *
 * @param {Props} { form, title, onCancel, onBack, children, icon }
 */
export const View = ({
    className,
    title,
    onCancel,
    onBack,
    children,
    icon,
    targetView,
}: Props) => {
    const contextProps = useMemo(
        () => ({ onCancel, onBack }),
        [onCancel, onBack]
    );
    const actions = useFormActions(targetView, contextProps);
    return (
        <FormBar
            key={targetView}
            className={className}
            icon={icon}
            title={title}
            children={children}
            actions={actions}
        />
    );
};
View.displayName = "FormBar.View";
export default View;
