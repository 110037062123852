import { searchAbortAction } from "data/actions";
import { type Dispatch } from "react-redux";

/**
 * toggle scope of current form
 */

type Props = {
    dispatch: Dispatch,
};

class searchForm_abort {
    execute = ({ dispatch }: Props) => {
        if (dispatch == null) return false;
        dispatch(searchAbortAction());
        return true;
    };
}
export default searchForm_abort;
