import { getCloudInfo } from "data/api";
import { toastActionResult, toastStyled, toastTypes } from "data/toast";

/**
 * opens webDav edit http link for a document synced in cloud
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name: string }} { name }
 * @param {{ onlineEditor?: boolean }} { onlineEditor }
 */

type Context = {
    itemUri: string,
    name: string,
    onlineEditor?: boolean,
};

const SupportedExtensions = [".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx"];

class doc_editCloud {
    logger: () => any = toastActionResult;

    execute = async ({
        itemUri,
        name,
        onlineEditor = false,
        ext,
    }: Context): Promise<boolean> => {
        const i18n = onlineEditor
            ? "itemActions:doc_editCloud"
            : "itemActions:doc_editCloudLocal";

        if (!onlineEditor && !SupportedExtensions.includes(ext)) {
            toastStyled({
                ...toastTypes.failure,
                toastId: "doc_editCloudLocal",
                message: `$${i18n}.not_supported`,
            });
            return;
        }

        try {
            const [webEditUrl, webDavUrl] = await getCloudInfo(itemUri, name);
            const args: Array<string> = [];
            if (onlineEditor) {
                args.push(webEditUrl);
                args.push("");
                args.push(
                    `status=no,location=no,toolbar=no,menubar=no,width=600px,height=640px,top=${
                        Math.random() * 100
                    },left=${Math.random() * 100}`
                );
            } else {
                args.push(webDavUrl);
            }
            if (args[0] == null || String(args[0]).length === 0) {
                throw new Error(
                    `Invalid ${onlineEditor ? "WebEditURL" : "WebDavURL"}`
                );
            }
            window.open(...args);
            this.logger(true, i18n);
        } catch (err) {
            this.logger(false, i18n);
        }
    };
}

export default doc_editCloud;
