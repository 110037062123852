// @flow
import React from "react";
import Dropdown from "@hs/dropdown";
import MenuItem from "react-bootstrap/lib/MenuItem";
import SearchBucket from "./SearchBucket";
import loc from "i18next";
// // import Icons from "@hs/icons";

type Props = {
    buckets: Array<SearchBucketDTO>,
    onSelect: (name: string, value: Object) => void,
    isDisabled: boolean,
};

const CombinedFiltersMenu = ({ buckets, onSelect, isDisabled }: Props) => {
    if (buckets.length === 0) return null;
    return (
        <Dropdown bsSize="small" key="cfm" id="cfm" disabled={isDisabled}>
            <Dropdown.Toggle chrome title={loc.t("common:filter.set")}>
                {loc.t("actionCategory:filter.name")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {buckets.map((bucket, i) => (
                    <MenuItem
                        key={`cfm-${i}`}
                        onSelect={
                            /*istanbul ignore next*/ () =>
                                onSelect({
                                    name: bucket.name,
                                    value: null,
                                })
                        }
                    >
                        <SearchBucket.Name item={bucket} />
                    </MenuItem>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
CombinedFiltersMenu.displayName = "FilterBar.CombinedFiltersMenu";

export default CombinedFiltersMenu;
