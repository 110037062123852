// @flow
import React from "react";
import Avatar from "components/User/Avatar";
import AttributeLabel from "components/ExtraAttributes/AttributeLabel";
import ViewerControlBase from "components/ExtraAttributes/ViewerControl/ViewerControlBase";
import sharedStyles from "shared/styles.module.css";

type Props = {
    header?: string,
    value: any | string,
    userid: number,
    width?: number,
};

const UserIdViewerControl = ({ header, value, userid, width }: Props) => {
    return (
        <ViewerControlBase style={{ whiteSpace: "nowrap" }}>
            <AttributeLabel label={header} />{" "}
            <div
                className={`form-control form-control-static ${sharedStyles.select_text}`}
                style={{ padding: 0 }}
            >
                <Avatar id={userid} className="round">
                    <div
                        style={{
                            marginLeft: "30px",
                            position: "relative",
                            top: "5px",
                        }}
                    >
                        {value}
                    </div>
                </Avatar>
            </div>
        </ViewerControlBase>
    );
};

UserIdViewerControl.displayName = "UserIdViewerControl";
export default UserIdViewerControl;
