// @flow
import React, { PureComponent } from "react";
import { connect, Dispatch } from "react-redux";
import { addSearchFavorite, delFavoriteLink } from "data/api";
import { renameSearchFavoriteAction } from "data/actions";
import {
    type SearchRequestDTO,
    type SearchItemDTO,
    SearchRequestType,
} from "data/types";
import Searchcrumb from "components/Searchcrumb";
import Constants, { type ConstantsEnum } from "data/constants";
import Status from "components/Status";
import { toastActionResult } from "data/toast";
import * as s from "data/reducers/selectors";

type Props = {
    /** searchItem object (not executed yet) */
    searchItem: ?SearchItemDTO,
    /** search Request object (executed query) */
    searchRequest: SearchRequestDTO,
    /** whether user is allowed to rename searchcrumb (and hide favorite option) */
    isReadonly: boolean,
    /** Redux dispatch */
    dispatch: Dispatch,
};

type State = {
    status: ConstantsEnum,
    isFav: boolean,
    favStatus: ConstantsEnum,
    name: string,
    linkId: number,
};

export class SearchcrumbContainer extends PureComponent<Props, State> {
    favOnClick: Function;

    constructor(props: Props) {
        super(props);
        this.state = {
            status: Constants.LOADING,
            isFav: false,
            favStatus: Constants.LOADING,
            name: "",
            linkId: -1,
        };
        this.favOnClick = this.favOnClick.bind(this);
    }

    // componentDidMount() {
    //     this._parse(this.props.searchRequest);
    // }

    componentDidUpdate(prevProps: Props) {
        /* istanbul ignore else */
        if (prevProps.searchRequest !== this.props.searchRequest) {
            this._parse(this.props.searchRequest);
        }
    }

    _parse = (searchRequest: SearchRequestDTO): void => {
        if (searchRequest) {
            this.setState({
                status: Constants.OK,
                isFav:
                    searchRequest.isCustom === false &&
                    searchRequest.searchType === SearchRequestType.Favorite,
                favStatus: Constants.OK,
                name: searchRequest.name || "",
                linkId: searchRequest.id,
            });
        } else {
            this.setState({ status: Constants.OK });
        }
    };

    async favOnClick(e: SyntheticEvent<MouseEvent>) {
        e.preventDefault();
        const { searchRequest } = this.props;
        const { name, isFav, linkId } = this.state;

        this.setState({ favStatus: Constants.LOADING });

        try {
            const success = isFav
                ? await delFavoriteLink(linkId)
                : await addSearchFavorite(searchRequest);

            toastActionResult(
                success,
                isFav ? "breadcrumb.delfav" : "breadcrumb.addfav",
                { name }
            );

            if (success) {
                this.setState({
                    isFav: !isFav,
                    favStatus: Constants.OK,
                });
            } else {
                this.setState({
                    favStatus: Constants.ERROR,
                });
            }
        } catch (err) {
            this.setState({
                favStatus: Constants.ERROR,
            });
        }
    }

    favOnRename = (name: string) =>
        this.props.dispatch(renameSearchFavoriteAction({ name }));

    render() {
        const { searchRequest, searchItem, isReadonly } = this.props;
        if (searchRequest != null && searchItem != null) {
            const { name, status, isFav, favStatus } = this.state;
            if (status === Constants.OK)
                return (
                    <Searchcrumb
                        name={name}
                        isFav={isFav}
                        isReadonly={isReadonly}
                        favStatus={favStatus}
                        favOnClick={this.favOnClick}
                        onRename={
                            searchRequest.id === -1 ? this.favOnRename : null
                        }
                    />
                );

            return <Status status={this.state.status} inline />;
        } else if (searchItem != null) {
            return (
                <Searchcrumb
                    name={searchItem.name}
                    // isFav={isFav}
                    isReadonly={isReadonly}
                    // favStatus={favStatus}
                    // favOnClick={this.favOnClick}
                    // onRename={
                    //     searchRequest.id === -1 ? this.favOnRename : null
                    // }
                />
            );
        } else {
            return (
                <Searchcrumb
                    isReadonly={isReadonly}
                    favStatus={Constants.NONE}
                />
            );
        }
    }
}

const mapStateToProps = /* istanbul ignore next */ (state, ownProps) => ({
    searchRequest: s.searchRequestSelector(state),
    ...ownProps,
});

export default connect(mapStateToProps)(SearchcrumbContainer);
