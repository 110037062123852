import * as API from "data/api";
import { SearchItemType, type SearchRequestDTO } from "data/types";
import { toastActionResult } from "data/toast";

/**
 * toggles favorite flag for the given search request
 *
 * @param {{ searchRequest: SearchRequestDTO }} { searchRequest }
 */
class search_favorite {
    logger: () => any = toastActionResult;
    execute = async ({
        searchRequest,
    }: {
        searchRequest: SearchRequestDTO,
    }) => {
        const endpoint =
            searchRequest.searchType === SearchItemType.Favorite
                ? "delFavoriteLink"
                : "addSearchFavorite";
        const message =
            searchRequest.searchType === SearchItemType.Favorite
                ? "breadcrumb.delfav"
                : "breadcrumb.addfav";
        const payload =
            searchRequest.searchType === SearchItemType.Favorite
                ? searchRequest.id
                : searchRequest;
        try {
            const success = await API[endpoint](payload);
            this.logger(success, message, {
                name: searchRequest.name,
            });
            return true;
        } catch (error) {
            this.logger(false, message, { name: searchRequest.name });
            return false;
        }
    };
}
export default search_favorite;
