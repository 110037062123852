import { CustomHttpHeader } from "data/types";
import { hasConsole } from "data/bowser";
import { correlationId, accessToken } from "data/storeHelper";
import { JL } from "jsnlog";

export { JL };

export /* istanbul ignore next */ const setupLogging = () => {
    // configure jsnLog
    let appenders = [];

    const consoleAppender = JL.createConsoleAppender("consoleAppender");
    if (hasConsole()) {
        // https://github.com/mperdeck/jsnlog.js/issues/2
        // logs in devtools a serialized string instead of structured logs...
        consoleAppender.sendLogItems = (logItems, successCallback) => {
            for (const logItem of logItems) {
                try {
                    const item = logItem.m.startsWith("{")
                        ? JSON.parse(logItem.m)
                        : logItem.m;

                    if (logItem.l <= JL.getDebugLevel()) {
                        console.debug(
                            "[%s] %s",
                            logItem.n,
                            item.msg || "",
                            item
                        );
                    } else if (logItem.l <= JL.getInfoLevel()) {
                        console.info(
                            "[%s] %s",
                            logItem.n,
                            item.msg || "",
                            item
                        );
                    } else if (logItem.l <= JL.getWarnLevel()) {
                        console.warn(
                            "[%s] %s",
                            logItem.n,
                            item.msg || "",
                            item
                        );
                    } else {
                        console.error(
                            "[%s] %s",
                            logItem.n,
                            item.msg || "",
                            item
                        );
                    }
                } catch (e) {}
            }
            if (typeof successCallback === "function") successCallback();
        };
    }
    consoleAppender.setOptions({
        level: window.CONFIG.browserLoggingLevel || 4000,
    });
    appenders.push(consoleAppender);

    if (window.CONFIG.browserLoggingForwardToServer === true) {
        // http://jsnlog.com/Documentation/JSNLogJs/AjaxAppender/SetOptions
        const serverAppender = JL.createAjaxAppender("mainappender");
        serverAppender.setOptions({
            batchSize: 20,
            bufferSize: 20,
            storeInBufferLevel: 1000,
            level: window.CONFIG.browserLoggingLevel || 4000,
            sendWithBufferLevel: 6000,
            url: `${window.CONFIG.host.basename}${window.CONFIG.general.api}/jsnlog.logger`,
            // #54890: Add CorrelationId to JsnLogs
            beforeSend: (xhr) => {
                xhr.setRequestHeader(
                    CustomHttpHeader.Authorization,
                    `Bearer ${accessToken()}`
                );
                xhr.setRequestHeader(
                    CustomHttpHeader.CorrelationId,
                    correlationId()
                );
            },
        });
        appenders.push(serverAppender);
    }

    JL().setOptions({
        appenders,
    });
};
