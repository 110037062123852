import React, { useMemo } from "react";
import {
    type ItemFlowConfigDTO,
    SiteType,
    CardType,
    CardItemType,
} from "data/types";
import CardItem from "components/CardList/CardItem";
import "components/CardList/index.css";
import NoData from "components/NoData";
import FlowCardImage from "./FlowCardImage";
import Divider from "components/Divider";
import CountBadge from "components/CountBadge";
import { getLocalizedText, getClassNames } from "data/utils";
import { removeSpaces } from "data/integrationTestUtils";
import styles from "./List.module.css";

type Props = {
    /** current folder's itemUri */
    itemUri: string,
    /** current filterText */
    filterText: string,
    /** list of all flows */
    flows: Array<ItemFlowConfigDTO>,
    /** currently selected flow */
    selectedFlow: ?ItemFlowConfigDTO,
    /** callback when user selects a flow
     * @param {ItemFlowConfigDTO} flow selected
     */
    onSelect: (flow: ItemFlowConfigDTO) => void,
};

const List = ({
    itemUri,
    filterText = "",
    flows = [],
    selectedFlow,
    onSelect,
}: Props) => {
    const suggested = useMemo(
        () => flows.filter((flow) => flow.isSuggested),
        [flows]
    );
    const others = useMemo(
        () => flows.filter((flow) => !flow.isSuggested),
        [flows]
    );
    if (flows.length === 0) return <NoData />;

    const renderCardItem = (flow) => (
        <CardItem
            visible
            isSelected={flow === selectedFlow}
            key={`ni-${flow.type}-${flow.id}`}
            type={CardType.Action}
            siteType={SiteType.NotSet}
            itemtype={CardItemType.CommandAction}
            filterText={filterText}
            name={getLocalizedText(flow.name)}
            desc={getLocalizedText(flow.desc)}
            image={<FlowCardImage flow={flow} />}
            onClick={() => onSelect(flow)}
            dataTest={`flowListItem${removeSpaces(flow.name)}`}
        />
    );
    return (
        <div className={getClassNames("cards", styles.cards)}>
            {suggested.length > 0 && (
                <Divider
                    name="itemFlowsList.isSuggestedExpanded"
                    header={
                        <>
                            {getLocalizedText("$itemflows:list.isSuggested")}{" "}
                            <CountBadge count={suggested.length} />
                        </>
                    }
                >
                    <ul className={styles.wrapper}>
                        {suggested.map((flow) => renderCardItem(flow))}
                    </ul>
                </Divider>
            )}
            {others.length > 0 && (
                <Divider
                    name="itemFlowsList.OthersExpanded"
                    header={
                        <>
                            {getLocalizedText("$itemflows:list.others")}{" "}
                            <CountBadge count={others.length} />
                        </>
                    }
                >
                    <ul className={styles.wrapper}>
                        {others.map((flow) => renderCardItem(flow))}
                    </ul>
                </Divider>
            )}
        </div>
    );
};
List.displayName = "ItemFlows.List";
export default List;
