import React from "react";
import { ApiErrorResult } from "data/types";
import Button from "react-bootstrap/lib/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import { get } from "data/constants";
import { getLocalizedText } from "data/utils";
import { toastActionResult, toastStyled, toastTypes } from "data/toast";
import copy from "copy-to-clipboard";
import { JL } from "data/logging";

export const HTTP_ERROR_CONFLICT = 412;

const _log = JL("apiError");
export class ApiErrorContainer extends Error {
    data: ApiErrorResult;

    constructor(response: ApiErrorResult) {
        super(response.message);
        /* istanbul ignore else */
        if (Error.captureStackTrace)
            Error.captureStackTrace(this, ApiErrorContainer);
        this.data = response;
    }
}

export class ApiError extends ApiErrorContainer {
    constructor(...args) {
        super({
            message: args.length > 0 ? args[0] : "Unknown",
            isError: true,
        });
    }
}

export class ApiNetworkError extends ApiErrorContainer {
    constructor(...args) {
        const response: Response = args[0];
        super({
            ...(args.length > 1 ? args[1] : {}),
            statusCode: response.status,
            statusText: response.statusText,
            isError: true,
        });
    }
}

export class ApiConflictError extends ApiNetworkError {}

export class ApiNetworkOfflineError extends ApiErrorContainer {
    constructor(...args) {
        super({
            message: args[0],
            detail: args.length > 1 ? args[1] : null,
            isError: true,
            eventId: { id: 0, name: "OFFLINE" },
        });
    }
}

export const notifyOnError = (response) => {
    _log.error(() => ({
        msg: "Fetch.Api.Error",
        response,
    }));

    if (!response.extra || response.extra.notify !== true) {
        return;
    }

    const { extra, isError, ...rest } = response;
    /* istanbul ignore next */
    const {
        email = false,
        text = "",
        url = false,
    } = get(window.CONFIG, ["general", "localContactInfo"], {});
    const message = getLocalizedText("$fetch.clipboard_copy.message");
    const handleClick = () =>
        copy(JSON.stringify(rest), {
            message,
            format: "text/plain",
            onCopy: /* istanbul ignore next */ (clipboardData: Object) =>
                toastActionResult(true, "fetch.clipboard_copy"),
        });

    const details = () => (
        <ButtonGroup bsSize="small">
            {email && (
                <Button bsStyle="link" href={`mailto:${email}`}>
                    {email}
                </Button>
            )}
            {url && (
                <Button
                    bsStyle="link"
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {url}
                </Button>
            )}
            <Button bsStyle="link" onClick={handleClick}>
                {message}
            </Button>
        </ButtonGroup>
    );

    toastStyled({
        ...toastTypes.failure,
        autoClose: false,
        details,
        message: getLocalizedText(response.extra.loc || "$fetch.error", {
            text,
        }),
    });
};
