// @flow
import React from "react";
import RenderActionsMenu from "./Render";
import Dropdown from "@hs/dropdown";
import Icons from "@hs/icons";
import styles from "./ActionsMenu.module.css";
import { getClassNames } from "data/utils";

type Props = {
    /** view name */
    name: string,
    /** callback when user selects action from ActionsMenu
     * @param {string} actionId which action has been selected by the user
     */
    onAction: (actionId: string) => void,
    /** callback to decide whether specified action should be visible
     * @param {object} action action object for which to decide visibility
     * @returns {boolean} true if should be visible, false to not render
     */
    onShouldBeVisible?: (action: { id: string }) => boolean,
    /** whether to disable the whole actionsMenu */
    disabled?: boolean,
    /** optional CSS class to apply to dropdown */
    className?: string,
    toggleComponent?: React.Element,
};

/**
 * renders a dropdown menu with actions
 */
const ActionsMenu = ({
    name,
    onAction,
    onShouldBeVisible,
    disabled,
    className,
    toggleComponent,
}: Props) => (
    <Dropdown
        id={`menu-actions-${name}`}
        // pullLeft
        openMenuOnHover={false}
        className={getClassNames(styles.menu, className)}
        disabled={disabled}
    >
        <Dropdown.Toggle caret={false} chrome>
            {toggleComponent ? (
                toggleComponent
            ) : (
                <Icons.Library name="fa-regular fa-ellipsis-vertical" />
            )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {RenderActionsMenu(name, onShouldBeVisible, null, onAction)}
        </Dropdown.Menu>
    </Dropdown>
);

ActionsMenu.displayName = "ActionsMenu";
export default ActionsMenu;
