import React, { useCallback, useMemo } from "react";
import ItemActions from "components/ActionPanel/ItemActions";
import { connect, type Dispatch } from "react-redux";
import { resetDocFilters, setDocFilterByRef } from "data/actions";
import * as s from "data/reducers/selectors";
import {
    GetDefaults,
    GetFilterValueAsString,
} from "components/DocListFilterModal/Utils";
import { getLocalizedText } from "data/utils";

import {
    DocListColumnType,
    DocListFilterOperators,
    type DocListFilterRequestDTO,
} from "data/types";

type Props = {
    onClick: (props: Object) => void,
    itemUri: string,
    action: ItemActionConfig,
    view: ItemActionViewConfig,
    filters: Array<DocListFilterRequestDTO>,
    dispatch: Dispatch,
};

type DocListClickContext = {
    rowIndex: number,
    columnIndex: number,
    itemUri: string,
};

const Icons = {
    filterByValue: "filter",
    filterAddValue: "filter",
    filterRemove: "eraser",
    reset: "xmark",
};

const getActionArgs = (
    actionId: string,
    context: DocListClickContext,
    cols,
    filters,
    rows
): {} | { col: string, operator: string, value: string } => {
    if (
        actionId === "reset" ||
        context.columnIndex == null ||
        context.rowIndex == null ||
        cols == null ||
        cols[context.columnIndex] == null ||
        filters == null
    ) {
        return {};
    }

    // find filter reference
    const filter = filters.find(
        (f) => f.name === cols[context.columnIndex].name
    );

    /* istanbul ignore if */
    if (filter == null) {
        return {};
    }

    // replace "None" with "Equal"
    let opType = filter.operator;
    // those operators are not supported - will be replaced (if chosen) in _setDocFilter()
    /* istanbul ignore if */
    if (
        [
            DocListFilterOperators.None,
            DocListFilterOperators.Between,
            DocListFilterOperators.NotBetween,
        ].includes(opType)
    )
        opType = DocListFilterOperators.Equals;
    /* istanbul ignore if */
    if ([DocListFilterOperators.DoesNotContain].includes(opType))
        opType = DocListFilterOperators.NotEquals;

    // arguments used in i18n.json which are then shown in ContextMenu
    return {
        col: cols[context.columnIndex].caption,
        opType,
        operator: getLocalizedText("$folder:action_filter.operators." + opType),
        value: GetFilterValueAsString(
            rows[context.rowIndex][context.columnIndex]
        ),
    };
};

export const showAction = (
    actionId: string,
    context: DocListClickContext,
    cols,
    filters,
    rows
): boolean => {
    if (
        context.columnIndex == null ||
        context.rowIndex == null ||
        cols == null ||
        rows == null
    )
        return false;

    // always allow resetting filters
    if (actionId === "reset") return true;

    const col = cols[context.columnIndex];

    // no sorting/filtering allowed?
    /* istanbul ignore else */
    if (!col.canSort) return false;

    // find filter reference
    const filter = filters.find((f) => f.name === col.name);
    // can we actually have a filter for it?
    /* istanbul ignore else */
    if (filter == null) return false;

    // filter already set?
    // TODO filterRemove always displayed!
    const isFilterSet = String(filter.values[0]).length > 0;
    // show filterRemove, but hide the filterAdd options
    if (isFilterSet && actionId === "filterRemove") {
        return true;
    } else if (actionId === "filterRemove") return false;

    // no support for some column types
    /* istanbul ignore else */
    if (
        [
            DocListColumnType.DateTimeValue,
            DocListColumnType.ObjectValue,
            DocListColumnType.DateTimeOffsetValue,
        ].includes(col.type)
    )
        return false;

    // check empty value
    /* istanbul ignore if */
    if (
        rows[context.rowIndex] == null ||
        rows[context.rowIndex][context.columnIndex] == null
    )
        return false;
    const value = GetFilterValueAsString(
        rows[context.rowIndex][context.columnIndex]
    );
    /* istanbul ignore if */
    if (String(value).length === 0) return false;
    return true;
};

export const ItemView_Filter = ({
    view,
    itemUri,
    columnIndex,
    rowIndex,
    filters,
    cols,
    rows,
    dispatch,
    onAction,
}: Props) => {
    const context = useMemo(
        (): DocListClickContext => ({
            rowIndex,
            columnIndex,
            itemUri,
        }),
        [rowIndex, columnIndex, itemUri]
    );

    const actions = useMemo(
        () =>
            view.actions
                .filter((action) =>
                    showAction(action, context, cols, filters, rows)
                )
                .map((action) => ({
                    id: action,
                    name: getLocalizedText(
                        `$doclist_context:actions.${action}`,
                        getActionArgs(action, context, cols, filters, rows)
                    ),
                    icon: Icons[action],
                })),
        [view.actions, context, cols, rows, filters]
    );

    const onActionClick = useCallback(
        (action) => {
            switch (action.id) {
                case "filterByValue":
                    dispatch(setDocFilterByRef(context, true));
                    break;
                case "filterAddValue":
                    dispatch(setDocFilterByRef(context, false));
                    break;
                case "filterRemove":
                    dispatch(
                        setDocFilterByRef(
                            {
                                columnIndex: columnIndex,
                                rowIndex: -1, //clear values of this filter
                            },
                            false
                        )
                    );
                    break;
                case "reset":
                    dispatch(resetDocFilters());
                    break;
                default:
                    console.warn(
                        `Unknown ActionId ${action.id} will be ignored`
                    );
            }
            onAction();
        },
        [dispatch, context, columnIndex, onAction]
    );
    if (rowIndex == null || columnIndex == null || cols == null || rows == null)
        return null;
    return <ItemActions actions={actions} onAction={onActionClick} />;
};
ItemView_Filter.displayName = "ItemViews.Filter";

//#region redux
const mapStateToProps = /* istanbul ignore next */ (state, ownProps) => {
    let filters = s.docsLayoutFiltersSelector(state);
    const cols = s.docsColsSelector(state);
    const formatId = s.breadcrumbFormatIdSelector(
        state,
        s.currentNameSelector(state)
    );
    /* istanbul ignore else */
    if (filters == null || filters.length === 0) {
        /* istanbul ignore else */
        if (cols != null) {
            filters = GetDefaults(ownProps.itemUri, formatId, cols).filters;
        }
    }
    return {
        filters,
        cols,
        rows: s.docsRowsSelector(state),
        ...ownProps,
    };
};
//#endregion

export default connect(mapStateToProps)(ItemView_Filter);
