// @flow
import { produce } from "immer";
import { type ToggleItemDTO, type ToggleDTO } from "./types";

export const Toggles = {
    home: {
        name: "home",
        isMultiple: false,
        toggles: [
            {
                value: "action",
                text: "Actions",
                icon: "thumbtack",
                isSelected: true,
                isHidden: false,
            },
            {
                value: "fav",
                text: "Favorites",
                icon: "fa-regular fa-star",
                isSelected: false,
            },
            {
                value: "rec",
                text: "Recents",
                icon: "clock-rotate-left",
                isSelected: false,
            },
        ],
    },
    wf: {
        name: "wf",
        isMultiple: false,
        toggles: [
            {
                value: "start",
                text: "Start",
                icon: "fa-regular fa-calendar-plus",
                isSelected: false,
            },
            {
                value: "pending",
                text: "Pending",
                icon: "fa-regular fa-calendar",
                isSelected: true,
            },
            // {
            //     value: "done",
            //     text: "Done",
            //     icon: "calendar-check-o",
            // },
        ],
    },
    news: {
        name: "news",
        isMultiple: true,
        toggles: [
            {
                value: "folders",
                text: "Folders",
                icon: "fa-regular fa-folder",
                isSelected: true,
            },
            {
                value: "documents",
                text: "Documents",
                icon: "fa-regular fa-file",
                isSelected: true,
            },
            {
                value: "shares",
                text: "shares",
                icon: "share",
                isSelected: true,
            },
            {
                value: "reminders",
                text: "reminders",
                icon: "fa-regular fa-clock",
                isSelected: true,
            },
        ],
    },
    minipicker: {
        name: "minipicker",
        isMultiple: true,
        toggles: [
            {
                value: "tree",
                text: "Tree",
                icon: "sitemap",
                isSelected: true,
            },
            {
                value: "doclist",
                text: "Documents",
                icon: "list",
                isSelected: true,
            },
        ],
    },
    folder: {
        name: "folder",
        isMultiple: true,
        toggles: [
            {
                value: "tree",
                text: "Tree",
                icon: "sitemap",
                isSelected: true,
            },
            {
                value: "form",
                text: "Classification",
                icon: "pen-to-square",
                isSelected: true,
            },
            {
                value: "doclist",
                text: "Documents",
                icon: "list",
                isSelected: true,
            },
        ],
    },
    flat: {
        name: "flat",
        isMultiple: false,
        toggles: [
            {
                value: "doclist",
                text: "Documents",
                icon: "list",
                isSelected: true,
                isDisabled: true,
            },
        ],
    },
    mailbox: {
        name: "mailbox",
        isMultiple: true,
        toggles: [
            {
                value: "tree",
                text: "Tree",
                icon: "sitemap",
                isSelected: true,
            },
            {
                value: "doclist",
                text: "Mails",
                icon: "list",
                isSelected: true,
            },
            {
                value: "viewer",
                text: "Viewer",
                icon: "fa-regular fa-envelope",
                isSelected: true,
            },
        ],
    },
    miniflat: {
        name: "miniflat",
        isMultiple: true,
        toggles: [
            {
                value: "doclist",
                text: "Mails",
                icon: "list",
                isSelected: true,
            },
            {
                value: "viewer",
                text: "Viewer",
                icon: "fa-regular fa-file",
                isSelected: true,
            },
        ],
    },
    search: {
        name: "search",
        isMultiple: true,
        toggles: [
            {
                value: "forms",
                text: "Forms",
                icon: "list-ul",
                isSelected: true,
                isDisabled: false,
            },
            {
                value: "search",
                text: "Manual",
                icon: "pen-to-square",
                isSelected: true,
                isDisabled: false,
            },
            {
                value: "result",
                text: "Results",
                icon: "list",
                isSelected: false,
                isDisabled: true,
            },
        ],
    },
    searchresult: {
        name: "searchresult",
        isMultiple: false,
        toggles: [
            // {
            //     value: "form",
            //     text: "Form",
            //     icon: "edit",
            //     isSelected: false
            // },
            {
                value: "result",
                text: "Results",
                icon: "list",
                isSelected: true,
                isDisabled: true,
            },
        ],
    },
    document: {
        name: "document",
        isMultiple: true,
        toggles: [
            {
                value: "viewer",
                text: "Document",
                icon: "fa-regular fa-files",
                isSelected: true,
            },
            {
                value: "form",
                text: "Form",
                icon: "pen-to-square",
                isSelected: true,
            },
            {
                value: "act",
                text: "Activity",
                icon: "clock-rotate-left",
                isSelected: true,
            },
        ],
    },
    docmodal: {
        name: "docmodal",
        isMultiple: true,
        toggles: [
            {
                value: "viewer",
                text: "Document",
                icon: "fa-regular fa-file",
                isSelected: true,
            },
            {
                value: "form",
                text: "Form",
                icon: "pen-to-square",
                isSelected: true,
            },
            {
                value: "act",
                text: "Activity",
                icon: "clock-rotate-left",
                isSelected: true,
            },
        ],
    },
    upload: {
        name: "upload",
        isMultiple: true,
        toggles: [
            {
                value: "files",
                text: "Files",
                icon: "fa-regular fa-files",
                isSelected: true,
            },
            {
                value: "form",
                text: "Target",
                icon: "bullseye",
                isSelected: false,
                isDisabled: true,
            },
        ],
    },
    itemflows: {
        name: "itemflows",
        isMultiple: true,
        toggles: [
            {
                value: "list",
                text: "Items",
                icon: "list-ul",
                isSelected: true,
            },
            {
                value: "form",
                text: "Form",
                icon: "pen-to-square",
                isSelected: false,
                isDisabled: true,
            },
        ],
    },
};

export default function getToggles(
    name: string,
    isCallback?: Function
): ToggleDTO | void {
    const toggles = Toggles[name];
    if (toggles) {
        return produce(toggles, (draft) => {
            draft.toggles.forEach((toggle: ToggleItemDTO) => {
                toggle.text = `$${name}:toggles.${toggle.value}`;
                /* istanbul ignore else */
                if (typeof isCallback === "function")
                    toggle = isCallback(toggle);
            });
        });
    }
    console.error("Cannot find toggles: " + name);
    return undefined;
}
