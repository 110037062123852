// @flow
import React from "react";
import PropTypes from "prop-types";
import loc from "i18next";
import CommandActionHandler from "components/CommandActions";
import { type ItemStatusesDTO, ItemStatuses, ItemLinkType } from "data/types";
import Icons from "@hs/icons";

const itemStatusMapping = {
    [ItemStatuses.Reminder]: Icons.Reminder,
    [ItemStatuses.Share]: Icons.Share,
    [ItemStatuses.Relation]: Icons.Relation,
    [ItemStatuses.PDF]: Icons.Pdf,
    [ItemStatuses.WFReport]: Icons.WorkflowReport,
    [ItemStatuses.Note]: Icons.Notes,
    [ItemStatuses.Link]: Icons.Link,
    [ItemStatuses.MailHasAttachment]: Icons.Email.Attachment,
    [ItemStatuses.MailReplied]: Icons.Email.Replied,
    [ItemStatuses.MailForwarded]: Icons.Email.Forwarded,
    [ItemStatuses.Locked]: Icons.Locked,
    [ItemStatuses.CloudSync]: Icons.Cloud,
    [ItemStatuses.Signed]: Icons.FileSignature,
};

const getPropsForItemStatus = (
    status: ItemStatusesDTO,
    itemUri: string,
    ext: string,
    /** callback when user clicks on More... Button
     * @param {SyntheticMouseEvent} e HTML click event
     */
    onMoreClick?: (e: SyntheticMouseEvent) => void
) => {
    switch (status) {
        case ItemStatuses.Reminder:
            return typeof onMoreClick === "function"
                ? {
                      style: {
                          cursor: "pointer",
                      },
                      onClick: onMoreClick,
                  }
                : {};
        case ItemStatuses.WFReport:
            return {
                style: {
                    cursor: "pointer",
                },
                onClick: () =>
                    CommandActionHandler("doc_related", {
                        itemUri,
                        itemLinkType: ItemLinkType.WorkflowReport,
                    }),
            };
        case ItemStatuses.CloudSync:
            return {
                style: {
                    cursor: "pointer",
                },
                onClick: (e: SyntheticMouseEvent) =>
                    CommandActionHandler("doc_editCloud", {
                        itemUri,
                        ext,
                        onlineEditor: !(e.ctrlKey || e.altKey),
                    }),
            };
        case ItemStatuses.Signed:
            return {
                style: {
                    cursor: "pointer",
                },
                onClick: (e: SyntheticMouseEvent) =>
                    CommandActionHandler("doc_preview", {
                        itemUri,
                    }),
            };
        default:
            return {};
    }
};

const ItemStatusIcons = ({
    itemStatus,
    itemUri,
    ext,
    style,
    className,
    onMoreClick,
}: {
    itemStatus: ItemStatusesDTO,
    itemUri: ?string,
    ext: ?string,
    style?: CSSStyleDeclaration,
    className?: string,
    /** callback when user clicks on More... Button
     * @param {SyntheticMouseEvent} e HTML click event
     */
    onMoreClick?: (e: SyntheticMouseEvent) => void,
}) => {
    if (itemStatus === ItemStatuses.Nothing) return null;
    const iconStyle = Object.assign({}, style, {
        marginRight: 2,
    });
    let bit = 1;
    let children = [];
    while (bit <= 1 << 30) {
        if (itemStatus & bit) {
            const ItemStatusComponent = itemStatusMapping[bit];
            if (ItemStatusComponent != null) {
                const props = getPropsForItemStatus(
                    bit,
                    itemUri,
                    ext,
                    onMoreClick
                );
                children.push(
                    <span
                        key={`b${bit}`}
                        title={loc.t(`itemStatus.${bit}`)}
                        {...props}
                    >
                        <ItemStatusComponent
                            height={14}
                            width={14}
                            style={iconStyle}
                            className={className}
                        />
                    </span>
                );
            }
        }
        bit *= 2;
    }
    return children.length ? <>{children}</> : null;
};

ItemStatusIcons.displayName = "ItemStatusIcons";
ItemStatusIcons.propTypes = {
    //itemStatus: PropTypes.number.isRequired,
    style: PropTypes.object,
    className: PropTypes.string,
};

export default ItemStatusIcons;
