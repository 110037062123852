// @flow
import loc from "i18next";
import { openDocModal } from "components/DocModal";
import { fetchRelatedDocs } from "data/api";
import { type ItemLinkTypeEnum } from "data/types";
import { toastActionResult, toastStyled, toastTypes } from "data/toast";

/**
 * opens related documents in preview
 */

type Context = {
    itemUri: string,
    itemLinkType: ItemLinkTypeEnum,
    getSource: ?boolean,
};

class doc_related {
    /* istanbul ignore next */
    logger: () => any = (...args) =>
        typeof args[0] === "boolean"
            ? toastActionResult(...args)
            : toastStyled(...args);

    execute = async ({
        itemUri,
        itemLinkType,
        getSource,
        ...relatedDocsProps
    }: Context): Promise<boolean> => {
        try {
            const rows: Array<string> = await fetchRelatedDocs(
                itemUri,
                itemLinkType,
                getSource
            );
            if (!rows || !rows.length) {
                return false;
            }
            const docModalProps = Object.assign(relatedDocsProps, {
                itemUri: rows[0],
                activeRowIndex: 0,
                totalCount: rows.length > 1 ? rows.length : -1,
                getActiveRowItemUri: /* istanbul ignore next */ (rowIndex) =>
                    rows[rowIndex] || undefined,
            });
            openDocModal({ ...docModalProps });
            return true;
        } catch (err) {
            this.logger({
                message: loc.t("itemActions:doc_related.error"),
                ...toastTypes.failure,
            });
        }
    };

    report = async (success: boolean): Promise<boolean> => {
        !success && this.logger(success === true, "itemActions:doc_related");
    };
}

export default doc_related;
