// @flow
import React, { useState, useEffect, useContext } from "react";
import { useToggle } from "hooks";
import { PaneSizeContext, MultiSelectionContext } from "data/context";
import {
    type SearchBucketDTO,
    type ListViewModeEnum,
    ListViewMode,
} from "data/types";
import { connect } from "react-redux";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "react-bootstrap/lib/Button";
import Icons from "@hs/icons";
import "./index.css";
import loc from "i18next";
import Measure from "react-measure";
import NarrowToggle from "components/NarrowToggle";
import SearchBucket from "./SearchBucket";
import CombinedFiltersMenu from "./CombinedFiltersMenu";
import ViewModes from "./ViewModes";
import SearchInput from "@hs/search-input";
import { getClassNames } from "data/utils";

type Props = {
    className?: string,
    /** list of filters (and if any possible values) to show */
    buckets: Array<SearchBucketDTO>,
    /** callback when user selects a filter
     * @param {string} name field's common name
     * @param {Object} value the actual value selected
     */
    onSelect: ({
        name: string,
        value: Object,
    }) => void,
    /** current filterText value */
    filterText: ?string,
    /** callback when user wants to quickly filter by text */
    onFilterTextChange?: (value: ?string) => void,
    /** placeholder text to display on quick text filter */
    filterTextPlaceholder?: string,
    /** callback when user wants to clear/reset all filter values */
    onClear?: () => void,
    /** whether to show the clear/reset button */
    showClear?: boolean,
    /** current viewMode */
    viewMode: ListViewModeEnum,
    /** which view modes to show/allow */
    viewModes?: Array<ListViewModeEnum>,
    /** callback when user changes viewMode
     * @param {ListViewModeEnum} viewMode the viewMode the user chose
     */
    onViewModeChange: (viewMode: ListViewModeEnum) => void,
    /** whether filter should be rendered */
    renderFilter: ?boolean,
    /** whether filter should be disabled */
    disableFilter: ?boolen,
    /** maxHeight allowed for FilteBar, before toggeling narrow mode, defaults to 41 pixels */
    maxHeight: ?number,
};

const FilterBar = ({
    className,
    buckets,
    onSelect,
    onClear,
    showClear,
    filterText,
    onFilterTextChange,
    filterTextPlaceholder,
    viewMode = ListViewMode.List,
    viewModes,
    onViewModeChange,
    browser,
    renderFilter = true,
    disableFilter = false,
    maxHeight = 42,
}: Props) => {
    // Fallback if context is not set.
    const dimensions = useContext(PaneSizeContext) || { width: 0, height: 0 };
    /* istanbul ignore next */
    const { multiple, renderedBucket } = useContext(MultiSelectionContext) || {
        multiple: false,
        renderedBucket: null,
    };
    const [height, setHeight] = useState(0);
    const [maxWidth, setMaxWidth] = useState(0);
    const [isNarrow, setIsNarrow] = useToggle(false);
    useEffect(() => {
        if (maxWidth > 0 && dimensions.width <= maxWidth) {
            // dimensions reach maxwidth
            setIsNarrow(true);
        } else if (maxWidth === 0 && height > maxHeight) {
            // onResize could have been called before dimensions where ready
            setIsNarrow(true);
            setMaxWidth(dimensions.width);
        } else if (!browser.greaterThanOrEqual.medium) {
            // mobile always render narrow
            setIsNarrow(true);
        } else {
            // default is not narrow
            setIsNarrow(false);
        }
    }, [
        browser.greaterThanOrEqual.medium,
        maxWidth,
        dimensions.width,
        height,
        maxHeight,
        setIsNarrow,
    ]);
    return (
        <Measure
            bounds
            onResize={({ bounds }) => {
                if (height === bounds.height) return;
                /* istanbul ignore else */
                if (bounds.height > maxHeight) {
                    setIsNarrow(true);
                    setMaxWidth(dimensions.width);
                }
                setHeight(bounds.height);
            }}
        >
            {({ measureRef }) => (
                <div
                    ref={measureRef}
                    className={getClassNames(
                        isNarrow ? "filterbar--narrow" : "",
                        "filterbar",
                        className
                    )}
                >
                    <ButtonToolbar>
                        {renderFilter && (
                            <>
                                {typeof onFilterTextChange === "function" && (
                                    <SearchInput
                                        borderless
                                        narrow={isNarrow}
                                        value={filterText}
                                        onChange={onFilterTextChange}
                                        placeholder={filterTextPlaceholder}
                                        className={`textfilter${
                                            disableFilter || multiple
                                                ? " disabled"
                                                : ""
                                        }`}
                                        disabled={disableFilter || multiple}
                                    />
                                )}
                                <NarrowToggle
                                    icon="filter"
                                    narrow={isNarrow}
                                    title={loc.t("common:filter.toggle")}
                                    label={loc.t("common:filter.label")}
                                >
                                    <CombinedFiltersMenu
                                        onSelect={onSelect}
                                        isDisabled={disableFilter || multiple}
                                        buckets={buckets.filter(
                                            (item) =>
                                                item.visible !== false &&
                                                item.hideMenu === true &&
                                                item.selected.length === 0
                                        )}
                                    />
                                    {typeof onClear === "function" &&
                                        showClear === true && (
                                            <Button
                                                bsSize="small"
                                                className="btn-chrome"
                                                onClick={onClear}
                                                title={loc.t(
                                                    "common:filter.reset"
                                                )}
                                            >
                                                <Icons.Library name="trash-can" />
                                            </Button>
                                        )}
                                    {buckets
                                        .filter(
                                            (item) =>
                                                item.visible !== false &&
                                                (item.hideMenu !== true ||
                                                    item.selected.length > 0)
                                        )
                                        .map((item, i) => (
                                            <SearchBucket
                                                key={`b-${i}`}
                                                item={item}
                                                i={i}
                                                onSelect={onSelect}
                                                isDisabled={
                                                    disableFilter || multiple
                                                }
                                            />
                                        ))}
                                    {!disableFilter && renderedBucket}
                                </NarrowToggle>
                            </>
                        )}
                        {viewModes && (
                            <ViewModes
                                viewMode={viewMode}
                                viewModes={viewModes}
                                onChange={onViewModeChange}
                            />
                        )}
                    </ButtonToolbar>
                </div>
            )}
        </Measure>
    );
};

FilterBar.displayName = "FilterBar";

export { FilterBar };

const mapStateToProps = /* istanbul ignore next */ (state, ownProps) => ({
    browser: state.browser,
    ...ownProps,
});

export default connect(mapStateToProps)(FilterBar);
