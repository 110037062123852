import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleBar from "components/ToggleBar";
import { persistTogglesAction } from "data/actions";
import { produce } from "immer";

const ToggleBarContainer = ({
    isMultiple,
    name,
    toggles: togglesFromProps,
}) => {
    const dispatch = useDispatch();
    // enrich toggles with data from store and fix multiple selection issues
    const toggles = useSelector((state) =>
        produce(togglesFromProps, (draft) => {
            const togglesFromStore = state.toggles[name];
            draft = draft.map((toggle) => {
                toggle.count = togglesFromStore?.counts?.[toggle.value] ?? 0;
                toggle.status =
                    togglesFromStore?.status?.[toggle.value] ?? null;
                return toggle;
            });
        })
    );
    const handleClick = useCallback(
        (item) => {
            const newToggles = produce(toggles, (draft) => {
                draft = draft.map((toggle) => {
                    if (!isMultiple) toggle.isSelected = false;
                    if (toggle.value === item.value) {
                        toggle.isSelected = !toggle.isSelected;
                    }
                    return toggle;
                });
                // if none selected preselect first one
                /* istanbul ignore else */
                if (draft.filter((toggle) => toggle.isSelected).length === 0) {
                    draft[0].isSelected = true;
                }
            });
            dispatch(persistTogglesAction(name, newToggles));
        },
        [name, toggles, isMultiple, dispatch]
    );

    return (
        <ToggleBar
            name={name}
            isMultiple={isMultiple}
            toggles={toggles}
            onClick={handleClick}
        />
    );
};

export default ToggleBarContainer;
