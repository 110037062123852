import React, { useCallback, useMemo } from "react";
import FormsyEditorBase from "./FormsyEditorBase";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import Button from "react-bootstrap/lib/Button";
import Icons from "@hs/icons";
import { type EnumValue } from "data/types";
import { getLocalizedText } from "data/utils";

type Props = {
    /** Enum Values */
    enum: Array<EnumValue<any>>,
    /** Is a required field */
    isRequired?: boolean,
    /** Is a read only field */
    isReadonly?: boolean,
    /** whether it is an Enum or and EnumFlag */
    isMulti?: boolean,
};

type EnumRendererProps = {
    isValid: boolean,
    enum: Array<EnumValues<any>>,
    value?: int,
    name: string,
    changeValue: () => void,
};

const ButtonGroupEnumRenderer = (props: EnumRendererProps) => (
    <ButtonGroup className={!props.isValid ? "has-error" : ""}>
        {props.enum.map((enumValue) => (
            <Button
                key={`enum.${props.name}.${enumValue.value}`}
                onClick={() => props.onChange(enumValue.value)}
                disabled={!!props.isReadonly}
                active={props.isActive(enumValue.value)}
            >
                {enumValue.icon && typeof enumValue.icon === "string" ? (
                    <Icons.Library name={enumValue.icon} />
                ) : (
                    enumValue.icon
                )}{" "}
                {getLocalizedText(enumValue.label)}
            </Button>
        ))}
    </ButtonGroup>
);

ButtonGroupEnumRenderer.displayName = "ButtonGroupEnumRenderer";

const DropdownEnumRenderer = (props: EnumRendererProps) => (
    <DropdownButton
        title={props.activeLabels}
        className={!props.isValid ? "has-error" : ""}
        id={props.id}
        disabled={!!props.isReadonly}
        onClick={/* istanbul ignore next */ (e) => e.stopPropagation()}
    >
        {props.enum.map((enumValue, idx) => (
            <MenuItem
                eventKey={idx + 1}
                key={`enum.${props.name}.${enumValue.value}`}
                onClick={() => props.onChange(enumValue.value)}
                active={props.isActive(enumValue.value)}
            >
                {enumValue.icon && typeof enumValue.icon === "string" ? (
                    <Icons.Library name={enumValue.icon} />
                ) : (
                    enumValue.icon
                )}{" "}
                {getLocalizedText(enumValue.label)}
            </MenuItem>
        ))}
    </DropdownButton>
);

const EnumRenderer = {
    ButtonGroup: ButtonGroupEnumRenderer,
    Dropdown: DropdownEnumRenderer,
};

const EnumEditorControl = (props: Props) => {
    const isActive = useCallback(
        (enumValue: int) =>
            props.isMulti
                ? (props.value & enumValue) > 0
                : enumValue === props.value,
        [props.isMulti, props.value]
    );

    const handleFlagsChange = useCallback(
        (enumValue: int) =>
            (props.value & enumValue) > 0
                ? props.value & ~enumValue
                : props.value | enumValue,
        [props.value]
    );

    const handleChange = useCallback(
        (changeValue: () => void, enumValue: int) => {
            const newValue = props.isMulti
                ? handleFlagsChange(enumValue)
                : enumValue;
            changeValue({ currentTarget: { value: newValue } });
        },
        [props.isMulti, handleFlagsChange]
    );
    const activeLabels = useMemo(
        () =>
            props.enum
                .filter((enumValue) =>
                    props.isMulti
                        ? (props.value & enumValue.value) > 0
                        : enumValue.value ===
                              props.value /* istanbul ignore next */ ||
                          (enumValue.value == null && props.value == null)
                )
                .map((enumValue) => (
                    <React.Fragment
                        key={`enumLabel.${props.name}.${enumValue.value}`}
                    >
                        {enumValue.icon &&
                        typeof enumValue.icon === "string" ? (
                            <Icons.Library name={enumValue.icon} />
                        ) : (
                            enumValue.icon
                        )}{" "}
                        {getLocalizedText(enumValue.label)}
                    </React.Fragment>
                )),
        [props.enum, props.value, props.isMulti, props.name]
    );
    const EnumRenderComponent = useMemo(() => {
        if (
            typeof props.component === "string" &&
            EnumRenderer.hasOwnProperty(props.component)
        ) {
            return EnumRenderer[props.component];
        }
        if (props.component != null && typeof props.component === "function") {
            return props.component;
        }
        return EnumRenderer.ButtonGroup;
    }, [props.component]);

    return (
        <FormsyEditorBase
            required={props.isRequired ? "isDefaultRequiredValue" : undefined}
            useHtmlFor={
                EnumRenderComponent.displayName !== "ButtonGroupEnumRenderer"
            }
            render={(
                value?: int,
                changeValue: (name: string, value?: int) => void,
                isValid: boolean
            ) => (
                <EnumRenderComponent
                    isActive={isActive}
                    isMulti={props.isMulti}
                    isReadonly={props.isReadonly}
                    value={props.value}
                    isValid={isValid}
                    id={props.id || props.name}
                    enum={props.enum}
                    name={props.name}
                    activeLabels={activeLabels}
                    onChange={(enumValue) =>
                        handleChange(changeValue, enumValue)
                    }
                />
            )}
            {...props}
        />
    );
};

EnumEditorControl.displayName = "EnumEditorControl";
export default EnumEditorControl;
