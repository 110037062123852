// @flow
import React from "react";
import { openItemActionPanel } from "components/ItemActionPanel";
import { type MapLocationInfo } from "data/types";
import { getFileExtension } from "data/utils";

export const openGeoPointActionPanel = ({
    location,
}: {
    location: MapLocationInfo,
}) =>
    openItemActionPanel({
        title: location.label,
        ext: location.label.includes(".") && getFileExtension(location.label),
        contextProps: {
            itemUri: location.itemUri || "GEOUPLOAD",
            ext:
                location.label.includes(".") &&
                getFileExtension(location.label),
        },
        thumbnail: location.thumbnail && (
            <img
                src={location.thumbnail}
                alt="thumb"
                style={{
                    width: "100%",
                }}
            />
        ),
        attributes: [
            ["Longitude", String(location.longitude)],
            ["Latitude", String(location.latitude)],
        ],
    });
