// @flow
import { useCallback } from "react";
import { useEvent } from "hooks";

// hook to handle message events
export function usePostMessageHandler(
    handlers: Object,
    parentWindow = window.parent
) {
    // calls the registered handler for a given message type
    const handleMessage = useCallback(
        (data, source) => {
            if (data == null || data.source !== "inPoint") return;
            var handler = handlers[data.type];
            if (handler == null || typeof handler !== "function") return;
            handler(data.data, source);
        },
        [handlers]
    );

    const onMessage = useCallback(
        (e) => {
            // some security checks
            if (!e.isTrusted || e.data == null) return;
            /* istanbul ignore next */ else if (
                window !== parentWindow &&
                window.location.href !== parentWindow.location.href &&
                window.location.origin === parentWindow.location.origin
            )
                // forward message
                parentWindow.postMessage(e.data, "*");
            else {
                // handle
                if (Array.isArray(e.data)) {
                    e.data.forEach((d) => handleMessage(d, e.source));
                } else handleMessage(e.data, e.source);
            }
        },
        [handleMessage, parentWindow]
    );

    useEvent("message", onMessage);
}
