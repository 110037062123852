import React from "react";
import Button from "react-bootstrap/lib/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import Icons from "@hs/icons";
import loc from "i18next";

const Zoom = ({
    value,
    maxZoom,
    onZoom,
}: {
    value: number,
    maxZoom: number,
    onZoom: (zoom: number) => void,
}) => (
    <ButtonGroup>
        <Button
            title={loc.t("upload:image.zoomIn")}
            disabled={value === maxZoom}
            onClick={() => onZoom(value + 1)}
        >
            <Icons.Library name="fa-regular fa-magnifying-glass-plus" />
        </Button>

        <Button
            title={loc.t("upload:image.zoomOut")}
            disabled={value === 1}
            onClick={() => onZoom(value - 1)}
        >
            <Icons.Library name="fa-regular fa-magnifying-glass-minus" />
        </Button>
    </ButtonGroup>
);

Zoom.displayName = "ImageEditor.Zoom";
export default Zoom;
