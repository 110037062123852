// idea extended from https://github.com/watson/roundround/blob/master/index.js
export function clampInRange(array, index) {
    index = index || 0;

    /* istanbul ignore next */
    if (array == null || !Array.isArray(array))
        throw new Error("Expecting argument to RoundRound to be an Array");

    return {
        prev: () => {
            if (index <= 0) index = array.length;
            return array[--index];
        },
        next: () => {
            if (index >= array.length - 1) index = -1;
            return array[++index];
        },
    };
}
