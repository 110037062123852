// @flow
import React from "react";
import Breadcrumb from "react-bootstrap/lib/Breadcrumb";
import { LinkContainer } from "react-router-bootstrap";
import loc from "i18next";
import Status from "components/Status";
import Rename from "components/Rename";

type Props = {
    /** name of search item */
    name: ?string,
    /** whether this is a favorite */
    isFav: boolean,
    /** whether user is allowed to rename searchcrumb (and hide favorite option) */
    isReadonly: boolean,
    /** favorite add/remove fetch status */
    favStatus: string,
    /** callback when favorite icon is clicked */
    favOnClick?: () => void,
    /** callback when user renamed favorite
     * @param {string} name new name
     */
    onRename: ?(name: string) => void,
    /** CSS styles to apply */
    style?: StyleSheet | Object,
    /** className to apply */
    className?: string,
};

const Searchcrumb = ({
    name,
    isFav = false,
    isReadonly = false,
    favStatus,
    favOnClick,
    onRename,
    style,
    className,
}: Props) => (
    <Breadcrumb>
        {typeof favOnClick === "function" &&
            !isReadonly &&
            (isFav ? (
                <a
                    href="about:blank"
                    title={loc.t("breadcrumb.delfav.title")}
                    onClick={favOnClick}
                >
                    <Status status={favStatus} ok="star" inline />
                </a>
            ) : (
                <a
                    href="about:blank"
                    title={loc.t("breadcrumb.addfav.title")}
                    onClick={favOnClick}
                >
                    <Status status={favStatus} ok="fa-regular fa-star" inline />
                </a>
            ))}{" "}
        <Breadcrumb.Item active={isReadonly}>
            {isReadonly === true && (
                <span title={loc.t("sidebar.search")}>
                    {loc.t("search.searchcrumb")}
                </span>
            )}
            {isReadonly === false && (
                <LinkContainer to="/search" title={loc.t("sidebar.search")}>
                    <span>{loc.t("search.searchcrumb")}</span>
                </LinkContainer>
            )}
        </Breadcrumb.Item>
        {name && String(name).length > 0 && (
            <Breadcrumb.Item active={true}>
                {typeof onRename === "function" && !isReadonly ? (
                    <Rename
                        name={name}
                        onChange={onRename}
                        title={loc.t("common:breadcrumb.renfav.title")}
                    />
                ) : (
                    name
                )}
            </Breadcrumb.Item>
        )}
    </Breadcrumb>
);
Searchcrumb.displayName = "Searchcrumb";
export default Searchcrumb;
