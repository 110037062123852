// @flow
import React, { useState, useMemo, useCallback } from "react";
import { useAsync } from "hooks";
import { openPickerModal } from "components/PickerModal";
import immutable from "object-path-immutable";
import Modal, { ModalSize } from "components/Modal";
import { createModal } from "react-modal-promise";
import LayersEditor from "./LayersEditor";
import Button from "react-bootstrap/lib/Button";
import Icons from "@hs/icons";
import loc from "i18next";
import styles from "./RasterMapModal.module.css";
import { type RasterMapResultDTO, RasterMapLayerEditAction } from "data/types";
import { getGeoRasterProvider } from "components/MapViewer/providerSupport";
import Status from "components/Status";
import Constants from "data/constants";
import { fetchRasterMapLayers, saveRasterMapLayers } from "data/api";

type Props = {
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog */
    close: () => void,
    /** current formatId */
    formatId: number,
    /** current itemUri  */
    itemUri: string,
    /** current name  */
    name: string,
};

/** presents the available raster layers and allows managing them */
export const RasterMapManagerModal = ({
    open,
    close,
    formatId,
    itemUri,
    name,
}: Props) => {
    const [map, setMap] = useState();

    // get current mapProvider
    const mapProvider = useMemo(
        () => getGeoRasterProvider(itemUri, formatId),
        [itemUri, formatId]
    );
    // get current layers
    const state = useAsync(async () => {
        try {
            // retrieve any existing layers (or create appropriately)
            const map: RasterMapResultDTO = await fetchRasterMapLayers({
                itemUri,
                rasterMapIdSelector: mapProvider.rasterMapIdSelector,
                create: true,
            });
            setMap(map);
            return map;
        } catch (e) {
            console.error(e);
            throw e;
        }
    });

    const addLayer = useCallback(async () => {
        const selectedItemUri = await openPickerModal({
            itemUri,
            helpText: loc.t("common:raster.pickerText"),
        });
        /* istanbul ignore else */
        if (selectedItemUri) {
            setMap(
                immutable.push(map, "layers", {
                    editAction: RasterMapLayerEditAction.add,
                    name: loc.t("common:raster.newLayer"),
                    itemUri: selectedItemUri,
                })
            );
        }
    }, [itemUri, map]);

    const saveMap = useCallback(async () => {
        try {
            await saveRasterMapLayers({ map });
            window.location.reload(true);
        } catch (e) {
            console.error(e);
            alert(e.message);
        }
    }, [map]);

    return (
        <Modal
            size={ModalSize.Small}
            scroll
            enforceFocus
            show={open !== false}
            onHide={/* istanbul ignore next */ () => close(false)}
            dialogClassName={styles.modal}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Icons.Library name="map" /> {name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {state.loading && <Status status={Constants.LOADING} />}
                {state.error && <Status status={Constants.ERROR} />}
                {state.value && (
                    <LayersEditor
                        map={map}
                        onMapEdit={
                            /* istanbul ignore next */ (newMap) =>
                                setMap(newMap)
                        }
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                {state.value && (
                    <Button
                        id="btnAddLayer"
                        aria-label="add"
                        onClick={addLayer}
                        bsStyle="link"
                        className="pull-left"
                    >
                        {loc.t("common:raster.addLayer")}
                    </Button>
                )}
                <Button
                    id="btnSave"
                    aria-label="save"
                    onClick={saveMap}
                    bsStyle="primary"
                    disabled={state.value == null}
                >
                    {loc.t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
RasterMapManagerModal.displayName = "RasterMapManagerModal";
export const openRasterMapManagerModal = createModal(RasterMapManagerModal);
