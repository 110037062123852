import { combineReducers } from "redux";
import togglesReducer from "./togglesReducer";
import userReducer from "./userReducer";
import loginReducer from "./loginReducer";
import formReducer from "./formReducer";
import cardsReducer from "./cardsReducer";
import workflowReducer from "./workflowReducer";
import panesReducer from "./panesReducer";
import treeReducer from "./treeReducer";
import docsReducer from "./docsReducer";
import thumbnailsReducer from "./thumbnailsReducer";
import docsLayoutReducer from "./docsLayoutReducer";
import settingsReducer from "./settingsReducer";
import breadcrumbReducer from "./breadcrumbReducer";
import favoritesReducer from "./favoritesReducer";
import globalSearchReducer from "./globalSearchReducer";
import currentItemReducer from "./currentItemReducer";
import searchReducer from "./searchReducer";
import searchFormsReducer from "./searchFormsReducer";
import globalEventsReducer from "./globalEventsReducer";
import offlineReducer from "./offlineReducer";
import serviceWorkerReducer from "./serviceWorkerReducer";
import notificationReducer from "./notificationReducer";
import commandActionReducer from "./commandActionReducer";
// import { reducer as oidcReducer } from "redux-oidc";
import { createResponsiveStateReducer } from "redux-responsive";
import transform from "lodash/transform";
import { reducer as uppyReducer } from "@uppy/store-redux";
import { reducer as networkReducer } from "redux-saga-network-status";

const appReducers = combineReducers({
    // oidc: oidcReducer,
    toggles: togglesReducer,
    user: userReducer,
    offline: offlineReducer,
    login: loginReducer,
    favorites: favoritesReducer,
    cards: cardsReducer,
    workflow: workflowReducer,
    tree: treeReducer,
    docs: docsReducer,
    thumbnails: thumbnailsReducer,
    docsLayout: docsLayoutReducer,
    panes: panesReducer,
    settings: settingsReducer,
    breadcrumb: breadcrumbReducer,
    globalSearch: globalSearchReducer,
    current: currentItemReducer,
    search: searchReducer,
    searchForms: searchFormsReducer,
    form: formReducer,
    globalEvents: globalEventsReducer,
    sw: serviceWorkerReducer,
    network: networkReducer,
    commandAction: commandActionReducer,
    browser: createResponsiveStateReducer(null, {
        extraFields: ({ greaterThan, is }) => ({
            // greaterThanOrEqual is built by transforming greaterThan
            greaterThanOrEqual: transform(
                greaterThan,
                (result, value, mediaType) => {
                    // and combining the value with the `is` field
                    result[mediaType] = value || is[mediaType];
                },
                {}
            ),
        }),
    }),
    uppy: uppyReducer,
    notification: notificationReducer,
});

export default appReducers;
