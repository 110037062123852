// @flow
import React, { useState, useEffect, useCallback, type Element } from "react";
import { useSelector, useDispatch } from "react-redux";
import { compose } from "redux";
import FolderTree from "components/FolderTree";
import { isValidItemUri } from "data/utils";
import { getTreeAction, togglesUpdateCounterAction } from "data/actions";
import * as s from "data/reducers/selectors";
import {
    ServerEventActionType,
    ServerEventItemType,
    type TreeItemDTO,
} from "data/types";
import Constants from "data/constants";
import Status from "components/Status";
import GlobalEventsHandler from "containers/GlobalEventsHandler";
import NoData from "components/NoData";
import loc from "i18next";

type Props = {
    /** current itemUri */
    itemUri: string,
    /** current selected tree node's id */
    activeTreeNodeId: number,
    /** text filtering */
    filter: ?string,
    /** whether to include subfolders when text filtering */
    includeSubfolders: boolean,
    /** callback when a tree item is clicked
     * @param {string} itemUri tree node's itemUri     * @param {number} nodeId tree node's id
     */
    onClick: (itemUri: string, nodeId: number) => void,
    /** callback when a tree item is expanded
     * @param {string} itemUri tree node's itemUri
     */
    onExpand: (itemUri: string) => void,
    /** callback when tree is being scrolled */
    onScroll?: () => void,
    /** GlobalEventsHandler.refreshId */
    refreshId: number,
    /** optional NoData Content Component */
    NoDataContent: Element<any>,
    /** whether list is currently focused
     * @default {boolean} true
     */
    focused?: boolean,
};

export const FolderTreeContainer = ({
    itemUri,
    viewName = "folder",
    activeTreeNodeId,
    filter,
    includeSubfolders,
    onClick,
    onExpand,
    onScroll,
    refreshId,
    NoDataContent,
    focused = true,
}: Props) => {
    const dispatch = useDispatch();
    // redux state
    const status = useSelector(s.treeStatusSelector);
    const error = useSelector(s.treeErrorSelector);
    const treeNodes = useSelector(s.treeNodesSelector);
    const totalCount = useSelector(s.treeTotalCountSelector);
    const isBrowsable = useSelector(s.treeIsBrowsableSelector);

    const [all, setAll] = useState(false);

    const _updateToggleCounter = useCallback(
        (count: number, loading?: boolean, status?: string): void =>
            dispatch(
                togglesUpdateCounterAction({
                    name: viewName,
                    counts: { tree: count },
                    status: {
                        tree: loading === true ? Constants.LOADING : status,
                    },
                })
            ),
        [dispatch, viewName /*, treeNodes, totalCount, filter*/]
    );

    useEffect(() => {
        /* istanbul ignore else */
        if (itemUri && isValidItemUri(itemUri)) {
            // fetch results
            dispatch(
                getTreeAction({
                    itemUri,
                    filter,
                    deep: includeSubfolders,
                    all: false,
                })
            );
            setAll(false);
        }
    }, [
        dispatch,
        itemUri,
        filter,
        includeSubfolders,
        refreshId,
        _updateToggleCounter,
    ]);

    // update tree toggle:
    // show totalCount of results,
    // show if results are filtered,
    // show if there are more results available
    useEffect(() => {
        if (status === Constants.OK)
            _updateToggleCounter(
                totalCount,
                false,
                filter && String(filter).length > 0
                    ? "filter"
                    : treeNodes && treeNodes.length < totalCount
                    ? "forward-step"
                    : null
            );
        else if (status === Constants.LOADING)
            // start loading icon
            _updateToggleCounter(0, true);
    }, [_updateToggleCounter, totalCount, status, treeNodes, filter]);

    // user clicks a tree node
    const _onClick = useCallback(
        (treeNode: TreeItemDTO, e?: SyntheticMouseEvent<*>) => {
            /* istanbul ignore next */
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            onClick(treeNode.itemUri, treeNode.id);
        },
        [onClick]
    );

    // user expands a tree node
    const _onExpand = (treeNode: TreeItemDTO, e?: SyntheticMouseEvent<*>) => {
        /* istanbul ignore else */
        if (!isValidItemUri(treeNode.itemUri)) {
            return;
        }
        /* istanbul ignore next */
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        onExpand(treeNode.itemUri);
    };

    const _onLoadMore = useCallback(() => {
        // start loading icon
        _updateToggleCounter(0, true);
        // fetch results
        dispatch(
            getTreeAction({
                itemUri,
                filter,
                deep: includeSubfolders,
                all: true,
            })
        );
        setAll(true);
    }, [dispatch, _updateToggleCounter, itemUri, filter, includeSubfolders]);

    // #56492 Handle folders that are not on initial Tree payload
    useEffect(() => {
        if (
            filter ||
            !treeNodes ||
            treeNodes.length === 0 ||
            activeTreeNodeId == null ||
            treeNodes.some((node) => node.id === activeTreeNodeId)
        ) {
            return;
        }
        if (totalCount === treeNodes.length) {
            // Item does not exist (anymore), select first element
            onClick(treeNodes[0].itemUri, treeNodes[0].id);
            return;
        }
        if (!all) _onLoadMore();
    }, [
        _onLoadMore,
        totalCount,
        treeNodes,
        activeTreeNodeId,
        all,
        onClick,
        filter,
    ]);

    if (status !== Constants.OK) {
        return <Status status={status} error={error} />;
    }

    // do not render further if this folder is non-browsable!
    if (isBrowsable === false) {
        return (
            <NoData locContext="folder">
                <div>{loc.t("folder:nonBrowsable")}</div>
            </NoData>
        );
    } else {
        return (
            <FolderTree
                itemUri={itemUri}
                activeTreeNodeId={activeTreeNodeId}
                focused={focused}
                tree={treeNodes}
                filter={filter}
                onClick={_onClick}
                onDoubleClick={_onExpand}
                onLoadMore={_onLoadMore}
                onExpand={_onExpand}
                onScroll={onScroll}
                NoDataContent={NoDataContent}
                totalCount={totalCount}
            />
        );
    }
};

export default compose(
    GlobalEventsHandler({
        actions: [
            ServerEventActionType.add,
            ServerEventActionType.modify,
            ServerEventActionType.refresh,
            ServerEventActionType.delete,
        ],
        items: [ServerEventItemType.folder],
    })
)(FolderTreeContainer);
