// global geolocator settings
const geoLocatorOptions = (custom: Object) => ({
    enableHighAccuracy: true,
    timeout: 5000,
    maximumWait: 10000, // max wait time for desired accuracy
    maximumAge: 0, // disable cache
    desiredAccuracy: 2, // meters
    fallbackToIP: false,
    addressLookup: false, // requires Google API key if true
    timezone: false, // requires Google API key if true
    //map: "map-canvas", // interactive map element id (or options object)
    staticMap: false, // get a static map image URL (boolean or options object)
    ...custom,
});
export default geoLocatorOptions;
