// @flow
import React, { type Node, useMemo } from "react";
import styles from "./Avatar.module.css";
import { useSelector } from "react-redux";
import { isFeatureOn, Feature } from "data/constants";
import * as s from "data/reducers/selectors";
import { getClassNames } from "data/utils";

type Props = {
    id: number,
    style?: StyleSheet | Object,
    title?: string,
    className?: string,
    children?: Node,
};

const Avatar = ({ id, style, title, className, children }: Props) => {
    const isOnline = useSelector(s.isOnlineSelector);
    const correlationId = useSelector(s.userCorrelationIdSelector);

    const imgUrl = useMemo(() => {
        let result = `${window.CONFIG.host.basename}${
            window.CONFIG.general.api
        }/user/avatar/${id}?correlationId=${
            isOnline || !isFeatureOn(Feature.offline)
                ? encodeURIComponent(correlationId)
                : "offline"
        }`;
        return result;
    }, [id, isOnline, correlationId]);

    const avatarStyle = useMemo(
        () =>
            Object.assign(
                {},
                {
                    backgroundImage: `url(${imgUrl})`,
                },
                style
            ),
        [imgUrl, style]
    );

    return (
        <div
            className={getClassNames(styles.avatar, className)}
            alt={title || "user"}
            title={title}
            style={avatarStyle}
        >
            {children}
        </div>
    );
};
Avatar.displayName = "Avatar";
export default Avatar;
