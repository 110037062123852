import React, { useState, useCallback, useMemo, useRef } from "react";
import FormsyEditorBase from "./FormsyEditorBase";
import Mark from "@hs/mark";
import PrincipalAvatar from "components/User/PrincipalAvatar";
import loc from "i18next";
import { fetchSearchPrincipal } from "data/api";
import {
    PrincipalType,
    type UserPrincipalDTO,
    type GroupPrincipalDTO,
    type ApiError,
} from "data/types";
import { closeMenuOnScroll } from "data/utils";
import Async from "react-select/async";
import AsyncCreatable from "react-select/async-creatable";
import { components } from "react-select";
import { validationRules } from "formsy-react";
import styles from "./PrincipalEditorControl.module.css";
import { useAbortController } from "hooks";

const { Option } = components;

type Props = {
    /** Allows to create new User */
    createUser?: boolean,
    /** Allows to create new Group */
    createGroup?: boolean,
    /** Handle Users */
    includeUsers?: boolean,
    /** Handle UserGroups */
    includeGroups?: boolean,
    /** Is a required field */
    isRequired?: boolean,
    /** Can multiple Users be selected */
    isMulti?: boolean,
    /** Can input be cleared */
    isClearable?: boolean,
    /** New UserId reference */
    newUserIdRef?: { current?: int },
    /** Focus the control when it is mounted */
    autoFocus?: boolean,
    /** Name of the input field */
    name: string,
};

const PrincipalEditorControl = (props: Props) => {
    const localNewUserIdRef = useRef(0);
    const abortController = useAbortController();
    const {
        isMulti = false,
        createUser = false,
        createGroup = false,
        includeUsers = true,
        includeGroups = false,
        newUserIdRef = localNewUserIdRef,
    } = props;
    const [keyword, setKeyword] = useState("");

    const getNewId = useCallback(() => {
        newUserIdRef.current -= 1;
        return newUserIdRef.current;
    }, [newUserIdRef]);

    const types = useMemo(() => {
        const types = [];
        /* istanbul ignore else */
        if (includeUsers)
            types.push(loc.t(`principal.type.user${isMulti ? "s" : ""}`));
        /* istanbul ignore else */
        if (includeGroups)
            types.push(loc.t(`principal.type.group${isMulti ? "s" : ""}`));
        return types.join(
            isMulti ? loc.t("principal.type.and") : loc.t("principal.type.or")
        );
    }, [includeUsers, includeGroups, isMulti]);

    const Component = useMemo(
        () => (createUser || createGroup ? AsyncCreatable : Async),
        [createUser, createGroup]
    );
    const componentProps = useMemo(
        () =>
            createUser || createGroup
                ? {
                      createOptionPosition: "first",
                      isValidNewOption: (
                          inputValue: string,
                          selectValue?: Array<any> = [],
                          selectOptions?: Array<any> = []
                      ) =>
                          inputValue &&
                          selectOptions.find(
                              (principal) =>
                                  principal &&
                                  principal.email &&
                                  principal.email.toLowerCase() ===
                                      inputValue.toLowerCase()
                          ) == null &&
                          createUser &&
                          validationRules.isEmail(selectValue, inputValue),
                      getNewOptionData: (
                          inputValue: string,
                          optionLabel: React.Node
                      ): any => {
                          const isEmail = validationRules.isEmail(
                              undefined,
                              inputValue
                          );
                          return {
                              id: getNewId(),
                              $type: isEmail
                                  ? PrincipalType.User
                                  : PrincipalType.Group,
                              displayName: inputValue,
                              email: isEmail ? inputValue : undefined,
                          };
                      },
                  }
                : {},
        [createUser, createGroup, getNewId]
    );

    const handleInputChange = useCallback((keyword: string) => {
        setKeyword(keyword);
        return keyword;
    }, []);

    const handleSearchAsYouType = useCallback(
        (
            keyword: string
        ): Promise<Array<UserPrincipalDTO | GroupPrincipalDTO>> => {
            if (keyword.length > 0) {
                return fetchSearchPrincipal(
                    keyword,
                    !!includeUsers,
                    !!includeGroups,
                    /* !! Do NOT abort multiple calls, since Async react-select, caches responses !! */
                    abortController().signal
                )
                    .catch((err: ApiError) => {
                        return [];
                    })
                    .then((options) => options);
            } else {
                // TODO return previously used emails
                return Promise.resolve([]);
            }
        },
        [includeUsers, includeGroups, abortController]
    );
    const optionRenderer = useCallback(
        (props) =>
            Option && (
                <Option {...props}>
                    <PrincipalAvatar
                        id={props.data.id}
                        type={props.data.$type}
                        className={styles.avatar}
                    />
                    {props.data.id < 0 ? (
                        /* istanbul ignore next */
                        loc.t(`principal.new.${props.data.$type}`, {
                            ...props.data,
                        })
                    ) : (
                        <Mark className={styles.name} word={keyword}>
                            {props.data.displayName}
                        </Mark>
                    )}
                </Option>
            ),
        [keyword]
    );
    return (
        <FormsyEditorBase
            required={props.isRequired ? "isDefaultRequiredValue" : undefined}
            render={(
                value?: UserPrincipalDTO | GroupPrincipalDTO,
                changeValue: (
                    name: string,
                    value?: UserPrincipalDTO | GroupPrincipalDTO
                ) => void,
                isValid: boolean
            ) => (
                <Component
                    classNamePrefix="pec"
                    inputId={props.name}
                    // styling
                    styles={{
                        container: /* istanbul ignore next */ (
                            provided,
                            state
                        ) => ({
                            ...provided,
                            width: "100%",
                            minWidth: 191,
                        }),
                        control: /* istanbul ignore next */ (
                            provided,
                            state
                        ) => ({
                            ...provided,
                            minHeight: 34,
                            borderColor: isValid
                                ? provided.borderColor
                                : "#a94442",
                            borderRadius: 0,
                        }),
                        menu: /* istanbul ignore next */ (provided, state) => ({
                            ...provided,
                            borderRadius: 0,
                            marginTop: 0,
                        }),
                        menuPortal: /* istanbul ignore next */ (
                            provided,
                            state
                        ) => ({
                            ...provided,
                            zIndex: 9999,
                        }),
                        valueContainer: /* istanbul ignore next */ (
                            provided,
                            state
                        ) => ({
                            ...provided,
                            padding: "0px 6px",
                            minHeight: 34,
                        }),
                        input: /* istanbul ignore next */ (
                            provided,
                            state
                        ) => ({
                            ...provided,
                            margin: 0,
                            padding: 0,
                        }),
                        dropdownIndicator: /* istanbul ignore next */ (
                            provided,
                            state
                        ) => ({
                            ...provided,
                            padding: 4,
                        }),
                        clearIndicator: /* istanbul ignore next */ (
                            provided,
                            state
                        ) => ({
                            ...provided,
                            padding: 4,
                        }),
                        option: /* istanbul ignore next */ (
                            provided,
                            state
                        ) => {
                            let style = {
                                ...provided,
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                            };
                            /* istanbul ignore next */
                            if (state.isFocused) {
                                return Object.assign({}, style, {
                                    boxShadow: "none",
                                    background: "#f5f5f5",
                                    textShadow: "none",
                                    cursor: "pointer",
                                    // border: "1px solid #f5f5f5"
                                });
                            }
                            return style;
                        },
                    }}
                    components={{
                        Option: optionRenderer,
                    }}
                    // async loading
                    cacheOptions={true}
                    loadOptions={handleSearchAsYouType}
                    // options
                    getOptionLabel={
                        /* istanbul ignore next */
                        (option: UserPrincipalDTO | GroupPrincipalDTO) =>
                            option.displayName
                    }
                    getOptionValue={
                        /* istanbul ignore next */
                        (option: UserPrincipalDTO | GroupPrincipalDTO) =>
                            option.id
                    }
                    defaultOptions={true}
                    isMulti={props.isMulti || false}
                    // behaviour
                    isClearable={props.isClearable || false}
                    openMenuOnFocus={true}
                    // localizations
                    aria-label={loc.t("principal.search.prompt", { types })}
                    loadingMessage={
                        /* istanbul ignore next */
                        () => loc.t("principal.search.loading")
                    }
                    placeholder={loc.t("principal.placeholder", { types })}
                    noOptionsMessage={
                        /* istanbul ignore next */
                        () => loc.t("principal.search.prompt", { types })
                    }
                    // selection
                    onInputChange={handleInputChange}
                    onChange={(value) =>
                        changeValue({
                            currentTarget: {
                                value:
                                    Array.isArray(value) && !value.length
                                        ? null
                                        : value,
                            },
                        })
                    }
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                    closeMenuOnScroll={closeMenuOnScroll}
                    value={props.value}
                    autoFocus={props.autoFocus}
                    {...componentProps}
                />
            )}
            {...props}
        />
    );
};

PrincipalEditorControl.displayName = "PrincipalEditorControl";
export default PrincipalEditorControl;
