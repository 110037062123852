import { all, fork } from "redux-saga/effects";
import offlineSaga, { checkServerConnectivity } from "./offlineSaga";
import userSaga from "./userSaga";
import favoritesSaga from "./favoritesSaga";
import cardsSaga from "./cardsSaga";
import workflowSaga from "./workflowSaga";
import treeSaga from "./treeSaga";
import docsSaga from "./docsSaga";
import docsLayoutSaga from "./docsLayoutSaga";
import currentItemSaga from "./currentItemSaga";
import searchSaga from "./searchSaga";
import thumbnailsSaga from "./thumbnailsSaga";
import watchNetworkStatus from "redux-saga-network-status";
import notificationsSaga from "./notificationsSaga";
import toastSaga from "./toastSaga";
import commandActionSaga from "./commandActionSaga";

export default function* rootSaga() {
    yield all([
        fork(watchNetworkStatus),
        fork(checkServerConnectivity),
        fork(offlineSaga),
        fork(userSaga),
        fork(cardsSaga),
        fork(workflowSaga),
        fork(favoritesSaga),
        fork(treeSaga),
        fork(docsSaga),
        fork(docsLayoutSaga),
        fork(currentItemSaga),
        fork(searchSaga),
        fork(thumbnailsSaga),
        fork(notificationsSaga),
        fork(toastSaga),
        fork(commandActionSaga),
    ]);
}

export function* localRootSaga() {
    yield all([
        fork(watchNetworkStatus),
        fork(checkServerConnectivity),
        fork(favoritesSaga),
        fork(treeSaga),
        fork(docsSaga),
        fork(docsLayoutSaga),
        fork(currentItemSaga),
        fork(searchSaga),
        fork(thumbnailsSaga),
        fork(toastSaga),
        fork(commandActionSaga),
    ]);
}
