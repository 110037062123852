import React from "react";
import sharedStyles from "./SummaryAttribute.module.css";
import styles from "./ValueFacePile.module.css";
import Badge from "react-bootstrap/lib/Badge";
import PrincipalAvatar from "components/User/PrincipalAvatar";
import CommandActionHandler from "components/CommandActions";
import loc from "i18next";
import { getClassNames } from "data/utils";

import { type SummaryAttributeValueFacePile } from "data/types";

const ValueFacePile = ({
    value,
    className,
}: {
    value: SummaryAttributeValueFacePile,
}) => (
    <span className={getClassNames(className, styles.wrapper)}>
        {value.pile.map((p, index) => (
            <PrincipalAvatar
                id={p.id}
                type={p.$type}
                className={sharedStyles.avatar}
                key={`avp${p.id}-${index}`}
                title={`${p.displayName} ${p.description || ""}`}
            />
        ))}
        {value.total != null && value.total > value.pile.length && (
            <Badge
                className={styles.facePileMore}
                title={
                    (value.onMoreCommand != null &&
                        loc.t("summaryAttribute:facePile.onMore")) ||
                    ""
                }
                onClick={
                    /* istanbul ignore next */ () =>
                        value.onMoreCommand != null &&
                        CommandActionHandler(
                            value.onMoreCommand.name,
                            value.onMoreCommand.props
                        )
                }
            >
                +{value.total - value.pile.length}
            </Badge>
        )}
    </span>
);
ValueFacePile.displayName = "SummaryAttributeValue.FacePile";
export default ValueFacePile;
