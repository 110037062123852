import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";

const initialState = {
    name: "",
    itemUri: "",
    status: Constants.LOADING,
};

const currentItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CURRENTITEM_BAK:
            return {
                ...state,
                saved: state,
            };
        case actionTypes.CURRENTITEM_RESTORE:
            return {
                ...state,
                ...state.saved,
                saved: undefined,
            };
        case actionTypes.CURRENTITEM_SET:
            const { name, itemUri } = state;
            // we reset the state.current to remove any extra values
            return {
                name,
                itemUri,
                status: Constants.LOADING,
                saved: state.saved,
                ...action.payload,
            };
        case actionTypes.CURRENTITEM_LOADED:
            return { ...state, ...action.payload };

        /*case actionTypes.SPLITPANES_SET:
            return {
                ...state,
                [action.payload.name]: action.payload.dimensions
            };*/

        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default currentItemReducer;

export const currentItemUriSelector = (state: Object) => state.current.itemUri;
export const currentNameSelector = (state: Object) => state.current.name;
export const currentStatusSelector = (state: Object) => state.current.status;
