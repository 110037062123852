// @flow
import React from "react";
import styles from "./ToggleBar.module.css";
import Button from "./Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import { type ToggleItemDTO } from "data/types";

type Props = {
    name: string,
    isMultiple: boolean,
    toggles: Array<ToggleItemDTO>,
    onClick: Function,
};

const ToggleBar = ({ name, toggles, ...props }: Props) => (
    <ButtonGroup data-test="toggleButtonGroup" className={styles.group}>
        {toggles.map((item: ToggleItemDTO, index: number) => (
            <Button
                {...props}
                item={item}
                key={`toggle-${name}-${item.name ?? index}`}
            />
        ))}
    </ButtonGroup>
);

export default ToggleBar;
