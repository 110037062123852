// @flow
import React, { PureComponent, type ComponentType } from "react";
import { connect, Dispatch } from "react-redux";
import Constants, { getDisplayName } from "data/constants";
import { type docsLayoutState } from "data/reducers/types";
import { requestDocsLayoutAction } from "data/actions";
import Status from "components/Status";

type Props = {
    dispatch: Dispatch,
    itemUri: string,
    layout: docsLayoutState,
    showDocs?: boolean,
};

// will block until docsLayout is OK and pass it on as a layout prop
const LayoutLoader = (WrappedComponent: ComponentType<any>) => {
    class PP extends PureComponent<Props> {
        static displayName = `LayoutLoader(${getDisplayName(
            WrappedComponent
        )})`;

        componentDidMount() {
            const { dispatch, itemUri, showDocs } = this.props;
            dispatch(requestDocsLayoutAction(itemUri, showDocs === false));
        }

        componentDidUpdate(prevProps: Props) {
            const { dispatch, itemUri, showDocs } = this.props;
            if (itemUri !== prevProps.itemUri) {
                dispatch(requestDocsLayoutAction(itemUri, showDocs === false));
            }
        }

        render() {
            const { layout, ...rest } = this.props;
            if (layout.status === Constants.OK)
                return <WrappedComponent {...rest} layout={layout} />;
            if (layout.status === Constants.ERROR)
                return <Status status={Constants.ERROR} />;
            return <Status status={Constants.LOADING} />;
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        layout: state.docsLayout,
        ...ownProps,
    });

    return connect(mapStateToProps)(PP);
};

export default LayoutLoader;
