import { useRef, useEffect, useCallback } from "react";

const useAbortController = (
    abortControllerInstance = new AbortController()
) => {
    const abort = useRef(abortControllerInstance);

    // Get AbortController (optional: abort existing)
    const abortController = useCallback((reset = false): AbortController => {
        if (reset) {
            abort.current.abort();
            abort.current = new AbortController();
        }
        return abort.current;
    }, []);

    // Abort on unmount
    useEffect(() => {
        function handleUnmount() {
            abort.current.abort();
        }
        return handleUnmount;
    }, [abort]);
    return abortController;
};

export { useAbortController };
