import React from "react";
import styles from "./ValueProgress.module.css";
import ProgressBar from "react-bootstrap/lib/ProgressBar";
import { getClassNames } from "data/utils";

import { type SummaryAttributeValueProgress } from "data/types";

const ValueProgress = ({
    value,
    className,
}: {
    value: SummaryAttributeValueProgress,
    className: string,
}) => {
    if (value.progress) {
        return (
            <ProgressBar className={getClassNames(className, styles.progress)}>
                {value.progress.map((p, index) => (
                    <ProgressBar key={`wap-${index}`} title={p.label} {...p} />
                ))}
            </ProgressBar>
        );
    }

    return null;
};
ValueProgress.displayName = "SummaryAttributeValue.Progress";
export default ValueProgress;
