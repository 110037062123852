import React from "react";
import Icons from "@hs/icons";
import Thumbnail from "components/DocListSimple/Thumbnail";
import NoData from "components/NoData";
import styles from "./itemView_docListGallery.module.css";
import { useSwipeable } from "react-swipeable";

const Thumbs = ({ result, onClick }) =>
    Object.keys(result.rows).map((key, index) => {
        const name = result.rows[key][1]; //hopefully it's always at that position ;)
        const itemUri = result.rows[key][result.cols.length - 1];
        return (
            <button
                key={`ivdlg-gal${index}`}
                className={styles.thumb}
                title={name}
                onClick={/* istanbul ignore next */ () => onClick(itemUri)}
            >
                <Thumbnail itemUri={itemUri} />
            </button>
        );
    });
Thumbs.displayName = "ItemView_DocListGallery.Thumbs";

const ItemView_DocListGallery = ({ result, onClick }) => {
    const stripRef = React.useRef();
    const goLeft = /*istanbul ignore next*/ () =>
        (stripRef.current.scrollLeft -= 80);
    const goRight = /*istanbul ignore next*/ () =>
        (stripRef.current.scrollLeft += 80);
    const handlers = useSwipeable({
        onSwipeLeft: goLeft,
        onSwipeRight: goRight,
    });

    if (result && result.rows && result.rowCount > 0) {
        return (
            <div className={styles.strip} {...handlers}>
                <div onClick={goLeft} className={styles.nav}>
                    <Icons.Library name="chevron-left" fixedWidth />
                </div>
                <div ref={stripRef} className={styles.stripInner}>
                    <Thumbs result={result} onClick={onClick} />
                </div>
                <div onClick={goRight} className={styles.nav}>
                    <Icons.Library name="chevron-right" fixedWidth />
                </div>
            </div>
        );
    }

    return <NoData />;
};
ItemView_DocListGallery.displayName = "ItemViews.DocListGallery";
export default ItemView_DocListGallery;
