//@flow
import { useCallback, useEffect } from "react";
import {
    ItemStatuses,
    SpecialFoldersItemURIs,
    type ItemStatusesDTO,
} from "data/types";
import { type History } from "history";

const useRecycleBin = (
    itemUriFromProps: string,
    itemStatusFromProps: ItemStatusesDTO = ItemStatuses.Nothing,
    history: History = null
) => {
    const checkIfRecycledDocuments = useCallback(
        (itemUri: string = itemUriFromProps) =>
            itemUri?.endsWith(SpecialFoldersItemURIs.RecycledDocumentsUri),
        [itemUriFromProps]
    );
    const checkIfDeleted = useCallback(
        (
            itemStatus: ItemStatusesDTO = itemStatusFromProps,
            itemUri: string = itemUriFromProps
        ) =>
            (itemStatus & ItemStatuses.Deleted) > 0 ||
            checkIfRecycledDocuments(itemUri),
        [itemStatusFromProps, itemUriFromProps, checkIfRecycledDocuments]
    );
    const checkIfAnyParentDeleted = useCallback(
        (itemStatus: ItemStatusesDTO = itemStatusFromProps) =>
            (itemStatus & ItemStatuses.ParentDeleted) > 0,
        [itemStatusFromProps]
    );
    const checkIfInRecycleBin = useCallback(
        (itemStatus: ItemStatusesDTO = itemStatusFromProps) =>
            checkIfDeleted(itemStatus) || checkIfAnyParentDeleted(itemStatus),
        [itemStatusFromProps, checkIfDeleted, checkIfAnyParentDeleted]
    );
    useEffect(() => {
        if (history == null) return;
        const { pathname, search, hash } = history.location;

        const isRecycleBin = checkIfInRecycleBin();
        if (
            (isRecycleBin &&
                hash !== `#${SpecialFoldersItemURIs.RecycleBinUri}`) ||
            (!isRecycleBin &&
                hash === `#${SpecialFoldersItemURIs.RecycleBinUri}`)
        ) {
            history.replace(
                `${pathname}${search}${
                    isRecycleBin
                        ? `#${SpecialFoldersItemURIs.RecycleBinUri}`
                        : ""
                }`
            );
        }
    }, [checkIfInRecycleBin, history]);
    return [
        checkIfRecycledDocuments,
        checkIfDeleted,
        checkIfAnyParentDeleted,
        checkIfInRecycleBin,
    ];
};

export { useRecycleBin };
