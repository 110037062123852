import React from "react";
import { compose } from "redux";
import { useAsync } from "hooks";
import Constants from "data/constants";
import { fetchWorkflowInfo } from "data/api";
import Status from "components/Status";
import styles from "./itemView_workflow.module.css";
import CountBadge from "components/CountBadge";
import Attributes from "components/Attributes";
import Divider from "components/Divider";
import {
    type ItemActionViewConfig,
    type ItemActionConfig,
    type WorkflowAction,
    ServerEventItemType,
} from "data/types";
import ItemActions from "components/ActionPanel/ItemActions";
import loc from "i18next";
import { get } from "data/constants";
import GlobalEventsHandler from "containers/GlobalEventsHandler";

/**
 * shows the pending Workflow Actions
 */
export const WorkflowActions = ({
    onClick,
    actions,
}: {
    onClick: (props: Object) => void,
    actions: Array<WorkflowAction>,
}) => {
    const workflowActions = actions.map((action) => ({
        icon: action.icon,
        name: action.caption,
        desc: action.description,
        url: action.page,
    }));
    const onActionClick = (action) =>
        onClick({
            title: action.name,
            url: action.url,
        });
    return <ItemActions actions={workflowActions} onAction={onActionClick} />;
};
WorkflowActions.displayName = "ItemViews.Workflow.Actions";

export /**
 * shows Workflow
 *
 * @param {{ actions: Array<WorkflowAction> }} { actions }
 */
const ItemView_Workflow = ({
    refreshId,
    onClick,
    itemUri,
    action,
    view,
}: {
    refreshId: number,
    onClick: (props: Object) => void,
    itemUri: string,
    action: ItemActionConfig,
    view: ItemActionViewConfig,
}) => {
    const state = useAsync(async () => {
        try {
            const info = get(
                action,
                ["commandAction", "props", "workflow"],
                null
            );
            const wfs = await fetchWorkflowInfo({
                provider: info && info.provider,
                wfId: info && info.wfid,
                itemUri,
            });
            return wfs;
        } catch (err) {
            console.warn("Error while fetchWorkflow", err);
            throw err;
        }
    }, [action, itemUri, refreshId]);

    if (state.loading || state.error) {
        return (
            <Divider
                key="itemView_wi"
                name="itemView_wi"
                hideHR
                className={styles.top}
                header={
                    <>
                        <div>
                            {state.loading && (
                                <Status
                                    status={Constants.LOADING}
                                    inline={true}
                                />
                            )}
                            {state.error && (
                                <Status
                                    status={Constants.ERROR}
                                    inline={true}
                                />
                            )}{" "}
                            {loc.t("ipwf:loading")}
                        </div>
                    </>
                }
            />
        );
    } else if (state.value == null || state.value === "") {
        return null;
    } else {
        return state.value.map((wf) => (
            <Divider
                key={`itemView_wi_${wf.id}`}
                name={`itemView_wi_${wf.id}`}
                hideHR
                className={styles.top}
                header={
                    <>
                        <div style={{ width: "100%" }} title={`ID: ${wf.id}`}>
                            {wf.name} ({wf.status})
                            {wf.progress != null && (
                                <CountBadge
                                    count={`${wf.progress}%`}
                                    className={styles.progressBadge}
                                />
                            )}
                        </div>
                    </>
                }
            >
                <>
                    {wf.attributes && (
                        <div className={styles.wrapper}>
                            <Attributes
                                attributes={wf.attributes}
                                useDefaultStyle={false}
                            />
                        </div>
                    )}
                    {wf.actions && (
                        <Divider
                            hideHR
                            name={`itemView_wa_${wf.id}`}
                            header={loc.t("ipwf:approver.actions.name")}
                            className={styles.actions}
                        >
                            <WorkflowActions
                                actions={wf.actions}
                                onClick={onClick}
                            />
                        </Divider>
                    )}
                </>
            </Divider>
        ));
    }
};

ItemView_Workflow.displayName = "ItemViews.Workflow";
// export default ItemView_Workflow;

export default compose(
    GlobalEventsHandler({
        items: [ServerEventItemType.task],
    })
)(ItemView_Workflow);
