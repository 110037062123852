// @flow
import React from "react";
import globalloc from "data/i18n.global";

const Loading = ({
    isLoading,
    pastDelay,
    error,
}: {
    isLoading: boolean,
    pastDelay: boolean,
    error: boolean,
}) => {
    if (isLoading) {
        // While our other component is loading...
        /*if (props.timedOut) {
            // In case we've timed out loading our other component.
            return <div>Loader timed out!</div>;
        } else*/ if (
            pastDelay
        ) {
            // Display a loading screen after a set delay.
            return <div>{globalloc.modules.loading}</div>;
        } else {
            // Don't flash "Loading..." when we don't need to.
            return null;
        }
    } else if (error) {
        console.error("Loader error", error);
        // If we aren't loading, maybe
        return <div>{globalloc.modules.error}</div>;
    } else {
        // This case shouldn't happen... but we'll return null anyways.
        return null;
    }
};

export default Loading;
