// @flow
import actionTypes from "./actionTypes";
import Constants, { type ConstantsEnum } from "data/constants";
import type {
    ApiErrorResult,
    Dictionary,
    UserInfoResultDTO,
    CardItemResultDTO,
    SAYTOptionDTO,
    DocListFilterRequestDTO,
    DocListFilterOperatorsEnum,
    BreadcrumbResultDTO,
    DocListSortRequestDTO,
    TreeDTO,
    DocsResultDTO,
    DocListLayout,
    SearchRequestDTO,
    SearchResultDTO,
    ListViewModeEnum,
    ToggleItemDTO,
    SearchFormResultDTO,
    SearchFormInfoDTO,
    SearchItemDTO,
    SearchCountResultDTO,
    SearchValueTypeEnum,
    OfflineSyncStatusEnum,
    OfflineItemDTO,
    NotificationDTO,
    NotificationId,
    NotificationStatusFlagEnum,
    GUID,
    CommandActionLog,
    CommandActionStatusFlagEnum,
} from "data/types";
import { OfflineSyncStatus } from "data/types";
import { type actionResult } from "./types";
import { docFilterText } from "data/reducers/types";

//#region Toggles
// called to set but not persist toggle
export const togglesChangeAction = (
    name: string,
    toggles: Array<ToggleItemDTO>
): actionResult => ({
    type: actionTypes.TOGGLES_CHANGE,
    payload: { name, toggles },
});

// called by containers/ToggleBarContainer to switch a toggle
export const persistTogglesAction = (
    name: string,
    toggles: Array<ToggleItemDTO>
): actionResult => ({
    type: actionTypes.TOGGLES_PERSIST,
    payload: { name, toggles },
});

// update toggle counters
// counts: { name: count, name: count, ... }
// status: { name: status, name: status, ... }
// use status=Constants.LOADING to show spinner
export const togglesUpdateCounterAction = ({
    name,
    counts,
    status,
}: {
    name: string,
    counts: Dictionary<string, number>,
    status?: Dictionary<string, ?string>,
}): actionResult => ({
    type: actionTypes.TOGGLES_UPDATECOUNTER,
    payload: { name, counts, status },
});
//#endregion

//#region USER

// called by containers/UserContainer to load current user's info
export const getUserAction = (status?: ConstantsEnum): actionResult => ({
    type: actionTypes.USER_FETCH_REQUESTED,
    payload: { status: status || Constants.LOADING },
});

// called by sagas/userSaga to load current user's info
export const getUserResultAction = ({
    status,
    message,
    info,
}: {
    status: ConstantsEnum,
    message?: string,
    info?: UserInfoResultDTO,
}): actionResult => ({
    type: actionTypes.USER_FETCH_RESULT,
    payload: { status, message, ...info },
    receivedAt: Date.now(),
});

export const setSitesAction = (sites: Array<SidebarItemDTO>) => ({
    type: actionTypes.USER_SITES_RESULT,
    payload: { sites },
});

export const setLoginResultAction = (payload: {
    idToken: string,
    accessToken: string,
    refreshToken?: string,
    expiresOn: number,
    expiresIn: number,
    status: ConstantsEnum,
    message?: string,
}): actionResult => ({
    type: actionTypes.LOGIN_RESULT,
    payload,
    receivedAt: Date.now(),
});

export const loginSignoutAction = (): actionResult => ({
    type: actionTypes.LOGIN_SIGNOUT,
    payload: {},
});
//#endregion

//#region GlobalSearch

export const getGlobalSearchDefaultAction = (): actionResult => ({
    type: actionTypes.GLOBALDEFAULTSEARCH_FETCH_REQUEST,
    payload: {},
});

export const globalSearchDefaultResultAction = ({
    status,
    message,
    options,
}: {
    status: ConstantsEnum,
    message?: string,
    options: Array<SAYTOptionDTO>,
}): actionResult => ({
    type: actionTypes.GLOBALDEFAULTSEARCH_FETCH_RESULT,
    payload: {
        status,
        message,
        options,
    },
    receivedAt: Date.now(),
});

//#endregion

//#region Favorites

export const getFavoritesAction = (status?: ConstantsEnum): actionResult => ({
    type: actionTypes.FAVORITES_FETCH_REQUEST,
    payload: { status: status || Constants.LOADING },
});

export const getFavoritesResultAction = ({
    status,
    message,
    favorites,
}: {
    status: ConstantsEnum,
    message?: string,
    favorites: Array<string>,
}): actionResult => ({
    type: actionTypes.FAVORITES_FETCH_RESULT,
    payload: {
        status,
        message,
        favorites,
    },
    receivedAt: Date.now(),
});

//#endregion

//#region Workflows

export const getWorkflowProviders = (): actionResult => ({
    type: actionTypes.WF_PROVIDERS_FETCH_REQUESTED,
    payload: { status: Constants.LOADING },
});

export const getWorkflowProvidersResult = ({
    status,
    providers,
    message,
}: {
    status: ConstantsEnum,
    providers: Array<WorkflowProviderDTO>,
    message?: string,
}): actionResult => ({
    type: actionTypes.WF_PROVIDERS_FETCH_RESULT,
    payload: { status, providers, message },
});

export const getWorkflowCards = (status?: ConstantsEnum): actionResult => ({
    type: actionTypes.WF_FETCH_REQUESTED,
    payload: { status: status || Constants.LOADING },
});

export const getWorkflowCardsResult = ({
    status,
    cards,
    message,
}: {
    status: ConstantsEnum,
    cards: Array<CardItemResultDTO>,
    message?: string,
}): actionResult => ({
    type: actionTypes.WF_FETCH_RESULT,
    payload: {
        status,
        message,
        cards,
    },
    receivedAt: Date.now(),
});
//#endregion

//#region Cards

// called by sagas/cardsSaga to load current user's home cards
export const getCardsAction = (status?: ConstantsEnum): actionResult => ({
    type: actionTypes.CARDS_FETCH_REQUESTED,
    payload: { status: status || Constants.LOADING },
});

export const getCardsByEntityAction = (
    cardTypes: Array<CardType>,
    cardItemTypes: Array<CardItemType>,
    status?: ConstantsEnum
): actionResult => ({
    type: actionTypes.CARDS_FETCH_ENTITY_REQUESTED,
    payload: { status: status || Constants.LOADING, cardTypes, cardItemTypes },
});

export const getCardsResultAction = ({
    status,
    cards,
    message, //optional error message
}: {
    status: ConstantsEnum,
    cards: Array<CardItemResultDTO>,
    message?: string,
}): actionResult => ({
    type: actionTypes.CARDS_FETCH_RESULT,
    payload: {
        status,
        message,
        cards,
    },
    receivedAt: Date.now(),
});

export const getCardsByEntityResultAction = ({
    status,
    cards,
    cardTypes,
    cardItemTypes,
}: {
    status: ConstantsEnum,
    cards: Array<CardItemResultDTO>,
    cardTypes: Array<CardTypeEnum>,
    cardItemTypes: Array<CardItemTypeEnum>,
}): actionResult => ({
    type: actionTypes.CARDS_FETCH_ENTITY_RESULT,
    payload: {
        status,
        cards,
        cardTypes,
        cardItemTypes,
    },
    receivedAt: Date.now(),
});

export const setTreeStatus = (
    status: ContantsEnum = Constants.LOADING
): actionResult => ({
    type: actionTypes.TREE_SET_STATUS,
    payload: { status },
});

export const getTreeAction = ({
    itemUri,
    filter,
    deep,
    all,
}: {
    itemUri: string,
    filter: ?string,
    deep: boolean,
    all: boolean,
}): actionResult => ({
    type: actionTypes.TREE_FETCH_REQUEST,
    payload: { itemUri, filter, deep, all, status: Constants.LOADING },
});

export const getTreeResultAction = ({
    status,
    tree,
    error,
}: {
    status: ConstantsEnum,
    tree: TreeDTO,
    error?: Error,
}): actionResult => ({
    type: actionTypes.TREE_FETCH_RESULT,
    payload: {
        status,
        error,
        nodes:
            tree && Object.prototype.hasOwnProperty.call(tree, "items")
                ? tree.items
                : [],
        isRoot:
            tree && Object.prototype.hasOwnProperty.call(tree, "isRoot")
                ? tree.isRoot
                : false,
        isBrowsable:
            tree && Object.prototype.hasOwnProperty.call(tree, "isBrowsable")
                ? tree.isBrowsable
                : true,
        totalCount: (tree && tree.totalCount) || 0,
    },
    receivedAt: Date.now(),
});

//#endregion

//#region Search

export const searchAbortAction = (): actionResult => ({
    type: actionTypes.SEARCH_ABORT,
});

export const searchResetAction = (): actionResult => ({
    type: actionTypes.SEARCH_RESET,
    payload: {},
});

export const getSearchFormListAction = (): actionResult => ({
    type: actionTypes.SEARCH_FORM_LIST_FETCH_REQUEST,
    payload: { status: Constants.LOADING },
});

export const getSearchFormListResultAction = ({
    status,
    result,
    message,
}: {
    status: ConstantsEnum,
    result: Array<SearchFormInfoDTO>,
    message?: string,
}): actionResult => ({
    type: actionTypes.SEARCH_FORM_LIST_FETCH_RESULT,
    payload: { status, result, message },
    receivedAt: Date.now(),
});

export const setSearchFormChangesAction = (changes: Object): actionResult => ({
    type: actionTypes.SEARCH_FORM_CHANGES,
    payload: { changes },
});

export const getSearchFormAction = ({
    id,
    model,
}: {
    id: number,
    model: Object,
}): actionResult => ({
    type: actionTypes.SEARCH_FORM_FETCH_REQUEST,
    payload: { id, model, status: Constants.LOADING },
});

export const getSearchFormResultAction = ({
    status,
    result,
    message,
}: {
    status: ConstantsEnum,
    result: SearchFormResultDTO,
    message?: string,
}): actionResult => ({
    type: actionTypes.SEARCH_FORM_FETCH_RESULT,
    payload: { status, result, message },
    receivedAt: Date.now(),
});

export const searchFormResetAction = (): actionResult => ({
    type: actionTypes.SEARCH_FORM_RESET,
});

export const searchFormValidationAction = (isValid): actionResult => ({
    type: actionTypes.SEARCH_FORM_VALIDATION,
    payload: { isValid },
});

export const setSearchItemAction = ({
    searchItem,
}: {
    searchItem: SearchItemDTO,
}): actionResult => ({
    type: actionTypes.SEARCH_ITEM_SAVE,
    payload: { searchItem },
});

export const getSearchAction = ({
    searchRequest,
}: {
    searchRequest: SearchRequestDTO,
}): actionResult => ({
    type: actionTypes.SEARCH_FETCH_REQUEST,
    payload: { searchRequest, status: Constants.LOADING },
});

export const getSearchResultAction = ({
    status,
    result,
    terms,
    error,
}: {
    status: ConstantsEnum,
    result: SearchResultDTO,
    terms: Array<string>,
    error?: ApiErrorResult,
}): actionResult => ({
    type: actionTypes.SEARCH_FETCH_RESULT,
    payload: { status, error, result, terms },
    receivedAt: Date.now(),
});

export const getSearchCountAction = ({
    searchRequest,
}: {
    searchRequest: SearchRequestDTO,
}): actionResult => ({
    type: actionTypes.SEARCH_COUNT_REQUEST,
    payload: { searchRequest, status: Constants.LOADING },
});

export const getSearchCountResultAction = ({
    status,
    result,
    message,
}: {
    status: ConstantsEnum,
    result: SearchCountResultDTO,
    message?: string,
}): actionResult => ({
    type: actionTypes.SEARCH_COUNT_RESULT,
    payload: { status, message, ...result },
    receivedAt: Date.now(),
});

export const renameSearchFavoriteAction = ({
    name,
}: {
    name: string,
}): actionResult => ({
    type: actionTypes.SEARCH_FAVRENAME_REQUEST,
    payload: { name },
});

export const renameSearchFavoriteResultAction = ({
    status,
    searchRequest,
    message,
}: {
    status: ConstantsEnum,
    searchRequest: SearchRequestDTO,
    message?: string,
}): actionResult => ({
    type: actionTypes.SEARCH_FAVRENAME_RESULT,
    payload: { status, message, searchRequest },
    receivedAt: Date.now(),
});

//#endregion

//#region Documents

export const docThumbnailRequestAction = (itemUri: string): actionResult => ({
    type: actionTypes.DOCS_THUMBNAIL_REQUEST,
    payload: { itemUri },
});

export const docThumbnailResultAction = ({
    itemUri,
    result,
    status,
    message,
}: {
    itemUri: string,
    result: string,
    status: ConstantsEnum,
    message?: string,
}): actionResult => ({
    type: actionTypes.DOCS_THUMBNAIL_RESULT,
    payload: { itemUri, result, status, message },
});

export const setDocListViewModeAction = (
    viewMode: ListViewModeEnum
): actionResult => ({
    type: actionTypes.SETTINGS_DOCLIST_VIEWMODE,
    payload: { viewMode },
});

export const exportDocsToCsvAction = (): actionResult => ({
    type: actionTypes.DOCS_EXPORT_CSV,
});

export const getDocsAction = ({
    itemUri,
    pageNum,
    sorts,
    filters,
}: {
    itemUri: string,
    pageNum?: number,
    sorts?: Array<DocListSortRequestDTO>,
    filters?: Array<DocListFilterRequestDTO>,
}): actionResult => ({
    type: actionTypes.DOCS_FETCH_REQUEST,
    payload: { itemUri, pageNum, filters, sorts, status: Constants.LOADING },
});

export const getDocsResultAction = ({
    status,
    result,
    error,
    totalCount,
}: {
    status: ConstantsEnum,
    result: DocsResultDTO,
    error?: Error,
    totalCount?: number,
}): actionResult => ({
    type: actionTypes.DOCS_FETCH_RESULT,
    payload: {
        status,
        error,
        result,
        totalCount,
    },
    receivedAt: Date.now(),
});

//#endregion

//#region Settings

export const setSettingAction = (
    name: string,
    value: Object
): actionResult => ({
    type: actionTypes.SETTINGS_SET,
    payload: { name, value },
});

export const setSplitPanesAction = ({
    view,
    name,
    dimensions,
}: {
    view: string,
    name: string,
    dimensions: Array<number>,
}): actionResult => ({
    type: actionTypes.SPLITPANES_SET,
    payload: { view, name, dimensions },
});

export const persistSplitPanesAction = ({
    view,
    name,
    dimensions,
}: {
    view: string,
    name: string,
    dimensions: Array<number>,
}): actionResult => ({
    type: actionTypes.SPLITPANES_PERSIST,
    payload: { view, name, dimensions },
});

export const setSidebarMini = (mini: boolean): actionResult => ({
    type: actionTypes.SETTINGS_SIDEBAR_MINI,
    payload: { mini },
});

export const setCurrentBreadcrumb = ({
    breadcrumb,
    isFav,
    viewName,
}: {
    breadcrumb: BreadcrumbResultDTO,
    isFav?: boolean,
    viewName: string,
}): actionResult => ({
    type: actionTypes.CURRENT_BREADCRUMB,
    payload: { isFav, breadcrumb, viewName },
});

export const setCurrentItemAction = (payload: Object): actionResult => ({
    type: actionTypes.CURRENTITEM_SET,
    payload,
});

export const backupCurrentItemAction = (): actionResult => ({
    type: actionTypes.CURRENTITEM_BAK,
});

export const restoreCurrentItemAction = (): actionResult => ({
    type: actionTypes.CURRENTITEM_RESTORE,
});

export const setDocsLayoutAction = (layout?: DocListLayout): actionResult => ({
    type: actionTypes.DOCS_LAYOUT_SET,
    payload: {
        status: Constants.OK,
        ...layout,
    },
});

export const requestDocsLayoutAction = (
    itemUri: string,
    allowPreFetch?: boolean
): actionResult => ({
    type: actionTypes.DOCS_LAYOUT_REQUEST,
    payload: { itemUri, allowPreFetch, status: Constants.LOADING },
});

export const setDocFilterTextAction = ({
    name,
    value,
    valueType,
    operator,
}: docFilterText): actionResult => ({
    type: actionTypes.DOCS_FILTERTEXT_SET,
    payload: { name, value, valueType, operator },
});

export const resetDocFilters = (): actionResult => ({
    type: actionTypes.DOCS_FILTERS_RESET,
    payload: {},
});

export const setDocFilterByRef = (
    docContextClick: {
        rowIndex: number,
        columnIndex: number,
    },
    resetValues: boolean
): actionResult => ({
    type: actionTypes.DOCS_FILTERS_SETBYREF,
    payload: { docContextClick, resetValues },
});

export const setDocFilterByName = ({
    name,
    valueType,
    value,
    operator,
}: {
    name: string,
    valueType: SearchValueTypeEnum,
    value: any,
    operator?: DocListFilterOperatorsEnum,
}): actionResult => ({
    type: actionTypes.DOCS_FILTERS_SETBYNAME,
    payload: { name, valueType, operator, value },
});

// persist=>TRUE save in long storage,
// parise=>FALSE save only in session cache
export const saveCurrentItemAction = ({
    name,
    key,
    value,
    persist,
}: {
    name: string,
    key: string,
    value: Object,
    persist: boolean,
}): actionResult => ({
    type: actionTypes.CURRENTITEM_SAVE,
    payload: { name, key, value, persist },
});

export const currentItemLoadedAction = (value?: Object): actionResult => ({
    type: actionTypes.CURRENTITEM_LOADED,
    payload: { status: Constants.OK, ...value },
});

//#endregion

//#region Forms

export const formSetDefaultsAction = (defaults: Object): actionResult => ({
    type: actionTypes.FORM_DEFAULTS_SET,
    payload: { defaults },
});

export const formSetEditModeAction = (
    name: string,
    isEditMode: boolean
): actionResult => ({
    type: actionTypes.FORM_EDITMODE_SET,
    payload: { name, isEditMode },
});

export const extraAttributesSetTabIndexAction = ({
    name,
    itemUri,
    tabIndex,
}: {
    name: string,
    itemUri: string,
    tabIndex: number,
}): actionResult => ({
    type: actionTypes.EA_TABINDEX_SET,
    payload: { name, itemUri, tabIndex },
});

export const extraAttributesClearAction = (): actionResult => ({
    type: actionTypes.EA_CLEAR,
    payload: {},
});

export const extraAttributesSetDetailViewAction = (
    values?: Object
): actionResult => ({
    type: actionTypes.EA_DETAILVIEW_SET,
    payload: values,
});

export const extraAttributesSetField = (
    ns: string,
    name: string,
    value: Object
): actionResult => ({
    type: actionTypes.EA_FIELD_SET,
    payload: { ns, name, value },
});

export const extraAttributesAuditLogUpdateRow = (
    name: string,
    seqId: string,
    values: Array<Object>
): actionResult => ({
    type: actionTypes.EA_AUDITLOG_UPDATE,
    payload: { name, seqId: String(seqId), values },
});

export const extraAttributesAuditLogDeleteRow = (
    name: string,
    seqId: string
): actionResult => ({
    type: actionTypes.EA_AUDITLOG_DEL,
    payload: { name, seqId: String(seqId) },
});

export const extraAttributesAuditLogClearRow = (
    name: string,
    seqId: string,
    values: Array<Object>
): actionResult => ({
    type: actionTypes.EA_AUDITLOG_CLEAR,
    payload: { name, seqId: String(seqId), values },
});

export const extraAttributesAuditLogInsertRow = (
    name: string,
    rowIndex: number,
    values: Array<Object>
): actionResult => ({
    type: actionTypes.EA_AUDITLOG_INSERT,
    payload: { name, rowIndex, values },
});

export const extraAttributesGridSetCurrentRow = (
    name: string,
    rowIndex: number,
    seqId: string
): actionResult => ({
    type: actionTypes.EA_GRID_CURRENTROW,
    payload: { name, rowIndex, seqId: String(seqId) },
});

//#endregion

//#region ServerSentEvents

export const serverEventAction = (evt: any): actionResult => ({
    type: actionTypes.GLOBALEVENTS_SERVER,
    payload: evt,
    receivedAt: Date.now(),
});

//#endregion

//#region Offline

export /* istanbul ignore next */ const startOfflineSyncAction = (
    items?: Array<OfflineItemDTO>
): actionResult => ({
    type: actionTypes.OFFLINE_SYNC_REQUEST,
    payload: { items },
    receivedAt: Date.now(),
});

export const offlineSyncStartedAction = (
    {
        status,
        message,
    }: {
        status?: OfflineSyncStatusEnum,
        message?: string,
    } = { status: OfflineSyncStatus.Syncing }
): actionResult => ({
    type: actionTypes.OFFLINE_SYNC_STARTED,
    payload: { status, message },
    receivedAt: Date.now(),
});

export const offlineSyncDoneAction = (
    {
        status,
        message,
    }: {
        status: OfflineSyncStatusEnum,
        message?: string,
    } = { status: OfflineSyncStatus.Synced }
): actionResult => ({
    type: actionTypes.OFFLINE_SYNC_DONE,
    payload: { status, message },
    receivedAt: Date.now(),
});

export /*istanbul ignore next */ const offlineSyncUpdateAction =
    (): actionResult => ({
        type: actionTypes.OFFLINE_SYNC_UPDATE,
    });

export const setOfflineItemSyncStatusAction = ({
    item,
    status,
    took,
    message,
}: {
    item: OfflineItemStatus,
    status: OfflineSyncStatusEnum,
    took: number,
    message?: string,
}): actionResult => ({
    type: actionTypes.OFFLINEITEM_SYNC_STATUS,
    payload: { status, item, took, message },
    receivedAt: Date.now(),
});

export const removeOfflineItemAction = (
    item: OfflineItemStatus
): actionResult => ({
    type: actionTypes.OFFLINEITEM_REMOVE,
    payload: { item },
    receivedAt: Date.now(),
});

export /* istanbul ignore next */ const getOfflineItemDepsMapAction = (
    item: OfflineItemStatus
): actionResult => ({
    type: actionTypes.OFFLINEITEM_DEPSMAP_REQUEST,
    payload: { item },
    receivedAt: Date.now(),
});

export const setOfflineItemDepsMapAction = (
    item: OfflineItemStatus,
    depsMap: Object
): actionResult => ({
    type: actionTypes.OFFLINEITEM_DEPSMAP_RESULT,
    payload: { item, depsMap },
    receivedAt: Date.now(),
});
//#endregion

//#region ServiceWorker
export const serviceWorkerInitializedAction = ({
    registration,
    error,
}: {
    registration?: ServiceWorkerRegistration,
    error?: Error,
}): actionResult => ({
    type: actionTypes.SW_INIT,
    payload: { registration, error },
    receivedAt: Date.now(),
});

export const serviceWorkerUpdatedAction = ({
    registration,
}: {
    registration: ServiceWorkerRegistration,
}): actionResult => ({
    type: actionTypes.SW_UPDATE,
    payload: { registration },
    receivedAt: Date.now(),
});
//#endregion

//#region Notification
export const notificationUpsertAction = (
    payload: NotificationDTO
): actionResult => ({
    type: actionTypes.NOTIFICATION_UPSERT,
    payload,
});

export const notificationDelAction = (
    notificationId: NotificationId
): actionResult => ({
    type: actionTypes.NOTIFICATION_DEL,
    payload: { notificationId },
});

export const notificationStatusAction = (
    status: NotificationStatusFlagEnum,
    notificationId: NotificationId,
    replace: boolean = false
): actionResult => ({
    type: actionTypes.NOTIFICATION_STATUS,
    payload: { notificationId, status, replace },
});

export const notificationClearAction = (
    payload: NotificationDTO
): actionResult => ({
    type: actionTypes.NOTIFICATION_CLEAR,
    payload,
});

export const notificationToastAction = (
    payload: NotificationDTO
): actionResult => ({
    type: actionTypes.NOTIFICATION_TOAST,
    payload,
});
//#endregion

//#region CommandAction
export const commandActionRequestAction = (
    payload: CommandActionRequestDTO
): actionResult => ({
    type: actionTypes.COMMANDACTION_REQUEST,
    payload: { guid: window.crypto.randomUUID(), ...payload },
});

export const commandActionQueueAddAction = (payload): actionResult => ({
    type: actionTypes.COMMANDACTION_QUEUE_ADD,
    payload,
});
export const commandActionQueueRemoveAction = (guid: GUID): actionResult => ({
    type: actionTypes.COMMANDACTION_QUEUE_REMOVE,
    payload: { guid },
});
export const commandActionQueuePauseAction = (): actionResult => ({
    type: actionTypes.COMMANDACTION_QUEUE_PAUSE,
});
export const commandActionQueueStartAction = (): actionResult => ({
    type: actionTypes.COMMANDACTION_QUEUE_START,
});

export const commandActionRemoveAction = (guid: GUID): actionResult => ({
    type: actionTypes.COMMANDACTION_REMOVE,
    payload: { guid },
});

export const commandActionAbortAction = (guid: GUID): actionResult => ({
    type: actionTypes.COMMANDACTION_ABORT,
    payload: { guid },
});

export const commandActionLogAction = (
    guid: GUID,
    log: CommandActionLog
): actionResult => ({
    type: actionTypes.COMMANDACTION_LOG,
    payload: { guid, log },
});

export const commandActionStatusAction = (
    guid: GUID,
    status: CommandActionStatusFlagEnum,
    replace: boolean = false
): actionResult => ({
    type: actionTypes.COMMANDACTION_STATUS,
    payload: { guid, status, replace },
});

export const commandActionItemStatusAction = (
    guid: GUID,
    itemUri: string,
    status: ConstantsEnum
): actionResult => ({
    type: actionTypes.COMMANDACTION_ITEM_STATUS,
    payload: { guid, itemUri, status },
});
//#endregion
