import { call, put, takeLatest, cancelled, select } from "redux-saga/effects";
import actionTypes from "data/actions/actionTypes";
import { getDocsResultAction } from "data/actions";
import { fetchDocs, fetchDocsCsv, fetchDocCount } from "data/api";
import Constants from "data/constants";
import FileSaver from "file-saver";
import * as s from "data/reducers/selectors";

// fetches batch of results
export function* getDocs(action) {
    // https://decembersoft.com/posts/redux-saga-abort-controller-cancel-api-calls/
    const abortController = new AbortController();

    // this delay in combination with takeLatest
    // is our debouncer to avoid multiple calls
    // yield call(delay, 300);
    try {
        const result = yield call(
            fetchDocs,
            action.payload.itemUri,
            action.payload.pageNum,
            action.payload.sorts,
            action.payload.filters,
            action.payload.cols,
            abortController.signal
        );

        yield put(
            getDocsResultAction({
                status: Constants.OK,
                result,
                totalCount: result.rowCount,
                error: null,
            })
        );

        // do we need to fetch the real totalCount?
        if (
            (action.payload.pageNum === 0 ||
                action.payload.pageNum === undefined) &&
            result.rowCount > 0 &&
            result.rowCount === result.pageSize
        ) {
            const totalCount = yield call(
                fetchDocCount,
                action.payload.itemUri,
                action.payload.filters,
                abortController.signal
            );
            yield put(
                getDocsResultAction({
                    status: Constants.OK,
                    result,
                    totalCount: totalCount >= 0 ? totalCount : result.rowCount, // Handle -1/-2 responses from server
                    error: null,
                })
            );
        }
    } catch (e) {
        yield put(
            getDocsResultAction({
                status: Constants.ERROR,
                error: e,
            })
        );
    } finally {
        if (yield cancelled()) {
            // console.log("getDocs cancelled");
            // Cancel the API call if the saga was cancelled
            abortController.abort();
        }
    }
}

function* exportToCsv() {
    const itemUri = yield select(s.currentItemUriSelector);
    const filters = yield select(s.docsFiltersSelector);
    const sorts = yield select(s.docsSortsSelector);

    const blob = yield call(fetchDocsCsv, itemUri, sorts, filters);
    FileSaver.saveAs(blob, "inPoint.Web.Export.csv");
}

function* docsSaga() {
    yield takeLatest(actionTypes.DOCS_FETCH_REQUEST, getDocs);
    yield takeLatest(actionTypes.DOCS_EXPORT_CSV, exportToCsv);
}

export default docsSaga;
