// @flow
import React, { PureComponent, type Node } from "react";
import { compose } from "redux";
import { connect, Dispatch } from "react-redux";
import loc from "i18next";
import noop from "lodash/noop";
import isEqual from "lodash/isEqual";
import LayoutLoader from "containers/LayoutLoader";
import {
    RefreshMode,
    type DocListFilterRequestDTO,
    type DocListSortRequestDTO,
    SortDirectionOptions,
    type DocListLayout,
    type ListViewModeEnum,
    ListViewMode,
    ServerEventItemType,
    type TrackCurrentRowProps,
} from "data/types";
import { type docsResultState } from "data/reducers/types";
import {
    getDocsAction,
    togglesUpdateCounterAction,
    setDocsLayoutAction,
} from "data/actions";
import DocListFilterBar from "components/DocListFilterBar";
import DocList from "components/DocList";
import DocListSimple from "components/DocListSimple";
import DocListFilterModal from "components/DocListFilterModal";
import {
    IsFiltered,
    GetDefaultFilters,
} from "components/DocListFilterModal/Utils";
import Constants, { type ConstantsEnum } from "data/constants";
import * as s from "data/reducers/selectors";
import GlobalEventsHandler from "containers/GlobalEventsHandler";
import UploadHandler from "containers/UploadHandler";
import Status from "components/Status";
import NoDocs from "components/NoDocs";
import withOfflineHandler from "containers/OfflineHandler";
import { type MultiSelectionProps } from "containers/MultiSelection";

type Props = {
    /** folder itemUri to show */
    itemUri: string,
    /** whether to popup filter modal */
    showFilter: boolean,
    /** whether to automatically load and show documents */
    showDocs: boolean,
    /** whether to show the AddDocument action when NoDocs found
     * @default {boolean} true
     */
    showAddDocument?: boolean,
    /** callback when user closes filter modal */
    onFilterClose: () => void,
    /** callback when user clicks document icon
     * @param {SyntheticMouseEvent} e HTML click event
     */
    onIconClick?: (e: SyntheticMouseEvent) => void,
    /** callback when user single clicks the whole row
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onSingleClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    /** callback when user double clicks the whole row
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onDoubleClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    /** callback when user clicks on More... button
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onMoreClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    /** whether doclist filters should be rendered */
    renderFilter: ?boolean,
    /** whether to disable the More... button */
    actionsDisabled?: boolean,
    /** callback when user right clicks the whole row
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {number} rowIndex the row's index
     * @param {number} columnsIndex the column's index
     * @param {string} itemUri the row's itemUri
     */
    onContextClick?: (
        e: SyntheticMouseEvent,
        rowIndex: number,
        columnIndex: number,
        itemUri: string
    ) => void,
    activeRowIndex?: number,
    scrollToRow?: number,
    /** React.Node children */
    children?: Node,

    // from redux connect()
    layout?: DocListLayout,
    /** current viewName */
    viewName: ?string,
    breadcrumbName: string,
    result?: docsResultState,
    status: ConstantsEnum,
    /** GlobalEventsHandler.refreshId */
    refreshId: number,
    /** Redux dispatch */
    dispatch: Dispatch,
    /** current docListViewMode */
    docListViewMode: ListViewModeEnum,
    /** redux: current formatId */
    formatId: number,
    /** TrackCurrentRow: tracks current selected document row */
    docTracker: ?TrackCurrentRowProps,
    /** withMultipleSelection additional props */
    multiSelection: MultiSelectionProps,
};

type State = {
    rowStartIndex: number, //current top row rendered
    scrollToRow: number,
    focusOnFilterName: ?string,
};

export class DocListContainer extends PureComponent<Props, State> {
    static defaultProps = {
        showFilter: false,
        showDocs: true,
        onFilterClose: undefined,
        status: Constants.LOADING,
        dispatch: noop,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            focusOnFilterName: null,
            rowStartIndex: 0,
            scrollToRow: props.scrollToRow || -1,
        };
    }

    componentDidMount() {
        /* istanbul ignore else */
        if (
            this.props.showDocs === true ||
            this.props.status === Constants.NONE
        ) {
            this._fetch(this.props.itemUri);
        }
    }

    componentDidUpdate(prevProps: Props) {
        // reload data?
        /* istanbul ignore else */
        if (this.props.showDocs === true && this.props.itemUri !== undefined) {
            const prevNotInitialFilters = prevProps.layout?.filters?.length;

            const isLayoutChanged =
                prevProps.layout?.filters &&
                this.props.layout?.filters &&
                !isEqual(prevProps.layout, this.props.layout);

            const notDefaultFilters =
                this.props.layout?.filters &&
                this.props.result?.cols &&
                !isEqual(
                    this.props.layout.filters,
                    GetDefaultFilters(this.props.result.cols)
                );

            /* istanbul ignore else */
            if (
                prevProps.showDocs !== this.props.showDocs ||
                prevProps.itemUri !== this.props.itemUri ||
                (isLayoutChanged &&
                    (prevNotInitialFilters || notDefaultFilters))
            ) {
                this.setState(
                    {
                        scrollToRow: this.props.activeRowIndex,
                        rowStartIndex: 0,
                    },
                    () => this._fetch(this.props.itemUri)
                );
            }

            /* istanbul ignore else */
            if (
                this.props.docTracker &&
                isLayoutChanged &&
                (prevNotInitialFilters || notDefaultFilters)
            ) {
                this.props.docTracker.setCurrentRow(-1);
                this.setState({ scrollToRow: -1 });
            }
        }

        if (prevProps.itemUri !== this.props.itemUri) {
            this.props.multiSelection?.disable();
        }

        // update totalCount
        if (
            prevProps.result !== this.props.result &&
            this.props.result != null &&
            this.props.viewName != null
        ) {
            /* istanbul ignore else */
            if (prevProps.result?.rows !== this.props.result.rows)
                this._updateToggleCounter(
                    this.props.result.totalCount,
                    Constants.OK
                );

            // Update multiSelection available Rows
            /* istanbul ignore next */
            this.props.multiSelection?.setAvailableRows(this.props.result.rows);

            this._updateArrowNavigationRows();
        }

        if (
            this.props.status === Constants.ERROR &&
            prevProps.status !== this.props.status &&
            this.props.viewName != null
        ) {
            this.props.dispatch(
                togglesUpdateCounterAction({
                    name: this.props.viewName,
                    counts: { doclist: 0 },
                    status: { doclist: Constants.ERROR },
                })
            );
        }

        // finished uploading? refresh
        /* istanbul ignore else */
        if (prevProps.refreshId !== this.props.refreshId) {
            this.props.docTracker.setCurrentRow(-1);
            this._fetch(this.props.itemUri);
            this.props.multiSelection?.disable();
        }
        /* istanbul ignore else */
        if (
            prevProps.docTracker?.currentRow !==
            this.props.docTracker?.currentRow
        ) {
            this.setState({
                scrollToRow: this.props.activeRowIndex,
            });
        }
    }

    _updateArrowNavigationRows() {
        const { postMessage, multiSelection, result } = this.props;
        // Update postMessage rows if applicable
        /* istanbul ignore next */
        if (result?.totalCount && postMessage?.setRows) {
            const documents = {};
            Object.keys(result.rows).forEach((i) => {
                const index = parseInt(i, 10);
                if (
                    !multiSelection?.multiple ||
                    multiSelection?.activeRows.hasOwnProperty(i)
                ) {
                    const row = result.rows[i];
                    documents[index] = row[row.length - 1];
                }
            });
            postMessage.setRows({
                ...documents,
                length: result.totalCount,
            });
        }
    }

    _updateToggleCounter(count: number, doclist?: ConstantsEnum): void {
        const { dispatch, viewName, layout } = this.props;
        let status = undefined;
        /* istanbul ignore else */
        if (layout && layout.filters && IsFiltered(layout.filters)) {
            status = {
                doclist: doclist !== Constants.LOADING ? "filter" : doclist,
            };
        } else if (doclist != null) {
            status = { doclist };
        }
        /* istanbul ignore else */
        if (viewName != null)
            dispatch(
                togglesUpdateCounterAction({
                    name: viewName,
                    counts: { doclist: count },
                    status,
                })
            );
    }

    // load next page of results
    _loadMoreRows = (pageNum: number) => {
        /* istanbul ignore else */
        if (this.props.itemUri !== undefined && pageNum > 0)
            this._fetch(this.props.itemUri, pageNum);
    };

    // load first page of documents
    _fetch(itemUri: string, pageNum: number = null) {
        const { dispatch, showDocs, layout } = this.props;
        if ((pageNum ?? 0) === 0 && showDocs)
            this._updateToggleCounter(0, Constants.LOADING);
        dispatch(
            getDocsAction({
                pageNum: showDocs ? pageNum ?? 0 : -1,
                itemUri,
                sorts: layout?.sorts,
                filters: layout?.filters,
            })
        );
    }

    _setSorting = (name: string) => {
        const { layout } = this.props;
        let direction = SortDirectionOptions.Ascending;
        if (layout?.sorts?.[0]?.name === name) {
            direction =
                layout.sorts[0].direction === SortDirectionOptions.Ascending
                    ? SortDirectionOptions.Descending
                    : SortDirectionOptions.Ascending;
        }

        const sorts: Array<DocListSortRequestDTO> = [
            {
                name,
                direction,
            },
        ];
        this.props.dispatch(setDocsLayoutAction({ sorts }));
    };

    _onFilter = (filters: Array<DocListFilterRequestDTO>) => {
        this._onFilterClose();
        this.props.dispatch(setDocsLayoutAction({ filters }));
    };

    _onFilterClose = () => {
        this.setState({ focusOnFilterName: null });
        /* istanbul ignore else */
        if (typeof this.props.onFilterClose === "function")
            this.props.onFilterClose();
    };

    /* istanbul ignore next */ _requestShowFilter = (name) =>
        this.setState({
            focusOnFilterName: name,
        });

    /* istanbul ignore next */ _rowStartIndexUpdated = (rowStartIndex) =>
        this.setState({ rowStartIndex });

    _onContextClick = (
        e,
        rowIndex: number,
        columnIndex: number,
        itemUri: string
    ) => this.props.onMoreClick(e, itemUri, rowIndex, columnIndex);

    _isRowActive = (rowIndex: number, activeState: boolean): boolean => {
        return this.props.multiSelection?.multiple
            ? this.props.multiSelection?.isSelected(rowIndex)
            : activeState;
    };

    render() {
        const {
            status,
            showDocs,
            showFilter,
            renderFilter,
            result,
            hasFilter,
            layout,
            breadcrumbName,
            docListViewMode,
        } = this.props;
        if (showDocs) {
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <DocListFilterBar
                        renderFilter={renderFilter}
                        disableFilter={
                            (!result || result.totalCount === 0) && !hasFilter
                        }
                        layout={layout}
                        setSorting={this._setSorting}
                        requestShowFilter={this._requestShowFilter}
                    />

                    {status === Constants.OK && result != null && (
                        <DocListFilterModal
                            show={
                                showFilter ||
                                this.state.focusOnFilterName !== null
                            }
                            focusOnFilterName={this.state.focusOnFilterName}
                            onClose={this._onFilterClose}
                            onFilter={this._onFilter}
                            result={result}
                            title={breadcrumbName}
                            filters={layout && layout.filters}
                            formatId={this.props.formatId}
                        />
                    )}
                    {status !== Constants.OK &&
                        (result == null || result.totalCount === 0) && (
                            <Status
                                status={status}
                                error={
                                    this.props.error ||
                                    this.props.offlineHandler.error
                                }
                            />
                        )}
                    {status === Constants.OK &&
                        result != null &&
                        result.totalCount === 0 && (
                            <NoDocs
                                layout={layout}
                                showAddDocument={this.props.showAddDocument}
                                requestShowFilter={this._requestShowFilter}
                            />
                        )}
                    {result != null && result.totalCount > 0 && (
                        <>
                            {(docListViewMode === ListViewMode.List ||
                                docListViewMode ===
                                    ListViewMode.Thumbnails) && (
                                <DocListSimple
                                    result={result}
                                    pageNum={this.props.pageNum}
                                    totalCount={result.totalCount}
                                    loadMoreRows={this._loadMoreRows}
                                    layout={layout}
                                    showThumbnails={
                                        docListViewMode ===
                                        ListViewMode.Thumbnails
                                    }
                                    scrollToRow={this.state.scrollToRow}
                                    activeRowIndex={this.props.activeRowIndex}
                                    onIconClick={this.props.onIconClick}
                                    onSingleClick={this.props.onSingleClick}
                                    onDoubleClick={this.props.onDoubleClick}
                                    onContextClick={this._onContextClick}
                                    onMoreClick={this.props.onMoreClick}
                                    requestShowFilter={this._requestShowFilter}
                                    rowStartIndexUpdated={
                                        this._rowStartIndexUpdated
                                    }
                                >
                                    {this.props.children}
                                </DocListSimple>
                            )}
                            {docListViewMode === ListViewMode.Grid && (
                                <DocList
                                    result={result}
                                    totalCount={result.totalCount}
                                    loadMoreRows={this._loadMoreRows}
                                    setSorting={this._setSorting}
                                    layout={layout}
                                    onIconClick={this.props.onIconClick}
                                    onSingleClick={this.props.onSingleClick}
                                    onDoubleClick={this.props.onDoubleClick}
                                    onContextClick={this._onContextClick}
                                    onMoreClick={this.props.onMoreClick}
                                    requestShowFilter={this._requestShowFilter}
                                    rowStartIndexUpdated={
                                        this._rowStartIndexUpdated
                                    }
                                    scrollToRow={this.state.scrollToRow}
                                    activeRowIndex={this.props.activeRowIndex}
                                    isRowActive={this._isRowActive}
                                >
                                    {this.props.children}
                                </DocList>
                            )}
                        </>
                    )}
                </div>
            );
        } else if (showFilter || this.state.focusOnFilterName != null) {
            if (
                status === Constants.OK &&
                result != null &&
                result.filters != null
            ) {
                return (
                    <DocListFilterModal
                        show={
                            showFilter || this.state.focusOnFilterName != null
                        }
                        focusOnFilterName={this.state.focusOnFilterName}
                        onClose={this._onFilterClose}
                        onFilter={this._onFilter}
                        result={result}
                        title={breadcrumbName}
                        filters={layout && layout.filters}
                        formatId={this.props.formatId}
                    />
                );
            } else return <Status status={Constants.LOADING} />;
        } else
            return (
                this.props.children || (
                    <Status
                        status={Constants.ERROR}
                        error={
                            this.props.error || this.props.offlineHandler.error
                        }
                    />
                )
            );
    }
}

const mapStateToProps = /* istanbul ignore next */ (state, ownProps) => ({
    viewName: s.currentNameSelector(state), // state.current.name,
    breadcrumbName: s.breadcrumbNameSelector(
        state,
        s.currentNameSelector(state)
    ),
    status: s.docsStatusSelector(state),
    error: s.docsErrorSelector(state),
    docListViewMode: s.docListViewModeSelector(state),
    formatId: s.breadcrumbFormatIdSelector(state, s.currentNameSelector(state)),
    result: state.docs,
    pageNum: s.docsPageNumSelector(state),
    hasFilter: (s.docsLayoutFiltersSelector(state) || []).filter(
        (filter) => filter.values.filter((v) => v).length
    ).length,
    ...ownProps,
});

export default compose(
    LayoutLoader,
    connect(mapStateToProps),
    GlobalEventsHandler({
        items: [ServerEventItemType.document, ServerEventItemType.activity],
        refreshMode: RefreshMode.Originator,
        refreshMessage: loc.t("globalEvents:refresh.doclist"),
    }),
    UploadHandler(),
    withOfflineHandler
)(DocListContainer);
