import { useState, useEffect, useRef } from "react";
import { useAsync } from "hooks";
import { isListed } from "data/utils";
import { fetchItemFlows } from "data/api";
import { type ItemFlowsConfigDTO } from "data/types";

type Props = {
    /** current folder's itemUri  */
    itemUri: string,
    /** optional target itemUri  */
    targetItemUri?: string,
    /** flow direction "in" vs "out" */
    direction?: ItemFlowDirectionEnum,
    /** optional flow.id to pre-select */
    flowId?: string,
    /** optional list of flow.ids to show */
    visibleFlowIds?: Array<string>,
    /** optional category.id to pre-select */
    categoryId?: string,
    /** possibility to opt out, defaults to true */
    canFetch?: boolean,
};

const useItemFlows = ({
    itemUri,
    targetItemUri,
    direction,
    flowId,
    visibleFlowIds,
    categoryId,
    canFetch = true,
}: Props) => {
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    // We use a ref, since might set the selectedFlow in the useAsync...
    const allowFlowIdFromProps = useRef(true);
    // get input item flows
    const state = useAsync(async () => {
        if (!canFetch) return null;
        if (targetItemUri == null && itemUri == null) return null;
        try {
            const cfg: ItemFlowsConfigDTO = await fetchItemFlows(
                targetItemUri || itemUri,
                direction
            );
            // filter requested visible flows
            // PS: yes I know we could filter them on the server-side, but it would not improve performance just reduce packet size
            if (visibleFlowIds) {
                cfg.flows = cfg.flows.filter((f) =>
                    isListed(f.id, visibleFlowIds)
                );
            }
            // pre-select a specific flow
            if (cfg.selectFlowId) {
                allowFlowIdFromProps.current = false;
                setSelectedFlow(
                    cfg.flows.find((f) => f.id === cfg.selectFlowId)
                );
            } else if (cfg.flows.length === 1) {
                allowFlowIdFromProps.current = false;
                //pre-select if only one flow
                setSelectedFlow(cfg.flows[0]);
            } else {
                allowFlowIdFromProps.current = true;
            }
            return cfg;
        } catch (err) {
            console.warn("Error while fetchItemFlows", err);
            throw err;
        }
    }, [targetItemUri, itemUri, direction, visibleFlowIds, canFetch]);

    useEffect(() => {
        if (
            state.loading ||
            state.error ||
            !state.value ||
            !flowId ||
            !allowFlowIdFromProps.current
        )
            return;
        setSelectedFlow(state.value.flows.find((f) => f.id === flowId));
    }, [state.loading, state.error, state.value, flowId]);

    useEffect(() => {
        if (state.loading || state.error || !state.value || !categoryId) return;
        setSelectedCategory(
            state.value.categories.find((f) => f.id === categoryId)
        );
    }, [state.loading, state.error, state.value, categoryId]);

    return [
        selectedFlow,
        selectedCategory,
        state,
        setSelectedFlow,
        setSelectedCategory,
    ];
};

export { useItemFlows };
