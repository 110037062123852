import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";

const initialState = {
    nodes: [],
    status: Constants.NONE,
    error: undefined,
    isRoot: undefined,
    isBrowsable: undefined,
    totalCount: 0,
};

const treeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TREE_SET_STATUS:
            return {
                ...state,
                status: action.payload.status,
            };
        case actionTypes.TREE_FETCH_REQUEST:
            return {
                ...state,
                itemUri: action.payload.itemUri,
                status: action.payload.status,
                // ...action.payload,
            };
        case actionTypes.TREE_FETCH_RESULT:
            return { ...state, ...action.payload };

        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default treeReducer;

// ------ selectors

export const treeNodesSelector = (state: Object) => state.tree.nodes;
export const treeStatusSelector = (state: Object) => state.tree.status;
export const treeErrorSelector = (state: Object) => state.tree.error;
export const treeIsRootSelector = (state: Object) => !!state.tree.isRoot;
export const treeIsBrowsableSelector = (state: Object) =>
    state.tree.isBrowsable;
export const treeTotalCountSelector = (state: Object) => state.tree.totalCount;
