import { compose, createStore, applyMiddleware } from "redux";
import appReducers from "data/reducers";
// redux-persist
import { persistStore, persistReducer } from "redux-persist";
import {
    // transformCorrelationId,
    transformInitialState,
} from "data/persistTransforms";
import { LocalNodeDevelopment } from "./config";
// responsive
import { responsiveStoreEnhancer } from "redux-responsive";
// sagas
import createSagaMiddleware from "redux-saga";
//import { version } from "../../package.json";
import { persistStorage } from "data/storage";
import { initialState as loginInitialState } from "data/reducers/loginReducer";
// import { initialState as userInitialState } from "data/reducers/userReducer";
import { setupLogging } from "data/logging";

export const injectConfigFromServer = async () => {
    let config;
    try {
        const url = `${window.CONFIG.host.basename}/config`;
        const result = await fetch(url);
        config = await result.json();
    } catch (e) {
        console.error(
            `Cannot load appsettings from inPoint.Web.Server. Please check if the Server is running and the proxy is configured`,
            e
        );
    }
    return config || LocalNodeDevelopment;
};

/* istanbul ignore next */
if (process.env.NODE_ENV !== "production") {
    // Log the initial state
    //console.log(store.getState())
    // Every time the state changes, log it
    // Note that subscribe() returns a function for unregistering the listener//let unsubscribe =
    /*  store.subscribe(() => {
        console.log("%c store", "color: orange", store.getState());
    }); */
    // this sets window.CONFIG either locally (node.js server) or when hosted, from MVC

    /* istanbul ignore next */
    if (typeof window.CONFIG === "string") {
        window.CONFIG = LocalNodeDevelopment;
        injectConfigFromServer().then((config) => (window.CONFIG = config));
    }
}

/* istanbul ignore next */
if (process.env.JEST_WORKER_ID === undefined) {
    setupLogging();
}

// create the saga middleware
export const sagaMiddleware = createSagaMiddleware();

// redux-persist
const transforms = [
    /*transformCorrelationId()*/
];
/* istanbul ignore next */
if (window.CONFIG.auth.forceLogin === true || window.CONFIG.token != null) {
    transforms.push(transformInitialState("login", loginInitialState));
    // transforms.push(transformInitialState("user", userInitialState));
}

const persistConfig = {
    key: "root",
    version: 3,
    whitelist: [
        "settings",
        /*"user",*/ "login",
        "cards",
        "offline",
        "notification",
    ],
    storage: persistStorage, //localForage,
    debug: process.env.NODE_ENV !== "production",
    autoRehydrate: true,
    transforms,
};
const reducer = persistReducer(persistConfig, appReducers);

// simple Redux console logger
// https://twitter.com/dan_abramov/status/629347448247468032?lang=en
/* function logger({ getState }) {
    return next => action => {
        //console.time();
        console.groupCollapsed(action.type);
        console.info("dispatching:", action);
        const result = next(action);
        console.log("next state:", getState());
        console.groupEnd();
        //console.timeEnd();
        return result;
    };
} */

// http://redux.js.org/
const middlewares = [sagaMiddleware];
/* if (process.env.NODE_ENV !== "production") {
    middlewares.push(logger);
} */

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducer,
    composeEnhancers(responsiveStoreEnhancer, applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);

export default store;
