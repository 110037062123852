import React from "react";
import {
    type SummaryAttributeValue,
    SummaryAttributeValueType,
} from "data/types";
import styles from "./SummaryAttribute.module.css";

import ValueFacePile from "./ValueFacePile";
import ValuePrincipal from "./ValuePrincipal";
import ValueProgress from "./ValueProgress";
import ValueVisualRules from "./ValueVisualRules";
import ValueEnum from "./ValueEnum";
import ValueTextLink from "./ValueTextLink";
import ValueFilesize from "./ValueFilesize";
import ValueDatetime from "./ValueDatetime";
import { SimpleAttributeValue } from "../SimpleAttribute";

const SummaryAttributeValueFactory = (props: {
    value: SummaryAttributeValue,
    className: boolean,
}) => {
    switch (props.value.$type) {
        case SummaryAttributeValueType.FacePile:
            return <ValueFacePile {...props} />;

        case SummaryAttributeValueType.VisualRules:
            return <ValueVisualRules {...props} />;

        case SummaryAttributeValueType.Enum:
            return <ValueEnum {...props} />;

        case SummaryAttributeValueType.TextLink:
            return <ValueTextLink {...props} />;

        case SummaryAttributeValueType.Filesize:
            return <ValueFilesize {...props} />;

        case SummaryAttributeValueType.User:
        case SummaryAttributeValueType.Group:
            return <ValuePrincipal {...props} />;

        case SummaryAttributeValueType.Progress:
            return <ValueProgress {...props} />;

        case SummaryAttributeValueType.Datetime:
            return <ValueDatetime {...props} />;

        // for internal use - will never be passed from the Server
        case SummaryAttributeValueType.ReactElement:
            return props.value.element;

        default:
            return <SimpleAttributeValue {...props} />;
    }
};
SummaryAttributeValueFactory.displayName = "SummaryAttribute.ValueFactory";
export default SummaryAttributeValueFactory;
