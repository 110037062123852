// @flow
import React, { PureComponent, type ElementRef } from "react";
import styles from "components/NativeSelect/NativeSelect.module.css";
import { components } from "react-select";
const { DownChevron } = components;

type Props = {
    /** control id */
    id?: string,
    /** current selected value */
    value?: ?Object | ?Array<Object>,
    /** defaultValue for initial rendering */
    defaultValue?: Object | ?Array<Object>,
    /** placeholder text to show when nothing is rendered */
    placeholder?: string,
    /** callback when user selects a value
     * @param {Object} option new option selected
     */
    onChange?: (option: Object) => void,
    /** React.createRef reference */
    selectRef?: ElementRef<*>,
    /** CSS Classname to apply to wrapper */
    wrapperClassName?: string,
    /** CSS Classname to apply */
    className?: string,
    /** @see options property name used to show to the user */
    labelKey?: string,
    /** @see options property name used to store value reference */
    valueKey?: string,
    /** list of options to display */
    options?: Array<Object>,
    /** whether the SELECT tag should be disabled */
    disabled?: boolean,
    /** whether to allow multiple selection */
    isMultiple?: boolean,
};

type State = {
    selected: ?Object | ?Array<Object>,
};

// based on: https://github.com/JaniAnttonen/react-select-native/blob/master/src/index.js
export default class NativeSelect extends PureComponent<Props, State> {
    static defaultProps = {
        labelKey: "label",
        valueKey: "value",
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            selected: props.value || props.defaultValue,
        };
    }

    _onChange = (e: SyntheticInputEvent<*>) => {
        e.preventDefault();
        let selected = null;
        if (this.props.isMultiple) {
            selected = [...e.currentTarget.options]
                .filter((so) => so.selected && so.value.length > 0)
                .map(
                    (so) =>
                        this.props.options &&
                        this.props.options.find(
                            (o) => o[this.props.valueKey] === so.value
                        )
                );
        } else {
            const value = e.currentTarget.value;
            selected =
                this.props.options &&
                this.props.options.find(
                    (o) => String(o[this.props.valueKey]) === value
                );
        }
        /* istanbul ignore else */
        if (selected != null) {
            /* istanbul ignore else */
            if (typeof this.props.onChange === "function")
                this.props.onChange(selected);
            this.setState({
                selected,
            });
        }
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        /* istanbul ignore else */
        if (prevProps.value !== this.props.value) {
            this.setState({
                selected: this.props.value,
            });
        }
    }

    _getValue = () => {
        if (this.state.selected == null) return undefined;
        if (this.props.isMultiple) {
            return this.state.selected.map((o) => o[this.props.valueKey]);
        } else {
            return this.state.selected[this.props.valueKey];
        }
    };

    render = () => (
        <div
            className={`${styles.wrapper} ${this.props.wrapperClassName || ""}`}
        >
            <select
                disabled={this.props.disabled}
                className={this.props.className}
                id={this.props.id}
                ref={this.props.selectRef}
                value={this._getValue()}
                onChange={this._onChange}
                multiple={this.props.isMultiple}
            >
                {this.props.placeholder && (
                    <option value={""}>{this.props.placeholder}</option>
                )}
                {this.props.options &&
                    this.props.options.map((option, index) => (
                        <option
                            key={`o-${index}`}
                            value={option[this.props.valueKey]}
                        >
                            {option[this.props.labelKey]}
                        </option>
                    ))}
            </select>
            <DownChevron className={styles.icon} />
        </div>
    );
}
