// @flow
import { BasePlugin } from "@uppy/core";
//import { type uppyFile } from "data/uppy";
import isEmpty from "lodash/isEmpty";
import parser from "exif-parser";

type uppyFileMetadata = {
    type: string,
};

export type uppyFile = {
    id: string, // 'uppyteamkongjpg1501851828779'
    name: string, // 'nature.jpg'
    extension: string, // '.jpg'
    type: string, // 'image/jpeg'
    data: Object, // the Blob object
    size: number | string, // 3947642 (returns 'N/A' if size cannot be determined)
    preview: string, // value that can be used to populate "src" attribute of an "img" tag,
    meta: uppyFileMetadata,
};

/* istanbul ignore next */
export default class UppyExif extends BasePlugin {
    constructor(uppy: Object, opts: Object) {
        super(uppy, opts);
        this.uppy = uppy;
        this.type = "exif";
        this.id = "Exif";
        this.title = "uppy-exif";

        // set default options
        const defaultOptions = {
            prefix: "exif_",
            tags: [],
        };

        // merge default options with the ones set by user
        this.opts = Object.assign({}, defaultOptions, opts);
    }

    install() {
        this.uppy.log("[Exif] Installing UppyExif Plugin");
        this.uppy.on("file-added", this.extractExif);
    }

    uninstall() {
        this.uppy.log("[Exif] Removing UppyExif Plugin");
        this.uppy.off("file-added", this.extractExif);
    }

    extractExif = (file: uppyFile) => {
        // NOTE: EXIF only supported on jpeg files!
        if (file.meta.type !== "image/jpeg") {
            this.uppy.log(
                `[Exif] mime type: ${file.meta.type} does not support EXIF tags`
            );
            return;
        }

        this.uppy.log(`[Exif] Extracting tags from ${file.name}`);
        const reader = new FileReader();
        reader.addEventListener("loadend", () => {
            // DONE?
            /* istanbul ignore else */
            if (reader.readyState === 2) {
                try {
                    let buffer = parser.create(reader.result);
                    // get Exif Tags
                    const result = buffer.parse();
                    const metadata = {};
                    // any requested tags?
                    if (this.opts.tags.length === 0) {
                        // no, return them all
                        Object.keys(result.tags).forEach((tagName) => {
                            metadata[this.opts.prefix + tagName] =
                                result.tags[tagName];
                        });
                    } else {
                        // yes, return only those
                        this.opts.tags.forEach((tagName) => {
                            const tag = result.tags[tagName];
                            /* istanbul ignore else */
                            if (tag != null) {
                                metadata[this.opts.prefix + tagName] = tag;
                            }
                        });
                    }
                    // any tags found?
                    if (!isEmpty(metadata)) {
                        this.uppy.setFileMeta(file.id, metadata);
                        this.uppy.log(
                            `[Exif] Found tags for ${file.name} and added to metadata with prefix: ${this.opts.prefix}`
                        );
                    } else {
                        this.uppy.log(
                            `[Exif] None of the requested tags found for ${file.name}`
                        );
                    }
                } catch (err) {
                    // console.log(err);
                    // got invalid data, handle error
                    this.uppy.log(
                        `[Exif] Cannot analyse tags for ${file.name}`,
                        err.message
                    );
                }
            } else {
                this.uppy.log(
                    `[Exif] Cannot load file ${file.name}: ${reader.error.message}`
                );
            }
        });

        // convert from Blob to ArrayBuffer
        reader.readAsArrayBuffer(file.data);
    };
}
