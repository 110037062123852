// @flow
import React, { PureComponent, type Node } from "react";
import {
    GridListConstant,
    SortDirectionOptions,
    type DocListLayout,
} from "data/types";
import { type docsResultState } from "data/reducers/types";
import Constants, { type ConstantsEnum } from "data/constants";
import GridList from "@hs/grid-list";
import ContextMenuHandler from "components/ContextMenu/Handler";
import CellContent from "./CellContent";
import { isHandheld } from "data/bowser";
import isEqual from "lodash/isEqual";

type Props = {
    /** doclist fetch result */
    result: docsResultState,
    /** current page number */
    pageNum: number,
    /** total number of documents */
    totalCount: number,
    /** callback to request loading next page */
    loadMoreRows: ({ rowIndex: number }) => Promise,
    /** callback to set sorting on a specific column
     * @param {string} colName name of column to sort
     */
    setSorting: (colName: string) => void,
    /** docList layout settings */
    layout?: DocListLayout,
    /** callback to set filtering on a specific column
     * @param {string} colName name of column to filter
     */
    requestShowFilter: (colName: string) => void,
    /** which row index is currently selected */
    activeRowIndex?: ?number,
    /** which row index to bring into view */
    scrollToRow?: ?number,
    /** which row is the UI currently updating
     * @param {number} rowStartIndex row index in result.rows
     */
    rowStartIndexUpdated: (rowStartIndex: number) => void,
    /** callback when user clicks document icon
     * @param {SyntheticMouseEvent} e HTML click event
     */
    onIconClick?: (e: SyntheticMouseEvent<*>) => void,
    /** callback when user single clicks the whole row
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onSingleClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    /** callback when user double clicks the whole row
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onDoubleClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    /** callback when user clicks on More... button
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onMoreClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    /** whether to disable the More... button */
    actionsDisabled?: boolean,
    /** callback when user right clicks the whole row
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {number} rowIndex the row's index
     * @param {number} columnsIndex the column's index
     * @param {string} itemUri the row's itemUri
     */
    onContextClick?: (
        e: SyntheticMouseEvent,
        rowIndex: number,
        columnIndex: number,
        itemUri: string
    ) => void,
    /** React.Node children */
    children?: Node,
};

type State = {
    rows: Object,
    pageSize: number,
    pagesStatus: Array<ConstantsEnum>,
};

/**
 * Renders the Grid documents list
 */
export default class DocList extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            //first page results loaded by DocListContainer
            rows: props.result.rows,
            pageSize: props.result.pageSize,
            //first page already pre-loaded otherwise we wouldn't be here
            pagesStatus: [Constants.OK],
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (!isEqual(this.props.result.rows, this.state.rows)) {
            this.setState(({ pagesStatus }) => ({
                rows: this.props.result.rows,
                pagesStatus: pagesStatus.map((p, i) =>
                    p === Constants.LOADING
                        ? i === this.props.pageNum
                            ? Constants.OK
                            : null
                        : p
                ),
            }));
        }
    }

    isColumnFiltered = (name: string): boolean => {
        const { layout } = this.props;
        if (layout?.filters == null) return false;
        const filter = layout.filters.find((f) => f.name === name);
        /* istanbul ignore next */
        if (filter == null) return false;
        return (
            filter?.values?.length > 0 && String(filter.values[0]).length > 0
        );
    };

    canColumnFilter = (name: string): boolean => {
        const { layout } = this.props;
        if (!layout?.filters?.length) return true;
        return layout.filters.findIndex((f) => f.name === name) !== -1;
    };

    isColumnSorted = (name: string): boolean => {
        if (this.props.layout == null) {
            return false;
        }
        const { sorts } = this.props.layout;
        return sorts?.some((s) => s.name === name);
    };

    getColumnSortDirection = (name: string): SortDirectionEnum => {
        if (this.props.layout == null) {
            return SortDirectionOptions.Ascending;
        }
        const { sorts } = this.props.layout;
        const sort = sorts?.find((s) => s.name === name);
        return sort ? sort.direction : SortDirectionOptions.Ascending;
    };

    // #36000 single-click behavior
    handleCellClick = (
        e: SyntheticMouseEvent<*>,
        rowIndex: number,
        columnIndex: number,
        itemUri: string
    ): void => {
        if (columnIndex === 0) {
            /* istanbul ignore else */
            if (typeof this.props.onIconClick === "function")
                this.props.onIconClick(e, itemUri, rowIndex);
        } else {
            /* istanbul ignore else */
            if (typeof this.props.onSingleClick === "function")
                this.props.onSingleClick(e, itemUri, rowIndex);
        }
    };

    parseCellColumnTitle = (caption: string, content: any): string => {
        if (caption === GridListConstant.ItemUri) {
            return undefined;
        } else if (content != null && typeof content !== "string") {
            return content.value;
        }
        return content;
    };

    handleHeaderFilterClick = (
        e: SyntheticMouseEvent<*>,
        name: string
    ): void => {
        e.stopPropagation();
        this.props.requestShowFilter(name);
    };

    handleHeaderClick = (columnIndex: number, header: string): void => {
        const col = this.props.result.cols[columnIndex];
        if (col.canSort) this.props.setSorting(col.name);
    };

    parseCellHeaderCaption = (rawData: string): string => {
        if (
            rawData === GridListConstant.ExtensionIcon ||
            rawData === GridListConstant.IconItemStatus ||
            rawData === GridListConstant.ItemUri
        ) {
            return "";
        }
        return rawData;
    };

    getColumnWidth = ({
        index,
        col,
    }: {
        index: number,
        col: DocColumnResultDTO,
    }): number => {
        const header = col.caption;
        let width = 0;
        if (header === GridListConstant.ExtensionIcon) {
            width += isHandheld() ? 35 : 42;
        } else if (header === GridListConstant.IconItemStatus) {
            /* istanbul ignore next */
            width += col.width > 0 ? col.width : 20;
        } else {
            width += col.width * 1.2 + 15;
        }
        if (index === this.props.result.cols.length - 1) {
            width += 20;
        }
        return width;
    };

    isRowLoaded = ({ rowIndex }: { rowIndex: number }): boolean =>
        Object.prototype.hasOwnProperty.call(this.state.rows, rowIndex);

    loadMoreRows = ({ rowIndex }: { rowIndex: number }) => {
        const { pageSize, pagesStatus } = this.state;

        // calc page number
        let pageNum = 0;
        /* istanbul ignore else */
        if (rowIndex > 0) {
            // specific row index requested? calc page number
            pageNum = Math.floor(rowIndex / pageSize);
        }

        // page already loaded/requested?
        /* istanbul ignore else */
        if (pagesStatus[pageNum] != null) return null;

        // fetch
        pagesStatus[pageNum] = Constants.LOADING;
        this.props.loadMoreRows(pageNum);
    };

    sectionRendered = (rowStartIndex: number) =>
        this.props.rowStartIndexUpdated(rowStartIndex);

    render() {
        const count =
            this.props.totalCount === 0
                ? this.props.result.rowCount
                : this.props.totalCount;
        if (this.props.result.cols.length === 0) {
            return null;
        }
        return (
            <GridList
                cols={this.props.result.cols}
                rows={this.state.rows}
                columnCount={this.props.result.cols.length - 1}
                rowCount={count}
                getColumnWidth={this.getColumnWidth}
                CellContent={CellContent}
                ContextMenuHandler={ContextMenuHandler}
                parseCellHeaderCaption={this.parseCellHeaderCaption}
                parseCellColumnTitle={this.parseCellColumnTitle}
                isRowLoaded={this.isRowLoaded}
                canColumnFilter={this.canColumnFilter}
                isColumnFiltered={this.isColumnFiltered}
                isColumnSorted={this.isColumnSorted}
                getColumnSortDirection={this.getColumnSortDirection}
                rowStartIndexUpdated={this.sectionRendered}
                loadMoreRows={this.loadMoreRows}
                onCellClick={this.handleCellClick}
                onCellDoubleClick={this.props.onDoubleClick}
                onCellMoreClick={this.props.onMoreClick}
                onContextClick={this.props.onContextClick}
                onHeaderClick={this.handleHeaderClick}
                onHeaderFilterClick={this.handleHeaderFilterClick}
                activeRowIndex={this.props.activeRowIndex}
                scrollToRow={this.props.scrollToRow}
                children={this.props.children}
                actionsDisabled={this.props.actionsDisabled}
                filterText={this.props.result.filterText}
                isRowActive={this.props.isRowActive}
            />
        );
    }
}
