// @flow
import React, { useRef, useEffect } from "react";
import {
    type SiteTypeEnum,
    SiteType,
    CardType,
    type CardTypeEnum,
    CardItemType,
    type CardItemTypeEnum,
} from "data/types";
import CountBadge from "components/CountBadge";
import Icons from "@hs/icons";
import Mark from "@hs/mark";
import ActionsButton from "components/ActionPanel/ActionsButton";
import { getClassNames } from "data/utils";
import { removeSpaces } from "data/integrationTestUtils";
// import loc from "i18next";

/**
 * renders a CardItem's subtype's icon
 */
const CardSubTypeIcon = ({
    cardtype,
    itemtype,
    name,
    style,
}: {
    cardtype: CardTypeEnum,
    itemtype: CardItemTypeEnum,
    name: string,
    style?: CSSStyleDefinition,
}) => {
    switch (itemtype) {
        case CardItemType.Task:
            return (
                <Icons.Library
                    name="fa-regular fa-square-check"
                    className="image-bg"
                    size="5x"
                />
            );

        case CardItemType.Folder:
            return (
                <Icons.Library
                    name="fa-regular fa-folder-open"
                    className={style == null ? "image-bg fa-5x" : undefined}
                    style={style}
                />
            );
        case CardItemType.Doc:
            const ext = name.substring(name.lastIndexOf("."));
            return (
                <Icons.FileExt
                    ext={ext}
                    style={
                        style || { height: "100%", width: "100%", padding: 25 }
                    }
                />
            );

        case CardItemType.ArchiveTarget:
            switch (cardtype) {
                case CardType.Rec:
                    return (
                        <Icons.Library
                            name="fa-regular fa-clock-rotate-left"
                            className="image-bg"
                            size="5x"
                        />
                    );
                case CardType.Fav:
                    return (
                        <Icons.Library
                            name="fa-regular fa-star"
                            className="image-bg"
                            size="5x"
                        />
                    );
                default:
                    return (
                        <Icons.Library
                            name="upload"
                            className="image-bg"
                            size="5x"
                        />
                    );
            }

        case CardItemType.Search:
        case CardItemType.SearchForm:
            return (
                <Icons.Library
                    name="magnifying-glass"
                    className="image-bg"
                    size="5x"
                />
            );

        case CardItemType.CommandAction:
            return cardtype === CardType.Task ? (
                <Icons.Library
                    name="fa-regular fa-square-check"
                    className="image-bg"
                    size="5x"
                />
            ) : (
                <Icons.Library
                    name="puzzle-piece"
                    className="image-bg"
                    size="5x"
                />
            );

        /* istanbul ignore next */
        default:
            console.log("Unknown CardItem.ItemType=" + itemtype);
            return (
                <Icons.Library
                    name="fa-regular fa-file"
                    className="image-bg"
                    size="5x"
                />
            );
    }
};

/**
 * returns the document's name without extension
 *
 * @param {CardItemTypeEnum} itemType doc if document
 * @param {string} name full filename
 * @returns {string} filename without extension
 */
function onlyName(
    cardType: CardTypeEnum,
    itemType: CardItemTypeEnum,
    name: string
): string {
    if (cardType !== CardType.Task && itemType === CardItemType.Doc) {
        return name.substring(0, name.lastIndexOf(".")) || name;
    }
    return name;
}

// const typeIcon = {
//     [CardType.Fav]: "star",
//     [CardType.Rec]: "history",
//     [CardType.Task]: "tasks"
//     // [CardType.Action]: "puzzle-piece"
// };

function cardColor(
    type: CardTypeEnum,
    itemtype: CardItemTypeEnum,
    siteType: SiteTypeEnum
): string {
    if (type === CardType.Task) {
        return "task";
    }
    if (itemtype === CardItemType.Folder && siteType === SiteType.GlobalSites) {
        return "top";
    }
    return type;
}

type Props = {
    /** the card item's type */
    itemtype: CardItemTypeEnum,
    /** the card site type */
    siteType: SiteTypeEnum,
    /** optional icon */
    icon?: string,
    /** optional color */
    color?: string,
    /** card type */
    type: CardTypeEnum,
    /** image in base64 encoding */
    image?: ?string | ?React.ReactElement,
    /** card name */
    name?: string,
    /** card's description */
    desc?: string,
    /** breadcrumb text */
    bcText?: string,
    /** whether should be visible */
    visible: boolean,
    /** callback when user clicks on a card
     * @param {SyntheticMouseEvent<*>} e HTML click event object
     */
    onClick: (e: SyntheticMouseEvent<*>) => void,
    /** optional callback when user clicks on the three dots more
     * @param {SyntheticMouseEvent<*>} e HTML click event object
     */
    onMoreClick?: (e: SyntheticMouseEvent<*>) => void,
    /** current filter text (empty string if none) */
    filterText: string,
    /** search statistics */
    count?: number,
    /** whether to mark Card as selected */
    isSelected?: boolean,
    extras?: Object,
    /** optional string to help test engines target this component */
    dataTest?: string,
};

/**
 * renders one Card
 */
const CardItem = ({
    itemtype,
    siteType,
    icon,
    color,
    bcText,
    type,
    image,
    name,
    desc,
    visible,
    extras,
    onClick,
    onMoreClick,
    filterText,
    count,
    isSelected,
    dataTest,
}: Props) => {
    const liRef = useRef(null);
    useEffect(() => {
        if (isSelected === true) {
            window.setTimeout(() => {
                liRef.current != null &&
                    liRef.current.scrollIntoView(/*{ behavior: "smooth" }*/);
            }, 100);
        }
    }, [isSelected]);

    const className = ["card", cardColor(type, itemtype, siteType)];
    /* istanbul ignore else */
    if (isSelected) {
        className.push("selected");
    }
    return (
        // <Collapse
        //     in={visible}
        //     transitionAppear={true}
        //     mountOnEnter={true}
        //     unmountOnExit={true}
        //     timeout={5}
        // >
        <li
            ref={liRef}
            className={getClassNames(...className)}
            style={{
                display: visible ? "flex" : "none",
            }}
            // title={desc}
            onClick={onClick}
            data-test={dataTest}
        >
            <div className="card-bg">
                {
                    /* istanbul ignore next */ image != null &&
                        typeof image === "string" &&
                        (!image.startsWith("data:image") &&
                        [CardType.Task, CardType.Action].includes(type) ? (
                            <div className="card-fileicon">
                                <Icons.Library
                                    name={image}
                                    size="5x"
                                    className="image-bg"
                                />
                            </div>
                        ) : (
                            <img src={image} alt="" className="image-bg" />
                        ))
                }
                {
                    /* istanbul ignore next */ image != null &&
                        React.isValidElement(image) && <>{image}</>
                }
                {image == null && (
                    <>
                        <div className="card-fileicon">
                            <CardSubTypeIcon
                                cardtype={type}
                                itemtype={itemtype}
                                name={name /* istanbul ignore next */ || ""}
                            />
                        </div>
                        {(siteType !== SiteType.NotSet || icon) && (
                            <Icons.Custom
                                icon={icon}
                                siteType={siteType}
                                className="card-siteicon"
                            />
                        )}
                        {count != null && count !== -1 && (
                            <CountBadge count={count} />
                        )}
                    </>
                )}
            </div>
            <div className="card-details">
                <div
                    className="card-title"
                    data-test={`cardTitle${removeSpaces(name)}`}
                >
                    <Mark word={filterText}>
                        {onlyName(
                            type,
                            itemtype,
                            name /* istanbul ignore next */ || ""
                        )}
                    </Mark>
                    {type === CardType.Task &&
                        extras != null &&
                        extras.name != null && (
                            <div className="card-taskitem">
                                {/* <Icons.Site
                                    siteType={siteType}
                                    style={{ marginRight: 3, height: 12 }}
                                /> */}
                                <CardSubTypeIcon
                                    cardtype={type}
                                    itemtype={itemtype}
                                    name={extras.name}
                                    style={{
                                        height: 15,
                                        width: 15,
                                        marginRight: 2,
                                    }}
                                />
                                <Mark word={filterText}>{extras.name}</Mark>
                            </div>
                        )}
                    {/* {bcText && (
                    <div className="card-bc" title={bcText}>
                        {bcText}
                    </div>
                )} */}
                </div>

                {desc && (
                    <div className="card-footer">
                        <div className="card-desc">
                            <Mark word={filterText}>{desc}</Mark>
                        </div>
                    </div>
                )}
            </div>
            <div className="card-aside" title={bcText}>
                {siteType !== SiteType.NotSet && (
                    <div
                        style={{ borderRightColor: color }}
                        className={
                            "card-corner " + cardColor(type, itemtype, siteType)
                        }
                    >
                        {bcText && (
                            <Icons.Library
                                className="card-corner-icon"
                                name="sitemap"
                            />
                        )}
                    </div>
                )}
                {typeof onMoreClick === "function" && (
                    <ActionsButton
                        className="card-more"
                        onClick={onMoreClick}
                    />
                )}
                {/* <div className="card-info">
                <Icons.Library name="question-circle-o" />
            </div> */}
            </div>
        </li>
        // </Collapse>
    );
};
CardItem.displayName = "CardItem";
export default CardItem;
