// @flow
//#region imports
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { usePrevious, useEffectOnce } from "hooks";
import TogglesHandler from "containers/TogglesHandler";
import { useDispatch, useSelector, type Dispatch } from "react-redux";
import { compose } from "redux";
import MainLayout from "components/MainLayout";
import Status from "components/Status";
import {
    RefreshMode,
    ServerEventItemType,
    ServerEventActionType,
    type ToggleDTO,
} from "data/types";
import TreeFilter from "components/TreeFilter";
import FolderTreeContainer from "containers/FolderTree";
import loc from "i18next";
import Constants, { type ConstantsEnum } from "data/constants";
import DocListContainer from "containers/DocList";
import * as s from "data/reducers/selectors";
import SplitPanes from "containers/SplitPanes";
import GlobalEventsHandler from "containers/GlobalEventsHandler";
import CloseButton from "@hs/close-button";
import CommandActionHandler from "components/CommandActions";
import { LocalStoreHandler } from "containers/LocalStore";
import Pane from "components/SplitPanes/Pane";
import styles from "./minipicker.module.css";
import { sitesSelector } from "data/storeHelper";
import { getClassNames } from "data/utils";
//#endregion

export const viewName = "minipicker";

//#region flow types
type Props = {
    /** callback when closing Modal dialog */
    onClose?: () => void,

    // from TogglesHandler
    /** TogglesHandler: current toggles */
    toggles: ?ToggleDTO,

    /** DocList: callback on Document single click
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onDocSingleClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    /** DocList: callback on Document double click
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onDocDoubleClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,

    /** FolderTree callback on Folder single click */
    onFolderSingleClick: (itemUri: string, treeNodeId: number) => void,

    /** optional breadcrumbPart click event instead of history.push */
    onBreadcrumbPartClick?: (itemUri: string) => void,

    /** whether to limit breadcrumb scope */
    breadcrumbScopeFromLevel?: number,
    /** TogglesHandler: whether multiple toggles are allowed */
    isMultiple: boolean,
    /** TogglesHandler: currently selected toggles' names */
    selectedToggles: Array<string>,
};

export const MiniPicker = ({
    filterAction,
    toggles,
    onDocSingleClick,
    onDocDoubleClick,
    onFolderSingleClick,
    onBreadcrumbPartClick,
    itemUri,
    onClose,
    breadcrumbScopeFromLevel,
    canChangeSite = false,
}: Props) => {
    //#region redux
    const dispatch: Dispatch = useDispatch();
    const treeStatus: ConstantsEnum = useSelector(s.treeStatusSelector);
    const treeIsBrowsable: boolean = useSelector(s.treeIsBrowsableSelector);
    const treeTotalCount: number = useSelector(s.treeTotalCountSelector);
    const treeAvailable = useMemo(
        () => treeIsBrowsable && treeTotalCount,
        [treeIsBrowsable, treeTotalCount]
    );
    // Note: Use default state for user specific data.
    const sites = useMemo(sitesSelector, []);
    //#endregion

    //#region state
    /** current tree name filter */
    const [treeFilter, setTreeFilter] = useState("");
    /** whether tree filter should include subfolders */
    const [includeSubfolders, setIncludeSubfolders] = useState(false);
    /** whether tree is focused */
    const [isTreeFocused, setIsTreeFocused] = useState(false);
    /** the real itemUri currently selected */
    const [activeItemUri, setActiveItemUri] = useState(itemUri);
    /** folder row */
    const [currentFolderRow, setCurrentFolderRow] = useState(null);
    /** document row */
    const [currentDocumentRow, setCurrentDocumentRow] = useState(-1);
    //#endregion};

    //#region props
    const prevItemUri = usePrevious(itemUri) || itemUri;
    //#endregion

    //#region tree
    const handleTreeClick = useCallback(
        (itemUri: string, treeNodeId: number): void => {
            setCurrentFolderRow(treeNodeId);
            setActiveItemUri(itemUri);
            setIsTreeFocused(true);
            /* istanbul ignore else */
            if (typeof onFolderSingleClick === "function")
                onFolderSingleClick(itemUri, treeNodeId);
        },
        [onFolderSingleClick]
    );

    const handleTreeExpand = useCallback(
        (itemUri: string): void => {
            setTreeFilter("");
            setActiveItemUri(itemUri);
            setCurrentFolderRow(null);
            setIsTreeFocused(false);
            /* istanbul ignore else */
            if (typeof onBreadcrumbPartClick === "function")
                onBreadcrumbPartClick(itemUri);
        },
        [onBreadcrumbPartClick]
    );

    const handleTreeFilterChange = useCallback(
        (value: ?string, includeSubfolders: boolean): void => {
            setTreeFilter(value || "");
            setIncludeSubfolders(includeSubfolders);
            setIsTreeFocused(false);
        },
        []
    );
    //#endregion

    //#region itemUri effect
    useEffect(() => {
        if (itemUri === prevItemUri) return;
        /* istanbul ignore else */
        if (itemUri !== activeItemUri) setActiveItemUri(itemUri);
        setTreeFilter("");
        setIncludeSubfolders(false);
        setCurrentFolderRow(null);
        setCurrentDocumentRow(-1);
    }, [itemUri, prevItemUri, activeItemUri, dispatch]);
    //#endregion

    //#region callbacks
    const handleSingleClick = useCallback(
        (e: SyntheticMouseEvent<*>, itemUri: string, rowIndex: number) => {
            setCurrentDocumentRow(rowIndex);
            setIsTreeFocused(false);
            /* istanbul ignore else */
            if (typeof onDocSingleClick === "function") {
                onDocSingleClick(e, itemUri, rowIndex);
            }
        },
        [onDocSingleClick]
    );
    const handleIconClick = useCallback(
        (e: SyntheticMouseEvent<*>, itemUri: string, rowIndex: number) => {
            handleSingleClick(e, itemUri, rowIndex);
            e.preventDefault();
            CommandActionHandler("doc_preview", {
                itemUri,
                newWindow: e.ctrlKey || e.altKey,
            });
        },
        [handleSingleClick]
    );
    //#endregion

    //#region mount effect
    // Always select first itemUri from Sites, if Picker is empty
    useEffectOnce(() => {
        if (itemUri == null) handleTreeExpand(sites?.[0]?.itemUri);
    });
    //#endregion

    return (
        <MainLayout
            name={viewName}
            toggles={toggles}
            itemUri={activeItemUri}
            actionsDisabled={true}
            onBreadcrumbPartClick={handleTreeExpand}
            breadcrumbReadonly={false}
            breadcrumbCanChangeSite={canChangeSite}
            topRowEnd={<CloseButton onClick={onClose} />}
            breadcrumbScopeFromLevel={breadcrumbScopeFromLevel}
        >
            <Pane
                id="picker_tree"
                className={getClassNames(
                    styles.split,
                    styles.tree,
                    (treeAvailable || treeStatus === Constants.LOADING) &&
                        styles.loading
                )}
            >
                {treeAvailable ? (
                    <TreeFilter
                        key="searchInput"
                        onChange={handleTreeFilterChange}
                        filter={treeFilter}
                        includeSubfolders={includeSubfolders}
                        allowSubfolders={true}
                        className={styles.filter}
                    />
                ) : (
                    <div className={styles.noTree} />
                )}
                {itemUri ? (
                    <FolderTreeContainer
                        viewName={viewName}
                        focused={isTreeFocused}
                        itemUri={itemUri}
                        activeTreeNodeId={currentFolderRow}
                        filter={treeFilter}
                        includeSubfolders={includeSubfolders}
                        onClick={handleTreeClick}
                        onExpand={handleTreeExpand}
                    />
                ) : (
                    <Status status={Constants.LOADING} />
                )}
            </Pane>
            <Pane
                id="picker_doclist"
                className={`${styles.split} ${styles.doclist}`}
            >
                {activeItemUri ? (
                    <DocListContainer
                        showAddDocument={false}
                        itemUri={activeItemUri}
                        activeRowIndex={currentDocumentRow}
                        onIconClick={handleIconClick}
                        onSingleClick={handleSingleClick}
                        onDoubleClick={onDocDoubleClick}
                        onMoreClick={handleSingleClick}
                    />
                ) : (
                    <Status status={Constants.LOADING} />
                )}
            </Pane>
        </MainLayout>
    );
};

export default compose(
    LocalStoreHandler({ name: viewName }),
    TogglesHandler({ viewName }),
    GlobalEventsHandler({
        items: [ServerEventItemType.folder],
        actions: [ServerEventActionType.delete],
        refreshMode: RefreshMode.Originator,
        refreshMessage: loc.t("globalEvents:folder.delete"),
        refreshRoute: true,
    }),
    SplitPanes({
        viewName,
        paneIds: ["#picker_tree", "#picker_doclist"],
        toggleIds: ["tree", "doclist"],
        direction: "horizontal",
    })
)(MiniPicker);
