import actionTypes from "../actions/actionTypes";
import immutable from "object-path-immutable";
import {
    parseSeqId,
    isGridRowDeleted,
} from "components/ExtraAttributes/GridEditorControl/GridUtils";

export const initialState = {
    detailView: {},
    defaults: {},
    isEditMode: {},
    "ea.tab": {},
};

const formReducer = (state = initialState, action) => {
    let isNew, seqId;
    switch (action.type) {
        case actionTypes.EA_AUDITLOG_UPDATE:
            return {
                ...immutable.merge(
                    state,
                    [
                        action.payload.name,
                        "auditLog",
                        "updatedRows",
                        action.payload.seqId,
                    ],
                    action.payload.values
                ),
            };

        case actionTypes.EA_AUDITLOG_DEL:
            [isNew, seqId] = parseSeqId(action.payload.seqId);
            if (isNew) {
                return {
                    ...immutable.del(state, [
                        action.payload.name,
                        "auditLog",
                        "newRows",
                        seqId,
                    ]),
                };
            }
            if (
                state[action.payload.name] &&
                isGridRowDeleted(state[action.payload.name].auditLog, seqId)
            ) {
                return state;
            }
            return {
                ...immutable.push(
                    state,
                    [action.payload.name, "auditLog", "deletedIds"],
                    seqId
                ),
            };
        case actionTypes.EA_AUDITLOG_INSERT:
            return {
                ...immutable.merge(
                    state,
                    [
                        action.payload.name,
                        "auditLog",
                        "newRows",
                        action.payload.rowIndex,
                    ],
                    action.payload.values
                ),
            };

        case actionTypes.EA_AUDITLOG_CLEAR:
            [isNew, seqId] = parseSeqId(action.payload.seqId);
            if (isNew) {
                return {
                    ...immutable.set(
                        state,
                        [action.payload.name, "auditLog", "newRows", seqId],
                        action.payload.values
                    ),
                };
            }
            // Clear Updates
            let _state = immutable.del(state, [
                action.payload.name,
                "auditLog",
                "updatedRows",
                action.payload.seqId,
            ]);
            // Clear Deletes
            if (
                state[action.payload.name] &&
                isGridRowDeleted(state[action.payload.name].auditLog, seqId)
            ) {
                _state = immutable.del(_state, [
                    action.payload.name,
                    "auditLog",
                    "deletedIds",
                    state[action.payload.name].auditLog.deletedIds.indexOf(
                        seqId
                    ),
                ]);
            }
            return {
                ..._state,
            };

        case actionTypes.EA_GRID_CURRENTROW:
            return {
                ...immutable.merge(state, [action.payload.name], {
                    rowIndex: action.payload.rowIndex,
                    seqId: action.payload.seqId,
                }),
            };

        case actionTypes.EA_DETAILVIEW_SET:
            if (action.payload == null) {
                return { ...state, detailView: {} };
            } else {
                return {
                    ...immutable.merge(state, "detailView", action.payload),
                };
            }

        case actionTypes.EA_FIELD_SET:
            if (action.payload == null) {
                return state;
            } else {
                return {
                    ...state,
                    lastChange: action.payload,
                };
            }

        case actionTypes.EA_TABINDEX_SET:
            return {
                ...immutable.merge(
                    state,
                    ["ea.tab", action.payload.itemUri, action.payload.name],
                    { tabIndex: action.payload.tabIndex }
                ),
            };

        case actionTypes.EA_CLEAR:
            // make sure not to clear the default form values (set by containers/DefaultValuesHandler)
            return {
                ...initialState,
                defaults: state.defaults,
                isEditMode: state.isEditMode,
                "ea.tab": state["ea.tab"],
            };

        case actionTypes.FORM_DEFAULTS_SET:
            return { ...initialState, defaults: action.payload.defaults };

        case actionTypes.FORM_EDITMODE_SET:
            // if (state.current && state.current.name)
            let isEditMode = {};
            // HACK tricky to get called to pass in viewName, so we set everything here
            if (action.payload.name === "doc") {
                isEditMode.docmodal = action.payload.isEditMode;
                isEditMode.document = action.payload.isEditMode;
            } else {
                /* istanbul ignore else */ if (
                    action.payload.name === "folder"
                ) {
                    isEditMode.folder = action.payload.isEditMode;
                } else alert("Unknown form named " + action.payload.name);
            }
            return {
                ...initialState,
                isEditMode,
                "ea.tab": state["ea.tab"],
            };

        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default formReducer;

export const formDetailViewSelector = (state: Object) => state.form.detailView;
export const formLastChangeSelector = (state: Object) => state.form.lastChange;
export const formAuditLogSelector = (state: Object, name: string) =>
    state.form && state.form[name] != null && state.form[name].auditLog != null
        ? state.form[name].auditLog
        : null;
export const formGridRowIndexSelector = (state: Object, name: string) =>
    state.form && state.form[name] && state.form[name].rowIndex != null
        ? state.form[name].rowIndex
        : -1;
export const formGridRowSeqIdSelector = (state: Object, name: string) =>
    state.form && state.form[name] && state.form[name].seqId != null
        ? state.form[name].seqId
        : -1;
export const formDefaultsSelector = (state: Object) => state.form.defaults;
export const formIsEditModeSelector = (state: Object, name: string) =>
    state.form && state.form.isEditMode[name]
        ? state.form.isEditMode[name]
        : false;
export const formTabIndexSelector = (
    state: Object,
    name: string,
    itemUri: string
) =>
    state.form &&
    state.form["ea.tab"] &&
    state.form["ea.tab"][itemUri] &&
    state.form["ea.tab"][itemUri][name]
        ? state.form["ea.tab"][itemUri][name].tabIndex
        : -1;
