import React, { useCallback, useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import { useAsync, useAbortController, usePersistSetting } from "hooks";
import {
    fetchShare,
    deleteShare,
    createShare,
    updateShare,
    checkShareName,
} from "data/api";
import Constants from "data/constants";
import Status from "components/Status";
import { createModal } from "react-modal-promise";
import { openConfirmModal } from "components/ConfirmModal";
import { openNotifyOptionsModal } from "./NotifyOptionsModal";
import { toastActionResult } from "data/toast";
import loc from "i18next";
import Modal, { ModalSize } from "components/Modal";
import Button from "react-bootstrap/lib/Button";
import Checkbox from "react-bootstrap/lib/Checkbox";
import Icons from "@hs/icons";
import ShareForm from "./ShareForm";
import styles from "./ShareModal.module.css";
import {
    getShareDate,
    isEditable,
    getActionKey,
} from "components/ShareModal/Utils";
import {
    AllowedShareActionFlags,
    ShareType,
    ShareAccessControlListType,
    ShareNotifyType,
    type ShareDTO,
    type ShareFormDTO,
    type ShareAttachment,
    type AllowedShareActionFlagsEnum,
    type ShareNotifyTypeTypeEnum,
} from "data/types";

type Props = {
    open: boolean,
    close: () => void,
    itemUris: Array<string>,
    items: Array<any>,
    name: string,
    isEdit: boolean,
    canEdit: boolean,
    isDoc: boolean,
    allowedShareActionFlags: AllowedShareActionFlagsEnum,
    aclEnumValues?: Array<EnumValue<ShareAccessControlListTypeEnum>>,
    attachments?: Array<ShareAttachment>,
    shareType?: ShareTypeEnum,
    folderCount: number,
    docCount: number,
};

const getShare = async (
    allowedShareActionFlags: AllowedShareActionFlagsEnum,
    itemUris: Array<string>,
    shareAs: Array<string>,
    shareType: ShareTypeEnum
): ShareFormDTO => ({
    itemUris,
    action: {
        allowedFlags: allowedShareActionFlags,
        type: shareType,
        acl: ShareAccessControlListType.View,
        shareAs: await checkShareName(itemUris, shareAs), //Always get an initial valid shareAs
        expires: null,
    },
    principals: null,
    message: null,
});

export const ShareModal = (props: Props) => {
    const {
        open,
        close,
        itemUris,
        items,
        name,
        itemKey,
        isEdit,
        canEdit,
        isDoc,
        allowedShareActionFlags: allowedShareActionFlagsFromProps,
        aclEnumValues,
        attachments,
        shareType: shareTypeFromProps,
        folderCount,
        docCount,
    } = props;

    // Load persisted settings
    const [isAdvancedFromSettings, persistIsAdvancedToSettings] =
        usePersistSetting({
            name: "shareIsAdvanced",
            defaultValue: false,
        });
    const [shareTypeFromSettings, persistShareTypeToSettings] =
        usePersistSetting({
            name: "shareDefaultType",
            defaultValue: ShareType.Share,
        });
    const [isPersisted, persistIsPersisted] = usePersistSetting({
        name: "shareIsPersisted",
        defaultValue: false,
    });

    // Setup variables
    const [share, setShare] = useState();
    const [notify, setNotify] = useState(
        isEdit ? ShareNotifyType.None : ShareNotifyType.All
    );
    const [shareAs, setShareAs] = useState();
    const [canSubmit, setCanSubmit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isAdvanced, setIsAdvanced] = useState(
        isEdit || isAdvancedFromSettings
    );
    const [allowedShareActionFlags, setAllowedShareActionFlags] = useState(
        allowedShareActionFlagsFromProps
    );
    const shareType = shareTypeFromProps || shareTypeFromSettings;

    const abortController = useAbortController();

    const state = useAsync(async () => {
        if (isEdit) {
            try {
                return await fetchShare(
                    itemUris[0],
                    abortController().signal
                ).then((share) => {
                    setAllowedShareActionFlags(share.action.allowedFlags);
                    setShareAs(share.action.shareAs); //Update name to NOT check again.
                    setShare(share);
                });
            } catch (err) {
                console.warn("Error while fetchShare", err);
                throw err;
            }
        } else {
            setShare(
                await getShare(
                    allowedShareActionFlags,
                    itemUris,
                    items.map((i) => i.name),
                    shareType
                )
            );
        }
    }, []);

    const handleConfirm = useCallback(
        (name: string, value: ShareTypeEnum) => {
            if (!isEdit && canEdit && name === "type" && isEditable(value)) {
                if (items.length > 1) {
                    toastActionResult(
                        false,
                        "share:action_update",
                        {
                            name,
                            context: "multiple",
                        },
                        "multi_share_edit"
                    );
                } else {
                    return openConfirmModal({
                        body: loc.t(
                            `share:action_update.confirmBody.${itemKey}`
                        ),
                        confirmButton: loc.t("share:action_update.confirmYes"),
                    }).then(
                        (success) =>
                            success &&
                            (close() ||
                                openShareModal({ ...props, isEdit: true }))
                    );
                }
            }
            return new Promise((resolve) => resolve(true));
        },
        [props, isEdit, canEdit, close, itemKey, items]
    );

    // Handle not allowed Share Types (by Settings or Acl)
    useEffect(() => {
        // Wait until initial share is loaded
        if (state.loading || state.error || share == null) return;

        const shareTypeKey = Object.keys(ShareType).find(
            (key) => ShareType[key] === share.action.type
        );
        let changeShareType = false;
        /* istanbul ignore else */
        if (
            shareTypeKey == null ||
            !(
                (allowedShareActionFlags &
                    AllowedShareActionFlags[shareTypeKey]) >
                0
            )
        ) {
            console.warn(
                `ShareType "${ShareType[shareTypeKey]}" is not allowed by AllowedShareActionFlags`
            );
            changeShareType = true;
        }

        /* istanbul ignore else */
        if (canEdit && !isEdit && share.action.type === ShareType.Share) {
            console.warn(
                `You can only create one Share per itemUri, changing to next available ShareType`
            );
            changeShareType = true;
            handleConfirm("type", ShareType.Share);
        }

        /* istanbul ignore else */
        if (changeShareType) {
            const newShareTypeKey = Object.keys(ShareType).find(
                (key) =>
                    key !== shareTypeKey &&
                    (allowedShareActionFlags & AllowedShareActionFlags[key]) > 0
            );
            if (newShareTypeKey == null) {
                toastActionResult(false, "share:action_allowed", {
                    name,
                });
                close();
            }
            setShare({
                ...share,
                action: { ...share.action, type: ShareType[newShareTypeKey] },
            });
        }
    }, [
        name,
        isEdit,
        canEdit,
        handleConfirm,
        allowedShareActionFlags,
        share,
        state.error,
        state.loading,
        close,
    ]);

    const handleChange = useCallback(
        (name: string, value: any) => {
            if (isEdit && name === "principals") setNotify(null);
            setShare((r) => ({ ...r, [name]: value }));
        },
        [isEdit]
    );

    const handlePersistSettings = useCallback(() => {
        if (isPersisted === false) {
            return;
        }
        persistShareTypeToSettings(share.action.type);
        persistIsAdvancedToSettings(isAdvanced);
    }, [
        share,
        isAdvanced,
        isPersisted,
        persistShareTypeToSettings,
        persistIsAdvancedToSettings,
    ]);

    const upsertShare = useCallback(
        async (shareAs: ?Array<string>, notify: ?ShareNotifyTypeTypeEnum) => {
            setIsSubmitting(true);
            const upsertShare = isEdit ? updateShare : createShare;
            const SHARE: ShareDTO = {
                names: items?.map((i) => i.name),
                itemUris: items?.map((i) => i.itemUri),
                action: { ...share.action },
                principals: share.principals,
                message: share.message,
                notify,
            };
            if (shareAs != null) {
                SHARE.action.shareAs = shareAs;
            }
            const locContext = {
                name: items.length > 1 ? name : SHARE.action.shareAs.join(", "),
                date: getShareDate(SHARE.action.expires),
            };
            let success = false;
            try {
                success = await upsertShare(
                    SHARE,
                    abortController(true).signal
                );
                if (success) close();
            } catch {
                success = false;
            }
            setIsSubmitting(false);
            toastActionResult(
                success,
                `share:action_${getActionKey(isEdit)}`,
                locContext
            );
        },
        [isEdit, share, abortController, close, items, name]
    );

    const handleShareAsOnSave = useCallback(async () => {
        if (share?.action?.type !== ShareType.Share) {
            return null;
        }
        if (isEqual(share?.action?.shareAs, shareAs)) {
            return shareAs;
        }
        try {
            const proposedShareAs = await checkShareName(
                itemUris,
                share.action.shareAs,
                abortController(true).signal
            );
            if (isEqual(proposedShareAs, share.action.shareAs)) {
                return proposedShareAs;
            }

            // i18n context
            const name = share.action.shareAs.filter(
                (s, i) => s !== proposedShareAs[i]
            );
            const proposed = proposedShareAs.filter(
                (s, i) => s !== share.action.shareAs[i]
            );
            // if shareAs is already present, await confirmation
            const confirmed = await openConfirmModal({
                body: loc.t("share:action_unique.confirmBody", {
                    name,
                    proposed,
                }),
                confirmButton: loc.t("share:action_unique.confirmYes", {
                    proposed,
                }),
            });
            if (confirmed) {
                return proposedShareAs;
            }
            setIsAdvanced(true);
        } catch {
            toastActionResult(false, "share:action_unique");
        }
        throw new Error("ShareAs not unique, user input needed.");
    }, [share, abortController, itemUris, shareAs]);

    const handleShareNotifyOptionsOnSave = useCallback(
        async (shareAs) => {
            if (!isEdit) return null;
            if (notify != null) return notify;
            return await openNotifyOptionsModal({ shareAs });
        },
        [isEdit, notify]
    );

    const handleSave = useCallback(async () => {
        try {
            const shareAs = await handleShareAsOnSave();
            const notify = await handleShareNotifyOptionsOnSave(shareAs);
            upsertShare(shareAs, notify);
            handlePersistSettings();
        } catch (e) {
            console.info("Upsert Share was aborted...", e);
        }
    }, [
        upsertShare,
        handleShareAsOnSave,
        handleShareNotifyOptionsOnSave,
        handlePersistSettings,
    ]);

    const handleDelete = useCallback(async () => {
        const confirmed = await openConfirmModal({
            body: loc.t("share:action_delete.confirmBody", { name }),
            confirmButton: loc.t("share:action_delete.confirmYes"),
        });
        let success = false;
        /* istanbul ignore else */
        if (confirmed === true) {
            try {
                success = await deleteShare(
                    itemUris[0],
                    abortController(true).signal
                );
            } catch {
                success = false;
            }
            toastActionResult(success, "share:action_delete");
            if (success) close();
        }
    }, [itemUris, abortController, close, name]);
    return (
        <Modal
            size={ModalSize.Small}
            enforceFocus={false}
            show={open !== false}
            onHide={/* istanbul ignore next */ () => close(false)}
            dialogClassName={styles.modal}
            docked
            dataTest={`shareModal${isAdvanced ? "Advanced" : "Simple"}`}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Icons.Library name="share-nodes" />{" "}
                    {loc.t("share:title", { name })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {state.loading || state.error ? (
                    <Status
                        status={
                            state.loading ? Constants.LOADING : Constants.ERROR
                        }
                        inline={true}
                    />
                ) : (
                    <ShareForm
                        setCanSubmit={setCanSubmit}
                        share={share}
                        handleSave={handleSave}
                        handleChange={handleChange}
                        handleConfirm={handleConfirm}
                        isAdvanced={isAdvanced}
                        setIsAdvanced={setIsAdvanced}
                        isDoc={isDoc}
                        allowedShareActionFlags={allowedShareActionFlags}
                        aclEnumValues={aclEnumValues}
                        items={items}
                        isEdit={isEdit}
                        attachments={attachments}
                        itemKey={itemKey}
                        folderCount={folderCount}
                        docCount={docCount}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Checkbox
                    onChange={() => persistIsPersisted(!isPersisted)}
                    checked={isPersisted}
                >
                    {loc.t("share:persist")}
                </Checkbox>
                <Button
                    aria-label="cancel"
                    onClick={() => close(false)}
                    bsStyle="link"
                    className="pull-left"
                >
                    {loc.t("cancel")}
                </Button>
                {!(state.loading || state.error) && (
                    <>
                        {isEdit ? (
                            <Button
                                aria-label="delete"
                                onClick={handleDelete}
                                bsStyle="link"
                                className={styles.delete}
                                data-test="ShareModalStop"
                            >
                                {loc.t("share:stop")}
                            </Button>
                        ) : (
                            <Button
                                aria-label="advanced"
                                onClick={() => setIsAdvanced(!isAdvanced)}
                                bsStyle="link"
                            >
                                {loc.t(
                                    `share:${
                                        isAdvanced ? "simple" : "advanced"
                                    }`
                                )}
                            </Button>
                        )}
                        <Button
                            disabled={
                                !canSubmit ||
                                /*istanbul ignore next*/ isSubmitting
                            }
                            aria-label="save"
                            onClick={handleSave}
                            bsStyle="primary"
                            data-test="ShareModalSubmit"
                        >
                            {isSubmitting && (
                                <Status
                                    status={Constants.LOADING}
                                    inline={true}
                                />
                            )}{" "}
                            {loc.t(`share:${getActionKey(isEdit)}`)}
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

ShareModal.displayName = "ShareModal";
export const openShareModal = createModal(ShareModal);
