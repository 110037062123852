import React, { useCallback } from "react";
import Icons from "@hs/icons";
import { getLocalizedText, getClassNames } from "data/utils";
import styles from "./ValueEnum.module.css";

export const ValueEnum = ({
    className,
    caption,
    value,
}: {
    className: string,
    caption: string,
    value: SummaryAttributeValueEnum,
}) => {
    const renderEnumValue = useCallback(
        (enumValue: EnumValue<T>) =>
            enumValue ? (
                <span key={`enumValue.${caption}.${enumValue.value}`}>
                    {enumValue.icon && <Icons.Library name={enumValue.icon} />}{" "}
                    {getLocalizedText(enumValue.label)}
                </span>
            ) : null,
        [caption]
    );
    return (
        <span
            className={getClassNames(className, styles.wrapper)}
            title={value.rawValue}
        >
            {value.isMulti
                ? value.enum
                      .filter((e) => (value.value & e.value) > 0)
                      .map((e) => renderEnumValue(e))
                : renderEnumValue(
                      value.enum.find((e) => e.value === value.value)
                  )}
        </span>
    );
};
ValueEnum.displayName = "SummaryAttribute.Value.Enum";

export default ValueEnum;
