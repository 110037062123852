import React, { type Component } from "react";
import Alert from "react-bootstrap/lib/Alert";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "react-bootstrap/lib/Button";
import FormsyEditorBase from "components/ExtraAttributes/EditorControl/FormsyEditorBase";
import ShareActionForm from "components/ShareModal/ShareActionForm";
import Icons from "@hs/icons";
import loc from "i18next";
import styles from "./ShareAction.module.css";
import { type ShareActionDTO } from "data/types";

type Props = {
    value?: ShareActionDTO,
    name: string,
    extras: any,
    onChange: () => void,
    onConfirm: (name: string, value: ShareTypeEnum) => Promise<boolean>,
    onCancel?: () => void,
    Form?: Component,
};

const ShareActionEditorControl = ({
    Form = ShareActionForm,
    ...props
}: Props) => (
    <FormsyEditorBase
        isAdditionalRender={true}
        required={props.isRequired ? "isDefaultRequiredValue" : undefined}
        render={(
            value?: ShareActionDTO,
            changeValue: (name: string, value?: ShareActionDTO) => void,
            isValid: boolean
        ) => (
            <div className="form-group" data-test="ShareActionEditorControl">
                <Alert bsClass="alert-share" className="alert">
                    {typeof props.onCancel === "function" && (
                        <div className={styles.header} onClick={props.onCancel}>
                            <Icons.Library name="caret-down" fixedWidth />{" "}
                            {props.header}
                        </div>
                    )}
                    <Form
                        name={props.name}
                        value={value}
                        extras={props.extras}
                        onChange={(value) =>
                            changeValue({ currentTarget: { value } })
                        }
                        onConfirm={props.onConfirm}
                    />
                    {typeof props.onCancel === "function" && (
                        <ButtonToolbar>
                            <Button
                                bsStyle="link"
                                onClick={props.onCancel}
                                className="pull-right"
                                data-test="ShareActionClose"
                            >
                                {loc.t("close")}
                            </Button>
                        </ButtonToolbar>
                    )}
                </Alert>
            </div>
        )}
        {...props}
    />
);

ShareActionEditorControl.displayName = "ShareActionEditorControl";
export default ShareActionEditorControl;
