// @flow
import { openProgressModal } from "components/ProgressModal";
import { downloadDocUri } from "data/api";
import { getLocalizedText } from "data/utils";
import { downloadFile } from "./doc_download";
import { toastActionResult } from "data/toast";

type Context = {
    items?: Array<any>,
};

class doc_downloadZip {
    logger: () => any = toastActionResult;
    getFileName = (disposition: string): string => {
        const utf8FilenameRegex = /filename\*=UTF-8''([\w%-.]+)(?:; ?|$)/i;
        const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

        let fileName: string = null;
        if (utf8FilenameRegex.test(disposition)) {
            fileName = decodeURIComponent(
                utf8FilenameRegex.exec(disposition)[1]
            );
        } else {
            const filenameStart = disposition
                .toLowerCase()
                .indexOf("filename=");
            if (filenameStart >= 0) {
                const partialDisposition = disposition.slice(filenameStart);
                const matches = asciiFilenameRegex.exec(partialDisposition);
                if (matches != null && matches[2]) {
                    fileName = matches[2];
                }
            }
        }
        return fileName;
    };

    execute = async ({ items }: Context): Promise<boolean> => {
        const documents = items?.filter((item) => item.isDoc) || [];
        if (!documents.length) {
            this.logger(false, "itemActions:doc_downloadZip");
            return false;
        }
        const zip = new (
            await import("jszip" /* webpackChunkName: "jszip" */)
        ).default();

        const target = zip;
        const name = getLocalizedText(`$multiselection:actionpanel.document`, {
            count: documents.length,
        });

        await openProgressModal({
            icon: "fa-regular fa-file-zip",
            title: getLocalizedText(
                "$itemActions:doc_downloadZip.compressing",
                {
                    documents: name,
                }
            ),
            initialProgress: 0,
            doWork: async (close, setError, setProgress) => {
                const progressPart = 100 / (documents.length + 1);
                try {
                    for (let doc = 0; doc < documents.length; doc++) {
                        const document = documents[doc];
                        try {
                            const uri = await downloadDocUri(document.itemUri);
                            if (uri == null)
                                throw new Error(
                                    "Cannot create download URI for document!"
                                );
                            const response = await fetch(uri);
                            const buffer = await response.arrayBuffer();
                            target.file(
                                this.getFileName(
                                    response.headers.get("content-disposition")
                                ),
                                buffer
                            );
                            setProgress(progressPart * (doc + 1));
                        } catch (e) {
                            console.warn(
                                `Cannot add ${document.name} to ZIP file`,
                                e
                            );
                        }
                    }

                    const base64 = await zip.generateAsync(
                        { type: "base64" },
                        (metadata) => {
                            setProgress(
                                progressPart * documents.length +
                                    (progressPart / 100) *
                                        metadata.percent.toFixed(0)
                            );
                        }
                    );
                    await downloadFile(
                        `data:application/zip;base64,${base64}`,
                        `${name}.zip`
                    );
                    //Close delayed to always show 100% progress
                    setTimeout(close, 800);
                } catch (err) {
                    /* istanbul ignore else */
                    if (err && err.message) setError(err.message);
                    console.warn(err);
                    // notify user
                    this.logger(false, "itemActions:doc_downloadZip");
                }
            },
        });
        return true;
    };
}

export default doc_downloadZip;
