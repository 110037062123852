import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSettingAction } from "data/actions";
import { settingValueSelector } from "data/reducers/selectors";

/** hooks version of containers/SettingValue */
export function usePersistSetting({ name, defaultValue, asObject = false }) {
    const dispatch: Dispatch = useDispatch();
    const value: any =
        useSelector((state) => settingValueSelector(name, state)) ??
        defaultValue;
    const setValue = useCallback(
        (value: any) => dispatch(setSettingAction(name, value)),
        [name, dispatch]
    );

    return asObject ? { value, setValue } : [value, setValue];
}
