// @flow
import { PdfPrintAction } from "hooks/PostMessage/Actions";
/**
 * trigger pdf.js print dialog
 */

type Context = DocModalProps & {
    target?: any,
};

class doc_print {
    execute = ({ target = window }: Context) => {
        target.postMessage(
            {
                source: "inPoint",
                type: PdfPrintAction,
            },
            "*"
        );
        return true;
    };
}
export default doc_print;
