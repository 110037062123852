// @flow
import React from "react";
import Modal, { ModalSize } from "components/Modal";
import Button from "react-bootstrap/lib/Button";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import { createModal } from "react-modal-promise";
import Icons from "@hs/icons";
import loc from "i18next";
import styles from "./ImageEditorModal.module.css";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import Rotation from "./rotation";
import Zoom from "./zoom";

const MAX_ZOOM = 10;

type Props = {
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog
     * @param {{croppedImage : string}} croppedImage new image after editing
     */
    close: ({ croppedImage: string }) => void,
    /** Modal Dialog title */
    title?: string,
    /** dataURL (base64) encoded image */
    src: string,
    /** requested image height */
    height: number,
    /** requested image width */
    width: number,
};

type State = {
    crop: ?Object,
    zoom: number,
    aspect: Number,
    rotation: number,
    croppedAreaPixels: ?Object,
};

/** presents a simple image editor (rotate, crop, ...) dialog to the user
 */
export class ImageEditorModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 4 / 3,
            rotation: 0,
            croppedAreaPixels: null,
        };
    }

    _onDone = async () => {
        const croppedImage = await getCroppedImg(
            this.props.src,
            this.state.croppedAreaPixels,
            this.state.rotation
        );
        this.props.close({ croppedImage });
    };

    _onCropChange = (crop) => this.setState({ crop });

    _onCropComplete = (croppedArea, croppedAreaPixels) =>
        this.setState({ croppedAreaPixels });

    _onZoomChange = (zoom) => this.setState({ zoom });

    _onRotationChange = (rotation) => this.setState({ rotation });

    render() {
        const { open, close, title } = this.props;
        return (
            <Modal
                size={ModalSize.Small}
                enforceFocus
                show={open !== false}
                onHide={/* istanbul ignore next */ () => close()}
                dialogClassName={styles.modal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Icons.Library name="pen-to-square" /> {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Cropper
                        image={this.props.src}
                        crop={this.state.crop}
                        // cropSize: { width: props.width, height: props.height },
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        rotation={this.state.rotation}
                        cropShape="rect"
                        minZoom={1}
                        maxZoom={MAX_ZOOM}
                        onCropChange={this._onCropChange}
                        onCropComplete={this._onCropComplete}
                        onZoomChange={this._onZoomChange}
                        onRotationChange={this._onRotationChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar className="pull-left">
                        <Rotation
                            value={this.state.rotation}
                            onRotate={this._onRotationChange}
                        />
                        <Zoom
                            value={this.state.zoom}
                            maxZoom={MAX_ZOOM}
                            onZoom={this._onZoomChange}
                        />
                    </ButtonToolbar>

                    {/* {this.state.legacy && (
                        <Button bsStyle="primary" onClick={this._onUpload}>
                            {loc.t("barcode:uploadButton")}
                        </Button>
                    )} */}
                    <ButtonToolbar className="pull-right">
                        <Button onClick={this._onDone}>{loc.t("save")}</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            </Modal>
        );
    }
}
export const openImageEditorModal = createModal(ImageEditorModal);
