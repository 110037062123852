//@flow
import React from "react";
import "./SimpleAttribute.css"; // TODO refactor to CSSModule - note used globally!

export const SimpleAttributeValue = ({
    value,
    className = "a-val",
}: {
    value: string,
    className: string,
}) => (
    <span className={className} title={value}>
        {value}
    </span>
);

const SimpleAttribute = ({ attribute }: { attribute: Array<any> }) => (
    <span className="a-wrapper">
        <span className="a-name" title={attribute[0]}>
            {attribute[0]}
        </span>{" "}
        <SimpleAttributeValue value={attribute[1]} />
    </span>
);
SimpleAttribute.displayName = "SimpleAttribute";
export default SimpleAttribute;
