import { searchRouteWithItem } from "components/ItemUriLink";
import history from "data/history";
import {
    type SearchItemDTO,
    SearchItemType,
    type SearchFilterDTO,
    type SearchSortDTO,
    NotificationStyle,
} from "data/types";
import getGeoLocation from "./searchResult_form.geoLocation";
import getBarcode from "./searchResult_form.barcode";
import loc from "i18next";
import { toastStyled } from "data/toast";
import { tokenize } from "data/utils";

export /**
 * will prefix the filter's name with "." (unless $SEARCHTEXT$) to signalize the server-side processing to replace only the value
 * and take all filter properties as configured in the search form
 * @param {*} name
 */
const fixSearchFilterName = (name) =>
    name.toLowerCase() === "$searchtext$" ? "$SEARCHTEXT$" : "." + name; //only overwrite value (see SearchQueryConversion.cs)

/**
 * create a searchItem object and navigate to the /searchresult view
 */
const runSearchItem = ({
    id,
    name,
    showFilterbar,
    searchType,
    viewMode,
    filters,
    sorts,
}) =>
    history.push(
        searchRouteWithItem({
            id,
            name,
            searchType,
            fb: showFilterbar ? 1 : 0,
            vm: viewMode,
            filters: JSON.stringify(filters),
            sorts: JSON.stringify(sorts),
        })
    );

export const createSearchItem = ({
    id,
    name,
    showFilterbar,
    searchType,
    viewMode,
    filters,
    sorts,
    onSearchItemCreated,
}) => {
    const searchItem: SearchItemDTO = {
        id,
        name,
        searchType: searchType || SearchItemType.Form,
        showFilterbar:
            showFilterbar !== "false" && showFilterbar !== false ? true : false,
        viewMode,
        filters: filters && JSON.parse(JSON.stringify(filters)), //HACK: We need a mutable object for this function
        sorts,
    };
    // analyse required filters (any tokens?)
    if (filters && filters.length > 0) {
        // any geolocation token?
        const geoFilter = searchItem.filters.find(
            (f) =>
                f.value &&
                String(f.value).toLowerCase().indexOf("{geolocation}") !== -1
        );
        if (geoFilter) {
            getGeoLocation({
                onLocation: (strLoc) => {
                    geoFilter.name = fixSearchFilterName(geoFilter.name); //only overwrite value (see SearchQueryConversion.cs)
                    // geolocation string format with radius (as required by inPoint.Web.Server for geoField radius search)
                    const geolocation = `${strLoc},${geoFilter.radius || 1000}`;
                    geoFilter.value = tokenize(geoFilter.value, {
                        geolocation,
                    });
                    onSearchItemCreated(searchItem);
                },
            });
            return null;
        }

        // or barcode token?
        const barcodeFilter = searchItem.filters.find(
            (f) =>
                f.value &&
                String(f.value).toLowerCase().indexOf("{barcode}") !== -1
        );
        if (barcodeFilter) {
            getBarcode({
                title: name,
                matchRegexp: barcodeFilter.matchRegexp,
                onBarcode: (barcode) => {
                    if (barcode == null) {
                        toastStyled({
                            icon: "triangle-exclamation",
                            style: NotificationStyle.Error,
                            toastId: "barcode-failure",
                            message: loc.t("barcode:noMatch"),
                        });
                        return;
                    }
                    barcodeFilter.name = fixSearchFilterName(
                        barcodeFilter.name
                    ); //only overwrite value (see SearchQueryConversion.cs)
                    barcodeFilter.value = tokenize(barcodeFilter.value, {
                        barcode,
                    });
                    onSearchItemCreated(searchItem);
                },
            });
            return null;
        }
    }
    return searchItem;
};

export /**
 * a Promise version of the createSearchItem() function (in case of placeholders it uses callbacks)
 */
const createSearchItemAsync = (
    searchItem: SearchItemDTO
): Promise<SearchItemDTO> =>
    new Promise((resolve, reject) => {
        const newSearchItem = createSearchItem({
            ...searchItem,
            onSearchItemCreated: resolve,
        });
        /* istanbul ignore else */
        if (newSearchItem != null) resolve(newSearchItem);
    });

type Context = {
    title: string,
    searchForm: number,
    showFilterbar?: boolean | string,
    viewMode?: string,
    filters?: Array<SearchFilterDTO>,
    sorts?: Array<SearchSortDTO>,
};

class searchResult_form {
    /**
     * shows searchResults for a pre-defined searchForm
     * @param {{ title: string }} { title } the modal title
     * @param {{ searchForm: number }} { searchForm } searchForm.id to run
     * @param {{ showFilterbar: boolean }} { showFilterbar } whether to include Filterbar (default: true)
     * @param {{ viewMode: string }} { viewMode } change default viewMode (List/Map) (default: List)
     * @param {{ filters: Array<SearchFilterDTO> }} { filters } set additional filters
     * @param {{ sorts: Array<SearchSortDTO> }} { sorts } set sorting
     */
    execute = ({
        title,
        searchForm,
        showFilterbar,
        viewMode,
        filters,
        sorts,
    }: Context) => {
        const searchItem = createSearchItem({
            id: searchForm,
            name: title,
            showFilterbar,
            viewMode,
            filters,
            sorts,
            onSearchItemCreated: runSearchItem,
        });
        if (searchItem != null) {
            // no filters / nor geolocation / nor barcode
            runSearchItem(searchItem);
        }
        return true;
    };
}

export default searchResult_form;
