import { openItemFlowsModal } from "components/ItemFlowsModal";
import { ItemFlowDirection } from "data/types";

type Context = {
    itemUri: string,
    files?: Array<DroppedFile>,
    flowId?: string,
    categoryId?: string,
};

/**
 * opens the upload dialog to the given itemUri
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ files?: Array<DroppedFile> }} { files }
 * @param {{ flowId?: string}} { flowId } pre-selected flow.id, defaults to doc_upload
 * @param {{ categoryId?: string}} { categoryId } pre-selected category.id, defaults to document
 */

class doc_upload {
    execute = async ({
        itemUri,
        files,
        flowId = "doc_upload",
        categoryId = "document",
    }: Context) => {
        await openItemFlowsModal({
            itemUri,
            direction: ItemFlowDirection.in,
            flowId,
            categoryId,
            files,
        });
        return true;
    };
}

export default doc_upload;
