// @flow
import React, { useState, useCallback, useRef } from "react";
import { usePdfHighlightInput } from "hooks";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "react-bootstrap/lib/Button";
import ClearableInput from "@hs/clearable-input";
import Avatar from "components/User/Avatar";
import loc from "i18next";
import styles from "./NoteInput.module.css";
import { ActivityType, ItemNoteType, type ActivityDTO } from "data/types";
import { getClassNames } from "data/utils";

type Props = {
    /** callback when user wants to add note
     * @param {ActivityDTO} activity new note to add
     */
    onEnter: (activity: ActivityDTO) => void,
    /** optional callback when user aborts note
     * @param {SyntheticMouseEvent<*>} e mouse event
     */
    onAbort?: (e: SyntheticMouseEvent<*>) => void,
    /** current userId */
    userId: number,
    /** optional: initial values */
    activity?: ActivityDTO,
};

export const getNoteType = (type: string): string => {
    switch (type) {
        case ItemNoteType.PdfHighlight:
            return type.toLowerCase();
        default:
            if (type != null)
                console.info(
                    `Unknown ItemNoteType: ${type}, fallback to default.`
                );
            return "note";
    }
};

const NoteInput = ({
    onEnter,
    onAbort,
    userId,
    activity = { what: ActivityType.comment, extras: null, text: "" },
}: Props) => {
    const noteRef = useRef(null);
    const [value, setValue] = useState(activity.text);
    const [noteExtras, setNoteExtras] = useState(activity.extras);

    const handleNoteExtras = useCallback(
        (extras) =>
            setNoteExtras(
                extras != null
                    ? /* istanbul ignore next */ extras
                    : activity.extras
            ),
        [activity.extras]
    );

    const { handleHighlight, handleSelect } = usePdfHighlightInput(
        handleNoteExtras,
        noteRef
    );

    const handleEnter = useCallback(
        (text: string) => {
            onEnter({
                ...activity,
                text,
                extras: noteExtras,
            }).then((result) => {
                /* istanbul ignore else */
                if (result) {
                    handleHighlight(null);
                    setValue("");
                    handleSelect();
                }
            });
        },
        [onEnter, noteExtras, activity, handleHighlight, handleSelect]
    );

    const handleAbort = useCallback(
        (e) => {
            typeof onAbort === "function" && onAbort(e);
            setNoteExtras(activity.extras);
            setValue("");
            handleSelect();
        },
        [onAbort, activity.extras, handleSelect]
    );

    return (
        <div className={`note-input ${styles.noteContainer}`}>
            <FormGroup className={styles.noteWrapper}>
                {userId && <Avatar id={userId} className="round" />}
                <ClearableInput
                    multi
                    maxRows={3}
                    aria-label="note"
                    placeholder={loc.t(
                        `activity:${getNoteType(
                            noteExtras && noteExtras.$type
                        )}.placeholder`
                    )}
                    ref={noteRef}
                    onChange={setValue}
                    onEnter={handleEnter}
                    value={value}
                    className={getClassNames("form-control", styles.noteInput)}
                />
            </FormGroup>
            {((activity.extras == null && noteExtras != null) ||
                activity.text !== "" ||
                activity.extras != null ||
                value !== activity.text) && (
                <Button bsStyle="link" bsSize="xsmall" onClick={handleAbort}>
                    {loc.t(
                        `activity:${getNoteType(
                            noteExtras && noteExtras.$type
                        )}.abort`
                    )}
                </Button>
            )}
        </div>
    );
};
NoteInput.displayName = "NoteInput";
export default NoteInput;
