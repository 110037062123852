//@flow
import React from "react";
import Status from "./index";
import Constants, { type ConstantsEnum } from "data/constants";
import styles from "./Overlay.module.css";

type Props = { status: ConstantsEnum, message: string };
const Overlay = ({ status, message }: Props) =>
    status !== Constants.OK ? (
        <div className={styles.overlay}>
            <Status status={status} message={message} />
        </div>
    ) : null;
Overlay.displayName = "Status.Overlay";

export default Overlay;
