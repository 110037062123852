import { useRef } from "react";
import { compose, createStore, applyMiddleware } from "redux";
import appReducers from "data/reducers";
// responsive
import { responsiveStoreEnhancer } from "redux-responsive";
// sagas
import createSagaMiddleware from "redux-saga";
import { localRootSaga } from "data/sagas/rootSaga";

const getStore = (storeName: string = "LocalStore") => {
    // create the saga middleware
    const sagaMiddleware = createSagaMiddleware();

    // http://redux.js.org/
    const middlewares = [sagaMiddleware];
    /*istanbul ignore next*/
    const composeEnhancers =
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                name: `inPoint.Web ${storeName}`,
            })) ||
        compose;
    const store = createStore(
        appReducers,
        composeEnhancers(
            responsiveStoreEnhancer,
            applyMiddleware(...middlewares)
        )
    );

    // then run the rootSaga which forks all the others
    sagaMiddleware.run(localRootSaga);

    return store;
};

const useLocalStore = (storeName: ?string) => {
    const store = useRef(null);
    /* istanbul ignore else */
    if (store.current == null) {
        store.current = getStore(storeName);
    }
    return store.current;
};

export { useLocalStore, getStore };
