// @flow
import React from "react";
import MenuItem from "react-bootstrap/lib/MenuItem";
import { type SearchBucketDTO } from "data/types";

type Props = { item: SearchBucketDTO };

const Header = ({ item }: Props) => (
    <MenuItem header>{item.caption || item.name}</MenuItem>
);

Header.displayName = "FilterBar.SearchBucket.Header";

export default Header;
