// @flow
import React, { PureComponent } from "react";
import { Manager } from "react-popper";
import FormsyEditorBase from "./FormsyEditorBase";
import moment from "moment";
import Moment from "containers/Moment";
import DayPickerInput from "components/DayPickerInput";
import { DateTimeFormat, type DateTimeFormatValues } from "data/types";
import { userLanguage } from "data/storeHelper";
import ClearableInput from "@hs/clearable-input";

type Props = {
    /** Field name */
    name: string,
    /** Field value */
    value: ?string,
    /** whether this field is required */
    isRequired: boolean,
    /** whether this field is readonly */
    isReadonly: boolean,
    /** how to render component */
    type: DateTimeFormatValues,
};

type State = {
    day: Date,
    time: Date,
    dateValue: string,
    timeValue: string,
};

export class DateEditorControl extends PureComponent<Props, State> {
    format: string;

    static defaultProps = {
        value: "",
        isRequired: false,
        isReadonly: false,
        type: DateTimeFormat.Date,
    };

    constructor(props: Props) {
        super(props);
        // set user's locale for parsing/formatting
        moment.locale(userLanguage());
        // full date&time format
        this.format = this._getFormat(props);
        // parse pristine value or set NOW
        if (props.value != null && String(props.value).length > 0) {
            const dt = moment(props.value, this.format);
            this.state = {
                day: dt.toDate(),
                time: dt.toDate(),
                dateValue: this.getValueAsDate(props.value),
                timeValue: this.getValueAsDate(
                    props.value,
                    moment.HTML5_FMT[
                        props.type.toLowerCase().includes("long")
                            ? "TIME_SECONDS"
                            : "TIME"
                    ]
                ),
            };
        } else {
            this.state = { day: new Date(), time: new Date() };
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.value !== this.props.value &&
            !this.props.value?.startsWith("__INVALID__")
        ) {
            const dt = moment(this.props.value, this.format);
            this.setState({
                day: dt.toDate(),
                time: dt.toDate(),
                dateValue: this.getValueAsDate(this.props.value),
                timeValue: this.getValueAsDate(
                    this.props.value,
                    moment.HTML5_FMT[
                        this.props.type.toLowerCase().includes("long")
                            ? "TIME_SECONDS"
                            : "TIME"
                    ]
                ),
            });
        }
    }

    _getFormat = (props) => {
        switch (props.type) {
            case DateTimeFormat.Time:
                return moment.localeData().longDateFormat("LT");
            case DateTimeFormat.LongTime:
                return moment.localeData().longDateFormat("LTS");
            case DateTimeFormat.DateTime:
                return `${moment.localeData().longDateFormat("L")} ${moment
                    .localeData()
                    .longDateFormat("LT")}`;
            case DateTimeFormat.DateLongTime:
                return `${moment.localeData().longDateFormat("L")} ${moment
                    .localeData()
                    .longDateFormat("LTS")}`;
            case DateTimeFormat.Date:
                return moment.localeData().longDateFormat("L");
            case DateTimeFormat.JsonDate:
                return `YYYY-MM-DD[T]00:00:00.000`;
            case DateTimeFormat.JsonDateEnd:
                return `YYYY-MM-DD[T]23:59:59.000`;
            case DateTimeFormat.JsonDateTime:
                return `YYYY-MM-DD[T]HH:mm:ss.SSS`;
            default:
                console.error(
                    "Wrong DateTimeFormat provided. Defaulting to DatetimeFormat.Date"
                );
                return moment.localeData().longDateFormat("L");
        }
    };

    _getDate = () => {
        const { day, time } = this.state;
        const m = moment(time)._isValid
            ? moment(
                  // only consider the time for the date if it is valid
                  new Date(
                      day.getFullYear(),
                      day.getMonth(),
                      day.getDate(),
                      time.getHours(),
                      time.getMinutes(),
                      time.getSeconds()
                  )
              )
            : /* istanbul ignore next */
              moment(
                  new Date(day.getFullYear(), day.getMonth(), day.getDate())
              );
        return m.format(this.format);
    };

    _onDayChange = (
        day: ?Date,
        dayPickerInput: DayPickerInput,
        changeValue: Function,
        inputValue: ?Date,
        isValueEmpty: boolean = false
    ) => {
        // this callback is called before the setState is finished,
        // so when the user picks a value from the date picker
        // we have to directly pass it to the function
        // otherwise we can use the value in the input field

        const value =
            inputValue ||
            dayPickerInput?._ref?.value ||
            dayPickerInput?.state?.value;

        if (day != null) {
            this.setState(
                {
                    day,
                    dateValue: this.getValueAsDate(day, undefined, "L"),
                },
                () =>
                    changeValue({
                        currentTarget: {
                            value:
                                !this.shouldRenderTime() ||
                                this.getValueAsDate(this.state.timeValue) !== ""
                                    ? this._getDate()
                                    : this.invalidate(this.state.dateValue),
                        },
                    })
            );
        } else {
            this.setState((prevState) => {
                const dateValue = this.invalidate(value);
                const nextState = {
                    dateValue,
                    timeValue: prevState.timeValue,
                };
                // Clear was pressed
                if (
                    prevState.dateValue === value ||
                    prevState.dateValue === dateValue ||
                    value === "" ||
                    isValueEmpty
                ) {
                    nextState.dateValue = this.shouldRenderTime()
                        ? this.invalidate()
                        : null;
                    if (prevState.timeValue === "__INVALID__")
                        nextState.timeValue = null;
                    if (nextState.timeValue == null) nextState.dateValue = null;
                }
                changeValue({
                    currentTarget: {
                        value: nextState.dateValue,
                    },
                });
                return nextState;
            });
        }
    };

    _onTimeChange = (event: SyntheticInputEvent<*>, changeValue: Function) => {
        const value = event.currentTarget.value;
        const time = moment(value, "LTS").toDate();
        if (
            value?.trim().length > 0 &&
            !isNaN(time) &&
            (!this.shouldRenderSeconds() || value.match(/:/g).length === 2) //HACK: do validate long time correctly
        ) {
            this.setState({ time, timeValue: value }, () =>
                changeValue({
                    currentTarget: {
                        value:
                            !this.shouldRenderDate() ||
                            this.getValueAsDate(this.state.dateValue) !== ""
                                ? this._getDate()
                                : this.invalidate(value),
                    },
                })
            );
        } else {
            this.setState((prevState) => {
                const timeValue = this.invalidate(value);
                const nextState = {
                    timeValue,
                    dateValue: prevState.dateValue,
                };
                // Clear was pressed
                if (value === "") {
                    nextState.timeValue = this.shouldRenderDate()
                        ? this.invalidate()
                        : null;
                    // Handle date with time invalidation
                    if (prevState.dateValue === "__INVALID__")
                        nextState.dateValue = null;
                    if (nextState.dateValue == null) nextState.timeValue = null;
                }
                changeValue({
                    currentTarget: {
                        value: nextState.timeValue,
                    },
                });
                return nextState;
            });
        }
    };

    shouldRenderDate = () => this.props.type.toLowerCase().includes("date");
    shouldRenderTime = () => this.props.type.toLowerCase().includes("time");
    shouldRenderSeconds = () => this.props.type.toLowerCase().includes("long");

    invalidate = (value: string = ""): string => `__INVALID__${value}`;

    /* istanbul ignore next */
    getValueAsDate = (
        value: ?string,
        format: string = "L",
        valueFormat: string = this.format
    ) =>
        value &&
        String(value).length > 0 &&
        !String(value).startsWith("__INVALID__")
            ? moment(value, valueFormat).format(format)
            : "";

    getValidValue = (value: ?string): any =>
        value && typeof value === "string"
            ? value.replace("__INVALID__", "")
            : value;

    render = () => (
        <FormsyEditorBase
            required={
                this.props.isRequired ? "isDefaultRequiredValue" : undefined
            }
            render={(
                value: ?string,
                changeValue: (name: string, value: ?string) => void,
                isValid: boolean
            ) => (
                <Manager>
                    {this.shouldRenderDate() && (
                        <DayPickerInput
                            inputProps={{
                                className: `form-control${
                                    !isValid ? " has-error" : ""
                                }`,
                                disabled: this.props.isReadonly,
                            }}
                            required={this.props.isRequired}
                            classNames={{
                                container:
                                    "DayPickerInput container-form-control",
                                overlayWrapper: "DayPickerInput-OverlayWrapper",
                                overlay: "DayPickerInput-Overlay",
                            }}
                            dayPickerProps={{
                                showWeekNumber: true,
                                numberOfMonths: 1,
                                fixedWeeks: true,
                            }}
                            value={this.getValidValue(this.state.dateValue)}
                            id={this.props.name}
                            onDayChange={
                                /* istanbul ignore next */ (
                                    day: ?Date,
                                    dayPickerInput: DayPickerInput,
                                    inputValue: ?Date,
                                    isValueEmpty: ?boolean
                                ) =>
                                    this._onDayChange(
                                        day,
                                        dayPickerInput,
                                        changeValue,
                                        inputValue,
                                        isValueEmpty
                                    )
                            }
                            placeholder={moment
                                .localeData()
                                .longDateFormat("L")}
                            format={moment.localeData().longDateFormat("L")}
                        />
                    )}
                    {this.shouldRenderTime() && (
                        <ClearableInput
                            onChangeWithRawEvent={true}
                            name={this.props.name}
                            inputType="time"
                            disabled={this.props.isReadonly}
                            value={this.getValidValue(this.state.timeValue)}
                            step={this.shouldRenderSeconds() ? 1 : 60}
                            onChange={
                                /*istanbul ignore next*/ (event) =>
                                    this._onTimeChange(event, changeValue)
                            }
                            style={{
                                flexGrow: 0,
                            }}
                            className="TimePickerInput form-control"
                        />
                    )}
                </Manager>
            )}
            {...this.props}
        />
    );
}

export default Moment(DateEditorControl);
