import actionTypes from "data/actions/actionTypes";
import Constants from "data/constants";

export const initialState = {
    idToken: null,
    accessToken: null,
    refreshToken: null,
    status: Constants.NONE,
    expiresIn: null,
    expiresOn: null,
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_RESULT:
            return {
                ...state,
                ...action.payload,
            };
        case actionTypes.LOGIN_SIGNOUT:
        /* istanbul ignore next */
        // eslint-disable-next-line no-fallthrough
        case actionTypes.PERSIST_PURGE:
            return initialState;
        default:
            return state;
    }
};

export default loginReducer;

export const loginStatusSelector = (state: Object) => state.login.status;
export const loginIdTokenSelector = (state: Object) =>
    state.login.status === Constants.OK
        ? state.login.idToken
        : /* istanbul ignore next */ null;
export const loginAccessTokenSelector = (state: Object) =>
    state.login.status === Constants.OK
        ? state.login.accessToken
        : /* istanbul ignore next */ null;
export const loginRefreshTokenSelector = (state: Object) =>
    state.login.status === Constants.OK
        ? state.login.refreshToken
        : /* istanbul ignore next */ null;
export const loginExpiresOnSelector = (state: Object) => state.login.expiresOn;
export const loginExpiresInSelector = (state: Object) => state.login.expiresIn;
