import { getFolderItemUri, restoreItemUri } from "data/api";
import { toastActionResult } from "data/toast";
import { folderRoute } from "components/ItemUriLink";
import history from "data/history";

/**
 * Restores a document from the Recycle Bin by itemUri
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name: string }} { name }
 */

type Context = {
    itemUri: string,
    name: string,
};

class recyclebin_docRestore {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;

    execute = async ({ itemUri }: Context): Promise<boolean> => {
        try {
            const success = await restoreItemUri(itemUri);
            if (success) {
                const folderUri = await getFolderItemUri(itemUri);
                history.replace(folderRoute(folderUri));
            }
            return success;
        } catch (err) {
            console.warn(err);
            return false;
        }
    };

    report = async (success: boolean, { name }: Context): Promise<boolean> => {
        this.logger(success === true, "commandAction:recyclebin_docRestore", {
            name,
        });
    };
}

export default recyclebin_docRestore;
