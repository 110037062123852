import { call, put, takeLatest, cancelled } from "redux-saga/effects";
import actionTypes from "data/actions/actionTypes";
import { getTreeResultAction } from "data/actions";
import { fetchTree } from "data/api";
import Constants from "data/constants";

export function* getTree(action) {
    // https://decembersoft.com/posts/redux-saga-abort-controller-cancel-api-calls/
    const abortController = new AbortController();

    try {
        const tree = yield call(fetchTree, {
            itemUri: action.payload.itemUri,
            filter: action.payload.filter,
            deep: action.payload.deep,
            all: action.payload.all,
            signal: abortController.signal,
        });
        yield put(
            getTreeResultAction({ status: Constants.OK, tree, error: null })
        );
    } catch (e) {
        yield put(
            getTreeResultAction({
                status: Constants.ERROR,
                error: e,
            })
        );
    } finally {
        if (yield cancelled()) {
            // Cancel the API call if the saga was cancelled
            abortController.abort();
        }
    }
}

function* treeSaga() {
    yield takeLatest(actionTypes.TREE_FETCH_REQUEST, getTree);
}

export default treeSaga;
