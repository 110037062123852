import { useMemo, useCallback } from "react";
import isEqual from "lodash/isEqual";
import ItemActions from "components/ActionPanel/ItemActions";
import { getLocalizedText } from "data/utils";

const Icons = {
    enable: "toggle-on",
    disable: "toggle-off",
    invert: "shuffle",
    selectAll: "check-double",
    deselectAll: "times",
};

export const ItemView_MultiSelection = ({
    multiSelection,
    onAction,
}: Props) => {
    const {
        multiple,
        enable,
        disable,
        actions: handler,
        activeRows,
        availableRows,
    } = multiSelection || {};
    const actions = useMemo(() => {
        const actions = [];
        if (multiple) {
            actions.push(["disable", disable]);
            actions.push(["invert", handler.handleInvert]);
            /* istanbul ignore else */
            if (!isEqual(activeRows, availableRows)) {
                actions.push(["selectAll", handler.handleSelectAll]);
            }
            /* istanbul ignore else */
            if (Object.keys(activeRows).length > 0) {
                actions.push(["deselectAll", handler.handleDeselectAll]);
            }
        } else {
            actions.push(["enable", enable]);
        }

        return actions.map(([id, action]) => ({
            id,
            name: getLocalizedText(`$multiselection:${id}`),
            icon: Icons[id],
            action,
        }));
    }, [disable, enable, multiple, handler, activeRows, availableRows]);

    const onActionClick = useCallback(
        (action) => {
            action.action();
            onAction();
        },
        [onAction]
    );
    if (multiSelection == null) return null;
    return <ItemActions actions={actions} onAction={onActionClick} />;
};
ItemView_MultiSelection.displayName = "ItemViews.MultiSelection";

export default ItemView_MultiSelection;
