import ApiManager from "data/ApiManagerBase";
import { offlineQueueStorage, getAllItems } from "data/storage";
import { toastActionResult, dismissToast, toastId } from "data/toast";
import { sleep } from "data/utils";
import { JL } from "data/logging";
import { openConflictModal } from "components/ConflictModal";
import { produce } from "immer";
import { OfflineQueueItem, OfflineQueueItemType } from "data/types";
// import uppy from "data/uppy";

const _log = JL("offlineQueue");
const UPDATE_CONFLICT_EVENTID = 1201;

export /**
 * Add an ApiCatalog call to the offlineQueue
 * @param {string} name ApiCatalog.name
 * @param {*} params parameters to pass to ApiManager.call
 * @returns {Promise<void>}
 */
const addToOfflineQueue = ({
    key,
    type,
    name,
    params,
}: {
    key?: string,
    type: OfflineQueueItemTypeEnum,
    name: string,
    params: any,
}): Promise<void> => {
    let dbKey = key;
    if (dbKey == null) {
        dbKey = String(Date.now());
        if (params && params.hasOwnProperty("itemUri")) {
            dbKey = params.itemUri + "_" + dbKey;
        }
    }
    return offlineQueueStorage.setItem(dbKey, {
        type,
        name,
        params,
    });
};

export const anyPendingChanges = (keyPrefix: string): Promise<boolean> =>
    // TODO review anyPendingChanges for exact or startsWith itemUri?
    offlineQueueStorage
        .keysStartingWith(keyPrefix)
        .then((result) => result.length > 0);

/**
 * Works through the offlineQueue and retries the ApiCatalog calls
 */
export async function replayOfflineQueue(): Promise<void> {
    const count = await offlineQueueStorage.length();
    if (count === 0) return;
    _log.debug(() => ({
        msg: `Found ${count} pending items in offlineQueue`,
    }));

    const api = new ApiManager();
    let isError = false;
    const allItems = await getAllItems(offlineQueueStorage);
    for (const item of allItems) {
        const offlineQueueItem = item.value;
        _log.debug(() => ({
            msg: `Syncing  "${item.key}" from offlineQueue`,
            api: offlineQueueItem,
        }));
        let success = false;
        switch (offlineQueueItem.type) {
            case OfflineQueueItemType.ApiCall:
                success = await _replayApiCall(api, item.key, offlineQueueItem);
                break;
            case OfflineQueueItemType.UppyFile:
                success = await _replayUppyUpload(item.key, offlineQueueItem);
                break;
            /* istanbul ignore next */
            default:
                _log.warn(() => ({
                    msg: "Unknown OfflineQueueItemType",
                    offlineQueueItem,
                }));
                break;
        }

        if (!success) isError = true;
    }

    dismissToast(toastId.NetworkStatus);
    toastActionResult(!isError, "common:offline.synced");
}

// returns true for success
async function _replayApiCall(
    api: ApiManager,
    key: string,
    queueItem: OfflineQueueItem
): Promise<boolean> {
    let qi = produce(queueItem, (draft) => draft);

    // #55386 simple retrial policy
    for (let i = 0; i < 5; i++) {
        try {
            await api.call(qi.name, {
                params: qi.params,
            });
            await offlineQueueStorage.removeItem(key);
            return true;
        } catch (error) {
            // eslint-disable-next-line no-loop-func
            _log.error(() => ({
                msg: `Error while syncing "${key}" offline queue`,
                api: qi,
                error,
            }));
            qi = produce(qi, (draft) => {
                draft.retries = (draft.retries || 0) + 1;
                draft.error = error.data || error.message;
            });
            await offlineQueueStorage.setItem(key, qi);

            // #55376 conflict management
            if (
                error.data != null &&
                error.data.eventId === UPDATE_CONFLICT_EVENTID &&
                error.data.extra &&
                error.data.extra.name != null
            ) {
                qi = await _handleConflict(error, key, qi);
                if (qi == null) return true;
            }
            await sleep(3000);
        }
    }

    return false;
}

// returns true to try again
async function _handleConflict(
    error,
    key: string,
    offlineQueueItem: OfflineQueueItem
): Promise<OfflineQueueItem | null> {
    const doOverwrite = await openConflictModal(error.data.extra.name);
    if (doOverwrite) {
        const qi = produce(offlineQueueItem, (draft) => {
            // we blindly set the etag, reset counter try again
            draft.params["etag"] = error.data.extra.currentEtag;
            draft.retries = 0;
            draft.error = null;
        });
        // update and immediately continue and retry (shorten concurrency window)
        await offlineQueueStorage.setItem(key, qi);
        return qi;
    } else {
        await offlineQueueStorage.removeItem(key);
        return null;
    }
}

// returns true for success
async function _replayUppyUpload(
    key: string,
    offlineQueueItem: OfflineQueueItem
): Promise<boolean> {
    try {
        const uppy = (await import("data/uppy")).default;
        uppy.addFile(offlineQueueItem.params);
        const result = await uppy.retryUpload(offlineQueueItem.params.id);
        if (result && result.failed && result.failed.length === 0) {
            await offlineQueueStorage.removeItem(key);
            uppy.cancelAll();
            return true;
        } else {
            throw new Error("Uppy upload failed");
        }
    } catch (error) {
        _log.error(() => ({
            msg: "While replaying uppy file upload",
            error,
            offlineQueueItem,
        }));
        const qi = produce(offlineQueueItem, (draft) => {
            draft.retries = (draft.retries || 0) + 1;
            draft.error = error.data || error.message;
        });
        await offlineQueueStorage.setItem(key, qi);
    }
    return false;
}
