import React from "react";
import { getDisplayName } from "data/constants";

type Config = {
    mapper: () => string,
};

const withViewName =
    ({ mapper }: Config) =>
    (WrappedComponent: ComponentType<any>) => {
        const HOC = (props: Props) => {
            const viewName = mapper(props);
            return <WrappedComponent {...props} viewName={viewName} />;
        };
        HOC.displayName = `withViewName(${getDisplayName(WrappedComponent)})`;
        return HOC;
    };

export default withViewName;
