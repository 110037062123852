// @flow
import React from "react";
import AttributeLabel from "components/ExtraAttributes/AttributeLabel";
import ViewerControlBase from "components/ExtraAttributes/ViewerControl/ViewerControlBase";

type Props = {
    name: string,
    header?: string,
    value?: any | string,
    width?: number,
    height?: number,
    className?: string,
};

export const defaultFallbackImage =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

const ImageViewerControl = ({
    name,
    header,
    value,
    width,
    height,
    className,
}: Props) => {
    const heightStyle = height == null || height === 0 ? "auto" : `${height}px`;
    const widthStyle = width == null || width === 0 ? "auto" : `${width}px`;
    const parsedValue = value
        ? Object.prototype.hasOwnProperty.call(value, "src")
            ? value.src
            : value
        : defaultFallbackImage;
    return (
        <ViewerControlBase>
            <AttributeLabel label={header} />{" "}
            <img
                src={parsedValue}
                alt={name}
                className={className}
                style={{
                    width: widthStyle,
                    height: heightStyle,
                    border: "1px solid #ededed",
                    objectFit: "cover",
                    minWidth: "24px",
                    minHeight: "24px",
                }}
            />
        </ViewerControlBase>
    );
};
ImageViewerControl.displayName = "ImageViewerControl";
export default ImageViewerControl;
