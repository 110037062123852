//@flow
import { useMemo, useCallback, useState } from "react";
import useUpdateEffect from "react-use/lib/useUpdateEffect";
import { createGlobalState } from "react-use";
import { postMessageTo } from "hooks/PostMessage/Utils";
import {
    PdfHighlightAction,
    type PdfHighlightActionEnum,
} from "hooks/PostMessage/Actions";
import { usePostMessageHandler } from "hooks/PostMessage/Handler";
import {
    ActivityType,
    ItemNoteType,
    type ActivityDTO,
    type IPdfShape,
} from "data/types";

type PdfHighlight = {
    $type: ItemNoteTypeEnum,
    shapes: Array<IPdfShape>,
    activityId: number,
    isReadonly: boolean,
};

const usePdfHighlight = createGlobalState(null);

const usePdfHighlightActions = () => {
    const [target] = usePdfHighlight();
    const createPostMessage = useCallback(
        (action: PdfHighlightActionEnum) => (payload: any) => {
            if (target == null) {
                console.warn(`Target is null, ${action} will not be executed!`);
                return;
            }
            postMessageTo(action, payload, target);
        },
        [target]
    );
    return useMemo(
        () => ({
            handleLoad: createPostMessage(PdfHighlightAction.Load),
            handleUpsert: createPostMessage(PdfHighlightAction.Upsert),
            handleHighlight: createPostMessage(PdfHighlightAction.Highlight),
            handleDelete: createPostMessage(PdfHighlightAction.Delete),
            handleSelect: createPostMessage(PdfHighlightAction.Select),
        }),
        [createPostMessage]
    );
};

const usePdfHighlightActivityList = (
    items: Array<ActivityDTO>,
    userId: number,
    setEdit: (activityId: number) => void,
    onAction: (action: string, itemIndex: number) => void,
    isReadOnly: boolean = false
) => {
    const [highlight, setHighlight] = useState(null);
    const actions = usePdfHighlightActions();

    // Handle UI input from PDF
    usePostMessageHandler(
        useMemo(() => {
            const messageHandler = {
                [PdfHighlightAction.Highlight]: (pdfHighlight: PdfHighlight) =>
                    setHighlight(pdfHighlight.activityId),
            };

            if (!isReadOnly) {
                messageHandler[PdfHighlightAction.Upsert] = (
                    pdfHighlight: PdfHighlight
                ) => setEdit(pdfHighlight.activityId);
                messageHandler[PdfHighlightAction.Delete] = (
                    pdfHighlight: PdfHighlight
                ) =>
                    onAction(
                        "delete_note",
                        items.findIndex(
                            (item) =>
                                item.what === ActivityType.comment &&
                                item.id === pdfHighlight.activityId
                        )
                    );
            }

            return messageHandler;
        }, [setEdit, onAction, items, isReadOnly])
    );

    // Update PDF when items change
    useUpdateEffect(() => {
        const pdfHighlights: Array<PdfHighlight> = items
            .filter(
                (item) =>
                    item.extras &&
                    item.extras.$type === ItemNoteType.PdfHighlight
            )
            .map((item) => ({
                activityId: item.id,
                ...item.extras,
                isReadonly: userId !== item.userId,
            }));
        actions.handleLoad(pdfHighlights);
        return () => actions.handleLoad([]);
    }, [items, actions, userId]);

    // Create handleHightlicht Callback Factory for ActivityDTOs
    const handleHighlight = useCallback(
        (item: ActivityDTO) => {
            const targetHighlight =
                item &&
                item.extras &&
                item.extras.$type === ItemNoteType.PdfHighlight
                    ? item.id
                    : null;
            actions.handleHighlight({ activityId: targetHighlight });
            setHighlight(targetHighlight);
        },
        [actions]
    );

    return useMemo(
        () => [highlight, handleHighlight],
        [highlight, handleHighlight]
    );
};

const usePdfHighlightInput = (handleNoteExtras, noteRef) => {
    usePostMessageHandler(
        useMemo(
            () => ({
                [PdfHighlightAction.Select]: (pdfHighlight: PdfHighlight) => {
                    /* istanbul ignore else */
                    if (
                        pdfHighlight != null &&
                        noteRef.current &&
                        noteRef.current.clearableInput
                    ) {
                        noteRef.current.clearableInput.scrollIntoView({
                            block: "center",
                            inline: "center",
                        });
                        noteRef.current.clearableInput.focus();
                    }
                    handleNoteExtras(pdfHighlight);
                },
            }),
            [handleNoteExtras]
        )
    );
    return usePdfHighlightActions();
};

export {
    usePdfHighlight,
    usePdfHighlightActions,
    usePdfHighlightActivityList,
    usePdfHighlightInput,
};
