// @flow
import React, { useMemo, useCallback } from "react";
import { useAsyncMemo } from "hooks";
import ActionPanel from "components/ActionPanel";
import { createModal } from "react-modal-promise";
import { fetchItemContexts } from "data/api";
import {
    type ItemActionConfig,
    type ItemActionCategoryConfig,
    ItemContextsFlags,
} from "data/types";
import CommandActionHandler from "components/CommandActions";
import { asyncFilter } from "data/utils";
import { mergeItemActionsConfig, shouldRenderItem } from "./Utils";
import { produce } from "immer";

const getContextProps = async (
    contextProps: any,
    itemContexts = ItemContextsFlags.ResultFlags |
        ItemContextsFlags.Offline |
        ItemContextsFlags.Pdf |
        ItemContextsFlags.ItemStatus
) => {
    const { itemUri, formatId, name, multiple, items, hasWorkflow } =
        contextProps;
    /* istanbul ignore else */
    if (name == null) {
        itemContexts |= ItemContextsFlags.WebDavName;
    }
    /* istanbul ignore else */
    if (hasWorkflow == null) {
        itemContexts |= ItemContextsFlags.Workflow;
    }
    const itemUris = multiple ? items.map((i) => i.itemUri) : [itemUri];
    let formatIds = multiple ? items.map((i) => i.formatId) : [formatId];
    if (formatIds.filter((i) => i != null).length === 0) {
        formatIds = null;
    }
    return await fetchItemContexts(itemUris, formatIds, itemContexts);
};

type Props = {
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog */
    close: () => void,
    /** React Component to render as Title */
    title?: React.Node,
    /** React Component to render as body */
    body?: React.Node,
    /** any context props passed by caller will be assigned to commandAction props */
    contextProps: {
        itemUri: string,
        formatId: number,
        multiple: boolean,
        items: Array<any>,
    } & Object,
    /** Override view context for Action Panel items */
    targetView?: string,
};

export const ActionPanelContainer = (props: Props) => {
    const { contextProps, targetView, ...rest } = props;

    // fetch context and extend contextProps
    const allContextProps = useAsyncMemo(async () => {
        try {
            return {
                ...contextProps,
                ...(await getContextProps(contextProps)),
            };
        } catch (e) {
            console.error(`Cannot calculate contextProps: ${e.message}`);
            return contextProps;
        }
    }, [contextProps]);

    const [itemUri, formatId] = useMemo(() => {
        if (allContextProps == null) {
            return [null, -1];
        }
        const { itemUri, folderItemUri, formatId, multiple, items } =
            allContextProps;
        if (!multiple) {
            return [itemUri, formatId];
        }
        const combinedFormatId = items.every(
            (item) => item.formatId === items[0].formatId
        )
            ? items[0].formatId
            : -1;
        return [folderItemUri, combinedFormatId];
    }, [allContextProps]);

    const { categories: categoriesFromConfig, actions: actionsFromConfig } =
        useMemo(
            () => mergeItemActionsConfig(itemUri, formatId),
            [itemUri, formatId]
        );

    const categories: Array<ItemActionCategoryConfig> = useAsyncMemo(
        async () =>
            await asyncFilter(
                categoriesFromConfig,
                (category: ItemActionCategoryConfig) =>
                    shouldRenderItem(
                        category,
                        itemUri,
                        formatId,
                        allContextProps,
                        targetView
                    )
            ),
        [categoriesFromConfig, itemUri, formatId, allContextProps, targetView]
    );

    const actions: Array<ItemActionConfig> = useAsyncMemo(async () => {
        const result = produce(
            await asyncFilter(actionsFromConfig, (action: ItemActionConfig) =>
                shouldRenderItem(
                    action,
                    itemUri,
                    formatId,
                    allContextProps,
                    targetView
                )
            ),
            (draft) => {
                // HACK itemViews_* use the CommandActionHandler to RENDER before onAction is being called!
                draft.forEach(
                    (action) =>
                        action.commandAction &&
                        (action.commandAction.props = Object.assign(
                            {},
                            allContextProps,
                            action.commandAction.props
                        ))
                );
            }
        );

        return result;
    }, [actionsFromConfig, itemUri, formatId, allContextProps, targetView]);

    const onAction = useCallback(
        (action: ItemActionConfig) => {
            if (action.commandAction == null) {
                alert(
                    `Sorry, no CommandAction defined for action.name='${action.name}'`
                );
            } else {
                CommandActionHandler(
                    action.commandAction.name,
                    produce({}, (draft) => {
                        draft = Object.assign(
                            draft,
                            allContextProps,
                            action.commandAction.props
                        );
                    })
                );
            }
        },
        [allContextProps]
    );

    return (
        <ActionPanel
            isLoading={allContextProps == null}
            {...rest}
            actions={actions}
            categories={categories}
            onAction={onAction}
        />
    );
};
/* istanbul ignore next */
const MemoActionPanelContainer = React.memo(
    ActionPanelContainer,
    (prevProps, nextProps) => prevProps.itemUri !== nextProps.itemUri
);
export const openActionPanel = createModal(MemoActionPanelContainer);
