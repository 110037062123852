// @flow
import React, { useMemo, useCallback, useContext } from "react";
import { MultiSelectionContext } from "data/context";
import Icons from "@hs/icons";
import ItemStatusIcons from "components/ItemStatusIcons";
import Attributes from "components/Attributes";
import {
    GridListConstant,
    type DocColumnResultDTO,
    type docFilterText,
    DocListColumnType,
} from "data/types";
// import { shortItemUri } from "components/ItemUriLink";
import Thumbnail from "./Thumbnail";
import Mark from "@hs/mark";
import ValueFactory from "components/Attributes/SummaryAttribute/ValueFactory";
import { getClassNames, getValue } from "data/utils";
import { isHandheld } from "data/bowser";
import withStickyToggle from "containers/StickyToggle";
import StickyToggleStyle from "containers/StickyToggle/style";
import styles from "./RowResult.module.css";

type Props = {
    /** CSS styles to apply */
    style?: StyleSheet,
    /** the row object */
    row: Object,
    /** row index */
    index: number,
    /** all the columns */
    cols: Array<DocColumnResultDTO>,
    /** whether row should be marked as selected */
    isSelected: boolean,
    /** callback when user clicks document icon
     * @param {SyntheticMouseEvent} e HTML click event
     */
    onIconClick?: (e: SyntheticMouseEvent<*>) => void,
    /** callback when user single clicks the whole row
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onSingleClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    /** callback when user double clicks the whole row
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onDoubleClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    /** callback when user clicks on More... Button
     * @param {SyntheticMouseEvent} e HTML click event
     * @param {string} itemUri the row's itemUri
     * @param {number} index the row's index
     */
    onMoreClick?: (
        e: SyntheticMouseEvent,
        itemUri: string,
        index: number
    ) => void,
    /** whether to disable the More... button */
    actionsDisabled?: boolean,
    /** callback when row has been rendered (and HTML loaded) */
    onLoad?: () => void,
    /** whether to show thumbnails or just file types' icons */
    showThumbnail: boolean,
    /** quick filter details */
    filterText: ?docFilterText,
};

/**
 * Render one document row in "Simple" layout mode
 */
export const RowResult = (props: Props) => {
    const {
        className,
        style,
        row,
        index,
        cols,
        isSelected,
        isHovered,
        filterText,
        onIconClick,
        onSingleClick,
        onDoubleClick,
        onMoreClick,
        onMouseEnter,
        onMouseLeave,
        onLoad,
        showThumbnail,
        divRef,
        onTouchStart,
        onTouchMove,
        onTouchEnd,
        onMouseDown,
        onMouseUp,
        onContextMenu,
    } = props;

    /* istanbul ignore next */
    const {
        multiple,
        renderCheckbox,
        isSelected: isMultiSelected,
    } = useContext(MultiSelectionContext) || {
        multiple: false,
        renderCheckbox: () => null,
        isSelected: (index: number) => false,
    };

    const itemUri = useMemo(() => row[row.length - 1], [row]);

    const handleSingleClick = useCallback(
        (e: SyntheticMouseEvent<*>) => {
            e.stopPropagation();
            /* istanbul ignore else */
            if (typeof onSingleClick === "function")
                onSingleClick(e, itemUri, index);
        },
        [itemUri, index, onSingleClick]
    );

    const handleDoubleClick = useCallback(
        (e: SyntheticMouseEvent<*>) => {
            e.stopPropagation();
            /* istanbul ignore else */
            if (typeof onDoubleClick === "function")
                onDoubleClick(e, itemUri, index);
        },
        [itemUri, index, onDoubleClick]
    );

    const handleIconClick = useCallback(
        (e: SyntheticMouseEvent<*>) => {
            e.stopPropagation();
            /* istanbul ignore else */
            if (typeof onIconClick === "function")
                onIconClick(e, itemUri, index);
        },
        [itemUri, index, onIconClick]
    );

    const renderName = useCallback(() => {
        let nameColIndex = cols.findIndex(
            (col) => col.type === DocListColumnType.WebDavName
        );
        // FlatAUs do not have any Name columns
        if (nameColIndex === -1) nameColIndex = 1;

        const col = cols[nameColIndex];

        let name = row[nameColIndex];
        if (typeof name !== "string") {
            name = (
                <ValueFactory
                    value={name}
                    className={`a-val ${name?.$type ?? "simple"}--list`}
                />
            );
        }

        if (
            filterText &&
            filterText.name &&
            filterText.value &&
            filterText.name.toUpperCase() === col.name.toUpperCase()
        ) {
            return <Mark word={filterText.value}>{name}</Mark>;
        } else return name;
    }, [row, cols, filterText]);

    const extensionColIndex = useMemo(
        () =>
            cols.findIndex(
                (col) => col.name === GridListConstant.ExtensionIcon
            ),
        [cols]
    );
    const statusColIndex = useMemo(
        () =>
            cols.findIndex(
                (col) => col.name === GridListConstant.IconItemStatus
            ),
        [cols]
    );
    const sizeColIndex = useMemo(
        () => cols.findIndex((col) => col.type === DocListColumnType.FileSize),
        [cols]
    );

    const size = useMemo(
        () => getValue(row[sizeColIndex]),
        [row, sizeColIndex]
    );
    const attributes = useMemo(
        () =>
            cols.reduce((accumulator, col, idx, arr) => {
                const val = row[idx];
                if (
                    ![
                        GridListConstant.ExtensionIcon.toUpperCase(),
                        GridListConstant.IconItemStatus.toUpperCase(),
                        GridListConstant.ItemUri.toUpperCase(),
                    ].includes(col.name.toUpperCase()) &&
                    col.type !== DocListColumnType.WebDavName &&
                    col.type !== DocListColumnType.FileSize &&
                    val != null
                ) {
                    if (typeof val === "string") {
                        accumulator.push([col.caption, val]);
                    } else {
                        accumulator.push({
                            caption: col.caption,
                            value: val,
                        });
                    }
                }

                return accumulator;
            }, []),
        [cols, row]
    );
    const combinedClassNames = getClassNames(
        className,
        styles.row,
        multiple
            ? isMultiSelected(index)
                ? styles.active
                : null
            : isSelected
            ? styles.active
            : null,
        isHovered ? styles.hovered : null,
        showThumbnail ? styles.thumbnail : null,
        isHandheld() ? (multiple ? styles.multi : null) : styles.multi
    );
    return (
        <div
            className={combinedClassNames}
            ref={divRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={style}
            onClick={handleSingleClick}
            onDoubleClick={handleDoubleClick}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onContextMenu={onContextMenu}
        >
            {(multiple || (isHovered && !isHandheld())) &&
                renderCheckbox(index, styles.checkbox)}
            <div className={styles.ext}>
                {showThumbnail && (
                    <Thumbnail
                        itemUri={itemUri}
                        onLoad={onLoad}
                        onClick={handleIconClick}
                    />
                )}
                {!showThumbnail && (
                    <Icons.Custom
                        ext={
                            row[extensionColIndex]?.value ||
                            row[extensionColIndex]
                        }
                        icon={row[extensionColIndex]?.icon}
                        color={row[extensionColIndex]?.color}
                        className={getClassNames(styles.icon, "fa-fw")}
                        onClick={handleIconClick}
                        data-test={`fileExt${
                            row[extensionColIndex]?.value ||
                            row[extensionColIndex]
                        }`}
                    />
                )}
            </div>
            <div className={styles.below}>
                {sizeColIndex !== -1 && size !== "0 B" && size + " "}
                {showThumbnail && (
                    <Icons.Custom
                        ext={
                            row[extensionColIndex]?.value ||
                            row[extensionColIndex]
                        }
                        icon={row[extensionColIndex]?.icon}
                        color={row[extensionColIndex]?.color}
                        className={styles.icon}
                        data-test={`fileExt${
                            row[extensionColIndex]?.value ||
                            row[extensionColIndex]
                        }`}
                    />
                )}
                <ItemStatusIcons
                    itemUri={itemUri}
                    itemStatus={row[statusColIndex]}
                    onMoreClick={onMoreClick}
                    style={{
                        verticalAlign: "middle",
                    }}
                />
            </div>
            <div className={styles.name}>{renderName()}</div>
            <Attributes className={styles.attrs} attributes={attributes} />
        </div>
    );
};
RowResult.displayName = "RowResult";
RowResult.defaultProps = {
    isSelected: false,
    showThumbnail: false,
};

const RowResultWithStickyToggle = withStickyToggle({
    renderDiv: false,
    usePortal: false,
    getStyle: StickyToggleStyle.List,
    isActiveProp: "isSelected",
})(RowResult);

export default RowResultWithStickyToggle;
