// @flow
import React from "react";
import { type ListViewModeEnum } from "data/types";
import Dropdown from "@hs/dropdown";
import MenuItem from "react-bootstrap/lib/MenuItem";
import Icons from "@hs/icons";
import loc from "i18next";
import { ViewModeIcon } from "./ViewModes";

type Props = {
    /** current selected viewMode */
    viewMode: ListViewModeEnum,
    /** all available viewModes to render */
    viewModes?: Array<ListViewModeEnum>,
    /** callback when user changes viewMode
     * @param {ListViewModeEnum} viewMode the viewMode the user chose
     */
    onChange: (viewMode: ListViewModeEnum) => void,
    /** options CSS class name to apply */
    className?: string,
};

/** render a Dropdownlist with requested viewModes marking the currently active viewMode */
const ViewModesMenu = ({ viewMode, viewModes, onChange, className }: Props) => (
    <Dropdown bsSize="small" id="viewModes" className={className}>
        <Dropdown.Toggle chrome title={loc.t("common:view.menuCaption")}>
            <Icons.Library name={ViewModeIcon[viewMode]} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {viewModes.map((vm) => (
                <MenuItem
                    key={`vm-${vm}`}
                    active={viewMode === vm}
                    onSelect={/* istanbul ignore next */ () => onChange(vm)}
                >
                    <Icons.Library
                        name={ViewModeIcon[vm]}
                        style={{ marginRight: 5 }}
                    />
                    {loc.t(`common:view.${vm}`)}
                </MenuItem>
            ))}
        </Dropdown.Menu>
    </Dropdown>
);

ViewModesMenu.displayName = "ViewModesMenu";
export default ViewModesMenu;
