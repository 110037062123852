// @flow
import React, { useCallback, useMemo } from "react";
import Badge from "react-bootstrap/lib/Badge";
import Icons from "@hs/icons";
import loc from "i18next";
import Mark from "@hs/mark";
import { type TreeItemDTO } from "data/types";
import Tree from "components/Tree";
import styles from "./FolderTree.module.css";
import { BrowserDragLink } from "components/ItemUriLink";
import { getLocalizedText, isValidItemUri } from "data/utils";
import { getClassNames } from "data/utils";

type Props = {
    /** current node id to mark as active */
    activeTreeNodeId: number,
    /** tree items */
    tree: Array<TreeItemDTO>,
    /** callback when user clicks a tree item
     * @param {TreeItemDTO} treeItem tree item's object the user clicked
     * @param {SyntheticMouseEvent<*>} e HTML event object
     */
    onClick: (treeItem: TreeItemDTO, e: SyntheticMouseEvent<*>) => void,
    /** callback when user double-click a tree item
     * @param {TreeItemDTO} treeItem tree item's object the user clicked
     */
    onDoubleClick: (treeItem: TreeItemDTO) => void,
    /** callback when user expands a tree item
     * @param {TreeItemDTO} treeItem tree item's object the user clicked
     * @param {SyntheticMouseEvent<*>} e HTML event object
     */
    onExpand: (treeItem: TreeItemDTO, e: SyntheticMouseEvent<*>) => void,
    /** callback when user wants to load more results */
    onLoadMore: () => void,
    /** filter text currently applied */
    filter?: string,
    /** optional NoData Content Component */
    NoDataContent: Element<any>,
    /** total number of results available */
    totalCount: number,
    /** whether list is currently focused
     * @default {boolean} true
     */
    focused?: boolean,
};

const FolderTree = ({
    activeTreeNodeId,
    tree,
    onClick,
    onDoubleClick,
    onExpand,
    onLoadMore,
    filter,
    NoDataContent,
    totalCount,
    focused = true,
}: Props) => {
    const _hasMore = useMemo(
        () => tree && totalCount > tree.length,
        [totalCount, tree]
    );

    const _renderRow = useCallback(
        ({
            key, // Unique key within array of rows
            index, // Index of row within collection
            isScrolling, // The List is currently being scrolled
            isVisible, // This row is visible within the List (eg it is not an overscanned row)
            style, // Style object to be applied to row (to position it)
        }: {
            key: number,
            index: number,
            isScrolling: boolean,
            isVisible: boolean,
            style: StyleSheet,
        }) => {
            if (_hasMore && index === tree.length) {
                return (
                    <div
                        className={[styles.listGroupItem, styles.hasMore].join(
                            " "
                        )}
                        key="TREE$MORE"
                        onClick={onLoadMore}
                        style={style}
                    >
                        {loc.t("folder:treefilter.loadmore")}
                        {"  "}
                        <Icons.Library name="forward-step" />
                    </div>
                );
            }
            const treeItem = tree[index];
            /* istanbul ignore next */
            const localizedWebDavName = isValidItemUri(treeItem.itemUri)
                ? treeItem.webDavName
                : getLocalizedText(treeItem.webDavName);
            return (
                <div
                    className={getClassNames(
                        styles.listGroupItem,
                        activeTreeNodeId === treeItem.id
                            ? styles.listGroupItemActive
                            : ""
                    )}
                    key={treeItem.id}
                    style={style}
                    title={treeItem.bc || ""}
                    onDoubleClick={
                        /* istanbul ignore next */ (e) =>
                            onDoubleClick(treeItem, e)
                    }
                    onClick={
                        /* istanbul ignore next */ (e) => onClick(treeItem, e)
                    }
                    data-test="folderTreeRow"
                >
                    <span
                        className={styles.listLeft}
                        onClick={
                            /* istanbul ignore next */ (e) =>
                                onExpand(treeItem, e)
                        }
                    >
                        {treeItem.numberOfChildren > 0 && (
                            <Icons.Library
                                className={getClassNames(styles.expand)}
                                name="caret-right"
                            />
                        )}
                        {treeItem.numberOfChildren === 0 && (
                            <span className={styles.noexpand} />
                        )}
                        <BrowserDragLink itemUri={treeItem.itemUri + ""}>
                            <Icons.Custom
                                elementType={treeItem.elementType}
                                icon={treeItem.icon}
                                color={treeItem.color}
                                className={getClassNames(
                                    styles.elementIcon,
                                    "fa-fw"
                                )}
                            />
                        </BrowserDragLink>
                    </span>
                    <Mark word={filter} className={styles.listName}>
                        {localizedWebDavName}
                    </Mark>
                    {treeItem.numberOfChildren > 0 && (
                        <Badge
                            className={[styles.treeCount, "hidden-xs"].join(
                                " "
                            )}
                            title={loc.format(
                                treeItem.numberOfChildren,
                                "pretty"
                            )}
                        >
                            {loc.format(treeItem.numberOfChildren, "pretty")}
                        </Badge>
                    )}
                </div>
            );
        },
        [
            tree,
            onClick,
            onDoubleClick,
            onExpand,
            onLoadMore,
            filter,
            activeTreeNodeId,
            _hasMore,
        ]
    );

    const _getActiveTreeNodeIndex = useCallback(
        () =>
            tree ? tree.findIndex((row) => row.id === activeTreeNodeId) : -1,
        [tree, activeTreeNodeId]
    );

    return (
        <Tree
            className={focused === false ? styles.unfocused : ""}
            filter={filter}
            activeTreeNodeId={activeTreeNodeId}
            scrollToIndex={_getActiveTreeNodeIndex()}
            totalItems={
                /* istanbul ignore next */ tree
                    ? tree.length + (_hasMore ? 1 : 0)
                    : 0
            }
            renderRow={_renderRow}
            locContext="folder"
            NoDataContent={NoDataContent}
        />
    );
};

export default FolderTree;
