// @flow
import React, { useMemo } from "react";
import AttributeLabel from "components/ExtraAttributes/AttributeLabel";
import ViewerControlBase from "components/ExtraAttributes/ViewerControl/ViewerControlBase";
import { getValue } from "data/utils";
import sharedStyles from "shared/styles.module.css";

type Props = {
    name: string,
    header?: string,
    value: any | string,
    width?: number,
};

const StaticViewerControl = ({ name, header, value, width }: Props) => {
    // $FlowFixMe
    let style: CSSStyleDeclaration = {};
    if (width && width > 0) style.width = width * 1.3 + "px";
    else style.minWidth = "100px";

    // replace carriage returns and some basic html safety
    const htmlValue = useMemo(() => {
        const v = getValue(value);
        return (
            (v
                ? String(v)
                      .replace(/</g, "&lt;")
                      .replace(/>/g, "&gt;")
                      .replace(/\n/g, "<br/>")
                : "") + "&nbsp;"
        );
    }, [value]);

    return (
        <ViewerControlBase>
            <AttributeLabel label={header} />
            <p
                className={`form-control form-control-static ${sharedStyles.select_text}`}
                style={style}
                dangerouslySetInnerHTML={{ __html: htmlValue }}
            />
        </ViewerControlBase>
    );
};

StaticViewerControl.displayName = "StaticViewerControl";
export default StaticViewerControl;
