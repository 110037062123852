import actionTypes from "../actions/actionTypes";
import { type CommandActionRequestDTO } from "data/types";
import { produce } from "immer";

export const initialState: Array<CommandActionRequestDTO> = [];

const commandActionReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        const { payload, type } = action;
        const i = state.findIndex((i) => i.guid === payload?.guid);

        switch (type) {
            case actionTypes.COMMANDACTION_REQUEST:
                draft.push(payload);
                break;
            case actionTypes.COMMANDACTION_REMOVE:
                if (i > -1) draft.splice(i, 1);
                else
                    console.warn(
                        `Not found. Cannot delete CommandAction ${payload.guid}`
                    );
                break;
            case actionTypes.COMMANDACTION_LOG:
                if (i > -1) {
                    if (!Array.isArray(draft[i].log)) draft[i].log = [];
                    draft[i].log.unshift(payload.log);
                } else
                    console.warn(
                        `Not found. Cannot log to CommandAction ${payload.guid}`
                    );
                break;
            case actionTypes.COMMANDACTION_STATUS:
                if (i > -1)
                    draft[i].status = payload.replace
                        ? payload.status
                        : draft[i].status | payload.status;
                else
                    console.warn(
                        `Not found. Cannot update status on CommandAction ${payload.guid}`
                    );
                break;
            case actionTypes.COMMANDACTION_ITEM_STATUS:
                if (i === -1) {
                    console.warn(
                        `GUID not found. Cannot update item status on CommandAction ${payload.guid}`
                    );
                    return;
                }
                const j = draft[i].items.findIndex(
                    (i) =>
                        i.itemUri === payload?.itemUri ||
                        (i.itemUri == null && payload?.itemUri == null)
                );
                if (j > -1) {
                    draft[i].items[j].status = payload.status;
                } else
                    console.warn(
                        `Item not found. Cannot update item status on CommandAction ${payload.guid}`
                    );
                break;
            /* istanbul ignore next */
            case actionTypes.PERSIST_PURGE:
                return initialState;
            default:
                break;
        }
    });

export default commandActionReducer;

export const commandActionRequestSelector = (
    state: Any,
    guid: GUID = null
): Array<CommandActionRequest> =>
    state.commandAction?.filter((i) => guid == null || i.guid === guid) ?? [];

export const commandActionRequestItemSelector = (
    state: Any,
    guid: GUID,
    itemUri: string = null
): Array<CommandActionItem> =>
    commandActionRequestSelector(state, guid)?.[0]?.items?.filter(
        (i) => itemUri == null || i.itemUri === itemUri
    ) ?? [];
