// @flow
import React from "react";
import TextEditorControl from "components/ExtraAttributes/EditorControl/TextEditorControl";
import HiddenEditorControl from "components/ExtraAttributes/EditorControl/HiddenEditorControl";
import MemoEditorControl from "components/ExtraAttributes/EditorControl/MemoEditorControl";
import NumberEditorControl from "components/ExtraAttributes/EditorControl/NumberEditorControl";
import LookupEditorControl from "components/ExtraAttributes/EditorControl/LookupEditorControl";
import StaticViewerControl from "components/ExtraAttributes/ViewerControl/StaticViewerControl";
import UserIdViewerControl from "components/ExtraAttributes/ViewerControl/UserIdViewerControl";
import ImageEditorControl from "components/ExtraAttributes/EditorControl/ImageEditorControl";
import DateEditorControl from "components/ExtraAttributes/EditorControl/DateEditorControl";
import GeoPointEditorControl from "components/ExtraAttributes/EditorControl/LoadableGeoPointEditorControl";
import GeoAreaEditorControl from "components/ExtraAttributes/EditorControl/GeoAreaEditorControl";
import GeoRasterEditorControl from "components/ExtraAttributes/EditorControl/GeoRasterEditorControl";
import SummaryAttributeViewerControl from "components/ExtraAttributes/ViewerControl/SummaryAttributeViewerControl";
import { type FormFieldDTO, FormLookupType } from "data/types";
import loc from "i18next";
import {
    GeoPointValidationRegex,
    GeoAreaValidationRegex,
} from "components/MapViewer/utils";
import { DateTimeFormat } from "data/types";

function findCustomField(
    fields: Array<FormFieldDTO>,
    name: string
): FormFieldDTO | void {
    return fields.find((f) => f.format === name && f.name === name);
    /*for (var i = 0; i < fields.length; i++) {
        if (fields[i].format === name && fields[i].name === name)
            return fields[i];
    }
    return undefined;*/
}

function getValidationErrors(field: FormFieldDTO, customErrors?: Object) {
    let validationErrors = customErrors || {};
    if (field.req)
        validationErrors.isDefaultRequiredValue = loc.t(
            "common:validation.required"
        );
    return validationErrors;
}

const FieldEdit = ({
    isDoc,
    itemUri,
    formatId,
    field,
    fields,
    onChange,
}: {
    isDoc: boolean,
    itemUri: string,
    formatId: number,
    field: FormFieldDTO,
    fields: Array<FormFieldDTO>,
    onChange: Function,
}) => {
    const formPrefix = "ip.";
    const header = field.header || field.name;
    if (field.ro && field.extra?.summary)
        return (
            <SummaryAttributeViewerControl
                header={header}
                name={field.name}
                values={field.extra?.summary}
            />
        );
    //console.dir(formAttribute);
    switch (field.format) {
        case "None":
        case undefined:
            return null;

        case "Image":
            return (
                <ImageEditorControl
                    itemUri={itemUri}
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    width={field.width}
                    onChange={onChange}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validationErrors={getValidationErrors(field)}
                />
            );

        case "CreatorName":
            const creatorIdField = findCustomField(fields, "CreatorId");
            /* istanbul ignore if */
            if (creatorIdField === undefined) return null;
            return (
                <UserIdViewerControl
                    header={header}
                    value={field.value}
                    userid={parseInt(creatorIdField.value, 10)}
                    width={field.width}
                />
            );

        case "ModifiedName":
            const modifiedIdField = findCustomField(fields, "ModifiedId");
            /* istanbul ignore if */
            if (modifiedIdField === undefined) return null;
            return (
                <UserIdViewerControl
                    header={header}
                    value={field.value}
                    userid={parseInt(modifiedIdField.value, 10)}
                    width={field.width}
                />
            );

        case "CreatorId":
        case "ModifiedId":
            // those will not be rendered since they are internally created
            if (field.format === field.name) return null;
            else
                return (
                    <StaticViewerControl
                        name={field.name}
                        header={header}
                        value={field.value}
                        width={field.width}
                    />
                );

        case "Date_Time":
        case "Date_Date":
            if (field.ro) {
                return (
                    <StaticViewerControl
                        name={field.name}
                        header={header}
                        value={field.value}
                    />
                );
            } else {
                return (
                    <DateEditorControl
                        name={formPrefix + field.name}
                        header={header}
                        value={field.value}
                        onChange={onChange}
                        type={
                            field.format === "Date_Time"
                                ? DateTimeFormat.DateTime
                                : DateTimeFormat.Date
                        }
                        isReadonly={field.ro}
                        isRequired={field.req}
                        validations={{ isNotInvalid: true }}
                        validationErrors={getValidationErrors(field, {
                            isNotInvalid: loc.t("common:validate.date"),
                        })}
                    />
                );
            }

        case "Text_Lookup":
        case "Text_LookupRep":
            const value =
                field.value == null || field.value.T == null
                    ? null
                    : field.value;
            return (
                <LookupEditorControl
                    isDoc={isDoc}
                    formatId={formatId}
                    name={formPrefix + field.name}
                    header={header}
                    value={value}
                    lookupType={FormLookupType.ip}
                    onChange={onChange}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validationErrors={getValidationErrors(field)}
                />
            );

        case "Number_IntegerNumber":
            return (
                <NumberEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    decimalScale={0}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validationErrors={getValidationErrors(field)}
                />
            );

        case "Number_DecimalNumberOneDecimal":
            return (
                <NumberEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    decimalScale={1}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validationErrors={getValidationErrors(field)}
                />
            );

        case "Number_DecimalNumberTwoDecimal":
            return (
                <NumberEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    decimalScale={2}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validationErrors={getValidationErrors(field)}
                />
            );

        case "Number_DecimalNumberThreeDecimal":
            return (
                <NumberEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    decimalScale={3}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validationErrors={getValidationErrors(field)}
                />
            );

        case "Number_CurrencyEuroTwoDecimal":
            return (
                <NumberEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    decimalScale={2}
                    currency="€"
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validationErrors={getValidationErrors(field)}
                />
            );

        case "Number_CurrencyDollarTwoDecimal":
            return (
                <NumberEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    decimalScale={2}
                    currency="$"
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validationErrors={getValidationErrors(field)}
                />
            );

        case "Number_CurrencyEuroThreeDecimal":
            return (
                <NumberEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    decimalScale={3}
                    currency="€"
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validationErrors={getValidationErrors(field)}
                />
            );

        case "Number_CurrencyDollarThreeDecimal":
            return (
                <NumberEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    decimalScale={3}
                    currency="$"
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validationErrors={getValidationErrors(field)}
                />
            );

        case "WebDavName":
            // Render hidden field, in case WebDavName is hidden
            if (field.extra && field.extra.hidden === true) {
                return (
                    <HiddenEditorControl
                        name={formPrefix + field.name}
                        value={field.value}
                    />
                );
            }
            return (
                <TextEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validations={{
                        [isDoc ? "isFilename" : "isPathname"]: null,
                        maxLength: field.max,
                    }}
                    validationErrors={getValidationErrors(field, {
                        isFilename: loc.t("common:validation.filename"),
                        isPathname: loc.t("common:validation.pathname"),
                        maxLength: loc.t("common:validation.maxLength", {
                            maxLength: field.max,
                        }),
                    })}
                />
            );

        case "Text_TelNumber":
            return (
                <TextEditorControl
                    inputType="tel"
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validations={{
                        maxLength: field.max,
                    }}
                    validationErrors={getValidationErrors(field, {
                        maxLength: loc.t("common:validation.maxLength", {
                            maxLength: field.max,
                        }),
                    })}
                />
            );

        case "Text_Text":
            return (
                <MemoEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validations={{ maxLength: field.max }}
                    validationErrors={getValidationErrors(field, {
                        maxLength: loc.t("common:validation.maxLength", {
                            maxLength: field.max,
                        }),
                    })}
                />
            );

        case "Text_EMail":
            return (
                <TextEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validations={{
                        maxLength: field.max,
                        isEmail: true,
                    }}
                    validationErrors={getValidationErrors(field, {
                        isEmail: loc.t("common:validation.email"),
                        maxLength: loc.t("common:validation.maxLength", {
                            maxLength: field.max,
                        }),
                    })}
                />
            );

        case "Text_GeoPoint":
            return (
                <GeoPointEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    width={field.width}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validations={{
                        maxLength: field.max,
                        matchRegexp: GeoPointValidationRegex,
                    }}
                    validationErrors={getValidationErrors(field, {
                        matchRegexp: loc.t("common:validation.geoPoint"),
                        maxLength: loc.t("common:validation.maxLength", {
                            maxLength: field.max,
                        }),
                    })}
                    onChange={onChange}
                    showLabel={true}
                    marker={field.extra && field.extra.marker}
                />
            );

        case "Text_GeoArea":
            return (
                <GeoAreaEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    width={field.width}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validations={{
                        maxLength: field.max,
                        matchRegexp: GeoAreaValidationRegex,
                    }}
                    validationErrors={getValidationErrors(field, {
                        matchRegexp: loc.t("common:validation.geoArea"),
                        maxLength: loc.t("common:validation.maxLength", {
                            maxLength: field.max,
                        }),
                    })}
                    onChange={onChange}
                    showLabel={true}
                />
            );

        case "Text_GeoRaster":
            return (
                <GeoRasterEditorControl
                    itemUri={itemUri}
                    formatId={formatId}
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    width={field.width}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    onChange={onChange}
                    showLabel={true}
                    marker={field.extra && field.extra.marker}
                />
            );
        case "Text_Link":
            return (
                <TextEditorControl
                    name={formPrefix + field.name}
                    header={header}
                    value={field.value}
                    onChange={onChange}
                    isReadonly={field.ro}
                    isRequired={field.req}
                    validations={{
                        maxLength: field.max,
                    }}
                    validationErrors={getValidationErrors(field, {
                        maxLength: loc.t("common:validation.maxLength", {
                            maxLength: field.max,
                        }),
                    })}
                />
            );

        default:
            return (
                <StaticViewerControl
                    name={field.name}
                    header={header}
                    value={field.value}
                    width={field.width}
                />
            );
    }
};

export default FieldEdit;
