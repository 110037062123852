import { openConfirmModal } from "components/ConfirmModal";
import loc from "i18next";

export const openConflictModal = (name: string): Promise<boolean> =>
    openConfirmModal({
        title: loc.t("common:conflict.title", {
            name,
        }),
        body: loc.t("common:conflict.message"),
        confirmButton: loc.t("common:conflict.overwrite"),
        cancelButton: loc.t("common:conflict.cancel"),
        requireSelection: true,
    });
