// @flow
// import { SearchItemDTO } from "data/types";

// type BarcodeConfig = {
//     description: string,
//     matchRegexp: RegExp,
//     searchItem: SearchItemDTO
// };

// const barcodes: Array<BarcodeConfig> = [
//     {
//         description:
//             "by Freetext: will retrieve k code from a SolothurnURL e.g. https://geo.so.ch/map/?k=cde94a977",
//         matchRegexp: /k=(.*)/gm,
//         searchItem: {
//             name: "QR location search",
//             searchType: 2, //SearchItemType.Freetext
//             itemUri: "pam-item://hierarchy=51@path=78$-7\\,82$-8812\\,83$-8813",
//             scope: 2, //SearchScope.CurrentFolder
//             filters: [
//                 // {
//                 //     name: "sys.itemtype",
//                 //     value: "Folder"
//                 // },
//                 {
//                     name: "KCode",
//                     valueMatchGroup: 1,
//                     op: 8, //SearchOperator.Equals,
//                     valueType: "String", //SearchValueType.String
//                     nodeType: 0 //SearchNodeType.AllNodes
//                 }
//             ]
//         }
//     }
//     // {
//     //     description:
//     //         "by SearchForm: will retrieve k code from a SolothurnURL e.g. https://geo.so.ch/map/?k=cde94a977",
//     //     matchRegexp: /k=(.*)/gm,
//     //     searchItem: {
//     //         pageNum: 0,
//     //         id: 9410, //"Barcode - Solothurn"
//     //         // name: "QR location search",
//     //         searchType: 3, //SearchItemType.Form
//     //         filters: [
//     //             // {
//     //             //     name: "sys.itemtype",
//     //             //     value: "Folder"
//     //             // },
//     //             {
//     //                 name: "KCode",
//     //                 op: 8,//SearchOperator.Equals,
//     //                 valueType: "String", //SearchValueType.String
//     //                 nodeType: 0, //SearchNodeType.AllNodes
//     //                 matchGroup: 1
//     //             }
//     //         ]
//     //     }
//     // }
// ];

// export /**
//  * tries to match a barcode configuration and returns filters to set
//  *
//  * @param {{ code: string }} { code } barcode scanned
//  * @returns {{ barcode: BarcodeConfig, filters: Object }}
//  */
// const findBarcodeConfig = ({ code }: { code: string }) => {
//     let i = 0;
//     // loop through all barcode configs
//     while (i < barcodes.length) {
//         let barcode = barcodes[i++];
//         let m;
//         // find all matches
//         while ((m = barcode.matchRegexp.exec(code)) !== null) {
//             // This is necessary to avoid infinite loops with zero-width matches
//             if (m.index === barcode.matchRegexp.lastIndex) {
//                 barcode.matchRegexp.lastIndex++;
//             }
//             // anything found?
//             if (m.length > 0) {
//                 const filters = [];
//                 // let's build a filter list searchItemType.FreeText
//                 // eslint-disable-next-line
//                 barcode.searchItem.filters.forEach(filter => {
//                     if (filter.value) filters.push(filter);
//                     else if (filter.valueMatchGroup)
//                         filters.push(
//                             Object.assign(
//                                 {},
//                                 filter,
//                                 { valueMatchGroup: null },
//                                 { value: m[filter.valueMatchGroup] }
//                             )
//                         );
//                 });
//                 return {
//                     barcode,
//                     filters
//                 };
//             }
//         }
//     }
//     return null;
// };

export const getBarcodeMatch = ({
    code,
    matchRegexp,
}: {
    code: string,
    matchRegexp: string,
}): ?string => {
    let m;
    const re = new RegExp(matchRegexp, "gmi");
    // find all matches
    while ((m = re.exec(code)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        /* istanbul ignore if */
        if (m.index === re.lastIndex) {
            re.lastIndex++;
        }
        // anything found?
        /* istanbul ignore else */
        if (m.length > 1) {
            return m[1];
        }
    }
    return null;
};
