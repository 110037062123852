import { toggleFavorite } from "containers/Breadcrumb";

/**
 * toggles favorite flag for the given itemUri
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ isFav: boolean}} { isFav }
 * @param {{ name: string}} { name }
 */

type Context = {
    itemUri: string,
    isFav: boolean,
    name: string,
};

class favorite {
    queueMultiple: boolean = true;
    execute = ({ itemUri, isFav, name }: Context) =>
        toggleFavorite({ isFav, itemUri, name, logger: this.logger });
}
export default favorite;
