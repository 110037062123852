// @flow
import React from "react";
import loc from "i18next";
import Button from "react-bootstrap/lib/Button";
import Icons from "@hs/icons";
import CommandActionHandler from "./index";
import { getClassNames } from "data/utils";
import { ItemFlowDirection } from "data/types";
import styles from "./CommandActionButton.module.css";

/**
 * Create itemAction localization identifier for CommandAction Button
 *
 * @param {action: string } {itemUri} CommandAction identifier string
 * @param {props?: any } {props} optional commandAction properties
 *
 */
const getItemActionLocalizationIdentifier = (
    action: string,
    props?: any
): string => {
    switch (action) {
        case "itemFlows":
            let locId = `${action}_${
                props?.direction === ItemFlowDirection.out ? "output" : "input"
            }`;
            return props?.categoryId !== "all"
                ? `${locId}.${props.categoryId}`
                : locId;
        default:
            return action;
    }
};

const CommandActionButton = ({
    className,
    action,
    icon,
    props,
    actionPrefix = "itemActions",
    bsStyle = "link",
}: {
    className?: string,
    action: string,
    icon?: string,
    props?: any,
    actionPrefix?: string,
    bsStyle?: string,
}) => {
    const label = loc.t(
        `${actionPrefix}:${getItemActionLocalizationIdentifier(
            action,
            props
        )}.name`
    );
    return (
        <Button
            className={getClassNames(className, styles.btn)}
            aria-label={label}
            bsStyle={bsStyle}
            onClick={() => CommandActionHandler(action, props)}
        >
            {icon && <Icons.Library className={styles.icon} name={icon} />}
            {label}
        </Button>
    );
};
CommandActionButton.displayName = "CommandActionButton";
export default CommandActionButton;
