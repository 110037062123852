// @flow
import React from "react";
import PropTypes from "prop-types";
import Icons from "@hs/icons";
import Constants from "data/constants";
import styles from "./Status.module.css";
import Overlay from "./Overlay";
import loc from "i18next";

// central UI for status icon
const Status = ({
    status,
    ok,
    okStyle,
    inline = false,
    message = "",
    error = null,
}: {
    status: string,
    ok?: string,
    okStyle?: StyleSheet,
    inline?: boolean,
    message: ?string,
    error?: Error,
}) => {
    switch (status) {
        case Constants.REFRESH:
        case Constants.LOADING:
            return inline ? (
                <Icons.Library name="spinner" pulse className="fa-lg" />
            ) : (
                <div className={styles.wrapper}>
                    <Icons.Library name="spinner" pulse className="fa-lg" />
                </div>
            );
        case Constants.OK:
            return ok ? (
                <Icons.Library name={ok} style={okStyle} className="fa-lg" />
            ) : null;
        case Constants.ERROR:
            if (
                error != null &&
                error.constructor.name === "ApiNetworkOfflineError"
            ) {
                return inline ? (
                    <Icons.Library name="link-slash" className="fa-lg" />
                ) : (
                    <div className={styles.wrapper}>
                        {loc.t("offline.pretty.msg")}
                    </div>
                );
            }
        //fallthrough
        default:
            return inline ? (
                <Icons.Library name="circle-minus" className="fa-lg" />
            ) : (
                <div className={styles.wrapper}>
                    <Icons.Library name="circle-minus" className="fa-lg" />
                    {message && (
                        <>
                            <br />
                            {message}
                        </>
                    )}
                </div>
            );
    }
};

Status.Overlay = Overlay;

Status.propTypes = {
    status: PropTypes.oneOf([
        Constants.NONE,
        Constants.LOADING,
        Constants.ERROR,
        Constants.OK,
        Constants.REFRESH,
    ]).isRequired,
    ok: PropTypes.string,
    okStyle: PropTypes.object,
    inline: PropTypes.bool,
    message: PropTypes.string,
};

export default Status;
