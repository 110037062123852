import { openItemFlowsModal } from "components/ItemFlowsModal";
import { type ItemFlowDirectionEnum, ItemFlowDirection } from "data/types";
import { stringForFormTokens, withFormTokens } from "./utils/FormTokens";
import { tokenize } from "data/utils";
import { createDraft, finishDraft } from "immer";

/**
 * opens the itemFlows input dialog for the given itemUri
 *
 * @param {{ itemUri: string }} { itemUri } folder itemUri
 * @param {{ targetItemUri: string }} { targetItemUri } optional target folder itemUri (default: itemUri)
 * @param {{ direction: ItemFlowDirection }} { direction } "in" or "out" (defaults to "in")
 * @param {{ flowId?: string}} { flowId } optional pre-selected flow.id
 * @param {{ visibleFlowIds?: Array<string>}} { visibleFlowIds } optional limit which flowIds are at all visible
 * @param {{ categoryId?: string}} { categoryId } optional pre-selected category.id
 * @param {{ defaultValues?: Object }} { defaultValues } optional default attribute values to pass to form
 * @param {{ previewNewItem?: boolean}} { previewNewItem } internal: whether to preview to the newly created item (defaults to false)
 *
 */

type Context = {
    itemUri: string,
    targetItemUri?: string,
    direction?: ItemFlowDirectionEnum,
    flowId?: string,
    visibleFlowIds?: Array<string>,
    categoryId?: string,
    defaultValues?: Object,
    previewNewItem?: boolean,
    useFormTokens?: boolean,
};

class itemFlows {
    execute = async ({
        itemUri,
        targetItemUri,
        direction = ItemFlowDirection.in,
        flowId,
        visibleFlowIds,
        categoryId,
        defaultValues = {},
        previewNewItem = true,
        useFormTokens = false,
    }: Context): Promise<boolean> => {
        const draft = createDraft(defaultValues);
        const recipe = async (draft) => {
            if (useFormTokens === true) {
                const tokens = await withFormTokens({ itemUri });
                Object.keys(draft).forEach((key) => {
                    var val = tokenize(stringForFormTokens(draft[key]), tokens);
                    try {
                        if (String(val).startsWith("{")) {
                            val = JSON.parse(val);
                        }
                    } catch {}
                    draft[key] = val;
                });
            }
        };
        await recipe(draft);
        const defaultValuesWithTokens = finishDraft(draft);
        await openItemFlowsModal({
            itemUri,
            targetItemUri,
            direction,
            flowId,
            visibleFlowIds,
            categoryId,
            defaultValues: defaultValuesWithTokens,
            previewNewItem,
        });
        return true;
    };
}

export default itemFlows;
