import actionTypes from "../actions/actionTypes";
import immutable from "object-path-immutable";
import panesDefaultState from "../panes.js";

const panesReducer = (state = panesDefaultState, action) => {
    switch (action.type) {
        case actionTypes.SPLITPANES_PERSIST:
        case actionTypes.SPLITPANES_SET:
            return immutable.set(
                state,
                [action.payload.view, action.payload.name],
                action.payload.dimensions
            );
        /* istanbul ignore next */
        case actionTypes.PERSIST_PURGE:
            return panesDefaultState;
        default:
            return state;
    }
};

export default panesReducer;

export const panesViewSelector = (state: Object, viewName: string) =>
    state.panes[viewName];
