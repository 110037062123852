// @flow
import React from "react";
import Badge from "react-bootstrap/lib/Badge";
import loc from "i18next";

type Props = {
    /** CSS class to apply */
    className?: string,
    /** number to show (null or <0 will not render) */
    count?: number,
    /** title to show, defaults to count */
    title?: string,
};

// https://stackoverflow.com/questions/2685911/is-there-a-way-to-round-numbers-into-a-reader-friendly-format-e-g-1-1k
function abbrNum(number, decPlaces) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    var abbrev = ["k", "m", "b", "t"];

    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            number = Math.round((number * decPlaces) / size) / decPlaces;

            // Handle special case where we round up to the next abbreviation
            /* istanbul ignore next */
            if (number === 1000 && i < abbrev.length - 1) {
                number = 1;
                i++;
            }

            // Add the letter for the abbreviation
            number = loc.format(number, "pretty") + abbrev[i];

            // We are done... stop
            break;
        }
    }

    return number;
}

/** renders a normalized localized count number in a Badge */
const CountBadge = ({ className, count, title }: Props) => {
    if (count == null || count < 0) return null;
    return (
        <Badge className={className} title={title || count}>
            {abbrNum(count, 1)}
        </Badge>
    );
};
CountBadge.displayName = "CountBadge";
export default CountBadge;
