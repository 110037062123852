import { useEffect } from "react";
import { toastTypes, toastStyled } from "data/toast";
import { anyPendingChanges } from "data/offline/offlineQueue";
import { isFeatureOn, Feature } from "data/constants";

export function usePendingOfflineChanges(itemUri: string) {
    useEffect(() => {
        if (itemUri != null && isFeatureOn(Feature.offline)) {
            anyPendingChanges(itemUri).then((result) => {
                if (result === true) {
                    toastStyled(toastTypes.offlinePendingChanges);
                }
            });
        }
    }, [itemUri]);
}
