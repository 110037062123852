import React from "react";
import Button from "react-bootstrap/lib/Button";
import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import Icons from "@hs/icons";
import loc from "i18next";
import { clampInRange } from "./clampInRange";

const degrees = [0, 90, 180, 270];

const Rotation = ({
    value = 0,
    onRotate,
}: {
    value: number,
    onRotate: (degrees: number) => void,
}) => {
    const setRotation = clampInRange(
        degrees,
        degrees.findIndex((v) => v === value)
    );

    return (
        <ButtonGroup>
            <Button
                title={loc.t("upload:image.rotateLeft")}
                onClick={() => onRotate(setRotation.prev())}
            >
                <Icons.Library name="rotate-left" />
            </Button>

            <Button
                title={loc.t("upload:image.rotateRight")}
                onClick={() => onRotate(setRotation.next())}
            >
                <Icons.Library name="arrow-rotate-right" />
            </Button>
        </ButtonGroup>
    );
};
Rotation.displayName = "ImageEditor.Rotation";
export default Rotation;
