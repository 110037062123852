//@flow
import { type FormActionsConfigDTO } from "data/types";

export const getFormActionsConfig = (): FormActionsConfigDTO => [
    {
        id: "searchForm_operator",
        icon: "filter",
        name: "$formActions:searchForm_operator.name",
        description: "$formActions:searchForm_operator.description",
        views: ["search"],
        shouldBeVisible: ["hasContext:showSearchOperators"],
        commandAction: {
            name: "searchForm_operator",
        },
    },
    {
        id: "searchForm_scope",
        icon: "fa-regular fa-circle-dot",
        name: "$formActions:searchForm_scope.name",
        description: "$formActions:searchForm_scope.description",
        views: ["search"],
        shouldBeVisible: ["hasContext:showSearchScope"],
        commandAction: {
            name: "searchForm_scope",
        },
    },
    {
        id: "search_scope",
        icon: "fa-regular fa-folder",
        name: "$formActions:search_scope.name",
        description: "$formActions:search_scope.description",
        views: ["search_scope"],
        commandAction: {
            name: "search_scope",
        },
    },
    {
        id: "unlock",
        icon: "unlock",
        name: "$formActions:unlock.name",
        views: ["lock_info"],
        shouldBeVisible: ["isAnyOnlineStatus", "hasContext:isDocEditAllowed"],
        commandAction: {
            name: "unlock",
        },
    },
    {
        id: "doc_editClassification",
        icon: "pencil",
        name: "$formActions:doc_editClassification.name",
        views: ["document", "docmodal"],
        shouldBeVisible: [
            "isAnyOnlineStatus",
            "!hasContext:isEdit",
            "hasContext:isDocEditAllowed",
            "hasContext:isDocClassificationEditAllowed",
            "licenseAllowEdit",
        ],
        commandAction: {
            name: "doc_editClassification",
        },
    },
    {
        id: "folder_editClassification",
        icon: "pencil",
        name: "$formActions:folder_editClassification.name",
        views: ["folder"],
        shouldBeVisible: [
            "isAnyOnlineStatus",
            "!hasContext:isEdit",
            "hasContext:isEditAllowed",
            "hasContext:isFolderClassificationEditAllowed",
            "licenseAllowEdit",
        ],
        commandAction: {
            name: "folder_editClassification",
        },
    },
    {
        id: "edit_form_clear",
        name: "$formActions:form_clear.name",
        views: ["folder", "document", "docmodal", "upload", "itemflows"],
        shouldBeVisible: [
            "isAnyOnlineStatus",
            "hasContext:onClear",
            "hasContext:isEdit",
        ],
        commandAction: {
            name: "form_clear",
        },
    },
    {
        id: "edit_form_back",
        name: "$formActions:form_back.name",
        views: ["folder", "document", "docmodal", "upload", "itemflows"],
        shouldBeVisible: [
            "isAnyOnlineStatus",
            "hasContext:onBack",
            "hasContext:isEdit",
        ],
        commandAction: {
            name: "form_back",
        },
    },
    {
        id: "edit_form_cancel",
        name: "$formActions:form_cancel.name",
        views: ["folder", "document", "docmodal", "upload", "itemflows"],
        shouldBeVisible: [
            "isAnyOnlineStatus",
            "hasContext:onCancel",
            "hasContext:isEdit",
        ],
        commandAction: {
            name: "form_cancel",
        },
    },
    {
        id: "view_form_clear",
        name: "$formActions:form_clear.name",
        views: ["archive_target", "search"],
        shouldBeVisible: ["isAnyOnlineStatus", "hasContext:onClear"],
        commandAction: {
            name: "form_clear",
        },
    },
    {
        id: "view_form_back",
        name: "$formActions:form_back.name",
        views: ["archive_target", "search"],
        shouldBeVisible: ["isAnyOnlineStatus", "hasContext:onBack"],
        commandAction: {
            name: "form_back",
        },
    },
    {
        id: "view_form_cancel",
        name: "$formActions:form_cancel.name",
        views: ["archive_target", "search"],
        shouldBeVisible: ["isAnyOnlineStatus", "hasContext:onCancel"],
        commandAction: {
            name: "form_cancel",
        },
    },
    {
        id: "searchForm_abort",
        icon: "fa-regular fa-ban",
        name: "$formActions:searchForm_abort.name",
        description: "$formActions:searchForm_abort.description",
        views: ["search"],
        shouldBeVisible: ["hasContext:isSearching"],
        commandAction: {
            name: "searchForm_abort",
        },
    },
];
