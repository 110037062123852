const actions = {
    TOGGLES_PERSIST: "toggles/PERSIST",
    TOGGLES_CHANGE: "toggles/CHANGE",
    TOGGLES_UPDATECOUNTER: "toggles/UPDATECOUNTER",

    USER_FETCH_REQUESTED: "user/FETCH_REQUESTED",
    USER_FETCH_RESULT: "user/FETCH_RESULT",

    USER_SITES_RESULT: "user/SITES_RESULT",

    LOGIN_RESULT: "login/RESULT",
    LOGIN_SIGNOUT: "login/SIGNOUT",

    FAVORITES_FETCH_REQUEST: "favorites/FETCH_REQUEST",
    FAVORITES_FETCH_RESULT: "favorites/FETCH_RESULT",

    CARDS_FETCH_REQUESTED: "cards/FETCH_REQUESTED",
    CARDS_FETCH_RESULT: "cards/FETCH_RESULT",
    CARDS_FETCH_ENTITY_REQUESTED: "cards/FETCH_ENTITY_REQUESTED",
    CARDS_FETCH_ENTITY_RESULT: "cards/FETCH_ENTITY_RESULT",

    WF_FETCH_REQUESTED: "wf/FETCH_REQUEST",
    WF_FETCH_RESULT: "wf/FETCH_RESULT",

    WF_PROVIDERS_FETCH_REQUESTED: "wf_providers/FETCH_REQUEST",
    WF_PROVIDERS_FETCH_RESULT: "wf_providers/FETCH_RESULT",

    TREE_SET_STATUS: "tree/SET_STATUS",
    TREE_FETCH_REQUEST: "tree/FETCH_REQUEST",
    TREE_FETCH_RESULT: "tree/FETCH_RESULT",

    DOCS_FETCH_REQUEST: "docs/FETCH_REQUEST",
    DOCS_FETCH_RESULT: "docs/FETCH_RESULT",
    DOCS_EXPORT_CSV: "docs/EXPORT_CSV",

    DOCS_LAYOUT_REQUEST: "docs/LAYOUT_REQUEST",
    DOCS_LAYOUT_SET: "docs/LAYOUT_SET",

    DOCS_FILTERS_RESET: "docs/FILTER_RESET",
    DOCS_FILTERS_SETBYREF: "docs/FILTER_SETBYREF",
    DOCS_FILTERS_SETBYNAME: "docs/FILTER_SETBYNAME",
    DOCS_FILTERTEXT_SET: "docs/FILTERTEXT",

    DOCS_THUMBNAIL_REQUEST: "docs/THUMBNAIL_REQUEST",
    DOCS_THUMBNAIL_RESULT: "docs/THUMBNAIL_RESULT",

    SEARCH_ABORT: "search/ABORT",
    SEARCH_RESET: "search/RESET",
    SEARCH_FETCH_REQUEST: "search/FETCH_REQUEST",
    SEARCH_FETCH_RESULT: "search/FETCH_RESULT",
    SEARCH_FAVRENAME_REQUEST: "search/FAVRENAME_REQUEST",
    SEARCH_FAVRENAME_RESULT: "search/FAVRENAME_RESULT",
    SEARCH_ITEM_SAVE: "search/ITEM_SAVE",
    SEARCH_COUNT_REQUEST: "search/AGGR_REQUEST",
    SEARCH_COUNT_RESULT: "search/AGGR_RESULT",

    SEARCH_FORM_FETCH_REQUEST: "search/FORM_FETCH_REQUEST",
    SEARCH_FORM_FETCH_RESULT: "search/FORM_FETCH_RESULT",
    SEARCH_FORM_CHANGES: "search/FORM_CHANGES",
    SEARCH_FORM_RESET: "search/FORM_RESET",
    SEARCH_FORM_VALIDATION: "search/FORM_VALIDATION",

    SEARCH_FORM_LIST_FETCH_REQUEST: "search/FORM_LIST_FETCH_REQUEST",
    SEARCH_FORM_LIST_FETCH_RESULT: "search/FORM_LIST_FETCH_RESULT",

    SPLITPANES_PERSIST: "splitPanes/PERSIST",
    SPLITPANES_SET: "splitPanes/SET",

    SETTINGS_SIDEBAR_MINI: "settings/SIDEBAR_MINI",
    SETTINGS_DOCLIST_VIEWMODE: "settings/DOCLIST_VIEWMODE",
    SETTINGS_SET: "settings/SET",

    GLOBALDEFAULTSEARCH_FETCH_REQUEST: "globalDefaultSearch/FETCH_REQUEST",
    GLOBALDEFAULTSEARCH_FETCH_RESULT: "globalDefaultSearch/FETCH_RESULT",

    CURRENT_BREADCRUMB: "current/BREADCRUMB",

    CURRENTITEM_SET: "currentItem/SET",
    CURRENTITEM_BAK: "currentItem/BACKUP",
    CURRENTITEM_RESTORE: "currentItem/RESTORE",
    CURRENTITEM_SAVE: "currentItem/SAVE",
    CURRENTITEM_LOADED: "currentItem/LOADED",

    EA_CLEAR: "EA/CLEAR",
    EA_DETAILVIEW_SET: "EA/DETAILVIEW_SET",
    EA_GRID_CURRENTROW: "EA/GRID_CURRENTROW",
    EA_FIELD_SET: "EA/FIELD_SET",
    EA_AUDITLOG_UPDATE: "EA/AUDITLOG_UPDATE",
    EA_AUDITLOG_CLEAR: "EA/AUDITLOG_CLEAR",
    EA_AUDITLOG_INSERT: "EA/AUDITLOG_INSERT",
    EA_AUDITLOG_DEL: "EA/AUDITLOG_DEL",
    EA_TABINDEX_SET: "EA/TABINDEX_SET",
    FORM_DEFAULTS_SET: "form/DEFAULTS_SET",
    FORM_EDITMODE_SET: "form/EDITMODE_SET",

    GLOBALEVENTS_SERVER: "GLOBALEVENTS/SERVER",

    OFFLINE_SYNC_REQUEST: "offline/SYNC_REQUEST",
    OFFLINE_SYNC_STARTED: "offline/SYNC_STARTED",
    OFFLINE_SYNC_DONE: "offline/SYNC_DONE",
    OFFLINE_SYNC_UPDATE: "offline/SYNC_UPDATE",
    OFFLINEITEM_SYNC_STATUS: "offlineItem/STATUS",
    OFFLINEITEM_REMOVE: "offlineItem/REMOVE",
    OFFLINEITEM_DEPSMAP_REQUEST: "offlineItem/DEPSMAP_REQUEST",
    OFFLINEITEM_DEPSMAP_RESULT: "offlineItem/DEPSMAP_RESULT",
    // EVENT_REFRESH_TASKS: "EVENT/REFRESH_TASKS" source: client/server, lastUpdateDate:xxx

    SW_INIT: "sw/INIT",
    SW_UPDATE: "sw/UPDATE",

    NOTIFICATION_TOAST: "notification/TOAST",
    NOTIFICATION_UPSERT: "notification/UPSERT",
    NOTIFICATION_DEL: "notification/DEL",
    NOTIFICATION_CLEAR: "notification/CLEAR",
    NOTIFICATION_STATUS: "notification/STATUS",

    COMMANDACTION_REQUEST: "commandAction/REQUEST",
    COMMANDACTION_QUEUE_ADD: "commandAction/QUEUE_ADD",
    COMMANDACTION_QUEUE_REMOVE: "commandAction/QUEUE_REMOVE",
    COMMANDACTION_QUEUE_START: "commandAction/QUEUE_START",
    COMMANDACTION_QUEUE_PAUSE: "commandAction/QUEUE_PAUSE",
    COMMANDACTION_ABORT: "commandAction/ABORT",
    COMMANDACTION_REMOVE: "commandAction/REMOVE",
    COMMANDACTION_LOG: "commandAction/LOG",
    COMMANDACTION_STATUS: "commandAction/STATUS",
    COMMANDACTION_ITEM_STATUS: "commandAction/ITEM_STATUS",

    PERSIST_PURGE: "persist/PURGE",
};
export default actions;
