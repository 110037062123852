// @flow
import { useMemo } from "react";
import { openSearchResultActionPanel } from "components/SearchResultActionPanel";
import { openGeoPointActionPanel } from "components/GeoPointActionPanel";
import { openPickerModal } from "components/PickerModal";
import {
    searchRouteWithItem,
    getRoute,
    folderRoute,
    docRoute,
    flatRoute,
    mailRoute,
} from "components/ItemUriLink";
import { check_isView } from "containers/ActionPanel/Utils";
import CommandActionHandler from "components/CommandActions";
import { usePostMessageHandler } from "./Handler";
import { useModalPostMessageHandler } from "./Modal";
import { DefaultAction } from "./Actions";
import { postMessageTo } from "./Utils";
import { toastStyled } from "data/toast";

const handleGeoInfo = (data) => {
    /* istanbul ignore else */
    if (data.row) {
        openSearchResultActionPanel({
            row: {
                ...data.row,
                targetView: "searchresult",
            },
        });
    } else if (check_isView({ views: ["upload"] })) {
        openGeoPointActionPanel({
            location: data,
        });
    }
};

const useDefaultPostMessageHandler = (history) =>
    usePostMessageHandler(
        useMemo(
            () => ({
                [DefaultAction.Browse]: ({
                    itemUri,
                    mini,
                    externalLink,
                    isDoc,
                    toggles,
                    view,
                    mark,
                    print,
                    edit,
                }: {
                    itemUri: string,
                    mini?: boolean,
                    externalLink?: boolean,
                    isDoc?: boolean,
                    toggles?: string | Array<string>,
                    view?: "doc" | "flat" | "folder" | "mail",
                    mark?: string,
                    print?: boolean,
                    edit?: boolean,
                }) => {
                    let url;
                    switch (view) {
                        case "doc":
                            url = docRoute(
                                itemUri,
                                mini,
                                externalLink,
                                toggles,
                                mark,
                                print,
                                edit
                            );
                            break;
                        case "flat":
                            url = flatRoute(
                                itemUri,
                                mini,
                                externalLink,
                                toggles
                            );
                            break;
                        case "folder":
                            url = folderRoute(
                                itemUri,
                                mini,
                                externalLink,
                                toggles
                            );
                            break;
                        case "mail":
                            url = mailRoute(
                                itemUri,
                                mini,
                                externalLink,
                                toggles
                            );
                            break;
                        default:
                            url = getRoute({
                                itemUri,
                                isMini: mini,
                                isExternalLink: externalLink,
                                isDoc,
                                toggles,
                            });
                            break;
                    }
                    history.push(url);
                },
                [DefaultAction.Search]: (data) =>
                    history.push(searchRouteWithItem(/*searchItem*/ data)),
                [DefaultAction.GeoInfo]: handleGeoInfo,
                [DefaultAction.GeoInfoLegacy]: handleGeoInfo,
                [DefaultAction.Exec]: (data) =>
                    CommandActionHandler(data.actionId, data.props),
                [DefaultAction.Toast]: toastStyled,
                [DefaultAction.Picker.Open]: async (
                    {
                        itemUri,
                        helpText,
                        pickerType,
                        selectButton,
                        scopeBreadcrumb = false,
                        additionalActions = null,
                        canChangeSite = true,
                    },
                    source
                ) => {
                    let action = null;
                    const onSelect = (eventKey) => (action = eventKey);
                    const selectedItemUri = await openPickerModal({
                        itemUri,
                        pickerType,
                        helpText,
                        selectButton,
                        scopeBreadcrumb,
                        additionalActions,
                        canChangeSite,
                        onSelect,
                    });
                    if (selectedItemUri) {
                        postMessageTo(
                            DefaultAction.Picker.Select,
                            { itemUri: selectedItemUri, action },
                            source
                        );
                    } else {
                        postMessageTo(
                            DefaultAction.Picker.Cancel,
                            null,
                            source
                        );
                    }
                },
            }),
            [history]
        )
    );

export {
    usePostMessageHandler,
    useDefaultPostMessageHandler,
    useModalPostMessageHandler,
};
