//@flow
import React, { PureComponent, type ComponentType } from "react";
import { connect } from "react-redux";
import findIndex from "lodash/findIndex";
import { getDisplayName } from "data/constants";
import * as s from "data/reducers/selectors";
import { type TrackCurrentRowProps } from "containers/TrackCurrentRow";
import {
    type ServerEventMessage,
    ServerEventItemType,
    ServerEventActionType,
} from "data/types";
import { type docsResultState } from "data/reducers/types";

type Props = {
    /** Current User ID */
    userId: number,
    /** DocList Container: Result (from API) */
    result: docsResultState,
    /** DocList Container: Tracker Object */
    docTracker: TrackCurrentRowProps,
    /** GlobalEventsHandler: Id */
    refreshId: number,
    /** GlobalEventsHandler: Event */
    refreshEvent: ?ServerEventMessage,
};

type State = {
    refreshId: number,
    userId: number,
    itemUri: string,
};

const UploadHandler = () => (WrappedComponent: ComponentType<any>) => {
    class PP extends PureComponent<Props, State> {
        static displayName = `UploadHandler(${getDisplayName(
            WrappedComponent
        )})`;

        state = {
            refreshId: -1,
            userId: -1,
            itemUri: "",
        };

        static _maybeSetActiveRow(props, state) {
            /* istanbul ignore else */
            if (!props.result) {
                return null;
            }

            const rows = props.result.rows;
            /* istanbul ignore else */
            if (!rows || props.result.rowCount === 0) {
                return null;
            }

            const itemUriIndex = findIndex(props.result.cols, [
                "name",
                "ITEMURI",
            ]);
            /* istanbul ignore else */
            if (itemUriIndex === -1) {
                return null;
            }

            const activeRowIndex = Object.keys(rows).reduce(
                (indexOrUndefined, index) =>
                    typeof indexOrUndefined === "undefined" &&
                    rows[index][itemUriIndex] === state.itemUri
                        ? index
                        : indexOrUndefined,
                undefined
            );
            /* istanbul ignore else */
            if (
                typeof activeRowIndex !== "undefined" &&
                props.docTracker &&
                props.docTracker.currentRow !== activeRowIndex
            ) {
                props.docTracker.setCurrentRow(activeRowIndex);
                // Reset itemUri and User ID
                return {
                    userId: -1,
                    itemUri: "",
                };
            }
            return null;
        }

        static getDerivedStateFromProps(props, state) {
            /* istanbul ignore else */
            if (!props.refreshEvent) {
                return null;
            }

            // Did my upload finish? Maybe need to set new active row
            /* istanbul ignore else */
            if (state.itemUri && state.userId === props.userId) {
                const newState = PP._maybeSetActiveRow(props, state);
                /* istanbul ignore else */
                if (newState) {
                    return newState;
                }
            }

            const { item, action, data } = props.refreshEvent;
            // Is the refresh event a file upload
            /* istanbul ignore else */
            if (
                item !== ServerEventItemType.document ||
                action !== ServerEventActionType.add
            ) {
                return null;
            }

            // Is the upload event mine and new?
            /* istanbul ignore else */
            if (
                props.refreshId !== state.refreshId &&
                data.userId === props.userId
            ) {
                return {
                    refreshId: props.refreshId,
                    userId: data.userId,
                    itemUri: data.itemUri,
                };
            }
            return null;
        }

        render() {
            // Remove userID property
            const { userId, ...props } = this.props;

            return <WrappedComponent {...props} />;
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        userId: s.userIdSelector(state),
        ...ownProps,
    });

    return connect(mapStateToProps)(PP);
};

export default UploadHandler;
