// https://github.com/EventMobi/redux-saga-network-status/blob/master/src/reducer.js

// // True when we have been online at least once
// hasBeenOnline: false,
// // Whether we have pinged the server at least once
// hasDetectedNetworkStatus: false,
// // Whether the browser is connected to a network
// isNavigatorOnline: false,
// // Whether the server is reachable over the network
// isOnline: false,
// // Whether we are currently pinging the server
// isPinging: false,
// // Number of milliseconds until the next ping attempt
// msUntilNextPing: 0,
// // The most recent ping error
// pingError: null,

export const isApiOnlineSelector = (state) => state.network.isOnline;
export const isNavigatorOnlineSelector = (state) =>
    state.network.isNavigatorOnline;
export const hasDetectedNetworkStatusSelector = (state) =>
    state.network.hasDetectedNetworkStatus;
export const hasBeenOnlineSelector = (state) => state.network.hasBeenOnline;

export const isOnlineSelector = (state) =>
    hasDetectedNetworkStatusSelector(state)
        ? isNavigatorOnlineSelector(state) && isApiOnlineSelector(state)
        : isNavigatorOnlineSelector(state); // do not block even if not ping'ed api yet
