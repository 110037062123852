import React, { PureComponent } from "react";
import { connect, Dispatch } from "react-redux";
import { docThumbnailRequestAction } from "data/actions";
// import { fetchThumbnail } from "data/api";
import { Img } from "react-image";
import Icons from "@hs/icons";
import Constants, { type ConstantsEnum } from "data/constants";
import * as s from "data/reducers/selectors";
import styles from "./Thumbnail.module.css";

type Props = {
    /** document's itemUri */
    itemUri: string,
    /** callback when rendered (and HTML loaded) */
    onLoad?: () => void,
    /** callback when user clicks the img element */
    onClick?: () => void,
    // from Redux
    /** thumbnail's data base64 encoded */
    result?: string,
    /** Redux dispatch */
    dispatch: Dispatch,
    /** thumbnail fetch status */
    status: ConstantsEnum,
    /** optional style to apply to image itself
     * @default {CSSStyleDeclaration} { maxHeight:128, maxWidth:128 }
     */
    style?: CSSStyleDeclaration,
};

/**
 * Renders one document's Thumbnail
 */
export class Thumbnail extends PureComponent<Props, State> {
    componentDidMount() {
        /* istabul ignore else */
        if (
            this.props.itemUri != null &&
            this.props.result === undefined &&
            this.props.status === Constants.NONE
        ) {
            this._fetch();
        }
    }

    _fetch = () => {
        const { itemUri, dispatch } = this.props;
        dispatch(docThumbnailRequestAction(itemUri));
    };

    _onLoad = () => {
        /* istanbul ignore else */
        if (typeof this.props.onLoad === "function") this.props.onLoad();
    };

    render() {
        if (
            this.props.result == null ||
            String(this.props.result).length === 0
        ) {
            if (
                [Constants.NONE, Constants.LOADING].includes(this.props.status)
            ) {
                return <Icons.Library name="spinner" pulse />;
            } else {
                return (
                    <span className="fa-stack fa-lg">
                        <i className="fa fa-camera fa-stack-1x" />
                        <i
                            className="fa fa-ban fa-stack-2x text-danger"
                            style={{ color: "gray" }}
                        />
                    </span>
                );
            }
        } else {
            return (
                <Img
                    src={this.props.result}
                    onLoad={this._onLoad}
                    onClick={this.props.onClick}
                    loader={<Icons.Library name="spinner" pulse />}
                    className={styles.thumbnail}
                    style={
                        this.props.style || {
                            maxWidth: 128,
                            maxHeight: 128,
                        }
                    }
                />
            );
        }
    }
}

const mapStateToProps = /* istanbul ignore next */ (state, ownProps) => ({
    status: s.thumbnailStatusSelector(state, ownProps.itemUri),
    result:
        ownProps.itemUri == null
            ? undefined
            : s.thumbnailStatusSelector(state, ownProps.itemUri) ===
              Constants.OK
            ? s.thumbnailResultSelector(state, ownProps.itemUri)
            : undefined,
    ...ownProps,
});

export default connect(mapStateToProps)(Thumbnail);
