import React from "react";
import Icons from "@hs/icons";
import styles from "./UserGroup.module.css";
import { getClassNames } from "data/utils";

const UserGroup = ({
    className,
    title,
}: {
    className?: string,
    title?: string,
}) => (
    <span className={getClassNames(styles.group, className)} title={title}>
        <Icons.Library name="users" className={styles.icon} />
    </span>
);

UserGroup.displayName = "UserGroup";
export default UserGroup;
