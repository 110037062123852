// @flow
import React, { useRef, useCallback } from "react";
import { usePdfHighlight } from "hooks";
import useHandleSignedPdf from "./HandleSignedPdf";
import useHandlePdfPrint from "./HandlePdfPrint";
import useViewerUrl from "./ViewerUrl";
import usePdfArrowNavigation from "./PdfArrowNavigation";

type Props = {
    /** document download token */
    token: string,
    /** whether document is a PDF file */
    pdf: boolean,
    /** callback when user click on Prev */
    onPrev?: () => void,
    /** callback when user click on Next */
    onNext?: () => void,
    /**pdf.js: search terms */
    searchTerm?: string,
    /** pdf.js: whether trigger browser print */
    triggerPrint: boolean,
};

const DocViewer = ({
    token,
    pdf,
    onPrev,
    onNext,
    searchTerm,
    triggerPrint,
}: Props) => {
    const iframe = useRef(null);
    const [target, setTarget] = usePdfHighlight();
    useHandleSignedPdf();
    useHandlePdfPrint(target, triggerPrint);

    const viewerUrl = useViewerUrl({ token, isPdf: pdf, searchTerm });

    // setup pdfJs once ready
    const onPdfReady = useCallback(() => {
        /* istanbul ignore else */
        if (iframe.current != null) {
            setTarget(iframe.current.contentWindow);
            iframe.current.contentWindow.PDFViewerApplication.pdfViewer.focus();
        }
    }, [iframe, setTarget]);

    const { onLoad } = usePdfArrowNavigation({
        iframe,
        onNext,
        onPrev,
        onPdfReady,
    });

    return (
        <iframe
            ref={iframe}
            onLoad={onLoad}
            title="PdfJsViewer"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen={true}
            src={viewerUrl}
        />
    );
};

export default DocViewer;
