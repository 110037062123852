//@flow
import { useState, useEffect } from "react";
import moment from "moment";
import Constants, { type ConstantsEnum } from "data/constants";

const getMomentLocale = (language: string): string => {
    if (language == null) {
        return "de";
    }
    switch (language) {
        case "en":
        case "en-US":
            return null;
        case "de-DE":
            return "de";
        default:
            return language.toLowerCase();
    }
};

const useMomentLocale = (language: string): ConstantsEnum => {
    const locale = getMomentLocale(language);
    const [status, setStatus] = useState(Constants.LOADING);
    useEffect(() => {
        if (locale == null || moment.locales().includes(locale)) {
            return setStatus(Constants.OK);
        }
        import(
            `__i18n/moment/${locale}` /* webpackChunkName: "moment-[request]" */
        )
            .then(() => setStatus(Constants.OK))
            .catch((e) => {
                console.error(
                    "[useMomentLocale] Cannot load moment.js locale:",
                    e
                );
                setStatus(Constants.ERROR);
            });
    }, [locale]);
    return status;
};

export { useMomentLocale, getMomentLocale };
