// @flow
import React from "react";
import Modal, { ModalSize } from "components/Modal";
import { createModal } from "react-modal-promise";
import styles from "./ItemFlowsModal.module.css";
import ItemFlowsContainer from "containers/ItemFlows";
import { type ItemFlowDirectionEnum, type NewItemResultDTO } from "data/types";
import { useModalPostMessageHandler } from "hooks";

type Props = {
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog */
    close: () => void,
    /** current folder's itemUri  */
    itemUri: string,
    /** optional target folder's itemUri (default: itemUri) */
    targetItemUri?: string,
    /** flow direction "in" vs "out" */
    direction?: ItemFlowDirectionEnum,
    /** flow.id to pre-select */
    flowId?: string,
    /** flow.ids to allow visible */
    visibleFlowIds?: Array<string>,
    /** category.id to pre-select */
    categoryId?: string,
    /** optional default attribute values to pass to form */
    defaultValues?: Object,
    /** optional whether to preview the newly created item
     * @default true
     */
    previewNewItem?: boolean,
    /** files to upload */
    files?: Array<DroppedFile>,
};

/** presents the available item templates to the user */
export const ItemFlowsModal = ({
    open,
    close,
    itemUri,
    targetItemUri,
    direction,
    flowId,
    visibleFlowIds,
    categoryId,
    defaultValues,
    previewNewItem,
    files,
}: Props) => {
    useModalPostMessageHandler(close);
    return (
        <Modal
            size={ModalSize.Medium}
            fullscreen
            enforceFocus={false}
            show={open !== false}
            onHide={/* istanbul ignore next */ () => close(false)}
            dialogClassName={styles.itemflows}
            headerSize={0}
            footerSize={0}
            dataTest="itemFlowsModal"
        >
            <Modal.Body>
                <ItemFlowsContainer
                    itemUri={itemUri}
                    targetItemUri={targetItemUri}
                    flowId={flowId}
                    visibleFlowIds={visibleFlowIds}
                    categoryId={categoryId}
                    direction={direction}
                    onClose={close}
                    defaultValues={defaultValues}
                    previewNewItem={previewNewItem}
                    files={files}
                />
            </Modal.Body>
        </Modal>
    );
};
ItemFlowsModal.displayName = "ItemFlowsModal";
export const openItemFlowsModal = createModal(ItemFlowsModal);
