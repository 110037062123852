import { openConfirmModal } from "components/ConfirmModal";
import { delItemUri } from "data/api";
import loc from "i18next";
import { toastActionResult } from "data/toast";
import { postMessageTo } from "hooks/PostMessage/Utils";
import { ModalAction, ItemAction } from "hooks/PostMessage/Actions";
import { isMiniView } from "data/constants";

type Context = {
    itemUri: string,
    items?: Array<any>,
    name: string,
};

class doc_delete {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;
    shouldRender: boolean = true;

    /**
     * renders confirm dialog
     *
     * @param {{ name: string }} { name }
     * @param {{ items?: Array<any> }} { items }
     */
    render = async ({ name, items }: Context): Promise<boolean> => {
        this.shouldRender = false;
        const count = items?.length ?? 1;
        return await openConfirmModal({
            body: loc.t("commandAction:doc_delete.confirmBody", {
                name,
                count,
            }),
            confirmButton: loc.t("commandAction:doc_delete.confirmYes", {
                count,
            }),
        });
    };

    /**
     * deletes an existing document by itemUri
     *
     * @param {{ itemUri: string }} { itemUri }
     */
    execute = async ({ itemUri }: Context): Promise<boolean> => {
        try {
            const success = await delItemUri(itemUri);
            if (success) {
                postMessageTo(ItemAction.Deleted, itemUri, window);
                if (isMiniView(window.location, "doc")) {
                    window.close();
                    setTimeout(window.location.reload, 1000);
                } else {
                    postMessageTo(ModalAction.Close, null, window);
                }
            }

            return success;
        } catch (err) {
            console.warn(err);
            return false;
        }
    };

    /**
     * logs success state
     *
     * @param { success: boolean } { success }
     */
    report = async (success: boolean): Promise<boolean> => {
        this.logger(success === true, "commandAction:doc_delete");
    };
}

export default doc_delete;
