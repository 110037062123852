// @flow
import React from "react";
import InputGroup from "react-bootstrap/lib/InputGroup";
import FormControl from "react-bootstrap/lib/FormControl";
import Icons from "@hs/icons";
import loc from "i18next";
import Button from "react-bootstrap/lib/Button";
import { ParseArea, AreaToString } from "components/MapViewer/utils";
import {
    SupportGeoAreaEdit,
    getGeoAreaProvider,
} from "components/MapViewer/providerSupport";
import { openMapModal } from "components/MapModal";
import styles from "./GeoAreaInput.module.css";

type Props = {
    /** displayName of field */
    label: ?string,
    /** current area in string format */
    value: ?string,
    /** whether to show an icon before the text field */
    showIcon: boolean,
    /** callback when user sets location
     * @param {string} value new area path in string format
     */
    onChange: (value: string) => void,
    /** callback when user sets area path by map
     * @param {string} area area coordinates
     */
    onManualArea?: (area: string) => void,
    /** optional CSS class to apply to root element */
    className?: string,
    /** placeholder text to display when @see {value} is empty */
    placeholder?: string,
};

type State = {
    value: ?string,
};

class GeoAreaInput extends React.PureComponent<Props, State> {
    static defaultProps = {
        showIcon: true,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    /** change location input text value */
    changeValue = (e) =>
        this.setState({ value: e.target.value }, () =>
            this.props.onChange(this.state.value)
        );

    /** show map and allow user to change marker's location */
    _setAreaManually = () =>
        openMapModal({
            mapProvider: getGeoAreaProvider(),
            useMarker: true,
            setButtonLabel: loc.t("common:map.setArea"),
            locations: [
                {
                    attribute: this.props.label,
                    label: this.props.label,
                    allowEdit: true, // allow dragging/editing
                    area: ParseArea(
                        this.state.value != null &&
                            String(this.state.value).length > 0
                            ? this.state.value
                            : "0,0;0,0;0,0"
                    ),
                },
            ],
            onSet: (editLocationResult: MapEditLocationResult) => {
                this.props.onManualArea(AreaToString(editLocationResult.area));
            },
        });

    render = () => (
        <InputGroup className={styles.inputGroup}>
            {this.props.showIcon && (
                <InputGroup.Addon className={styles.iconWrapper}>
                    <Icons.Library name="location-dot" fixedWidth />
                </InputGroup.Addon>
            )}
            <FormControl
                type="text"
                value={this.state.value || ""}
                onChange={this.changeValue}
                placeholder={
                    this.props.placeholder || loc.t("common:location.notSet")
                }
            />
            <InputGroup.Addon className={styles.buttonsWrapper}>
                {SupportGeoAreaEdit() && (
                    <Button
                        title={loc.t("common:location.setManual")}
                        onClick={this._setAreaManually}
                        className={styles.button}
                    >
                        <Icons.Library fixedWidth name="pencil" />
                    </Button>
                )}
            </InputGroup.Addon>
            {this.props.children}
        </InputGroup>
    );
}
export default GeoAreaInput;
