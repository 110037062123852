// @flow
import React, { useState, useCallback, useEffect } from "react";
import { usePdfHighlightActivityList } from "hooks";
import {
    type ActivityDTO,
    ActivityType,
    ItemNoteType,
    type ItemNoteTypeEnum,
} from "data/types";
import loc from "i18next";
import Activity from "./Activity";

type ActionCallback<T> = (actionId: string, payload: T | number) => any;
type ActionFactory<T> = (item: T, index: number) => ActionCallback<T>;

type Props = {
    /** list of activities */
    items: Array<ActivityDTO>,
    /** current user's id */
    userId: number,
    /** whether to show activities details */
    showDetails: boolean,
    /** whether to show history menu
     * @default {boolean} true
     */
    showActions?: boolean,
    /** whether to show notes (comment) entries */
    showNotes: boolean,
    /** whether to show history entries */
    showHistory: boolean,
    /** which version should be selected
     * 0 to show latest
     */
    currentVersion: number,
    /** callback when user chooses an action
     * @param {string} actionId action identifier
     * @param {ActivityDTO | number} payload payload or activity row index
     */
    onAction: ActionCallback<ActivityDTO>,
    /** whether ActivityList is read-only */
    isReadOnly?: boolean,
};

/** Renders a list of activities */
const ActivityList = ({
    items,
    userId,
    showDetails,
    showNotes = true,
    showHistory = true,
    showActions = true,
    currentVersion,
    onAction,
    isReadOnly = false,
}: Props) => {
    const [edit, setEdit] = useState(null);
    const [commentType, setCommentType] = useState(null);

    const [highlight, handleHighlight] = usePdfHighlightActivityList(
        items,
        userId,
        setEdit,
        onAction,
        isReadOnly
    );

    const handleAction: ActionFactory<ActivityDTO> = useCallback(
        (item: ActivityDTO, index: number) =>
            (actionId: string, payload: any = index) => {
                switch (actionId) {
                    case "filter_note":
                        const itemCommentType: ItemNoteTypeEnum =
                            (item.what === ActivityType.comment &&
                                item.extras &&
                                item.extras.$type) ||
                            ItemNoteType.Default;
                        return setCommentType(
                            commentType !== itemCommentType
                                ? itemCommentType
                                : null
                        );
                    case "edit_note":
                        return setEdit(payload ? item.id : null);
                    case "highlight_note":
                        return handleHighlight(
                            highlight !== item.id ? item : null
                        );
                    case "update_note":
                        return onAction(actionId, payload).then((success) => {
                            /* istanbul ignore else */
                            if (success) setEdit(null);
                            return success;
                        });
                    case "delete_note":
                        return onAction(actionId, payload).then((success) => {
                            /* istanbul ignore else */
                            if (success) {
                                setEdit(null);
                                handleHighlight(null);
                            }
                            return success;
                        });
                    default:
                        return onAction(actionId, payload);
                }
            },
        [commentType, highlight, handleHighlight, onAction]
    );

    const filterType = useCallback(
        (item: ActivityDTO): boolean => {
            if (commentType != null)
                return (
                    showNotes &&
                    item.what === ActivityType.comment &&
                    ((item.extras && item.extras.$type === commentType) ||
                        (!item.extras && commentType === ItemNoteType.Default))
                );
            if (item.what === ActivityType.comment) return showNotes;
            return showHistory;
        },
        [commentType, showHistory, showNotes]
    );

    useEffect(() => {
        // Reset CommentType
        if (items.filter(filterType).length === 0) setCommentType(null);
    }, [filterType, items]);

    if (!showNotes && !showHistory)
        return <div>{loc.t("activity:list.invalid")}</div>;
    let lastVersionId = 1;
    const totalVersions = items.reduce((accumulator, item: ActivityDTO) => {
        if (item.what !== ActivityType.comment) {
            lastVersionId = item.id;
            return accumulator + 1;
        }
        return accumulator;
    }, 0);

    return (
        <>
            {items.map(
                (item: ActivityDTO, index: number) =>
                    filterType(item) && (
                        <Activity
                            key={`a-${item.what}-${item.id}`}
                            activity={item}
                            userId={userId}
                            showDetails={showDetails}
                            isEdit={
                                item.what === ActivityType.comment &&
                                edit === item.id
                            }
                            isHighlighted={
                                item.what === ActivityType.comment &&
                                highlight === item.id
                            }
                            isSelected={
                                item.what !== ActivityType.comment &&
                                currentVersion === item.id
                            }
                            isLastVersion={
                                item.what !== ActivityType.comment &&
                                lastVersionId === item.id
                            }
                            actionMenu={
                                isReadOnly
                                    ? "docactivity_readonly_context"
                                    : "docactivity_context"
                            }
                            showActions={totalVersions > 1 && showActions}
                            onAction={handleAction(item, index)}
                            isReadOnly={isReadOnly}
                        />
                    )
            )}
        </>
    );
};
ActivityList.displayName = "ActivityList";
export default ActivityList;
