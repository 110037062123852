import actionTypes from "data/actions/actionTypes";
import { produce } from "immer";

// state.toggles {}
//     ._selected[] of string toggle.value
//     .counts {} of string toggle.value=toggle.count

export function getSelected(toggles) {
    let result = [];
    toggles
        .filter((toggle) => toggle.isSelected)
        .forEach((toggle) => result.push(toggle.value));
    return result;
}

const togglesReducer = (state = {}, action) =>
    produce(state, (draft) => {
        const { name, toggles, counts, status } = action?.payload || {};
        switch (action.type) {
            case actionTypes.TOGGLES_PERSIST: {
                /* istanbul ignore else */
                if (draft[name] == null) {
                    draft[name] = {};
                }
                draft[name].persisted = true;
            }
            // eslint-disable-next-line no-fallthrough
            case actionTypes.TOGGLES_CHANGE: {
                if (draft[name] == null) {
                    draft[name] = {};
                }
                draft[name]._selected = getSelected(toggles);
                break;
            }
            case actionTypes.TOGGLES_UPDATECOUNTER: {
                if (draft[name] == null) {
                    draft[name] = {};
                }
                if (draft[name].counts == null) {
                    draft[name].counts = {};
                }
                if (status != null && draft[name].status == null) {
                    draft[name].status = {};
                }
                Object.keys(action.payload.counts).forEach((key) => {
                    draft[name].counts[key] = counts[key];
                    if (status != null) {
                        draft[name].status[key] = status[key];
                    }
                });
                break;
            }
            /* istanbul ignore next */
            case actionTypes.PERSIST_PURGE:
                return {};
            default:
                return draft;
        }
    });

export default togglesReducer;

export const selectedTogglesSelector = (state: Object, viewName: string) =>
    state.toggles?.[viewName]?.["_selected"] ?? undefined; // used by TogglesHandler to load initialState
export const togglesPersistedSelector = (state: Object, viewName: string) =>
    state.toggles?.[viewName]?.["persisted"] ?? false; // used by currentItemSaga to load initialState
