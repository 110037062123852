// @flow
import React from "react";
import Breadcrumb from "./Breadcrumb";
import { type BreadcrumbPartDTO, type SiteTypeEnum } from "data/types";
import noop from "lodash/noop";
import Measure from "react-measure";
import throttle from "lodash/debounce";

type Props = {
    /** width in pixel */
    knownWidth?: number,
    /** breadcrumb leaf name */
    text?: string,
    /** siteType */
    siteType?: SiteTypeEnum,
    /** all parts making up the breadcrumb */
    parts: Array<BreadcrumbPartDTO>,
    /** whether user is allowed to navigate the breadcrumb parts */
    isReadonly?: boolean,
    /** current version */
    version?: number,
    /** optional breadcrumbPart click event instead of history.push */
    onPartClick?: (itemUri: string) => void,
};

type State = {
    width: number,
    parts: Array<number>,
    showParts: Array<boolean>,
    hiddenCount: number,
    calculated: boolean,
};

/**
 * Default Breadcrumb gap size .35em => approx. 4px
 */
export const BREADCRUMB_GAP = 4;

/**
 * Calculated offset
 *
 * Favorite Icon 17.7px
 * Site Icon 15px
 * Elipsis Truncation 11.92px
 * and their gap ()
 */
export const BREADCRUMB_OFFSET = 17.7 + 15 + 11.92 + 3 * BREADCRUMB_GAP;

class ResponsiveBreadcrumb extends React.PureComponent<Props, State> {
    _isMounted: boolean;

    constructor(props: Props) {
        super(props);
        this._isMounted = false;
        this.state = {
            width: props.knownWidth || 0,
            parts: [],
            showParts: [],
            hiddenCount: 0,
            calculated: false,
        };
    }

    _onMeasured = (index: number, width: number) => {
        const { parts, showParts } = this.state;
        parts[index] = width;
        showParts[index] = true;
        this.setState({ parts, showParts });
    };

    __calcVisible = (knownState: State) => {
        if (knownState.width === 0) return;
        const { parts, width } = knownState;
        if (parts.length === 0) return;

        let showParts = [],
            hiddenCount = 0;
        parts.reduceRight((previousValue, currentValue, index) => {
            const result = previousValue + currentValue;
            showParts[index] =
                index === parts.length - 1 ||
                result <= width - BREADCRUMB_OFFSET;
            if (!showParts[index]) hiddenCount++;
            return result;
        }, 0);

        /* istanbul ignore else */
        if (this._isMounted)
            this.setState({ showParts, hiddenCount, calculated: true });
    };

    _calcVisible = throttle(this.__calcVisible, 200, {
        leading: false,
        trailing: true,
    });

    componentDidMount() {
        this._isMounted = true;
        this._calcVisible(this.state);
    }

    componentWillUnmount = () => (this._isMounted = false);

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.parts !== this.props.parts) {
            this.setState(
                {
                    parts: [],
                    showParts: [],
                    hiddenCount: 0,
                    calculated: false,
                },
                () => this._calcVisible(this.state)
            );
        }

        if (prevState.width === this.state.width && this.state.calculated)
            return;
        this._calcVisible(this.state);
    }

    render() {
        if (this.props.knownWidth !== undefined)
            return (
                <Breadcrumb
                    onMeasured={
                        /* istanbul ignore next */
                        this.state.parts.length === 0 ? this._onMeasured : noop
                    }
                    showParts={this.state.showParts}
                    hiddenCount={this.state.hiddenCount}
                    {...this.props}
                />
            );

        return (
            <Measure
                bounds
                onResize={({ bounds }) =>
                    this.setState({ width: bounds.width })
                }
            >
                {({ measureRef }) => (
                    <Breadcrumb
                        ref={measureRef}
                        onMeasured={
                            /* istanbul ignore next */
                            this.state.parts.length === 0
                                ? this._onMeasured
                                : noop
                        }
                        showParts={this.state.showParts}
                        hiddenCount={this.state.hiddenCount}
                        {...this.props}
                    />
                )}
            </Measure>
        );
    }
}

export default ResponsiveBreadcrumb;
