// @flow
import React from "react";
import Modal, { ModalSize } from "components/Modal";
import Button from "react-bootstrap/lib/Button";
import { createModal } from "react-modal-promise";
import styles from "./ConfirmModal.module.css";
import Icons from "@hs/icons";
import loc from "i18next";

type Props = {
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog */
    close: () => void,
    /** text to show in Confirm button */
    confirmButton?: string,
    /** confirm button bsStyle
     * @default {string} danger
     */
    confirmStyle?: string,
    /** text to show in Cancel button */
    cancelButton?: string,
    /** Modal Dialog title */
    title?: string,
    /** text to show in body of Modal dialog */
    body: string,
    /** FontAwesome icon to show above title
     * @default {string} "exclamation-triangle"
     */
    icon: ?string,
    /** whether escape closes the window calling close(false) */
    requireSelection?: boolean,
};

/** presents a Confirm dialog to the user
 * @returns {boolean} true if user confirmed, null/undefined otherwise
 */
export const ConfirmModal = ({
    open,
    close,
    body,
    confirmButton = loc.t("common:confirm.yes"),
    confirmStyle = "danger",
    cancelButton = loc.t("common:confirm.cancel"),
    title = loc.t("common:confirm.title"),
    icon = "triangle-exclamation",
    requireSelection = false,
}: Props) => (
    <Modal
        size={ModalSize.Small}
        enforceFocus
        show={open !== false}
        onHide={
            /* istanbul ignore next */ () =>
                requireSelection !== true && close(false)
        }
        dialogClassName={styles.modal}
        dataTest="confirmModal"
    >
        {title && (
            <Modal.Header closeButton={requireSelection !== true}>
                {icon && (
                    <Icons.Library
                        name={icon}
                        className="fa-5x"
                        style={{ color: "red" }}
                    />
                )}
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
        )}
        <Modal.Body>
            <p>{body}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button
                bsStyle={confirmStyle}
                onClick={/* istanbul ignore next */ () => close(true)}
                data-test="confirmModalConfirmButton"
            >
                {confirmButton}
            </Button>
            <Button
                onClick={/* istanbul ignore next */ () => close(false)}
                data-test="confirmModalCancelButton"
            >
                {cancelButton}
            </Button>
        </Modal.Footer>
    </Modal>
);
ConfirmModal.displayName = "ConfirmModal";
export const openConfirmModal = createModal(ConfirmModal);
