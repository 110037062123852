import localForage from "localforage";
import { extendPrototype } from "localforage-startswith";

extendPrototype(localForage);

// Add n/a fallback StorageDriver #53854
export function NotAvailable(...args) {
    return new Promise((resolve, reject) => {
        console.error("No StorageDriver is available", args);
        resolve(null);
    });
}

const NoStorageDriver = {
    _driver: "NOSTORAGE",
    _initStorage: /* istanbul ignore next */ () => {},
    setItem: NotAvailable,
    getItem: NotAvailable,
    removeItem: NotAvailable,
    clear: NotAvailable,
    length: NotAvailable,
    key: NotAvailable,
    keys: NotAvailable,
    iterate: NotAvailable,
};
localForage.defineDriver(NoStorageDriver);

// configure our local storage for Views (currentItem_*)
// https://github.com/localForage/localForage/issues/191#issuecomment-342793463
/* istanbul ignore next */
export const viewStorage = localForage.createInstance({
    name: "inPoint.Web",
    driver: [localForage.INDEXEDDB, localForage.LOCALSTORAGE, "NOSTORAGE"],
    version: 5,
    size: 4980736,
    storeName: "views",
});
viewStorage
    .ready()
    .catch(
        /* istanbul ignore next */ () =>
            console.error("Cannot load viewStorage")
    );

// configure our local storage for persist (redux-persist)
/* istanbul ignore next */
export const persistStorage = localForage.createInstance({
    name: "inPoint.Web",
    driver: [localForage.LOCALSTORAGE, "NOSTORAGE"],
    version: 5,
    size: 4980736,
    storeName: "persist",
});
persistStorage
    .ready()
    .catch(
        /* istanbul ignore next */ () =>
            console.error("Cannot load persistStorage")
    );

/* istanbul ignore next */
export const cacheStorage = localForage.createInstance({
    name: "inPoint.Web",
    driver: [localForage.LOCALSTORAGE, "NOSTORAGE"],
    version: 5,
    size: 4980736,
    storeName: "cache",
});
// clear on F5 or Browser Refresh
cacheStorage
    .ready()
    .then(() => cacheStorage.clear())
    .catch(
        /* istanbul ignore next */ () =>
            console.error("Cannot load cacheStorage")
    );

export const offlineQueueStorage = localForage.createInstance({
    name: "inPoint.Web",
    driver: [localForage.INDEXEDDB],
    version: 5,
    size: 4980736,
    storeName: "offline",
});

export async function getStorageEstimate() {
    if ("storage" in navigator && "estimate" in navigator.storage) {
        const { usage, quota } = await navigator.storage.estimate();
        const percentUsed = Math.round((usage / quota) * 100);
        const usageInGb = Math.round(usage / (1024 * 1024) / 954);
        const quotaInGb = Math.round(quota / (1024 * 1024) / 954);

        // const usageInMib = Math.round(usage / (1024 * 1024) / 954);
        // const quotaInMib = Math.round(quota / (1024 * 1024) / 954);
        // const details = `${usageInMib} out of ${quotaInMib} MiB used (${percentUsed}%)`;

        return { usageInGb, quotaInGb, percentUsed };
    }
    return null;
}

export /* istanbul ignore next*/ function getAllItems(db): Promise<Array<any>> {
    var accumulator = [];
    return db
        .iterate(function (value, key /*, iterationNumber*/) {
            accumulator.push({ key, value });
        })
        .then(function () {
            return accumulator;
        });
}
