import { toastActionResult } from "data/toast";
import { getRoute } from "components/ItemUriLink";
import { downloadDocUri } from "data/api";

type Context = {
    name: string,
    itemUri: string,
    isDoc: boolean,
    isDocSendByEmailAllowed?: boolean,
    isDocDownloadAllowed?: boolean,
};

class share_web {
    logger: () => any = toastActionResult;
    execute = async ({
        name,
        itemUri,
        isDoc,
        isDocSendByEmailAllowed,
        isDocDownloadAllowed,
    }: Context) => {
        // double-check (itemAction.shouldBeVisible should already have hasBrowser_webShareApi())
        if (navigator.share === undefined) {
            return false;
        }
        const text = "Your link is ready to be shared.";
        let files = undefined;
        if (isDoc && isDocSendByEmailAllowed && isDocDownloadAllowed) {
            try {
                const uri = await downloadDocUri(itemUri);
                const response = await fetch(uri);
                if (!response.ok) throw new Error("Could not retrieve file");
                // retrieve real filename/mimetype
                name = getFileNameFromContentDisposition(
                    name,
                    response.headers.get("content-disposition")
                );
                const blob = await response.blob();
                // TODO #44618 eventually support attaching multiple files
                files = [new File([blob], name, { type: blob.type })];

                /* istanbul ignore else */
                if (files && files.length > 0) {
                    if (!navigator.canShare || !navigator.canShare({ files })) {
                        return false;
                    }
                }
            } catch (e) {
                console.error("Error while trying to download/attach file", e);
                return false;
            }
        }
        try {
            await navigator.share({
                files,
                title: name,
                text,
                url: getRoute({
                    itemUri,
                    isMini: true,
                    isExternalLink: true,
                    isDoc,
                }),
            });
            return true;
        } catch (error) {
            console.error("Error while trying to share", error);
            return false;
        }
    };
    report = (success) => {
        !success && this.logger(success, "itemActions:share_web");
    };
}

export function getFileNameFromContentDisposition(name, contentDisposition) {
    const regex = /filename[^;=\n]*=(UTF-8(['"]*))?([^;\s]*)/;
    const matches = regex.exec(contentDisposition);
    let filename = name;

    if (matches != null && matches[3]) {
        filename = matches[3].replace(/['"]/g, "");
    }

    return decodeURI(filename);
}

export default share_web;
