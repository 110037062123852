// @flow
import React, { useEffect, useState, useRef } from "react";
import { compose } from "redux";
import DocViewer from "components/DocViewer";
import InlineViewer from "components/InlineViewer";
import LoadingIframe from "components/LoadingIframe";
import { fetchDownloadToken } from "data/api";
import {
    LoadingType,
    ServerEventActionType,
    ServerEventItemType,
    ItemContextKey,
} from "data/types";
import Status from "components/Status";
import Constants, { type ConstantsEnum } from "data/constants";
import GlobalEventsHandler from "containers/GlobalEventsHandler";
import Loader from "components/Loader";
import withOfflineHandler from "containers/OfflineHandler";
import withItemContext from "containers/ItemContext";
import withContinuousPreview from "containers/ContinuousPreview";

/* istanbul ignore next */
const MediaViewer = Loader(
    {
        loader: () =>
            import(
                "components/MediaViewer" /* webpackChunkName: "component-MediaViewer" */
            ),
    },
    LoadingType.Status
);

/* istanbul ignore next */
const ImageViewer = Loader(
    {
        loader: () =>
            import(
                "components/ImageViewer" /* webpackChunkName: "component-ImageViewer" */
            ),
    },
    LoadingType.Status
);

/* istanbul ignore next */
const HtmlViewer = Loader(
    {
        loader: () =>
            import(
                "containers/HtmlViewer" /* webpackChunkName: "containers-HtmlViewer" */
            ),
    },
    LoadingType.Status
);

type Props = {
    /** current itemUri to display */
    itemUri?: ?string,
    /** callback when user clicks left arrow */
    onPrev?: () => void,
    /** callback when user clicks right arrow */
    onNext?: () => void,
    /** GlobalEventsHandler.refreshId */
    refreshId: number,
    /**pdf.js: search terms */
    searchTerm?: string,
    /** pdf.js: whether trigger browser print */
    triggerPrint: boolean,
    /** withContinuousPreview: whether to render html from top or not */
    atEnd: boolean,
};

export const DocViewerContainer = ({
    itemUri,
    token: tokenFromProps,
    onPrev,
    onNext,
    refreshId,
    searchTerm,
    triggerPrint,
    offlineHandler,
    isPdf,
    hasPdf,
    canPdf,
    ext,
    name,
    isDocDownloadAllowed,
    customViewerUrl,
    atEnd,
}: Props) => {
    const [status: ConstantsEnum, setStatus] = useState(
        tokenFromProps ? Constants.OK : Constants.LOADING
    );
    const tokenRef = useRef(tokenFromProps);
    const { factory: offlineHandlerFactory } = offlineHandler;

    useEffect(() => {
        if (itemUri == null || refreshId == null) return;
        tokenRef.current = null;
        setStatus(Constants.LOADING);
        (async () => {
            try {
                tokenRef.current = await offlineHandlerFactory(
                    fetchDownloadToken
                )(itemUri);
                setStatus(Constants.OK);
            } catch (e) {
                setStatus(Constants.ERROR);
            }
        })();
    }, [itemUri, refreshId, offlineHandlerFactory]);

    let viewer = null;
    const { current: token } = tokenRef;
    switch (true) {
        case name == null:
            break;
        case customViewerUrl != null:
            viewer = "customViewerUrl";
            break;
        case window.CONFIG.general.htmlViewerExtensions.includes(
            String(ext).toLowerCase()
        ):
            viewer = "html";
            break;
        case window.CONFIG.general.imageViewerExtensions.includes(
            String(ext).toLowerCase()
        ):
            viewer = "image";
            break;
        case window.CONFIG.general.mediaViewerExtensions.includes(
            String(ext).toLowerCase()
        ):
            viewer = "media";
            break;
        case isPdf || hasPdf || canPdf:
            viewer = "pdfJs";
            break;
        default:
            console.warn(`is/has/canPdf is false for ${name})`);
            viewer = "inline";
            break;
    }

    if (token == null || viewer == null || status !== Constants.OK) {
        return <Status status={status} error={offlineHandler.error} />;
    }

    switch (viewer) {
        case "customViewerUrl":
            return (
                <LoadingIframe
                    title="CustomViewer"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen={true}
                    src={customViewerUrl}
                />
            );
        case "image":
            return (
                <ImageViewer token={token} onPrev={onPrev} onNext={onNext} />
            );
        case "media":
            return (
                <MediaViewer token={token} onPrev={onPrev} onNext={onNext} />
            );
        case "html":
            return (
                <HtmlViewer
                    token={token}
                    onPrev={onPrev}
                    onNext={onNext}
                    atEnd={atEnd}
                />
            );
        case "inline":
            return (
                <InlineViewer
                    itemUri={itemUri}
                    isDocDownloadAllowed={isDocDownloadAllowed}
                    onPrev={onPrev}
                    onNext={onNext}
                />
            );
        case "pdfJs":
            return (
                <DocViewer
                    pdf={true}
                    token={token}
                    onPrev={onPrev}
                    onNext={onNext}
                    searchTerm={searchTerm}
                    triggerPrint={triggerPrint}
                />
            );
        /* istanbul ignore next */
        default:
            console.error(
                `Unknown docViewer: ${window.CONFIG.general.docViewer} !`
            );
            return null;
    }
};

export default compose(
    GlobalEventsHandler({
        actions: [
            ServerEventActionType.modify,
            ServerEventActionType.edit,
            ServerEventActionType.refresh,
        ],
        items: [ServerEventItemType.document],
    }),
    withOfflineHandler,
    withItemContext({
        asProps: [
            ItemContextKey.IsPdf,
            ItemContextKey.HasPdf,
            ItemContextKey.CanPdf,
            ItemContextKey.Extension,
            ItemContextKey.WebDavName,
            ItemContextKey.IsDocDownloadAllowed,
            ItemContextKey.ViewerUrl,
        ],
    }),
    withContinuousPreview
)(DocViewerContainer);
