// @flow
//#region imports
import React, { PureComponent } from "react";
import { connect, Dispatch } from "react-redux";
import { compose } from "redux";
import { fetchBreadcrumb, addFavorite, delFavorite } from "data/api";
import {
    ServerEventActionType,
    ServerEventItemType,
    type BreadcrumbResultDTO,
    type BreadcrumbPartDTO,
    type SiteTypeEnum,
} from "data/types";
import { setCurrentBreadcrumb } from "data/actions";
import Breadcrumb from "components/Breadcrumb";
import Constants, { type ConstantsEnum } from "data/constants";
import Status from "components/Status";
import GlobalEventsHandler from "containers/GlobalEventsHandler";
import * as s from "data/reducers/selectors";
import { toastActionResult } from "data/toast";
import withOfflineHandler from "containers/OfflineHandler";
//#endregion

//#region flow types
type Props = {
    /** itemUri */
    itemUri?: string,
    /** whether marked as favorite */
    isFav: boolean,
    /** whether this item is favorable */
    isFavorable: boolean,
    /** Redux dispatch */
    dispatch: Dispatch,
    /** all part objects making up breadcrumb */
    parts?: Array<BreadcrumbPartDTO>,
    /** current version */
    version?: number,
    /** siteType */
    siteType?: SiteTypeEnum,
    /** optional custom icon */
    icon?: string,
    /** optional custom icon color*/
    color?: string,
    /** whole breadcrumb in text  */
    text?: string,
    /** breadcrumb item name */
    name: string,
    /** current view name */
    viewName: string,
    /** whether user is allowed to navigate through breadcrumb (and hide favorite option) */
    isReadonly: boolean,
    /** whether this updates when globalEvents are received */
    isRefreshing: boolean,
    /** scope breadcrumb to show parts only starting the specified level number */
    scopeFromLevel?: number,
    /** GlobalEventsHandler.refreshId */
    refreshId: number,
    /** optional breadcrumbPart click event instead of history.push */
    onBreadcrumbPartClick?: (itemUri: string) => void,
};

type State = {
    status: ConstantsEnum,
    favStatus: ConstantsEnum,
    initialLoading: boolean,
};
//#endregion

export async function toggleFavorite({
    isFav,
    itemUri,
    name,
    logger = toastActionResult,
}: {
    isFav: boolean,
    itemUri: string,
    name: string,
    logger: () => void,
}): Promise<boolean> {
    const success: boolean = isFav
        ? await delFavorite(itemUri)
        : await addFavorite(itemUri, name);
    logger(success, isFav ? "breadcrumb.delfav" : "breadcrumb.addfav", {
        name,
    });
    return success;
}

export class BreadcrumbContainer extends PureComponent<Props, State> {
    _isMounted: boolean;
    abortController: AbortController | null;

    state = {
        status: Constants.LOADING,
        favStatus: Constants.LOADING,
        initialLoading: true,
    };

    static defaultProps = {
        isFav: false,
        isReadonly: false,
        isRefreshing: true,
    };

    componentDidMount = () => {
        this._isMounted = true;
        this._fetch(this.props.itemUri);
    };

    componentWillUnmount = () => {
        const { dispatch, viewName } = this.props;
        /* istanbul ignore else */
        if (this.abortController != null) {
            this.abortController.abort();
        }
        dispatch(
            setCurrentBreadcrumb({
                breadcrumb: {},
                viewName,
            })
        );
        this._isMounted = false;
    };

    componentDidUpdate(prevProps: Props) {
        /* istanbul ignore else */
        if (
            prevProps.itemUri !== this.props.itemUri ||
            (this.props.isRefreshing === true &&
                prevProps.refreshId !== this.props.refreshId)
        ) {
            this._fetch(this.props.itemUri);
        }
        /*if (nextProps.isFav !== this.props.isFav) {
            this._isFavToast(nextProps.isFav);
        }*/
    }

    _maybeGetNewAbortController = () => {
        if (this.abortController != null) {
            this.abortController.abort();
            return new AbortController();
        }
        return this.abortController || new AbortController();
    };

    _fetch = (itemUri: ?string): void => {
        this.abortController = this._maybeGetNewAbortController();
        const { dispatch, viewName } = this.props;
        if (itemUri != null) {
            this.setState({ status: Constants.LOADING });
            this.props.offlineHandler
                .factory(fetchBreadcrumb)(itemUri, this.abortController.signal)
                .then((breadcrumb: BreadcrumbResultDTO) => {
                    this._isMounted &&
                        this.setState(
                            {
                                status: Constants.OK,
                                initialLoading: false,
                                favStatus: Constants.OK,
                            },
                            () =>
                                dispatch(
                                    setCurrentBreadcrumb({
                                        breadcrumb,
                                        viewName,
                                    })
                                )
                        );
                })
                .catch((err) => {
                    /* istanbul ignore if */
                    if (this._isMounted && err.name === "AbortError") {
                        this.setState({
                            status: Constants.LOADING,
                            initialLoading: true,
                        });
                    } else if (this._isMounted) {
                        this.setState({
                            status: Constants.ERROR,
                            favStatus: Constants.ERROR,
                        });
                    }
                });
        } else {
            this._isMounted && this.setState({ status: Constants.OK });
        }
    };

    favOnClick = (e: SyntheticMouseEvent<*>) => {
        e.preventDefault();
        const { itemUri, name, isFav } = this.props;

        /* istanbul ignore next */
        if (itemUri == null) return;

        this.setState({ favStatus: Constants.LOADING });

        toggleFavorite({ isFav, itemUri, name }).then((success) => {
            this.setState({
                favStatus: success ? Constants.OK : Constants.ERROR,
            });
        });
    };

    render() {
        /* istanbul ignore else */
        if (this.props.itemUri) {
            const { initialLoading, status, favStatus } = this.state;
            const {
                text,
                parts,
                version,
                siteType,
                icon,
                color,
                isDoc,
                isFav,
                isReadonly,
                isFavorable,
                scopeFromLevel,
                onBreadcrumbPartClick,
                canChangeSite,
            } = this.props;

            /* istanbul ignore else */
            if (initialLoading === true || status === Constants.ERROR)
                return (
                    <Status
                        status={status}
                        error={this.props.offlineHandler.error}
                        inline
                    />
                );

            /* istanbul ignore else */
            if (status !== Constants.ERROR)
                return (
                    <Breadcrumb
                        text={text}
                        parts={parts || /* istanbul ignore next */ []}
                        version={version}
                        isFav={isFav}
                        favStatus={favStatus}
                        favOnClick={this.favOnClick}
                        siteType={siteType}
                        icon={icon}
                        color={color}
                        isDoc={isDoc}
                        isReadonly={isReadonly}
                        isFavorable={isFavorable}
                        scopeFromLevel={scopeFromLevel}
                        onPartClick={onBreadcrumbPartClick}
                        canChangeSite={canChangeSite}
                    />
                );

            /* istanbul ignore next */
            return null;
        }

        return null;
    }
}

// apply Redux Store state to Component Properties
const mapStateToProps = /*istanbul ignore next*/ (state, ownProps) => {
    let result = {
        name: s.breadcrumbNameSelector(state, ownProps.viewName),
        ...ownProps,
    };
    if (state.breadcrumb[ownProps.viewName]) {
        const { itemUri, ...rest } = state.breadcrumb[ownProps.viewName];
        result = Object.assign({}, result, rest);
    }
    return result;
};

export default compose(
    connect(mapStateToProps),
    GlobalEventsHandler({
        items: [
            ServerEventItemType.document,
            ServerEventItemType.folder,
            ServerEventItemType.favorite,
        ],
        actions: [
            ServerEventActionType.modify,
            ServerEventActionType.edit,
            ServerEventActionType.add,
            ServerEventActionType.delete,
            ServerEventActionType.refresh,
        ],
    }),
    withOfflineHandler
)(BreadcrumbContainer);
