// @flow
import React from "react";
import Modal from "components/Modal";
// import Tab from "react-bootstrap/lib/Tab";
// import Tabs from "react-bootstrap/lib/Tabs";
// import loc from "i18next";
import ActionCategory from "./ActionCategory";
// import ItemActions from "./ItemActions";
import {
    type ItemActionConfig,
    type ItemActionCategoryConfig,
} from "data/types";
import NoData from "components/NoData";
import Status from "components/Status";
import Constants from "data/constants";
import { useModalPostMessageHandler } from "hooks";

type Props = {
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog */
    close: () => void,
    /** React Component to render as Title */
    title?: React.Node,
    /** React Component to render as body */
    body?: React.Node,
    /** list of action categories */
    categories?: Array<ItemActionCategoryConfig>,
    /** CommandActions to render */
    actions?: Array<ItemActionConfig>,
    /** callback when user selects an Action to execute */
    onAction: (action: ItemActionConfig) => void,
    /** is loading */
    isLoading: boolean,
};

/**
 * renders a docked modal as the actionPanel
 */
const ActionPanel = ({
    open,
    close,
    title,
    body,
    categories,
    actions,
    onAction,
    isLoading,
}: Props) => {
    useModalPostMessageHandler(close);
    return (
        <Modal
            docked
            scroll
            show={open !== false}
            onHide={close}
            dataTest="actionPanel"
        >
            {title && (
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
            )}
            <Modal.Body style={{ margin: 15 }}>
                {body}
                {isLoading && <Status status={Constants.LOADING} />}
                {!isLoading &&
                    (categories == null || categories.length === 0) && (
                        <NoData locContext="actionpanel" />
                    )}
                {!isLoading &&
                    categories &&
                    categories.length > 0 &&
                    categories
                        .sort((a, b) => a.order - b.order)
                        .map((category, index) => (
                            <ActionCategory
                                key={`acat-${index}`}
                                category={category}
                                actions={actions || []}
                                onAction={
                                    /* istanbul ignore next */ (
                                        action?: ItemActionConfig
                                    ) => {
                                        if (
                                            action != null &&
                                            typeof onAction === "function"
                                        )
                                            onAction(action);
                                        close();
                                    }
                                }
                            />
                        ))}
            </Modal.Body>
        </Modal>
    );
};
ActionPanel.displayName = "ActionPanel";
export default ActionPanel;
