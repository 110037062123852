import React, { useCallback } from "react";
import { getLocalizedText } from "data/utils";
import Icons from "@hs/icons";
import { type EnumValue } from "data/types";

type Props = {
    enum: Array<EnumValue<T>>,
    value: T,
    name: string,
    label: string,
    isMulti: boolean,
    className: string,
};

const EnumIcon = ({
    name,
    label,
    enum: enumValues,
    className,
    isMulti,
    value,
}: Props) => {
    const renderEnumValue = useCallback(
        (enumValue: EnumValue<T>) =>
            enumValue &&
            enumValue.icon && (
                <span
                    key={`enumValue.${name}.${enumValue.value}`}
                    title={`${label}: ${getLocalizedText(enumValue.label)}`}
                >
                    {typeof enumValue.icon === "string" ? (
                        <Icons.Library name={enumValue.icon} />
                    ) : (
                        enumValue.icon
                    )}
                </span>
            ),
        [name, label]
    );
    return value ? (
        <span className={className}>
            {isMulti
                ? enumValues
                      .filter((e) => (value & e.value) > 0)
                      .map((e) => renderEnumValue(e))
                : renderEnumValue(enumValues.find((e) => e.value === value))}
        </span>
    ) : null;
};

EnumIcon.displayName = "EnumIcon";
export default EnumIcon;
