// @flow
import React, { PureComponent, type Children } from "react";
import ReactDOM from "react-dom";
import { connect, Dispatch } from "react-redux";
import Formsy from "formsy-react";
import Row from "react-bootstrap/lib/Row";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import Button from "react-bootstrap/lib/Button";
import FieldEdit from "./FieldEdit";
import "components/Form/index.css";
import EAForm from "components/ExtraAttributes/EAForm";
import NoData from "components/NoData";
import LocalContactInfo from "components/LocalContactInfo";
import loc from "i18next";
import { produce } from "immer";
import { addValidationRule } from "formsy-react";
import { extraAttributesSetField } from "data/actions";
import NavigationBlock from "components/NavigationBlock";
import { type FormResultDTO, type DocFormResultDTO } from "data/types";
import isEmpty from "lodash/isEmpty";
import Icons from "@hs/icons";
import { LabelSizeContext } from "data/context";
import { getClassNames } from "data/utils";

type Props = {
    /** Redux dispatch */
    dispatch: Dispatch,
    /** the Form itself */
    form: FormResultDTO | DocFormResultDTO,
    /** itemUri of Folder or Document */
    itemUri: string,
    /** callback for Cancel button */
    onCancel?: () => void,
    /** callback for Save button
     * @param {Object} model model to save
     * @returns {boolean} true is success
     */
    onSave: (model: Object) => boolean,
    /**
     * handler to validate model
     * @param {Object} model the current form (changed) values
     * @returns {Object} validation errors e.g. {field:msg,field:msg,...}
     */
    onValidate: (model: Object) => Object,
    /** save button label
     * @default common:save
     */
    saveLabel?: string,
    /** saving button label
     * @default common:savingLabel
     */
    savingLabel?: string,
    /** if NOT null will prompt user upon navigating away */
    confirmLabel?: boolean,
    /** should the save button be enabled */
    isFormButtonEnabled?: boolean,
    /** whether to include all or only changed fields
     * @default false
     */
    onSaveReturnAllFields?: boolean,
    /** defaultValues to set
     * Note: $NAME$ is special - see {@link _prepForm}
     * @example {"$NAME$"":"test.docx"}
     */
    defaultValues?: Object,
    /** optional DOM ref to render ButtonToolbar onto (otherwise will render as child of form) */
    buttonToolbarPortal?: React.DOMElement,
};

type State = {
    canSubmit: boolean,
    changed: Object,
    form: FormResultDTO | DocFormResultDTO,
    validationErrors: Object,
    isSubmitting: boolean,
    isValidating: boolean,
};

export class MyFormEdit extends PureComponent<Props, State> {
    constructor(props: Props, children: any) {
        super(props);
        this._formsyRef = React.createRef();
        this.state = {
            canSubmit: false,
            isSubmitting: false,
            isValidating: false,
            validationErrors: {},
            ...this._prepForm(props.form),
        };
        this._setupValidations();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.form !== prevProps.form) {
            this.setState((state) => ({
                ...this._prepForm(this.props.form, state.changed),
            }));
        }
        if (this.props.defaultValues !== prevProps.defaultValues) {
            this.setState((state) => ({
                ...this._prepForm(this.props.form, state.changed),
            }));
        }
    }

    /**
     * returns immutable form & changeLog with applied props.defaultValues
     * Note: $NAME$ is special
     * @param {FormResultDTO} form the current form
     * @memberof MyFormEdit
     */
    _prepForm = (form: FormResultDTO | DocFormResultDTO, changed = {}) => {
        const { defaultValues } = this.props;
        return produce({ form, changed }, (draft) => {
            if (defaultValues == null || form == null) {
                return;
            }
            Object.keys(defaultValues).forEach((defaultValueKey) => {
                if (defaultValueKey.startsWith("ea.")) {
                    /* istanbul ignore next */
                    if (form.attributes == null) {
                        console.warn(
                            `Requested setting DefaultValue for ${defaultValueKey} but there are no ExtraAttributes defined here!`
                        );
                        return null;
                    }
                    const defaultAttributeKey = defaultValueKey.substr(3);
                    let idx = form.attributes.findIndex(
                        (attribute) => attribute.name === defaultAttributeKey
                    );
                    if (idx === -1) {
                        console.warn(
                            `Requested setting DefaultValue for ${defaultValueKey} but it was not found!`
                        );
                        return;
                    }
                    /* istanbul ignore else */
                    if (draft.changed.ea == null) draft.changed.ea = {};
                    // update changeLog (dot notation)
                    draft.changed.ea[form.attributes[idx].name] =
                        defaultValues[defaultValueKey];
                    // update form (to immediately show value)
                    draft.form.attributes[idx].value =
                        defaultValues[defaultValueKey];
                } else {
                    const defaultFieldKey = defaultValueKey.startsWith("ip.")
                        ? defaultValueKey.substr(3)
                        : defaultValueKey;
                    // find field by field name (commonName)
                    let idx = form.fields.findIndex(
                        (field) => field.name === defaultFieldKey
                    );
                    if (idx === -1) {
                        console.warn(
                            `Requested setting DefaultValue for ${defaultValueKey} but it was not found!`
                        );
                        return;
                    }
                    /* istanbul ignore else */
                    if (draft.changed.ip == null) draft.changed.ip = {};
                    // update changeLog (dot notation)
                    draft.changed.ip[form.fields[idx].name] =
                        defaultValues[defaultValueKey];
                    // update form (to immediately show value)
                    draft.form.fields[idx].value =
                        defaultValues[defaultValueKey];
                }
            });
        });
    };

    /* istanbul ignore next */ _setupValidations = () => {
        // setup our custom validation rules used by Field/AttributeEdit components
        addValidationRule("isRange", (values, value, range) => {
            // empty value need no validation - is required needs additional isRequired validation
            if (value == null) return true;
            return value >= range.min && value <= range.max;
        });

        addValidationRule("isFilename", (values, value) => {
            // rules: https://docs.microsoft.com/en-us/windows/desktop/FileIO/naming-a-file
            var rg1 = /^[^\\/:*?"<>|]+$/; // forbidden characters \ / : * ? " < > |
            var rg2 = /^\./; // cannot start with dot (.)
            var rg3 = /^(aux|nul|prn|con|lpt[1-9]|com[1-9])(\.|$)/i; // forbidden file names
            return rg1.test(value) && !rg2.test(value) && !rg3.test(value);
        });

        addValidationRule("isPathname", (values, value) => {
            var rg1 = /^[^\\/:*?"<>|]+$/; // forbidden characters \ / : * ? " < > |
            return rg1.test(value);
        });

        addValidationRule(
            "isNotInvalid",
            (values, value) =>
                value == null || !String(value).startsWith("__INVALID__")
        );
    };

    _onInvalid = () => this.setState({ canSubmit: false });

    _onValid = () => this.setState({ canSubmit: true });

    _onSubmit = async (
        model: Object,
        resetForm?: boolean,
        invalidateForm?: () => void,
        cancelEditMode?: boolean = true
    ) => {
        try {
            this.setState({ isValidating: true });
            const validationErrors = await this.props.onValidate(model);
            if (!isEmpty(validationErrors)) {
                invalidateForm && invalidateForm(validationErrors);
                this.setState({ isValidating: false });
            } else {
                this.setState({ isValidating: false, isSubmitting: true });
                const result = await this.props.onSave(
                    this.props.onSaveReturnAllFields === true
                        ? model
                        : this.state.changed,
                    cancelEditMode
                );
                if (false === result) {
                    this.setState({ isSubmitting: false });
                }
            }
        } catch (e) {
            /* istanbul ignore else */
            if (e.code !== 20)
                //AbortController aborted
                this.setState({ isValidating: false, isSubmitting: false });
        }
    };

    /**
     * On every change will update changeLog and fire a Redux action
     * @param {string} name the field's name (dot notation e.g. ip.WebDavName)
     * @param {any} value the field's value
     * @memberof MyFormEdit
     */
    _onFieldChange = (name: string, value: any): void => {
        const [type, commonName] = name.split(".", 2);

        // is it an inPoint field?
        const valueType = type === "ip" ? "fields" : "attributes";
        const idx = this.state.form[valueType].findIndex(
            (a) => a.name === commonName
        );

        let nextState;
        // Allow fast (= multiple) field changes of state (e.g. Lookup Extras)
        this.setState(
            (prevState) => {
                nextState = produce(prevState, (draft) => {
                    if (idx !== -1) draft.form[valueType][idx].value = value;
                    // only submit current field if submitOnChange is true
                    if (draft.form[valueType][idx]?.extra?.submitOnChange) {
                        draft.changed = {};
                    }
                    (draft.changed[type] ??= {})[commonName] = value;
                });
                return nextState;
            },
            () => {
                this.props.dispatch(
                    extraAttributesSetField(type, commonName, value)
                );
                // handle submitOnChange, if defined
                this.state.form[valueType][idx]?.extra?.submitOnChange &&
                    this._onSubmit(
                        nextState.changed,
                        false,
                        (e) => {
                            // do something on error
                            console.error(e);
                        },
                        false
                    );
            }
        );
    };

    _isDirty = (): boolean => {
        /* istanbul ignore if */
        if (this.state.changed == null) return false;
        return Object.keys(this.state.changed).length > 0;
    };

    _getSaveButtonLabel = (): React.Element => {
        const { isValidating, isSubmitting } = this.state;

        if (isValidating === true) {
            return (
                <>
                    {loc.t("validating")}
                    <Icons.Library name="spinner" pulse />
                </>
            );
        }

        if (isSubmitting === true) {
            return (
                <>
                    {this.props.savingLabel || loc.t("saving")}
                    <Icons.Library name="spinner" pulse />
                </>
            );
        }

        return this.props.saveLabel || loc.t("save");
    };

    _formsySubmit = () => this._formsyRef.current.submit();

    render() {
        const { itemUri, saveLabel, confirmLabel } = this.props;
        const {
            form,
            validationErrors,
            canSubmit,
            isSubmitting,
            isValidating,
        } = this.state;
        const hasEA = form && form.attributes != null;
        const toolbar = (
            <ButtonToolbar className="form-actions pull-right">
                <Button
                    aria-label={saveLabel || loc.t("save")}
                    // type="submit"
                    onClick={this._formsySubmit}
                    bsStyle="primary"
                    style={{ padding: "6px 30px" }}
                    disabled={
                        isValidating ||
                        isSubmitting ||
                        !canSubmit ||
                        !this.props.isFormButtonEnabled
                    }
                    data-test="saveButton"
                >
                    {this._getSaveButtonLabel()}
                </Button>
            </ButtonToolbar>
        );
        return (
            <LabelSizeContext.Provider value={form.labelWidth}>
                <Formsy
                    ref={this._formsyRef}
                    className={getClassNames(
                        "form-inline",
                        "ip-form",
                        "form-edit",
                        hasEA ? "has-ea" : "no-ea"
                    )}
                    onValidSubmit={this._onSubmit}
                    onValid={this._onValid}
                    onInvalid={this._onInvalid}
                    // onChange={this._validate}
                    validationErrors={validationErrors}
                    data-test="formEdit"
                >
                    <NavigationBlock
                        isDirty={this._isDirty() && !isSubmitting}
                        message={confirmLabel}
                    />
                    {form &&
                        form.fields !== null &&
                        form.fields.map((field, index) => (
                            <Row
                                key={field.id}
                                data-test={`formRowEdit${field.name}`}
                            >
                                <FieldEdit
                                    isDoc={form.isDoc}
                                    itemUri={itemUri}
                                    formatId={form.formatId}
                                    field={field}
                                    fields={form.fields}
                                    onChange={this._onFieldChange}
                                />
                            </Row>
                        ))}
                    {form && form.attributes !== null && (
                        <EAForm
                            isEditMode={true}
                            isDoc={form.isDoc}
                            itemUri={itemUri}
                            formatId={form.formatId}
                            formTitle={form.formTitle}
                            layoutXml={form.layoutXml}
                            attributes={form.attributes}
                            onChange={this._onFieldChange}
                        />
                    )}
                    {form &&
                        form.fields === null &&
                        form.attributes === null && (
                            <NoData locContext="form">
                                <LocalContactInfo />
                            </NoData>
                        )}
                    {this.props.buttonToolbarPortal == null && toolbar}
                </Formsy>
                {this.props.buttonToolbarPortal &&
                    ReactDOM.createPortal(
                        toolbar,
                        this.props.buttonToolbarPortal
                    )}
            </LabelSizeContext.Provider>
        );
    }
}

export default connect()(MyFormEdit);
