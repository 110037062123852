import React, {
    useMemo,
    useContext,
    useState,
    useCallback,
    useEffect,
} from "react";
import { useAsyncMemo, usePrevious } from "hooks";
import { fetchItemContext } from "data/api";
import { ItemContextKeyEnum } from "data/types";
import { getDisplayName } from "data/constants";
import { ItemContext } from "data/context";

type Config = {
    asProps?: boolean | Array<ItemContextKeyEnum>,
};

type Props = {
    itemUri: string,
    refreshId: ?number,
};

const withItemContext =
    ({ asProps }: ?Config = {}) =>
    (WrappedComponent: ComponentType<any>) => {
        const HOC = (props: Props) => {
            const parentContext = useContext(ItemContext);
            const itemUri = props.itemUri;
            const [refreshId, setRefreshId] = useState(props.refreshId || 0);
            const prevRefreshId = usePrevious(refreshId) ?? refreshId;

            // #region Nested context update by refreshId
            // Update refreshId if the nested child's itemContext refreshId prop changes

            const baseRefreshContext = useCallback(
                (newRefreshId) => {
                    /* istanbul ignore if*/
                    if (refreshId === newRefreshId) {
                        return;
                    }
                    setRefreshId(newRefreshId);
                },
                [refreshId]
            );

            // get the parentContext's refreshContext,
            // if parentContext is available and itemUris match,
            // otherwise set it
            const refreshContext =
                parentContext?.itemUri === itemUri
                    ? parentContext?.refreshContext ?? baseRefreshContext
                    : baseRefreshContext;

            useEffect(() => {
                setRefreshId(props.refreshId);
            }, [props.refreshId]);

            // if the refreshId changed, update the parent itemContext's refreshId as well
            useEffect(() => {
                if (prevRefreshId === refreshId) {
                    return;
                }
                refreshContext(refreshId);
            }, [refreshId, prevRefreshId, refreshContext]);
            // #endregion

            const itemContext: ItemContextProps = useAsyncMemo(
                async () => {
                    if (!itemUri) return;
                    if (parentContext?.itemUri === itemUri)
                        return parentContext;
                    let c = {};
                    try {
                        c = await fetchItemContext(itemUri);
                        c.itemUri = itemUri;
                    } catch (e) {
                        console.error(e);
                    }
                    return c;
                },
                [parentContext, itemUri, refreshId],
                {}
            );

            const itemContextWithExtras = useMemo(
                () => ({
                    ...itemContext,
                    itemUri,
                    refreshContext,
                }),
                [itemContext, itemUri, refreshContext]
            );

            const itemContextAsProps = useMemo(() => {
                if (asProps === true) {
                    return itemContextWithExtras;
                }
                const props = {};
                if (!asProps) return props;
                asProps.forEach((key: ItemContextKeyEnum) => {
                    props[key] = itemContextWithExtras?.[key];
                });
                return props;
            }, [itemContextWithExtras]);

            return (
                <ItemContext.Provider value={itemContextWithExtras}>
                    <WrappedComponent {...props} {...itemContextAsProps} />
                </ItemContext.Provider>
            );
        };
        HOC.displayName = `withItemContext(${getDisplayName(
            WrappedComponent
        )})`;
        return HOC;
    };

export default withItemContext;
