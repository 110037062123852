/**
 * execute clear of current form
 */

type Props = {
    onClear?: () => void,
};

class form_clear {
    execute = ({ onClear }: Props) => {
        if (typeof onClear !== "function") {
            return false;
        }
        onClear();
        return true;
    };
}
export default form_clear;
