import React, { useMemo } from "react";
import sharedStyles from "./SummaryAttribute.module.css";
import styles from "./ValuePrincipal.module.css";
import Icons from "@hs/icons";
import PrincipalAvatar from "components/User/PrincipalAvatar";
import { getClassNames, getLocalizedText } from "data/utils";

import { type SummaryAttributeValuePrincipal } from "data/types";

const Principal = ({ value, Component = "span", ...props }) => (
    <Component {...props}>
        <span>
            {value.email && (
                <Icons.Library
                    name="fa-regular fa-envelope"
                    className={styles.icon}
                />
            )}
            {value.displayName}
        </span>
        {value.description && <span>{value.description}</span>}
        {value.id > -1 && (
            <PrincipalAvatar
                id={value.id}
                type={value.$type}
                className={getClassNames(sharedStyles.avatar, styles.avatar)}
                title={`${value.displayName} ${value.description || ""}`}
            />
        )}
    </Component>
);

const ValuePrincipal = ({
    value,
    className,
}: {
    value: SummaryAttributeValuePrincipal,
    className: string,
}) => {
    const props = useMemo(
        () =>
            value.email != null
                ? {
                      href: `mailto:${value.email}`,
                      rel: "noopener external noreferrer",
                      target: "_blank",
                      title: getLocalizedText("$summaryAttribute:user.email", {
                          name: value.displayName,
                      }),
                      Component: "a",
                  }
                : {},
        [value.email, value.displayName]
    );

    if (value.displayName == null) return null;
    return (
        <Principal
            value={value}
            className={getClassNames(className, styles.wrapper)}
            {...props}
        />
    );
};
ValuePrincipal.displayName = "AttributeSummaryValue.Principal";
export default ValuePrincipal;
