// @flow
import getPkce from "oauth-pkce";
import { Location } from "history";
import qs from "data/queryString";
import { persistor } from "data/storeHelper";
import localForage from "localforage";

/* istanbul ignore next */
/**
 * create a valid GUID
 *
 * @returns {string} random guid
 */
function randomGuid(): string {
    var guidHolder = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx";
    var hex = "0123456789abcdef";
    var r = 0;
    var guidResponse = "";
    for (var i = 0; i < guidHolder.length; i++) {
        if (guidHolder[i] !== "-" && guidHolder[i] !== "4") {
            // each x and y needs to be random
            r = (Math.random() * 16) | 0;
        }

        if (guidHolder[i] === "x") {
            guidResponse += hex[r];
        } else if (guidHolder[i] === "y") {
            // clock-seq-and-reserved first hex is filtered and remaining hex values are random
            r &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
            r |= 0x8; // set pos 3 to 1 as 1???
            guidResponse += hex[r];
        } else {
            guidResponse += guidHolder[i];
        }
    }
    return guidResponse;
}
// export as function for test mocking
const functions = {
    randomGuid,
};
export { functions };

/**
 * creates a URL to request authentication from identityServer
 *
 * @export
 * @param {Object} state can include a "from" property to specify the return route after login (used by {@link getAuthCallback})
 * @returns absolute url to identity login process
 */
export function getAuthUrl(
    state?: { from: string },
    code?: { code_verifier: string, code_challenge: string }
): string {
    const params = {
        client_id: window.CONFIG.auth.clientId,
        redirect_uri: window.CONFIG.auth.redirectUrl,
        response_type: `${window.CONFIG.auth.responseType || "id_token token"}${
            code ? " code" : ""
        }`,
        scope: `${window.CONFIG.auth.scope || "openid"} offline_access`,
        state: /* istanbul ignore next */ state
            ? JSON.stringify(state)
            : undefined,

        nonce: functions.randomGuid(),
        // https://github.com/IdentityModel/oidc-client-js/issues/624
        // https://stackoverflow.com/a/23945116/1069189
        resource: window.CONFIG.auth.resource,
        //login_hint: pre-fill username on login form
        ...code,
    };
    if (window.CONFIG.auth.forceLogin === true) {
        params.prompt = window.CONFIG.auth.forceLoginPrompt || "login";
    }
    // http://docs.identityserver.io/en/release/endpoints/authorize.html
    return window.CONFIG.auth.authorizeEndpoint + "?" + qs.stringify(params);
}

export async function getToken(params): string {
    const body = new URLSearchParams(params).toString();
    const response = await fetch(window.CONFIG.auth.tokenEndpoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body,
    });
    return response.json();
}

export type authInfo = {
    /** identity refresh_token */
    refresh_token: string,
    /** identity id_token */
    id_token: string,
    /** identity access_token */
    access_token: string,
    /** which route should the user land at */
    state: { from: string },
    /** ticks until tokens expire (absolute value, not related to token itself) */
    expires_in: number,
};

/**
 * parses the hash part of the url to retrieve identity authentication tokens - id_token, access_token, etc...
 * will also parse the state originally passed by {@link getAuthUrl}
 * @export
 * @param {Location} urlLocation
 * @returns object with all parsed identity values
 */
export function getAuthCallback(urlLocation: Location): authInfo {
    const search = urlLocation.hash.substring(1);
    const qr = qs.parse(search);
    /* istanbul ignore next */
    if (qr.error != null) {
        alert(qr.error_description);
        return null;
    }
    let expires_in = 73360; //1 hour default
    /* istanbul ignore else */
    if (qr.expires_in) {
        expires_in = parseInt(qr.expires_in, 10);
    }
    let expires_on = Date.now() + 1000 * expires_in;
    const parsed = parseJwt(qr.access_token || qr.id_token);
    if (parsed !== null && parsed.exp !== null) {
        expires_on = parsed.exp;
    }
    return {
        code: qr.code,
        id_token: qr.id_token,
        access_token: qr.access_token || qr.id_token,
        state: /* istanbul ignore next */ qr.state
            ? JSON.parse(qr.state)
            : { from: "/" },
        expires_in,
        expires_on,
    };
}

function parseJwt(token) {
    /* istanbul ignore next */
    const split = token?.split(".") ?? [];
    if (split.length < 2) {
        return null;
    }
    const base64Url = split[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

/**
 * will sign-out the current user invaliding the stored tokens and forwarding to the identity/EndSession Endpoint
 *
 * @export
 * @param {string} idToken
 */
export const signout = (idToken: string): Promise<void> => {
    // #43383 clear all persisted data
    persistor.pause();
    persistor.flush().then(() => {
        persistor.purge().then(() =>
            window.location.assign(
                window.CONFIG.auth.endSessionEndpoint +
                    "?" +
                    qs.stringify({
                        // by passing the original id_token the user gets a prompt to return to logoutRedirectUrl
                        id_token_hint: idToken,
                        // this must be the same as the Identity/Config.ClientStore.logoutRedirectUrl otherwise it wont work!
                        post_logout_redirect_uri:
                            window.CONFIG.auth.logoutRedirectUrl,
                    })
            )
        );
    });
};

/**
 * will return the current location pathname + search values but remove any basename from the route
 *
 * @export
 * @returns relative url of current route (without basename)
 */
export function signinFromLocation(): string {
    return (window.location.pathname + window.location.search).replace(
        window.CONFIG.host.basename,
        ""
    );
}

/**
 * will redirect user after successful login to his original requested route
 *
 * @export
 */
export function signinRedirect(): void {
    getPkce(43, async (error, { verifier, challenge }) => {
        /* istanbul ignore if */
        if (!!error) {
            console.error(error);
        }
        await localForage.setItem("code_verifier", verifier);
        window.location.assign(
            getAuthUrl(
                {
                    from: signinFromLocation(),
                },
                /* istanbul ignore next */
                window.CONFIG.auth.tokenEndpoint
                    ? {
                          code_challenge_method: "S256",
                          code_challenge: challenge,
                      }
                    : undefined
            )
        );
    });
}
