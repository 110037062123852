// @flow
import React, { useMemo } from "react";
import Icons from "@hs/icons";
import loc from "i18next";
import queryString from "data/queryString";
import { getRoute } from "components/ItemUriLink";
import AttributeLabel from "components/ExtraAttributes/AttributeLabel";
import ViewerControlBase from "components/ExtraAttributes/ViewerControl/ViewerControlBase";
import styles from "./LinkViewer.module.css";
import sharedStyles from "shared/styles.module.css";
import { getClassNames } from "data/utils";

type Props = {
    /** attribute name */
    name: string,
    /** current value */
    value: string | any,
    /** which link type to create (mailto, tel, ...) set as prefix (if not defined will show localized "open link" text */
    linkType?: string,
    /** value width */
    width?: number,
    /** which FontAwesome icon to render */
    icon?: string,
    /** whether icon should be place at beginning or end of text */
    isIconLast?: boolean,
    width?: number,
    className?: string,
    caption?: string,
    /** optional onClick handler */
    onClick?: () => void,
};

type ControlledProps = Props & {
    /** label text */
    header?: string,
    /** whether rendered inside a Grid */
    isGridCell: boolean,
};

const renderValue = (
    linkType?: string,
    value?: string,
    caption?: string,
    icon?: string,
    isIconLast?: boolean
) => (
    <>
        {icon && isIconLast === false && (
            <>
                <Icons.Library name={icon} />{" "}
            </>
        )}
        {linkType == null && caption == null ? (
            <span>{loc.t("plugin:extraAttributes.link_open")}</span>
        ) : (
            <span>{caption || value}</span>
        )}
        {icon && isIconLast && (
            <>
                {" "}
                <Icons.Library name={icon} />
            </>
        )}
    </>
);

const useAnchorProps = (value, linkType, width, isDoc) => {
    // #35977 convert inpoint:browse links
    let href = String(value || "");
    let target = "_blank";

    let comparableValue = String(value || "").toLowerCase();
    if (comparableValue.startsWith("inpoint:browse")) {
        const qs = queryString.parse(value.substr(value.indexOf("?")));
        /* istanbul ignore else */
        if (qs["uri"] !== undefined) {
            target = "_self";
            href = getRoute({
                itemUri: qs.uri,
                isExternalLink: true,
                isDoc,
            });
        }
    } else if (comparableValue.startsWith("pam-item://")) {
        target = "_self";
        href = getRoute({
            itemUri: comparableValue,
            isExternalLink: true,
            isDoc,
        });
    } else if (linkType != null) {
        href = `${linkType}:${href}`;
    }
    let style: CSSStyleDeclaration = useMemo(
        () => ({
            color: "#337ab7",
            width: width && width > 0 ? `${width}px` : "100px",
        }),
        [width]
    );
    return useMemo(() => ({ href, target, style }), [href, target, style]);
};

const LinkViewer = ({
    name,
    value,
    isDoc,
    linkType,
    width,
    icon,
    isIconLast,
    className,
    caption,
    onClick,
}: Props) => {
    const { style, href, target } = useAnchorProps(
        value,
        linkType,
        width,
        isDoc
    );

    // if there's no value for mail fields, don't display the icon or the mailto link
    if (!value && linkType === "mailto") {
        return <span className={className}></span>;
    }

    return href.length > 0 ? (
        <a
            href={href}
            style={style}
            className={className}
            target={target}
            onClick={onClick}
            rel="noopener noreferrer"
        >
            {renderValue(linkType, value, caption, icon, isIconLast)}
        </a>
    ) : (
        <span
            className={getClassNames(
                className,
                styles.nolink,
                sharedStyles.select_none,
                sharedStyles.cursor_forbidden
            )}
        >
            {loc.t("plugin:extraAttributes.link_empty")}
        </span>
    );
};
LinkViewer.displayName = "LinkViewer";

const LinkViewerControl = ({
    isGridCell,
    header,
    ...props
}: ControlledProps) => {
    if (isGridCell)
        return (
            <div style={{ textAlign: "center" }}>
                <LinkViewer {...props} />
            </div>
        );
    else
        return (
            <ViewerControlBase>
                <AttributeLabel label={header} />{" "}
                <LinkViewer
                    className={`form-control form-control-static ${sharedStyles.select_text}`}
                    {...props}
                />
            </ViewerControlBase>
        );
};

LinkViewerControl.displayName = "LinkViewerControl";
LinkViewerControl.defaultProps = {
    isGridCell: false,
    isIconLast: true,
    icon: "fa-regular fa-arrow-up-right-from-square",
};
export default LinkViewerControl;

export { LinkViewer };
