import React, { useState, useCallback, useMemo, useEffect } from "react";
import { getDisplayName } from "data/constants";
import { usePersistSetting, usePrevious } from "hooks";
import { produce } from "immer";
import { RememberValuesContext } from "data/context";
import {
    type NewFormTypeEnum,
    type DocumentFormDTO,
    NewFormType,
} from "data/types";
import { cacheStorage } from "data/storage";
import Checkbox from "./Checkbox";
import Control from "./Control";

type Props = {
    newFormType: NewFormTypeEnum,
    form: DocumentFormDTO,
};

const withRememberValues = (WrappedComponent: ComponentType<any>) => {
    const HOC = (props: Props) => {
        const rememberValues = usePersistSetting({
            name: "rememberValues",
            defaultValue: false,
            asObject: true,
        });
        const { value: remember, setValue: setRemember } = rememberValues;
        const isNewDocument = props.newFormType === NewFormType.Document;
        const computedRemember = remember && isNewDocument;

        const [selected, setSelected] = useState({});
        const [remembered, setRemembered] = useState({});
        const [availableValues, setAvailableValues] = useState({});
        const formatId = `${props.form?.formatId ?? ""}`;
        const prevFormatId = usePrevious(formatId);

        // load values from cache when remember is enabled
        useEffect(() => {
            (async () => {
                if (remember) {
                    const valuesString = await cacheStorage.getItem(formatId);
                    if (valuesString) {
                        const values = JSON.parse(valuesString);
                        setRemembered(values);
                        setSelected(values);
                    }
                } else {
                    await cacheStorage.removeItem(formatId);
                }
            })();
        }, [remember, formatId]);

        // save values to cache when remember is enabled
        useEffect(() => {
            (async () => {
                if (computedRemember) {
                    await cacheStorage.setItem(
                        formatId,
                        JSON.stringify(selected)
                    );
                }
            })();
        }, [computedRemember, formatId, selected]);

        // update available values when form changes
        useEffect(() => {
            if (prevFormatId === formatId) return;
            if (props.form) {
                const values = produce({}, (draft) => {
                    const mapFormDefaultValues = (item, prefix) =>
                        (draft[`${prefix}.${item.name}`] = item.defaultValue);
                    props.form.fields?.forEach((item) =>
                        mapFormDefaultValues(item, "ip")
                    );
                    props.form.attributes?.forEach((item) =>
                        mapFormDefaultValues(item, "ea")
                    );
                });
                setAvailableValues(values);
            }
        }, [prevFormatId, formatId, props.form]);

        // update selected values when available values change
        useEffect(() => {
            setSelected((selected) =>
                produce(selected, (draft) => {
                    Object.keys(draft).forEach((key) => {
                        /* istanbul ignore else */
                        if (availableValues.hasOwnProperty(key)) {
                            draft[key] = availableValues[key];
                        }
                    });
                })
            );
        }, [availableValues]);

        // update selected values when model changes
        const handleToggle = useCallback(
            (name) =>
                setSelected((selected) =>
                    produce(selected, (draft) => {
                        if (draft.hasOwnProperty(name)) {
                            draft[name] = null;
                            delete draft[name];
                        } else {
                            draft[name] = availableValues[name];
                        }
                    })
                ),
            [availableValues]
        );

        // check if value is selected
        const isSelected = useCallback(
            (name) => selected.hasOwnProperty(name),
            [selected]
        );

        // update available values when model changes
        const updateAvailableValues = useCallback(
            (model) => {
                isNewDocument &&
                    setAvailableValues((availableValues) =>
                        produce(availableValues, (draft) => {
                            Object.keys(model).forEach((type) => {
                                Object.keys(model[type]).forEach((name) => {
                                    const value = model[type][name];
                                    draft[`${type}.${name}`] = value;
                                });
                            });
                        })
                    );
            },
            [isNewDocument]
        );

        const maybeAddRememberedValues = useCallback(
            (defaultValues) => {
                if (!computedRemember) return defaultValues;
                const defaultValuesWithRememberedValues = produce(
                    defaultValues,
                    (draft) => {
                        Object.keys(remembered).forEach((name) => {
                            draft[name] = remembered[name];
                        });
                    }
                );
                return defaultValuesWithRememberedValues;
            },
            [computedRemember, remembered]
        );

        // render individual checkbox
        const renderCheckbox = useCallback(
            (name, label, className) =>
                isNewDocument &&
                name &&
                name !== "ip.$NAME$" && (
                    <Checkbox
                        name={name}
                        label={label}
                        className={className}
                        isSelected={isSelected}
                        handleToggle={handleToggle}
                    />
                ),
            [isNewDocument, handleToggle, isSelected]
        );

        // render control
        const renderControl = useCallback(
            (classNames = []) =>
                isNewDocument && (
                    <Control
                        classNames={classNames}
                        remember={remember}
                        setRemember={setRemember}
                    />
                ),
            [isNewDocument, remember, setRemember]
        );

        // create context
        const context = useMemo(
            () => ({
                remember: computedRemember,
                selected,
                renderCheckbox,
            }),
            [computedRemember, selected, renderCheckbox]
        );

        // create additional props
        const additionalProps = useMemo(
            () => ({
                renderControl,
                updateAvailableValues,
                maybeAddRememberedValues,
            }),
            [renderControl, updateAvailableValues, maybeAddRememberedValues]
        );

        return (
            <RememberValuesContext.Provider value={context}>
                <WrappedComponent {...props} rememberValues={additionalProps} />
            </RememberValuesContext.Provider>
        );
    };
    HOC.displayName = `withRememberValues(${getDisplayName(WrappedComponent)})`;
    return HOC;
};

export default withRememberValues;
