import { hasCacheSupport, isListed } from "data/utils";
import { getStorageEstimate } from "data/storage";
import { JL } from "data/logging";

export default class ApiCacheManager {
    constructor() {
        this._log = JL("ApiCacheManager");
        this.OpenCache = this.OpenCache.bind(this);
        this.DeleteCache = this.DeleteCache.bind(this);
        this.CacheExists = this.CacheExists.bind(this);
        this.LogEstimates = this.LogEstimates.bind(this);
    }

    /**
     * Returns a handle to the requested cache
     *
     * @returns {Promise<Cache>} cache handler
     * @memberof ApiCacheManager
     * @public
     */
    OpenCache = (id: string): Promise<Cache> => {
        if (!hasCacheSupport())
            throw new Error("Browser does not support cache!");
        return window.caches.open(id);
    };

    /**
     * Deletes the requested cache
     *
     * @returns {Promise<bool>} f the cache was actually deleted and a false if something went wrong or the cache doesn’t exist
     * @memberof ApiCacheManager
     * @public
     */
    DeleteCache = (id: string): Promise<boolean> => {
        if (!hasCacheSupport())
            throw new Error("Browser does not support cache!");
        return window.caches.delete(id);
    };

    /**
     * Checks if an existing Cache key already exists
     * @returns {Promise<boolean>} true or false
     * @memberof ApiCacheManager
     * @public
     */
    CacheExists = (id: string): Promise<boolean> => {
        if (!hasCacheSupport())
            throw new Error("Browser does not support cache!");
        return window.caches.keys().then((keys) => isListed(id, keys));
    };

    /**
     * Logs to the console some storage usage estimates
     *
     * @memberof ApiCacheManager
     * @public
     */
    LogEstimates = (id: string): void => {
        // show total number of cached items - unfortunately it is not possible to retrieve cache total in diskspace
        // https://github.com/w3c/ServiceWorker/issues/587
        this.OpenCache(id).then((cache) =>
            cache.keys().then(async (cachedRequests) => {
                this._log.info(() => ({
                    msg: `[storage] total of ${cachedRequests.length} cached items`,
                    id,
                }));
                const estimate = await getStorageEstimate();
                /* istanbul ignore next */
                if (estimate != null) {
                    this._log.info(() => ({
                        msg: `[storage] total usage estimate: ${estimate.usageInGb} out of ${estimate.quotaInGb}Gb used (${estimate.percentUsed}%)`,
                        estimate,
                        id,
                    }));
                }
            })
        );
    };
}
