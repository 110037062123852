// @flow
import React, { type ComponentType } from "react";
import { connect } from "react-redux";
import FormsyEditorBase from "./FormsyEditorBase";
import { fetchFormLookup, fetchFormLookupDependencies } from "data/api";
import {
    type ApiError,
    type FormLookupTypeEnum,
    FormLookupType,
    type FieldDefinitionDTO,
    type SearchFieldDefinitionDTO,
    type SearchFilterDTO,
    type FormLookupValue,
    type LookupValuesResultDTO,
    type SearchValueTypeEnum,
    SearchValueType,
    SearchOperator,
} from "data/types";
import loc from "i18next";
import { userLanguage } from "data/storeHelper";
import Constants, { type ConstantsEnum } from "data/constants";
import { AsyncPaginate } from "react-select-async-paginate";
import { createFilter, components } from "react-select";
import Status from "components/Status";
import CountBadge from "components/CountBadge";
import NativeSelect from "components/NativeSelect";
import { isHandheld } from "data/bowser";
import * as s from "data/reducers/selectors";
import Mark from "@hs/mark";
import SearchBucket from "components/FilterBar/SearchBucket";
import { getClassNames, closeMenuOnScroll } from "data/utils";
import styles from "./LookupEditorControl.module.css";

const { Option } = components;

type FetchFormResponseDTO = {
    options: Array<FormLookupValue>,
    hasMore: boolean,
    additional: { pageNum: number },
};

type Props = {
    /** whether handling a Document or Folder form */
    isDoc: boolean,
    /** requested formatId */
    formatId: number,
    /** requested itemUri */
    itemUri: string,
    /** commonName */
    name: string,
    /** current value */
    value: string,
    /** all ExtraAttributes */
    attributes: Array<FieldDefinitionDTO | SearchFieldDefinitionDTO>,
    /** Redux form changes */
    changes: ?Object,
    /** type of lookup */
    lookupType: FormLookupTypeEnum,
    /** when lookupType==FormLookupType.sf_ftlookup then this is the searchFormId */
    formId?: number,
    /** SearchValueType */
    valueType?: SearchValueTypeEnum,
    /** whether multiple selection is allowed */
    isMultiple: boolean,
    /** whether this is a required field */
    isRequired: boolean,
    /** whether this is a readonly field */
    isReadonly: boolean,
    /** filtering by matching any part of starts with */
    useContains: boolean,
    /** whether to use lookup values keys */
    useKeys: boolean,
    /** all available options */
    values?: Array<FormLookupValue>,
    /** placeholder text to display when @see {value} is empty */
    placeholder?: string,
    /** optional children to render */
    children?: ComponentType<*>,
    /** optional parentName (dependent lookups) */
    parentName: string,
};

type State = {
    inputValue: ?string,
    status: ConstantsEnum,
    loadIndex: number,
};

/* istanbul ignore next */
const getLoadingMessage = () => loc.t("search:select:loading");

/* istanbul ignore next */
const getNoOptionMessage = () => loc.t("search:select:noOptions");

export class LookupEditorControl extends React.Component<Props, State> {
    static defaultProps = {
        isDoc: false,
        formatId: 0,
        name: "",
        lookupType: FormLookupType.ea_lookup,
        isMultiple: false,
        isRequired: false,
        isReadonly: false,
        useContains: false,
        useKeys: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            status: props.values ? Constants.OK : Constants.LOADING,
            loadIndex: 0,
            inputValue: null,
        };
    }

    /** re-render only when value changes! */
    shouldComponentUpdate = (nextProps: Props, nextState: State) =>
        nextProps.itemUri !== this.props.itemUri ||
        nextProps.value !== this.props.value ||
        nextProps.values !== this.props.values ||
        // used to force reloading
        nextState.loadIndex !== this.state.loadIndex ||
        nextState.inputValue !== this.state.inputValue;

    componentDidUpdate(prevProps: Props) {
        /* istanbul ignore else */
        if (
            prevProps.attributes !== this.props.attributes ||
            prevProps.values !== this.props.values
        ) {
            this.setState(({ loadIndex }: State) => ({
                loadIndex: loadIndex + 1,
            }));
        }
    }

    getParentAsFilters = (filters = [], parentName = this.props.parentName) => {
        // Bail early if not EA
        if (this.props.attributes == null) {
            return filters;
        }
        const attr = this.props.attributes.find(
            (attr) => attr.name === parentName
        );
        // No parent found, return found filters
        if (attr == null) return filters;

        // Up the dependency tree
        if (attr.extra && attr.extra.parentLookupCommonName != null) {
            filters = this.getParentAsFilters(
                filters,
                attr.extra.parentLookupCommonName
            );
        }
        const filter: SearchFilterDTO = {
            name: parentName,
            op: SearchOperator.Equals,
            value: attr.value,
        };
        filters.push(filter);
        return filters;
    };

    getCurrentDependentLookupValues = (
        lookupValues = [],
        parentName = this.props.name.substring(3)
    ) => {
        // Bail early if not EA
        if (this.props.attributes == null) {
            return lookupValues;
        }
        // Re-use getParentAsFilters to get all parent values
        if (
            lookupValues.length === 0 &&
            parentName === this.props.name.substring(3)
        ) {
            lookupValues = this.getParentAsFilters(
                lookupValues,
                this.props.parentName
            ).map((filter) => ({
                name: filter.name,
                value: filter.value,
            }));
        }

        // Find any child
        const attr = this.props.attributes.find(
            (attr) =>
                attr.extra && attr.extra.parentLookupCommonName === parentName
        );

        if (attr == null) return lookupValues;

        const value = {
            name: attr.name,
            value: attr.value,
        };
        lookupValues.push(value);

        // Down the dependency tree
        return this.getCurrentDependentLookupValues(lookupValues, attr.name);
    };

    getValueByOperator = (value: any, op: SearchOperatorEnum) => {
        switch (op) {
            case SearchOperator.Contextor:
            case SearchOperator.ContextorContains:
                return value && value.T ? value.T : value;
            default:
                return value;
        }
    };

    fetchLookup = (
        keyword: string,
        loadedOptions: any,
        { pageNum }: { pageNum: number }
    ): Promise<FetchFormResponseDTO | null> => {
        if (this.props.values) {
            return Promise.resolve({
                options: this.props.values,
                hasMore: false,
                additional: { pageNum: 0 },
            });
        }
        const name = this.props.name.substring(3); //remove formPrefix
        // collect other attribute values
        let filters: Array<SearchFilterDTO> = [];
        if (
            this.props.lookupType === FormLookupType.sf_ftlookup &&
            this.props.changes != null
        ) {
            this.props.attributes.forEach((attr) => {
                const changed = this.props.changes[attr.name];
                // pass any other set filters (always pass ourselves as well)
                // NOTE: we need to pass everything to allow resetting predefined SearchParameters!
                // if (
                //     changed != null &&
                //     (changed.val != null || attr.name === name)
                // ) {
                const filter: SearchFilterDTO = {
                    name: attr.name,
                    op: changed.op,
                    value: this.getValueByOperator(changed.val, changed.op),
                    valueType: attr.valueType,
                    boostLeafNode: attr.boostLeafNode,
                    nodeType: attr.nodeType,
                };
                filters.push(filter);
                // }
            });
        }
        // collect any (parent(s)) parent value and add to filter
        if (this.props.parentName) {
            filters = this.getParentAsFilters(filters);
        }

        return fetchFormLookup({
            keyword,
            pageNum,
            isDoc: this.props.isDoc,
            formatId: this.props.formatId,
            itemUri: this.props.itemUri,
            name,
            lookupType: this.props.lookupType,
            id: this.props.formId,
            filters,
            values: this.props.attributes?.reduce((changes, a) => {
                changes[a.name] = a.value;
                return changes;
            }, {}),
        })
            .catch((err: ApiError) => {
                this.setState({
                    status: Constants.ERROR,
                });
                return null;
            })
            .then((result: ?LookupValuesResultDTO) => {
                if (result != null && result.values != null) {
                    this.setState({
                        // values,
                        status: Constants.OK,
                    });
                    return {
                        options: result.values,
                        hasMore: result.hasMore,
                        additional: { pageNum: pageNum + 1 },
                    };
                } else
                    return {
                        options: [],
                        hasMore: false,
                        additional: { pageNum: 0 },
                    };
            });
    };

    _fixValue = (value: ?any | ?Array<FormLookupValue> | ?FormLookupValue) => {
        if (Array.isArray(value)) {
            const values = value
                .map((v) => this._fixValue(v))
                .filter((v) => v != null);
            return values.length ? values : null;
        }
        return value?.T ? value : null;
    };

    _onChange = (
        value: ?Array<FormLookupValue> | ?FormLookupValue,
        changeValue: Function
    ) =>
        this._handleDependenciesUpdate(
            this._handleLookupValue(value),
            changeValue
        );

    _prepareOtherValueFor = (name: string, value: any): any => {
        const target = this.props.attributes?.find((a) => a.name === name);
        let v = value;
        if (
            ["List_Combo", "Lookup_Combo", "FullTextLookup_Combo"].includes(
                target?.format
            )
        ) {
            v = { T: `${value}` };
        }
        if (v != null && typeof v === "object" && target?.extra?.useKeys) {
            v.K = `${value}`;
        }
        if (target?.extra?.isMultiple) {
            v = [v];
        }
        return v;
    };

    _handleLookupValue = (
        rawValue: ?Array<FormLookupValue> | ?FormLookupValue,
        name: string = this.props.name
    ): Array<FormsyEditorBaseDTO> => {
        const changed: Array<FormsyEditorBaseDTO> = [];
        if (Array.isArray(rawValue)) {
            changed.push({
                name,
                value: this._fixValue(rawValue),
            });
        } else {
            const { O: others, ...current } = rawValue || {};
            const value = this._fixValue(current);
            changed.push({
                name,
                value,
            });
            if (others != null) {
                const splittedName = name.split(".");
                const prefix = splittedName.shift();
                Object.keys(others)
                    .filter((name) => name !== splittedName.join("."))
                    .forEach((name) => {
                        changed.push({
                            name: `${prefix}.${name}`,
                            value: this._prepareOtherValueFor(
                                name,
                                others[name]
                            ),
                        });
                    });
            }
        }
        return changed;
    };

    _handleDependenciesUpdate = (
        changed: Array<FormsyEditorBaseDTO>,
        changeValue: Function
    ) => {
        const splittedName = changed[0].name.split(".");
        const currentLookupValues = this.getCurrentDependentLookupValues();
        // Bail early if only one Lookup is present
        if (currentLookupValues.length === 0) {
            return changeValue(changed);
        }
        currentLookupValues.push({
            name: splittedName[1],
            value: changed[0].value,
        });
        fetchFormLookupDependencies({
            isDoc: this.props.isDoc,
            formatId: this.props.formatId,
            itemUri: this.props.itemUri,
            name: this.props.name.substring(3),
            lookupType: this.props.lookupType,
            currentLookupValues,
        })
            .then((dependentLookups) => {
                dependentLookups.forEach((lookup) => {
                    const _lookup = this._handleLookupValue(
                        lookup.value,
                        `${splittedName[0]}.${lookup.name}`
                    );
                    // append updated lookup & other values
                    changed = changed.concat(_lookup);
                });
                changeValue(changed);
            })
            .catch((e) => {
                changeValue(changed);
            });
    };

    _onInputChange = (inputValue: string): void =>
        this.setState({ inputValue });

    _valueRenderer = (props: any) => {
        const { value } = props;
        switch (this.props.valueType) {
            case SearchValueType.Decimal:
            case SearchValueType.DecimalFieldOnly:
                return value.toLocaleString(userLanguage());
            default:
                const valueWithIcon = (
                    <SearchBucket.Icon
                        name={this.props.name.substring(3)}
                        value={value}
                    />
                );
                /* istanbul ignore next */
                return valueWithIcon != null ? (
                    <>
                        {valueWithIcon} {value}
                    </>
                ) : (
                    value
                );
        }
    };

    _optionRenderer = (props: any) => {
        // https://github.com/JedWatson/react-select/issues/3128#issuecomment-451936743
        const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
        const newProps = Object.assign({}, props, { innerProps: rest });
        const ValueComponent = this._valueRenderer;
        ValueComponent.displayName = "LookupEditorControl._valueRenderer";
        return (
            Option && (
                <Option
                    key={
                        /* istanbul ignore next */ this.props.useKeys
                            ? props.data.K
                            : props.data.T
                    }
                    {...newProps}
                    className={getClassNames(props.className, styles.option)}
                >
                    {this.props.lookupType === FormLookupType.sf_ftlookup ? (
                        <span>
                            <ValueComponent value={props.data.T} />
                        </span>
                    ) : (
                        <Mark word={props.selectProps.inputValue}>
                            <ValueComponent value={props.data.T} />
                        </Mark>
                    )}
                    <CountBadge count={props.data.C} />
                </Option>
            )
        );
    };

    render = () => (
        <FormsyEditorBase
            loadIndex={`l-${this.props.name}-${this.state.loadIndex}`}
            required={
                this.props.isRequired ? "isDefaultRequiredValue" : undefined
            }
            render={(value, changeValue) => {
                /* istanbul ignore if */
                if (this.state.status === Constants.ERROR)
                    return <Status inline status={Constants.ERROR} />;
                else {
                    // only on mobile with values fallback to HTML select
                    if (isHandheld() && this.props.values) {
                        return (
                            <NativeSelect
                                valueKey={
                                    /*istanbul ignore next*/ this.props.useKeys
                                        ? "K"
                                        : "T"
                                }
                                labelKey="T"
                                className="form-control Select-control"
                                value={this._fixValue(value)}
                                disabled={this.props.isReadonly}
                                isMultiple={this.props.isMultiple}
                                placeholder={
                                    this.props.placeholder ||
                                    loc.t("common:validation.select")
                                }
                                onChange={
                                    /* istanbul ignore next */ (item) =>
                                        this._onChange(item, changeValue)
                                }
                                options={this.props.values}
                                inputId={this.props.name}
                            />
                        );
                    } else {
                        // otherwise use full-featured react-select
                        return (
                            <>
                                <AsyncPaginate
                                    components={{
                                        Option: this._optionRenderer,
                                    }}
                                    // allows us to open outside of contained box modal
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    closeMenuOnScroll={closeMenuOnScroll}
                                    // styling
                                    className="rs"
                                    classNamePrefix="rs"
                                    isDisabled={this.props.isReadonly}
                                    styles={{
                                        container: /* istanbul ignore next */ (
                                            provided,
                                            state
                                        ) => ({
                                            ...provided,
                                            width: "100%",
                                            minWidth: 120,
                                            whiteSpace: "normal",
                                        }),
                                        control: /* istanbul ignore next */ (
                                            provided,
                                            state
                                        ) => ({
                                            ...provided,
                                            height: this.props.isMultiple
                                                ? "auto"
                                                : 34,
                                            minHeight: "fit-content",
                                            borderRadius: 0,
                                            boxShadow:
                                                "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
                                        }),
                                        valueContainer:
                                            /* istanbul ignore next */ (
                                                provided,
                                                state
                                            ) => ({
                                                ...provided,
                                                padding: "0px 6px",
                                                minHeight: 34,
                                            }),
                                        input: /* istanbul ignore next */ (
                                            provided,
                                            state
                                        ) => ({
                                            ...provided,
                                            margin: 0,
                                            padding: 0,
                                        }),
                                        dropdownIndicator:
                                            /* istanbul ignore next */ (
                                                provided,
                                                state
                                            ) => ({
                                                ...provided,
                                                padding: 4,
                                            }),
                                        indicatorSeparator:
                                            /* istanbul ignore next */ () => ({
                                                display: "none",
                                            }),
                                        clearIndicator:
                                            /* istanbul ignore next */ (
                                                provided,
                                                state
                                            ) => ({
                                                ...provided,
                                                padding: 0,
                                            }),
                                        menu: /* istanbul ignore next */ (
                                            provided,
                                            state
                                        ) => ({
                                            ...provided,
                                            marginTop: 0,
                                            borderRadius: 0,
                                            width: "auto",
                                            minWidth: "100%",
                                            // left: -300, // to set the dropdown bigger than the field - needs work to align correctly (and to the right side!)
                                            // minWidth: 300,
                                            // zIndex: 1051
                                        }),
                                        menuPortal: /* istanbul ignore next */ (
                                            provided,
                                            state
                                        ) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                        placeholder:
                                            /* istanbul ignore next */ (
                                                provided,
                                                state
                                            ) => ({
                                                ...provided,
                                                whiteSpace: "nowrap",
                                                color: "hsl(0, 0%, 80%)",
                                            }),
                                        option: /* istanbul ignore next */ (
                                            provided,
                                            state
                                        ) => {
                                            if (state.isSelected) {
                                                return Object.assign(
                                                    {},
                                                    provided,
                                                    {
                                                        boxShadow: "none",
                                                        background: "#0098AA",
                                                        textShadow: "none",
                                                        cursor: "pointer",
                                                        // border: "1px solid #f5f5f5"
                                                    }
                                                );
                                            } else if (state.isFocused) {
                                                return Object.assign(
                                                    {},
                                                    provided,
                                                    {
                                                        boxShadow: "none",
                                                        background: "#f5f5f5",
                                                        textShadow: "none",
                                                        cursor: "pointer",
                                                        // border: "1px solid #f5f5f5"
                                                    }
                                                );
                                            }
                                            return provided;
                                        },
                                    }}
                                    // async-paginate
                                    loadOptions={this.fetchLookup}
                                    debounceTimeout={300}
                                    additional={{
                                        pageNum: 0,
                                    }}
                                    cacheUniqs={[this.state.loadIndex]}
                                    onMenuClose={
                                        /*istanbul ignore next*/ () =>
                                            this.setState(
                                                (prevState: State) => ({
                                                    loadIndex:
                                                        prevState.loadIndex + 1,
                                                })
                                            )
                                    }
                                    // defaultOptions={true}
                                    // options
                                    getOptionLabel={
                                        /* istanbul ignore next */ (option: {
                                            T: "",
                                        }) => option.T
                                    }
                                    getOptionValue={
                                        /* istanbul ignore next */ (option: {
                                            K: "",
                                            T: "",
                                        }) =>
                                            this.props.useKeys
                                                ? option.K
                                                : option.T
                                    }
                                    filterOption={createFilter({
                                        ignoreAccents: false,
                                    })}
                                    // behavior
                                    isClearable={true}
                                    openMenuOnFocus={true}
                                    // localizations
                                    placeholder={
                                        this.props.placeholder ||
                                        loc.t("common:validation.select")
                                    }
                                    loadingMessage={getLoadingMessage}
                                    noOptionsMessage={getNoOptionMessage}
                                    // selection
                                    onChange={
                                        /* istanbul ignore next */ (item) =>
                                            this._onChange(item, changeValue)
                                    }
                                    value={this._fixValue(value)}
                                    inputValue={this.state.inputValue}
                                    onInputChange={this._onInputChange}
                                    isMulti={this.props.isMultiple}
                                    delimiter=","
                                    inputId={this.props.name}
                                />
                                {this.props.children}
                            </>
                        );
                    }
                }
            }}
            {...this.props}
            value={this._fixValue(this.props.value)}
        />
    );
}

const mapStateToProps = /* istanbul ignore next */ (
    state: State,
    ownProps: Props
) => ({
    changes: s.searchFormChangesSelector(state),
    formId:
        ownProps.lookupType === FormLookupType.sf_ftlookup
            ? s.searchFormIdSelector(state)
            : -1,
    ...ownProps,
    // #56024 disable FT lookup when offline
    isReadonly: s.isOnlineSelector(state)
        ? ownProps.isReadonly
        : ownProps.lookupType === FormLookupType.ea_ftlookup,
});

export default connect(mapStateToProps)(LookupEditorControl);
