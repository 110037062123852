import React from "react";
import { type SummaryAttributeValueFilesize } from "data/types";

export const ValueFilesize = ({
    className,
    value,
}: {
    className: string,
    value: SummaryAttributeValueFilesize,
}) => {
    return <span className={className}>{value.value}</span>;
};
ValueFilesize.displayName = "SummaryAttribute.Value.Filesize";

export default ValueFilesize;
