import { openExternalLinkModal } from "components/ExternalLinkModal";
import { stringForFormTokens, withFormTokens } from "./utils/FormTokens";
import { mapValues, tokenize } from "data/utils";

/**
 * opens the specified url either in a modal popup or new browser window
 * @param {{ asModal?: boolean}} { asModal } whether to open as Modal within inPoint
 * @param {{ docked?: boolean}} { docked } whether to open as a Docked Modal (only with asModal=true)
 * @param {{ url: string }} { url } supports interpolation with any context properties
 * @param {{ title?: string }} { title } Modal or Browser window title
 * @param {{ specs?: string }} { specs } Browser window(..., specs) specifications
 * @param {{ useFormTokens?: boolean }} { useFormTokens } whether to load all Form fields/attributes for the tokens
 */

type Context = {
    asModal?: boolean,
    docked?: boolean,
    url: string,
    title?: string,
    specs?: string,
    useFormTokens?: boolean,
    context: ?Object,
};

class link {
    execute = async ({
        asModal,
        docked,
        url,
        title,
        specs,
        useFormTokens,
        multiple,
        items,
        ...context
    }: Context): Promise<boolean> => {
        let encodedContext = {};
        if (multiple && (items?.length ?? 0)) {
            encodedContext.itemUri = items.map((i) =>
                encodeURIComponent(i.itemUri)
            );
            encodedContext.name = items.map((i) => encodeURIComponent(i.name));
        } else {
            encodedContext = mapValues(context, (value) =>
                encodeURIComponent(value)
            );
            if (useFormTokens === true) {
                encodedContext = await withFormTokens(
                    context,
                    encodedContext,
                    true
                );
            }
        }
        const tokenizedUrl = tokenize(stringForFormTokens(url), encodedContext);
        if (asModal === true) {
            openExternalLinkModal({ url: tokenizedUrl, name: title, docked });
        } else {
            window.open(
                tokenizedUrl,
                title || "",
                specs || "" // "width=600px,height=640px"
                //"scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=300,left=100,top=100"
            );
        }
        return true;
    };
}
export default link;
