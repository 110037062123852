import { openProgressModal } from "components/ProgressModal";
import { LocationToString } from "components/MapViewer/utils";
import loc from "i18next";
import geoLocatorOptions from "components/LocatorButton/geoLocatorOptions";

type Props = {
    /** callback when user's geolocation successfully retrieved
     * @param {string} strLocation string coordinates
     */
    onLocation: (strLocation: string) => void,
};

const getGeoLocation = async ({ onLocation }: Props) => {
    const geolocator = await import("geolocator");
    return openProgressModal({
        icon: "earth-europe",
        title: loc.t("common:location.loading"),
        doWork: (close, setError) =>
            geolocator.default.locate(geoLocatorOptions(), (err, location) => {
                if (err) {
                    /* istanbul ignore next */
                    if (err.message != null) setError(err.message);
                    else setError("Unknown Error Occured.");
                    console.warn(err);
                } else {
                    onLocation(LocationToString(location.coords));
                    close();
                }
            }),
    });
};

export default getGeoLocation;
