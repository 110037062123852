// @flow
import React, { type Node } from "react";
import loc from "i18next";
import styles from "./NoData.module.css";

const NoData = ({
    locContext,
    children,
}: {
    locContext?: string,
    children?: Node,
}) => (
    <div className={styles.nodata} data-test="noData">
        {loc.t("nodata", { context: locContext })}
        {children}
    </div>
);
NoData.displayName = "NoData";
export default NoData;
