import "core-js/stable";
import "data/extensions"; // Extension methods for vanilla js objects
import React from "react";
import ReactDOM from "react-dom";
import Loader from "components/Loader";
import bowser from "data/bowser";
import "bootstrap-no-fonts-no-js/css/bootstrap.css";
import "bootstrap-no-fonts-no-js/css/bootstrap-theme.css";
import "./index.css";
import "@hs/clearable-input/build/ClearableInput.css";
import "@hs/close-button/build/CloseButton.css";
import "@hs/dropdown/build/Dropdown.css";
import "@hs/form-bar/build/FormBar.css";
import "@hs/grid-list/build/GridList.css";
import "@hs/search-input/build/SearchInput.css";
import store, { sagaMiddleware } from "data/store";
import { Provider } from "react-redux";
import {
    serviceWorkerInitializedAction,
    serviceWorkerUpdatedAction,
} from "data/actions";
import * as serviceWorkerRegistration from "data/offline/serviceWorkerRegistration";
import { isFeatureOn, Feature } from "data/constants";
import rootSaga from "data/sagas/rootSaga";
import "@hs/icons/build/Icons.css";

// run the rootSaga which forks all the others
sagaMiddleware.run(rootSaga);

// import reportWebVitals from "./reportWebVitals";

/* istanbul ignore next */
const Root = Loader({
    loader: () => import("containers/Root" /* webpackChunkName: "Root" */),
});

export function browserCheck() {
    // Browser detection
    //console.log(`Browser ${bowser.getBrowserName()} v${bowser.getBrowserVersion()}`, bowser);
    // Browser/OS mappings: https://github.com/lancedikson/bowser/blob/master/src/constants.js
    /* istanbul ignore next */
    if (
        !bowser.satisfies({
            chrome: ">41",
            edge: ">90",
            firefox: ">89",
            safari: ">13",
            opera: ">76",
            android: ">7",
            iOS: ">13",
            samsung_internet: ">13",
            blackberry: ">10",
            electron: ">10",
        })
    ) {
        window.alert(
            "Sorry, your Browser or Version is unsupported - see Browser Requirements"
        );
        window.location.assign(
            "https://serviceportal.hs.ag/docs/inPoint.Web/docs/admin-gettingstarted.html#client"
        );
        return false;
    }
    return true;
}

/* istanbul ignore next */
if (browserCheck()) {
    if (isFeatureOn(Feature.offline)) {
        serviceWorkerRegistration.register({
            onSuccess: (registration) => {
                store.dispatch(
                    serviceWorkerInitializedAction({ registration })
                );
            },
            onUpdate: (registration) => {
                store.dispatch(serviceWorkerUpdatedAction({ registration }));
            },
            onFailure: (error) => {
                store.dispatch(serviceWorkerInitializedAction({ error }));
            },
        });
    } else {
        serviceWorkerRegistration.unregister();
    }

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    // reportWebVitals(console.log);
    if (process.env.JEST_WORKER_ID === undefined) {
        ReactDOM.render(
            // <React.StrictMode>
            <Provider store={store}>
                <Root />
            </Provider>,
            // </React.StrictMode>,
            document.getElementById("root")
        );
    }
}
