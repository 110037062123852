import { transfer as transferApi, getFolderItemUri } from "data/api";
import { toastActionResult, toastStyled, toastTypes } from "data/toast";
import { openPickerModal, PickerType } from "components/PickerModal";
import {
    NotificationStyle,
    TransferType,
    type TransferTypeEnum,
    type TransferItemRequestDTO,
} from "data/types";
import loc from "i18next";

/**
 * Transfer by type a given resource by itemUri
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name: string }} { name }
 * @param {{ type: TransferTypeEnum }} { type }
 * @param {{ isDoc: bool }} { isDoc }
 */

type Context = {
    itemUri: string,
    name: string,
    type: TransferTypeEnum,
    isDoc: boolean,
};

class transfer {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;
    shouldRender: boolean = true;
    transferRequest: ?TransferItemRequestDTO = null;

    render = async ({ itemUri, name, type }: Context) => {
        this.shouldRender = false;
        const folderUri = await getFolderItemUri(itemUri);
        let selectButton = loc.t("commandAction:transfer.copy.name");
        const additionalActions = [];
        const onSelect = (eventKey) => (type = eventKey);
        switch (type) {
            case TransferType.Copy:
                break;
            case TransferType.Move:
                selectButton = loc.t("commandAction:transfer.move.name");
                break;
            case TransferType.Link:
                selectButton = loc.t("commandAction:transfer.link.name");
                break;
            default:
                additionalActions.push({
                    label: loc.t("commandAction:transfer.move.name"),
                    eventKey: TransferType.Move,
                });
                additionalActions.push({
                    label: loc.t("commandAction:transfer.link.name"),
                    eventKey: TransferType.Link,
                });
                // Set default type
                type = type || TransferType.Copy;
                break;
        }
        const targetItemUri = await openPickerModal({
            pickerType: PickerType.Folder,
            scopeBreadcrumb: false,
            itemUri: folderUri,
            helpText: loc.t("commandAction:transfer.helpText", { name }),
            selectButton,
            onSelect,
            additionalActions,
        });
        if (targetItemUri === false) return false;
        this.transferRequest = {
            type,
            targetItemUri,
        };
        return true;
    };

    execute = async ({ itemUri, name, isDoc }: Context): Promise<boolean> => {
        try {
            const success = await transferApi({
                ...this.transferRequest,
                itemUri,
                name,
            });
            if (this.transferRequest?.type === TransferType.Move && !isDoc) {
                toastStyled({
                    ...toastTypes.failure,
                    icon: "fa-regular fa-exclamation-triangle",
                    style: NotificationStyle.Warning,
                    toastId: "transfer",
                    message: `$commandAction:transfer.${this.transferRequest?.type}.warning`,
                });
            }
            return success;
        } catch (error) {
            return false;
        }
    };
    report = (success: boolean, { name }: Context) => {
        this.logger(
            success,
            `commandAction:transfer.${this.transferRequest?.type}`,
            { name }
        );
    };
}

export default transfer;
