// @flow
import React from "react";
import Status from "./index";
import Constants from "data/constants";

const Loading = ({
    isLoading,
    pastDelay,
    error,
}: {
    isLoading: boolean,
    pastDelay: boolean,
    error: boolean,
}) => {
    if (!pastDelay && isLoading) return null;
    let status = error ? Constants.ERROR : Constants.LOADING;
    return <Status status={status} />;
};

export default Loading;
