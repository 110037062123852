// @flow
import React, { PureComponent, type ComponentType } from "react";
import { connect } from "react-redux";
import { getDisplayName, isMiniView } from "data/constants";
import { toastStyled, toastTypes } from "data/toast";
import * as s from "data/reducers/selectors";
import { getRoute } from "components/ItemUriLink";
import {
    RefreshMode,
    type RefreshModeEnum,
    type ServerEventMessage,
    type ServerEventItemEnum,
    type ServerEventActionEnum,
} from "data/types";

type Config = {
    actions?: Array<ServerEventActionEnum>,
    items?: Array<ServerEventItemEnum>,
    /** Whom do we want to auto refresh */
    refreshMode?: RefreshModeEnum,
    /** Message, if not auto refresh */
    refreshMessage?: string,
    /** Refresh Route (e.g. if itemUri was deleted) */
    refreshRoute?: boolean,
};

type Props = {
    /** current globalEvents from store */
    globalEvents: ?ServerEventMessage,
    /** current users Id */
    userId: number,
    /** current itemUri */
    itemUri?: string,
    /** React-router History */
    history?: History,
    /** is in mini view */
    isMini?: boolean,
};

type State = {
    refreshId: number,
    refreshEvent: ?ServerEventMessage,
};

/**
 * will intercept globalEvents changes and provide a refreshId property
 */
const GlobalEventsHandler =
    ({
        actions = [],
        items = [],
        refreshMode = RefreshMode.All,
        refreshMessage = "",
        refreshRoute = false,
    }: Config) =>
    (WrappedComponent: ComponentType<any>) => {
        class PP extends PureComponent<Props, State> {
            static displayName = `GlobalEventsHandler(${getDisplayName(
                WrappedComponent
            )})`;

            state = {
                refreshId: 0,
                refreshEvent: null,
            };

            componentDidUpdate(prevProps: Props) {
                // console.log(prevProps.globalEvents.id, this.props.globalEvents.id);

                // new event?
                let doRefresh =
                    prevProps.globalEvents.id !== this.props.globalEvents.id &&
                    this.props.globalEvents.data != null;
                if (!doRefresh) return;

                // specific itemUri?
                doRefresh =
                    this.props.itemUri == null
                        ? true
                        : // is this the one we are currently displaying?
                          this.props.globalEvents.data.itemUri ===
                              this.props.itemUri ||
                          this.props.globalEvents.data.parentItemUri ===
                              this.props.itemUri;
                if (!doRefresh) return;

                if (
                    actions.length > 0 &&
                    !actions.includes(this.props.globalEvents.action)
                )
                    return;
                if (
                    items.length > 0 &&
                    !items.includes(this.props.globalEvents.item)
                )
                    return;
                const refresh: string =
                    refreshRoute && this.props.globalEvents.data.parentItemUri
                        ? "_refreshRoute"
                        : "_refreshId";
                if (refreshMode === RefreshMode.Self) {
                    /* istanbul ignore else */
                    if (
                        this.props.globalEvents.data.userId ===
                        this.props.userId
                    ) {
                        this[refresh]();
                    }
                } else if (
                    refreshMode === RefreshMode.Originator &&
                    this.props.globalEvents.data.userId !== this.props.userId
                ) {
                    toastStyled({
                        ...toastTypes.refresh,
                        message: refreshMessage,
                        handleClick: this[refresh],
                    });
                } else {
                    this[refresh]();
                }
            }

            _refreshId = (): void => {
                this.setState((prevState: State) => ({
                    refreshId: prevState.refreshId + 1,
                    refreshEvent: this.props.globalEvents,
                }));
            };

            _refreshRoute = (): void => {
                const { history, isMini } = this.props;
                if (typeof history === "undefined") {
                    console.error(
                        "Refreshing routes is not possible without props history and isMini"
                    );
                    return;
                }
                history.push(
                    getRoute({
                        itemUri: this.props.globalEvents.data.parentItemUri,
                        isMini,
                    })
                );
            };

            render() {
                const { globalEvents, userId, ...rest } = this.props;
                return (
                    <WrappedComponent
                        {...rest}
                        refreshId={this.state.refreshId}
                        refreshEvent={this.state.refreshEvent}
                    />
                );
            }
        }

        const mapStateToProps = (state, ownProps) => ({
            globalEvents: state.globalEvents,
            userId: s.userIdSelector(state),
            // We might not have a history prop => fallback
            isMini:
                ownProps.isMini ||
                (ownProps.history
                    ? isMiniView(ownProps.history.location)
                    : false),
            ...ownProps,
        });

        return connect(mapStateToProps)(PP);
    };

export default GlobalEventsHandler;
