import React, { useState } from "react";
import { useAsync } from "hooks";
import { fetchItemFlowForm } from "data/api";
import {
    type NewFormTypeEnum,
    type ItemFlowTemplateTypeEnum,
} from "data/types";
import FormContainer from "containers/Form";
import Status from "components/Status";
import Constants from "data/constants";
import loc from "i18next";
import { produce } from "immer";
import { getLocalizedText } from "data/utils";
import { toastActionResult } from "data/toast";

type Props = {
    /** current flow selected by user */
    flow: ItemFlowConfigDTO,
    /** current viewName */
    viewName: string,
    /** target folder itemUri to create item in */
    itemUri: string,
    /** callback when user wants to go a step back */
    onBack?: () => void,
    /** callback when user cancel */
    onCancel?: () => void,
    /** callback when user wants to create the item
     * @async
     * @param {object} model the form's values
     * @returns {boolean} true if success, false otherwise
     */
    onCreate?: (model: Object) => Promise<boolean>,
    /** callback when item has been created
     * @param {boolean} success true if created successfully
     * @param {Object} error error object in case success is false
     * @param {NewItemResultDTO} data additional data related to new item
     */
    onCreated?: (
        success: boolean,
        error?: Object,
        data?: NewItemResultDTO
    ) => void,
    /** form default values */
    defaultValues?: Object,
    /** optional whether to preview the newly created item
     * @default true
     */
    previewNewItem?: boolean,
    /**
     * @type {NewFormTypeEnum}
     */
    formType: NewFormTypeEnum,
    /**
     * @type {ItemFlowTemplateTypeEnum}
     */
    templateType: ItemFlowTemplateTypeEnum,
    /**
     * @type {string}
     */
    templateId: string,
};

// centrally load new form definition required by any NewItemType (since all use /api/item/templates/newform)
// one thing special here: props.onCreate is expected to actually create the item,
// props.onCreated is called by parent to notify NewItemModal that it was actually created

const Form = (props: Props) => {
    // we might need to manipulate defaultValues
    const [defaultValues, setDefaultValues] = useState(
        props.defaultValues || {}
    );

    // get form definition
    const state = useAsync(async () => {
        try {
            const form = await fetchItemFlowForm({
                formType: props.formType,
                itemUri: props.itemUri,
                templateId: props.templateId,
                templateType: props.templateType,
            });
            // in order for WebDavName to be included in Save(model) it needs to be manually added to defaultValues for changeLog to take effect
            if (defaultValues == null || defaultValues["$NAME$"] == null) {
                const nameField = form.fields.find(
                    (f) => f.format === "WebDavName" && f.name === "$NAME$"
                );
                if (nameField != null) {
                    setDefaultValues(
                        produce(defaultValues, (draft) => {
                            draft["$NAME$"] = nameField.value;
                        })
                    );
                }
            }
            return form;
        } catch (err) {
            console.error("Error while fetchItemFlowForm", err);
            props.onCancel();
            toastActionResult(false, "itemflows:form");

            throw err;
        }
    }, [props.flow]);

    if (state.loading) return <Status status={Constants.LOADING} />;
    if (state.error) return <Status status={Constants.ERROR} />;

    return (
        <FormContainer
            form={state.value}
            itemUri={props.itemUri}
            viewName={props.viewName}
            isEditMode={true}
            onCancel={props.onCancel}
            onBack={props.onBack}
            onSaveNew={props.onCreate}
            saveLabel={loc.t("itemflows:form.create")}
            savingLabel={loc.t("itemflows:form.creating")}
            defaultFormTitle={getLocalizedText(props.flow.name)}
            defaultFormIcon="plus-circle"
            //isFormButtonEnabled={this.state.isFormButtonEnabled}
            defaultValues={defaultValues}
            newFormType={props.formType}
            onSaveReturnAllFields={true}
        />
    );
};
Form.displayName = "ItemFlows.Form";
export default Form;
