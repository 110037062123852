import React from "react";

export const PaneSizeContext = React.createContext();
PaneSizeContext.displayName = "PaneSize";

export const LabelSizeContext = React.createContext();
LabelSizeContext.displayName = "LabelSize";

export const CurrentItemContext = React.createContext();
CurrentItemContext.displayName = "CurrentItem";

export const MultiSelectionContext = React.createContext();
MultiSelectionContext.displayName = "MultiSelection";

export const ItemContext = React.createContext();
ItemContext.displayName = "ItemContext";

export const RememberValuesContext = React.createContext();
RememberValuesContext.displayName = "RememberValuesContext";
