// @flow
import React, { useContext } from "react";
import { LabelSizeContext } from "data/context";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import styles from "./AttributeLabel.module.css";
import sharedStyles from "shared/styles.module.css";

type Props = {
    /** the field's label to display */
    label?: ?string,
    /** optional CSS Styles to apply */
    style?: CSSStyleDeclaration,
};

const AttributeLabel = ({ label, style = {}, children = null }: Props) => {
    const labelWidth = useContext(LabelSizeContext);
    if (labelWidth) style.width = labelWidth;
    // NOTE: to hide the labels one space will be used in the ExtraAttributeDefinition
    if (label && String(label.trim()).length > 1)
        return (
            <ControlLabel
                className={`${styles.label} ${sharedStyles.select_text}`}
                title={label}
                style={style}
            >
                <span className={styles.text}>{label}</span>
                {children}
            </ControlLabel>
        );
    return children ? <span className={styles.label}>{children}</span> : null;
};
AttributeLabel.displayName = "AttributeLabel";
export default AttributeLabel;
