// @flow
import React, { PureComponent, type Node } from "react";
import uppy from "data/uppy";
import { toastActionResult } from "data/toast";
import { getClassNames } from "data/utils";
import isDragDropSupported from "@uppy/utils/lib/isDragDropSupported";
import getDroppedFiles from "@uppy/utils/lib/getDroppedFiles";
import "./index.css";

export type DroppedFile = {
    source: string,
    name: string,
    type: string,
    data: Object,
};

type Props = {
    /** whether to allow dropping file on these children */
    allowDrop: boolean,
    /** called after files have been dropped
     * @param {Array<DroppedFile>} files dropped files ready for uppy - only if @see {addFilesToUppy} is false!
     */
    onDrop: (files: Array<DroppedFile>) => void,
    /** React node(s) to render */
    children: ?Node,
    /** HTML id attribute */
    id: ?string,
    /** HTML style attribute */
    style: ?string,
    /** HTML class attribute */
    className: ?string,
    /** Component to apply the FileDropHandler (must be HTML Tag string or Component that forwards the filedrop ref ) */
    component: ?string | PureComponent,
    /** whether to automatically add files to global uppy instance */
    addFilesToUppy?: boolean,
    /** message to show while user is dragging over the targetEl dropzone */
    dragOverText?: string,
    /** relative path */
    relativePath?: string,
};

type State = {
    isDragover: boolean,
    isDrop: boolean,
};

/**
 * This will watch @see {targetEl} as a dropzone and process the files (by adding them to uppy)
 * and finally calling the onDrop callback
 * @export
 */
export default class FileDropHandler extends PureComponent<Props, State> {
    _dropzone: ?React$ElementRef<ElementType>;
    _timeout: ?TimeoutID;
    _isMounted: boolean;

    static defaultProps = {
        addFilesToUppy: false,
        relativePath: null,
    };

    constructor(props) {
        super(props);
        this._dropzone = React.createRef();
        this.state = {
            isDragover: false,
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
        this._registerHandler();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        this._unregisterHandler();
    };

    componentDidUpdate(prevProps: Props) {
        // allowDrop changed
        /* istanbul ignore else */
        if (prevProps.allowDrop !== this.props.allowDrop) {
            if (this.props.allowDrop === false) {
                this._unregisterHandler();
            } else {
                this._registerHandler();
            }
        }
    }

    _registerHandler = () => {
        if (this.props.allowDrop !== true || !isDragDropSupported()) {
            return;
        }
        this._dropzone.current.addEventListener("drop", this.onDrop);
        this._dropzone.current.addEventListener("dragover", this.onDragOver);
        this._dropzone.current.addEventListener("dragleave", this.onDragLeave);
    };

    _unregisterHandler = () => {
        this._dropzone.current.removeEventListener("drop", this.onDrop);
        this._dropzone.current.removeEventListener("dragover", this.onDragOver);
        this._dropzone.current.removeEventListener(
            "dragleave",
            this.onDragLeave
        );
        clearTimeout(this._timeout);
    };

    onDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = "copy";

        if (!this.isFileDrag(event)) {
            toastActionResult(false, "action_drop");
            return;
        }

        getDroppedFiles(event.dataTransfer).then((files) => {
            let addedCount = 0;
            const filesDTO = files.map((data) => {
                const fileDTO = {
                    source: "FileDropHandler",
                    name: data.name,
                    type: data.type,
                    data,
                    meta: {
                        name: data.name,
                        relativePath: this.props.relativePath,
                    },
                };

                /* istanbul ignore else */
                if (this.props.addFilesToUppy)
                    try {
                        uppy.addFile(fileDTO);
                        addedCount++;
                    } catch (err) {
                        /* istanbul ignore next */
                        if (!err.isRestriction) {
                            uppy.log(err);
                        }
                    }
                else addedCount++;
                return fileDTO;
            });
            // only continue if any pending uploads remain
            /* istanbul ignore else */
            if (addedCount > 0) {
                /* istanbul ignore else */
                if (typeof this.props.onDrop === "function") {
                    this.props.onDrop(filesDTO);
                }
            }
            this.onDragLeave(event);
        });
    };

    isFileDrag = (event) =>
        event &&
        event.dataTransfer &&
        event.dataTransfer.types &&
        [...event.dataTransfer.types].includes("Files");

    onDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        /* istanbul ignore else */
        if (this._isMounted && this.isFileDrag(event))
            this.setState({
                isDragover: true,
            });
        clearTimeout(this._timeout);
    };

    onDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this._timeout = setTimeout(this._timedDragLeave, 100);
    };

    /** Delays firing of dragleave on child dragenter */
    _timedDragLeave = () =>
        this._isMounted && this.setState({ isDragover: false });

    render = () => {
        const {
            style,
            id,
            component: DropHandler = "div",
            dragOverText,
            children = null,
            relativePath,
            ...props
        } = this.props;
        let {
            className = "",
            allowDrop,
            onDrop,
            addFilesToUppy,
            ...componentProps
        } = props;
        className = getClassNames(
            className,
            this.state.isDragover ? "drag" : ""
        );
        return (
            <DropHandler
                className={className}
                id={id}
                ref={this._dropzone}
                style={style}
                {...componentProps}
                data-test="filedropHandler"
            >
                {this.state.isDragover && (
                    <span className="droptext">{this.props.dragOverText}</span>
                )}
                {children}
            </DropHandler>
        );
    };
}
