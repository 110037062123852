// @flow
import React, { useState, useCallback } from "react";
import BreadcrumbPart from "./BreadcrumbPart";
import BreadcrumbFavorite from "./BreadcrumbFavorite";
import SiteSelect from "components/SiteSelect";
import { type SiteTypeEnum, type BreadcrumbPartDTO } from "data/types";
import {
    maybeHandleSiteName,
    maybeLocalizeBreadcrumb,
    getClassNames,
} from "data/utils";
import "./index.css";
import loc from "i18next";
import Icons from "@hs/icons";
import Badge from "react-bootstrap/lib/Badge";
import styles from "./Breadcrumb.module.css";

type Props = {
    /** whole breadcrumb in text  */
    text?: string,
    /** siteType */
    siteType?: SiteTypeEnum,
    /** optional custom icon */
    icon?: string,
    /** optional custom icon color*/
    color?: string,
    /** all part objects making up breadcrumb */
    parts: Array<BreadcrumbPartDTO>,
    /** current version */
    version?: number,
    /** whether marked as favorite */
    isFav?: boolean,
    /** whether this item is favorable */
    isFavorable?: boolean,
    /** fetch processing status */
    favStatus?: string,
    /** callback when favorite icon clicked */
    favOnClick?: () => void,
    /** optional additional CSS style */
    style?: StyleSheet,
    /** optional additional CSS class to apply */
    className?: string,
    /** whether last breadcrumb part should be active */
    leafActive?: boolean,
    /** callback when UI measured length of rendered breadcrumb */
    onMeasured: Function,
    /** which parts in the breadcrumb to show (depending on rendering size and screen width) */
    showParts: Array<boolean>,
    /** how many parts of the breadcrumb are actually hidden (depending on rendering size and screen width) */
    hiddenCount: number,
    /** whether user is allowed to navigate through breadcrumb (and hide favorite option) */
    isReadonly: boolean,
    /** scope breadcrumb to show parts only starting the specified level number */
    scopeFromLevel: number,
    /** optional breadcrumbPart click event instead of history.push */
    onPartClick?: (itemUri: string) => void,
};

const MyBreadcrumb = React.forwardRef(
    (
        {
            text,
            siteType,
            icon,
            color,
            isDoc,
            parts,
            version,
            isFav = false,
            favStatus,
            favOnClick,
            style,
            className,
            leafActive = false,
            onMeasured,
            showParts,
            hiddenCount,
            isReadonly = false,
            isFavorable = true,
            canChangeSite,
            scopeFromLevel = -1,
            onPartClick,
        }: Props,
        ref
    ) => {
        const [showChangeSiteType, setShowChangeSiteType] = useState(false);
        const toggleChangeSiteType = useCallback(
            () => canChangeSite && setShowChangeSiteType((show) => !show),
            [canChangeSite]
        );
        const handleSiteChange = useCallback(
            (itemUri) => {
                /* istanbul ignore else */
                if (typeof onPartClick === "function") onPartClick(itemUri);
                setShowChangeSiteType(false);
            },
            [onPartClick]
        );
        return (
            <ol
                className="breadcrumb"
                role="navigation"
                aria-label="breadcrumbs"
                ref={ref}
                data-test="breadCrumb"
            >
                {siteType &&
                    // !isReadonly &&
                    isFavorable &&
                    scopeFromLevel <= 0 &&
                    typeof favOnClick === "function" && (
                        <BreadcrumbFavorite
                            isFav={isFav}
                            favStatus={favStatus}
                            onClick={favOnClick}
                        />
                    )}
                {parts &&
                    ((hiddenCount > 0 &&
                        parts.length > 1 &&
                        hiddenCount < showParts.length - 1) ||
                        parts.length === 1 ||
                        hiddenCount === 0) &&
                    siteType &&
                    " "}
                {parts &&
                    ((hiddenCount > 0 &&
                        parts.length > 1 &&
                        hiddenCount < showParts.length - 1) ||
                        parts.length === 1 ||
                        hiddenCount === 0 ||
                        isReadonly) &&
                    siteType &&
                    !showChangeSiteType && (
                        <Icons.Custom
                            className={getClassNames(
                                styles.icon,
                                icon && "fa-fw",
                                !color && styles.color,
                                canChangeSite && styles.canClick
                            )}
                            onClick={toggleChangeSiteType}
                            siteType={siteType}
                            icon={icon}
                            color={color}
                        />
                    )}{" "}
                {parts &&
                    siteType &&
                    parts.length === 1 &&
                    !showChangeSiteType && (
                        <BreadcrumbPart
                            index={0}
                            text={maybeHandleSiteName(parts[0].text, siteType)}
                            itemUri={parts[0].itemUri}
                            style={style}
                            className={className}
                            isLast={!canChangeSite}
                            isReadonly={isReadonly}
                            onClick={toggleChangeSiteType}
                        />
                    )}
                {parts && showChangeSiteType && (
                    <SiteSelect
                        defaultItemUri={parts[0]?.itemUri}
                        onChange={handleSiteChange}
                    />
                )}
                {parts &&
                    parts.length > 1 &&
                    !isReadonly &&
                    hiddenCount > 0 &&
                    (hiddenCount === showParts.length - 1 ||
                        (scopeFromLevel > 0 &&
                            hiddenCount ===
                                showParts.length - scopeFromLevel - 1)) && (
                        <BreadcrumbPart
                            index={0}
                            symbolName="turn-up"
                            text={parts[parts.length - 2].text}
                            itemUri={parts[parts.length - 2].itemUri}
                            style={style}
                            className={className}
                            isLast={false}
                            isReadonly={isReadonly}
                            onClick={onPartClick}
                        />
                    )}
                {parts &&
                    parts.length > 1 &&
                    hiddenCount > 0 &&
                    hiddenCount < showParts.length - 1 && (
                        <span title={text} style={{ color: "#777777" }}>
                            ...
                        </span>
                    )}
                {parts &&
                    parts.length > 1 &&
                    parts.map((part, index) => {
                        if (index === 0 && showChangeSiteType) return null;
                        if (showParts.length > 0 && !showParts[index])
                            return null;
                        if (index < scopeFromLevel) return null;
                        return (
                            <BreadcrumbPart
                                index={index}
                                onMeasured={onMeasured}
                                key={index}
                                bctext={maybeLocalizeBreadcrumb({
                                    siteType,
                                    bcText: text,
                                })}
                                text={
                                    index === 0
                                        ? maybeHandleSiteName(
                                              parts[index].text,
                                              siteType
                                          )
                                        : part.text
                                }
                                itemUri={part.itemUri}
                                isDoc={index === parts.length - 1 && isDoc}
                                style={style}
                                className={className}
                                isLast={
                                    !leafActive && index === parts.length - 1
                                }
                                isReadonly={isReadonly}
                                onClick={onPartClick}
                            />
                        );
                    })}
                {parts && parts.length > 1 && version > 0 && (
                    <Badge className="bc-version">
                        {loc.t("common:version.text", { version })}
                    </Badge>
                )}
            </ol>
        );
    }
);

MyBreadcrumb.displayName = "MyBreadcrumb";
export default MyBreadcrumb;
