// @flow
import React from "react";
import Modal, { ModalSize } from "components/Modal";
import Form from "react-bootstrap/lib/Form";
import FormGroup from "react-bootstrap/lib/FormGroup";
import ClearableInput from "@hs/clearable-input";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import HelpBlock from "react-bootstrap/lib/HelpBlock";
import Button from "react-bootstrap/lib/Button";
import { createModal } from "react-modal-promise";
// // import Icons from "@hs/icons";
import loc from "i18next";

type Props = {
    /** react-modal-promise: whether to show dialog */
    open: boolean,
    /** react-modal-promise: callback when user closes Dialog */
    close: () => void,
    /** text to show in OK button */
    okButton?: string,
    /** text to show in Cancel button */
    cancelButton?: string,
    /** Modal Dialog title */
    title?: string,
    /** default name to show */
    name?: string,
    /** text to show for the input text label */
    nameLabel?: string,
    /** callback to validate name before submitting
     * @param {string} newName new value typed by user
     */
    validation?: (newName: string) => Object,
};

type State = {
    newName: string,
    validationMessage: ?string,
};

/** presents a (re-)Name dialog to the user
 * @returns {string} new name user has chosen, null if cancelled
 */
export class NameModal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            newName: props.name || "",
            validationMessage: null,
        };
    }

    _isValid = () =>
        this.state.newName &&
        String(this.state.newName).length > 0 &&
        this.state.validationMessage == null;
    _validationState = () => (this._isValid() ? null : "error");

    _onSubmit = async () => {
        if (typeof this.props.validation === "function") {
            var result = await this.props.validation(this.state.newName);
            if (result != null) {
                this.setState({ validationMessage: result });
                return;
            }
        }
        this.props.close(this.state.newName);
    };

    _onChange = (newName: string) =>
        this.setState({ newName, validationMessage: null });

    render() {
        const {
            open,
            close,
            okButton = loc.t("confirm.yes"),
            cancelButton = loc.t("confirm.cancel"),
            nameLabel,
            title,
        } = this.props;
        return (
            <Modal
                size={ModalSize.Small}
                enforceFocus
                show={open !== false}
                onHide={/* istanbul ignore next */ () => close(null)}
                // dialogClassName={styles.modal}
            >
                {title && (
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                )}
                <Modal.Body>
                    <Form
                        onSubmit={
                            /* istanbul ignore next */ (e) => e.preventDefault()
                        }
                        style={{
                            padding: 50,
                            //     display: "flex",
                            //     flexDirection: "column",
                            //     height: "100%"
                        }}
                    >
                        <FormGroup
                            controlId="name"
                            validationState={this._validationState()}
                        >
                            {nameLabel && (
                                <ControlLabel>{nameLabel}</ControlLabel>
                            )}
                            <ClearableInput
                                autoFocus
                                className="form-control"
                                value={this.state.newName}
                                onChange={this._onChange}
                            />
                            {this.state.validationMessage && (
                                <HelpBlock style={{ fontStyle: "italic" }}>
                                    {this.state.validationMessage}
                                </HelpBlock>
                            )}
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        aria-label={okButton}
                        onClick={this._onSubmit}
                        bsStyle="primary"
                        disabled={!this._isValid()}
                        style={{ padding: "6px 30px" }}
                    >
                        {okButton}
                    </Button>
                    <Button
                        aria-label={cancelButton}
                        onClick={/* istanbul ignore next */ () => close(null)}
                    >
                        {cancelButton}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export const openNameModal = createModal(NameModal);
