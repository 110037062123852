// @flow
//#region imports
import React, { PureComponent } from "react";
import { connect, Dispatch } from "react-redux";
import { compose } from "redux";
import isEqual from "lodash/isEqual";
import { produce } from "immer";
import TogglesHandler from "containers/TogglesHandler";
import "./index.css";
import MainLayout from "components/MainLayout";
import ItemFlows from "components/ItemFlows";
import {
    type ToggleDTO,
    type ToggleItemDTO,
    type ItemFlowConfigDTO,
    type ItemFlowCategory,
    type NewItemResultDTO,
} from "data/types";
import SplitPanes from "containers/SplitPanes";
import CloseButton from "@hs/close-button";
import SearchInput from "@hs/search-input";
import { togglesUpdateCounterAction } from "data/actions";
import Constants from "data/constants";
import CategoryFilter from "./CategoryFilter";
import SettingValue, { type SettingValueProps } from "containers/SettingValue";
import { toastActionResult } from "data/toast";
import {
    setCurrentItemAction,
    backupCurrentItemAction,
    restoreCurrentItemAction,
} from "data/actions";
import { get } from "data/constants";
import ItemFlowsFactory from "components/CommandActions/itemFlows/Factory";
import Pane from "components/SplitPanes/Pane";
import { isHandheld } from "data/bowser";
import { getLocalizedText } from "data/utils";
import { inPointAction, inPointActionLink } from "components/ItemUriLink";
import { check_isSyncUpload } from "containers/ActionPanel/ShouldBeVisible";
import { postMessageTo } from "hooks/PostMessage/Utils";
import { ItemAction } from "hooks/PostMessage/Actions";
//#endregion

export const viewName = "itemflows";

type Props = {
    /** whether shown as Modal */
    asModal?: boolean,
    /** Redux dispatch */
    dispatch: Dispatch,
    /** callback when closing Modal dialog */
    onClose?: () => void,
    /** list of flows to render */
    flows: ?Array<ItemFlowConfigDTO>,
    /** list of flow categories */
    categories: ?Array<ItemFlowCategory>,
    /** pre-selected flow  */
    selectedFlow?: ItemFlowConfigDTO,
    /** pre-selected category */
    selectedCategory?: ItemFlowCategory,
    // from TogglesHandler
    /** TogglesHandler callback to set toggles */
    setToggles: Function,
    /** TogglesHandler this view's toggles definitions */
    toggles: ?ToggleDTO,
    /** current itemUri */
    itemUri: string,
    // from redux
    /** which toggles are currently selected */
    selectedToggles: Array<string>,
    /** SplitPanes callback to set split sizes */
    setSplitSizes: Function,
    /** persisted filterType */
    filterType: SettingValueProps,
    /** optional default attribute values to pass to form */
    defaultValues?: Object,
    /** optional whether to preview the newly created item
     * @default true
     */
    previewNewItem?: boolean,
    /** files to upload */
    files?: Array<DroppedFile>,
};

type State = {
    filterText: string,
    filterType: string,
    filteredFlows: ?Array<ItemFlowConfigDTO>,
    selectedFlow: ?ItemFlowConfigDTO,
};

export class ItemFlowsView extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            filterText: "",
            filterType: props.selectedCategory
                ? props.selectedCategory.id
                : props.filterType.value,
            filteredFlows: null,
            selectedFlow: props.selectedFlow,
        };
    }

    componentDidMount() {
        const { dispatch, itemUri, selectedFlow } = this.props;
        dispatch(backupCurrentItemAction());
        dispatch(setCurrentItemAction({ name: viewName, itemUri }));

        this._updateToggles();
        this._filterList();
        this._updateFormToggle(selectedFlow != null);
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        this._updateFormToggle(false);
        dispatch(restoreCurrentItemAction());
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (!isEqual(prevState.selectedFlow, this.state.selectedFlow)) {
            this._updateFormToggle(this.state.selectedFlow != null);
        }
        if (
            prevState.filterText !== this.state.filterText ||
            prevState.filterType !== this.state.filterType
        ) {
            this._filterList();
        }
        if (prevState.filteredFlows !== this.state.filteredFlows) {
            this._updateToggles();
        }
        if (prevProps.selectedFlow !== this.props.selectedFlow) {
            this.setState({ selectedFlow: this.props.selectedFlow });
        }
        if (prevProps.selectedCategory !== this.props.selectedCategory) {
            this.setState({
                filterType:
                    this.props.selectedCategory?.id ??
                    this.props.filterType.value,
            });
        }
    }

    _updateToggles = () =>
        this.props.dispatch(
            togglesUpdateCounterAction({
                name: viewName,
                counts: { list: this._getTotalCount() },
                status: { list: Constants.OK },
            })
        );

    _getTotalCount = () =>
        this.state.filteredFlows
            ? this.state.filteredFlows.length
            : this.props.flows.length;

    _filterList = () =>
        this.setState({ filteredFlows: this._getFilteredList() });

    _getFilteredList = () =>
        this.props.flows == null
            ? /* istanbul ignore next */ []
            : this.props.flows.filter((t) => {
                  // filter type
                  let show =
                      this.state.filterType === "all"
                          ? true
                          : t.category.toLowerCase() === this.state.filterType;
                  if (!show) return false;

                  // filter name and/or description
                  if (this.state.filterText.length > 0)
                      show =
                          (
                              getLocalizedText(
                                  get(t, ["name"], "")
                              ).toLowerCase() +
                              getLocalizedText(
                                  get(t, ["desc"], "")
                              ).toLowerCase()
                          ).indexOf(this.state.filterText.toLowerCase()) >= 0;
                  if (!show) return false;

                  return show;
              });

    _onFilterTextChange = (filterText: ?string) =>
        this.setState({ filterText: filterText || "" });

    _onFilterTypeChange = (filterType: ?string) =>
        this.setState({ filterType: filterType || "all" }, () => {
            this.props.filterType.setValue(filterType || "all");
        });

    _updateFormToggle = (show: boolean) => {
        /* istanbul ignore if */
        if (this.props.toggles == null) return;
        // form toggles visibility
        const toggles = produce(this.props.toggles, (draft) => {
            draft.toggles = draft.toggles.map((toggle: ToggleItemDTO) => {
                if (toggle.value === "form") {
                    // handle form toggle
                    toggle.isDisabled = !show;
                    toggle.isSelected = show;
                } else if (isHandheld() && toggle.value === "list") {
                    // handle list toggle (handheld only)
                    toggle.isSelected = !show;
                }
                return toggle;
            });
        });
        /* istanbul ignore else */
        if (toggles != null) {
            this.props.setToggles(toggles);
        }
    };

    _onSelect = (selectedFlow: ItemFlowConfigDTO) => {
        if (selectedFlow.id === "doc_upload" && check_isSyncUpload()) {
            const win = window.open(
                inPointActionLink(inPointAction.archive),
                "_blank"
            );
            win.focus();
        } else {
            this.setState({ selectedFlow });
        }
    };

    _onFormCancel = () => this.setState({ selectedFlow: null });

    _onCreated = (
        success: boolean,
        error?: Object,
        data?: NewItemResultDTO,
        triggerToast: boolean = true
    ) => {
        /* istanbul ignore else */
        if (triggerToast) toastActionResult(success, `${viewName}:created`);
        if (success === true) {
            postMessageTo(ItemAction.Created, data?.itemUri, window);
            /* istanbul ignore else */
            if (typeof this.props.onClose === "function") this.props.onClose();
        } else {
            // TODO do we toast itemFlows failure? Do we close?
            console.error(error || "Unknown error happened during creation");
        }
        return success;
    };

    render() {
        const { toggles, itemUri } = this.props;
        return (
            <MainLayout
                name={viewName}
                toggles={toggles}
                itemUri={itemUri}
                breadcrumbReadonly={true}
                topRowEnd={<CloseButton onClick={this.props.onClose} />}
            >
                <Pane id="itemFlowsListPanel" className="f-split">
                    {this.props.selectedFlow == null && (
                        <div className="itemFlowsSearchBar">
                            <CategoryFilter
                                value={this.state.filterType}
                                categories={this.props.categories}
                                onChange={this._onFilterTypeChange}
                                className="search-filter"
                            />
                            <SearchInput
                                key="search-itemflows"
                                placeholder={getLocalizedText(
                                    `$${viewName}:listFilter`,
                                    {
                                        count: this._getTotalCount(),
                                    }
                                )}
                                onChange={this._onFilterTextChange}
                                value={this.state.filterText}
                                className="search-input"
                            />
                        </div>
                    )}
                    <ItemFlows.List
                        itemUri={itemUri}
                        selectedFlow={this.state.selectedFlow}
                        flows={this.state.filteredFlows || this.props.flows}
                        filterText={this.state.filterText.toLowerCase()}
                        onSelect={this._onSelect}
                    />
                </Pane>
                <Pane id="itemFlowsFormPanel" className="f-split">
                    {this.state.selectedFlow && (
                        <ItemFlowsFactory
                            flow={this.state.selectedFlow}
                            viewName={viewName}
                            itemUri={itemUri}
                            onCancel={this._onFormCancel}
                            onCreated={this._onCreated}
                            defaultValues={this.props.defaultValues}
                            previewNewItem={this.props.previewNewItem}
                            files={this.props.files}
                        />
                    )}
                </Pane>
            </MainLayout>
        );
    }
}

export default compose(
    connect(),
    TogglesHandler({
        viewName,
    }),
    SplitPanes({
        viewName,
        paneIds: ["#itemFlowsListPanel", "#itemFlowsFormPanel"],
        toggleIds: ["list", "form"],
        direction: "horizontal",
    }),
    SettingValue({
        name: "itemFlows.filterType",
        namespace: "filterType",
        defaultValue: "all",
    })
)(ItemFlowsView);
