//@flow
import Loadable from "react-loadable";
import Loading from "./Loading";
import StatusLoading from "components/Status/Loading";
import { LoadingType, type LoadingTypeEnum } from "data/types";

const getDefaltsByLoadingType = (loadingType: LoadingTypeEnum) => {
    switch (loadingType) {
        case LoadingType.Default:
            return {
                loading: Loading,
                delay: 5000,
                timeout: 10,
            };
        case LoadingType.Status:
            return {
                loading: StatusLoading,
                delay: 0,
                timeout: 10,
            };
        /* istanbul ignore next */
        default:
            console.warn("Unknown LoadingType provided for Loader");
    }
};

type LoaderOptions = {
    loading: React.Node,
    delay: number,
    timeout: number,
};

const Loader = (
    opts: LoaderOptions,
    loadingType: LoadingTypeEnum = LoadingType.Default
) => Loadable(Object.assign(getDefaltsByLoadingType(loadingType), opts));

export default Loader;
