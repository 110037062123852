import { call, put, take, takeEvery, select, race } from "redux-saga/effects";
import { delay } from "redux-saga";
import actionTypes from "data/actions/actionTypes";
import { getDocsAction, setDocsLayoutAction } from "data/actions";
import * as s from "data/reducers/selectors";
import { DocListFilterOperators, SearchValueType } from "data/types";
import {
    GetDefaults,
    ResetFilters,
    GetFilterValueAsString,
} from "components/DocListFilterModal/Utils";
import immutable from "object-path-immutable";
import Constants, { getDefaultsConfig } from "data/constants";

const resultSelector = (state) => state.docs;
const currentItemSelector = (state) => state.current;
const formatIdSelector = (state) =>
    s.breadcrumbFormatIdSelector(state, s.currentNameSelector(state));

// clears all filters & sorts (in case none is available, will reset everything)
function* resetFilters() {
    const result = yield select(resultSelector);
    const formatId = yield select(formatIdSelector);
    if (result != null && result.cols != null && result.cols.length > 0) {
        yield put(
            setDocsLayoutAction({
                sorts: [],
                filters: ResetFilters(
                    result.itemUri,
                    formatId,
                    result.filters,
                    result.cols
                ),
            })
        );
    } else {
        // bruteforce - delete everything
        yield put(setDocsLayoutAction());
    }
}

// create a filter based on a value referenced from the result.rows
function* setFilterByRef(action) {
    const status = yield select(s.docsStatusSelector);
    if (status !== Constants.OK) return;

    const { docContextClick, resetValues } = action.payload;
    const result = yield select(resultSelector);
    const formatId = yield select(formatIdSelector);
    //const filters = yield select(s.docsFiltersSelector);
    //const cols = yield select(s.docsColsSelector);
    /* istanbul ignore else */
    if (result != null && result.cols != null) {
        // get current filters
        let filters = result.filters;
        // none yet? create default list
        if (filters == null || filters.length === 0) {
            filters = GetDefaults(
                result.itemUri,
                formatId,
                result.cols
            ).filters;
        }
        if (resetValues) {
            // reset values ONLY (operators stay as they are)
            filters = ResetFilters(
                result.itemUri,
                formatId,
                filters,
                result.cols
            );
        }
        const { cols } = result;
        const colName = cols[docContextClick.columnIndex].name;

        // then set our own
        const newfilters = filters.map((filter, index) => {
            if (filter.name === colName) {
                let newfilter = immutable.set(filter, "values", [
                    docContextClick.rowIndex === -1
                        ? ""
                        : GetFilterValueAsString(
                              result.rows[docContextClick.rowIndex][
                                  docContextClick.columnIndex
                              ]
                          ),
                ]);
                if (
                    [
                        DocListFilterOperators.None,
                        DocListFilterOperators.Between,
                        DocListFilterOperators.NotBetween,
                        // DocListFilterOperators.Contains
                    ].includes(newfilter.operator)
                )
                    newfilter.operator = DocListFilterOperators.Equals;
                if (
                    [DocListFilterOperators.DoesNotContain].includes(
                        newfilter.operator
                    )
                )
                    newfilter.operator = DocListFilterOperators.NotEquals;
                return newfilter;
            }
            return filter;
        });
        yield put(setDocsLayoutAction({ filters: newfilters }));
    }
}

function* setFilterByName(action) {
    const result = yield select(resultSelector);
    const formatId = yield select(formatIdSelector);
    /* istanbul ignore else */
    if (result == null || result.cols == null) return;

    const { name, valueType, operator, value } = action.payload;
    // get current filters
    let filters = result.filters;
    // none yet? create default list
    if (filters == null || filters.length === 0) {
        filters = GetDefaults(result.itemUri, formatId, result.cols).filters;
    }

    // new value to assign; reset or set value
    let newValue =
        value === "__ALL__" ||
        (valueType === SearchValueType.RelativeDate && value === "NotSet")
            ? ""
            : value;
    // Transform to Array (if not already an array)
    if (!Array.isArray(newValue)) {
        newValue = [newValue];
    }
    // find requested filter
    const idx = filters.findIndex((f) => f.name === name);
    let newFilters = filters;
    if (idx === -1) {
        // add new filter
        newFilters = immutable.push(filters, undefined, {
            name,
            operator,
            values: newValue,
        });
    } else {
        newFilters = immutable.set(filters, [idx], {
            name,
            operator: operator || filters[idx].operator,
            values: newValue,
        });
    }
    // publish the changed filters
    yield put(setDocsLayoutAction({ filters: newFilters }));
}

function* getDocsLayout(action) {
    let formatId = yield select(formatIdSelector);
    if (formatId == null) {
        yield take(actionTypes.CURRENT_BREADCRUMB);
        formatId = yield select(formatIdSelector);
    }
    // anything saved in storage?
    const { currentItemLoaded, timeout } = yield race({
        currentItemLoaded: take(actionTypes.CURRENTITEM_LOADED),
        timeout: call(delay, 2000),
    });
    if (
        !timeout &&
        currentItemLoaded &&
        currentItemLoaded.payload.layout != null
    ) {
        /* console.debug(
            "restoring layout from storage",
            currentItemLoaded.payload.layout
        ); */
        yield put(setDocsLayoutAction(currentItemLoaded.payload.layout));
        return;
    }
    // or maybe defaults config?
    const defaults = getDefaultsConfig(action.payload.itemUri, formatId);
    /* istanbul ignore else */
    if (defaults !== null) {
        const { filters, sorts } = defaults;
        /* istanbul ignore else */
        if (filters != null /* istanbul ignore next */ || sorts != null) {
            /* console.debug("setting layout from defaults", defaults); */
            yield put(setDocsLayoutAction({ filters, sorts }));
            return;
        }
    }

    // create default
    const result = yield select(resultSelector);
    if (result != null && result.cols != null) {
        /* console.debug("setting layout by GetDefaults()"); */
        yield put(
            setDocsLayoutAction(
                GetDefaults(result.itemUri, formatId, result.cols)
            )
        );
        return;
    } else if (action.payload.allowPreFetch === true) {
        // only if param "allowPreFetch" is set! (related to showDocs=true and immediately showing filters)
        const currentItem = yield select(currentItemSelector);
        /* console.debug("setting layout by first getting schema"); */
        yield put(getDocsAction({ pageNum: -1, itemUri: currentItem.itemUri }));
        const docsresult = yield take(actionTypes.DOCS_FETCH_RESULT);
        yield put(
            setDocsLayoutAction(
                GetDefaults(
                    currentItem.itemUri,
                    formatId,
                    docsresult.payload.result.cols
                )
            )
        );
        return;
    }

    yield put(setDocsLayoutAction());
}

function* setFilterText(action) {
    yield setFilterByName(action);
}

function* docsSaga() {
    yield takeEvery(actionTypes.DOCS_FILTERS_RESET, resetFilters);
    yield takeEvery(actionTypes.DOCS_FILTERS_SETBYREF, setFilterByRef);
    yield takeEvery(actionTypes.DOCS_FILTERS_SETBYNAME, setFilterByName);
    yield takeEvery(actionTypes.DOCS_FILTERTEXT_SET, setFilterText);

    yield takeEvery(actionTypes.DOCS_LAYOUT_REQUEST, getDocsLayout);
}

export default docsSaga;
