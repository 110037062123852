import share from "./share";
import { ShareType } from "data/types";

/**
 * opens the share dialog with share type attachment
 */

class doc_emailAttachment extends share {
    execute = async (context) => {
        return super.execute({
            ...context,
            shareType: ShareType.Attachment,
        });
    };
}
export default doc_emailAttachment;
