// @flow
import React from "react";
import LinkViewerControl from "./LinkViewerControl";

type Props = {
    name: string,
    header?: string,
    value: ?string,
    width?: number,
    isGridCell: boolean,
};

const EmailViewerControl = ({
    name,
    header,
    value,
    width,
    isGridCell,
}: Props) => (
    <LinkViewerControl
        name={name}
        header={header}
        linkType="mailto"
        value={value}
        icon="envelope"
        isGridCell={isGridCell}
        isIconLast={false}
    />
);
EmailViewerControl.displayName = "EmailViewerControl";
export default EmailViewerControl;
