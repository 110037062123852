/**
 * execute cancel of current form
 */

type Props = {
    onCancel?: () => void,
};

class form_cancel {
    execute = ({ onCancel }: Props) => {
        if (typeof onCancel !== "function") {
            return false;
        }
        onCancel();
        return true;
    };
}
export default form_cancel;
