// @flow
import React from "react";
import { type ListViewModeEnum } from "data/types";
import ToggleButtonGroup from "react-bootstrap/lib/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/lib/ToggleButton";
import Icons from "@hs/icons";
import loc from "i18next";
import { ViewModeIcon } from "./ViewModes";

type Props = {
    /** current selected viewMode */
    viewMode: ListViewModeEnum,
    /** all available viewModes to render */
    viewModes?: Array<ListViewModeEnum>,
    /** callback when user changes viewMode
     * @param {ListViewModeEnum} viewMode the viewMode the user chose
     */
    onChange: (viewMode: ListViewModeEnum) => void,
    /** options CSS class name to apply */
    className?: string,
};

/** render a Dropdownlist with requested viewModes marking the currently active viewMode */
const ViewModesButtons = ({
    viewMode,
    viewModes,
    onChange,
    className,
}: Props) => (
    <>
        {/* <div className="caption">{loc.t("view.menuCaption")}</div> */}
        <ToggleButtonGroup
            bsSize="small"
            id="viewModes"
            type="radio"
            name="viewMode"
            className={className}
            defaultValue={viewMode}
        >
            {/* <Dropdown.Toggle
                    chrome
                    title={loc.t("common:view.menuCaption")}
                >
                    <Icons.Library name={ViewModeIcon[viewMode]} />
                </Dropdown.Toggle> */}
            {viewModes.map((vm) => (
                <ToggleButton
                    key={`vm-${vm}`}
                    value={vm}
                    title={loc.t(`common:view.button.${vm}`)}
                    onClick={/* istanbul ignore next */ () => onChange(vm)}
                >
                    <Icons.Library
                        name={ViewModeIcon[vm]}
                        // style={{ marginRight: 5 }}
                    />
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    </>
);
ViewModesButtons.displayName = "ViewModesButtons";
export default ViewModesButtons;
