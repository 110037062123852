import React, { useMemo } from "react";
import ClearableInput from "@hs/clearable-input";
import FormsyEditorBase from "./FormsyEditorBase";

const MemoEditorControl = (props) => {
    const style = useMemo(
        () => ({
            width: "100%",
            borderRadius: 0,
            resize: "vertical",
            overflow: "auto",
            paddingTop: 6,
            paddingLeft: 9,
            paddingBottom: 6,
            border: "1px solid #cccccc",
            boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
            minHeight: props.height || 32,
        }),
        [props.height]
    );
    return (
        <FormsyEditorBase
            required={props.isRequired ? "isDefaultRequiredValue" : undefined}
            render={(value, changeValue) => (
                <ClearableInput
                    className="form-control"
                    disabled={props.isReadonly}
                    placeholder={props.placeholder}
                    value={value || ""}
                    onChange={changeValue}
                    onChangeWithRawEvent={true}
                    multi
                    maxRows={props.height ? undefined : 10}
                    style={style}
                    id={props.name}
                />
            )}
            {...props}
        />
    );
};
MemoEditorControl.displayName = "MemoEditorControl";
export default MemoEditorControl;
