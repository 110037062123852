import { useState, useRef, useEffect } from "react";
import {
    createUppy,
    addUppyPlugins,
    getUppyLocale,
    addUppyEvents,
    removeUppyEvents,
    type UppyEvent,
    uppyPendingFilesMetaSelector,
} from "data/uppy";
import { useDeepCompareEffect } from "hooks";
import { useSelector } from "react-redux";

type Props = {
    /** which uppy ID to use (to separate from other upload queues)
     * @default {string} const from data/uppy.js/DEFAULT_UPPY_ID
     */
    uppyId?: string,
    /** optional uppy properties to pass (e.g. maxNumberOfFiles) */
    uppyProps?: Object,
    /** optional list of uppy events to attach to */
    events?: Array<UppyEvent>,
    /** files to upload */
    files?: Array<DroppedFile>,
};

export function useUppy({ uppyId, uppyProps, events, files }: Props) {
    const [ready, setReady] = useState(false);
    const uppy = useRef();
    useEffect(() => {
        uppy.current = createUppy({
            id: uppyId,
            ...uppyProps,
        });
        uppy.current = addUppyPlugins(uppy.current);
        // cancel all pending uploads in this queue
        uppy.current.cancelAll();
        if (events) addUppyEvents(events, uppy.current);
        getUppyLocale()
            .then((result) => {
                uppy.current.locale = { ...result.i18n };
            })
            .finally(() => setReady(true));

        return () => {
            if (events) removeUppyEvents(events, uppy.current);
            uppy.current.close();
        };
    }, [uppyId, uppyProps, events, files]);

    useEffect(() => {
        if (!ready || !files?.length || uppy.current == null) return;
        // add optional files to queue
        /* istanbul ignore next */
        try {
            files?.forEach((file) => {
                /**
                 * if a immutable object is supplied, we need to open it up,
                 * due to the nature of uppy modifying the original object
                 */
                uppy.current.addFile({ ...file, meta: { ...file.meta } });
            });
        } catch (e) {
            console.error("Cannot add file to uppy queue", e);
        }
    }, [ready, files]);

    return ready ? uppy.current : null;
}

/**
 * data/uppy/uppyPendingFilesMetaSelector returns a new object on every call
 * this wrapper uses a deep compare to avoid re-renders
 * @returns {Array<uppyFile>} array of pending file uploads
 */
export function usePendingUploads() {
    const [pendingUploads, setPendingUploads] = useState([]);
    const uppyPendingUploads = useSelector(uppyPendingFilesMetaSelector);

    useDeepCompareEffect(() => {
        setPendingUploads(uppyPendingUploads);
    }, [uppyPendingUploads]);

    return pendingUploads;
}
