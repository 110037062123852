// configuration file for components/ActionsMenu
// localized text defined in data/i8n.*.json {viewName}/actions
const ActionMenus = {
    docactivity_context: [
        {
            id: "details",
        },
        {
            id: "view",
        },
        {
            id: "popout",
        },
        {
            id: "download",
        },
        {
            id: "sep",
            type: "separator",
        },
        {
            id: "restore",
        },
        {
            id: "delete",
        },
    ],
    docactivity_readonly_context: [
        {
            id: "details",
        },
        {
            id: "view",
        },
    ],
};

export default ActionMenus;
