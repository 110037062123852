import React, { useCallback, useMemo } from "react";
import AttributeLabel from "components/ExtraAttributes/AttributeLabel";
import ViewerControlBase from "components/ExtraAttributes/ViewerControl/ViewerControlBase";
import Icons from "@hs/icons";
import { type EnumValue } from "data/types";
import { getLocalizedText } from "data/utils";
import styles from "./EnumViewerControl.module.css";
import sharedStyles from "shared/styles.module.css";

type Props = {
    /** CommonName */
    name: string,
    /** Label */
    header: string,
    /** Style for  */
    style: CSSStyleDeclaration,
    /** Enum Value */
    value: T,
    /** Enum Values */
    enum: Array<EnumValue<T>>,
    /** Is a required field */
    isRequired?: boolean,
    /** whether it is an Enum or and EnumFlag */
    isMulti?: boolean,
};

const EnumViewerControl = (props: Props) => {
    const renderEnumValue = useCallback(
        (enumValue: EnumValue<T>) => (
            <span
                className={styles.enum}
                key={`enumViewer.${props.name}.${enumValue.value}`}
            >
                {enumValue.icon && typeof enumValue.icon === "string" ? (
                    <Icons.Library name={enumValue.icon} />
                ) : (
                    enumValue.icon
                )}{" "}
                {getLocalizedText(enumValue.label)}
            </span>
        ),
        [props.name]
    );
    const renderedValue = useMemo(
        () =>
            props.value
                ? props.isMulti
                    ? props.enum
                          .filter((e) => (props.value & e.value) > 0)
                          .map((e) => renderEnumValue(e))
                    : renderEnumValue(
                          props.enum.find((e) => e.value === props.value)
                      )
                : null,
        [renderEnumValue, props.isMulti, props.value, props.enum]
    );
    return (
        <ViewerControlBase style={props.style}>
            <AttributeLabel label={props.header} />
            <div
                className={`form-control form-control-static ${sharedStyles.select_text}`}
            >
                {renderedValue}
            </div>
        </ViewerControlBase>
    );
};

EnumViewerControl.displayName = "EnumViewerControl";
export default EnumViewerControl;
