import React from "react";
import FormsyEditorBase from "./FormsyEditorBase";
import GeoAreaInput from "components/GeoAreaInput";

const GeoAreaEditorControl = (props) => (
    <FormsyEditorBase
        required={props.isRequired ? "isDefaultRequiredValue" : undefined}
        render={(value, changeValue) => (
            <GeoAreaInput
                label={props.header}
                value={value || ""}
                showIcon={false}
                onChange={
                    /* istanbul ignore next */ (value: string) =>
                        changeValue({ currentTarget: { value } })
                }
                onManualArea={
                    /* istanbul ignore next */ (value: string) =>
                        changeValue({ currentTarget: { value } })
                }
                placeholder={props.placeholder}
                id={props.name}
            />
        )}
        {...props}
    />
);
GeoAreaEditorControl.displayName = "GeoAreaEditorControl";
export default GeoAreaEditorControl;
