import { createTransform } from "redux-persist";
// https://github.com/rt2zz/redux-persist

// // do not store/reload the correlationId (used for server-side logging)
// export const transformCorrelationId = /* istanbul ignore next */ () =>
//     createTransform(
//         // transform state on its way to being serialized and persisted.
//         (inboundState, key) => {
//             return { ...inboundState, correlationId: undefined };
//         },
//         // transform state being rehydrated
//         (outboundState, key) => {
//             return { ...outboundState, correlationId: undefined };
//         },
//         // define which reducers this transform gets called for.
//         { whitelist: ["user"] }
//     );

export const transformInitialState = /* istanbul ignore next */ (
    reducerName,
    initialState
) =>
    createTransform(
        // transform state on its way to being serialized and persisted.
        (inboundState, key) => {
            return {
                ...inboundState,
                ...initialState,
            };
        },
        // transform state being rehydrated
        (outboundState, key) => {
            return {
                ...outboundState,
                ...initialState,
            };
        },
        // define which reducers this transform gets called for.
        { whitelist: [reducerName] }
    );

// // optionally skip storing/loading the login reducer (forcing user to re-login every time)
// export const transformLoginToken = createTransform(
//     // transform state on its way to being serialized and persisted.
//     (inboundState, key) => {
//         return {
//             ...inboundState,
//             ...loginInitialState
//         };
//     },
//     // transform state being rehydrated
//     (outboundState, key) => {
//         return {
//             ...outboundState,
//             ...loginInitialState
//         };
//     },
//     // define which reducers this transform gets called for.
//     { whitelist: ["login"] }
// );

// // optionally skip storing/loading the login reducer (forcing user to re-login every time)
// export const transformUserToken = createTransform(
//     // transform state on its way to being serialized and persisted.
//     (inboundState, key) => {
//         return {
//             ...inboundState,
//             ...userInitialState
//         };
//     },
//     // transform state being rehydrated
//     (outboundState, key) => {
//         return {
//             ...outboundState,
//             ...userInitialState
//         };
//     },
//     // define which reducers this transform gets called for.
//     { whitelist: ["user"] }
// );
