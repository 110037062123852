import { getDefaultsConfig } from "data/constants";
import immutable from "object-path-immutable";
import {
    type DocListFilterRequestDTO,
    DocListFilterOperators,
    type DocListFilterOperatorsEnum,
    DocListColumnType,
    type DocListColumnTypeEnum,
    type DocColumnResultDTO,
    type DocListLayout,
} from "data/types";
import { getValue } from "data/utils";

export function GetDefaults(
    itemUri: string,
    formatId: number,
    cols?: Array<DocColumnResultDTO>
): DocListLayout {
    const defaults = getDefaultsConfig(itemUri, formatId);
    if (defaults != null && defaults.filters != null) {
        let filters: Array<DocListFilterRequestDTO> = [];
        for (let i = 0, len = defaults.filters.length; i < len; i++) {
            const filterDef = defaults.filters[i];
            filters.push(Object.assign({}, filterDef, {}));
        }
        return { filters, sorts: defaults.sorts };
    }
    if (cols != null)
        return {
            filters: GetDefaultFilters(cols),
            sorts: [],
        };
    return { filters: [], sorts: [] };
}

// creates the filters array for first use
export function GetDefaultFilters(
    cols: Array<DocColumnResultDTO>
): Array<DocListFilterRequestDTO> {
    let filters: Array<DocListFilterRequestDTO> = [];

    for (let i = 0, len = cols.length; i < len; i++) {
        filters.push({
            operator: GetDefaultOperatorForType(cols[i].type),
            name: cols[i].name,
            values: [""],
        });
    }
    return filters;
}

export function GetDefaultOperatorForType(
    type: DocListColumnTypeEnum
): DocListFilterOperatorsEnum {
    switch (type) {
        case DocListColumnType.BooleanValue:
            return DocListFilterOperators.None;
        case DocListColumnType.StringValue:
        case DocListColumnType.WebDavName:
            return DocListFilterOperators.Contains;
        case DocListColumnType.DateTimeValue:
            return DocListFilterOperators.DateRelative;
        default:
            return DocListFilterOperators.Equals;
    }
}

// returns the filters with cleared values
export function ResetFilters(
    itemUri: string,
    formatId: number,
    filters?: Array<DocListFilterRequestDTO>,
    cols?: Array<DocColumnResultDTO>
): ?Array<DocListFilterRequestDTO> {
    // none yet? return default list
    if (filters == null || filters.length === 0) {
        return GetDefaults(itemUri, formatId, cols).filters;
    }

    // reset values ONLY (operators stay as they are)
    // TODO should actually re-use docListFilter/resetValues()
    return filters.map((filter) => immutable.set(filter, "values", [""]));
}

export const IsFiltered = (filters: ?Array<DocListFilterRequestDTO>): boolean =>
    filters &&
    filters.some(
        (f) => f.values && f.values.length > 0 && String(f.values[0]).length > 0
    );

export const GetFilterValueAsString = (
    value: string | (SummaryAttributeValue & { value: string }) = ""
): string => {
    // Handle Objects with prop rawValue or value
    const v = getValue(value, "rawValue");
    // Handle non-string values
    return typeof v === "string" ? v : "";
};
