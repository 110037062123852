import ApiManagerBase, { type Context } from "./ApiManagerBase";
import { ContentType, HttpMethod, OfflineQueueItemType } from "data/types";
import { JL } from "data/logging";
import { ApiNetworkOfflineError } from "data/apiError";
import { addToOfflineQueue } from "data/offline/offlineQueue";
import * as storeHelper from "data/storeHelper";
import { toastStyled, toastTypes } from "data/toast";

const { isOnline } = storeHelper;
const QueuedOfflineStatusText = "[OFFLINE]";

/**
 * Adds Offline capabilities
 *
 * @export
 * @class ApiManagerOffline
 * @extends {ApiManagerBase}
 */
export default class ApiManagerOffline extends ApiManagerBase {
    constructor(args) {
        super(args);
        this._log = JL("ApiManagerOffline");
        this.OnFetch = this.OnFetch.bind(this);
        this.AfterFetch = this.AfterFetch.bind(this);
        this.CheckLicense = this.CheckLicense.bind(this);
    }

    /**
     * Runs actual data fetch
     * @param {Context} context query context
     * @returns {Promise<Context>} current context with fetch .response
     * @memberof ApiManagerOffline
     * @virtual
     */
    async OnFetch(context: Context): Promise<Context> {
        if (false === isOnline()) {
            this._log.debug(() => ({
                msg: `${context.request.name}: OnFetch`,
                apiContext: context,
            }));
            context = await this._handleOfflineRequest(context);
        } else {
            context = await super.OnFetch(context);
        }
        return context;
    }

    /**
     * First response in fetch response chain
     * @param {Context} context query context
     * @returns {Promise<Context>} current context
     * @memberof ApiManager
     * @virtual
     */
    async AfterFetch(context: Context): Promise<Context> {
        context = await super.AfterFetch(context);

        const { response } = context;
        const addedToOfflineQueue =
            response.status === 202 &&
            response.statusText === QueuedOfflineStatusText;

        if (
            addedToOfflineQueue &&
            context.request.offline &&
            context.request.offline.showToast !== false
        ) {
            toastStyled(toastTypes.swOfflineQueueAdd);
        }
        return context;
    }

    /**
     * Logic for handling a Request while network is offline
     * Note: we handle offline requests by ourselves, since:
     *  1. workbox only checks ONE cache at a time
     *  2. workbox cannot handle PUT/POST requests (tricky with plugins)
     *  3. we handle our own offlineItems (ApiManagerOffline)
     *  4. we return custom error for non-cached methods
     * @param {Context} context
     * @returns {Promise<Context>}
     * @memberof ApiManager
     * @private
     */
    async _handleOfflineRequest(context: Context): Promise<Context> {
        // do we have request pre-cached?
        const cacheKey = this._getFullCacheKey(context);
        const match = await window.caches.match(cacheKey);
        if (match != null) {
            this._log.debug(() => ({
                msg: `${context.request.name}: OnFetch (from cache)`,
                apiContext: context,
                cacheKey,
            }));
            context.response = match;
        } else {
            // is this a Save request and the ApiMethod supports it?
            const shouldSaveOffline =
                context.request.offline &&
                context.request.offline.allowEdit &&
                [HttpMethod.POST, HttpMethod.PUT, HttpMethod.DELETE].includes(
                    context.request.method
                );

            if (shouldSaveOffline) {
                context = await this._handleOfflineSave(context, cacheKey);
            } else if (
                typeof context.request?.offline?.storeHelper === "string" &&
                Object.prototype.hasOwnProperty.call(
                    storeHelper,
                    context.request.offline.storeHelper
                )
            ) {
                context = this._loadFromStore(context);
            } else {
                // not managed offline - throw offline error
                this._log.warn(() => ({
                    msg: `${context.request.name}: OnFetch (from cache) - NOT FOUND`,
                    apiContext: context,
                    cacheKey,
                }));
                throw new ApiNetworkOfflineError("OFFLINE", cacheKey);
            }
        }
        return context;
    }

    async _handleOfflineTransformer(context: Context): Promise<Context> {
        if (
            context.request.contentType !== ContentType.AUTO &&
            context.request.contentType !== ContentType.JSON
        ) {
            throw new Error(
                `ApiCatalog method ${context.request.name} error: cache.transformer can only be used on ContentType.AUTO or JSON!`
            );
        }

        // transform it and convert back to body
        const resultBody = JSON.stringify(
            context.request.offline.transformer(context.request.params)
        );

        // return this response instead
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        context.response = new Response(resultBody, {
            status: 202, // Accepted
            statusText: QueuedOfflineStatusText,
            headers,
        });
        return context;
    }

    _loadFromStore = (context: Context): Context => {
        if (
            context.request.contentType !== ContentType.AUTO &&
            context.request.contentType !== ContentType.JSON
        ) {
            throw new Error(
                `ApiCatalog method ${context.request.name} error: cache.storeHelper can only be used on ContentType.AUTO or JSON!`
            );
        }

        // transform it and convert back to body
        const resultBody = JSON.stringify(
            storeHelper[context.request.offline.storeHelper]()
        );

        // return this response instead
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        context.response = new Response(resultBody, {
            status: 202, // Accepted
            statusText: QueuedOfflineStatusText,
            headers,
        });
        return context;
    };

    /**
     * Logic for handling a Save Request while offline
     * @param {Context} context
     * @param {string} cacheKey
     * @returns {Promise<Context>}
     * @memberof ApiManager
     * @private
     */
    async _handleOfflineSave(
        context: Context,
        cacheKey: string
    ): Promise<Context> {
        this._log.info(() => ({
            msg: `${context.request.name}: OnFetch while offline - add to offline queue`,
            apiContext: context,
            cacheKey,
        }));

        await addToOfflineQueue({
            type: OfflineQueueItemType.ApiCall,
            name: context.request.name,
            params: context.request.params,
        });

        if (typeof context.request.offline.transformer === "function") {
            // #56022 Support offline save also for items not marked as offline
            // return the success body expected by the relevant apiCatalog method
            context = await this._handleOfflineTransformer(context);
        } else {
            // ...and return meaningful info e.g. "will be saved once online..." -> handled by AfterFetch()
            context.response = new Response(null, {
                status: 202, // Accepted
                statusText: QueuedOfflineStatusText,
            });
        }
        return context;
    }

    /**
     * We skip licensing check when offline
     * @param {Context} context query context
     * @returns {Promise<Context>} current context
     * @memberof ApiManagerOffline
     * @override
     */
    async CheckLicense(context: Context): Promise<Context> {
        return context;
    }
}
