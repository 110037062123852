import { subscribe as subscribeApi } from "data/api";
import { toastActionResult } from "data/toast";

/**
 * Subscribes a given resource by itemUri, with an optional confirm dialog
 *
 * @param {{ itemUri: string }} { itemUri }
 * @param {{ name: string }} { name }
 */

class subscribe {
    queueMultiple: boolean = true;
    logger: () => any = toastActionResult;

    execute = async ({ itemUri, name }: { itemUri: string, name: string }) => {
        try {
            return await subscribeApi(itemUri, name);
        } catch (error) {
            return false;
        }
    };
    report = (success, { name }: Context) => {
        this.logger(success, "commandAction:subscribe", {
            name,
            context: name ? "name" : undefined,
        });
    };
}

export default subscribe;
