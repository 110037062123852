//@flow
import React from "react";
import styles from "./Error.module.css";

type Props = {
    errorMessage: string,
    showValidationBelow: boolean,
};

const Error = ({ errorMessage, showValidationBelow }: Props) => (
    <>
        <span className={styles.overlay} title={errorMessage} />
        {showValidationBelow && (
            <p className={styles.message}>
                <span>*{errorMessage}</span>
            </p>
        )}
    </>
);
Error.displayName = "ExtraAttributes.Error";

export default Error;
