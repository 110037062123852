import React from "react";
import LocalContactInfo from "components/LocalContactInfo";
import Constants, { type ConstantsEnum } from "data/constants";
import styles from "./PageStatus.module.css";
import { getClassNames } from "data/utils";

type Props = {
    /** current status  */
    status: ConstantsEnum,
    /** optional message to display
     * NOTE: only visible when status = ERROR or LOADING
     */
    message?: string,
    /** optional React element to show below message
     * NOTE: only visible when status = ERROR or LOADING
     */
    children?: React.ElementType,
};

const PageStatus = ({ status, message, children }: Props) => (
    <div className={getClassNames(styles.outer, status)}>
        <img src={require("./inPoint_192.png").default} alt="inPoint Icon" />
        {[Constants.ERROR, Constants.LOADING].includes(status) && (
            <div className={styles.inner}>
                {message}
                {children}
                {status === Constants.ERROR && <LocalContactInfo />}
            </div>
        )}
    </div>
);
PageStatus.displayName = "PageStatus";
PageStatus.defaultProps = {
    status: Constants.NONE,
};
export default PageStatus;
