import React, { useState, useCallback, useContext, useMemo } from "react";
import { getDisplayName } from "data/constants";
import { ItemContext } from "data/context";
import { usePostMessageHandler } from "hooks";
import { HtmlViewerAction } from "hooks/PostMessage/Actions";

const withContinuousPreview = (WrappedComponent: ComponentType<any>) => {
    const HOC = (props: Props) => {
        const { isContinuousPreview } = useContext(ItemContext) || {};

        const { onPrev, onNext } = props;

        const [atEnd, setAtEnd] = useState(false);

        const handlePrev = useCallback(
            (triggeredByHtmlViewer = false) => {
                /* istanbul ignore else */
                if (
                    typeof onPrev === "function" &&
                    (!triggeredByHtmlViewer || isContinuousPreview)
                )
                    onPrev();
                setAtEnd(isContinuousPreview);
            },
            [isContinuousPreview, onPrev, setAtEnd]
        );

        const handleNext = useCallback(
            (triggeredByHtmlViewer = false) => {
                /* istanbul ignore else */
                if (
                    typeof onNext === "function" &&
                    (!triggeredByHtmlViewer || isContinuousPreview)
                )
                    onNext();
                setAtEnd(false);
            },
            [onNext, setAtEnd, isContinuousPreview]
        );

        usePostMessageHandler(
            useMemo(
                () => ({
                    [HtmlViewerAction.Top]: /* istanbul ignore next */ () =>
                        handlePrev(true),
                    [HtmlViewerAction.Bottom]: /* istanbul ignore next */ () =>
                        handleNext(true),
                }),
                [handlePrev, handleNext]
            )
        );

        return (
            <WrappedComponent
                {...props}
                onPrev={handlePrev}
                onNext={handleNext}
                atEnd={atEnd}
            />
        );
    };
    HOC.displayName = `withContinuousPreview(${getDisplayName(
        WrappedComponent
    )})`;
    return HOC;
};

export default withContinuousPreview;
