import {
    fork,
    all,
    call,
    put,
    takeLatest,
    select,
    spawn,
    take,
    race,
} from "redux-saga/effects";
import { delay } from "redux-saga";
import {
    startOfflineSyncAction,
    offlineSyncStartedAction,
    offlineSyncDoneAction,
    setOfflineItemSyncStatusAction,
    removeOfflineItemAction,
    setOfflineItemDepsMapAction,
} from "data/actions";
import { actions as networkActions } from "redux-saga-network-status";
import * as networkActionTypes from "redux-saga-network-status/lib/actionTypes";
import actionTypes from "data/actions/actionTypes";
import {
    CustomHttpHeader,
    OfflineSyncStatus,
    type ApiMethodRef,
} from "data/types";
import ApiManagerMakeAvailableOffline from "data/ApiManagerMakeAvailableOffline";
import * as s from "data/reducers/selectors";
import {
    fetchOfflineItems,
    fetchUser,
    getArchiveTargets,
    fetchCards,
    fetchWorkflowCards,
    fetchWorkflowProviders,
    fetchNews,
} from "data/api";
import { hasCacheSupport } from "data/utils";
import { JL } from "data/logging";
import { replayOfflineQueue } from "data/offline/offlineQueue";
import { isFeatureOn, Feature } from "data/constants";

const _log = JL("offlineSaga");

// caching API instances per offlineItem
let APIs = {};
const getAPI = (item: OfflineItemDTO) =>
    APIs[item.id] == null
        ? (APIs[item.id] = new ApiManagerMakeAvailableOffline(item))
        : APIs[item.id];
let isSyncing = false;

let promptedForPersistentStorage = false;
const depsMap = {};

export function* updateUser() {
    yield call(fetchUser);
}

export function* startSync(action) {
    try {
        isSyncing = true;
        _log.debug(() => "starting sync");
        const items = action.payload.items; //exact items to sync
        if (items && items.length > 0) {
            console.time("[offline]");
            let anyOfflineItemSyncErrors = false;
            yield put(offlineSyncStartedAction());

            // update archive targets
            yield spawn(getArchiveTargets, true);

            for (var i = 0; i < items.length; i++) {
                const item = items[i];
                _log.debug(() => ({
                    msg: "syncing",
                    bc: item.breadcrumb.text,
                    offlineItem: item,
                }));
                depsMap[item.id] = {
                    name: item.breadcrumb.text,
                    children: [],
                    attributes: {
                        itemUri: item.itemUri,
                    },
                };

                try {
                    yield put(
                        setOfflineItemSyncStatusAction({
                            item,
                            status: OfflineSyncStatus.Syncing,
                        })
                    );

                    const t0 = performance.now();
                    yield call(preFetchFolder, item, item.itemUri);
                    const took = performance.now() - t0;

                    // retrieve item from redux to check for errors
                    const storeItems = yield select(s.offlineItemsSelector);
                    /* istanbul ignore if */
                    if (storeItems == null) {
                        throw new Error(
                            "offlineSaga could not retrieve all offlineItems"
                        );
                    }
                    const storeItem = storeItems[item.id];
                    /* istanbul ignore if */
                    if (storeItem == null) {
                        throw new Error(
                            "offlineSaga could not retrieve offlineItem " +
                                item.id
                        );
                    }

                    /* istanbul ignore else */
                    if (storeItem.status === OfflineSyncStatus.Syncing) {
                        // no errors, update to done!
                        yield put(
                            setOfflineItemSyncStatusAction({
                                item,
                                status: OfflineSyncStatus.Synced,
                                took,
                            })
                        );
                        delete depsMap[item.id].attributes.error;
                    } else {
                        anyOfflineItemSyncErrors = true;
                    }
                } catch (e) {
                    anyOfflineItemSyncErrors = true;
                    _log.fatalException(
                        () => ({
                            msg: "error while syncing",
                            bc: item.breadcrumb.text,
                            offlineItem: item,
                        }),
                        e
                    );
                    depsMap[item.id].attributes.error = e;
                    depsMap[item.id]._collapsed = false;
                    yield put(
                        setOfflineItemSyncStatusAction({
                            item,
                            message: e.message,
                            status: OfflineSyncStatus.SyncedWithErrors,
                        })
                    );
                }
                console.timeLog("[offline]", item);
                yield call(getAPI(item).LogEstimates);
            }
            yield put(
                offlineSyncDoneAction({
                    status: anyOfflineItemSyncErrors
                        ? OfflineSyncStatus.SyncedWithErrors
                        : OfflineSyncStatus.Synced,
                })
            );
            console.timeEnd("[offline]");
        }
    } catch (e) {
        /* istanbul ignore next */
        _log.fatalException(() => "Error in startSync()", e);

        /* istanbul ignore next */
        yield put(
            offlineSyncDoneAction({
                status: OfflineSyncStatus.Error,
                message: e.message,
            })
        );
    } finally {
        // release all API instances
        APIs = {};
    }
    isSyncing = false;
}

function* preFetchGlobals(): void {
    _log.debug(() => ({
        msg: "pre-fetching globals",
    }));

    // wait for user token
    yield take(actionTypes.USER_FETCH_RESULT);

    // fetch globals
    yield spawn(fetchCards);
    yield spawn(fetchWorkflowCards);
    yield spawn(fetchWorkflowProviders);
    yield spawn(fetchNews);
}
function* preFetchFolder(item: OfflineItemDTO, itemUri: string): void {
    if (item.isDoc !== false) {
        _log.warn(() => ({
            msg: "cannot pre-fetch offlineItem as currently only Folders are supported!",
            offlineItem: item,
        }));
        throw new Error("Only Folders are supported for offline sync!");
    }

    _log.debug(() => ({
        msg: "pre-fetching Folder",
        item,
        itemUri,
    }));

    // starting point is always the tree
    const childMapRef = {
        name: "fetchTree",
        children: [],
        attributes: {
            itemUri,
        },
    };
    depsMap[item.id].children.push(childMapRef);
    const rootDep = {
        name: "fetchTree",
        params: {
            itemUri,
            deep: false,
            all: true, // no paging
        },
    };
    yield call(fetchDeps, item, rootDep, childMapRef);
}

function* fetchDeps(item: OfflineItemDTO, dep: ApiMethodRef, mapRef): void {
    _log.debug(() => ({
        msg: "Fetching DEP:",
        dep,
    }));
    const t0 = performance.now();
    const { name, ...args } = dep;
    const deps = yield call(getAPI(item)._callWithDeps, dep.name, args);
    const took = Math.round(performance.now() - t0) + "ms";
    mapRef.attributes = {
        took,
        ...dep.params,
    };

    _log.debug(() => ({
        msg: "Retrieved sub-dependencies",
        dep,
        args,
        deps,
    }));

    for (const dep of deps) {
        try {
            // add child dependencies for processing
            const childMapRef = {
                name: dep.name,
                children: [],
                attributes: {},
            };
            mapRef.children.push(childMapRef);

            yield call(fetchDeps, item, dep, childMapRef);
        } catch (e) {
            _log.warn(() => ({
                msg: "error while fetchDeps",
                dep,
                e,
            }));

            yield put(
                setOfflineItemSyncStatusAction({
                    item,
                    message: e.message,
                    status: OfflineSyncStatus.SyncedWithErrors,
                })
            );
        }
    }
}

function* removeOfflineItem(action) {
    _log.debug(() => ({
        msg: "remove offline item",
        offlineItem: action.payload.item,
    }));
    const itemAPI = yield call(getAPI, action.payload.item);
    // remove window.cache entries
    itemAPI.DeleteCache().then((isSuccess) => {
        if (isSuccess)
            _log.debug(() => ({
                msg: "successfully deleted cache",
                offlineItem: action.payload.item,
            }));
        else
            _log.warn(() => ({
                msg: "could not delete cache",
                offlineItem: action.payload.item,
            }));
    });
}

export function* checkOfflineItems() {
    const hasOfflineItems = yield select(s.userHasOfflineItemsSelector);
    // retrieve current offline items
    const currentOffline = yield select(s.offlineItemsSelector);
    /* istanbul ignore else */
    if (
        hasOfflineItems /* istanbul ignore next */ ||
        Object.keys(currentOffline).length > 0
    ) {
        // https://storage.spec.whatwg.org/#example-3a7051a8
        if (
            !promptedForPersistentStorage &&
            navigator.storage &&
            navigator.storage.persist
        ) {
            // eslint-disable-next-line no-loop-func
            navigator.storage.persist().then((persistent) => {
                promptedForPersistentStorage = true;
                /* istanbul ignore else */
                if (persistent)
                    _log.info(
                        () =>
                            "Storage will not be cleared except by explicit user action"
                    );
                else
                    _log.warn(
                        () =>
                            "Storage may be cleared by the UA under storage pressure."
                    );
            });
        }

        // manual offline files
        try {
            const itemsToSync = [];

            // Update cached User network call to fix #56961 (update detached)
            yield spawn(updateUser);

            // retrieve new ones (with .LastUpdate) from server
            const items = yield call(fetchOfflineItems);

            // let's check the new ones
            for (const item of items) {
                // do we have this one already?
                const currentOfflineItem = currentOffline[item.id];
                // same same? no need to sync
                if (
                    currentOfflineItem &&
                    currentOfflineItem.lastUpdate === item.lastUpdate &&
                    [
                        OfflineSyncStatus.Synced,
                        OfflineSyncStatus.SyncedWithErrors,
                        OfflineSyncStatus.Error,
                    ].includes(currentOfflineItem.status)
                ) {
                    const exists = yield call(
                        getAPI(currentOfflineItem).CacheExists
                    );
                    if (exists) {
                        _log.debug(() => ({
                            msg: "item already synchronized",
                            offlineItem: currentOfflineItem,
                        }));
                        continue;
                    }
                }
                // TODO better granularity of which ItemsUris within an OfflineItem to sync?
                itemsToSync.push(item);
            }
            // process currentOfflineItems that have been removed!
            for (const currentOfflineItem of Object.values(currentOffline)) {
                const item = items?.find?.(
                    (i) => i.id === currentOfflineItem.id
                );
                // does not exist anymore! purge
                if (item == null) {
                    yield put(removeOfflineItemAction(currentOfflineItem));
                }
            }

            if (itemsToSync.length > 0) {
                _log.debug(() => ({
                    msg: "checkOfflineItems: itemsToSync",
                    itemsToSync,
                }));
                // TODO should we clear current cache for this item as a whole?
                // put(setOfflineItemSyncStatusAction) BEFORE startOfflineSyncAction() so they are all visible in the sidebar!
                for (const item of itemsToSync) {
                    yield put(
                        setOfflineItemSyncStatusAction({
                            item,
                            status: OfflineSyncStatus.Unknown,
                        })
                    );
                }
                yield put(startOfflineSyncAction(itemsToSync));
            } else {
                _log.debug(() => "checkOfflineItems: nothing to sync!");
            }
        } catch (e) {
            _log.fatalException(
                () => ({ msg: "Error in checkOfflineItems()" }),
                e
            );
            yield put(
                offlineSyncDoneAction({
                    status: OfflineSyncStatus.Error,
                    message: e.message,
                })
            );
        }
    }
}

export function* watchOfflineSync() {
    while (true) {
        const isOnline = yield select(s.isApiOnlineSelector);
        // if (!isOnline) {
        //     const hasBeenOnline = yield select(s.hasBeenOnlineSelector);
        //     if (!hasBeenOnline) {
        //         yield call(delay, 1000 * 5);
        //         continue;
        //     }
        // }
        if (isOnline && !isSyncing) {
            // check pending offline queue
            yield call(replayOfflineQueue);
            // check offlineItems to cache
            yield call(checkOfflineItems);
        }
        yield race({
            user: take(actionTypes.OFFLINE_SYNC_UPDATE),
            network: take(networkActionTypes.PING_SUCCESS),
            timeout: delay(1000 * 60), // TODO #54607 appSettings offline refresh timeout
        });
    }
}

/* istanbul ignore next */
function* getDepsMap(action) {
    yield put(
        setOfflineItemDepsMapAction(
            action.payload.item,
            depsMap[action.payload.item.id]
        )
    );
}

/* istanbul ignore next */
function persistNetworkStatus(action) {
    // To avoid race-condition with network-status reducer = > calculate online status by ourself
    const offline = [
        networkActionTypes.PING_FAILURE,
        networkActionTypes.NAVIGATOR_OFFLINE,
    ];
    const payload = {
        isOnline: !offline.includes(action.type),
    };
    window.caches.open("swConfig").then((cache) =>
        cache
            .put(
                "/swConfig",
                new Response(JSON.stringify(payload), {
                    status: 200,
                    headers: { "Content-Type": "application/json" },
                })
            )
            .then(() =>
                _log.info(() => ({
                    msg: "Persited Networkstatus to cache",
                    payload,
                }))
            )
    );
}

/* istanbul ignore next */
function* watchNetworkStatus() {
    yield takeLatest(
        [
            networkActionTypes.PING_SUCCESS,
            networkActionTypes.PING_FAILURE,
            networkActionTypes.NAVIGATOR_ONLINE,
            networkActionTypes.NAVIGATOR_OFFLINE,
        ],
        persistNetworkStatus
    );
}

function* offlineSaga() {
    if (!hasCacheSupport()) {
        _log.info(
            () =>
                "Browser does not support Caching - offlineItems cannot be synchronized!"
        );
        return;
    }
    if (!isFeatureOn(Feature.offline)) {
        _log.info(
            () =>
                "Offline feature disabled in appsettings - offlineItems cannot be synchronized!"
        );
        return;
    }
    // offline saga
    yield all([
        // listen for sync requests
        takeLatest(actionTypes.OFFLINE_SYNC_REQUEST, startSync),
        // listen for removals
        takeLatest(actionTypes.OFFLINEITEM_REMOVE, removeOfflineItem),
        // retrieve last offlineItem sync and add it to redux store
        takeLatest(actionTypes.OFFLINEITEM_DEPSMAP_REQUEST, getDepsMap),
        // background sync job
        fork(watchOfflineSync),
        // handle online/offline changes
        fork(watchNetworkStatus),
        // pre-fetch globals
        spawn(preFetchGlobals),
    ]);
}

export default offlineSaga;

// continuos  check for actual server connectivity - forked by rootSaga
/* istanbul ignore next*/
export function* checkServerConnectivity() {
    // check for network outages
    const correlationId = yield select(s.userCorrelationIdSelector);
    const pingUrl = `${window.CONFIG.host.basename}/ping`;
    var headers = new Headers();
    if (correlationId != null) {
        headers.append(CustomHttpHeader.CorrelationId, correlationId);
    }
    yield put(
        networkActions.startWatchNetworkStatus(
            new Request(pingUrl, {
                headers,
            })
        )
    );
    // additionally, periodically check for server connection
    // while (true) {
    //     if (!isSyncing) {
    //         if (yield select(isNavigatorOnlineSelector)) {
    //             yield put(networkActions.ping(pingUrl));
    //         }
    //     }
    //     yield call(delay, 1000 * 10); //every 10sec
    // }
}
