// @flow

import {
    type ExtraAttributesEmbeddedGridDTO,
    type ExtraAttributeEmbeddedGridColumnDTO,
    type ExtraAttributeDataAuditLogDTO,
} from "data/types";

/**
 * returns the default values for a given grid
 *
 * @param {string} gridName grid name (identifier)
 * @param {any} attributes form attributes
 * @param {string} seqId seqId
 * @returns {Array<any>} default value of related grid row
 */
export const getGridRowDefaultValues = (
    gridName: string,
    attributes: any,
    seqId: string | number
): Array<any> => {
    const grid = getGrid(attributes, gridName);
    const [isNew] = parseSeqId(seqId);
    if (isNew) {
        return grid.columns.map((col, index) =>
            grid.seqIdColumn === index
                ? `${seqId}`
                : attributes.find((attribute) => attribute.name === col.name)
                      .value
        );
    }
    return grid.rows.find((row) => row[grid.seqIdColumn] === `${seqId}`);
};

/**
 * returns isNew and corresponding row index/seqId
 *
 * @param {string | number} seqId seqId to parse
 * @returns {Array<boolean|number>} array containing a bool flag "isNew" and the designated seqId (isNew = false) or new row index (isNew = true)
 */
export const parseSeqId = (seqId: string | number): Array<boolean | number> => {
    seqId = parseInt(seqId, 10);
    return [seqId < 0, seqId < 0 ? convertSeqIdToIndex(seqId) : seqId];
};

/**
 * converts a row index into a seqId
 *
 * @param {number} index row index to convert to seqId
 * @returns {number} seqId
 */
export const convertIndexToSeqId = (index: number) => (index + 1) * -1;

/**
 * converts a seqId into a row index
 *
 * @param {number} seqId seqId to convert to row index
 * @returns {number} row index
 */
export const convertSeqIdToIndex = (seqId: number) => seqId * -1 - 1;

/**
 * returns  Embedded Grid DTO from form attributes by given gridName
 *
 * @param {Array<any>} attributes form attributes
 * @param {string} gridName grid name with form type prefix
 * @returns {ExtraAttributesEmbeddedGridDTO}  Embedded Grid DTO
 */
export const getGrid = (
    attributes: Array<any>,
    gridName: string
): ExtraAttributesEmbeddedGridDTO => {
    return attributes.find(
        (attribute) => attribute.name === gridName.split(".")[1]
    ).value;
};

/**
 * maps a grid row to given ExtraAttributeEmbeddedGridColumnDTO names
 *
 * @param {Array<any>} row given row
 * @param {Array<ExtraAttributeEmbeddedGridColumnDTO>} columns grid columns
 * @returns {Object<any>} row values by column name
 */
export const mapGridRowToColumns = (
    row: Array<any>,
    columns: Array<ExtraAttributeEmbeddedGridColumnDTO>
): Object<any> => {
    const result = {};
    columns.forEach(
        (col: ExtraAttributeEmbeddedGridColumnDTO, index: number) => {
            result[col.name] = row[index];
        }
    );
    return result;
};

/**
 * checks if row is deleted in given auditLog
 *
 * @param {?ExtraAttributeDataAuditLogDTO} auditLog auditLog to check
 * @param {string} seqId row identifier
 * @returns {bool} flag if row by seqId has changed or is new
 */
export const isGridRowDeleted = (
    auditLog: ?ExtraAttributeDataAuditLogDTO,
    seqId: string
): boolean =>
    auditLog &&
    auditLog.deletedIds &&
    auditLog.deletedIds.includes(parseInt(seqId, 10));

/**
 * checks if row is updated changed in given auditLog
 *
 * @param {?ExtraAttributeDataAuditLogDTO} auditLog auditLog to check
 * @param {string} seqId row identifier
 * @returns {bool} flag if row by seqId has changed or is new
 */
export const isGridRowUpdated = (
    auditLog: ?ExtraAttributeDataAuditLogDTO,
    seqId: string
): boolean =>
    auditLog &&
    ((auditLog.updatedRows &&
        Object.prototype.hasOwnProperty.call(auditLog.updatedRows, seqId)) ||
        parseInt(seqId, 10) < 0);
